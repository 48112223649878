import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import { FormControl, TextField, InputLabel } from '@mui/material';

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import OutlinedInput from '@mui/material/OutlinedInput';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';

import { useState } from 'react';

import { useDispatch, useSelector } from "react-redux";
import { studentCreate } from "../../../actions/student";
import { useNavigate } from 'react-router-dom';

export default function StudentWrite() {

    const initialStudentState = {
        mem_idx: '', 
        username: '',
        id: '',
        password: '',
        confirm_password: '',
        showPassword: false,
    };

    const [student, setStudent] = useState(initialStudentState);

    const userInfo = useSelector(state => state.auth.user);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleInputChange = event => {
        const { name, value } = event.target;
        setStudent({ ...student, [name]: value });
    };

    const handleClickShowPassword = () => {
        setStudent({
            ...student,
            showPassword: !student.showPassword,
        });
    };
    
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleSubmit = (event) => {

        event.preventDefault();

        console.log("student : ", student);

        const sendData = {
            mem_idx: userInfo.idx,
            name: student.username,
            id: student.id,
            password: student.password
        };

        console.log("sendData : ", sendData);

        if (student.password !== student.confirm_password)
        {
            alert("패스워드가 일치하지 않습니다. 다시 확인해주세요.");
            return;
        }
        else
        {
            // 여기서 디스패치로~
            dispatch(studentCreate(sendData))
            .then(data => 
            {
                console.log("data : ", data);
                if (!data.success)
                {
                    alert(data.msg);
                    return;
                }
                
                navigate("/dashboard/StudentList");
            })
            .catch(e => {
                console.log(e);
            });
        }
    };

  return (
    <Card sx={{ minWidth: 275 }}>
      <CardContent>
      <FormControl>
            <TextField
                id="username"
                label="이름"
                helperText="이름을 입력해주세요."
                variant="standard"
                name="username"
                onChange={handleInputChange}
            />
            <TextField
                id="id"
                label="아이디"
                helperText="아이디를 입력해주세요."
                variant="standard"
                name="id"
                onChange={handleInputChange}
            />
            <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
            <InputLabel htmlFor="outlined-adornment-password">비밀번호</InputLabel>
                <OutlinedInput
                    id="password"
                    name="password"
                    type={student.showPassword ? 'text' : 'password'}
                    value={student.password}
                    onChange={handleInputChange}
                    endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                        >
                        {student.showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                    </InputAdornment>
                    }
                    label="Password"
                />
            </FormControl>
            <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
            <InputLabel htmlFor="outlined-adornment-password">비밀번호 확인</InputLabel>
                <OutlinedInput
                    id="confirm_password"
                    name="confirm_password"
                    type={student.showPassword ? 'text' : 'password'}
                    value={student.confirm_password}
                    onChange={handleInputChange}
                    endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                        >
                        {student.showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                    </InputAdornment>
                    }
                    label="Confirm Password"
                />
            </FormControl>
        </FormControl>
        
      </CardContent>
      <CardActions>
        <Button onClick={handleSubmit} size="small">학습자 추가</Button>
      </CardActions>
    </Card>
  );
}