import * as React from "react";

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
// import DashboardIcon from '@mui/icons-material/Dashboard';
import CampaignIcon from '@mui/icons-material/Campaign';
import CreditCardIcon from '@mui/icons-material/CreditCard';
// import AssessmentIcon from '@mui/icons-material/Assessment';
// import CategoryIcon from '@mui/icons-material/Category';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
// import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import ArticleIcon from '@mui/icons-material/Article';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';

import { useNavigate } from 'react-router-dom';

// const MenuArray = ['대쉬보드', '공지사항', '결제 정보', '학습자 정보', '카테고리 관리', '상품관리', '주문관리', '환불 히스토리'];
// const MenuUrlArray = ['/dashboard', '/dashboard/notice', '/dashboard/PaymentList', '/dashboard/student', '/dashboard/CategoryList', '/dashboard/ProductList', '/dashboard/OrderList', '/dashboard/RefundList'];
// const MenuIconArray = [<DashboardIcon />, <CampaignIcon />, <CreditCardIcon />, <AssessmentIcon />, <CategoryIcon />, <ShoppingBasketIcon />, <LibraryBooksIcon />, <ArticleIcon />];

const MenuArray = ['공지사항', '학습자 관리', '주문 관리', '패키지 관리', '환불 히스토리'];
const MenuUrlArray = ['/dashboard/NoticeListClient/notice', '/dashboard/StudentList', '/dashboard/OrderList', '/dashboard/PackageList', '/dashboard/RefundList'];
const MenuIconArray = [<CampaignIcon />, <PeopleAltIcon />, <CreditCardIcon />, <ShoppingBasketIcon />, <ArticleIcon />];

export default function LeftMenu() {

  const navigate = useNavigate();

  const handleLeftNav = (index) => {
    switch (index)
    {
      // dashboard : 대쉬보드
      // case 0:
      //   navigate(MenuUrlArray[0]);
      //   break;

      // notice : 공지사항
      case 0:
        navigate(MenuUrlArray[0]);
        break;

      // order : 학습자 관리
      case 1:
        navigate(MenuUrlArray[1]);
        break;

      // student : 주문 관리
      case 2:
        navigate(MenuUrlArray[2]);
        break;

      // student : 패키지 관리
      case 3:
        navigate(MenuUrlArray[3]);
        break;

      // refund : 환불 히스토리
      case 4:
        navigate(MenuUrlArray[4]);
        break;

      default:
        break;

    }
  };

    return (
        <div>
        <List>
          {MenuArray.map((text, index) => (
            <ListItem button key={text} onClick={() => handleLeftNav(index)}>
              <ListItemIcon>
                {MenuIconArray[index]}
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItem>
          ))}
        </List>
        </div>
    );
}