import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';

import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';

import Divider from '@mui/material/Divider';

import { useCallback, useState } from 'react';

import { useTranslation, Trans } from 'react-i18next'; // 1. react-i18next import 

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import { useDispatch, useSelector } from "react-redux";
import { classHomeworkListAll, classHomeworkInit } from "../../actions/class";
import { missionCategoryList } from "../../actions/mission_category";
import { classUserListAll } from "../../actions/users";

import ClassHomeworkCheckDialog from "./ClassHomeworkCheckDialog";

import * as common from "../../lib";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function StudentExcelUploadDialog(props) {
  const [open, setOpen] = React.useState(false);

  // 강제로 재렌더링을 하게 하는 방법
  const [, updateState] = useState();	// 상태변수는 선언하지 않는다.
  const forceUpdate = useCallback(() => updateState({}), []);

  const [checkItems, setCheckItems] = useState([]);
  const [originalcheckItems, setOriginalcheckItems] = useState([]);
  const [categoryIdx, setCategoryIdx] = useState('');
  const [levelValue, setLevelValue] = useState('');
  const [mstIdx, setMstIdx] = useState('');

  const [selectDisabled, setSelectDisabled] = useState(true);

  const { t, i18n } = useTranslation(); // 3. useTranslation hook 선언
  const dispatch = useDispatch();	

  const authState = useSelector(state => state.auth);
  const classHomeworkList = useSelector(state => state.classReducer.homeworkList);
//   console.log("classHomeworkList : ", classHomeworkList);
  const classUserList = useSelector(state => state.users.classuser);
//   console.log("classUserList : ", classUserList);
  const category = useSelector(state => state.mission_category);
// console.log("category : ", category);

// 블록코딩 인공지능 중에서 데이터가 쌓이는 미션들
const arrMissionAI = [
'Mission_5101', 'Mission_5102', 'Mission_5103', 'Mission_5104', 'Mission_5201', 'Mission_5202', 'Mission_5203', 'Mission_5204', 'Mission_5205', 'Mission_5206'
,'Mission_5207', 'Mission_5208', 'Mission_5213', 'Mission_5401', 'Mission_5402', 'Mission_5403', 'Mission_5404', 'Mission_5405', 'Mission_5406', 'Mission_5407'
,'Mission_5408', 'Mission_5409', 'Mission_5414', 'Mission_5601', 'Mission_5602', 'Mission_5603', 'Mission_5604', 'Mission_5605', 'Mission_5606', 'Mission_5607'
,'Mission_5608', 'Mission_5609', 'Mission_5610', 'Mission_5611', 'Mission_5616', 'Mission_5620', 'Mission_5801', 'Mission_5802', 'Mission_5803', 'Mission_5804'];

  const handleClickOpen = () => {
    setOpen(true);

	// console.log(props.RowData);

	// 미션 카테고리 데이터 불러오기
	dispatch(classUserListAll(props.RowData.mc_idx))
	.then(data => 
	{
		// console.log("data : ", data);
	})
	.catch(e => {
		console.log(e);
	});

	// 미션 카테고리 데이터 불러오기
	dispatch(missionCategoryList())
	.then(data => 
	{
		// console.log("data : ", data);
	})
	.catch(e => {
		console.log(e);
	});

  };

  const handleClose = () => {
    setOpen(false);

	setCategoryIdx("");
	setLevelValue("");
	setMstIdx("");

	// 숙제 데이터 초기화
	dispatch(classHomeworkInit())
	.then(data => 
	{
		// console.log("data : ", data);
	})
	.catch(e => {
		console.log(e);
	});
  };

  const checkHandled = (e) => {
    // console.log('checkHandled')
    checkItemHandler(e.target.id, e.target.checked);
  }

  const checkItemHandler = (id, isChecked) => {

	let intID = parseInt(id);	// 숫자로 저장해야지 인식합니다. 아니면 문자로 인식해서 includes 함수가 제대로 작동하지 않습니다.

	if (isChecked) 
	{
		setCheckItems((prev) => [...prev, intID]) // 불변성을 지키기 위한 원본 배열을 복사 후 추가
		setOriginalcheckItems((prev) => [...prev, intID]);	// 컨트롤 되기 전의 원래의 값들 (전송을 위한 전체 리스트를 따로 관리합니다.)
	} 
	else 
	{
		// console.log('not')
		setCheckItems(checkItems.filter((item) => item !== intID)) // 현재 checkItems의 배열에서 해당 id를 제외한 새로운 배열 반환
		setOriginalcheckItems(originalcheckItems.filter((item) => item !== intID));	// 컨트롤 되기 전의 원래의 값들 (전송을 위한 전체 리스트를 따로 관리합니다.)
	}
	//   console.log(checkItems);
	//   console.log(originalcheckItems);
  }

  // 체크박스 전체 선택
  const handleAllCheck = (checked) => {

	const idArray = [];
	classHomeworkList.forEach((el) => idArray.push(el.mis_idx));

	let originCheckItemCopy = originalcheckItems;

    if(checked) 
	{
      // 전체 선택 클릭 시 데이터의 모든 아이템(id)를 담은 배열로 checkItems 상태 업데이트  
      setCheckItems(idArray);
	  for (let i=0; i<idArray.length; i++)
	  {
		originCheckItemCopy.push(idArray[i]);
	  }

	  // 중복제거
		let uniqueArr = [];
		originCheckItemCopy.forEach((element) => {
			if (!uniqueArr.includes(element)) {
				uniqueArr.push(element);
			}
		});

	  setOriginalcheckItems(uniqueArr);
    }
    else {
      // 전체 선택 해제 시 checkItems 를 빈 배열로 상태 업데이트
      setCheckItems([]);
	  for (let i=0; i<idArray.length; i++)
	  {
		originCheckItemCopy = originCheckItemCopy.filter((element) => element !== idArray[i]);
	  }

	  // 중복제거
	  let uniqueArr = [];
		originCheckItemCopy.forEach((element) => {
			if (!uniqueArr.includes(element)) {
				uniqueArr.push(element);
			}
		});

	  setOriginalcheckItems(uniqueArr);
    }

	// console.log("checkItems : ", checkItems);
	// console.log("originalcheckItems : ", originalcheckItems);
	// console.log("idArray : ", idArray);
  }

  const handleInputChange = event => {

	const { name, value } = event.target;

	if (name === "category_idx")
	{
		setCategoryIdx(value);

		// 튜토리얼, 스페셜, 체험판은 레벨이 없으므로 레벨을 비활성화 시킵니다.
		if (value === 1 || value === 5 || value === 6)
		{
			setSelectDisabled(true);
			setLevelValue("");
		}
		else
		{
			setSelectDisabled(false);
		}
	}
	else
	{
		setMstIdx(value);
	}
	
  };

  const onSearchList = (event) => {

	event.preventDefault();

	let sendData = {
		mc_idx:props.RowData.mc_idx,
		mst_idx: mstIdx,
		category_idx: categoryIdx,
		level: levelValue,
	}

	// 창이 열릴 때 데이터를 불러온다. (화면에 표시되는 데이터를 편집하기 위한 데이터)
	dispatch(classHomeworkListAll(sendData))
	.then(data => 
	{
		// console.log("classHomeworkListAll data : ", data);
	})
	.catch(e => {
		console.log(e);
	});
  
  }

  const onSearchInit = (event) => {

	event.preventDefault();

	setCategoryIdx("");
	setLevelValue("");

	// console.log("onSearchInit call");

	let sendData = {
		mc_idx:props.RowData.mc_idx,
		mst_idx: mstIdx,
		category_idx: "",
		level: ""
	}

	// 창이 열릴 때 데이터를 불러온다.
	dispatch(classHomeworkListAll(sendData))
	.then(data => 
	{
		// console.log("classHomeworkListAll data : ", data);
	})
	.catch(e => {
		console.log(e);
	});

  }

  const onLevelValueChange = (event) => {
	setLevelValue(event.target.value);
  }

  return (
    <div>
      <Button variant="outlined" onClick={handleClickOpen}>{/* 관리 */}{t('ClassHomework.string21')}</Button>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
		maxWidth="md"
		fullWidth={true}
		sx={{ overflowY: 'hidden' }}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
          {/* 숙제관리 */}{t('ClassHomework.title1')}
		  
		  <Typography gutterBottom sx={{fontSize:15, marginTop: 2}}>

		  	<Box sx={{
				display: 'flex',
				flexDirection: 'row',
				alignItems: 'center'
				}}
			>
				<Box sx={{ minWidth: 140, width: 140, marginRight: 2}}>

				<FormControl fullWidth>
					<InputLabel id="category_idx">{/* 학습자 */}{t('ClassHomework.string1')}</InputLabel>
					<Select
					labelId="mst_idx"
					id="mst_idx"
					name="mst_idx"
					value={mstIdx || ""}
					onChange={handleInputChange}
					// label="학습자"
					label={t('ClassHomework.string1')}
					>
					<MenuItem value="" selected disabled>{/* 선택하세요 */}{t('ClassHomework.string2')}</MenuItem>
					{classUserList && classUserList.map((item, index) => (
					<MenuItem value={item.mst_idx} key={index}>
						{item.mst_name}
					</MenuItem>
					))}
					</Select>
				</FormControl>

				</Box>

				<Box sx={{ minWidth: 140, width: 140, marginRight: 2}}>

				<FormControl fullWidth>
					<InputLabel id="category_idx">{/* 카테고리 */}{t('ClassHomework.string3')}</InputLabel>
					<Select
					labelId="category_idx"
					id="category_idx"
					name="category_idx"
					value={categoryIdx || ""}
					onChange={handleInputChange}
					// label="카테고리"
					label={t('ClassHomework.string3')}
					>
					<MenuItem value="" selected disabled>{/* 선택하세요 */}{t('ClassHomework.string2')}</MenuItem>
					{category && category.filter((x)=> x.mis_category_name!=="체험판").map((item, index) => (
					<MenuItem value={item.mis_category_idx} key={index}>
						{/* item.mis_category_name */}
						{item.mis_category_name ? common.returnCategoryTranslation(item.mis_category_name) : ""}
					</MenuItem>
					))}
					</Select>
				</FormControl>
				
				</Box>

				<Box sx={{ minWidth: 140, width: 140, marginRight: 2}}>
				<FormControl fullWidth>
					<InputLabel id="demo-simple-select-label">{/* 레벨 */}{t('ClassHomework.string4')}</InputLabel>
					<Select
					labelId="classValue"
					id="classValue"
					value={levelValue}
					// label="레벨"
					label={t('ClassHomework.string4')}
					onChange={onLevelValueChange}
					disabled={selectDisabled}
					>
						<MenuItem value="" selected disabled>{/* 선택하세요 */}{t('ClassHomework.string2')}</MenuItem>
						<MenuItem value="B">{/* 초급 */}{t('ClassHomework.string5')}</MenuItem>
						<MenuItem value="M">{/* 중급 */}{t('ClassHomework.string6')}</MenuItem>
						<MenuItem value="H">{/* 고급 */}{t('ClassHomework.string7')}</MenuItem>
					</Select>
				</FormControl>
				</Box>
				<Button variant="contained" size='small' onClick={onSearchList} sx={{marginRight:1}}>{/* 검색 */}{t('ClassHomework.string8')}</Button>
				<Button variant="contained" size='small' onClick={onSearchInit} sx={{marginRight:10}}>{/* 검색 초기화 */}{t('ClassHomework.string9')}</Button>
			</Box>
          </Typography>
        </BootstrapDialogTitle>

        <DialogContent dividers>
			
        </DialogContent>

		<Box
			component="form"
			sx={{
				'& .MuiTextField-root': { m: 1, width: '670px', marginTop: '20px', marginLeft: '20px', marginRight: '20px' },
			}}
			noValidate
			autoComplete="off"
		>
		<div>
			<TableContainer sx={{ maxHeight: 670 }}>
				<Table stickyHeader aria-label="sticky table" size="small">
				<TableHead>
					<TableRow>
					{/* <TableCell align="center">
						<Checkbox
							onChange={(e) => handleAllCheck(e.target.checked)}
							checked={checkItems.length === classHomeworkList?.length ? true : false}
							// 데이터 개수와 체크된 아이템의 개수가 다를 경우 선택 해제 (하나라도 해제 시 선택 해제)
							inputProps={{ 'aria-label': 'controlled' }}
						/>
					</TableCell> */}
					<TableCell align="center">{/* 번호 */}{t('ClassHomework.string10')}</TableCell>
					<TableCell align="center">{/* 미션명 */}{t('ClassHomework.string11')}</TableCell>
					<TableCell align="center">{/* 카테고리 */}{t('ClassHomework.string12')}</TableCell>
					<TableCell align="center">{/* 등급 */}{t('ClassHomework.string13')}</TableCell>
					<TableCell align="center">{/* 상태 */}{t('ClassHomework.string14')}</TableCell>
					<TableCell align="center">{/* 첨삭 */}{t('ClassHomework.string15')}</TableCell>
					<TableCell align="center">{/* 확인 */}{t('ClassHomework.string16')}</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
				{
					classHomeworkList && classHomeworkList.length !== 0
					? 
					classHomeworkList
					.filter((x)=> x.mis_category_name!=="체험판")
					.filter((x)=> !arrMissionAI.includes(x.mis_id))
					.map((row, index) => {
						return (
						<TableRow hover role="checkbox" tabIndex={-1} key={index}>
							{/* <TableCell align="center">
								<Checkbox
									key={row.mis_idx} 
									id={row.mis_idx} 
									onChange={checkHandled}
									checked={checkItems.includes(row.mis_idx) ? true : false}
									inputProps={{ 'aria-label': 'controlled' }}
								/>
							</TableCell> */}
							<TableCell component="th" scope="row" align="center">
							{index+1}
							</TableCell>
							<TableCell align="center">{row.mis_id}</TableCell>
							<TableCell align="center">{ row.mis_category_name ? common.returnCategoryTranslation(row.mis_category_name) : "" }</TableCell>
							<TableCell align="center">{ row.mis_rating ? common.returnMissionRating(row.mis_rating) : "" }</TableCell>
							<TableCell align="center"
								sx={{
									backgroundColor: row.hm_idx === null ? "white" : "#1E88E5", color: row.hm_idx === null ? 'black' : 'white'
								}}
							>{row.hm_idx === null ? /* "숙제 중" */t('ClassHomework.string17') : /* "숙제 완료" */t('ClassHomework.string18')}</TableCell>
							<TableCell align="center"
								sx={{
									backgroundColor: row.hc_idx === "0" ? "white" : "#FF9800", color: row.hc_idx === "0" ? 'black' : 'white'
								}}
							>{row.hc_idx === "0" ? /* "첨삭 중" */t('ClassHomework.string19') : /* "첨삭 완료" */t('ClassHomework.string20')}</TableCell>
							<TableCell align="center">{row.hm_idx === null ? "-" : <ClassHomeworkCheckDialog RowData={row} mstIdx={mstIdx} categoryIdx={categoryIdx} levelValue={levelValue} mcIdx={props.RowData.mc_idx} />}</TableCell>
						</TableRow>
						);
					})
					:
					<TableRow>
						<TableCell component="th" scope="row" align="center" colSpan="9">{/* 데이터 없음 */}{t('Common.No_data')}</TableCell>
					</TableRow>
					}
				</TableBody>
				</Table>
			</TableContainer>
		</div>
		</Box>
		<Divider />
		
        <DialogActions>
		  <Button autoFocus onClick={handleClose}>
            {/* 닫기 */}{t('Common.close')}
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}