import {
  LIST_COUPON,
  CREATE_COUPON,
  MODIFY_COUPON,
  DELETE_COUPON,
  SELECT_COUPON,
  EXCEL_DOWNLOAD_COUPON,
  DETAIL_LIST_COUPON,
  MANUAL_ADD_COUPON,
  REGISTER_COUPON,
  USER_LIST_COUPON,
  USER_SELECT_COUPON,
  USER_ALL_COUPON,
  REGISTER_BULK_COUPON,
} from "../actions/types";

const initialState = [];

const couponReducer = (coupon = initialState, action) => {
  const { type, payload } = action;

  // console.log("couponReducer Call!");
  // console.log("type : ", type);
  // console.log("payload : ", payload);

  switch (type) {

    case LIST_COUPON:
      return {
        ...coupon,
        list : payload.list,
        paging : payload.paging,
      };

    case CREATE_COUPON:
      return [];

    case MODIFY_COUPON:
      return [];

    case DELETE_COUPON:
      return [];

    case SELECT_COUPON:
      return{
        ...coupon,
        select: payload,
      };

    case EXCEL_DOWNLOAD_COUPON:
      return{
        ...coupon,
        exceldownload: payload,
      };

    case DETAIL_LIST_COUPON:
      return {
        ...coupon,
        detaillist : payload.list,
        detailpaging : payload.paging,
      };

    case MANUAL_ADD_COUPON:
      return [];

    case REGISTER_COUPON:
      return [];

    case USER_LIST_COUPON:
      return {
        ...coupon,
        userlist : payload.list,
        userpaging : payload.paging,
      };

    case USER_SELECT_COUPON:
      return{
        ...coupon,
        userselect: payload,
      };

    case USER_ALL_COUPON:
      return{
        ...coupon,
        all: payload,
      };

    case REGISTER_BULK_COUPON:
      return [];

    default:
      return coupon;
  }
};

export default couponReducer;