import * as React from 'react';
import Button from '@mui/material/Button';

import { useTranslation, Trans } from 'react-i18next'; // 1. react-i18next import 

export default function PackageInfoDiv(props) {

  const { t, i18n } = useTranslation(); // 3. useTranslation hook 선언

  return (
    <>
		{/* <p>이용권 {props.myPackageList?.filter(row => row?.mm_state === "W", row => row?.mm_state_cd === "A").length}개 사용가능</p> */}
    <p>{t('GroupMypageClassReport.string11', {count : props.myPackageList?.filter(row => row?.mm_state === "W", row => row?.mm_state_cd === "A").length})}</p>
		{/* <Button variant="outlined" size="small">이용권 확인</Button> */}
    </>
  );
}