import * as React from 'react';
import { useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import styles from "../css/common.module.css";
import $ from 'jquery';

import { newsOutsideList } from "../actions/news";

import { useNavigate, useParams } from 'react-router-dom';

import * as common from "../lib";

import { useTranslation, Trans } from 'react-i18next'; // 1. react-i18next import 

export default function News() {

	const authState = useSelector(state => state.auth);
    // console.log("authState : ", authState);

	let board_page  = useParams().prevPage;
    // console.log("board_page : ", board_page);

	const news_list = useSelector(state => state.news?.list);
    // console.log("news_list : ", news_list);
    const news_paging = useSelector(state => state.news?.paging);
    // console.log("news_paging : ", news_paging);

	const { t, i18n } = useTranslation(); // 3. useTranslation hook 선언

    if (board_page === undefined)
          board_page = 1;

    const dispatch = useDispatch();
    const navigate = useNavigate();

	useEffect(() => {

		// 여기에서 jQuery를 초기화 합니다.
		$(document).ready(function(){

			// console.log("jquery ready!");

			//결제 정보 페이지 페이징 버튼	
			$(`.${styles.list_pagebtn_area} li`).on('click',function() 
			{
				let tg=$(this);
				let num=tg.index();
				let prev;
				let next;
				let last;
				let nowBtn=$(`.${styles.list_pagebtn_area}`).children(`.${styles.on}`);
				let nowIndex=nowBtn.index();
				
				$(`.${styles.list_pagebtn_area} li`).removeClass(`${styles.on}`);
				
				if(tg.hasClass(`${styles.arrow_btn}`)==true)
				{
					if(tg.hasClass(`${styles.first}`)==true)
					{
						tg=$(`.${styles.list_pagebtn_area} li`).eq(2);
					}
					else if(tg.hasClass(`${styles.prev}`)==true)
					{
						prev=nowIndex-1;
						
						if(nowIndex===2)
						{
							tg=$(`.${styles.list_pagebtn_area} li`).eq(2);
						}else
						{
							tg=$(`.${styles.list_pagebtn_area} li`).eq(prev);
						};
						// console.log("prev click");
					}
					else if(tg.hasClass(`${styles.next}`)==true)
					{
						let lastNum=$(`.${styles.list_pagebtn_area} li`).last().index();
						next=nowIndex+1;
						last=lastNum-2;
						
						if(nowIndex===last)
						{
							tg=$(`.${styles.list_pagebtn_area} li`).eq(last);
						}else
						{
							tg=$(`.${styles.list_pagebtn_area} li`).eq(next);
						};
						// console.log("next click");
					}
					else if(tg.hasClass(`${styles.last}`)==true)
					{
						last=num-2;
						tg=$(`.${styles.list_pagebtn_area} li`).eq(last);
					};
					
					tg.addClass(`${styles.on}`);
					
				}
				else
				{
					tg.addClass(`${styles.on}`);
				};
			});

		});

		const boardListSend = {
			board_page : board_page,
		  }
  
		  // 여기서 디스패치로~
		  dispatch(newsOutsideList(boardListSend))
		  .then(data => 
		  {
			//   console.log("data : ", data);
		  })
		  .catch(e => {
			  console.log(e);
		  });
		  
		  return () => {      // 언마운트
			  console.log("언마운트!");
		  };

	}, [$, dispatch]);

  return (
    <>
      	{/* Header 분리됨 MainLayout Header에 있음 */}

		  <div className={styles.news_area}>
			<div className={styles.news_top_line_banner}>
				<div className={styles.inner}>
					<span>{/* 야미 새소식 */}{t("News.title")}</span>
				</div>
			</div>
			<div className={styles.news_wrap}>
                <div className={styles.inner}>
                    <div className={styles.news_content_area}>
						
						{
							news_list?.length !== 0 ? news_list && news_list.map((row, index) => (

							<a href={`/NewsContent/${row.idx}/${board_page}`} className={styles.news_box} key={index}>
								<div className={styles.img_area}>
									<img src={common.returnImgSrc(row.photo_path, row.photo_file)} alt="썸네일"/>
								</div>
								<div className={styles.text_area}>
									<span>{row.title}</span>
									<p>{row.contents}</p>
								</div>
							</a>
						)) 
						:
							// <>데이터 없음</>
							<>{t("Common.No_data")}</>
						}
                        {/* <a href="/NewsContent" className={styles.news_box}>
                            <div className={styles.img_area}>
                                <img src="/assets/3d_img1@2x.png" alt="썸네일"/>
                            </div>
                            <div className={styles.text_area}>
                                <span>야미코딩 정식버전 드디어 출시, 출시 기념 최대 50% 할인 이벤트</span>
                                <p>야미코딩이 드디어 정식버전으로 출시되었습니다. 이미 라이트 버전에서 많은 사람들에게 사랑받아 인기브랜드대상까지 수상한 야미코딩이 드디어 정식 버전으로 사람들 앞에 서게 되었습니다.야미코딩이 드디어 정식버전으로 출시되었습니다. 이미 라이트 버전에서 많은 사람들에게 사랑받아 인기브랜드대상까지 수상한 야미코딩이 드디어 정식 버전으로 사람들 앞에 서게 되었습니다.야미코딩이 드디어 정식버전으로 출시되었습니다. 이미 라이트 버전에서 많은 사람들에게 사랑받아 인기브랜드대상까지 수상한 야미코딩이 드디어 정식 버전으로 사람들 앞에 서게 되었습니다.야미코딩이 드디어 정식버전으로 출시되었습니다. 이미 라이트 버전에서 많은 사람들에게 사랑받아 인기브랜드대상까지 수상한 야미코딩이 드디어 정식 버전으로 사람들 앞에 서게 되었습니다.야미코딩이 드디어 정식버전으로 출시되었습니다. 이미 라이트 버전에서 많은 사람들에게 사랑받아 인기브랜드대상까지 수상한 야미코딩이 드디어 정식 버전으로 사람들 앞에 서게 되었습니다.야미코딩이 드디어 정식버전으로 출시되었습니다. 이미 라이트 버전에서 많은 사람들에게 사랑받아 인기브랜드대상까지 수상한 야미코딩이 드디어 정식 버전으로 사람들 앞에 서게 되었습니다.</p>
                            </div>
                        </a>
                        <a href="/NewsContent" className={styles.news_box}>
                            <div className={styles.img_area}>
                                <img src="/assets/3d_img1@2x.png" alt="썸네일"/>
                            </div>
                            <div className={styles.text_area}>
                                <span>야미코딩 정식버전 드디어 출시, 출시 기념 최대 50% 할인 이벤트</span>
                                <p>야미코딩이 드디어 정식버전으로 출시되었습니다. 이미 라이트 버전에서 많은 사람들에게 사랑받아 인기브랜드대상까지 수상한 야미코딩이 드디어 정식 버전으로 사람들 앞에 서게 되었습니다.야미코딩이 드디어 정식버전으로 출시되었습니다. 이미 라이트 버전에서 많은 사람들에게 사랑받아 인기브랜드대상까지 수상한 야미코딩이 드디어 정식 버전으로 사람들 앞에 서게 되었습니다.야미코딩이 드디어 정식버전으로 출시되었습니다. 이미 라이트 버전에서 많은 사람들에게 사랑받아 인기브랜드대상까지 수상한 야미코딩이 드디어 정식 버전으로 사람들 앞에 서게 되었습니다.야미코딩이 드디어 정식버전으로 출시되었습니다. 이미 라이트 버전에서 많은 사람들에게 사랑받아 인기브랜드대상까지 수상한 야미코딩이 드디어 정식 버전으로 사람들 앞에 서게 되었습니다.야미코딩이 드디어 정식버전으로 출시되었습니다. 이미 라이트 버전에서 많은 사람들에게 사랑받아 인기브랜드대상까지 수상한 야미코딩이 드디어 정식 버전으로 사람들 앞에 서게 되었습니다.야미코딩이 드디어 정식버전으로 출시되었습니다. 이미 라이트 버전에서 많은 사람들에게 사랑받아 인기브랜드대상까지 수상한 야미코딩이 드디어 정식 버전으로 사람들 앞에 서게 되었습니다.</p>
                            </div>
                        </a>
                        <a href="/NewsContent" className={styles.news_box}>
                            <div className={styles.img_area}>
                                <img src="/assets/3d_img1@2x.png" alt="썸네일"/>
                            </div>
                            <div className={styles.text_area}>
                                <span>야미코딩 정식버전 드디어 출시, 출시 기념 최대 50% 할인 이벤트</span>
                                <p>야미코딩이 드디어 정식버전으로 출시되었습니다. 이미 라이트 버전에서 많은 사람들에게 사랑받아 인기브랜드대상까지 수상한 야미코딩이 드디어 정식 버전으로 사람들 앞에 서게 되었습니다.야미코딩이 드디어 정식버전으로 출시되었습니다. 이미 라이트 버전에서 많은 사람들에게 사랑받아 인기브랜드대상까지 수상한 야미코딩이 드디어 정식 버전으로 사람들 앞에 서게 되었습니다.야미코딩이 드디어 정식버전으로 출시되었습니다. 이미 라이트 버전에서 많은 사람들에게 사랑받아 인기브랜드대상까지 수상한 야미코딩이 드디어 정식 버전으로 사람들 앞에 서게 되었습니다.야미코딩이 드디어 정식버전으로 출시되었습니다. 이미 라이트 버전에서 많은 사람들에게 사랑받아 인기브랜드대상까지 수상한 야미코딩이 드디어 정식 버전으로 사람들 앞에 서게 되었습니다.야미코딩이 드디어 정식버전으로 출시되었습니다. 이미 라이트 버전에서 많은 사람들에게 사랑받아 인기브랜드대상까지 수상한 야미코딩이 드디어 정식 버전으로 사람들 앞에 서게 되었습니다.야미코딩이 드디어 정식버전으로 출시되었습니다. 이미 라이트 버전에서 많은 사람들에게 사랑받아 인기브랜드대상까지 수상한 야미코딩이 드디어 정식 버전으로 사람들 앞에 서게 되었습니다.</p>
                            </div>
                        </a>
                        <a href="/NewsContent" className={styles.news_box}>
                            <div className={styles.img_area}>
                                <img src="/assets/3d_img1@2x.png" alt="썸네일"/>
                            </div>
                            <div className={styles.text_area}>
                                <span>야미코딩 정식버전 드디어 출시, 출시 기념 최대 50% 할인 이벤트</span>
                                <p>야미코딩이 드디어 정식버전으로 출시되었습니다. 이미 라이트 버전에서 많은 사람들에게 사랑받아 인기브랜드대상까지 수상한 야미코딩이 드디어 정식 버전으로 사람들 앞에 서게 되었습니다.야미코딩이 드디어 정식버전으로 출시되었습니다. 이미 라이트 버전에서 많은 사람들에게 사랑받아 인기브랜드대상까지 수상한 야미코딩이 드디어 정식 버전으로 사람들 앞에 서게 되었습니다.야미코딩이 드디어 정식버전으로 출시되었습니다. 이미 라이트 버전에서 많은 사람들에게 사랑받아 인기브랜드대상까지 수상한 야미코딩이 드디어 정식 버전으로 사람들 앞에 서게 되었습니다.야미코딩이 드디어 정식버전으로 출시되었습니다. 이미 라이트 버전에서 많은 사람들에게 사랑받아 인기브랜드대상까지 수상한 야미코딩이 드디어 정식 버전으로 사람들 앞에 서게 되었습니다.야미코딩이 드디어 정식버전으로 출시되었습니다. 이미 라이트 버전에서 많은 사람들에게 사랑받아 인기브랜드대상까지 수상한 야미코딩이 드디어 정식 버전으로 사람들 앞에 서게 되었습니다.야미코딩이 드디어 정식버전으로 출시되었습니다. 이미 라이트 버전에서 많은 사람들에게 사랑받아 인기브랜드대상까지 수상한 야미코딩이 드디어 정식 버전으로 사람들 앞에 서게 되었습니다.</p>
                            </div>
                        </a>
                        <a href="/NewsContent" className={styles.news_box}>
                            <div className={styles.img_area}>
                                <img src="/assets/3d_img1@2x.png" alt="썸네일"/>
                            </div>
                            <div className={styles.text_area}>
                                <span>야미코딩 정식버전 드디어 출시, 출시 기념 최대 50% 할인 이벤트</span>
                                <p>야미코딩이 드디어 정식버전으로 출시되었습니다. 이미 라이트 버전에서 많은 사람들에게 사랑받아 인기브랜드대상까지 수상한 야미코딩이 드디어 정식 버전으로 사람들 앞에 서게 되었습니다.야미코딩이 드디어 정식버전으로 출시되었습니다. 이미 라이트 버전에서 많은 사람들에게 사랑받아 인기브랜드대상까지 수상한 야미코딩이 드디어 정식 버전으로 사람들 앞에 서게 되었습니다.야미코딩이 드디어 정식버전으로 출시되었습니다. 이미 라이트 버전에서 많은 사람들에게 사랑받아 인기브랜드대상까지 수상한 야미코딩이 드디어 정식 버전으로 사람들 앞에 서게 되었습니다.야미코딩이 드디어 정식버전으로 출시되었습니다. 이미 라이트 버전에서 많은 사람들에게 사랑받아 인기브랜드대상까지 수상한 야미코딩이 드디어 정식 버전으로 사람들 앞에 서게 되었습니다.야미코딩이 드디어 정식버전으로 출시되었습니다. 이미 라이트 버전에서 많은 사람들에게 사랑받아 인기브랜드대상까지 수상한 야미코딩이 드디어 정식 버전으로 사람들 앞에 서게 되었습니다.야미코딩이 드디어 정식버전으로 출시되었습니다. 이미 라이트 버전에서 많은 사람들에게 사랑받아 인기브랜드대상까지 수상한 야미코딩이 드디어 정식 버전으로 사람들 앞에 서게 되었습니다.</p>
                            </div>
                        </a> */}
                    </div>
                    {/* <button type="button" className={styles.list_more_btn}>+ 더보기</button>
                    <ul className={styles.list_pagebtn_area}>
                        <li className={`${styles.arrow_btn} ${styles.first}`}><a href="#"><img src="/assets/first_btn@2x.png" alt="첫번째 페이지로 이동"/></a></li>
                        <li className={`${styles.arrow_btn} ${styles.prev}`}><a href="#"><img src="/assets/prev_btn@2x.png" alt="이전 페이지로 이동"/></a></li>
                        <li className={styles.on}><a href="#">1</a></li>
                        <li><a href="#">2</a></li>
                        <li><a href="#">3</a></li>
                        <li><a href="#">4</a></li>
                        <li><a href="#">5</a></li>
                        <li><a href="#">6</a></li>
                        <li><a href="#">7</a></li>
                        <li><a href="#">8</a></li>
                        <li><a href="#">9</a></li>
                        <li><a href="#">10</a></li>
                        <li className={`${styles.arrow_btn} ${styles.next}`}><a href="#"><img src="/assets/next_btn@2x.png" alt="다음 페이지로 이동"/></a></li>
                        <li className={`${styles.arrow_btn} ${styles.last}`}><a href="#"><img src="/assets/last_btn@2x.png" alt="마지막 페이지로 이동"/></a></li>
                    </ul> */}
                </div>
            </div>
		</div>{/* //news_area */}
		
		{/* Footer 분리됨 MainLayout Footer에 있음 */}
    </>
  );
}