import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import styles from "../../css/common.module.css";
import $ from 'jquery';

import { studentList } from "../../actions/student";
import { chartClassAvgStudyTime, chartClassLastLogin, chartClassStudentCount, chartClassAgeDiv, chartClassAgeRange } from "../../actions/chart";
import { groupUserClassInfo } from "../../actions/users";

import * as common from "../../lib";

import ClassDataTable from './ClassDataTable';
import ClassDataTable2 from './ClassDataTable2';
import ClassAddDialog from './ClassAddDialog';

import ClassChart1 from './ClassChart1';
import ClassChart2 from './ClassChart2';
import ClassChart3 from './ClassChart3';
import ClassChart4 from './ClassChart4';
import ClassChart5 from './ClassChart5';

import PackageInfoDialog from './PackageInfoDialog';

import { useTranslation, Trans } from 'react-i18next'; // 1. react-i18next import 
import { useLocation } from 'react-router-dom';
import { now } from 'moment';

export default function GroupMypageReport() {

	const [mstIdx, setMstIdx] = useState(0);
	const [startDate, setStartDate] = useState(0);
	const [lastDate, setLastDate] = useState(0);
	const [abilityObject, setAbilityObject] = useState(null);

	const dispatch = useDispatch();	
	const location = useLocation();

	const { t, i18n } = useTranslation(); // 3. useTranslation hook 선언

	const authState = useSelector(state => state.auth);
    // console.log("authState : ", authState);

	const chartClassAvgStudyTimeData = useSelector(state => state.chart.chartClassAvgStudyTime);
	const chartClassLastLoginData = useSelector(state => state.chart.chartClassLastLogin);
	const chartClassStudentCountData = useSelector(state => state.chart.chartClassStudentCount);
	const chartClassAgeDivData = useSelector(state => state.chart.chartClassAgeDiv);
	const chartClassAgeRangeData = useSelector(state => state.chart.chartClassAgeRange);
	const userInfo = useSelector(state => state.users.info);

	// console.log("chartClassAvgStudyTimeData : ", chartClassAvgStudyTimeData);
	// console.log("chartClassLastLoginData : ", chartClassLastLoginData);
	// console.log("chartClassStudentCountData : ", chartClassStudentCountData);
	// console.log("chartClassAgeDivData : ", chartClassAgeDivData);
	// console.log("chartClassAgeRangeData : ", chartClassAgeRangeData);
	// console.log("userInfoData : ", userInfo);

	const mypage_tab_btn_areaRef =  useRef();
    const hover_bgRef =  useRef();
    const onRef =  useRef();
	const my_report_cognitive_ability_areaRef = useRef();

	const print_areaRef = useRef();

	let classDataTablePageNumber = location?.state?.prePage;

	// console.log("classDataTablePageNumber : ", classDataTablePageNumber);

	if (classDataTablePageNumber === undefined)
		classDataTablePageNumber = 1;

	useEffect(() => {

		// 여기에서 jQuery를 초기화 합니다.
		// jquery ready 호출. jquery3 부터 변경됨
		$(function(){
			// console.log("jquery ready!");

            //마이페이지 탭 버튼(활성화 효과)
			$(`.${styles.mypage_tab_btn_area} li`).on('click',function()
			{
				let tg=$(this);
				
				$(`.${styles.mypage_tab_btn_area} li`).removeClass(`${styles.on}`);
				tg.addClass(`${styles.on}`);
			});
            
            //마이페이지 탭 버튼(후버 효과)
            $(window).resize(function()
			{
				if (window.innerWidth < 1200) 
				{ 
					$(`.${styles.mypage_tab_btn_area} li.${styles.hover_bg}`).css('display','none');
				}
				else
				{
					let nowbtnNum=$(`.${styles.mypage_tab_btn_area} li.${styles.on}`).index();
					let hoverBg=$(`.${styles.mypage_tab_btn_area} li.${styles.hover_bg}`);

					$(`.${styles.mypage_tab_btn_area} ul`).on('mouseenter',function()
					{
						
						$(`.${styles.mypage_tab_btn_area} li`).removeClass(`${styles.on}`);
						hoverBg.css('display','block');
						
						$(`.${styles.mypage_tab_btn_area} li`).on('mouseenter',function()
						{
							let tgIndex=$(this).index();
							
							if(tgIndex===1)
							{
								hoverBg.stop().css('left','167px').css('width','116px');
							}
							else if(tgIndex===2)
							{
								hoverBg.stop().css('left','300px').css('width','150px');
							}
							else if(tgIndex===3)
							{
								hoverBg.stop().css('left','467px').css('width','116px');
							}
							else if(tgIndex===4)
							{
								hoverBg.stop().css('left','624px').css('width','102px');
							}
							else if(tgIndex===5)
							{
								hoverBg.stop().css('left','774px').css('width','102px');
							}
							else if(tgIndex===6)
							{
								hoverBg.stop().css('left','924px').css('width','102px');
							};
						});
						
					}).on('mouseleave',function()
					{
						$(`.${styles.mypage_tab_btn_area} li`).eq(nowbtnNum).addClass(`${styles.on}`);
						hoverBg.css('display','none');
						
						if(nowbtnNum===0)
						{
							hoverBg.css({left:'167px'}).css({width:'116px'});
						}
						else if(nowbtnNum===1)
						{
							hoverBg.css({left:'300px'}).css({width:'150px'});
						}
						else if(nowbtnNum===2)
						{
							hoverBg.css({left:'467px'}).css({width:'116px'});
						}
						else if(nowbtnNum===3)
						{
							hoverBg.css({left:'624px'}).css({width:'102px'});
						}
						else if(nowbtnNum===4)
						{
							hoverBg.css({left:'774px'}).css({width:'102px'});
						}
						else if(nowbtnNum===5)
						{
							hoverBg.css({left:'924px'}).css({width:'102px'});
						};

					});
					
				}
			}).resize();

		});

		// 차트 데이터들
		dispatch(chartClassAvgStudyTime(authState.user.idx))
        .then(data => 
        {
            // console.log("chartClassAvgStudyTime data : ", data);
        })
        .catch(e => {
            console.log(e);
        });

		dispatch(chartClassLastLogin(authState.user.idx))
        .then(data => 
        {
            // console.log("chartClassLastLogin data : ", data);
        })
        .catch(e => {
            console.log(e);
        });

		dispatch(chartClassStudentCount(authState.user.idx))
        .then(data => 
        {
            // console.log("chartClassStudentCount data : ", data);
        })
        .catch(e => {
            console.log(e);
        });

		dispatch(chartClassAgeDiv(authState.user.idx))
        .then(data => 
        {
            // console.log("chartClassAgeDiv data : ", data);
        })
        .catch(e => {
            console.log(e);
        });

		dispatch(chartClassAgeRange(authState.user.idx))
        .then(data => 
        {
            // console.log("chartClassAgeRange data : ", data);
        })
        .catch(e => {
            console.log(e);
        });

		// 학생 리스트 불러오기
        dispatch(studentList())
        .then(data => 
        {
            // console.log("studentList data : ", data);
			// 학생의 개수를 체크해서 하나도 없으면 페이지를 이동합니다.

			if (data.length === 0)
			{
				// alert("마이페이지를 이용하실려면 최소 1명 이상의 학습자가 필요합니다.");
				alert(`${t("GroupMypageReport.alert.string1")}`);
				window.location.href = "/GroupUserMypage/GroupMypageStudentAdd";
			}
        })
        .catch(e => {
            console.log(e);
        });

		dispatch(groupUserClassInfo(authState.user.idx))
		.then(data => 
		{
			// console.log("data : ", data);
		})
		.catch(e => {
			console.log(e);
		});

	}, [$]);

	const onReadyClick = () => 
	{
		// alert("서비스 중비중입니다.");
		alert(`${t("Common.service_wait")}`);
	};
	
  return (
    <>
      	{/* Header 분리됨 MainLayout Header에 있음 */}
        <div className={styles.mypage_top_line_banner}>
			<div className={styles.inner}>
				<span>{/* 관리자 페이지 */}{t('Common.Mypage_title2')}</span>
			</div>
		</div>
		
		<div className={styles.mypage_tab_btn_area} ref={mypage_tab_btn_areaRef}>
			<div className={styles.inner}>
				<ul>
					<li className={styles.hover_bg} ref={hover_bgRef}></li>
					<li className={styles.on} ref={onRef}><a href="/GroupUserMypage/GroupReport">{/* 클래스 관리 */}{t('Common.Group_SubMenu.menu1')}</a></li>
					<li><a href="/GroupUserMypage/GroupParentsInfo">{/* 관리자 정보 */}{t('Common.Group_SubMenu.menu2')}</a></li>
					<li><a href="/GroupUserMypage/GroupPayInfo">{/* 결제 관리 */}{t('Common.Group_SubMenu.menu3')}</a></li>
					<li><a href="/GroupUserMypage/GroupCouponInfo">{/* 쿠폰관리 */}{t('Common.Group_SubMenu.menu4')}</a></li>
					{/* <li><a href="/GroupUserMypage/GroupStudentInfo">공지사항</a></li>
					<li><a href="/GroupUserMypage/GroupQnaInfo">질문과 답변</a></li> */}
					<li><a href="#!" onClick={()=>onReadyClick()}>{/* 공지사항 */}{t('Common.Group_SubMenu.menu5')}</a></li>
					<li><a href="#!" onClick={()=>onReadyClick()}>{/* 질문과 답변 */}{t('Common.Group_SubMenu.menu6')}</a></li>
				</ul>
			</div>
		</div>
		<div className={`${styles.mypage_tab} ${styles.mypage_report}`} ref={print_areaRef}>
			<div className={styles.inner}>
				<div className={styles.my_report_top_btn_area}>
					<ClassAddDialog />
				</div>
				<span className={styles.my_tit}>{/* 클래스 관리 */}{t('GroupMypageReport.title')}</span>
				<p className={styles.for_freeuser_info}>{/* * 무료체험은 데이터가 없어 학습 리포트의 지표가 0으로 표시됩니다. */}{t('GroupMypageReport.string1')}</p>
				<div className={styles.my_report_bottom_area}>
					 <div className={styles.head}>
						<span>{/* 클래스 리스트 */}{t('GroupMypageReport.string2')}</span>
						{/* <p>1개의 클래스</p> */}
						<p>{/* {userInfo?.classInfo?.length || 0}개의 클래스 */} 
						{t("GroupMypageReport.string13", {count : userInfo?.classInfo?.length || 0})} 
						/ 
						{t("GroupMypageReport.string14", {count : userInfo?.productInfo?.filter(row => row?.mm_state === 'W' && row?.mm_state_cd === 'A').length || 0})}
						{/* 사용 가능한 이용권 {userInfo?.productInfo?.filter(row => row?.mm_state === 'W' && row?.mm_state_cd === 'A').length || 0}개 */} </p>
						<PackageInfoDialog productInfo={userInfo?.productInfo} />
					 </div>
					 <div className={styles.wrap}>
					 	<ClassDataTable classDataTablePageNumber={classDataTablePageNumber} />
					 </div>
				</div>
				<div className={styles.my_chart_missonprogress_area}>
					 <div className={styles.head}>
						<span>{/* 최근 로그인 및 클래스별 학생수 */}{t('GroupMypageReport.string3')}</span>
						{/* <p>쓰게 되면 쓰고</p> */}
					 </div>
					 <div className={styles.wrap}>
						{/* <div className={styles.misson_progress}>
							<div className={styles.misson_class_txt}>
								<span className={styles.misson_class_name}>클래스 평균 학습시간</span>
								<p>클래스별 학생 수를 표시하며 최대 5개까지 표시합니다.</p>
							</div>
							<div>
								<ClassChart1 />
							</div>
						</div> */}
						<div className={styles.misson_progress}>
							<div className={styles.misson_class_txt}>
								<span className={styles.misson_class_name}>{/* 최근 로그인 */}{t('GroupMypageReport.string4')}</span>
								<p>{/* 가장 최근에 로그인한 6개의 학습자를 표시합니다. */}{t('GroupMypageReport.string5')}</p>
							</div>
							<div>
								<ClassDataTable2 RowData={chartClassLastLoginData} />
							</div>
						</div>
						<div className={styles.misson_progress}>
							<div className={styles.misson_class_txt}>
								<span className={styles.misson_class_name}>{/* 클래스별 학생수 */}{t('GroupMypageReport.string6')}</span>
								<p>{/* 클래스별 학생 수를 표시하며 최대 5개까지 표시합니다. */}{t('GroupMypageReport.string7')}</p>
							</div>
							<div>
								<ClassChart2 RowData={chartClassStudentCountData} />
							</div>
						</div>
					 </div>
				</div>
				<div className={styles.my_chart_missonprogress_area}>
					 <div className={styles.head}>
						<span>{/* 클래스 실시간 현황 */}{t('GroupMypageReport.string8')}</span>
						{/* <p>쓰게 되면 쓰고</p> */}
					 </div>
					 <div className={styles.wrap}>
						<div className={styles.misson_progress}>
							<div className={styles.misson_class_txt}>
								<span className={styles.misson_class_name}>{/* 성별 비율 */}{t('GroupMypageReport.string9')}</span>
								<p>{/* 클래스 전체의 성별 비율입니다. */}{t('GroupMypageReport.string10')}</p>
							</div>
							<div>
								<ClassChart3  RowData={chartClassAgeDivData} />
							</div>
						</div>
						<div className={styles.misson_progress}>
							<div className={styles.misson_class_txt}>
								<span className={styles.misson_class_name}>{/* 연령별 학생수 */}{t('GroupMypageReport.string11')}</span>
								<p>{/* 연령별 학생의 수입니다. */}{t('GroupMypageReport.string12')}</p>
							</div>
							<div>
								<ClassChart4  RowData={chartClassAgeRangeData} />
							</div>
						</div>
					 </div>{/* wrap */}
				</div>{/* my_report_cognitive_ability_area */}
				
				{/* <div className={styles.my_chart_missonprogress_area}>
					 <div className={styles.head}>
						<span>클래스 실시간 현황</span>
							<p>쓰게 되면 쓰고</p>
					 </div>
					 <div className={styles.wrap}>
						<div className={styles.misson_progress}>
							<div className={styles.misson_class_txt}>
								<span className={styles.misson_class_name}>클래스별 평균 진도</span>
								<p>클래스별 학생 수를 표시하며 최대 5개까지 표시합니다.</p>
							</div>
							<div>
								<ClassChart5 />
							</div>
						</div>
						<div className={styles.misson_progress}>
							<div className={styles.misson_class_txt}>
								<span className={styles.misson_class_name}>최근 로그인</span>
								<p>가장 최근에 로그인한 5개의 학습자를 표시합니다.</p>
							</div>
							<div>
								<ClassDataTable2 />
							</div>
						</div>
					 </div>
				</div> */}
			</div>{/* inner */}
		</div>
		
		{/* Footer 분리됨 MainLayout Footer에 있음 */}
    </>
  );
}