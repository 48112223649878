import {
  CREATE_GROUP_ORDER,
  LIST_GROUP_ORDER,
  CANCEL_GROUP_ORDER,
  SELECT_GROUP_ORDER,
} from "./types";

import GroupOrderService from "../services/GroupOrderService";

export const groupOrderCreate = (data) => async (dispatch) => {
  
  try {
    
    const res = await GroupOrderService.groupOrderCreate(data);

    // console.log("res : ", res);

    dispatch({
      type: CREATE_GROUP_ORDER,
      payload: res.data,
    });

    return Promise.resolve(res.data);

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};

export const groupOrderList = () => async (dispatch) => {
  
  try {

    const res = await GroupOrderService.groupOrderGetAll();

    // console.log("res : ", res);

    dispatch({
      type: LIST_GROUP_ORDER,
      payload: res.data.data,
    });

    return Promise.resolve(res.data.data);

  } catch (err) {
    // console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};

export const groupOrderSelect = (ord_idx) => async (dispatch) => {
  
  try {
    
    const res = await GroupOrderService.groupOrderSelect(ord_idx);

    // console.log("res : ", res);

    dispatch({
      type: SELECT_GROUP_ORDER,
      payload: res.data.data,
    });

    return Promise.resolve(res.data.data);

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};

export const groupOrderCancel = (ord_idx) => async (dispatch) => {
  
  try {

    // console.log("orderCancel! ord_idx : ", ord_idx);

    const res = await GroupOrderService.groupOrderCancel(ord_idx);

    // console.log("res : ", res);

    if (res.data.success === false)
    {
      // alert(res.data.msg);
      return Promise.resolve(ord_idx);
    }
    else
    {
      dispatch({
        type: CANCEL_GROUP_ORDER,
        payload: { ord_idx },
      });
  
      return Promise.resolve(res.data);
    }

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};

