import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import PersonIcon from '@mui/icons-material/Person';
import { blue } from '@mui/material/colors';
import UserTypeChipsArray from './UserTypeChipsArray';
import Box from '@mui/material/Box';
import { useEffect, useState } from 'react';

const userTypes = ['관리자', '일반', '소셜', '단체'];

function SimpleDialog(props) {
  const { onClose, selectedValue, open } = props;

  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = (value) => {
    onClose(value);
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>Set backup account</DialogTitle>
      <List sx={{ pt: 0 }}>
        {userTypes.map((userType, index) => (
          <ListItem button onClick={() => handleListItemClick(userType)} key={index}>
            <ListItemAvatar>
              <Avatar sx={{ bgcolor: blue[100], color: blue[600] }}>
                <PersonIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={userType} />
          </ListItem>
        ))}
      </List>
    </Dialog>
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
};

export default function SimpleDialogDemo({category, setCategory, chipData, setChipData}) {

  const [open, setOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState(userTypes[1]);
  
//   const [chipData, setChipData] = useState([
//     { key: 0, label: '사용자 유형' },
//   ]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
    setSelectedValue(value);
    addChipData(value);    
  };

  const dataMake = (value) => {
    setCategory({ ...category, ["category_users"]: value});
  }

  const handleDelete = (chipToDelete) => () => {
    setChipData((chips) => chips.filter((chip) => chip.key !== chipToDelete.key));
  };

  const addChipData = (userType) => {
    console.log(userType);

    let string1 = chipData.find(x => x.label === userType);
    
    if (string1 !== undefined)
    {
        alert("이미 추가되었습니다.");
        return;
    }
    setChipData((chips) => (chips.concat({key: userType, label: userType})));
  }

    useEffect(() => {

        dataMake(chipData);
            
        return () => {      // 언마운트
            console.log("언마운트!");
        };

    }, [chipData])

  return (
    <div>
      <Button size="small" variant="contained" sx={{marginLeft:1, marginTop:2}} onClick={handleClickOpen}>
        사용자 유형 설정
      </Button>
      <br />
      <Box
        sx={{
            '& > :not(style)': { m: 1, width: '500px' },
        }}
        noValidate
        autoComplete="off"
        >
            <UserTypeChipsArray chipData={chipData} handleDelete={handleDelete} />
      </Box>
      <SimpleDialog
        selectedValue={selectedValue}
        open={open}
        onClose={handleClose}
      />
    </div>
  );
}