import http from "../http-common";

const inappProductCreate = data => {
  
  //전송할 데이터에 파일이 있을경우
  const config = {
    headers : {
        'context-type' : 'multipart/form-data'
    }
  }

  return http.post("payment/inappProduct", data, config);
};

const inappProductGetAll = () => {
  return http.get("payment/inappProduct");
};

const inappProductDelete = data => {
  return http.delete("payment/inappProduct", {data : {prd_idx : data}});
};

const inappProductModify = data => {

  //전송할 데이터에 파일이 있을경우
  const config = {
    headers : {
        'context-type' : 'multipart/form-data'
    }
  }

  return http.put("payment/inappProduct", data, config);
};

const inappProductSelect = data => {
  return http.get(`payment/inappProduct/${data}`);
};

const InappProductService = {
  inappProductCreate,
  inappProductGetAll,
  inappProductDelete,
  inappProductModify,
  inappProductSelect,
};

export default InappProductService;
