import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

import Divider from '@mui/material/Divider';

import UploadFileIcon from '@mui/icons-material/UploadFile';

import { useCallback, useState } from 'react';
import * as XLSX from "xlsx";

import { useTranslation, Trans } from 'react-i18next'; // 1. react-i18next import 

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import StudentExcelPackageSelectDialog from './StudentExcelPackageSelectDialog';
import StudentExcelUploadErrorDialog from './StudentExcelUploadErrorDialog';

import { useDispatch, useSelector } from "react-redux";
import { studentAddsCreate, studentIdCheck, newClassStudentList } from "../../actions/student";
import { packageInfoList } from "../../actions/package";

import YameLoading from '../../common/YameLoding';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function StudentExcelUploadDialog(props) {
  const [open, setOpen] = React.useState(false);
  const [idCheck, setIdCheck] = React.useState(false);	// 엑셀 데이터 내에서 서로 중복된 아이디가 있는지 체크하는 변수입니다.

  const [display, setDisplay] = useState(false); 
  const [loading, setLoading] = useState(false);

  // 강제로 재렌더링을 하게 하는 방법
  const [, updateState] = useState();	// 상태변수는 선언하지 않는다.
  const forceUpdate = useCallback(() => updateState({}), []);

  const { t, i18n } = useTranslation(); // 3. useTranslation hook 선언
  const dispatch = useDispatch();	

  const authState = useSelector(state => state.auth);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
	setUploadedFile(null);
  };

  const [uploadedFile, setUploadedFile] = useState(null);

  const handleDrop = useCallback(async (acceptedFiles, event) => {

	// console.log("loading....1");
	setDisplay(true);
    
	if (acceptedFiles.length > 0) 
	{
		// console.log("loading....2");
		const file = acceptedFiles[0];
		const reader = new FileReader();

		reader.readAsArrayBuffer(file);

		reader.onload = async (e) => 
		{
			// console.log("loading....3");
			const data = new Uint8Array(e.target.result);
			const workbook = XLSX.read(data, { type: "array", bookVBA: true });

			const sheetName = workbook.SheetNames[0];
			const sheet = workbook.Sheets[sheetName];
			const jsonData = XLSX.utils.sheet_to_json(sheet);

			// 정규 표현식으로 아이디 생성 규칙에 맞는지 체크합니다.
			const regId =  /^[A-Za-z0-9+]{2,50}$/;

			// 정규 표현식으로 비밀번호 생성 규칙에 맞는지 체크합니다.
			const regPassword = new RegExp(/(?=.*\d{1,20})(?=.*[~`!@#$%\^&*()-+=]{1,20})(?=.*[a-zA-Z]{1,20}).{8,20}$/);

			// 정규 표현식 4자리 숫자
			const regYear =  /^\d{4}$/;

			// 아이디의 중복을 체크하기 위해서 별도의 배열을 선언해서 저장합니다.
			let arrayID = [];

			// 칼럼의 이름이 정상적으로 불려왔는지 체크하는 변수
			let successSheetName = false;

			// console.log("sheet : ", sheet?.A1?.h);
			// console.log(jsonData);

			if (sheet?.A1?.h === "number" && sheet?.B1?.h === "ID" && sheet?.C1?.h === "Name" && sheet?.D1?.h === "Nickname" && sheet?.E1?.h === "year_of_birth" && sheet?.F1?.h === "Gender" && sheet?.G1?.h === "Password")
				successSheetName = true;

			if (successSheetName)	// 칼럼 이름이 정확해야지 데이터를 불러옵니다.
			{
				// 이용권을 빈 값으로 넣어줌
				for(let i=0; i<jsonData.length; i++)
				{
					let errorMessage = "";
					jsonData[i].Ticket = {name:"", mm_idx:0};
					jsonData[i].errorMsg = "";

					arrayID[i] = jsonData[i].ID;

					// 여기에서 검사함

					// 값이 누락되었는지 체크함
					// 값이 규칙에 맞는지 체크함

					if('ID' in jsonData[i] === false)
						errorMessage += t('ClassStudentCommonDlg.alert.string20');
						// errorMessage += "- 아이디가 없습니다.\n";
					else
					{
						// 아이디가 규칙에 맞는지 검사해서 규칙에 맞지 않으면 경고메세지를 뿌립니다.
						if(regId.test(jsonData[i].ID) === false)
							errorMessage += t('ClassStudentCommonDlg.alert.string21');
							// errorMessage += "- 아이디는 숫자와 영문으로만 가능합니다.\n";
					}

					if('Name' in jsonData[i] === false)
						errorMessage += t('ClassStudentCommonDlg.alert.string22');
						// errorMessage += "- 이름이 없습니다.\n";
					else
					{
						// 이름의 길이 제한
						if (jsonData[i].Nickname.length > 51)
							errorMessage += t('ClassStudentCommonDlg.alert.string23');
							// errorMessage += "- 이름은 50자리까지 입니다.\n";
					}

					if('Nickname' in jsonData[i] === false)
						errorMessage += t('ClassStudentCommonDlg.alert.string24');
						// errorMessage += "- 닉네임이 없습니다.\n";
					else
					{
						// 닉네임의 길이 제한
						if (jsonData[i].Nickname.length > 8)
							errorMessage += t('ClassStudentCommonDlg.alert.string25');
							// errorMessage += "- 닉네임은 7자리까지 입니다.\n";
					}

					if('year_of_birth' in jsonData[i] === false)
						errorMessage += t('ClassStudentCommonDlg.alert.string26');
						// errorMessage += "- 생년이 없습니다.\n";
					else
					{
						// 년도 데이터는 숫자 4자리입니다.
						if(regYear.test(jsonData[i].year_of_birth) === false)
							errorMessage += t('ClassStudentCommonDlg.alert.string27');	
							// errorMessage += "- 생년은 4자리 숫자입니다.\n";
					}

					if('Gender' in jsonData[i] === false)
						errorMessage += t('ClassStudentCommonDlg.alert.string28');
						// errorMessage += "- 성별이 없습니다.\n";
					else
					{
						// 성별 데이터가 맞는지 검사합니다.
						if (jsonData[i].Gender !== "male" && jsonData[i].Gender !== "female")
							errorMessage += t('ClassStudentCommonDlg.alert.string29');
							// errorMessage += "- 성별 데이터가 틀립니다.\n";
					}

					if('Password' in jsonData[i] === false)
						errorMessage += t('ClassStudentCommonDlg.alert.string30');
						// errorMessage += "- 패스워드가 없습니다.\n";
					else
					{
						// 패스워드가 규칙에 맞아야 합니다.
						if( !regPassword.test(jsonData[i].Password) ) 
							errorMessage += t('ClassStudentCommonDlg.alert.string31');
							// errorMessage += "- 패스워드가 규칙에 맞지 않습니다.\n";
					}

					jsonData[i].errorMsg = errorMessage;
					jsonData[i].duplication = 1;			// 중복체크값 기본 1, 체크 안됨, 0으로 만들어야지 사라짐
					
				}

				// 아이디의 중복을 체크합니다.
				for(let i = 0; i < arrayID.length; i++) 
				{
					const currElem = arrayID[i];
					
					for(let j = i+1; j < arrayID.length; j++) 
					{
						if(currElem === arrayID[j])
						{
							setIdCheck(true);
							break;
						}
					}
				}
				// console.log(file);
				setUploadedFile({ file, jsonData });

				event.target.value = "";	// value 초가화를 하면 같은 파일도 재업로드 시 다시 인식한다.

				setLoading(true);
			}
			else
			{
				setLoading(true);
				// return alert(`엑셀 데이터가 샘플과 맞지 않습니다. 엑셀 샘플 데이터를 다운받으셔서 작성해주세요.`);
				return alert(`${t("ClassStudentCommonDlg.alert.string37")}`);
			}

			setDisplay(false);
			setLoading(false);
			
		};
    }

  }, []);

//   const handleLicenseChange = (event, index) => {

// 	event.preventDefault();

// 	let jsonData = uploadedFile.jsonData;
// 	let file = uploadedFile.file;
// 	jsonData[index].Ticket = {name:"샘플", mm_idx:1111};

// 	setUploadedFile({ file, jsonData });
//   }

//   const handleErrorConfirm = (event, index) => {

// 	event.preventDefault();

// 	let jsonData = uploadedFile.jsonData;
// 	console.log(jsonData[index].errorMsg);
//   }


  const handleIDCheck = (event, index) => {

	event.preventDefault();

	let jsonData = uploadedFile.jsonData;
	let file = uploadedFile.file;

	if(jsonData[index].ID === undefined)
		return alert(`${t("ClassStudentCommonDlg.alert.string32")}`);
		// return alert(`빈 값은 중복체크가 안됩니다.`);

	dispatch(studentIdCheck(jsonData[index].ID))
	.then(data => 
	{
		// console.log("data : ", data);
		if(!data.success)
		{
			jsonData[index].duplication = 0;	
			forceUpdate();			// 강제로 화면을 다시 렌더링시킵니다. 그렇지 않으면 두번을 눌러야 반영이 됩니다.
		}
		else
		{
			return alert(`${t("ClassStudentCommonDlg.alert.string17", {id : jsonData[index].ID})}`);
			// return alert(`{{id}}은 중복된 이름입니다. 다른 이름을 사용하세요.`);
		}
	})
	.catch(e => {
		console.log(e);
	});

	// setUploadedFile({ file, jsonData });
  }

  const handleStudentMake = (event) => {

	event.preventDefault();

	let errorCount = 0;
	let errorCount2 = 0;
	let errorCount3 = 0;

	for (let i=0; i<uploadedFile.jsonData.length; i++)
	{
		if (uploadedFile.jsonData[i].errorMsg !== "")
			errorCount++;
	}

	for (let i=0; i<uploadedFile.jsonData.length; i++)
	{
		if (uploadedFile.jsonData[i].Ticket.mm_idx === 0)
			errorCount2++;
	}

	for (let i=0; i<uploadedFile.jsonData.length; i++)
	{
		if (uploadedFile.jsonData[i].duplication === 1)
			errorCount3++;
	}

	// console.log(errorCount);

	if (errorCount !== 0)
		return alert(`${t("ClassStudentCommonDlg.alert.string33")}`);
		// return alert("엑셀 데이터의 오류를 수정해서 다시 업로드해주세요!");

	if (errorCount2 !== 0)
		return alert(`${t("ClassStudentCommonDlg.alert.string34")}`);
		// return alert("이용권이 선택되지 않는 아이디가 있습니다!");

	if (errorCount3 !== 0)
		return alert(`${t("ClassStudentCommonDlg.alert.string35")}`);
		// return alert("중복확인되지 않는 아이디가 있습니다!");

	if (idCheck)
		return alert(`${t("ClassStudentCommonDlg.alert.string36")}`);
		// return alert("엑셀 데이터 내의 아이디가 중복되었습니다. 수정해서 다시 업로드해주세요!");

	// console.log(uploadedFile.jsonData);

	let memIdx = 0;

	if (authState.isLoggedIn)
	{
		memIdx = authState.user.idx;
	}
	else
	{
		// return alert("로그인이 필요합니다.");
		return alert(`${t("ClassStudentCommonDlg.alert.string13")}`);
	}

	// 전송하기 위한 데이터로 변환합니다.
	let sendData = [];
	
	for (let i=0; i<uploadedFile.jsonData.length; i++)
	{
		sendData[i] = {
			selProduct: uploadedFile.jsonData[i].Ticket.mm_idx,
			mem_idx: memIdx,
			id: uploadedFile.jsonData[i].ID,
			name: uploadedFile.jsonData[i].Name,
			nickname: uploadedFile.jsonData[i].Nickname,
			birthday: uploadedFile.jsonData[i].year_of_birth + "-01-01",
			gender: uploadedFile.jsonData[i].Gender === "male" ? 1 : 2,
			password: uploadedFile.jsonData[i].Password,
			email: "",
			mc_idx: props.mcIdx
		};
	}

	setDisplay(true);

	dispatch(studentAddsCreate(sendData))
	.then(data => 
	{
		// console.log("studentAddsCreate data : ", data);
		// 사용가능한 이용권의 갯수
		if (!data.success)
		{
			setLoading(true);
			return alert(data.msg);
		}
		else
		{
			const boardListSend = {
				board_page : 1,
				mc_idx: props.mcIdx,
				}

			// 패키지의 정보를 다시 불러옵니다.
			dispatch(packageInfoList())
			.then(data => 
			{
				// console.log("packageInfoList data : ", data);
				// 학습자 리스트를 다시 불러옵니다.
				dispatch(newClassStudentList(boardListSend))
				.then(data => 
				{
					// console.log("classListAll : ", data);
					
					setLoading(true);
					// alert("학습자 추가가 완료되었습니다. 즐거운 플레이 되세요~");
					alert(`${t("ClassStudentCommonDlg.alert.string14")}`);
					handleClose();
				})
				.catch(e => {
					console.log(e);
				});
			})
			.catch(e => {
				console.log(e);
			});
		}
	})
	.catch(e => {
		console.log(e);
	});
  }

const PackageDataTransFuntion = (value, index) => {
    // console.log(value);
	// console.log(index);
	
	let jsonData = uploadedFile.jsonData;
	let file = uploadedFile.file;

	jsonData[index].Ticket = {name:value.mm_name, mm_idx:value.mm_idx};

	setUploadedFile({ file, jsonData });
	// console.log(jsonData);
}

  return (
	<>
	{/* 로딩화면 */}
	<YameLoading display={display} loadingValue={loading} />

    <div>
      <Button variant="outlined" endIcon={<UploadFileIcon />} onClick={handleClickOpen}>{/* 학습자 엑셀로 업로드 */}{t('ClassStudentCommonDlg.string57')}</Button>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
		maxWidth="lg"
		fullWidth={true}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
          {/* 학습자를 엑셀로 업로드해서 만들기 */}{t('ClassStudentCommonDlg.string45')}
		  <Typography gutterBottom sx={{fontSize:15, marginTop: 2}}>
		  {/* * 작업 순서 : 팝업창 규칙 읽기 -> 엑셀 샘플 파일 다운로드 -> 엑셀 작성 -> 업로드 -> 아이디 중복 체크 -> 이용권 선택 -> 만들기 (오류 발생시 안내를 참조해서 수정해서 재 업로드) */}{t('ClassStudentCommonDlg.string46')}<br></br>
		  <a href="/assets/student_upload_sample.xlsx" download style={{fontSize:'14px', fontWeight:800, color:'#FF4500'}}>{/* [업로드 엑셀 샘플 다운로드] */}{t('ClassStudentCommonDlg.string47')}</a><br></br><br></br>
		  {/* * 규칙1. 아이디는 영문과 숫자의 조합으로 가능합니다. */}{t('ClassStudentCommonDlg.string48')}<br></br>
		  {/* * 규칙2. 이름과 닉네임 어떤 문자도 괜찮습니다 다만 이름은 50자, 닉네임은 7자로 제한이 있습니다. */}{t('ClassStudentCommonDlg.string49')} <br></br>
		  {/* * 규칙3. 비밀번호 생성 규칙은 영문, 숫자, 특수문자 포함 8자 이상 20자가지 입니다. */}{t('ClassStudentCommonDlg.string50')}  <br></br>
		  {/* * 규칙4. 출생년도(년도만 예 : 2001), 성별, 비밀번호는 반드시 입력해주세요. */}{t('ClassStudentCommonDlg.string51')} <br></br>
		  {/* * 규칙5. 반드시 샘플 엑셀을 다운받으셔서 규칙에 맞게 작성하신 후에 업로드해주세요. */}{t('ClassStudentCommonDlg.string52')} <br></br>
		  <br></br>
		  <label for="file">
			<div style={
				{
					width: '130px',
					height: '25px',
					background: '#fff',
					border: '1px solid rgb(77,77,77)',
					borderRadius: '10px',
					fontWeight: '200',
					fontSize:'13px',
					cursor: 'pointer',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center'
				}
			}>{/* 파일 업로드하기 */}{t('ClassStudentCommonDlg.string53')}</div>
		  </label>
		  <input
			type="file"
			accept=".xlsx, .xls, .csv"
			onChange={(e) => handleDrop(e.target.files, e)}
			id="file"
			style={{display:'none'}}
			/>
			{/* 업로드 파일 */}{t('ClassStudentCommonDlg.string54')} : {uploadedFile?.file?.name || t('ClassStudentCommonDlg.string56')}
          </Typography>
        </BootstrapDialogTitle>
        <DialogContent dividers>
          
        </DialogContent>
		<Box
		component="form"
		sx={{
			'& .MuiTextField-root': { m: 1, width: '670px', marginTop: '20px', marginLeft: '20px', marginRight: '20px' },
		}}
		noValidate
		autoComplete="off"
		>
		<div>
			<TableContainer sx={{ maxHeight: 670 }}>
				<Table stickyHeader aria-label="sticky table" size="small">
				<TableHead>
					<TableRow>
					<TableCell align="center">{/* 번호 */}{t('ClassStudentCommonDlg.string18')}</TableCell>
					<TableCell align="center">{/* 아이디 */}{t('ClassStudentCommonDlg.string1')}</TableCell>
					<TableCell align="center">{/* 이름 */}{t('ClassStudentCommonDlg.string3')}</TableCell>
					<TableCell align="center">{/* 닉네임 */}{t('ClassStudentCommonDlg.string4')}</TableCell>
					{/* <TableCell align="center"> */}{/* 부모 이메일 */}{/* {t('ClassStudentCommonDlg.string5')}</TableCell> */}
					<TableCell align="center">{/* 출생년도 */}{t('ClassStudentCommonDlg.string6')}</TableCell>
					<TableCell align="center">{/* 성별 */}{t('ClassStudentCommonDlg.string8')}</TableCell>
					<TableCell align="center">{/* 비밀번호 */}{t('Auth.common.password')}</TableCell>
					<TableCell align="center">{/* 이용권 */}{t('ClassStudentCommonDlg.string27')}</TableCell>
					<TableCell align="center">{/* 오류확인 */}{t('ClassStudentCommonDlg.string44')}</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
				{
					uploadedFile && uploadedFile.jsonData.length !== 0
					? 
					uploadedFile.jsonData
					.map((row, index) => {
						return (
						<TableRow hover role="checkbox" tabIndex={-1} key={index}>
							<TableCell component="th" scope="row" align="center">
							{index+1}
							</TableCell>
							<TableCell align="center">{row.ID}{row.duplication === 1 ? <Button onClick={(e)=> handleIDCheck(e, index)}>{t('Auth.common.duplicate')}</Button> : ""}</TableCell>
							<TableCell align="center">{row.Name}</TableCell>
							<TableCell align="center">{row.Nickname}</TableCell>
							{/* <TableCell align="center">{row.parent_e_mail}</TableCell> */}
							<TableCell align="center">{row.year_of_birth}</TableCell>
							<TableCell align="center">
							{
								{
									male : t('ClassStudentCommonDlg.string9'),
									female : t('ClassStudentCommonDlg.string10'),
								} [row.Gender]
							}
							</TableCell>
							{/* <TableCell align="center"> */}
								{/* <Button onClick={(e)=> handleSubmit(e, row)}>선택{t('ClassStudentCommonDlg.string21')}</Button> */}
							{/* </TableCell> */}
							<TableCell align="center">{row.Password}</TableCell>
							{/* <TableCell align="center"><Button onClick={(e)=> handleLicenseChange(e, index)}>{row.Ticket.name || t('Common.Select')}</Button></TableCell> */}
							<TableCell align="center"><StudentExcelPackageSelectDialog callbackFunction={PackageDataTransFuntion} index={index} jsonData={uploadedFile.jsonData} /></TableCell>
							{/* <TableCell align="center">{row.errorMsg !== "" ? <Button onClick={(e)=> handleErrorConfirm(e, index)}>{t('Common.confirm')}</Button> : "없음" }</TableCell> */}
							<TableCell align="center"><StudentExcelUploadErrorDialog errorMsg={row.errorMsg} /></TableCell>
						</TableRow>
						);
					})
					:
					<TableRow>
						<TableCell component="th" scope="row" align="center" colSpan="9">{/* 데이터 없음 */}{t('Common.No_data')}</TableCell>
					</TableRow>
					}
				</TableBody>
				</Table>
			</TableContainer>
		</div>
		</Box>
		<Divider />
		
        <DialogActions>
          <Button autoFocus onClick={(e)=> handleStudentMake(e)}>
			{/* 만들기 */}{t('ClassStudentCommonDlg.string55')}
          </Button>
		  <Button autoFocus onClick={handleClose}>
            {/* 취소 */}{t('Common.cancel')}
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
	</>
  );
}