import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import styles from "../../css/common.module.css";
import $ from 'jquery';
import { useParams } from 'react-router-dom';

import { solutionSelect } from "../../actions/solution";

import * as common from "../../lib";

export default function GroupMypageQnaInfoMore() {

    const solutionState = useSelector(state => state.solution.select);
    // console.log("solutionState : ", solutionState);

	const mypage_tab_btn_areaRef =  useRef();
    const hover_bgRef =  useRef();
    const onRef =  useRef();

	const dispatch = useDispatch();	

	let board_idx  = useParams().idx;
    // console.log("board_idx : ", board_idx);

    if (board_idx === undefined)
		board_idx = 1;

	useEffect(() => {

		// 여기에서 jQuery를 초기화 합니다.
		// jquery ready 호출. jquery3 부터 변경됨
		$(function(){
			// console.log("jquery ready!");

            //마이페이지 탭 버튼(활성화 효과)
			$(`.${styles.mypage_tab_btn_area} li`).on('click',function()
			{
				let tg=$(this);
				
				$(`.${styles.mypage_tab_btn_area} li`).removeClass(`${styles.on}`);
				tg.addClass(`${styles.on}`);
			});
            
            //마이페이지 탭 버튼(후버 효과)
            $(window).resize(function()
			{
				if (window.innerWidth < 1200) 
				{ 
					$(`.${styles.mypage_tab_btn_area} li.${styles.hover_bg}`).css('display','none');
				}
				else
				{
					let nowbtnNum=$(`.${styles.mypage_tab_btn_area} li.${styles.on}`).index();
					let hoverBg=$(`.${styles.mypage_tab_btn_area} li.${styles.hover_bg}`);

					$(`.${styles.mypage_tab_btn_area} ul`).on('mouseenter',function()
					{
						
						$(`.${styles.mypage_tab_btn_area} li`).removeClass(`${styles.on}`);
						hoverBg.css('display','block');
						
						$(`.${styles.mypage_tab_btn_area} li`).on('mouseenter',function()
						{
							let tgIndex=$(this).index();
							
							if(tgIndex===1)
							{
								hoverBg.stop().css('left','167px').css('width','116px');
							}
							else if(tgIndex===2)
							{
								hoverBg.stop().css('left','300px').css('width','150px');
							}
							else if(tgIndex===3)
							{
								hoverBg.stop().css('left','467px').css('width','116px');
							}
							else if(tgIndex===4)
							{
								hoverBg.stop().css('left','624px').css('width','102px');
							}
							else if(tgIndex===5)
							{
								hoverBg.stop().css('left','774px').css('width','102px');
							}
							else if(tgIndex===6)
							{
								hoverBg.stop().css('left','924px').css('width','102px');
							};
						});
						
					}).on('mouseleave',function()
					{
						$(`.${styles.mypage_tab_btn_area} li`).eq(nowbtnNum).addClass(`${styles.on}`);
						hoverBg.css('display','none');
						
						if(nowbtnNum===0)
						{
							hoverBg.css({left:'167px'}).css({width:'116px'});
						}
						else if(nowbtnNum===1)
						{
							hoverBg.css({left:'300px'}).css({width:'150px'});
						}
						else if(nowbtnNum===2)
						{
							hoverBg.css({left:'467px'}).css({width:'116px'});
						}
						else if(nowbtnNum===3)
						{
							hoverBg.css({left:'624px'}).css({width:'102px'});
						}
						else if(nowbtnNum===4)
						{
							hoverBg.css({left:'774px'}).css({width:'102px'});
						}
						else if(nowbtnNum===5)
						{
							hoverBg.css({left:'924px'}).css({width:'102px'});
						};

					});
					
				}
			}).resize();

		});

		// 여기서 디스패치로~
        dispatch(solutionSelect(board_idx))
        .then(data => 
        {
            // console.log("data : ", data);
        })
        .catch(e => {
            console.log(e);
        });

	}, [$, dispatch]);

  return (
    <>
      	{/* Header 분리됨 MainLayout Header에 있음 */}

		<div className={styles.mypage_top_line_banner}>
			<div className={styles.inner}>
				<span>학부모 페이지</span>
			</div>
		</div>
		<div className={styles.mypage_tab_btn_area} ref={mypage_tab_btn_areaRef}>
			<div className={styles.inner}>
				<ul>
					<li className={styles.hover_bg} ref={hover_bgRef}></li>
					<li><a href="/GroupUserMypage/GroupReport">학습 리포트</a></li>
					<li><a href="/GroupUserMypage/GroupStudentInfo">학습자 정보/관리</a></li>
					<li><a href="/GroupUserMypage/GroupParentsInfo">학부모 정보</a></li>
					<li><a href="/GroupUserMypage/GroupPayInfo">결제 정보</a></li>
					<li><a href="/GroupUserMypage/GroupCouponInfo">쿠폰 관리</a></li>
					<li className={styles.on} ref={onRef}><a href="/GroupUserMypage/GroupQnaInfo">문의 내역</a></li>
				</ul>
			</div>
		</div>
		<div className={`${styles.mypage_tab} ${styles.mypage_pay_info_more} ${styles.qna_info_more}`}>
			<div className={styles.inner}>
				<span className={styles.my_tit}>문의 상세정보</span>
				<div className={`
						${styles.pay_info_more_area} 
						${solutionState?.answer === null ? `` : `${styles.complete}`}
					`}>
					 <ul>
                        <li></li>
                        <li></li>
						<li>
							<span className={styles.head}>문의날짜</span>
							<p>{/*2022.9.30 - 11시 59분*/}{solutionState?.reg_date}</p>
						</li>
						<li>
                            <span className={styles.head}>이름</span>
							<p>{solutionState?.mem_name}</p>
						</li>
						<li className={styles.answer_state}>
                            <span className={styles.head}>답변상태</span>
							<p>{ solutionState?.answer === null ? "답변대기" : "답변완료" }</p>
						</li>
                        <li>
                            <span className={styles.head}>문의유형</span>
                            <p>{common.returnSolutionType(solutionState?.sc_category_idx)}</p>
                        </li>
                        <li>
                            <span className={styles.head}>제목</span>
                            <p>{solutionState?.title}</p>
                        </li>
                        <li className={styles.question}>
                            <span className={styles.head}>문의내용</span>
                            <p>{solutionState?.question}</p>
                        </li>
                        <li className={styles.answer_list}>
                            <span className={styles.head}>답변날짜</span>
                            <p>{/*2022.10.1 - 11시 59분*/}{solutionState?.answer_reg_date}</p>
                        </li>
                        <li className={`${styles.answer} ${styles.answer_list}`}>
                            <span className={styles.head}>답변</span>
                            <p>{solutionState?.answer || ""}</p>
                        </li>
					 </ul>
					<a href="/GroupUserMypage/GroupQnaInfo">확인</a>
				</div>
			</div>{/* //inner */}
		</div>{/* //mypage_tab */}
		
		{/* Footer 분리됨 MainLayout Footer에 있음 */}
    </>
  );
}