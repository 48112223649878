import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import { Link, Grid, Typography, Divider } from '@mui/material';

import { useDispatch } from "react-redux";
import { orderSelect, orderCancel } from "../../../actions/order";

import { useEffect, useState } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import * as common from "../../../lib";
import http from "../../../http-common";

// 결제 정보에 대한 컴포넌트
const MyPayment = (props) =>{
  return (
          <>
            <Grid item xs={12}> <Divider variant="middle" /> </Grid>
            <Grid item xs={12}> <Typography variant="h6" align="center">결제 정보</Typography> </Grid>
            <Grid item xs={12}> <Divider variant="middle" /> </Grid>
            <Grid item xs={4} md={3}>
            결제번호 : 
            </Grid>
            <Grid item xs={6} md={8}>
            {props.payment.payment_no || ""}
            </Grid>
            <Grid item xs={4} md={3}>
            결제수단 : 
            </Grid>
            <Grid item xs={6} md={8}>
            {props.payment.payment_opt_cd || ""}
            </Grid>
            <Grid item xs={4} md={3}>
            결제채널 : 
            </Grid>
            <Grid item xs={6} md={8}>
            {props.payment.channel || ""}
            </Grid>
            <Grid item xs={4} md={3}>
            결제금액 : 
            </Grid>
            <Grid item xs={6} md={8}>
            &#8361; {common.priceToString(props.payment.payment_amt) || ""}
            </Grid>
            <Grid item xs={4} md={3}>
            결제날짜 : 
            </Grid>
            <Grid item xs={6} md={8}>
            {props.payment.payment_date || ""}
            </Grid>
            <Grid item xs={4} md={3}>
            결제상태 : 
            </Grid>
            <Grid item xs={6} md={8}>
            {common.returnPaymentState(props.payment.payment_state) || ""}
            </Grid>
            <Grid item xs={4} md={3}>
            결제확인 url : 
            </Grid>
            <Grid item xs={6} md={8}>
            <Link href={props.payment.receipt_url || "#"} underline="none" target="_blank">영수증 확인</Link>
            </Grid>
          </>
  );
};

// 환불 정보에 대한 컴포넌트
const MyRefund = (props) =>{
  return (
          <>
            <Grid item xs={12}> <Divider variant="middle" /> </Grid>
            <Grid item xs={12}> <Typography variant="h6" align="center">환불 정보</Typography> </Grid>
            <Grid item xs={12}> <Divider variant="middle" /> </Grid>
            <Grid item xs={4} md={3}>
            결제번호 : 
            </Grid>
            <Grid item xs={6} md={8}>
            {props.refund.payment_no || ""}
            </Grid>
            <Grid item xs={4} md={3}>
            환불사유 : 
            </Grid>
            <Grid item xs={6} md={8}>
            {props.refund.refund_desc || ""}
            </Grid>
            <Grid item xs={4} md={3}>
            환불금액 : 
            </Grid>
            <Grid item xs={6} md={8}>
            &#8361; {common.priceToString(props.refund.refund_amt) || ""}
            </Grid>
            <Grid item xs={4} md={3}>
            환불날짜 : 
            </Grid>
            <Grid item xs={6} md={8}>
            {props.refund.refund_date || ""}
            </Grid>
            <Grid item xs={4} md={3}>
            처리상태 : 
            </Grid>
            <Grid item xs={6} md={8}>
            {common.returnRefundState(props.refund.refund_stat_cd) || ""}
            </Grid>
            <Grid item xs={4} md={3}>
            영수증 확인 url : 
            </Grid>
            <Grid item xs={6} md={8}>
            <Link href={props.refund.receipt_url || "#"} underline="none" target="_blank">영수증 확인</Link>
            </Grid>
          </>
  );
};

export default function ProductModify() {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    
    const ord_idx  = useParams().ord_idx;
    // console.log("ord_idx : ", ord_idx);

    const initialOrderState = {
      order_idx: null,
      order_count: null, 
      order_amt: null,
      order_stat_cd: null,
      order_date: null,
      product_name: null,
      product_idx: null,
      product_desc: null,
      sell_prc_unit: null,
      filePath: null,
      fileName: null,
      member_idx: null,
      member_name: null,
      payment_no: null,
      payment_opt_cd: null,
      payment_amt: null,
      payment_date: null,
      payment_state: null,
      receipt_url: null,
      channel: null,
      refund_amt: null,
      refund_desc: null,
      refund_stat_cd: null,
      refund_date: null
    };

    const [order, setOrder] = useState(initialOrderState);

    const handleCancelOrder = (event, idx) => {
      console.log(idx);

      if (window.confirm("정말 취소하시겠습니까??") === true)   //확인
      {
        // 여기서 디스패치로~
        dispatch(orderCancel(idx))
        .then(data => 
        {
            // console.log("data : ", data);
            if (!data.success)
                alert(data.msg);

            navigate("/dashboard/OrderList");
        })
        .catch(e => {
            console.log(e);
        });
      }
      else  //취소
      {
        return false;
      }

    };

    const handleImgError = (e) => {
      e.target.src = "https://cdn-icons-png.flaticon.com/512/1474/1474713.png";
    };

    const handlePayment = (e, idx) => {
      const IMP = window.IMP; // 생략 가능
      // IMP.init("imp80753747"); // Example: imp00000000
      IMP.init(process.env.REACT_APP_IMP_UID); // Example: imp00000000

      // console.log("order : ", order);
      
      let mobileCompleteUrl = process.env.REACT_APP_API_URL + "/payment/mobile/complete";

      // IMP.request_pay(param, callback) 결제창 호출
      IMP.request_pay({ // param
        pg: "html5_inicis",
        pay_method: "card",
        merchant_uid: order.order_no,
        name: order.product_name,
        amount: order.order_amt,
        buyer_email: order.member_email,
        buyer_name: order.member_name,
        buyer_tel: order.member_phone,
        m_redirect_url: mobileCompleteUrl,
      }, rsp => { // callback

        console.log("rsp : ", rsp);
        if (rsp.success) { // 결제 성공 시: 결제 승인 또는 가상계좌 발급에 성공한 경우
          
          // axios로 HTTP 요청
          const sendData = { imp_uid: rsp.imp_uid, merchant_uid: rsp.merchant_uid };
      
          http.post("payment/complete", sendData
          ).then((data) => {
            // 서버 결제 API 성공시 로직
            console.log(data);
            window.location.reload();
          })

        } else {
          alert(`결제에 실패하였습니다. 에러 내용: ${rsp.error_msg}`);
        }
      });

    };

/*

// 결제 후 응답

{
    "success": true,
    "imp_uid": "imp_301030574106",
    "pay_method": "card",
    "merchant_uid": "ORD-20220204-000019",
    "name": "표준 요금제",
    "paid_amount": 100,
    "currency": "KRW",
    "pg_provider": "html5_inicis",
    "pg_type": "payment",
    "pg_tid": "StdpayISP_INIpayTest20220207151645222548",
    "apply_num": "66147664",
    "buyer_name": "홍길동",
    "buyer_email": "sevenwind@hanmail.net",
    "buyer_tel": "010-4242-4242",
    "buyer_addr": "",
    "buyer_postcode": "",
    "custom_data": null,
    "status": "paid",
    "paid_at": 1644214606,
    "receipt_url": "https://iniweb.inicis.com/DefaultWebApp/mall/cr/cm/mCmReceipt_head.jsp?noTid=StdpayISP_INIpayTest20220207151645222548&noMethod=1",
    "card_name": "BC카드",
    "bank_name": null,
    "card_quota": 0,
    "card_number": "910020*********6"
}

// 조회
// https://api.iamport.kr/payments/imp_301030574106?_token=472ab44f5cceb60fe44c59b477f264566638dbfc

{
  "code": 0,
  "message": null,
  "response": {
    "amount": 100,
    "apply_num": "66147664",
    "bank_code": null,
    "bank_name": null,
    "buyer_addr": null,
    "buyer_email": "sevenwind@hanmail.net",
    "buyer_name": "홍길동",
    "buyer_postcode": null,
    "buyer_tel": "010-4242-4242",
    "cancel_amount": 0,
    "cancel_history": [],
    "cancel_reason": null,
    "cancel_receipt_urls": [],
    "cancelled_at": 0,
    "card_code": "361",
    "card_name": "BC카드",
    "card_number": "910020*********6",
    "card_quota": 0,
    "card_type": 0,
    "cash_receipt_issued": false,
    "channel": "pc",
    "currency": "KRW",
    "custom_data": null,
    "customer_uid": null,
    "customer_uid_usage": null,
    "emb_pg_provider": null,
    "escrow": false,
    "fail_reason": null,
    "failed_at": 0,
    "imp_uid": "imp_301030574106",
    "merchant_uid": "ORD-20220204-000019",
    "name": "표준 요금제",
    "paid_at": 1644214605,
    "pay_method": "card",
    "pg_id": "INIpayTest",
    "pg_provider": "html5_inicis",
    "pg_tid": "StdpayISP_INIpayTest20220207151645222548",
    "receipt_url": "https://iniweb.inicis.com/DefaultWebApp/mall/cr/cm/mCmReceipt_head.jsp?noTid=StdpayISP_INIpayTest20220207151645222548&noMethod=1",
    "started_at": 1644214030,
    "status": "paid",
    "user_agent": "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/97.0.4692.99 Safari/537.36",
    "vbank_code": null,
    "vbank_date": 0,
    "vbank_holder": null,
    "vbank_issued_at": 0,
    "vbank_name": null,
    "vbank_num": null
  }
}

*/

    useEffect(() => {
        
        // console.log("dispatch orderSelect");
        dispatch(orderSelect(ord_idx))
        .then(data => 
        {
            // console.log("data : ", data[0]);
            setOrder({
              order_idx: data[0].ord_idx,
              order_no: data[0].ord_no,
              order_count: data[0].ord_count, 
              order_amt: data[0].ord_amt,
              order_stat_cd: data[0].ord_stat_cd,
              order_date: data[0].ord_date,
              product_name: data[0].prd_name,
              product_idx: data[0].prd_idx,
              product_desc: data[0].prd_desc,
              sell_prc_unit: data[0].sell_prc_unit,
              filePath: data[0].prd_photo_path,
              fileName: data[0].prd_photo_file,
              member_idx: data[0].mem_idx,
              member_name: data[0].mem_name,
              member_email: data[0].mem_email,
              member_phone: data[0].mem_phone,
              payment_no: data[0].pay_no,
              payment_opt_cd: data[0].pay_opt_cd,
              payment_amt: data[0].pay_amt,
              payment_date: data[0].pay_date,
              payment_state: data[0].pay_state,
              receipt_url: data[0].receipt_url,
              channel: data[0].channel,
              refund_amt: data[0].ref_amt,
              refund_desc: data[0].ref_desc,
              refund_stat_cd: data[0].ref_stat_cd,
              refund_date: data[0].ref_date
            });
        })
        .catch(e => {
            console.log(e);
        });

    }, [dispatch])

  return (
    <Card sx={{ minWidth: 275, maxWidth: 500 }}>
      <CardContent>
        <Grid container spacing={2}>
            <Grid item xs={12}> <Divider variant="middle" /> </Grid>
            <Grid item xs={12}> <Typography variant="h6" align="center">주문 정보</Typography> </Grid>
            <Grid item xs={12}> <Divider variant="middle" /> </Grid>

            <Grid item xs={4} md={3}>
            주문번호 : 
            </Grid>
            <Grid item xs={6} md={8}>
            {order.order_no || ""}
            </Grid>
            <Grid item xs={4} md={3}>
            주문자 : 
            </Grid>
            <Grid item xs={6} md={8}>
            {order.member_name || ""}
            </Grid>
            <Grid item xs={4} md={3}>
            주문일 : 
            </Grid>
            <Grid item xs={6} md={8}>
            {order.order_date || ""}
            </Grid>
            <Grid item xs={4} md={3}>
            주문상태 : 
            </Grid>
            <Grid item xs={6} md={8}>
            {common.returnOrderState(order.order_stat_cd) || ""}
            </Grid>
            <Grid item xs={4} md={3}>
            상품명 : 
            </Grid>
            <Grid item xs={6} md={8}>
            {order.product_name || ""}
            </Grid>
            <Grid item xs={4} md={3}>
            상품설명 : 
            </Grid>
            <Grid item xs={6} md={8}>
            {order.product_desc || ""}
            </Grid>
            <Grid item xs={4} md={3}>
            제품 이미지
            </Grid>
            <Grid item xs={6} md={8}>
            {order.fileName ? <img src={common.returnImgSrc(order.filePath, order.fileName)} width="150"  onError={handleImgError} /> : "제품 이미지가 없습니다." }
            </Grid>
            <Grid item xs={4} md={3}>
            가격
            </Grid>
            <Grid item xs={6} md={8}>
            &#8361; {common.priceToString(order.sell_prc_unit) || ""}
            </Grid>
            <Grid item xs={4} md={3}>
            수량
            </Grid>
            <Grid item xs={6} md={8}>
            {order.order_count || ""} 개
            </Grid>
            <Grid item xs={4} md={3}>
            총 결제금액
            </Grid>
            <Grid item xs={6} md={8}>
            &#8361; {common.priceToString(order.order_amt) || ""}
            </Grid>
            {order.payment_no !== null ? <MyPayment payment={order} /> : ""}
            {order.refund_stat_cd === "cancelled" ? <MyRefund refund={order} /> : ""}
        </Grid>
      </CardContent>
      <CardActions>
        <Link href={`/dashboard/orderList`} underline="none">목록</Link>
        { order.order_stat_cd === "W" ? <Link onClick={(e)=>handleCancelOrder(e, ord_idx)} underline="none">취소하기</Link> : "" }
        { order.order_stat_cd === "W" ? <Link onClick={(e)=>handlePayment(e, ord_idx)} value={ord_idx} underline="none">결제하기!</Link> : "" }
      </CardActions>
    </Card>
  );
}