import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';

import { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { inappPaymentList, inappPaymentRefund } from "../../../actions/inapp_payment";

import { useNavigate } from 'react-router-dom';

import * as common from "../../../lib";

import http from "../../../http-common";

export default function InappPaymentList() {

    const payment = useSelector(state => state.inapp_payment);
    console.log("payment : ", payment);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {

        // 여기서 디스패치로~
        dispatch(inappPaymentList())
        .then(data => 
        {
            console.log("data : ", data);
        })
        .catch(e => {
            console.log(e);
        });

    }, [dispatch])

    const onClickRefund = (event, inp_idx, inp_no) => {

      event.preventDefault();

      let sendData = {
        inp_idx: inp_idx,
        inp_no: inp_no,
      }

      dispatch(inappPaymentRefund(sendData))
      .then(data => 
      {
          console.log("data : ", data);

          dispatch(inappPaymentList())
          .then(data => 
          {
              console.log("data : ", data);
          })
          .catch(e => {
              console.log(e);
          });
      })
      .catch(e => {
          console.log(e);
      });
    
    } 

  return (
    <Card sx={{ minWidth: 275 }}>
      <CardContent>
      결제 리스트
      <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="center">번호</TableCell>
            <TableCell align="center">상품명</TableCell>
            <TableCell align="center">구입자명</TableCell>
            <TableCell align="center">인앱타입</TableCell>
            <TableCell align="center">인앱상태</TableCell>
            <TableCell align="center">구입개수</TableCell>
            <TableCell align="center">구입금액</TableCell>
            <TableCell align="center">결제일</TableCell>
            <TableCell align="center">환불</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {payment && payment.map((row, index) => (
            <TableRow
              key={index}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row" align="center">
                {row.inp_idx}
              </TableCell>
              <TableCell align="center"><Link href={`/dashboard/InappPaymentView/${row.inp_idx}`} underline="none">{row.ina_name}</Link></TableCell>
              <TableCell align="center">{row.mem_name}</TableCell>
              <TableCell align="center">{row.inp_type}</TableCell>
              <TableCell align="center">{common.returnInappStatus(row.inp_stat_cd)}</TableCell>
              <TableCell align="center">{row.inp_count}</TableCell>
              <TableCell align="center">&#8361; {common.priceToString(row.inp_count * row.sell_prc_unit)}</TableCell>
              <TableCell align="center">{row.inp_date}</TableCell>
              <TableCell align="center"><Button variant="outlined" onClick={(e)=>onClickRefund(e, row.inp_idx, row.inp_no)}>{row.inp_stat_cd === 'A' ? "환불" : "복구"}</Button></TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      </TableContainer>

      </CardContent>
      <CardActions>
        {/* <Button onClick={handleWriteOrder} size="small">패키지 구매</Button> */}
      </CardActions>
    </Card>
  );
}