import http from "../http-common";

const bbsNoticeCreate = data => {
  
  //전송할 데이터에 파일이 있을경우
  const config = {
    headers : {
        'context-type' : 'multipart/form-data'
    }
  }

  return http.post("bbs/yame/notice/add", data, config);
};

const bbsNoticeGetAll = data => {
    console.log("bbsNoticeGetAll data : ", data);
    return http.get(`bbs/yame/notice/${data.board_name}/${data.board_page}`);
};

const bbsNoticeModify = data => {

  //전송할 데이터에 파일이 있을경우
  const config = {
    headers : {
        'context-type' : 'multipart/form-data'
    }
  }

  return http.put("bbs/yame/notice/edit", data, config);
};

const bbsNoticeSelect = (bbs_name, idx) => {
  return http.get(`bbs/yame/notice/select/${bbs_name}/${idx}`);
};

const bbsNoticeDelete = data => {
  return http.delete("bbs/yame/notice/del", {data : data});
};

const bbsNoticeOpen = data => {
  return http.put("bbs/yame/notice/use", {data : data});
};

const BBSYameNoticeService = {
  bbsNoticeCreate,
  bbsNoticeGetAll,
  bbsNoticeDelete,
  bbsNoticeModify,
  bbsNoticeSelect,
  bbsNoticeOpen,
};

export default BBSYameNoticeService;
