import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';

import { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { studentList, studentCount, studentDelete } from "../../../actions/student";

import { useNavigate } from 'react-router-dom';

// import * as common from "../../../lib";

export default function StudentList() {

    const student = useSelector(state => state.student.list);
    // console.log("student : ", student);
    let currentStudentCount = useSelector(state => state.student.count);
    // console.log("currentStudentCount : ", currentStudentCount);

    if (currentStudentCount&& currentStudentCount.package_sum === null)
      currentStudentCount.package_sum = 0;

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleWriteStudent = (event) => {

      if (parseInt(student.length) >= parseInt(currentStudentCount.package_sum))
      {
        alert("학습자 생성인원을 초과했습니다. 패키지를 더 구매해주세요.");
      }
      else
      {
        navigate("/dashboard/StudentWrite");
        return;
      }

    };

    const handleCancelStudent = (event, idx) => {
      // console.log(idx);

      if (window.confirm("정말 삭제하시겠습니까??") === true)   //확인
      {
        // 여기서 디스패치로~
        dispatch(studentDelete(idx))
        .then(data => 
        {
            // console.log("data : ", data);
            if (!data.success)
                alert(data.msg);

            // 여기서 디스패치로~
            dispatch(studentList())
            .then(data => 
            {
                // console.log("data : ", data);
            })
            .catch(e => {
                console.log(e);
            });

            // 여기서 디스패치로~
            dispatch(studentCount())
            .then(data => 
            {
                // console.log("data : ", data);
            })
            .catch(e => {
                console.log(e);
            });
        })
        .catch(e => {
            console.log(e);
        });
      }
      else  //취소
      {
        return false;
      }

    };

    useEffect(() => {

        // 여기서 디스패치로~
        dispatch(studentList())
        .then(data => 
        {
            // console.log("data : ", data);
        })
        .catch(e => {
            console.log(e);
        });

        // 여기서 디스패치로~
        dispatch(studentCount())
        .then(data => 
        {
            // console.log("data : ", data);
        })
        .catch(e => {
            console.log(e);
        });

    }, [dispatch])

  return (
    <Card sx={{ minWidth: 275 }}>
      <CardContent>
      학습자 리스트 (패키지 구매 인원 : { currentStudentCount ?  currentStudentCount.package_sum : "0" }명) - 학습자는 패키지 구매 인원을 넘을 수 없습니다.
      <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="center">번호</TableCell>
            <TableCell align="center">아이디</TableCell>
            <TableCell align="center">이름</TableCell>
            <TableCell align="center">닉네임</TableCell>
            <TableCell align="center">대표 캐릭터</TableCell>
            <TableCell align="center">상태</TableCell>
            <TableCell align="center">삭제</TableCell>
            <TableCell align="center">생성일</TableCell>
            <TableCell align="center">수정일</TableCell>
            <TableCell align="center">소속 패키지</TableCell>
            <TableCell align="center">상태</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {student && student.map((row, index) => (
            <TableRow
              key={index}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row" align="center">
                {row.mst_idx}
              </TableCell>
              <TableCell align="center"><Link href={`/dashboard/StudentView/${row.mst_id}`} underline="none">{row.mst_id}</Link></TableCell>
              <TableCell align="center">{row.mst_name || "-"}</TableCell>
              <TableCell align="center">{row.mst_nickname || "-"}</TableCell>
              <TableCell align="center">{row.mst_rep_character || "-"}</TableCell>
              <TableCell align="center">{row.mst_status === 1 ? "활성" : "비활성"}</TableCell>
              <TableCell align="center">
                {
                  row.ord_no === "" ? "-" :
                  <Link onClick={(e)=>handleCancelStudent(e, row.mst_idx)} value={row.mst_idx} underline="none">삭제</Link>
                }
              </TableCell>
              <TableCell align="center">{row.mst_reg_date || "-"}</TableCell>
              <TableCell align="center">{row.mst_mod_date || "-"}</TableCell>
              <TableCell align="center">{row.ord_no || "-"}</TableCell>
              <TableCell align="center">
                {
                  {
                    A : "유료",
                    F : "무료",
                    null : "-"
                  } [row.ord_stat_cd]
                }
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      </TableContainer>

      </CardContent>
      <CardActions>
        <Button onClick={handleWriteStudent} size="small">학습자 생성</Button>
      </CardActions>
    </Card>
  );
}