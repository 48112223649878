import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import StudentLicenseChangeDialog from './StudentLicenseChangeDialog';

import { useTranslation, Trans } from 'react-i18next'; // 1. react-i18next import 

export default function AlertDialog(props) {
  const [openAlert, setOpenAlert] = React.useState(props.openAlert);

  const { t, i18n } = useTranslation(); // 3. useTranslation hook 선언

//   console.log(props.RowData);

  const handleClickOpen = () => {
    setOpenAlert(true);
  };

  const handleClose = () => {
    setOpenAlert(false);
  };

  return (
    <div>
      <Button variant="outlined" size="small" onClick={handleClickOpen}>{/* 추가 */}{t('ClassStudentCommonDlg.string14')}</Button>
      <Dialog
        open={openAlert}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {/* 학습자 유효기간 확인 */}{t('ClassStudentCommonDlg.string40')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
    {/*         {props.RowData.mst_id}는 {props.RowData.valid_date}까지 유효기간입니다.<br></br>
            한번 사용된 이용권은 다시 적용할 수 없습니다. <br></br> 이용권을 추가하시겠습니까? */}

            <Trans i18nKey="ClassStudentCommonDlg.string41" values={{id:props.RowData.mst_id, valid_date:props.RowData.valid_date}} />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
		    <StudentLicenseChangeDialog myPackageList={props.myPackageList} RowData={props.RowData} currentPage={props.currentPage} mcIdx={props.mcIdx} handleClose={handleClose} popupCheck={true} />&nbsp;&nbsp;&nbsp;
          <Button onClick={handleClose} autoFocus variant="outlined" size="small">
            {/* 취소 */}{t('Common.cancel')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}