import { combineReducers } from "redux";
import auth from "./auth";
import users from "./users";
import category from "./category";
import product from "./product";
import order from "./order";
import payment from "./payment";
import refund from "./refund";
import student from "./student";
import myPackage from "./package";
import bbs from "./bbs";
import comment from "./comment";
import notice from "./notice"
import knowledge_category from "./knowledge_category";
import knowledge from "./knowledge";
import mission_category from "./mission_category";
import mission from "./mission";
import product_mission from "./product_mission";
import yame_notice from "./yame_notice";
import coupon from "./coupon";
import coupon_mission from "./coupon_mission";
import solution from "./solution";
import news from "./news";
import ranking from "./ranking";
import inapp_product from "./inapp_product";
import inapp_product_mission from "./inapp_product_mission";
import inapp_payment from "./inapp_payment";
import classReducer from "./class";
import group_order from "./group_order";
import group_payment from "./group_payment";
import chart from "./chart";

import { persistReducer } from "redux-persist"; // 해당 라인 추가
import storage from "redux-persist/lib/storage"; // 해당 라인 추가

// export default combineReducers({
//   auth,
//   users,
//   category,
//   product,
//   order,
//   payment,
//   refund,
//   student,
//   myPackage,
//   bbs,
//   comment,
//   notice,
//   knowledge_category,
//   knowledge,
//   mission_category,
//   mission,
//   product_mission,
//   yame_notice,
//   coupon,
//   coupon_mission,
//   solution,
//   news,
//   ranking,
//   inapp_product,
//   inapp_product_mission,
//   inapp_payment,
// });

const rootReducer = combineReducers({
  auth,
  users,
  category,
  product,
  order,
  payment,
  refund,
  student,
  myPackage,
  bbs,
  comment,
  notice,
  knowledge_category,
  knowledge,
  mission_category,
  mission,
  product_mission,
  yame_notice,
  coupon,
  coupon_mission,
  solution,
  news,
  ranking,
  inapp_product,
  inapp_product_mission,
  inapp_payment,
  classReducer,
  group_order,
  group_payment,
  chart,
}); // 추가

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["auth"]
}; // 추가

export default persistReducer(persistConfig, rootReducer); //  해당 라인 추가
