import {
  CREATE_MISSION,
  LIST_MISSION,
  DELETE_MISSION,
  MODIFY_MISSION,
  SELECT_MISSION,
  ORDER_CHANGE_MISSION,
} from "./types";

import MissionService from "../services/MissionService";

export const missionCreate = (data) => async (dispatch) => {
  
  try {
    
    const res = await MissionService.missionCreate(data);

    // console.log("res : ", res);

    dispatch({
      type: CREATE_MISSION,
      payload: res.data,
    });

    return Promise.resolve(res.data);

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};

export const missionList = (data) => async (dispatch) => {
  
  try {
    
    const res = await MissionService.missionGetAll(data);

    // console.log("res : ", res);

    dispatch({
      type: LIST_MISSION,
      payload: res.data.data,
    });

    return Promise.resolve(res.data.data);

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};

export const missionSelect = (idx) => async (dispatch) => {
  
  try {
    
    const res = await MissionService.missionSelect(idx);

    // console.log("res : ", res);

    dispatch({
      type: SELECT_MISSION,
      payload: res.data.data,
    });

    return Promise.resolve(res.data.data);

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};

export const missionDelete = (idx) => async (dispatch) => {
  
  try {

    // console.log("missionDelete! idx : ", idx);

    const res = await MissionService.missionDelete(idx);

    // console.log("res : ", res);

    dispatch({
      type: DELETE_MISSION,
      payload: res.data,
    });
  
    return Promise.resolve(res.data);

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};

export const missionModify = (data) => async (dispatch) => {
  
  try {
    
    const res = await MissionService.missionModify(data);

    // console.log("res : ", res);

    dispatch({
      type: MODIFY_MISSION,
      payload: res.data,
    });

    return Promise.resolve(res.data);

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};

export const missionOrderChange = (data) => async (dispatch) => {
  
  try {
    
    const res = await MissionService.missionOrderChange(data);

    // console.log("res : ", res);

    dispatch({
      type: ORDER_CHANGE_MISSION,
      payload: res.data,
    });

    return Promise.resolve(res.data);

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};
