import {
  CREATE_PAYMENT,
  LIST_PAYMENT,
  CANCEL_PAYMENT,
  SELECT_PAYMENT,
  VBANK_CANCEL_PAYMENT,
} from "./types";

import PaymentService from "../services/PaymentService";

export const paymentCreate = (data) => async (dispatch) => {
  
  try {
    
    const res = await PaymentService.paymentCreate(data);

    // console.log("res : ", res);

    dispatch({
      type: CREATE_PAYMENT,
      payload: res.data,
    });

    return Promise.resolve(res.data);

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};

export const paymentList = () => async (dispatch) => {
  
  try {
    
    const res = await PaymentService.paymentGetAll();

    // console.log("res : ", res);

    dispatch({
      type: LIST_PAYMENT,
      payload: res.data.data,
    });

    return Promise.resolve(res.data.data);

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};

export const paymentSelect = (ord_idx) => async (dispatch) => {
  
  try {
    
    const res = await PaymentService.paymentSelect(ord_idx);

    // console.log("res : ", res);

    dispatch({
      type: SELECT_PAYMENT,
      payload: res.data.data,
    });

    return Promise.resolve(res.data.data);

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};

export const paymentCancel = (data) => async (dispatch) => {
  
  try {

    // console.log("paymentCancel! data : ", data);

    const res = await PaymentService.paymentCancel(data);

    // console.log("res : ", res);

    if (res.data.success === false)
    {
      // alert(res.data.msg);
      return Promise.resolve(res.data);
    }
    else
    {
      dispatch({
        type: CANCEL_PAYMENT,
        payload: "",
      });
  
      return Promise.resolve(res.data);
    }

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};

export const paymentVbankCancel = (data) => async (dispatch) => {
  
  try {

    // console.log("paymentVbankCancel! data : ", data);

    const res = await PaymentService.paymentVbankCancel(data);

    // console.log("res : ", res);

    if (res.data.success === false)
    {
      // alert(res.data.msg);
      return Promise.resolve(res.data);
    }
    else
    {
      dispatch({
        type: VBANK_CANCEL_PAYMENT,
        payload: "",
      });
  
      return Promise.resolve(res.data);
    }

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};
