import * as React from 'react';
import styles from "../../css/common.module.css";

import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';

import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
// import { groupRegister } from "../../actions/auth";
import { useNavigate, useLocation } from 'react-router-dom';
import PopupDom from '../../common/PopupDom';
import PopupPostCode from '../../common/PopupPostCode';

import { useTranslation, Trans } from 'react-i18next'; // 1. react-i18next import 

export default function GroupRegisterView() {

  const initialJoinGroupState = {
    companyName: "", 
    bizCode1: "",
    bizCode2: "",
    bizCode3: "",
    companyPhone1: "",
    companyPhone2: "",
    companyPhone3: "",
    companyFax1: "",
    companyFax2: "",
    companyFax3:"",
    type:0,
    plusAddress: "",
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { t, i18n } = useTranslation(); // 3. useTranslation hook 선언

  // console.log('location state : ', location.state);
  
  const [join, setJoin] = useState(location?.state?.joinData);
  const [joinGroup, setJoinGroup] = useState(initialJoinGroupState);

  const [addressJson, setAddressJson] = useState(null);
  const [zoneCode, setZoneCode] = useState("");
  const [fullAddress, setFullAddress] = useState("");

  const [fontSizeValue, setFontSizeValue] = useState(null);

  // 팝업창 상태 관리
  const [isPopupOpen, setIsPopupOpen] = useState(false)

  const handleInputChange = event => {
    const { name, value } = event.target;
    // console.log("event target name : ", name);
    // console.log("event target value : ", value);
    setJoinGroup({ ...joinGroup, [name]: value });
  };

  // 사업자 번호 유효성 체크 함수
  const checkCorporateRegistrationNumber = (value) => 
  {
    let valueMap = value.replace(/-/gi, '').split('').map(function(item) 
    {
        return parseInt(item, 10);
    });

    if (valueMap.length === 10) 
    {
        var multiply = new Array(1, 3, 7, 1, 3, 7, 1, 3, 5);
        var checkSum = 0;

        for (var i = 0; i < multiply.length; ++i) 
        {
            checkSum += multiply[i] * valueMap[i];
        }

        checkSum += parseInt((multiply[8] * valueMap[8]) / 10, 10);
        return Math.floor(valueMap[9]) === (10 - (checkSum % 10));
    }

    return false;
}

  const handleSubmit = (event) => {
    event.preventDefault();

    // console.log('join : ', join);
    // console.log("joinGroup : ", joinGroup);
    // console.log("addressJson : ", addressJson);
    // console.log("zoneCode : ", zoneCode);
    // console.log("fullAddress : ", fullAddress);

    const regPhone = /^(0(2|3[1-3]|4[1-4]|5[1-5]|6[1-4]))-(\d{3,4})-(\d{4})$/;
    if(joinGroup.companyName === "")
    {
      /* return alert('회사 이름을 입력해주세요.'); */
      return alert(`${t("MypageParentsInfo.alert.string4")}`);
    }

    if(joinGroup.bizCode1 === "" || joinGroup.bizCode2 === "" || joinGroup.bizCode3 === "")
    {
      /* return alert('사업자 번호를 입력해주세요.'); */
      return alert(`${t("MypageParentsInfo.alert.string5")}`);
    }

    if(!checkCorporateRegistrationNumber(joinGroup.bizCode1+joinGroup.bizCode2+joinGroup.bizCode3))
    {
      /* return alert('유효한 사업자번호를 입력하세요.'); */
      return alert(`${t("MypageParentsInfo.alert.string6")}`);
    }

    if(joinGroup.companyPhone1 === "" || joinGroup.companyPhone2 === "" || joinGroup.companyPhone3 === "")
    {
      /* return alert('전화 번호를 입력해주세요.'); */
      return alert(`${t("MypageParentsInfo.alert.string7")}`);
    }
    
    if (regPhone.test(joinGroup.companyPhone1 + "-" + joinGroup.companyPhone2 + "-" + joinGroup.companyPhone3) === false) 
    {
      /* return alert('입력된 값은 전화번호 형식에 맞지 않습니다.'); */
      return alert(`${t("MypageParentsInfo.alert.string8")}`);
    }

    // if(joinGroup.companyFax1 === "" || joinGroup.companyFax2 === "" || joinGroup.companyFax3 === "")
    // {
    //   return alert('팩스 번호를 입력해주세요.');
    // }

    // if (regPhone.test(joinGroup.companyFax1 + "-" + joinGroup.companyFax2 + "-" + joinGroup.companyFax3) === false) 
    // {
    //   return alert('입력된 값은 팩스번호 형식에 맞지 않습니다.');
    // }

    if(joinGroup.type === 0)
    {
      /* return alert('법인형태를 선택해주세요.'); */
      return alert(`${t("MypageParentsInfo.alert.string11")}`);
    }

    if(zoneCode === "")
    {
      /* return alert('우편번호를 입력해주세요.'); */
      return alert(`${t("MypageParentsInfo.alert.string12")}`);
    }

    if(fullAddress === "")
    {
      /* return alert('주소를 입력해주세요.'); */
      return alert(`${t("MypageParentsInfo.alert.string13")}`);
    }

    if(joinGroup.plusAddress === "")
    {
      /* return alert('상세 주소를 입력해주세요.'); */
      return alert(`${t("MypageParentsInfo.alert.string14")}`);
    }

    const companyInfo = {
      name: joinGroup.companyName,
      biz_code: joinGroup.bizCode1 + "-" + joinGroup.bizCode2 + "-" + joinGroup.bizCode3,
      phone: joinGroup.companyPhone1 + "-" + joinGroup.companyPhone2 + "-" + joinGroup.companyPhone3,
      fax: joinGroup.companyFax1 + "-" + joinGroup.companyFax2 + "-" + joinGroup.companyFax3,
      type: joinGroup.type,
      addr_part1: fullAddress,
      addr_part2: addressJson.bname,
      addr_detail: joinGroup.plusAddress,
      addr_sinm: addressJson.sido,
      addr_sggnm: addressJson.sigungu,
      addr_emdnm: addressJson.bname2,
      addr_zip: zoneCode
    };

    // console.log("companyInfo : ", companyInfo);

    navigate("/auth/RegisterStudentView", {
      state: {
        body: join,
        addbody:companyInfo,
      },
    });

    // 여기서 디스패치로~
    // dispatch(groupRegister({join: join.body, joinGroup: companyInfo}))
    // .then(data => 
    // {
    //     console.log("data : ", data);
    //     if (!data.success)
    //     {
    //       alert(data.msg);
    //     }
    //     else
    //     {
    //       localStorage.removeItem("mem_idx");
    //       //alert("가입완료");
    //       navigate("/auth/RegisterStudentView", {
    //         state: {
    //           parentIdx: data.msg,
    //         },
    //       });
    //     }
    // })
    // .catch(e => {
    //     console.log(e);
    // });
  };
 
	// 팝업창 열기
  const openPostCode = () => {
      setIsPopupOpen(true)
  };
 
	// 팝업창 닫기
  const closePostCode = () => {
    setIsPopupOpen(false)
  };

  // 자식의 주소 값을 가져와서 갱신합니다.
  const setAddressFun = (addr1, addr2, addr3) => {
    // console.log("addressData1 : ", addr1);
    setAddressJson(addr1);
  
    // console.log("addressData2 : ", addr2);
    setFullAddress(addr2);
  
    // console.log("addressData3 : ", addr3);
    setZoneCode(addr3);
  };

  useEffect(() => {

    // if (location.state === null)
    // {
    //   navigate("/");
    // }

    let isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent) ? true : false;
			
			if(!isMobile) 
			{
				  //PC
          setFontSizeValue({style: {fontSize: 15}});
      }
      else 
      {
          // mobile
          setFontSizeValue({style: {fontSize: 12}});
      }

      // console.log(typeof(fontSizeValue));

      // console.log("location?.state?.joinData : ", location?.state?.joinData);

      if (location?.state?.joinData !== undefined)
        setJoin(location?.state?.joinData);

  }, []);

  return (
    <>
      {/* {console.log("join : ", join)} */}
      <div className={`${styles.login_bg_wrapper} ${styles.join_group_bg_wrapper}`}>
        <div className={`${styles.join_group_area} ${styles.middle}`}>
          <div className={styles.login_top}>
            <a href="/" className={styles.logo}><img src="/assets/logo@2x.png" alt="logo"/></a>
          </div>
          <span className={styles.login_title}>{/* 단체 가입 추가 정보 */}{t('Auth.group_register')}</span>
          <div className={`${styles.join_group_wrap} ${styles.center_align}`}>
            <div className={`${styles.input_area} ${styles.company_name}`}>
              <TextField
                required
                fullWidth
                id="companyName"
                // label="회사 이름"
                label={t('Auth.common.company_name')}
                name="companyName"
                autoComplete="username"
                onChange={handleInputChange}
                value={joinGroup?.companyName}
                size="small"
                InputLabelProps={fontSizeValue} // font size of input label
              />
            </div>
            <div className={`${styles.input_area} ${styles.number} ${styles.cf}`}>
              <p>{/* 사업자번호 */}{t('Auth.common.biz_code')}*</p>
              <div className={`${styles.input_area} ${styles.box1}`}>
                <TextField
                  fullWidth
                  name="bizCode1"
                  // label="3자리숫자"
                  label={t('Auth.common.biz_code_3')}
                  id="bizCode1"
                  autoComplete="username"
                  onChange={handleInputChange}
                  value={joinGroup?.bizCode1}
                  size="small"
                  InputLabelProps={fontSizeValue} // font size of input label
                  inputProps={{
                    maxLength: 3,
                  }}
                />
              </div>
              <div className={`${styles.input_area} ${styles.box2}`}>
                <TextField
                  fullWidth
                  name="bizCode2"
                  // label="2자리숫자"
                  label={t('Auth.common.biz_code_2')}
                  id="bizCode2"
                  autoComplete="username"
                  onChange={handleInputChange}
                  value={joinGroup?.bizCode2}
                  size="small"
                  InputLabelProps={fontSizeValue} // font size of input label
                  inputProps={{
                    maxLength: 2,
                  }}
                />
              </div>
              <div className={`${styles.input_area} ${styles.box3}`}>
                <TextField
                  fullWidth
                  name="bizCode3"
                  // label="5자리숫자"
                  label={t('Auth.common.biz_code_5')}
                  id="bizCode3"
                  autoComplete="username"
                  onChange={handleInputChange}
                  value={joinGroup?.bizCode3}
                  size="small"
                  InputLabelProps={fontSizeValue} // font size of input label
                  inputProps={{
                    maxLength: 5,
                  }}
                />
              </div>
            </div>
            <div className={`${styles.input_area} ${styles.addstring}`}>
              <p>{/* 소속된 단체의 사업자 번호를 입력해주세요. */}{t('Auth.common.confirm_string8')}</p>
            </div>
            <div className={`${styles.input_area} ${styles.number} ${styles.cf}`}>
              <p>{/* 전화번호* */}{t('Auth.common.phone_number')}</p>
              <div className={`${styles.input_area} ${styles.box1}`}>
                <TextField
                  fullWidth
                  id="companyPhone1"
                  name="companyPhone1"
                  autoComplete="username"
                  onChange={handleInputChange}
                  value={joinGroup?.companyPhone1}
                  size="small"
                  InputLabelProps={fontSizeValue} // font size of input label
                  inputProps={{
                    maxLength: 4,
                  }}
                />
              </div>
              <div className={`${styles.input_area} ${styles.box2}`}>
                <TextField
                  fullWidth
                  id="companyPhone2"
                  name="companyPhone2"
                  autoComplete="username"
                  onChange={handleInputChange}
                  value={joinGroup?.companyPhone2}
                  size="small"
                  InputLabelProps={fontSizeValue} // font size of input label
                  inputProps={{
                    maxLength: 4,
                  }}
                />
              </div>
              <div className={`${styles.input_area} ${styles.box3}`}>
                <TextField
                  fullWidth
                  id="companyPhone3"
                  name="companyPhone3"
                  autoComplete="username"
                  onChange={handleInputChange}
                  value={joinGroup?.companyPhone3}
                  size="small"
                  InputLabelProps={fontSizeValue} // font size of input label
                  inputProps={{
                    maxLength: 4,
                  }}
                />
              </div>
            </div>
            <div className={`${styles.input_area} ${styles.number} ${styles.cf}`}>
              <p>{/* 팩스번호 */}{t('Auth.common.fax_number')}</p>
              <div className={`${styles.input_area} ${styles.box1}`}>
                <TextField
                  required
                  fullWidth
                  id="companyFax1"
                  name="companyFax1"
                  autoComplete="username"
                  onChange={handleInputChange}
                  value={joinGroup?.companyFax1}
                  size="small"
                  InputLabelProps={fontSizeValue} // font size of input label
                  inputProps={{
                    maxLength: 4,
                  }}
                />
              </div>
              <div className={`${styles.input_area} ${styles.box2}`}>
                <TextField
                  required
                  fullWidth
                  id="companyFax2"
                  name="companyFax2"
                  autoComplete="username"
                  onChange={handleInputChange}
                  value={joinGroup?.companyFax2}
                  size="small"
                  InputLabelProps={fontSizeValue} // font size of input label
                  inputProps={{
                    maxLength: 4,
                  }}
                />
              </div>
              <div className={`${styles.input_area} ${styles.box3}`}>
                <TextField
                  required
                  fullWidth
                  id="companyFax3"
                  name="companyFax3"
                  autoComplete="username"
                  onChange={handleInputChange}
                  value={joinGroup?.companyFax3}
                  size="small"
                  InputLabelProps={fontSizeValue} // font size of input label
                  inputProps={{
                    maxLength: 4,
                  }}
                />
              </div>
            </div>
            <div className={`${styles.input_area} ${styles.select_area}`}>
                <Select
                  labelId="type"
                  id="type"
                  name="type"
                  onChange={handleInputChange}
                  value={joinGroup?.type}
                  // label="유저 타입"
                  label={t('Auth.common.user_type')}
                  sx={{ minWidth: 130 }}
                  size="small"
                  InputLabelProps={fontSizeValue} // font size of input label
                >
                <MenuItem value={0} disabled>
                  <em>{/* 선택 */}{t('Auth.common.select')}</em>
                </MenuItem>
                <MenuItem value={1}>{/* 개인사업자 */}{t('Auth.common.company_type1')}</MenuItem>
                <MenuItem value={2}>{/* 영리법인 */}{t('Auth.common.company_type2')}</MenuItem>
                <MenuItem value={3}>{/* 비영리법인 */}{t('Auth.common.company_type3')}</MenuItem>
                <MenuItem value={4}>{/* 기타공식단체 */}{t('Auth.common.company_type4')}</MenuItem>
                <MenuItem value={5}>{/* 비공식단체 */}{t('Auth.common.company_type5')}</MenuItem>
              </Select>
              <span className={styles.select_name}>{/* 법인형태 */}{t('Auth.common.company_type')}* </span>
            </div>
            <div className={`${styles.input_area} ${styles.hr1}`}>
              <hr></hr>
            </div>
            <div className={styles.post_srch_wrap}>
              <a href="#" className={styles.post_srch} onClick={openPostCode}>{/* 우편번호 검색 */}{t('Auth.common.postal_code_search')}</a>
              {/* 팝업 생성 기준 div */}
              <div id='popupDom'>
                { isPopupOpen && (
                    <PopupDom>
                        <PopupPostCode onClose={closePostCode} 
                        setAddressFun={setAddressFun}
                        />
                    </PopupDom>
                )}
              </div>
            </div>
            <div className={`${styles.input_area} ${styles.adress_box} ${styles.post_number}`}>
              <TextField
                required
                fullWidth
                id="zoneCode"
                // label="우편번호"
                label={t('Auth.common.postal_code')}
                name="zoneCode"
                value={zoneCode}
                autoComplete="username"
                size="small"
                InputLabelProps={fontSizeValue} // font size of input label
              />
            </div>
            <div className={`${styles.input_area} ${styles.adress_box} ${styles.adress}`}>
              <TextField
                required
                fullWidth
                id="fullAddress"
                // label="주소"
                label={t('Auth.common.address')}
                name="fullAddress"
                value={fullAddress}
                autoComplete="username"
                size="small"
                InputLabelProps={fontSizeValue} // font size of input label
              />
            </div>
            <div className={`${styles.input_area} ${styles.adress_box} ${styles.adress_more}`}>
              <TextField
                required
                fullWidth
                id="plusAddress"
                // label="상세주소"
                label={t('Auth.common.detailed_address')}
                name="plusAddress"
                autoComplete="username"
                onChange={handleInputChange}
                size="small"
                InputLabelProps={fontSizeValue} // font size of input label
              />
            </div>
            <div className={`${styles.input_area} ${styles.addstring}`}>
              <p>{/* * 필수입력 사항입니다. */}{t('Auth.common.confirm_string3')}</p>
            </div>
            {/* <a href="#" className={styles.ok} onClick={handleSubmit}>학습자 생성하기</a> */}
            <a href="#" className={styles.ok} onClick={handleSubmit}>{/* 다음 */}{t('Auth.common.next')}</a>
          </div>
        </div>
      </div>
    </>
  );
}