import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

import Divider from '@mui/material/Divider';

import AddIcon from '@mui/icons-material/Add';

import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormLabel from '@mui/material/FormLabel';
import FormControlLabel from '@mui/material/FormControlLabel';

import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";

import { studentAddCreate, studentIdCheck, newClassStudentList } from "../../actions/student";
import { packageInfoList } from "../../actions/package";

import PackageSelectDialog from './PackageSelectDialog';

import { useTranslation, Trans } from 'react-i18next'; // 1. react-i18next import 

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function StudentAddDialog(props) {
  
	const [open, setOpen] = useState(false);

	const [name, setName] = useState("");
	const [id, setId] = useState("");
	const [nickname, setNickname] = useState("");
	const [birthdayYear, setBirthdayYear] = useState("");
	const [birthdayMonth, setBirthdayMonth] = useState("01");
	const [birthdayDay, setBirthdayDay] = useState("01");
	const [gender, setGender] = useState(1);
	const [password, setPassword] = useState("");
	const [repassword, setRepassword] = useState("");
	const [selProduct, setSelProduct] = useState("");
	const [idCheck, setIdCheck] = useState(false);
	const [email, setEmail] = useState("");

	const [fontSizeValue, setFontSizeValue] = useState(null);

	const [helperText, setHelperText] = useState("");

	const dispatch = useDispatch();	

	const { t, i18n } = useTranslation(); // 3. useTranslation hook 선언

	const authState = useSelector(state => state.auth);
    // console.log("authState : ", authState);

  const handleClickOpen = () => {

	if (props.myPackageList?.filter(row => row?.mm_state === "W", row => row?.mm_state_cd === "A").length === 0)
	{
		// alert("이용권 구매가 필요합니다.");
		alert(`${t("ClassStudentCommonDlg.alert.string1")}`);
		return false;
	}
	else
	{
		setOpen(true);
	}

  };
  const handleClose = () => {
    
	setOpen(false);
	
	// 생년월일이 초기화가 안되서 초기화함. (모든 값을 초기화해야 함)
	setName("");
	setId("");
	setNickname("");
	setGender(1);
	setPassword("");
	setRepassword("");
	setSelProduct("");
	setIdCheck(false);
	setEmail("");
	setHelperText("");
	setBirthdayYear("");
	setBirthdayMonth("01");
	setBirthdayDay("01");

  };

  const handleSubmit = (event) => {

	event.preventDefault();
	// console.log("selProduct : ", selProduct);
	// console.log("name : ", name);
	// console.log("id : ", id);
	// console.log("nickname : ", nickname);
	// console.log("birthdayYear : ", birthdayYear);
	// console.log("birthdayMonth : ", birthdayMonth);
	// console.log("birthdayDay : ", birthdayDay);
	// console.log("gender : ", gender);
	// console.log("password : ", password);
	// console.log("repassword : ", repassword);
	// console.log("idCheck : ", idCheck);

	// 임시 사용자의 정보는 반드시 변경되어야 사용이 가능합니다.
	// 그 조건을 만족해야지 업데이트를 하게 됩니다.

	// 학습자 아이디가 입력이 됬는지 중복체크는 통과했는지 검사함
	if (id === "")
	{
		// return alert("아이디를 입력해주세요!");
		return alert(`${t("ClassStudentCommonDlg.alert.string2")}`);
	}
	else
	{
		if (!idCheck)
		{
			// return alert("아이디 중복확인을 해주세요!");
			return alert(`${t("ClassStudentCommonDlg.alert.string3")}`);
		}

		const regId =  /^[A-Za-z0-9+]{2,50}$/;
		if (regId.test(id) === false) 
		{
			// return alert('아이디는 2자리 이상 50자리 이하의 영어와 숫자만 입력해주세요.');
			return alert(`${t("ClassStudentCommonDlg.alert.string4")}`);
		}
	}
	
	// 구매 상품을 선택했는지 체크, 이름, 닉네임, 생년월일, 성별 데이터가 있는지 체크
	if (selProduct === "")
	{
		// return alert("구매 상품을 선택해주세요!");
		return alert(`${t("ClassStudentCommonDlg.alert.string5")}`);
	}
	else if (name === "")
	{
		// return alert("이름을 입력해주세요!");
		return alert(`${t("ClassStudentCommonDlg.alert.string6")}`);
	}
	else if (nickname === "" || nickname === null)
	{
		// return alert("닉네임을 입력해주세요!");
		return alert(`${t("ClassStudentCommonDlg.alert.string7")}`);
	}
	else if (birthdayYear === "")
	{
		// return alert("출생년도를 선택해주세요!");
		return alert(`${t("ClassStudentCommonDlg.alert.string8")}`);
	}
	else if (gender === "")
	{
		// return alert("성별을 선택해주세요!");
		return alert(`${t("ClassStudentCommonDlg.alert.string9")}`);
	}		
	// else if (email === "")
	// {
	// 	return alert("부모님의 이메일 주소를 넣어주세요");
	// }

	// 정규 표현식으로 비밀번호 생성 규칙에 맞는지 체크합니다.
	let reg = new RegExp(/(?=.*\d{1,20})(?=.*[~`!@#$%\^&*()-+=]{1,20})(?=.*[a-zA-Z]{1,20}).{8,20}$/);
	// console.log(reg.test(join.password));
	
	if( !reg.test(password) ) 
	{
		// return  alert("비밀번호 생성 규칙은 영문, 숫자, 특수문자 포함 8자 이상 20자가지 입니다.");
		return alert(`${t("ClassStudentCommonDlg.alert.string10")}`);
	}

	// 패스워드가 정상적으로 입력됬는지 체크
	if (password !== "" && repassword !== "")
	{
		if (password !== repassword)
		{
			// return alert("비밀번호가 일치하지 않습니다.");
			return alert(`${t("ClassStudentCommonDlg.alert.string11")}`);
		}
	}
	else
	{
		// return alert("비밀번호를 설정해주세요!");
		return alert(`${t("ClassStudentCommonDlg.alert.string12")}`);
	}

	let memIdx = 0;

	if (authState.isLoggedIn)
	{
		memIdx = authState.user.idx;
	}
	else
	{
		// return alert("로그인이 필요합니다.");
		return alert(`${t("ClassStudentCommonDlg.alert.string13")}`);
	}

	let sendData = {
		selProduct:selProduct,
		mem_idx: memIdx,
		id:id,
		name:name,
		nickname:nickname,
		birthday:birthdayYear + "-" + birthdayMonth + "-" + birthdayDay,
		gender:gender,
		password:password,
		email: email,
		mc_idx:props.mcIdx
	};

	// console.log("sendData : ", sendData);

	// 여기서 디스패치로~
	dispatch(studentAddCreate(sendData))
	.then(data => 
	{
		// console.log("data : ", data);
		if (!data.success)
		{
			alert(data.msg);
			return;
		}
		else
		{
			const boardListSend = {
				board_page : 1,
				mc_idx: props.mcIdx,
			  }

			// 패키지의 정보를 다시 불러옵니다.
			dispatch(packageInfoList())
			.then(data => 
			{
				// console.log("packageInfoList data : ", data);
				// 학습자 리스트를 다시 불러옵니다.
				dispatch(newClassStudentList(boardListSend))
				.then(data => 
				{
					// console.log("classListAll : ", data);

					// alert("학습자 추가가 완료되었습니다. 즐거운 플레이 되세요~");
					alert(`${t("ClassStudentCommonDlg.alert.string14")}`);
					handleClose();
				})
				.catch(e => {
					console.log(e);
				});
			})
			.catch(e => {
				console.log(e);
			});
		  
			//   // 여기서 디스패치로~
			//   dispatch(newClassStudentList(boardListSend))
			//   .then(data => 
			//   {
			// 	  // console.log("classListAll : ", data);

			// 	  alert("학습자 추가가 완료되었습니다. 즐거운 플레이 되세요~");
			// 	  handleClose();
			//   })
			//   .catch(e => {
			// 	  console.log(e);
			//   });
		}
	})
	.catch(e => {
		console.log(e);
	});
	
};

const handleInputChange = event => {

	const { name, value } = event.target;

	// console.log("name : " + name + " vlaue : " + value);

	if (name === "id")
	{
		setId(value);

		// 아이디 값이 변경되면 다시 중복 체크를 하도록 합니다.
		setIdCheck(false);

		const regId =  /^[A-Za-z0-9+]{2,50}$/;
		// 아이디가 규칙에 맞는지 검사해서 규칙에 맞지 않으면 경고메세지를 뿌립니다.
		if(regId.test(value) === false)
		{
			setIdCheck(false);
			// setHelperText("아이디는 숫자와 영문으로만 가능합니다.");
			setHelperText(`${t("ClassStudentCommonDlg.alert.string15")}`);
		}

		// 아이디가 공백이면 스타일시트를 지웁니다.
		if (value === "")
		{
			setHelperText("");
		}
	}
	else if (name === "name")
	{
		setName(value);
	}
	else if (name === "nickname")
	{
		setNickname(value);
	}
	
	else if (name === "gender")
	{
		setGender(value);
	}
	else if (name === "password")
	{
		setPassword(value);
	}
	else if (name === "repassword")
	{
		setRepassword(value);
	}
	else if (name === "email")
	{
		setEmail(value);
	}
};

const hasError = (passwordEntered) =>
{
	// 정규 표현식으로 비밀번호 생성 규칙에 맞는지 체크합니다.
	let reg = new RegExp(/(?=.*\d{1,20})(?=.*[~`!@#$%\^&*()-+=]{1,20})(?=.*[a-zA-Z]{1,20}).{8,20}$/);
	// console.log(reg.test(join.password));
	return !reg.test(password);
}
    
const hasNotSameError = passwordEntered =>
	password != repassword ? true : false;

const getYears = () => 
{
	let date=new Date();
	let selYear=date.getFullYear();

	//올해 기준으로 -30년을 보여줌
	let stY=Number(selYear)-80;
	let nY=Number(selYear);

	const result = [];
	let index = 0;

	// for(let y=stY; y<=nY; y++)
	for(let y=nY; y>=stY; y--)
	{
		// result.push(<MenuItem value={y} key={index++}>{y}년</MenuItem>);
		result.push(<MenuItem value={y} key={index++}>{y}</MenuItem>);
	}

	return result;
};

const onChangeYear = (event) => {
	event.preventDefault();

	// console.log(event.target.value);
	setBirthdayYear(event.target.value);
}

// const onChangeMonth = (event) => {
// 	event.preventDefault();

// 	// console.log(event.target.value.padStart(2, '0'));
// 	setBirthdayMonth(event.target.value.padStart(2, '0'));
// }

// const onChangeDay = (event) => {
// 	event.preventDefault();

// 	// console.log(event.target.value.padStart(2, '0'));
// 	setBirthdayDay(event.target.value.padStart(2, '0'));
// }

const PackageDataTransFuntion = (value) => {
    // console.log(value);
	
	setSelProduct(value.mm_idx);
}

const studentIDCheck = (id, event) => {

	event.preventDefault();

	// 학습자 중복 아이디 검사
	// console.log("dispatch studentIDCheck");
	// console.log("id : ", id);

	if(id === "")
	{
		setHelperText("");
		// return alert("아이디를 입력해주세요!");
		return alert(`${t("ClassStudentCommonDlg.alert.string2")}`);
	}

	const regId = /^[A-Za-z0-9+]{2,50}$/;
	// 아이디가 규칙에 맞는지 검사해서 규칙에 맞지 않으면 경고메세지를 뿌립니다.
	if(!regId.test(id))
	{
		setIdCheck(false);
		// setHelperText("아이디는 숫자와 영문으로만 가능합니다.");
		setHelperText(`${t("ClassStudentCommonDlg.alert.string15")}`);
		return false;
	}

	dispatch(studentIdCheck(id))
	.then(data => 
	{
		// console.log("data : ", data);

		if(!data.success)
		{
			setIdCheck(true);
			// setHelperText(id + "은 사용가능합니다.");
			setHelperText(`${t("ClassStudentCommonDlg.alert.string16", {id : id})}`);
		}
		else
		{
			setIdCheck(false);
		  	// setHelperText(id + "은 중복된 이름입니다. 다른 이름을 사용하세요."); 
			  setHelperText(`${t("ClassStudentCommonDlg.alert.string17", {id : id})}`);
		}
	})
	.catch(e => {
		console.log(e);
	});
}

useEffect(() => {

      let isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent) ? true : false;
			
	  if(!isMobile) 
	  {
		  //PC
          setFontSizeValue({style: {fontSize: 15}});
      }
      else 
      {
          // mobile
          setFontSizeValue({style: {fontSize: 12}});
      }

      // console.log(typeof(fontSizeValue));

  }, []);

  return (
    <div>
      <Button variant="outlined" onClick={handleClickOpen} endIcon={<AddIcon />}>{/* 학습자 추가 */}{t('ClassStudentCommonDlg.title1')}</Button>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
          {/* 학습자 추가 */}{t('ClassStudentCommonDlg.title1')}
        </BootstrapDialogTitle>
        {/* <DialogContent dividers>
          <Typography gutterBottom>
            학습자의 아이디를 중복을 허용하지 않습니다. <br></br>아이디 중복 체크를 해주세요.
          </Typography>
        </DialogContent> */}
		<Box
		component="form"
		sx={{
			'& .MuiButton-root': { m: 1, width: '450px', marginTop: '20px', marginLeft: '20px', marginRight: '20px' },
			'& .MuiTypography-root': { m: 1, width: '450px', marginLeft: '20px', marginRight: '20px', fontSize:'14px' },
		}}
		noValidate
		autoComplete="off"
		>
			<PackageSelectDialog myPackageList={props.myPackageList} callbackFunction={PackageDataTransFuntion} />
		</Box>
		<Box
		component="form"
		sx={{
			display: 'flex',
    		alignItems: 'center',
			'& .MuiTextField-root': { m: 1, width: '260px', marginTop: '20px', marginLeft: '20px'},
			'& .MuiButton-root': { m: 1},
		}}
		noValidate
		autoComplete="off"
		>
			<TextField
                required
                fullWidth
                id="id"
                // label="아이디"
				label={t('ClassStudentCommonDlg.string2')}
                name="id"
                autoComplete="username"
                onChange={handleInputChange}
                InputLabelProps={fontSizeValue} // font size of input label
				inputProps={{ maxLength: 50 }}
				helperText={helperText}
            />
			<Button onClick={(e)=>studentIDCheck(id, e)}>{/* 중복확인 */}{t('Auth.common.duplicate')}</Button>
		</Box>
		<Box
		component="form"
		sx={{
			'& .MuiTextField-root': { m: 1, width: '250px', marginTop: '20px', marginLeft: '20px' },
		}}
		noValidate
		autoComplete="off"
		>
		<div>
			<TextField
                required
                fullWidth
                id="name"
                // label="이름"
				label={t('ClassStudentCommonDlg.string3')}
                name="name"
                onChange={handleInputChange}
                InputLabelProps={fontSizeValue} // font size of input label
            />
		</div>
		</Box>
		<Box
		component="form"
		sx={{
			'& .MuiTextField-root': { m: 1, width: '250px', marginTop: '20px', marginLeft: '20px', marginBottom: '20px' },
		}}
		noValidate
		autoComplete="off"
		>
		<div>
			<TextField
                  required
                  fullWidth
                  id="nickname"
                //   label="닉네임"
				  label={t('ClassStudentCommonDlg.string4')}
                  name="nickname"
                  onChange={handleInputChange}
                  inputProps={{ maxLength: 7 }}
                  InputLabelProps={fontSizeValue} // font size of input label
              />
		</div>
		</Box>
		<Box
		component="form"
		sx={{
			'& .MuiTextField-root': { m: 1, width: '250px', marginTop: '20px', marginLeft: '20px', marginBottom: '20px' },
		}}
		noValidate
		autoComplete="off"
		>
		<div>
			<TextField
                  fullWidth
                  id="email"
                //   label="부모님 이메일 주소"
				  label={t('ClassStudentCommonDlg.string5')}
                  name="email"
                  onChange={handleInputChange}
                  inputProps={{ maxLength: 50 }}
                  InputLabelProps={fontSizeValue} // font size of input label
              />
		</div>
		</Box>
		<Box
		component="form"
		sx={{
			'& .MuiTypography-root': { m: 1, width: '350px',  marginLeft: '20px', fontSize: '14px' },
			'& .MuiFormLabel-root': { m: 1, width: '350px', marginLeft: '20px' },
		}}
		noValidate
		autoComplete="off"
		>
		<div>
			<FormLabel id="birthday">{/* 출생년도 */}{t('ClassStudentCommonDlg.string6')}</FormLabel>
			<Typography variant="subtitle1" gutterBottom>{/* 출생년도를 정확하게 입력해주시면 학습자의 데이터 분석에 많은 도움이 됩니다. */}
			{t('ClassStudentCommonDlg.string7')}</Typography>
			<FormControl sx={{ m: 1, minWidth: 120, marginLeft: '20px' }}>
              <Select
                value={birthdayYear}
                onChange={(e)=>onChangeYear(e)}
                displayEmpty
                name='year'
                inputProps={{ 'aria-label': 'Without label' }}
              >
                <MenuItem value="" disabled>
                  <em>{/* 년 */}{t('ClassStudentCommonDlg.string15')}</em>
                </MenuItem>
                {getYears()}
              </Select>
            </FormControl>
			{/* <FormControl sx={{ m: 1, minWidth: 80 }}>
              <Select
                value={birthdayMonth}
                onChange={(e)=>onChangeMonth(e)}
                displayEmpty
                name='month'
                inputProps={{ 'aria-label': 'Without label' }}
              >
                <MenuItem value="" disabled>
                  <em>월</em>
                </MenuItem>
                <MenuItem value={`01`}>1월</MenuItem>
                <MenuItem value={`02`}>2월</MenuItem>
                <MenuItem value={`03`}>3월</MenuItem>
                <MenuItem value={`04`}>4월</MenuItem>
                <MenuItem value={`05`}>5월</MenuItem>
                <MenuItem value={`06`}>6월</MenuItem>
                <MenuItem value={`07`}>7월</MenuItem>
                <MenuItem value={`08`}>8월</MenuItem>
                <MenuItem value={`09`}>9월</MenuItem>
                <MenuItem value={`10`}>10월</MenuItem>
                <MenuItem value={`11`}>11월</MenuItem>
                <MenuItem value={`12`}>12월</MenuItem>
              </Select>
            </FormControl>
            <FormControl sx={{ m: 1, minWidth: 80 }}>
              <Select
                value={birthdayDay}
                onChange={(e)=>onChangeDay(e)}
                displayEmpty
                name='day'
                inputProps={{ 'aria-label': 'Without label' }}
              >
                <MenuItem value="" disabled>
                  <em>일</em>
                </MenuItem>
                <MenuItem value={`01`}>1</MenuItem>
                <MenuItem value={`02`}>2</MenuItem>
                <MenuItem value={`03`}>3</MenuItem>
                <MenuItem value={`04`}>4</MenuItem>
                <MenuItem value={`05`}>5</MenuItem>
                <MenuItem value={`06`}>6</MenuItem>
                <MenuItem value={`07`}>7</MenuItem>
                <MenuItem value={`08`}>8</MenuItem>
                <MenuItem value={`09`}>9</MenuItem>
                <MenuItem value={`10`}>10</MenuItem>
                <MenuItem value={`11`}>11</MenuItem>
                <MenuItem value={`12`}>12</MenuItem>
                <MenuItem value={`13`}>13</MenuItem>
                <MenuItem value={`14`}>14</MenuItem>
                <MenuItem value={`15`}>15</MenuItem>
                <MenuItem value={`16`}>16</MenuItem>
                <MenuItem value={`17`}>17</MenuItem>
                <MenuItem value={`18`}>18</MenuItem>
                <MenuItem value={`19`}>19</MenuItem>
                <MenuItem value={`20`}>20</MenuItem>
                <MenuItem value={`21`}>21</MenuItem>
                <MenuItem value={`22`}>22</MenuItem>
                <MenuItem value={`23`}>23</MenuItem>
                <MenuItem value={`24`}>24</MenuItem>
                <MenuItem value={`25`}>25</MenuItem>
                <MenuItem value={`26`}>26</MenuItem>
                <MenuItem value={`27`}>27</MenuItem>
                <MenuItem value={`28`}>28</MenuItem>
                <MenuItem value={`29`}>29</MenuItem>
                <MenuItem value={`30`}>30</MenuItem>
                <MenuItem value={`31`}>31</MenuItem>
              </Select>
            </FormControl> */}
		</div>
		</Box>

		<Box
		component="form"
		sx={{
			'div': { m: 1, width: '250px', marginTop: '10px', marginLeft: '20px' },
		}}
		noValidate
		autoComplete="off"
		>
		<div>
			<FormLabel id="gender">{/* 성별 */}{t('ClassStudentCommonDlg.string8')}</FormLabel>
            <RadioGroup
              row
              aria-labelledby="gender"
              name="gender"
              defaultValue={1}
              onChange={handleInputChange}
            >
              <FormControlLabel value="1" control={<Radio />} /* label="남자" */ label={t('ClassStudentCommonDlg.string9')} />
              <FormControlLabel value="2" control={<Radio />} /* label="여자" */ label={t('ClassStudentCommonDlg.string10')} />
            </RadioGroup>
		</div>
		</Box>

		<Box
		component="form"
		sx={{
			'& .MuiTextField-root': { m: 1, width: '450px', marginTop: '20px', marginLeft: '20px' },
		}}
		noValidate
		autoComplete="off"
		>
		<div>
			<TextField
                required
                fullWidth
                name="password"
                // label="비밀번호 영문,숫자,특수문자 조합 8~20자리 권장"
				label={t('ClassStudentCommonDlg.string11')}
                type="password"
                id="password"
                error={hasError('password')} // 해당 텍스트필드에 error 핸들러 추가
                onChange={handleInputChange}
                autoComplete="new-password"
                InputLabelProps={fontSizeValue} // font size of input label
                inputProps={{ maxLength: 100 }}
              />
			  <p style={{fontSize: '13px', paddingLeft: '20px'}}>{t('ClassStudentCommonDlg.string11')}</p>
		</div>
		</Box>

		<Box
		component="form"
		sx={{
			'& .MuiTextField-root': { m: 1, width: '450px', marginTop: '20px', marginLeft: '20px' },
			'div': { marginBottom: '10px' },
		}}
		noValidate
		autoComplete="off"
		>
		<div>
			<TextField
                required
                fullWidth
                name="repassword"
                // label="비밀번호 확인"
				label={t('ClassStudentCommonDlg.string12')}
                type="password"
                id="repassword"
                error={hasNotSameError('repassword')} // 해당 텍스트필드에 error 핸들러 추가
                helperText={
                    // hasNotSameError('repassword') ? "입력한 비밀번호와 일치하지 않습니다." : null
					hasNotSameError('repassword') ? t('ClassStudentCommonDlg.string13') : null
                } // 에러일 경우에만 안내 문구 표시
                onChange={handleInputChange}
                autoComplete="new-password"
                InputLabelProps={fontSizeValue} // font size of input label
                inputProps={{ maxLength: 100 }}
              />
		</div>
		</Box>

		<Divider />
		
        <DialogActions>
          <Button onClick={handleSubmit}>
            {/* 추가 */}{t('ClassStudentCommonDlg.string14')}
          </Button>
		  <Button onClick={handleClose}>
            {/* 취소 */}{t('Common.cancel')}
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}