import {
  LIST_SOLUTION,
  CREATE_SOLUTION,
  MODIFY_SOLUTION,
  REPLY_SOLUTION,
  DELETE_SOLUTION,
  SELECT_SOLUTION,
  LIST_SOLUTION_NONMEMBER,
  CREATE_SOLUTION_NONMEMBER,
  MODIFY_SOLUTION_NONMEMBER,
  DELETE_SOLUTION_NONMEMBER,
  SELECT_SOLUTION_NONMEMBER,
  CATEGORY_SOLUTION,
  CHECK_SOLUTION_NONMEMBER,
} from "./types";

import SolutionService from "../services/SolutionService";

export const solutionList = data => async (dispatch) => {
  
  try {
    
    const res = await SolutionService.solutionGetAll(data);

    // console.log("res : ", res);

    dispatch({
      type: LIST_SOLUTION,
      payload: res.data.data,
    });

    return Promise.resolve(res.data.data);

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};

export const solutionCreate = (data) => async (dispatch) => {
  
  try {
    
    const res = await SolutionService.solutionCreate(data);

    // console.log("res : ", res);

    dispatch({
      type: CREATE_SOLUTION,
      payload: res.data,
    });

    return Promise.resolve(res.data);

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};

export const solutionModify = (data) => async (dispatch) => {
  
  try {
    
    const res = await SolutionService.solutionModify(data);

    // console.log("res : ", res);

    dispatch({
      type: MODIFY_SOLUTION,
      payload: res.data,
    });

    return Promise.resolve(res.data);

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};

export const solutionReply = (data) => async (dispatch) => {
  
  try {
    
    const res = await SolutionService.solutionReply(data);

    // console.log("res : ", res);

    dispatch({
      type: REPLY_SOLUTION,
      payload: res.data,
    });

    return Promise.resolve(res.data);

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};

export const solutionDelete = (data) => async (dispatch) => {
  
  try {

    const res = await SolutionService.solutionDelete(data);

    // console.log("res : ", res);

    if (res.data.success === false)
    {
      alert(res.data.msg);
      return Promise.resolve(res.data);
    }
    else
    {
      dispatch({
        type: DELETE_SOLUTION,
        payload: res.data,
      });
  
      return Promise.resolve(res.data);
    }

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};

export const solutionSelect = data => async (dispatch) => {
  
  try {
    
    const res = await SolutionService.solutionSelect(data);

    // console.log("res : ", res);

    dispatch({
      type: SELECT_SOLUTION,
      payload: res.data.data,
    });

    return Promise.resolve(res.data.data);

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};

export const solutionNonMemberList = data => async (dispatch) => {
  
  try {
    
    const res = await SolutionService.solutionNonMemberGetAll(data);

    // console.log("res : ", res);

    dispatch({
      type: LIST_SOLUTION_NONMEMBER,
      payload: res.data.data,
    });

    return Promise.resolve(res.data.data);

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};

export const solutionNonMemberCreate = (data) => async (dispatch) => {
  
  try {
    
    const res = await SolutionService.solutionNonMemberCreate(data);

    // console.log("res : ", res);

    dispatch({
      type: CREATE_SOLUTION_NONMEMBER,
      payload: res.data,
    });

    return Promise.resolve(res.data);

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};

export const solutionNonMemberModify = (data) => async (dispatch) => {
  
  try {
    
    const res = await SolutionService.solutionNonMemberModify(data);

    // console.log("res : ", res);

    dispatch({
      type: MODIFY_SOLUTION_NONMEMBER,
      payload: res.data,
    });

    return Promise.resolve(res.data);

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};

export const solutionNonMemberDelete = (data) => async (dispatch) => {
  
  try {

    const res = await SolutionService.solutionNonMemberDelete(data);

    // console.log("res : ", res);

    if (res.data.success === false)
    {
      alert(res.data.msg);
      return Promise.resolve(res.data);
    }
    else
    {
      dispatch({
        type: DELETE_SOLUTION_NONMEMBER,
        payload: res.data,
      });
  
      return Promise.resolve(res.data);
    }

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};

export const solutionNonMemberSelect = data => async (dispatch) => {
  
  try {
    
    const res = await SolutionService.solutionNonMemberSelect(data);

    // console.log("res : ", res);

    dispatch({
      type: SELECT_SOLUTION_NONMEMBER,
      payload: res.data.data,
    });

    return Promise.resolve(res.data.data);

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};

export const solutionCategory = data => async (dispatch) => {
  
  try {
    
    const res = await SolutionService.solutionCategoryGetAll(data);

    // console.log("res : ", res);

    dispatch({
      type: CATEGORY_SOLUTION,
      payload: res.data.data,
    });

    return Promise.resolve(res.data.data);

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};

export const solutionNonMemberCheck = (data) => async (dispatch) => {
  
  try {
    
    const res = await SolutionService.solutionNonMemberCheck(data);

    // console.log("res : ", res);

    dispatch({
      type: CHECK_SOLUTION_NONMEMBER,
      payload: res.data,
    });

    return Promise.resolve(res.data);

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};
