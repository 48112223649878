import http from "../http-common";

// const getAll = () => {
//   http.get("users/commonUser", { headers: httpheader() })
//   .then(function (response) {
//     console.log("response : ", response);
//     return response;
//   })
//   .catch(function (error) {
//     if (error.response) {
//       // 요청이 이루어졌으며 서버가 2xx의 범위를 벗어나는 상태 코드로 응답했습니다.
//       console.log("error response data : ", error.response.data);
//       console.log("error response status : ", error.response.status);
//       console.log("error response headers : ", error.response.headers);

//       if (!error.response.data.success)
//       {
//         return "400 error";
//       }
//     }
//     else if (error.request) {
//       // 요청이 이루어 졌으나 응답을 받지 못했습니다.
//       // `error.request`는 브라우저의 XMLHttpRequest 인스턴스 또는
//       // Node.js의 http.ClientRequest 인스턴스입니다.
//       console.log("error request : ", error.request);
//     }
//     else {
//       // 오류를 발생시킨 요청을 설정하는 중에 문제가 발생했습니다.
//       console.log('Error', error.message);
//     }
//     console.log("error config : ", error.config);
//   });
// };

const userCreate = data => {
  return http.post("users/commonUser", data);
};

const userGroupCreate = data => {
  return http.post("users/groupUser", data);
};

const userModify = data => {
  return http.put("users/commonUser", data);
};

const userGroupModify = data => {
  return http.put("users/groupUser", data);
};

const userDelete = data => {
  return http.delete("users/commonUser", {data : data});
};

const userActive = data => {
  return http.put("users/commonUserActive", data);
};

const userPasswordChange = data => {
  return http.put("users/commonUserPassword", data);
};

const getAll = data => {

  if (data.condition !== "" && data.keyword !== "")
    return http.get(`users/commonUser/${data.board_page}/${data.condition}/${data.keyword}`);
  else
    return http.get(`users/commonUser/${data.board_page}`);
    
};

const getSelect = email => {
  return http.get(`users/commonUserSelect/${email}`);
    
};

const getGroupSelect = data => {
  return http.get(`users/groupUser/${data.email}`);
    
};

const me = data => {
  return http.post("users/me", data);
};

const userMissionTotal = data => {
  return http.get(`users/totalMission/${data}`);  
};

const userMissionProgress = data => {
  return http.get(`users/missionProgress/${data}`);  
};

const userKnowledgeAnswer = data => {
  return http.get(`users/knowledgeAnswer/${data}`);  
};

const userTotalHumanAbility = () => {
  return http.get(`users/totalHumanAbility`);  
};

const userHumanAbility = data => {
  return http.get(`users/humanAbility/${data}`);  
};

const parentIdCheck = data => {
  return http.get(`users/commonUserIDCheck/${data}`);
};

const userPhoneModify = data => {
  return http.put("users/commonUser/phone", data);
};

const userLeave = data => {
  return http.post("users/commonUserLeave", data);
};

const userStudentUse = data => {
  return http.put("users/commonStudent/use", data);
};

const getGroupUserAll = data => {

  if (data.condition !== "" && data.keyword !== "")
    return http.get(`users/Group/commonUser/${data.board_page}/${data.condition}/${data.keyword}/${data.classvalue}`);
  else
    return http.get(`users/Group/commonUser/${data.board_page}`);
    
};

const getGroupUserSelect = email => {
  return http.get(`users/Group/commonUserSelect/${email}`);
    
};

const getGroupUserInfo = mem_idx => {
  return http.get(`users/Group/commonUserInfo/${mem_idx}`);
    
};

const getGroupUserClassInfo = mem_idx => {
  return http.get(`users/Group/commonUserClassInfo/${mem_idx}`);
    
};

const getClassUserAll = mc_idx => {
    return http.get(`users/commonClassStudent/${mc_idx}`);
};

const UsersService = {
  getAll,
  userCreate,
  userGroupCreate,
  userModify,
  userGroupModify,
  userDelete,
  userActive,
  userPasswordChange,
  getSelect,
  getGroupSelect,
  me,
  userMissionTotal,
  userMissionProgress,
  userKnowledgeAnswer,
  userTotalHumanAbility,
  userHumanAbility,
  parentIdCheck,
  userPhoneModify,
  userLeave,
  userStudentUse,
  getGroupUserAll,
  getGroupUserSelect,
  getGroupUserInfo,
  getGroupUserClassInfo,
  getClassUserAll,
};

export default UsersService;
