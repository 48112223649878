import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { rankingChallengeList } from "../../../actions/ranking";

import { useNavigate, useParams } from 'react-router-dom';

export default function RankingChallengeList() {

    const ranking_list = useSelector(state => state.ranking?.challengelist);
    console.log("ranking_list : ", ranking_list);
    let idx  = useParams().idx;
    console.log("idx : ", idx);
    let order  = useParams().order;
    console.log("order : ", order);
    let board_page  = useParams().prevPage;
    console.log("board_page : ", board_page);

    if (board_page === undefined)
          board_page = 1;

    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {

        // 여기서 디스패치로~
        dispatch(rankingChallengeList(idx))
        .then(data => 
        {
            console.log("data : ", data);
        })
        .catch(e => {
            console.log(e);
        });
        
        return () => {      // 언마운트
            console.log("언마운트!");
        };

    }, [dispatch])

    const onPrevPage = () => {
      navigate(`/dashboard/RankingList/${board_page}`);
    }

  return (
    <Card sx={{ minWidth: 275 }}>
      <CardContent>
      <Button variant="contained" size='small' onClick={onPrevPage} sx={{margin:2}}> 이전으로</Button> 
       야미 랭킹 {order}회차 도전 데이터 (데이터는 최대 100개까지만 제공합니다. 나머지 데이터들은 DB를 직접 조회하세요!)
      <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="center">일련번호</TableCell>
            <TableCell align="center">학습자 아이디</TableCell>
            <TableCell align="center">학습자 이름</TableCell>
            <TableCell align="center">등급</TableCell>
            <TableCell align="center">풀었던 개수</TableCell>
            <TableCell align="center">풀었던 시간</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {ranking_list?.length !== 0 ? ranking_list && ranking_list.map((row, index) => (
            <TableRow
              key={index}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row" align="center">
                {row.idx}
              </TableCell>
              <TableCell align="center">{row.mst_id}</TableCell>
              <TableCell align="center">{row.mst_name}</TableCell>
              <TableCell align="center">{row.rating === "M" ? "중급" : "고급" }</TableCell>
              <TableCell align="center">{row.number}</TableCell>
              <TableCell align="center">{row.solve_time}</TableCell>
            </TableRow>
          )) 
          :  
            <TableRow
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row" align="center" colSpan="7">데이터 없음</TableCell>
            </TableRow>
        }
        </TableBody>
      </Table>
      </TableContainer>
      </CardContent>
    </Card>
  );
}