import React from 'react';
import { useSelector, useDispatch } from "react-redux";
import { logout } from "../../actions/auth";
import GoogleLogoutButton from '../auth/GoogleLogoutButton';

function LoginDiv (props) 
{
    return (
        <>
            {props.user.name}<br /><br />
            {props.user.email}<br /><br />
            {props.user.nickname}<br /><br />
            {props.user.accessToken}<br /><br />
            <button onClick={() => props.logOut()}>logout</button>
        </>
    );
};

function GoogleLogout (props)
{
    return (
        <>
            {props.user.name}<br /><br />
            {props.user.email}<br /><br />
            {props.user.nickname}<br /><br />
            {props.user.accessToken}<br /><br />
            <GoogleLogoutButton />
        </>
    );
};

const DashboardMain = () => {

    const auth = useSelector(state => state.auth);
    // console.log("auth : ", auth);
    const dispatch = useDispatch();

    const logOut = () => 
    {
        dispatch(logout());
    }

    return (
        <>
            Dashboard Main <br /> {auth ? 
                auth.user.type !== 3 ? <LoginDiv user={auth.user} logOut={logOut} /> 
                : 
                <GoogleLogout user={auth.user} />  
            : "" }

        </>
    );
    
};

export default DashboardMain;