import http from "../http-common";

const classCreate = data => {
  return http.post("users/commonClass", data);
};

const classModify = data => {
  return http.put("users/commonClass", data);
};

const classDelete = data => {
  return http.delete("users/commonClass", {data : data});
};

const classListAll = data => {

  if (data.condition !== "" && data.keyword !== "")
    return http.get(`users/commonClass/${data.board_page}/${data.condition}/${data.keyword}`);
  else
    return http.get(`users/commonClass/${data.board_page}`);
    
};

const classSelect = data => {
  return http.get(`users/commonClassSelect/${data}`);
    
};

const classNameCheck = data => {
  return http.get(`users/commonClassNameCheck/${data}`);
    
};

const classAdminListAll = data =>
{
  return http.get(`users/commonClassAdmin/${data}`);
}

const classManagementListAll = data => {

  if (data.category_idx !== "" || data.level !== "")
    return http.get(`missionGroupMapList/${data.mc_idx}/${data.category_idx}/${data.level}`);
  else
    return http.get(`missionGroupMapList/${data.mc_idx}`);
    
};

const classManagementSet = data => {
  return http.post("missionGroup/product", data);
};

const classManagementStatusGet = data =>
{
  return http.get(`users/commonClassManagementStatus/${data}`);
}

const classManagementStatusSet = data => {
  return http.put("users/commonClassManagementStatus", data);
};

const classManagementSavedListAll = data => {

    return http.get(`missionGroupMapSavedList/${data.mc_idx}`);  
};

const classHomeworkListAll = data => {

  if (data.category_idx !== "" || data.level !== "")
    return http.get(`missionGroupHomeworkList/${data.mc_idx}/${data.mst_idx}/${data.category_idx}/${data.level}`);
  else
    return http.get(`missionGroupHomeworkList/${data.mc_idx}/${data.mst_idx}`);
    
};

const classHomeworkSelectAll = data => {
    return http.get(`missionGroupHomeworkSelect/${data.hm_idx}/${data.mst_idx}/${data.mis_idx}`);
};

const classHomeworkCommentInsert = data => {
  return http.post("homeworkComment", data);
};

const classHomeworkCommentSelectect = data => {
  return http.get(`homeworkCommentSelect/${data.mis_idx}/${data.mst_idx}`);
    
};

const classHomeworkCommentDelete = data => {
  return http.delete("homeworkComment", {data : data});
};
    
const ClassService = {
  classCreate,
  classModify,
  classDelete,
  classListAll,
  classSelect,
  classNameCheck,
  classAdminListAll,
  classManagementListAll,
  classManagementSet,
  classManagementStatusGet,
  classManagementStatusSet,
  classManagementSavedListAll,
  classHomeworkListAll,
  classHomeworkSelectAll,
  classHomeworkCommentInsert,
  classHomeworkCommentSelectect,
  classHomeworkCommentDelete,
};

export default ClassService;
