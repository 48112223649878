import http from "../http-common";

const bbsCreate = data => {
  return http.post("bbs/board/add", data);
};

const bbsReplyCreate = data => {
  return http.post("bbs/board/reply", data);
};

const bbsGetAll = data => {

  console.log("bbsGetAll data : ", data);

  if (data.condition !== "" && data.keyword !== "")
    return http.get(`bbs/board/${data.board_name}/${data.board_page}/${data.condition}/${data.keyword}`);
  else
    return http.get(`bbs/board/${data.board_name}/${data.board_page}`);
};

const bbsModify = data => {
  return http.put("bbs/board/edit", data);
};

const bbsSelect = (bbs_name, idx) => {
  return http.get(`bbs/board/select/${bbs_name}/${idx}`);
};

const bbsDelete = data => {
  return http.delete("bbs/board/del", {data : data});
};

const BBSService = {
  bbsCreate,
  bbsReplyCreate,
  bbsGetAll,
  bbsModify,
  bbsSelect,
  bbsDelete,
};

export default BBSService;
