import {
  CREATE_INAPP_PRODUCT,
  LIST_INAPP_PRODUCT,
  DELETE_INAPP_PRODUCT,
  MODIFY_INAPP_PRODUCT,
  SELECT_INAPP_PRODUCT,
} from "../actions/types";

const initialState = [];

const inappProductReducer = (inapp_product = initialState, action) => {
  const { type, payload } = action;

  // console.log("productReducer Call!");
  // console.log("type : ", type);
  // console.log("payload : ", payload);

  switch (type) {
    
    case CREATE_INAPP_PRODUCT:
      return [];

    case LIST_INAPP_PRODUCT:
      return payload;

    case SELECT_INAPP_PRODUCT:
      return payload;

    case MODIFY_INAPP_PRODUCT:
      return inapp_product.map((inapp_product) => {
        if (inapp_product.ina_idx === inapp_product.ina_idx) {
          return {
            ...inapp_product,
            ...payload,
          };
        } else {
          return inapp_product;
        }
      });

    case DELETE_INAPP_PRODUCT:
      return inapp_product.filter(({ ina_idx }) => ina_idx !== payload.ina_idx);

    default:
      return inapp_product;
  }
};

export default inappProductReducer;