import React from 'react';
import { useEffect } from 'react';
import styles from "../css/common.module.css";
import $ from 'jquery';

const YameLoading = (props) => {

  useEffect(() => 
  {
    
    //페이지 로딩되는 동안은 .pageLoading가 보임
    // $(window).on('load', function()
    // {
    //   $(`.${styles.pageLoading}`).fadeOut(); //로딩객체 제거
    // });
    if (props.loadingValue)
    {
        $(`.${styles.pageLoading}`).fadeOut(); //로딩객체 제거
    }

  }, [$, props]);

  return (
    <>
    {
        props.display ? <div className={styles.pageLoading}>
        <div className={styles.loadingImgArea}>
          <div className={`${styles.loadingImgMoveWrap} ${styles.loadingBaloon}`}>
            <div className={styles.loadingImgMove}><img src="/assets/loading.png" alt="loading img"/></div>
          </div>
        </div>
      </div>
      :
      ""
    }
    </>
  );
};

export default YameLoading;
