import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import { FormControl, TextField, Select, MenuItem, InputLabel } from '@mui/material';

import Stack from '@mui/material/Stack';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import MobileDatePicker from '@mui/lab/MobileDatePicker';
import Divider from '@mui/material/Divider';

import ImgFileUpload from '../../../common/ImgFileUpload';

import { useDispatch, useSelector } from "react-redux";
import { productSelect, productModify } from "../../../actions/product";
import { categoryList } from "../../../actions/category";

import { useEffect, useState } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import MissionEditDialog from './MissionEditDialog';

import * as common from "../../../lib";

export default function ProductModify() {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const prd_idx  = useParams().prd_idx;
    console.log("prd_idx : ", prd_idx);

    const initialProductState = {
        prd_idx: null,
        category_idx: null, 
        product_name: null,
        product_desc: null,
        default_amount: 1,
        expiration_date_unit: null,
        expiration_date: 1,
        sell_prc_unit: 1,
        sell_start_dt: null,
        sell_end_dt: null,
        file: null,
        fileName: null,
        filePath: null,
        fileChange: false,
        mis_count: null,
        total_mission: null,
        option1: [],
        option2: [],
    };

    const [product, setProduct] = useState(initialProductState);

    const category = useSelector(state => state.category);
    console.log("category : ", category);

    const handleInputChange = event => {
        const { name, value } = event.target;
        setProduct({ ...product, [name]: value });
    };

    const handleStartDateChange = (newValue) => {
        setProduct({ ...product, sell_start_dt: newValue });
    };

    const handleEndDateChange = (newValue) => {
        setProduct({ ...product, sell_end_dt: newValue });
    };

    const handleImgFileChange = (file) => {
        console.log("file : ", file);
        setProduct({ ...product, file: file, fileName: file.name, fileChange: true });
    };

    const refreshFunction = () => {
       window.location.reload();
    };

    const handleOption1Change = event => {
        const { name, value } = event.target;

        console.log("name : ", name);
        console.log("value : ", value);

        let nameSplit = name.split("-");
        let index = parseInt(nameSplit[0]);
        let selectType = nameSplit[1];
        let option1 = [...product.option1];

        if (selectType === "po1_count_start")
            option1[index] = {"po1_count_start": value, "po1_count_end": product.option1[index].po1_count_end,
            "po1_amt": product.option1[index].po1_amt, "expiration_date_unit": product.option1[index].expiration_date_unit, "expiration_date": product.option1[index].expiration_date};
        else if (selectType === "po1_count_end")
            option1[index] = {"po1_count_start": product.option1[index].po1_count_start, "po1_count_end": value,
            "po1_amt": product.option1[index].po1_amt, "expiration_date_unit": product.option1[index].expiration_date_unit, "expiration_date": product.option1[index].expiration_date};
        else if (selectType === "po1_amt")
            option1[index] = {"po1_count_start": product.option1[index].po1_count_start, "po1_count_end": product.option1[index].po1_count_end,
            "po1_amt": value, "expiration_date_unit": product.option1[index].expiration_date_unit, "expiration_date": product.option1[index].expiration_date};
        else if (selectType === "expiration_date_unit")
            option1[index] = {"po1_count_start": product.option1[index].po1_count_start, "po1_count_end": product.option1[index].po1_count_end,
            "po1_amt": product.option1[index].po1_amt, "expiration_date_unit": value, "expiration_date": product.option1[index].expiration_date};
        else if (selectType === "expiration_date")
            option1[index] = {"po1_count_start": product.option1[index].po1_count_start, "po1_count_end": product.option1[index].po1_count_end,
            "po1_amt": product.option1[index].po1_amt, "expiration_date_unit": product.option1[index].expiration_date_unit, "expiration_date": value};

        setProduct({ ...product, ["option1"]: option1 });
    }

    const handleOption2Change = event => {
        const { name, value } = event.target;

        console.log("name : ", name);
        console.log("value : ", value);

        let nameSplit = name.split("-");
        let index = parseInt(nameSplit[0]);
        let selectType = nameSplit[1];
        let option2 = [...product.option2];

        if (selectType === "po2_name")
            option2[index] = {"po2_name": value, "po2_price": product.option2[index].po2_price};
        else if (selectType === "po2_price")
            option2[index] = {"po2_name": product.option2[index].po2_name, "po2_price": value};

        setProduct({ ...product, ["option2"]: option2 });
    }

    const clickDelOption1 = (event, index) => {
        event.preventDefault();

        product.option1.splice(index, 1);
        setProduct({...product, ["option1"]: product.option1});
    };

    const clickAddOption1 = () => {
        setProduct({...product, ["option1"]: product.option1.concat([{"po1_count_start": "", "po1_count_end": "", "po1_amt": "", "expiration_date_unit": "", "expiration_date": ""}])});
    };

    const clickDelOption2 = (event, index) => {
        event.preventDefault();

        product.option2.splice(index, 1);
        setProduct({...product, ["option2"]: product.option2});
    };

    const clickAddOption2 = () => {
        setProduct({...product, ["option2"]: product.option2.concat([{"po2_name": "", "po2_name": ""}])});
    };

    const handleSubmit = (event) => {

        event.preventDefault();

        const formData = new FormData();
        formData.append('prd_idx', product.prd_idx);
        formData.append('imgFile', product.file);
        formData.append('fileName', product.fileName);
        formData.append('product_name', product.product_name);
        formData.append('category_idx', product.category_idx);
        formData.append('product_desc', product.product_desc);
        formData.append('default_amount', product.default_amount);
        formData.append('expiration_date', product.expiration_date);
        formData.append('expiration_date_unit', product.expiration_date_unit);
        formData.append('sell_prc_unit', product.sell_prc_unit);
        formData.append('option1', JSON.stringify(product.option1));
        formData.append('option2', JSON.stringify(product.option2));

        console.log(product.option1);
        console.log(product.option2);

        console.log("typeof : ", typeof(product.sell_start_dt));
        
        if (typeof(product.sell_start_dt) === "object" && product.sell_start_dt !== null)
            formData.append('sell_start_dt', product.sell_start_dt.toISOString().split("T")[0]);
        else if (typeof(product.sell_start_dt) === "string" && product.sell_start_dt !== null)
            formData.append('sell_start_dt', product.sell_start_dt);
        else
            formData.append('sell_start_dt', product.sell_start_dt);
        
        if (typeof(product.sell_end_dt) === "object" && product.sell_end_dt !== null)
            formData.append('sell_end_dt', product.sell_end_dt.toISOString().split("T")[0]);
        else if (typeof(product.sell_end_dt) === "string" && product.sell_end_dt !== null)
            formData.append('sell_end_dt', product.sell_end_dt);
        else
            formData.append('sell_end_dt', product.sell_end_dt);

        //console.log(product.sell_start_dt.toISOString().split("T")[0]);

        // 여기서 디스패치로~
        dispatch(productModify(formData))
        .then(data => 
        {
            console.log("data : ", data);
            if (!data.success)
                alert(data.msg);
            
            navigate("/dashboard/ProductList");
        })
        .catch(e => {
            console.log(e);
        });
        
    };

    useEffect(() => {
        
        console.log("dispatch productSelect");
        dispatch(productSelect(prd_idx))
        .then(data => 
        {
            console.log("dispatch productSelect data : ", data[0]);
            setProduct({
                prd_idx: data[0].prd_idx,
                category_idx: data[0].category_idx, 
                product_name: data[0].prd_name,
                product_desc: data[0].prd_desc,
                default_amount: data[0].default_amount,
                expiration_date_unit: data[0].expiration_date_unit,
                expiration_date: data[0].expiration_date,
                sell_prc_unit: data[0].sell_prc_unit,
                sell_start_dt: data[0].sell_start_dt,
                sell_end_dt: data[0].sell_end_dt,
                fileName: data[0].prd_photo_file,
                filePath: data[0].prd_photo_path,
                mis_count: data[0].mis_count,
                total_mission: data[0].total_mission,
                option1: data[0].option1 === undefined ? [] : data[0].option1,
                option2: data[0].option2 === undefined ? [] : data[0].option2,
            });
        })
        .catch(e => {
            console.log(e);
        });

        // 여기서 디스패치로~
        dispatch(categoryList())
        .then(data => 
        {
            console.log("data : ", data);
        })
        .catch(e => {
            console.log(e);
        });
        
        return () => {      // 언마운트
            console.log("언마운트!");
        };

    }, [dispatch])

  return (
    <Card sx={{ minWidth: 275 }}>
      <CardContent>
      <FormControl>
            <TextField
            id="name"
            helperText="상품 이름을 입력해주세요."
            variant="standard"
            name="product_name"
            onChange={handleInputChange}
            value={product.product_name || ""}
            />
            
            <TextField
            id="product_desc"
            helperText="상품 설명을 입력해주세요."
            variant="standard"
            name="product_desc"
            onChange={handleInputChange}
            value={product.product_desc || ""}
            />
            
            <br></br>

            <FormControl fullWidth>
            <InputLabel id="category_idx">카테고리</InputLabel>
            <Select
            labelId="category_idx"
            id="category_idx"
            label="카테고리"
            onChange={handleInputChange}
            name="category_idx"
            value={product.category_idx || ""}
            >
            {category && category.map((item, index) => (
            <MenuItem value={item.category_idx} key={index}>
                {item.category_name}
            </MenuItem>
            ))}
            </Select>
            </FormControl>

            <TextField
            id="default_amount"
            label="기본 수량"
            type="number"
            InputLabelProps={{
                shrink: true,
            }}
            variant="standard"
            name="default_amount"
            value={product.default_amount || 0}
            helperText="기본 수량은 패키지의 수량을 뜻합니다. 무료 패키지의 설정입니다."
            onChange={handleInputChange}
            inputProps={{
                inputMode: 'numeric', 
                pattern: '[0-9]*',
                step: 1,
                min: 1,
                max: 10000,
                type: 'number',
            }}
            />
            <br></br>

            <TextField
            id="sell_prc_unit"
            label="단가"
            type="number"
            helperText="단가를 0으로 설정하면 무료 패키지가 됩니다."
            InputLabelProps={{
                shrink: true,
            }}
            variant="standard"
            name="sell_prc_unit"
            onChange={handleInputChange}
            value={product.sell_prc_unit || ""}
            inputProps={{
                inputMode: 'numeric', 
                pattern: '[0-9]*',
                min: 0,
                type: 'number',
            }}
            />

            <br></br>
            <FormControl sx={{ width: '120px' }}>
            <InputLabel id="expiration_date_unit">유효단위</InputLabel>
                <Select
                labelId="expiration_date_unit"
                id="expiration_date_unit"
                label="유효단위"
                onChange={handleInputChange}
                name="expiration_date_unit"
                value={product.expiration_date_unit || ""}
                >
                    <MenuItem value={'day'}>일</MenuItem>
                    <MenuItem value={'month'}>월</MenuItem>
                </Select>
            </FormControl>

            <br></br>
            <TextField
            id="expiration_date"
            label="패키지 유효기간"
            type="number"
            value={product.expiration_date || 0}
            helperText="패키지의 유효기간으로 기본 단위는 1달(월)입니다."
            InputLabelProps={{
                shrink: true,
            }}
            variant="standard"
            name="expiration_date"
            onChange={handleInputChange}
            inputProps={{
                inputMode: 'numeric', 
                pattern: '[0-9]*',
                min: 1,
                max: 12,
                step: 1,
                type: 'number',
            }}
            />
            
            <br></br>
            
            <Button onClick={clickAddOption1}
            style={{
                marginBottom:'10px'
            }}
            >옵션1 추가</Button>

            {
              product.option1.map(((item, index) => (
                <div key={index}
                    style={{
                        marginBottom:'15px'
                    }}
                >
                <Divider></Divider>
                <br></br>
                <FormControl sx={{ width: '120px' }}>
                <InputLabel id={`${index}-expiration_date_unit`}>유효단위</InputLabel>
                    <Select
                    labelId={`${index}-expiration_date_unit`}
                    id={`${index}-expiration_date_unit`}
                    label="유효단위"
                    onChange={handleOption1Change}
                    name={`${index}-expiration_date_unit`}
                    value={product.option1[index].expiration_date_unit || ""}
                    >
                        <MenuItem value={'day'}>일</MenuItem>
                        <MenuItem value={'month'}>월</MenuItem>
                    </Select>
                </FormControl>
                <TextField 
                  sx={{marginRight:'10px', marginLeft:'10px', width:'100px'}}
                  id={`${index}-expiration_date`}
                  name={`${index}-expiration_date`}
                  label={`유효기간`}
                  onChange={handleOption1Change}  
                  inputProps={{ maxLength: 10, inputMode: 'numeric', pattern: '[0-9]*' }}
                  value={product.option1[index].expiration_date || ""}
                />
                <Button onClick={(e)=>{clickDelOption1(e, index)}}>삭제</Button>
                <br></br><br></br>
                <TextField 
                  sx={{marginRight:'10px', width:'100px'}}
                  id={`${index}-po1_count_start`}
                  name={`${index}-po1_count_start`}
                  label={`수량 시작`}
                  onChange={handleOption1Change} 
                  inputProps={{ maxLength: 100000, inputMode: 'numeric', pattern: '[0-9]*' }}
                  type="text"
                  value={product.option1[index].po1_count_start || ""}
                />
                <TextField 
                  sx={{marginRight:'10px', width:'100px'}}
                  id={`${index}-po1_count_end`}
                  name={`${index}-po1_count_end`}
                  label={`수량 끝`}
                  onChange={handleOption1Change}
                  inputProps={{ maxLength: 100000, inputMode: 'numeric', pattern: '[0-9]*' }}
                  value={product.option1[index].po1_count_end || ""}
                />
                <br></br><br></br>
                <TextField 
                  id={`${index}-po1_amt`}
                  name={`${index}-po1_amt`}
                  label={`가격`}
                  onChange={handleOption1Change}  
                  inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                  value={product.option1[index].po1_amt || ""}
                />
                <br></br>
                
                </div>
              )))
            }

            <Button onClick={clickAddOption2}
            style={{
                marginBottom:'10px'
            }}
            >옵션2 추가</Button>

            {
              product.option2.map(((item, index) => (
                <div key={index}
                    style={{
                        marginBottom:'15px'
                    }}
                >
                <Divider></Divider>
                <br></br>
                <TextField 
                  sx={{marginRight:'10px', width:'180px'}}
                  id={`${index}-po2_name`}
                  name={`${index}-po2_name`}
                  label={`옵션의 이름`}
                  onChange={handleOption2Change} 
                  inputProps={{ maxLength: 100 }}
                  type="text"
                  value={product.option2[index].po2_name || ""}
                />
                <TextField 
                  sx={{marginRight:'10px', marginLeft:'10px', width:'180px'}}
                  id={`${index}-po2_price`}
                  name={`${index}-po2_price`}
                  label={`옵션의 가격`}
                  onChange={handleOption2Change}  
                  inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                  value={product.option2[index].po2_price || ""}
                />
                <Button onClick={(e)=>{clickDelOption2(e, index)}}>삭제</Button>
                <br></br>
                </div>
              )))
            }
            
            <br></br>
            <Divider sx={{marginBottom:'10px'}}/>
            판매 기간이 정해져있는 상품의 설정
            <Divider sx={{marginTop:'10px'}} />
            <br></br>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Stack spacing={3}>
                    <MobileDatePicker
                    label="판매 시작일"
                    inputFormat="MM/dd/yyyy"
                    value={product.sell_start_dt || ""}
                    onChange={handleStartDateChange}
                    renderInput={(params) => <TextField {...params} />}
                    name="sell_start_dt"
                    />
                    <MobileDatePicker
                    label="판매 종료일"
                    inputFormat="MM/dd/yyyy"
                    value={product.sell_end_dt || ""}
                    onChange={handleEndDateChange}
                    renderInput={(params) => <TextField {...params} />}
                    name="sell_end_dt"
                    />
                </Stack>
            </LocalizationProvider>

        <br></br>
        <br></br>
        <span>
            <MissionEditDialog 
            prd_idx={product.prd_idx || 0} 
            mis_count={product.mis_count || 0} 
            total_mission={product.total_mission || 0} 
            refreshFunction={refreshFunction} />
        </span>
        <br></br>
        <br></br>
        <ImgFileUpload handleImgFileChange={handleImgFileChange} />
        {product.fileChange ? "" : <img src={common.returnImgSrc(product.filePath, product.fileName)} /> }
        </FormControl>
      </CardContent>
      <CardActions>
        <Button onClick={handleSubmit} size="small">상품수정</Button>
      </CardActions>
    </Card>
  );
}