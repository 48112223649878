import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';

import { useEffect } from 'react';

import { useDispatch, useSelector } from "react-redux";
import { productList } from "../../../actions/product";
import ProductItem from "../../../common/ProductItem";

export default function OrderWrite() {

  const product = useSelector(state => state.product);
  // console.log("product : ", product);

  const dispatch = useDispatch();

  useEffect(() => {

    // 여기서 디스패치로~
    dispatch(productList())
    .then(data => 
    {
        // console.log("data : ", data);
    })
    .catch(e => {
        console.log(e);
    });
    
    return () => {      // 언마운트
        // console.log("언마운트!");
    };

}, [dispatch])

  return (
    <Card sx={{ minWidth: 275 }}>
      <CardHeader
        title="패키지 구매 리스트"
        subheader="패키지 구매 후에 학습자 생성이 가능합니다."
      />
      <CardContent>
        {product && product.map((row, index) => (
          <ProductItem key={index} product={row} />
        ))}
      </CardContent>
    </Card>
  );
}