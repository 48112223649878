import http from "../http-common";

const chartClassAvgStudyTime = userIdx => {
  return http.get(`users/commonClassAvgStudyTime/${userIdx}`);
};

const chartClassLastLogin = userIdx => {
  return http.get(`users/commonClassLastLogin/${userIdx}`);
};

const chartClassStudentCount = userIdx => {
  return http.get(`users/commonClassStudentCount/${userIdx}`);
};

const chartClassAgeDiv = userIdx => {
  return http.get(`users/commonClassAgeDiv/${userIdx}`);
};

const chartClassAgeRange = userIdx => {
  return http.get(`users/commonClassAgeRange/${userIdx}`);
};

const chartClassLearningTime = (data, userIdx) => {
  return http.get(`users/commonClassLearningTime/${data}/${userIdx}`);
};

const chartClassMissionStateProgress = (data, userIdx) => {
  return http.get(`users/commonClassMissionStateProgress/${data}/${userIdx}`);
};

const chartClassMissionStateProgressTotal = (data, userIdx) => {
  return http.get(`users/commonClassMissionStateProgressTotal/${data}/${userIdx}`);
};

const chartClassMissionTotal = (data) => {
  return http.get(`users/commonClassTotalMission/${data}`);
};

const chartClassMissionProgress = (data) => {
  return http.get(`users/commonClassMissionProgress/${data}`);
};

const chartClassHumanAbility = (data) => {
  return http.get(`users/commonClassHumanAbility/${data}`);
};

const ChartService = {
  chartClassAvgStudyTime,
  chartClassLastLogin,
  chartClassStudentCount,
  chartClassAgeDiv,
  chartClassAgeRange,
  chartClassLearningTime,
  chartClassMissionStateProgress,
  chartClassMissionStateProgressTotal,
  chartClassMissionTotal,
  chartClassMissionProgress,
  chartClassHumanAbility,
};

export default ChartService;
