import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import { FormControl, TextField, Select, MenuItem, InputLabel } from '@mui/material';

import Stack from '@mui/material/Stack';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import MobileDatePicker from '@mui/lab/MobileDatePicker';

import ImgFileUpload from '../../../common/ImgFileUpload';

import { useDispatch } from "react-redux";
import { productSelect, productModify } from "../../../actions/product";

import { useEffect, useState } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import * as common from "../../../lib";

export default function ProductModify() {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const prd_idx  = useParams().prd_idx;
    console.log("prd_idx : ", prd_idx);

    const initialProductState = {
        prd_idx: null,
        category_idx: null, 
        product_name: null,
        product_desc: null,
        sell_prc_unit: null,
        sell_start_dt: null,
        sell_end_dt: null,
        file: null,
        fileName: null,
        filePath: null,
        fileChange: false
    };

    const [product, setProduct] = useState(initialProductState);

    const handleInputChange = event => {
        const { name, value } = event.target;
        setProduct({ ...product, [name]: value });
    };

    const handleStartDateChange = (newValue) => {
        setProduct({ ...product, sell_start_dt: newValue });
    };

    const handleEndDateChange = (newValue) => {
        setProduct({ ...product, sell_end_dt: newValue });
    };

    const handleImgFileChange = (file) => {
        // console.log("file : ", file);
        setProduct({ ...product, file: file, fileName: file.name, fileChange: true });
    };

    const handleSubmit = (event) => {

        event.preventDefault();

        const formData = new FormData();
        formData.append('prd_idx', product.prd_idx);
        formData.append('imgFile', product.file);
        formData.append('fileName', product.fileName);
        formData.append('product_name', product.product_name);
        formData.append('category_idx', product.category_idx);
        formData.append('product_desc', product.product_desc);
        formData.append('sell_prc_unit', product.sell_prc_unit);

        // console.log("typeof : ", typeof(product.sell_start_dt));
        
        if (typeof(product.sell_start_dt) === "object" && product.sell_start_dt !== null)
            formData.append('sell_start_dt', product.sell_start_dt.toISOString().split("T")[0]);
        else if (typeof(product.sell_start_dt) === "string" && product.sell_start_dt !== null)
            formData.append('sell_start_dt', product.sell_start_dt);
        else
            formData.append('sell_start_dt', product.sell_start_dt);
        
        if (typeof(product.sell_end_dt) === "object" && product.sell_end_dt !== null)
            formData.append('sell_end_dt', product.sell_end_dt.toISOString().split("T")[0]);
        else if (typeof(product.sell_end_dt) === "string" && product.sell_end_dt !== null)
            formData.append('sell_end_dt', product.sell_end_dt);
        else
            formData.append('sell_end_dt', product.sell_end_dt);

        //console.log(product.sell_start_dt.toISOString().split("T")[0]);

        // 여기서 디스패치로~
        dispatch(productModify(formData))
        .then(data => 
        {
            // console.log("data : ", data);
            if (!data.success)
                alert(data.msg);
            
            navigate("/dashboard/ProductList");
        })
        .catch(e => {
            console.log(e);
        });
        
    };

    useEffect(() => {
        
        // console.log("dispatch productSelect");
        dispatch(productSelect(prd_idx))
        .then(data => 
        {
            // console.log("data : ", data[0]);
            setProduct({
                prd_idx: data[0].prd_idx,
                category_idx: data[0].category_idx, 
                product_name: data[0].prd_name,
                product_desc: data[0].prd_desc,
                sell_prc_unit: data[0].sell_prc_unit,
                sell_start_dt: data[0].sell_start_dt,
                sell_end_dt: data[0].sell_end_dt,
                fileName: data[0].prd_photo_file,
                filePath: data[0].prd_photo_path,
            });
        })
        .catch(e => {
            console.log(e);
        });

        return () => {      // 언마운트
            // console.log("언마운트!");
        };

    }, [dispatch])

  return (
    <Card sx={{ minWidth: 275 }}>
      <CardContent>
      <FormControl>
            <TextField
            id="name"
            helperText="상품 이름을 입력해주세요."
            variant="standard"
            name="product_name"
            onChange={handleInputChange}
            value={product.product_name || ""}
            />
            
            <TextField
            id="product_desc"
            helperText="상품 설명을 입력해주세요."
            variant="standard"
            name="product_desc"
            onChange={handleInputChange}
            value={product.product_desc || ""}
            />
            
            <br></br>

            <FormControl fullWidth>
            <InputLabel id="category_idx">카테고리</InputLabel>
            <Select
            labelId="category_idx"
            id="category_idx"
            value=""
            label="카테고리"
            onChange={handleInputChange}
            name="category_idx"
            value={product.category_idx || ""}
            >
            <MenuItem value={1}>일반</MenuItem>
            <MenuItem value={2}>단체</MenuItem>
            <MenuItem value={3}>소셜</MenuItem>
            </Select>
            </FormControl>
            
            <br></br>

            <TextField
            id="sell_prc_unit"
            label="단가"
            type="number"
            InputLabelProps={{
                shrink: true,
            }}
            variant="standard"
            name="sell_prc_unit"
            onChange={handleInputChange}
            value={product.sell_prc_unit || ""}
            />
            
            <br></br>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Stack spacing={3}>
                    <MobileDatePicker
                    label="판매 시작일"
                    inputFormat="MM/dd/yyyy"
                    value={product.sell_start_dt || ""}
                    onChange={handleStartDateChange}
                    renderInput={(params) => <TextField {...params} />}
                    name="sell_start_dt"
                    />
                    <MobileDatePicker
                    label="판매 종료일"
                    inputFormat="MM/dd/yyyy"
                    value={product.sell_end_dt || ""}
                    onChange={handleEndDateChange}
                    renderInput={(params) => <TextField {...params} />}
                    name="sell_end_dt"
                    />
                </Stack>
            </LocalizationProvider>

        <br></br>
        <br></br>
        <ImgFileUpload handleImgFileChange={handleImgFileChange} />
        {product.fileChange ? "" : <img src={common.returnImgSrc(product.filePath, product.fileName)} /> }
        </FormControl>
      </CardContent>
      <CardActions>
        <Button onClick={handleSubmit} size="small">상품수정</Button>
      </CardActions>
    </Card>
  );
}