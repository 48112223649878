import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import { FormControl, TextField, Select, MenuItem, InputLabel } from '@mui/material';

import Stack from '@mui/material/Stack';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import MobileDatePicker from '@mui/lab/MobileDatePicker';
import Divider from '@mui/material/Divider';

import ImgFileUpload from '../../../common/ImgFileUpload';

import { useDispatch, useSelector } from "react-redux";
import { inappProductSelect, inappProductModify } from "../../../actions/inapp_product";
import { categoryList } from "../../../actions/category";

import { useEffect, useState } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import MissionEditDialog from './MissionEditDialog';

import * as common from "../../../lib";

export default function ProductModify() {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const ina_idx  = useParams().ina_idx;
    console.log("ina_idx : ", ina_idx);

    const initialProductState = {
        ina_idx: null,
        category_idx: null, 
        ina_name: null,
        ina_type: null,
        ina_product_id: null,
        ina_desc: null,
        default_amount: 1,
        expiration_date_unit: null,
        expiration_date: 1,
        sell_prc_unit: 1,
        sell_start_dt: null,
        sell_end_dt: null,
        file: null,
        fileName: null,
        filePath: null,
        mis_count: null,
        total_mission: null,
    };

    const [product, setProduct] = useState(initialProductState);

    const category = useSelector(state => state.category);
    console.log("category : ", category);

    const handleInputChange = event => {
        const { name, value } = event.target;
        setProduct({ ...product, [name]: value });
    };

    const handleStartDateChange = (newValue) => {
        setProduct({ ...product, sell_start_dt: newValue });
    };

    const handleEndDateChange = (newValue) => {
        setProduct({ ...product, sell_end_dt: newValue });
    };

    const handleImgFileChange = (file) => {
        console.log("file : ", file);
        setProduct({ ...product, file: file, fileName: file.name, fileChange: true });
    };

    const refreshFunction = () => {
       window.location.reload();
    };

    const handleSubmit = (event) => {

        event.preventDefault();

        const formData = new FormData();

        formData.append('ina_idx', product.ina_idx);
        formData.append('imgFile', product.file);
        formData.append('fileName', product.fileName);
        formData.append('ina_name', product.ina_name);
        formData.append('category_idx', product.category_idx);
        formData.append('ina_product_id', product.ina_product_id);
        formData.append('ina_type', product.ina_type);
        formData.append('ina_desc', product.ina_desc);
        formData.append('default_amount', product.default_amount);
        formData.append('expiration_date', product.expiration_date);
        formData.append('expiration_date_unit', product.expiration_date_unit);
        formData.append('sell_prc_unit', product.sell_prc_unit);

        console.log("typeof : ", typeof(product.sell_start_dt));
        
        if (typeof(product.sell_start_dt) === "object" && product.sell_start_dt !== null)
            formData.append('sell_start_dt', product.sell_start_dt.toISOString().split("T")[0]);
        else if (typeof(product.sell_start_dt) === "string" && product.sell_start_dt !== null)
            formData.append('sell_start_dt', product.sell_start_dt);
        else
            formData.append('sell_start_dt', product.sell_start_dt);
        
        if (typeof(product.sell_end_dt) === "object" && product.sell_end_dt !== null)
            formData.append('sell_end_dt', product.sell_end_dt.toISOString().split("T")[0]);
        else if (typeof(product.sell_end_dt) === "string" && product.sell_end_dt !== null)
            formData.append('sell_end_dt', product.sell_end_dt);
        else
            formData.append('sell_end_dt', product.sell_end_dt);

        //console.log(product.sell_start_dt.toISOString().split("T")[0]);

        // 여기서 디스패치로~
        dispatch(inappProductModify(formData))
        .then(data => 
        {
            console.log("data : ", data);
            if (!data.success)
                alert(data.msg);
            
            navigate("/dashboard/InappProductList");
        })
        .catch(e => {
            console.log(e);
        });
        
    };

    useEffect(() => {
        
        console.log("dispatch inappProductSelect");
        dispatch(inappProductSelect(ina_idx))
        .then(data => 
        {
            console.log("data : ", data[0]);
            setProduct({
                ina_idx: data[0].ina_idx,
                category_idx: data[0].category_idx, 
                ina_name: data[0].ina_name,
                ina_type: data[0].ina_type,
                ina_product_id: data[0].ina_product_id,
                ina_desc: data[0].ina_desc,
                default_amount: data[0].default_amount,
                expiration_date_unit: data[0].expiration_date_unit,
                expiration_date: data[0].expiration_date,
                sell_prc_unit: data[0].sell_prc_unit,
                sell_start_dt: data[0].sell_start_dt,
                sell_end_dt: data[0].sell_end_dt,
                fileName: data[0].ina_photo_file,
                filePath: data[0].ina_photo_path,
                mis_count: data[0].mis_count,
                total_mission: data[0].total_mission,
            });
        })
        .catch(e => {
            console.log(e);
        });

        // 여기서 디스패치로~
        dispatch(categoryList())
        .then(data => 
        {
            console.log("data : ", data);
        })
        .catch(e => {
            console.log(e);
        });
        
        return () => {      // 언마운트
            console.log("언마운트!");
        };

    }, [dispatch])

  return (
    <Card sx={{ minWidth: 275 }}>
      <CardContent>
      <FormControl>
            <TextField
            id="ina_name"
            helperText="상품 이름을 입력해주세요."
            variant="standard"
            name="ina_name"
            onChange={handleInputChange}
            value={product.ina_name || ""}
            />
            
            <br></br>
            <TextField
            id="ina_product_id"
            label="인앱에 설정된 상품 이름"
            helperText="인앱 상품 이름을 입력해주세요. (잘못 입력하면 작동이 안됩니다.)"
            variant="standard"
            name="ina_product_id"
            onChange={handleInputChange}
            value={product.ina_product_id || ""}
            />
            
            <br></br>
            <FormControl fullWidth>
            <InputLabel id="ina_type">인앱의 종류</InputLabel>
            <Select
            labelId="ina_type"
            id="ina_type"
            label="인앱의 종류"
            onChange={handleInputChange}
            name="ina_type"
            value={product.ina_type || ""}
            >
            <MenuItem value={`google`} key={1}>google</MenuItem>
            <MenuItem value={`apple`} key={2}>apple</MenuItem>
            </Select>
            </FormControl>
            
            <br></br>
            <TextField
            id="ina_desc"
            helperText="상품 설명을 입력해주세요."
            variant="standard"
            name="ina_desc"
            onChange={handleInputChange}
            value={product.ina_desc || ""}
            />
            
            <br></br>

            <FormControl fullWidth>
            <InputLabel id="category_idx">카테고리</InputLabel>
            <Select
            labelId="category_idx"
            id="category_idx"
            label="카테고리"
            onChange={handleInputChange}
            name="category_idx"
            value={product.category_idx || ""}
            >
            {category && category.map((item, index) => (
            <MenuItem value={item.category_idx} key={index}>
                {item.category_name}
            </MenuItem>
            ))}
            </Select>
            </FormControl>

            <TextField
            id="default_amount"
            label="기본 수량"
            type="number"
            InputLabelProps={{
                shrink: true,
            }}
            variant="standard"
            name="default_amount"
            value={product.default_amount || 0}
            helperText="기본 수량은 패키지의 수량을 뜻합니다. 무료 패키지의 설정입니다."
            onChange={handleInputChange}
            inputProps={{
                inputMode: 'numeric', 
                pattern: '[0-9]*',
                step: 1,
                min: 1,
                max: 10000,
                type: 'number',
            }}
            />
            <br></br>

            <TextField
            id="sell_prc_unit"
            label="단가"
            type="number"
            helperText="단가를 0으로 설정하면 무료 패키지가 됩니다."
            InputLabelProps={{
                shrink: true,
            }}
            variant="standard"
            name="sell_prc_unit"
            onChange={handleInputChange}
            value={product.sell_prc_unit || ""}
            inputProps={{
                inputMode: 'numeric', 
                pattern: '[0-9]*',
                min: 0,
                type: 'number',
            }}
            />
            
            <br></br>
            <FormControl sx={{ width: '120px' }}>
            <InputLabel id="expiration_date_unit">유효단위</InputLabel>
                <Select
                labelId="expiration_date_unit"
                id="expiration_date_unit"
                label="유효단위"
                onChange={handleInputChange}
                name="expiration_date_unit"
                value={product.expiration_date_unit || ""}
                >
                    <MenuItem value={'day'}>일</MenuItem>
                    <MenuItem value={'month'}>월</MenuItem>
                </Select>
            </FormControl>

            <br></br>
            <TextField
            id="expiration_date"
            label="패키지 유효기간"
            type="number"
            value={product.expiration_date || 0}
            helperText="패키지의 유효기간으로 기본 단위는 1달(월)입니다."
            InputLabelProps={{
                shrink: true,
            }}
            variant="standard"
            name="expiration_date"
            onChange={handleInputChange}
            inputProps={{
                inputMode: 'numeric', 
                pattern: '[0-9]*',
                min: 1,
                max: 12,
                step: 1,
                type: 'number',
            }}
            />
            
            <br></br>
            <Divider sx={{marginBottom:'10px'}}/>
            판매 기간이 정해져있는 상품의 설정
            <Divider sx={{marginTop:'10px'}} />
            <br></br>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Stack spacing={3}>
                    <MobileDatePicker
                    label="판매 시작일"
                    inputFormat="MM/dd/yyyy"
                    value={product.sell_start_dt || ""}
                    onChange={handleStartDateChange}
                    renderInput={(params) => <TextField {...params} />}
                    name="sell_start_dt"
                    />
                    <MobileDatePicker
                    label="판매 종료일"
                    inputFormat="MM/dd/yyyy"
                    value={product.sell_end_dt || ""}
                    onChange={handleEndDateChange}
                    renderInput={(params) => <TextField {...params} />}
                    name="sell_end_dt"
                    />
                </Stack>
            </LocalizationProvider>

        <br></br>
        <br></br>
        <span>
            <MissionEditDialog 
            ina_idx={product.ina_idx || 0} 
            mis_count={product.mis_count || 0} 
            total_mission={product.total_mission || 0} 
            refreshFunction={refreshFunction} />
        </span>
        <br></br>
        <br></br>
        <ImgFileUpload handleImgFileChange={handleImgFileChange} />
        {product.fileChange ? "" : <img src={common.returnImgSrc(product.filePath, product.fileName)} /> }
        </FormControl>
      </CardContent>
      <CardActions>
        <Button onClick={handleSubmit} size="small">상품수정</Button>
      </CardActions>
    </Card>
  );
}