import * as React from 'react';
import styles from "../../css/common.module.css";
import $ from 'jquery';

import TextField from '@mui/material/TextField';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormLabel from '@mui/material/FormLabel';
import FormControlLabel from '@mui/material/FormControlLabel';

import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';

import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { register, groupRegister } from "../../actions/auth";
import { studentCreate, studentIdCheck } from "../../actions/student";
import { useNavigate, useLocation } from 'react-router-dom';

import { useTranslation, Trans } from 'react-i18next'; // 1. react-i18next import 

export default function RegisterView() {

  const initialStudentState = {
    id: "", 
    password: "",
    confirmPassword: "",
    name: "",
    nickname: "",
    year: "",
    month: "",
    day: "",
    gender: 1,
    showPassword: false,
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { t, i18n } = useTranslation(); // 3. useTranslation hook 선언

  // console.log('location state : ', location.state);

  const [student, setStudent] = useState(initialStudentState);
  const [join, setJoin] = useState(location?.state?.body);
  const [joinGroup, setJoinGroup] = useState(location?.state?.addbody);
  // const [parentIdx, setParentIdx] = useState(location.state.parentIdx);

  const [fontSizeValue, setFontSizeValue] = useState(null);

  const [idCheck, setIdCheck] = useState(false);

  // console.log(parentIdx);

  const handleInputChange = event => {
    const { name, value } = event.target;
    // console.log("name : ", name);
    // console.log("value : ", value);

    if (name === "id")
		{
        // 아이디 값이 변경되면 다시 중복 체크를 하도록 합니다.
        setIdCheck(false);
        
        $(`.${styles.input_area}.${styles.nikname}`).removeClass(`${styles.overlap}`);
        $(`.${styles.input_area}.${styles.nikname}`).removeClass(`${styles.overlapno}`);
        $(`.${styles.input_area}.${styles.nikname}`).removeClass(`${styles.nokorean}`);
        $(`.${styles.student_register_area}`).find('ul').eq(0).children('li').eq(1).removeClass();

        const regId =  /^[A-Za-z0-9+]{2,50}$/;
        // 아이디가 규칙에 맞는지 검사해서 규칙에 맞지 않으면 경고메세지를 뿌립니다.
        if(regId.test(value) === false)
        {
          $(`.${styles.input_area}.${styles.nikname}`).removeClass(`${styles.overlap}`);
          $(`.${styles.input_area}.${styles.nikname}`).removeClass(`${styles.overlapno}`);
          $(`.${styles.input_area}.${styles.nikname}`).addClass(`${styles.nokorean}`);
        }

        // 아이디가 공백이면 스타일시트를 지웁니다.
        if (value === "")
        {
          $(`.${styles.input_area}.${styles.nikname}`).removeClass(`${styles.overlap}`);
          $(`.${styles.input_area}.${styles.nikname}`).removeClass(`${styles.overlapno}`);
          $(`.${styles.input_area}.${styles.nikname}`).removeClass(`${styles.nokorean}`);
        }
		}

    setStudent({ ...student, [name]: value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (student.id === "")
		{
			// return alert("아이디를 입력해주세요!");
      return alert(`${t("Auth.alert.string13")}`);
		}
		else
		{
			if (!idCheck)
			{
        // return alert("중복된 아이디입니다. 다른 아이디를 입력해주세요!");
        return alert(`${t("Auth.alert.string14")}`);
			}

			const regId =  /^[A-Za-z0-9+]{2,50}$/;
      if (regId.test(student.id) === false) 
      {
        // return alert('아이디는 2자리 이상 50자리 이하의 영어와 숫자만 입력해주세요.');
        return alert(`${t("Auth.alert.string15")}`);
      }
		}

    if(student.name === "")
    {
      // return alert('이름을 입력해주세요.');
      return alert(`${t("Auth.alert.string10")}`);
    }

    if(student.nickname === "")
    {
      // return alert('닉네임을 입력해주세요.');
      return alert(`${t("Auth.alert.string16")}`);
    }

    if(student.year === "")
    {
      // return alert('생년월일의 년도를 선택해주세요.');
      return alert(`${t("Auth.alert.string17")}`);
    }

    if(student.month === "")
    {
      // return alert('생년월일의 월을 선택해주세요.');
      return alert(`${t("Auth.alert.string18")}`);
    }

    if(student.day === "")
    {
      // return alert('생년월일의 일을 선택해주세요.');
      return alert(`${t("Auth.alert.string19")}`);
    }

    // 정규 표현식으로 비밀번호 생성 규칙에 맞는지 체크합니다.
    let reg = new RegExp(/(?=.*\d{1,20})(?=.*[~`!@#$%\^&*()-+=]{1,20})(?=.*[a-zA-Z]{1,20}).{8,20}$/);
    // console.log(reg.test(join.password));
    if( !reg.test(student.password) ) 
    {
        // alert("비밀번호 생성 규칙은 영문, 숫자, 특수문자 포함 8자 이상 20자가지 입니다.");
        alert(`${t("Auth.alert.string8")}`);
        return false;
    }

    if(student.password !== student.confirmPassword)
    {
      // return alert('비밀번호와 비밀번호 확인은 같아야 합니다.');
      return alert(`${t("Auth.alert.string9")}`);
    }

    // console.log("student : ", student);
    // console.log("join : ", join);
    // console.log("joinGroup : ", joinGroup);

    if (joinGroup === undefined)  // 그룹 사용자의 정보가 없다면... 일반 가입입니다.
    {
      if (join === undefined)
        return alert(`${t("Auth.alert.string20")}`);
        // return alert('사용자 값 오류입니다.');

      dispatch(register(join))
      .then(data => 
      {
          // console.log("data : ", data);
          if (!data.success)
          {
              alert(data.msg);
          }
          else
          {
            // navigate("/auth/RegisterStudentView", {
            //   state: {
            //     parentIdx: data.msg,
            //   },
            // });
  
            let body = {
              mem_idx: data.msg,
              id: student.id,
              password: student.password,
              name: student.name,
              nickname: student.nickname,
              gender: student.gender,
              birthday: student.year + "-" + student.month + "-" + student.day,
            };
        
            // console.log("body : ", body);
  
            // 여기서 디스패치로~
            dispatch(studentCreate(body))
            .then(data => 
            {
                // console.log("data : ", data);
                if (!data.success)
                {
                    alert(data.msg);
                    return;
                }
                // alert("가입이 완료되었습니다.");
                // navigate("/auth/login");
                navigate("/auth/RegisterComplete");
            })
            .catch(e => {
                console.log(e);
            });
          }
        })
        .catch(e => {
            console.log(e);
        });   
    }
    else  // 정보가 있다면 단체 가입입니다.
    {
      if (join === undefined)
        return alert(`${t("Auth.alert.string20")}`);
        // return alert('사용자 값 오류입니다.');

      if (joinGroup === undefined)
        return alert(`${t("Auth.alert.string21")}`);
        // return alert('사용자 추가값 오류입니다.');

      dispatch(groupRegister({join: join, joinGroup: joinGroup}))
      .then(data => 
      {
          // console.log("data : ", data);
          if (!data.success)
          {
            alert(data.msg);
          }
          else
          {
            let body = {
              mem_idx: data.msg,
              id: student.id,
              password: student.password,
              name: student.name,
              nickname: student.nickname,
              gender: student.gender,
              birthday: student.year + "-" + student.month + "-" + student.day,
            };
        
            // console.log("body : ", body);
  
            // 여기서 디스패치로~
            dispatch(studentCreate(body))
            .then(data => 
            {
                // console.log("data : ", data);
                if (!data.success)
                {
                    alert(data.msg);
                    return;
                }
                // alert("가입이 완료되었습니다.");
                // navigate("/auth/login");
                navigate("/auth/RegisterGroupComplete");
            })
            .catch(e => {
                console.log(e);
            });
          }
      })
      .catch(e => {
          console.log(e);
      });
    }
    
  }

  // const hasError = passwordEntered =>
  //   student.password.length < 5 ? true : false;

  const hasError = (passwordEntered) =>
  {
    // 정규 표현식으로 비밀번호 생성 규칙에 맞는지 체크합니다.
    let reg = new RegExp(/(?=.*\d{1,20})(?=.*[~`!@#$%\^&*()-+=]{1,20})(?=.*[a-zA-Z]{1,20}).{8,20}$/);
    // console.log(reg.test(join.password));
    return !reg.test(student.password);
  }
    
  const hasNotSameError = passwordEntered =>
    student.password != student.confirmPassword ? true : false;

  const getYears = () => 
  {
    let date=new Date();
    let selYear=date.getFullYear();
    
    //올해 기준으로 -30년을 보여줌
    let stY=Number(selYear)-80;
    let nY=Number(selYear);

    const result = [];
    let index = 0;

    // for(let y=stY; y<=nY; y++)
    for(let y=nY; y>=stY; y--)
    {
      result.push(<MenuItem value={y} key={index++}>{y}{/* 년 */}</MenuItem>);
    }
    
    return result;
  };

  useEffect(() => {

    let isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent) ? true : false;
			
			if(!isMobile) 
			{
				  //PC
          setFontSizeValue({style: {fontSize: 15}});
      }
      else 
      {
          // mobile
          setFontSizeValue({style: {fontSize: 12}});
      }

      // console.log(typeof(fontSizeValue));

      // console.log("location?.state?.body : ", location?.state?.body);
      // console.log("location?.state?.addbody : ", location?.state?.addbody);

      if (location?.state?.body !== undefined)
        setJoin(location?.state?.body);

      if (location?.state?.addbody !== undefined)
        setJoinGroup(location?.state?.addbody);

      
      $(`.${styles.input_area}.${styles.nikname}`).removeClass(`${styles.overlap}`);
      $(`.${styles.input_area}.${styles.nikname}`).removeClass(`${styles.overlapno}`);
      $(`.${styles.input_area}.${styles.nikname}`).removeClass(`${styles.nokorean}`);

  }, []);

  const studentIDCheck = (event, id) => {

		// 학습자 중복 아이디 검사
		// console.log("dispatch studentIDCheck");
		// console.log("id : ", id);

    if(id === "")
		{
			// return alert("아이디를 입력해주세요!");
      return alert(`${t("Auth.alert.string13")}`);
		}

    dispatch(studentIdCheck(id))
    .then(data => 
    {
        // console.log("data : ", data);

      if(!data.success)
			{
				const regId =  /^[A-Za-z0-9+]{2,50}$/;
				// 아이디가 규칙에 맞는지 검사해서 규칙에 맞지 않으면 경고메세지를 뿌립니다.
				if(regId.test(id) === false)
				{
          $(`.${styles.input_area}.${styles.nikname}`).removeClass(`${styles.overlap}`);
          $(`.${styles.input_area}.${styles.nikname}`).removeClass(`${styles.overlapno}`);
          $(`.${styles.input_area}.${styles.nikname}`).addClass(`${styles.nokorean}`);
				}
				else
				{
					setIdCheck(true);
          $(`.${styles.input_area}.${styles.nikname}`).removeClass(`${styles.overlap}`);
          $(`.${styles.input_area}.${styles.nikname}`).removeClass(`${styles.overlapno}`);
          $(`.${styles.input_area}.${styles.nikname}`).addClass(`${styles.overlapno}`);
				}
			}
			else
			{
				setIdCheck(false);
        $(`.${styles.input_area}.${styles.nikname}`).removeClass(`${styles.overlap}`);
        $(`.${styles.input_area}.${styles.nikname}`).removeClass(`${styles.overlapno}`);
        $(`.${styles.input_area}.${styles.nikname}`).addClass(`${styles.overlap}`);
			}
    })
    .catch(e => {
        console.log(e);
    });
	}

  return (
    <>
      <div className={`${styles.login_bg_wrapper} ${styles.stuedent_make_bg_wrapper}`}>
        <div className={`${styles.stuedent_make_area} ${styles.middle}`}>
          <div className={styles.login_top}>
            <a href="/" className={styles.logo}><img src="/assets/logo@2x.png" alt="logo"/></a>
            <a href="/" className={styles.to_join}>{/* 홈페이지로 돌아가기 */}{t('Auth.common.go_back')}</a>
          </div>
          <span className={styles.login_title}>{/* 학습자 만들기 */}{t('Auth.register_string1')}</span>
          <span className={styles.login_sub_title}>{/* 코딩수업을 진행하기 위해<br></br>학습자 계정을 생성합니다. */}<Trans i18nKey="Auth.register_string2"/></span>
          <div className={`${styles.stuedent_make_wrap} ${styles.center_align}`}>
            <div className={`${styles.input_area} ${styles.nikname} ${styles.nokorean}`}>
              <TextField
                required
                fullWidth
                id="id"
                // label="학습자 로그인 아이디"
                label={t('Auth.common.student_id')}
                name="id"
                autoComplete="username"
                autoFocus
                onChange={handleInputChange}
                InputLabelProps={fontSizeValue} // font size of input label
                sx={{ paddingRight: 2 }}
              />
              <button type="button" onClick={(e)=>studentIDCheck(e, student.id)}>{/* 중복확인 */}{t('Auth.common.duplicate')}</button>
            </div>
            <div className={`${styles.input_area} ${styles.addstring}`}>
              <p>{/* 학습자 로그인 아이디는 영문과 숫자로 작성해주세요. */}{t('Auth.common.confirm_string4')}</p>
            </div>
            <div className={`${styles.input_area} ${styles.password}`}>
              <TextField
                required
                fullWidth
                name="password"
                // label="학습자 로그인 비밀번호"
                label={t('Auth.common.student_password')}
                type="password"
                id="password"
                error={hasError('password')} // 해당 텍스트필드에 error 핸들러 추가
                onChange={handleInputChange}
                autoComplete="new-password"
                InputLabelProps={fontSizeValue} // font size of input label
                inputProps={{ maxLength: 100 }}
              />
              <p>{/* 영문, 숫자, 특수문자 조합 8~20자리 권장 */}{t('Auth.common.confirm_string1')}</p>
            </div> 
            <div className={`${styles.input_area} ${styles.re_password}`}>
              <TextField
                required
                fullWidth
                name="confirmPassword"
                // label="비밀번호 확인"
                label={t('Auth.common.password_confirm')}
                type="password"
                id="confirmPassword"
                error={hasNotSameError('confirmPassword')} // 해당 텍스트필드에 error 핸들러 추가
                helperText={
                    // hasNotSameError('confirmPassword') ? "입력한 비밀번호와 일치하지 않습니다." : null
                    hasNotSameError('confirmPassword') ? t('Auth.common.confirm_string2') : null
                } // 에러일 경우에만 안내 문구 표시
                onChange={handleInputChange}
                autoComplete="new-password"
                InputLabelProps={fontSizeValue} // font size of input label
                inputProps={{ maxLength: 100 }}
              />
            </div> 
            <div className={`${styles.input_area} ${styles.hr1}`}>
              <hr></hr>
            </div>
            <div className={`${styles.input_area} ${styles.name}`}>
              <TextField
                required
                fullWidth
                id="name"
                // label="학습자 이름"
                label={t('Auth.common.student_name')}
                name="name"
                onChange={handleInputChange}
                InputLabelProps={fontSizeValue} // font size of input label
              />
            </div> 
            <div className={`${styles.input_area} ${styles.addstring2}`}>
              <p>{/* 실명을 입력해주세요. */}{t('Auth.common.confirm_string5')}</p>
            </div>
            <div className={`${styles.input_area} ${styles.name}`}>
              <TextField
                  required
                  fullWidth
                  id="nickname"
                  // label="별명"
                  label={t('Auth.common.nickname')}
                  name="nickname"
                  onChange={handleInputChange}
                  inputProps={{ maxLength: 7 }}
                  InputLabelProps={fontSizeValue} // font size of input label
              />
            </div>
            <div className={`${styles.input_area} ${styles.addstring3}`}>
              <p>{/* 앱 내에서 사용되는 닉네임입니다. */}{t('Auth.common.confirm_string6')}</p>
            </div>
            <div className={`${styles.input_area} ${styles.hr2}`}>
              <hr></hr>
            </div>
            <div className={`${styles.input_area} ${styles.name}`}>
            <FormLabel id="gender">{/* 성별 */}{t('Auth.common.gender')}</FormLabel>
            <RadioGroup
              row
              aria-labelledby="gender"
              name="gender"
              defaultValue={1}
              onChange={handleInputChange}
            >
              <FormControlLabel value="1" control={<Radio />} /* label="남자" */ label={t('Auth.common.male')} />
              <FormControlLabel value="2" control={<Radio />} /* label="여자" */ label={t('Auth.common.female')} />
            </RadioGroup>
            </div>
            <div className={`${styles.input_area} ${styles.name}`}>
            <FormLabel id="year">{/* 출생년도 */}{t('Auth.common.birth_year')}</FormLabel><br></br>
            <FormControl sx={{ m: 1, minWidth: 120 }}>
              <Select
                value={student.year}
                onChange={handleInputChange}
                displayEmpty
                name='year'
                inputProps={{ 'aria-label': 'Without label' }}
              >
                <MenuItem value="" disabled>
                  <em>{/* 년 */}{t('Auth.common.year')}</em>
                </MenuItem>
                {getYears()}
              </Select>
            </FormControl>

            <FormControl sx={{ m: 1, minWidth: 80 }}>
              <Select
                value={student.month}
                onChange={handleInputChange}
                displayEmpty
                name='month'
                inputProps={{ 'aria-label': 'Without label' }}
              >
                <MenuItem value="" disabled>
                  <em>{/* 월 */}{t('Auth.common.month')}</em>
                </MenuItem>
                <MenuItem value={`01`}>1{/* 월 */}</MenuItem>
                <MenuItem value={`02`}>2{/* 월 */}</MenuItem>
                <MenuItem value={`03`}>3{/* 월 */}</MenuItem>
                <MenuItem value={`04`}>4{/* 월 */}</MenuItem>
                <MenuItem value={`05`}>5{/* 월 */}</MenuItem>
                <MenuItem value={`06`}>6{/* 월 */}</MenuItem>
                <MenuItem value={`07`}>7{/* 월 */}</MenuItem>
                <MenuItem value={`08`}>8{/* 월 */}</MenuItem>
                <MenuItem value={`09`}>9{/* 월 */}</MenuItem>
                <MenuItem value={`10`}>10{/* 월 */}</MenuItem>
                <MenuItem value={`11`}>11{/* 월 */}</MenuItem>
                <MenuItem value={`12`}>12{/* 월 */}</MenuItem>
              </Select>
            </FormControl>
            <FormControl sx={{ m: 1, minWidth: 80 }}>
              <Select
                value={student.day}
                onChange={handleInputChange}
                displayEmpty
                name='day'
                inputProps={{ 'aria-label': 'Without label' }}
              >
                <MenuItem value="" disabled>
                  <em>{/* 일 */}{t('Auth.common.day')}</em>
                </MenuItem>
                <MenuItem value={`01`}>1</MenuItem>
                <MenuItem value={`02`}>2</MenuItem>
                <MenuItem value={`03`}>3</MenuItem>
                <MenuItem value={`04`}>4</MenuItem>
                <MenuItem value={`05`}>5</MenuItem>
                <MenuItem value={`06`}>6</MenuItem>
                <MenuItem value={`07`}>7</MenuItem>
                <MenuItem value={`08`}>8</MenuItem>
                <MenuItem value={`09`}>9</MenuItem>
                <MenuItem value={`10`}>10</MenuItem>
                <MenuItem value={`11`}>11</MenuItem>
                <MenuItem value={`12`}>12</MenuItem>
                <MenuItem value={`13`}>13</MenuItem>
                <MenuItem value={`14`}>14</MenuItem>
                <MenuItem value={`15`}>15</MenuItem>
                <MenuItem value={`16`}>16</MenuItem>
                <MenuItem value={`17`}>17</MenuItem>
                <MenuItem value={`18`}>18</MenuItem>
                <MenuItem value={`19`}>19</MenuItem>
                <MenuItem value={`20`}>20</MenuItem>
                <MenuItem value={`21`}>21</MenuItem>
                <MenuItem value={`22`}>22</MenuItem>
                <MenuItem value={`23`}>23</MenuItem>
                <MenuItem value={`24`}>24</MenuItem>
                <MenuItem value={`25`}>25</MenuItem>
                <MenuItem value={`26`}>26</MenuItem>
                <MenuItem value={`27`}>27</MenuItem>
                <MenuItem value={`28`}>28</MenuItem>
                <MenuItem value={`29`}>29</MenuItem>
                <MenuItem value={`30`}>30</MenuItem>
                <MenuItem value={`31`}>31</MenuItem>
              </Select>
            </FormControl>
            </div>
            <div className={`${styles.input_area} ${styles.name}`}>
              <p>{/* 생년월일, 성별 데이터를 정확하게 입력해주시면 학습자의 데이터 분석에 많은 도움이 됩니다.<br></br><br></br>* 필수입력 사항입니다. */}
              <Trans i18nKey="Auth.common.confirm_string7"/></p>
            </div>
            
            <a href="#" onClick={handleSubmit} className={styles.ok}>{/* 가입 완료 */}{t('Auth.common.register_complete')}</a>
          </div>
        </div>
      </div>
    </>
  );
}