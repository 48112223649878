import React from 'react';

const Footer = () => {

    return (
        <>
            {/* Footer */}
        </>
    );
    
};

export default Footer;