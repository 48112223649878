import {
  CREATE_PRODUCT,
  LIST_PRODUCT,
  DELETE_PRODUCT,
  MODIFY_PRODUCT,
  SELECT_PRODUCT,
  LIST_PRODUCT_GROUP,
  LIST_PRODUCT_GROUP_OPTION1,
  LIST_PRODUCT_GROUP_OPTION2,
} from "../actions/types";

const initialState = [];

const productReducer = (product = initialState, action) => {
  const { type, payload } = action;

  // console.log("productReducer Call!");
  // console.log("type : ", type);
  // console.log("payload : ", payload);

  switch (type) {
    
    case CREATE_PRODUCT:
      return [];

    case LIST_PRODUCT:
      return payload;

    case SELECT_PRODUCT:
      return payload;

    case MODIFY_PRODUCT:
      return product.map((product) => {
        if (product.prd_idx === payload.prd_idx) {
          return {
            ...product,
            ...payload,
          };
        } else {
          return product;
        }
      });

    case DELETE_PRODUCT:
      return product.filter(({ prd_idx }) => prd_idx !== payload.prd_idx);

    case LIST_PRODUCT_GROUP:
      return payload;

    case LIST_PRODUCT_GROUP_OPTION1:
      return {
        ...product,
        option1 : payload
      };

    case LIST_PRODUCT_GROUP_OPTION2:
      return {
        ...product,
        option2 : payload
      };

    default:
      return product;
  }
};

export default productReducer;