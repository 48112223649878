import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';

import Box from '@mui/material/Box';
import Input from '@mui/material/Input';
import Link from '@mui/material/Link';
import FormControl from '@mui/material/FormControl';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormLabel from '@mui/material/FormLabel';
import FormControlLabel from '@mui/material/FormControlLabel';

import TextField from '@mui/material/TextField';

import Stack from '@mui/material/Stack';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import MobileDatePicker from '@mui/lab/MobileDatePicker';

import { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { rankingCreate } from "../../../actions/ranking";

import { useNavigate, useParams } from 'react-router-dom';

const ariaLabel = { 'aria-label': 'description' };

export default function NewsWrite() {

    const initialSendData = {
        order: 0, 
        ranking_start_date: null,
        ranking_end_date: null,
        open_start_date: null,
        open_end_date: null,
        question_number_start: 0,
        question_number_end: 0,
        open: 1,
    };

    const [open, setOpen] = useState(1);
    const [sendData, setSendData] = useState(initialSendData);
    const [datePickerDisabled, setDatePickerDisabled] = useState(false);

    // 리듀서 값들 저장
    const userInfo = useSelector(state => state.auth);
    // console.log("userInfo : ", userInfo);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const onChangeValue = (event) => {

        console.log(event.target.name);
        console.log(event.target.value);

        if (event.target.name === "question_number_start")
        {
            setSendData({ ...sendData, question_number_start: event.target.value });
        }
        else if (event.target.name === "question_number_end")
        {
            setSendData({ ...sendData, question_number_end: event.target.value });
        }
        else if (event.target.name === "open")
        {
            setSendData({ ...sendData, open: event.target.value });
        }
        else if (event.target.name === "order")
        {
            setSendData({ ...sendData, order: event.target.value });
        }

    }

    const handleRankingStartDateChange = (newValue) => {
        setSendData({ ...sendData, ranking_start_date: newValue });
    };

    const handleRankingEndDateChange = (newValue) => {
        setSendData({ ...sendData, ranking_end_date: newValue });
    };

    const handleOpenStartDateChange = (newValue) => {
        setSendData({ ...sendData, open_start_date: newValue });
    };

    const handleOpenEndDateChange = (newValue) => {
        setSendData({ ...sendData, open_end_date: newValue });
    };

    const onWriteSubmit = (event) => {

        event.preventDefault();

        console.log("sendData : ", sendData);
        
        if(userInfo.isLoggedIn)
        {
            if (sendData.order === 0)
            {
                alert("회차 정보가 없습니다.");
                return;
            }
        
            // 여기서 디스패치로~
            dispatch(rankingCreate(sendData))
            .then(data => 
            {
                console.log("data : ", data);
                if (!data.success)
                {
                    alert(data.msg);
                    return;
                }
                else
                    navigate(`/dashboard/RankingList`);
    
            })
            .catch(e => {
                console.log(e);
            });
        }
        else
        {
            alert("로그인 후에 공지를 작성할 수 있습니다.");
            return;
        }
    }

    const handleStartDateChange = (newValue) => {
        setSendData({ ...sendData, start_date: newValue });
    };

    const handleEndDateChange = (newValue) => {
        setSendData({ ...sendData, end_date: newValue });
    };

  return (
    <Card sx={{ minWidth: 275 }}>
        <CardContent>
        <Box
            sx={{
                '& > :not(style)': { m: 1, width: '400px' },
            }}
            >
            야미 랭킹 설정 쓰기<br></br><br></br>
        </Box>

        <Box
            sx={{
                '& > :not(style)': { m: 1, width: '400px' },
            }}
            noValidate
            autoComplete="off"
            >
            <TextField
                    id="order"
                    label="회차 정보"
                    type="number"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    variant="standard"
                    name="order"
                    value={sendData.order}
                    helperText="몇 회차입니까?"
                    onChange={onChangeValue}
                    inputProps={{
                        inputMode: 'numeric', 
                        pattern: '[0-9]*',
                        min: 1,
                        max: 10000,
                        type: 'number',
                    }}
                />
        </Box>

        <Box
            sx={{
                '& > :not(style)': { m: 1, width: '400px' },
            }}
            noValidate
            autoComplete="off"
            >
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                * 랭킹 도전 날짜
                <Stack spacing={3}>
                    <MobileDatePicker
                    label="시작일"
                    inputFormat="yyyy/MM/dd"
                    value={sendData.ranking_start_date || ""}
                    onChange={handleRankingStartDateChange}
                    renderInput={(params) => <TextField {...params} />}
                    name="ranking_start_date"
                    disabled={datePickerDisabled}
                    />
                    <MobileDatePicker
                    label="종료일"
                    inputFormat="yyyy/MM/dd"
                    value={sendData.ranking_end_date || ""}
                    onChange={handleRankingEndDateChange}
                    renderInput={(params) => <TextField {...params} />}
                    name="ranking_end_date"
                    disabled={datePickerDisabled}
                    />
                </Stack>
            </LocalizationProvider>
        </Box>

        <Box
            sx={{
                '& > :not(style)': { m: 1, width: '400px' },
            }}
            noValidate
            autoComplete="off"
            >
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                * 순위 노출 날짜
                <Stack spacing={3}>
                    <MobileDatePicker
                    label="시작일"
                    inputFormat="yyyy/MM/dd"
                    value={sendData.open_start_date || ""}
                    onChange={handleOpenStartDateChange}
                    renderInput={(params) => <TextField {...params} />}
                    name="open_start_date"
                    disabled={datePickerDisabled}
                    />
                    <MobileDatePicker
                    label="종료일"
                    inputFormat="yyyy/MM/dd"
                    value={sendData.open_end_date || ""}
                    onChange={handleOpenEndDateChange}
                    renderInput={(params) => <TextField {...params} />}
                    name="open_end_date"
                    disabled={datePickerDisabled}
                    />
                </Stack>
            </LocalizationProvider>
        </Box>

        <Box
            sx={{
                '& > :not(style)': { m: 1, width: '400px' },
            }}
            noValidate
            autoComplete="off"
            >
            <TextField
                    id="question_number_start"
                    label="첫번째 문제번호"
                    type="number"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    variant="standard"
                    name="question_number_start"
                    value={sendData.question_number_start}
                    helperText="이번 회차의 첫번째 문제의 번호입니다."
                    onChange={onChangeValue}
                    inputProps={{
                        inputMode: 'numeric', 
                        pattern: '[0-9]*',
                        min: 1,
                        max: 10000,
                        type: 'number',
                    }}
                />
        </Box>

        <Box
            sx={{
                '& > :not(style)': { m: 1, width: '400px' },
            }}
            noValidate
            autoComplete="off"
            >
            <TextField
                    id="question_number_end"
                    label="마지막 문제번호"
                    type="number"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    variant="standard"
                    name="question_number_end"
                    value={sendData.question_number_end}
                    helperText="이번 회차의 마지막 문제의 번호입니다."
                    onChange={onChangeValue}
                    inputProps={{
                        inputMode: 'numeric', 
                        pattern: '[0-9]*',
                        min: 1,
                        max: 10000,
                        type: 'number',
                    }}
                />
        </Box>

        <Box
        sx={{
            '& > :not(style)': { m: 1, width: '400px' },
        }}
        noValidate
        autoComplete="off"
        >
            <FormControl>
            <FormLabel id="open">공개여부</FormLabel>
            <RadioGroup
                row
                aria-labelledby="open"
                defaultValue="true"
                name="open"
                onChange={onChangeValue}
                value={sendData.open}
            >
                <FormControlLabel value={1} control={<Radio />} label="공개" />
                <FormControlLabel value={0} control={<Radio />} label="비공개" />
            </RadioGroup>
            </FormControl>
        </Box>

        </CardContent>
        <CardActions>
            <Button onClick={onWriteSubmit} size="small">쓰기</Button>
            <Link href={`/dashboard/RankingList`} underline="none">목록</Link>
        </CardActions>
    </Card>
  );
}