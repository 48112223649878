import {
  REGISTER_PRODUCT_MISSION,
  USE_PRODUCT_MISSION,
  USENOT_PRODUCT_MISSION,
} from "../actions/types";

const initialState = [];

const productMissionReducer = (product_mission = initialState, action) => {
  const { type, payload } = action;

  // console.log("productMissionReducer Call!");
  // console.log("type : ", type);
  // console.log("payload : ", payload);

  switch (type) {

    case REGISTER_PRODUCT_MISSION:
      return [];

    case USE_PRODUCT_MISSION:
      return {
        ...product_mission,
        useMission : payload,
      };

    case USENOT_PRODUCT_MISSION:
      return {
        ...product_mission,
        useNotMission : payload,
      };

    default:
      return product_mission;
  }
};

export default productMissionReducer;