import http from "../http-common";

const refundGetAll = () => {
  return http.get("payment/refund");
};

const RefundService = {
  refundGetAll,
};

export default RefundService;
