import * as React from 'react';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import styles from "../css/common.module.css";
import $ from 'jquery';

import { solutionNonMemberCreate, solutionNonMemberCheck, solutionCreate } from "../actions/solution";

import { useTranslation, Trans } from 'react-i18next'; // 1. react-i18next import 

export default function Qna() {

	const authState = useSelector(state => state.auth);
    // console.log("authState : ", authState);

	const dispatch = useDispatch();	

	const { t, i18n } = useTranslation(); // 3. useTranslation hook 선언

	const [isChecked, setIsChecked] = useState(false);
	const [vName, setVName] = useState("");
	const [vEmail, setVEmail] = useState("");
	const [vPassword, setVPassword] = useState("");
	const [vCategory, setVCategory] = useState("");
	const [vTitle, setVTitle] = useState("");
	const [vQuestion, setVQuestion] = useState("");
	const [vPopupName, setVPopupName] = useState("");
	const [vPopupEmail, setVPopupEmail] = useState("");
	const [vPopupPassword, setVPopupPassword] = useState("");

	let language = localStorage.getItem("language");
    // console.log("axios.interceptors.request.language : ", language);

	let faqArr=[];

	if (language === 'en')
	{
		faqArr=[
			,{"index":1,"categori":"학습(앱)","title":"How do I download YAM-E?","answer":"YAM-E supports both Android and iOS. Android will be available in the Play Store from September, and iOS is preparing to be released. <br>If you receive an e-mail from the parent information management on my page, you can receive news of the release."}
			,{"index":2,"categori":"학습(앱)","title":"Should YAM-E be connected to the Internet?","answer":"YAM-E is an app that you can't use unless you have an Internet connection. Please check the internet before using it."}
			,{"index":3,"categori":"학습(앱)","title":"What are the specifications for Android?","answer":"Device used: Galaxy S5 to Galaxy S22 <br>Operating System: OS Android 5.1 to Android 12"}
			,{"index":4,"categori":"학습(앱)","title":"Is there a specific device for simple login?","answer":"Google login is only available on Android and Apple login is only available on Apple. Please select the appropriate simple login for the device to run the YAM-E coding app."}
			,{"index":5,"categori":"홈페이지","title":"How do I download the membership manual?","answer":"I share the membership manual. Download it.  <a href='https://yamecoding.com/download/YAME%20%EC%82%AC%EC%9A%A9%EA%B0%80%EC%9D%B4%EB%93%9C(%EC%95%B1%EB%8B%A4%EC%9A%B4%EB%A1%9C%EB%93%9C%20%EB%B0%8F%20%ED%9A%8C%EC%9B%90%EA%B0%80%EC%9E%85)%20Ver%201.0.pdf' download> [Download]</a> <br>Click to open the window and see the document and to download it to your PC, right-click the link and click Save As."}
			,{"index":6,"categori":"홈페이지","title":"I can't log in after signing up.","answer":"After signing up for membership, you will receive a verification email to the email you signed up for. You need to verify your email to log in."}
			,{"index":7,"categori":"홈페이지","title":"How do I download the coupon registration and usage?","answer":"I share the coupon registration and usage manual. Download it.  <a href='https://yamecoding.com/download/YAME%20%EC%82%AC%EC%9A%A9%EA%B0%80%EC%9D%B4%EB%93%9C(%EC%BF%A0%ED%8F%B0%EB%93%B1%EB%A1%9D%20%EB%B0%A9%EB%B2%95%EA%B3%BC%20%EC%82%AC%EC%9A%A9)%20Ver%201.0.pdf' download> [Download]</a> <br>Click to open the window and see the document and to download it to your PC, right-click the link and click Save As."}
			,{"index":8,"categori":"홈페이지","title":"How do I download the paid payment manual?","answer":"I'm sharing the manual on how to pay. Download it.  <a href='https://yamecoding.com/download/YAME%20%EC%82%AC%EC%9A%A9%EA%B0%80%EC%9D%B4%EB%93%9C(%EC%9C%A0%EB%A3%8C%EA%B2%B0%EC%A0%9C%20%EB%B0%A9%EB%B2%95)%20Ver%201.0.pdf' download> [Download]</a> <br>Click to open the window and see the document and to download it to your PC, right-click the link and click Save As."}
			,{"index":9,"categori":"홈페이지","title":"Is there no study report for free learners?","answer":"Learning reports are for paid users. Provides information about paid users as a report."}
			,{"index":10,"categori":"결제","title":"How can I make a group purchase?","answer":"More than 30 learners must use the group purchase. For group purchases, please contact us by email or phone at the bottom of the website."}
			,{"index":11,"categori":"결제","title":"There's a problem with the payment. Where do I ask?","answer":"If there is a payment error, please contact Inicis. If you have any problems using Yami after the payment is successful, please contact us by email or phone at the bottom of the website."}		
			,{"index":12,"categori":"기타","title":"I'd like to ask YAM-E directly and get feedback.","answer":"If you have any questions, you can log in and log in to leave them. I'll answer it. Please go to the link. <a href='/Qna'> [Shortcut to Contact]</a>"}		
			,{"index":13,"categori":"결제","title":"How can I get a refund?","answer":"You can get a refund right away if you didn't use Yami within a week from the date of payment. My Page > You can get a refund from the payment information."}
		];
	}
	else
	{
		faqArr=[
			,{"index":1,"categori":"학습(앱)","title":"야미는 어떻게 다운로드 하나요?","answer":"야미는 Android와 ios를 모두 지원합니다. Android는 9월부터 플레이스토어에서 사용가능하시고 ios는 출시 준비 중입니다. <br>마이페이지의 학부모 정보관리에서 이메일 수신을 해주시면 출시 소식을 받으실 수 있습니다."}
			,{"index":2,"categori":"학습(앱)","title":"야미는 인터넷에 연결되어야 하나요?","answer":"야미는 인터넷에 연결이 되지 않으면 사용할 수 없는 앱입니다. 반드시 인터넷을 확인하시고 사용해주세요."}
			,{"index":3,"categori":"학습(앱)","title":"안드로이드 사용 스펙은 어떻게 되나요?","answer":"사용기기 : galaxy S5 ~ galaxy S22까지 <br>운영체제 : OS Android 5.1 ~ Android 12까지"}
			,{"index":4,"categori":"학습(앱)","title":"간편 로그인은 사용기기가 정해져 있나요?","answer":"간편 로그인 중 구글 로그인은 안드로이드에서만 사용 가능하고 애플 로그인은 애플에서만 사용 가능합니다. 야미 코딩 앱을 실행할 기기에 맞는 간편 로그인을 선택해 주세요."}
			,{"index":5,"categori":"홈페이지","title":"회원가입 메뉴얼 다운로드 어떻게 하나요?","answer":"회원가입 메뉴얼 공유드립니다. 다운로드 하세요.  <a href='https://yamecoding.com/download/YAME%20%EC%82%AC%EC%9A%A9%EA%B0%80%EC%9D%B4%EB%93%9C(%EC%95%B1%EB%8B%A4%EC%9A%B4%EB%A1%9C%EB%93%9C%20%EB%B0%8F%20%ED%9A%8C%EC%9B%90%EA%B0%80%EC%9E%85)%20Ver%201.0.pdf' download> [다운로드]</a> <br>클릭하시면 창이 열리면서 문서가 보이고 PC에 다운받으실려면 링크에 마우스 오른쪽 버튼을 누르시고 다른이름으로 저장하기를 누르세요."}
			,{"index":6,"categori":"홈페이지","title":"회원가입 후 로그인이 안됩니다.","answer":"회원가입 후에 가입하신 이메일로 인증메일이 갑니다. 이메일 인증을 해주셔야 로그인이 됩니다."}
			,{"index":7,"categori":"홈페이지","title":"쿠폰등록과 사용법은 다운로드 어떻게 하나요?","answer":"쿠폰등록과 사용법 메뉴얼 공유드립니다. 다운로드 하세요.  <a href='https://yamecoding.com/download/YAME%20%EC%82%AC%EC%9A%A9%EA%B0%80%EC%9D%B4%EB%93%9C(%EC%BF%A0%ED%8F%B0%EB%93%B1%EB%A1%9D%20%EB%B0%A9%EB%B2%95%EA%B3%BC%20%EC%82%AC%EC%9A%A9)%20Ver%201.0.pdf' download> [다운로드]</a> <br>클릭하시면 창이 열리면서 문서가 보이고 PC에 다운받으실려면 링크에 마우스 오른쪽 버튼을 누르시고 다른이름으로 저장하기를 누르세요."}
			,{"index":8,"categori":"홈페이지","title":"유료결제방법 메뉴얼 다운로드 어떻게 하나요?","answer":"유료결제방법 메뉴얼 공유드립니다. 다운로드 하세요.  <a href='https://yamecoding.com/download/YAME%20%EC%82%AC%EC%9A%A9%EA%B0%80%EC%9D%B4%EB%93%9C(%EC%9C%A0%EB%A3%8C%EA%B2%B0%EC%A0%9C%20%EB%B0%A9%EB%B2%95)%20Ver%201.0.pdf' download> [다운로드]</a> <br>클릭하시면 창이 열리면서 문서가 보이고 PC에 다운받으실려면 링크에 마우스 오른쪽 버튼을 누르시고 다른이름으로 저장하기를 누르세요."}
			,{"index":9,"categori":"홈페이지","title":"무료 학습자는 학습 리포트가 안나오나요?","answer":"학습 리포트는 유료 사용자용입니다. 유료 사용자의 정보를 리포트로 제공합니다."}
			,{"index":10,"categori":"결제","title":"단체 구매는 어떻게 하나요?","answer":"30명 이상의 학습자는 단체구매를 이용하셔야 합니다. 단체 구매는 홈페이지 하단의 문의메일이나 전화로 문의 부탁드립니다."}
			,{"index":11,"categori":"결제","title":"결제에 문제가 생겼어요. 어디에 문의하나요?","answer":"결제 오류가 발생하시면 이니시스에 문의부탁드립니다. 만약 결제성공 후 야미의 이용에 문제가 생기시면 홈페이지 하단의 문의메일이나 전화로 문의 부탁드립니다."}		
			,{"index":12,"categori":"기타","title":"야미에 직접 문의하고 하고 피드백 받고 싶어요.","answer":"문의사항은 로그인 및 로그인으로 문의를 남길 수 있습니다. 답변을 해드립니다. 링크로 이동해주세요. <a href='/Qna'> [문의사항 바로가기]</a>"}		
			,{"index":13,"categori":"결제","title":"환불은 어떻게 받아요?","answer":"환불은 결제하신 날짜로 부터 일주일 안에 야미를 사용하지 않았으면 바로 환불 가능합니다. 마이페이지 > 결제정보에서 환불하시면 됩니다."}
		];
	}

	// let faqArr=[
	// 	,{"index":1,"categori":"학습(앱)","title":"야미는 어떻게 다운로드 하나요?","answer":"야미는 Android와 ios를 모두 지원합니다. Android는 9월부터 플레이스토어에서 사용가능하시고 ios는 출시 준비 중입니다. <br>마이페이지의 학부모 정보관리에서 이메일 수신을 해주시면 출시 소식을 받으실 수 있습니다."}
	// 	,{"index":2,"categori":"학습(앱)","title":"야미는 인터넷에 연결되어야 하나요?","answer":"야미는 인터넷에 연결이 되지 않으면 사용할 수 없는 앱입니다. 반드시 인터넷을 확인하시고 사용해주세요."}
	// 	,{"index":3,"categori":"학습(앱)","title":"안드로이드 사용 스펙은 어떻게 되나요?","answer":"사용기기 : galaxy S5 ~ galaxy S22까지 <br>운영체제 : OS Android 5.1 ~ Android 12까지"}
	// 	,{"index":4,"categori":"학습(앱)","title":"간편 로그인은 사용기기가 정해져 있나요?","answer":"간편 로그인 중 구글 로그인은 안드로이드에서만 사용 가능하고 애플 로그인은 애플에서만 사용 가능합니다. 야미 코딩 앱을 실행할 기기에 맞는 간편 로그인을 선택해 주세요."}
	// 	,{"index":5,"categori":"홈페이지","title":"회원가입 메뉴얼 다운로드 어떻게 하나요?","answer":"회원가입 메뉴얼 공유드립니다. 다운로드 하세요.  <a href='https://yamecoding.com/download/YAME%20%EC%82%AC%EC%9A%A9%EA%B0%80%EC%9D%B4%EB%93%9C(%EC%95%B1%EB%8B%A4%EC%9A%B4%EB%A1%9C%EB%93%9C%20%EB%B0%8F%20%ED%9A%8C%EC%9B%90%EA%B0%80%EC%9E%85)%20Ver%201.0.pdf' download> [다운로드]</a> <br>클릭하시면 창이 열리면서 문서가 보이고 PC에 다운받으실려면 링크에 마우스 오른쪽 버튼을 누르시고 다른이름으로 저장하기를 누르세요."}
	// 	,{"index":6,"categori":"홈페이지","title":"회원가입 후 로그인이 안됩니다.","answer":"회원가입 후에 가입하신 이메일로 인증메일이 갑니다. 이메일 인증을 해주셔야 로그인이 됩니다."}
	// 	,{"index":7,"categori":"홈페이지","title":"쿠폰등록과 사용법은 다운로드 어떻게 하나요?","answer":"쿠폰등록과 사용법 메뉴얼 공유드립니다. 다운로드 하세요.  <a href='https://yamecoding.com/download/YAME%20%EC%82%AC%EC%9A%A9%EA%B0%80%EC%9D%B4%EB%93%9C(%EC%BF%A0%ED%8F%B0%EB%93%B1%EB%A1%9D%20%EB%B0%A9%EB%B2%95%EA%B3%BC%20%EC%82%AC%EC%9A%A9)%20Ver%201.0.pdf' download> [다운로드]</a> <br>클릭하시면 창이 열리면서 문서가 보이고 PC에 다운받으실려면 링크에 마우스 오른쪽 버튼을 누르시고 다른이름으로 저장하기를 누르세요."}
	// 	,{"index":8,"categori":"홈페이지","title":"유료결제방법 메뉴얼 다운로드 어떻게 하나요?","answer":"유료결제방법 메뉴얼 공유드립니다. 다운로드 하세요.  <a href='https://yamecoding.com/download/YAME%20%EC%82%AC%EC%9A%A9%EA%B0%80%EC%9D%B4%EB%93%9C(%EC%9C%A0%EB%A3%8C%EA%B2%B0%EC%A0%9C%20%EB%B0%A9%EB%B2%95)%20Ver%201.0.pdf' download> [다운로드]</a> <br>클릭하시면 창이 열리면서 문서가 보이고 PC에 다운받으실려면 링크에 마우스 오른쪽 버튼을 누르시고 다른이름으로 저장하기를 누르세요."}
	// 	,{"index":9,"categori":"홈페이지","title":"무료 학습자는 학습 리포트가 안나오나요?","answer":"학습 리포트는 유료 사용자용입니다. 유료 사용자의 정보를 리포트로 제공합니다."}
	// 	,{"index":10,"categori":"결제","title":"단체 구매는 어떻게 하나요?","answer":"30명 이상의 학습자는 단체구매를 이용하셔야 합니다. 단체 구매는 홈페이지 하단의 문의메일이나 전화로 문의 부탁드립니다."}
	// 	,{"index":11,"categori":"결제","title":"결제에 문제가 생겼어요. 어디에 문의하나요?","answer":"결제 오류가 발생하시면 이니시스에 문의부탁드립니다. 만약 결제성공 후 야미의 이용에 문제가 생기시면 홈페이지 하단의 문의메일이나 전화로 문의 부탁드립니다."}		
	// 	,{"index":12,"categori":"기타","title":"야미에 직접 문의하고 하고 피드백 받고 싶어요.","answer":"문의사항은 로그인 및 로그인으로 문의를 남길 수 있습니다. 답변을 해드립니다. 링크로 이동해주세요. <a href='/Qna'> [문의사항 바로가기]</a>"}		
	// 	,{"index":13,"categori":"결제","title":"환불은 어떻게 받아요?","answer":"환불은 결제하신 날짜로 부터 일주일 안에 야미를 사용하지 않았으면 바로 환불 가능합니다. 마이페이지 > 결제정보에서 환불하시면 됩니다."}
	// ];

	useEffect(() => {

		// 여기에서 jQuery를 초기화 합니다.
		$(document).ready(function(){

			// console.log("jquery ready!");
			
			//검색창 엔터키 이벤트
			$(`#${styles.faq_input_id}`).on('keydown', function(e)
			{
				if (e.code === 'Enter')
				{
					$(`.${styles.faq_srch_area} button`)[0].click();
				}
			});

			// FAQ 리스트 출력 변수
			let outputHtml = "";
			
			// FAQ 리스트 반복문 (전체 출력)className={styles.faq_tab}
			faqArr.map((x)=> 
			{
				// console.log(x.categori);
				outputHtml += `<li class=${styles.list}>
					<div class=${styles.question_area}>
						<span>Q</span>
						<p>${x.title}</p>
						<img src="/assets/faq_arrow.png" alt="arrow"/>
					</div>
					<div class=${styles.answer_area}>
						<p class=${styles.answer}>${x.answer}</p>
					</div>
				</li>`;
			})

			// FAQ 리스트 출력
			// console.log('outputHtml : ', outputHtml);
			$(`.${styles.faq_list_wrap}`).html(outputHtml);

			//카테고리(셀렉트 값) 변경될때마다 해당 질문 뿌려주기
			$(`#${styles.faq_categori_select}`).on('change', function()
			{
				let th=$(this).val();
				$(`#${styles.faq_input_id}`).val("");

				outputHtml = "";
				$(`.${styles.faq_list_wrap}`).html(outputHtml);

				if(th==="결제")
				{
					faqArr.filter((x)=> x.categori==="결제").map((x)=> 
					{
						// console.log(x.categori);
						outputHtml += `<li class=${styles.list}>
							<div class=${styles.question_area}>
								<span>Q</span>
								<p>${x.title}</p>
								<img src="/assets/faq_arrow.png" alt="arrow"/>
							</div>
							<div class=${styles.answer_area}>
								<p class=${styles.answer}>${x.answer}</p>
							</div>
						</li>`;
					})
				}
				else if(th==="학습(앱)")
				{
					faqArr.filter((x)=> x.categori==="학습(앱)").map((x)=> 
					{
						// console.log(x.categori);
						outputHtml += `<li class=${styles.list}>
							<div class=${styles.question_area}>
								<span>Q</span>
								<p>${x.title}</p>
								<img src="/assets/faq_arrow.png" alt="arrow"/>
							</div>
							<div class=${styles.answer_area}>
								<p class=${styles.answer}>${x.answer}</p>
							</div>
						</li>`;
					})
				}
				else if(th==="홈페이지")
				{
					faqArr.filter((x)=> x.categori==="홈페이지").map((x)=> 
					{
						// console.log(x.categori);
						outputHtml += `<li class=${styles.list}>
							<div class=${styles.question_area}>
								<span>Q</span>
								<p>${x.title}</p>
								<img src="/assets/faq_arrow.png" alt="arrow"/>
							</div>
							<div class=${styles.answer_area}>
								<p class=${styles.answer}>${x.answer}</p>
							</div>
						</li>`;
					})
				}
				else if(th==="기타")
				{
					faqArr.filter((x)=> x.categori==="기타").map((x)=> 
					{
						// console.log(x.categori);
						outputHtml += `<li class=${styles.list}>
							<div class=${styles.question_area}>
								<span>Q</span>
								<p>${x.title}</p>
								<img src="/assets/faq_arrow.png" alt="arrow"/>
							</div>
							<div class=${styles.answer_area}>
								<p class=${styles.answer}>${x.answer}</p>
							</div>
						</li>`;
					})
				}
				else
				{
					faqArr.map((x)=> 
					{
						// console.log(x.categori);
						outputHtml += `<li class=${styles.list}>
							<div class=${styles.question_area}>
								<span>Q</span>
								<p>${x.title}</p>
								<img src="/assets/faq_arrow.png" alt="arrow"/>
							</div>
							<div class=${styles.answer_area}>
								<p class=${styles.answer}>${x.answer}</p>
							</div>
						</li>`;
					})
				};
				
				// FAQ 리스트 출력
				// console.log('outputHtml : ', outputHtml);
				$(`.${styles.faq_list_wrap}`).html(outputHtml);

			});


			//질문 영역 클릭시 답변 영역 보이도록, 답변은 하나만 보이도록
			$(document).on('click',`.${styles.faq_list_wrap} li`,function()
			{
				let tg=$(this);
				let activeList=$(`.${styles.faq_list_wrap}`).find(`li.${styles.on}`);
				// console.log('tg :', tg);
				// console.log('activeList :', activeList);
				
				if(activeList.index()===tg.index())
				{
					//현재 활성화된 질문을 또 클릭했을 경우
					$(`.${styles.faq_list_wrap} li`).removeClass(`${styles.on}`);
				}
				else
				{
					//현재 활성화된 질문이 아닌 다른 질문을 클릭한 경우
					$(`.${styles.faq_list_wrap} li`).removeClass(`${styles.on}`);
					tg.addClass(`${styles.on}`);
				};
			});


			//약관 보기 클릭시 팝업 띄우기
			$(`.${styles.qna_tab} .${styles.privacy_agree_area} .${styles.popup}`).on('click',function() 
			{
				openQnaPrivacy();
			});

			//문의유형 셀렉트 콘솔
			$(`#${styles.qna_form_categori_select}`).on('change',function()
			{
				// console.log($(`#${styles.qna_form_categori_select}`).val());
			});



		});

	}, [$]);


	//FAQ 검색기능
	function faqSearch()
	{
		let keyword=$(`#${styles.faq_input_id}`).val();
		let srchSelect=$(`#${styles.faq_srch_select}`).val();
		// console.log(srchSelect);
		
		if(keyword==="")
		{
			//검색어를 입력하지 않았을때
			// alert('검색어를 입력해주세요.');
			alert(`${t("FQA.alert.string1")}`);
		}
		else
		{
			if(srchSelect===null)
			{
				//검색 카테고리를 선택하지 않았을때
				// alert('검색하실 내용을 선택해주세요.');
				alert(`${t("FQA.alert.string2")}`);
			}
			else if(srchSelect==="1")
			{
				//검색 카테고리가 제목 일때
				$(`#${styles.faq_categori_select}`).val("");
				let outputHtml = "";
		
				if(faqArr.filter((x)=> x.title.includes(keyword)===true).length>0)
				{
					faqArr.filter((x)=> x.title.includes(keyword)===true).map((x)=> 
					{
						// console.log(x.categori);
						outputHtml += `<li class=${styles.list}>
							<div class=${styles.question_area}>
								<span>Q</span>
								<p>${x.title}</p>
								<img src="/assets/faq_arrow.png" alt="arrow"/>
							</div>
							<div class=${styles.answer_area}>
								<p class=${styles.answer}>${x.answer}</p>
							</div>
						</li>`;
					})
				}
				else
				{
					// console.log('검색 결과 없음');
					// outputHtml = `<li class=${styles.no_result}>검색결과가 없습니다</li>`;
					outputHtml = `<li class=${styles.no_result}>${t('FQA.string2')}</li>`;
				};	
		
				$(`.${styles.faq_list_wrap}`).html(outputHtml);
			}
			else if(srchSelect==="2")
			{
				//검색 카테고리가 내용 일때
				$(`#${styles.faq_categori_select}`).val("");
				let outputHtml = "";
				
				if(faqArr.filter((x)=> x.answer.includes(keyword)===true).length>0)
				{
					faqArr.filter((x)=> x.answer.includes(keyword)===true).map((x)=> 
					{
						// console.log(x.categori);
						outputHtml += `<li class=${styles.list}>
							<div class=${styles.question_area}>
								<span>Q</span>
								<p>${x.title}</p>
								<img src="/assets/faq_arrow.png" alt="arrow"/>
							</div>
							<div class=${styles.answer_area}>
								<p class=${styles.answer}>${x.answer}</p>
							</div>
						</li>`;
					})
				}
				else
				{
					// console.log('검색 결과 없음');
					// outputHtml = `<li class=${styles.no_result}>검색결과가 없습니다</li>`;
					outputHtml = `<li class=${styles.no_result}>${t('FQA.string2')}</li>`;
				};	
		
				$(`.${styles.faq_list_wrap}`).html(outputHtml);
			}
			else
			{
				$(`#${styles.faq_categori_select}`).val("");
				let outputHtml = "";
				// outputHtml = `<li class=${styles.no_result}>검색결과가 없습니다</li>`;
				outputHtml = `<li class=${styles.no_result}>${t('FQA.string2')}</li>`;
				$(`.${styles.faq_list_wrap}`).html(outputHtml);
			};
		};

	};

	//개인정보 팝업 띄우기
	function openQnaPrivacy()
	{
		blackBg(1);
		$(`.${styles.qna_privacy_popup}`).css('display','block');
	}
	//개인정보 팝업 닫기
	function closeQnaPrivacy()
	{
		blackBg(2);
		$(`.${styles.qna_privacy_popup_wrapper}`).stop().animate({scrollTop:0},1);

		setTimeout(function() 
		{
			$(`.${styles.qna_privacy_popup}`).css('display','none');
		}, 5);	
	}

	//비회원 본인확인 팝업 띄우기
	function openQnaNoMember()
	{
		blackBg(1);
		$(`.${styles.qna_no_member_confirm_popup}`).css('display','block');
	}

	//비회원 본인확인 닫기(취소)
	function closeQnaNoMember()
	{
		blackBg(2);
		$(`.${styles.qna_no_member_confirm_popup}`).css('display','none');

		$(`#${styles.qna_no_member_confirm_popup_name_input}`).val('');
		$(`#${styles.qna_no_member_confirm_popup_email_input}`).val('');
		$(`#${styles.qna_no_member_confirm_popup_password_input}`).val('');
	}
	//비회원 본인확인 닫기(확인)
	function continueQnaNoMember()
	{
		let name=$(`#${styles.qna_no_member_confirm_popup_name_input}`).val();
		let email=$(`#${styles.qna_no_member_confirm_popup_email_input}`).val();
		let password=$(`#${styles.qna_no_member_confirm_popup_password_input}`).val();

		// if (name === "")
		// 	return alert("이름을 입력해주세요.");
		// else if (email === "")
		// 	return alert("이메일을 입력해주세요.");
		// else if (password === "")
		// 	return alert("패스워드를 입력해주세요.");

		if (name === "")
			return alert(`${t("FQA.alert.string3")}`);
		else if (email === "")
			return alert(`${t("FQA.alert.string4")}`);
		else if (password === "")
			return alert(`${t("FQA.alert.string5")}`);

		// console.log(`이름 : ${name}, 이메일 : ${email}, 비밀번호 : ${password}`);

		// console.log("vPopupName : ", vPopupName);
		// console.log("vPopupEmail : ", vPopupEmail);
		// console.log("vPopupPassword : ", vPopupPassword);

		$(`#${styles.qna_no_member_confirm_popup_name_input}`).val('');
		$(`#${styles.qna_no_member_confirm_popup_email_input}`).val('');
		$(`#${styles.qna_no_member_confirm_popup_password_input}`).val('');

		const sendData = {
			name: vPopupName,
			email: vPopupEmail,
			password: vPopupPassword,
		};

		// console.log("sendData : ", sendData);

		// 여기서 디스패치로~
		dispatch(solutionNonMemberCheck(sendData))
		.then(data => 
		{
			// console.log("data : ", data);
			if (!data.success)
			{
				alert(data.msg);
				return;
			}
			else
			{
				document.location.href = `/NonMemberQnaInfo/${vPopupName}/${vPopupEmail}`;
			}

		})
		.catch(e => {
			console.log(e);
		});

		blackBg(2);
		$(`.${styles.qna_no_member_confirm_popup}`).css('display','none');

	}

	//검정 반투명 배경 제어
	function blackBg(i)
	{
		if(i===1)
			{
				$(`.${styles.body_black}`).css('display','block');
				// $('body').css('overflow','hidden');
				// console.log('배경보임');
			}
			else
			{
				$(`.${styles.body_black}`).css('display','none');
				// $('body').css('overflow','visible');
				// console.log('배경꺼짐');
			};
	};

	// 인풋의 정보를 받는다.
	const handleChange = (event) => {
		// console.log(event.target.name);
		// console.log(event.target.value);

		if (event.target.name === "name")
			setVName(event.target.value);
		else if (event.target.name === "email")
			setVEmail(event.target.value);
		else if (event.target.name === "password")
			setVPassword(event.target.value);
		else if (event.target.name === "qna_form_categori")
			setVCategory(event.target.value);
		else if (event.target.name === "title")
			setVTitle(event.target.value);
		else if (event.target.name === "qna_form_content")
			setVQuestion(event.target.value);
	}

	// 팝업창 인풋의 정보를 받는다.
	const handleChangePopup = (event) => {
		// console.log(event.target.name);
		// console.log(event.target.value);

		if (event.target.name === "popupName")
			setVPopupName(event.target.value);
		else if (event.target.name === "popupEmail")
			setVPopupEmail(event.target.value);
		else if (event.target.name === "popupPassword")
			setVPopupPassword(event.target.value);
	}

	// 체크박스 이벤트
	const handleChecked = (event) => {
		// console.log(event.target.checked);
		setIsChecked(event.target.checked);
	};

	// 내용을 서버에 전달합니다.
	const handleSubmit = (event) => {

		event.preventDefault();

		// console.log("isChecked : ", isChecked);
		// console.log("vName : ", vName);
		// console.log("vEmail : ", vEmail);
		// console.log("vPassword : ", vPassword);
		// console.log("vCategory : ", vCategory);
		// console.log("vTitle : ", vTitle);
		// console.log("vQuestion : ", vQuestion);

		// if (isChecked === false)
		// 	return alert("약관에 동의해주세요.");

		if (isChecked === false)
			return alert(`${t("FQA.alert.string6")}`);

		let sendData;
		
		if (authState.isLoggedIn)
		{
			sendData = {
				mem_idx: authState?.user?.idx,
				name: "",
				email: "",
				category: vCategory,
				title: vTitle,
				question: vQuestion,
				password: "",
			};

			// 여기서 디스패치로~
			dispatch(solutionCreate(sendData))
			.then(data => 
			{
				// console.log("data : ", data);
				if (!data.success)
				{
					alert(data.msg);
					return;
				}
				else
				{
					document.location.href = '/UserMypage/QnaInfo';
				}
			})
			.catch(e => {
				console.log(e);
			});
		}
		else
		{
			sendData = {
				mem_idx: 0,
				name: vName,
				email: vEmail,
				category: vCategory,
				title: vTitle,
				question: vQuestion,
				password: vPassword,
			};

			// 여기서 디스패치로~
			dispatch(solutionNonMemberCreate(sendData))
			.then(data => 
			{
				// console.log("data : ", data);
				if (!data.success)
				{
					alert(data.msg);
					return;
				}
				else
				{
					document.location.reload();

					// alert("등록이 완료되었습니다. \n비회원 답변 확인하기 버튼을 눌러서 확인해주세요.");
					alert(`${t("FQA.alert.string7")}`);
					return;
				}
			})
			.catch(e => {
				console.log(e);
			});
		}
	}

  return (
    <>
      	{/* Header 분리됨 MainLayout Header에 있음 */}

		<div className={styles.body_black}></div>
		<div className={styles.qna_no_member_confirm_popup}>
			<div>
				<span className={styles.qna_no_member_confirm_popup_tit}>{/* 비회원 본인확인 */}{t('FQA.Qna.string1')}</span>
				<span>{/* 이름 */}{t('FQA.Qna.string2')}</span>
				<input type="text" id={styles.qna_no_member_confirm_popup_name_input} name="popupName" onChange={(e)=>handleChangePopup(e)} value={vPopupName} />
				<span>{/* 이메일 */}{t('FQA.Qna.string3')}</span>
				<input type="email" id={styles.qna_no_member_confirm_popup_email_input} name="popupEmail" onChange={(e)=>handleChangePopup(e)} value={vPopupEmail} />
				<span>{/* 비밀번호 */}{t('FQA.Qna.string4')}</span>
				<input type="password" id={styles.qna_no_member_confirm_popup_password_input} name="popupPassword" onChange={(e)=>handleChangePopup(e)} value={vPopupPassword} />
				<div className={styles.btn_area}>
					<button type="button" className={styles.cancle} onClick={closeQnaNoMember}>{/* 취소 */}{t('Common.cancel')}</button>
					<button type="button" className={styles.confirm} onClick={continueQnaNoMember}>{/* 확인 */}{t('Common.confirm')}</button>
				</div>
			</div>
		</div>
		<div className={styles.qna_privacy_popup}>
			<div className={styles.popup_area}>
				<div className={styles.close_area}>
					<button type="button" className={styles.close} onClick={closeQnaPrivacy}><img src="/assets/back_w_2px.png" alt="close"/></button>
				</div>
				<div className={styles.qna_privacy_popup_wrapper}>
					<div className={styles.popup_content_wrap}>
						<span className={styles.qna_privacy_popup_tit}>{/* 개인정보처리방침 */}{t('FQA.qna_privacy_popup.string1')}</span>
						<p className={styles.qna_privacy_popup_txt}>{/* 정보통신망법 규정에 따라 (주)위클러버의 상담목적으로 수집하는 개인정보의 항목, 개인정보의 수집 및 이용목적, 개인정보의 보유 및 이용기간을 안내해 드리면 자세히 읽은 후 동의하여 주시기 바랍니다. */}
						{t('FQA.qna_privacy_popup.string2')}</p>
						<div className={styles.qna_privacy_popup_content_wrap}>
							<span className={styles.content_tit}>{/* 1. 수집하는 개인정보의 항목 */}{t('FQA.qna_privacy_popup.string3')}</span>
							<p>
								{/* (1) 회사는 원활한 고객상담, 각종 서비스의 제공을 위해 최초 회원상담 당시 아래와 같은 최소한의 개인정보를 필수항목으로 수집하고 있습니다. */}
								{t('FQA.qna_privacy_popup.string4')}
								<br></br><br></br>{/* * 상담신청 필수 항목: 이름, 이메일 */}{t('FQA.qna_privacy_popup.string5')}
								<br></br><br></br>{/* (2) 서비스 이용과정이나 사업 처리 과정에서 아래와 같은 정보들이 자동으로 생성되어 수집될 수 있습니다. */}
								{t('FQA.qna_privacy_popup.string6')}
								<br></br><br></br>{/* * IP Address, 쿠키, 방문 일시, 서비스 이용 기록, 불량 이용 기록, 기기 정보 */}
								{t('FQA.qna_privacy_popup.string7')}
								<br></br><br></br>{/* (3) 이벤트 응모 과정에서 해당 서비스의 이용자에 한해서만 개인정보 추가 수집이 발생할 수 있으며, 이러한 경우 별도의 동의를 받습니다. */}
								{t('FQA.qna_privacy_popup.string8')}
							</p>
							<span className={styles.content_tit}>{/* 2. 개인정보의 수집 및 이용 목적 */}{t('FQA.qna_privacy_popup.string9')}</span>
							<p>
								{/* (1) 회사의 상담 서비스 제공에 관한 이행 및 서비스 제공에 따른 컨텐츠 제공, 특정 맞춤 서비스 제공 등 */}
								{t('FQA.qna_privacy_popup.string10')}
								<br></br><br></br>{/* (2) 마케팅·광고에의 활용 */} {t('FQA.qna_privacy_popup.string11')}
								<br></br>{/* 광고 게재, 서비스의 유효성 확인, 이벤트 정보 및 참여기회 제공, 광고성 정보 제공, 접속빈도 파악의 서비스 이용에 대한 통계 */}
								{t('FQA.qna_privacy_popup.string12')}
							</p>
							<span className={styles.content_tit}>{/* 3. 개인정보의 보유 및 이용기간 */}{t('FQA.qna_privacy_popup.string13')}</span>
							<p>
								{/* 이용자의 개인정보는 원칙적으로 개인정보의 수집 및 이용목적이 달성되면 지체 없이 파기합니다. 단, 다음의 정보에 대해서는 아래의 이유로 명시한 기간 동안 보존합니다. */}
								{t('FQA.qna_privacy_popup.string14')}
								<br></br><br></br>{/* (1) 회사 내부 방침에 의한 정보 보유 사유 */}{t('FQA.qna_privacy_popup.string15')}
								<br></br><br></br>{/* * 부정이용기록(부정 가입, 징계 기록 등의 비정상적 서비스 이용 기록) 보존 항목: 이름, 이메일 */}
								{t('FQA.qna_privacy_popup.string16')}
								<br></br><br></br>{/* (2) 관련법령에 의한 정보 보유 사유 */}{t('FQA.qna_privacy_popup.string17')}
								<br></br>{/* 상법 등에서의 소비자보호에 관한 법률 등 관계법령의 규정에 의하여 보존할 필요가 있는 경우 회사는 관계법령에서 정한 일정한 기간 동안 회원정보를 보관합니다. 이 경우 회사는 보관하는 정보를 그 보관의 목적으로만 이용하며 보존기간은 아래와 같습니다. */}
								{t('FQA.qna_privacy_popup.string18')}
								<br></br><br></br>{/* * 웹사이트 방문 기록 보존 이유: 통신비밀보호법 보존 기간: 3개월 */}{t('FQA.qna_privacy_popup.string19')}
							</p>
							<span className={styles.content_tit}>{/* 4. 제3자 개인정보제공 */}{t('FQA.qna_privacy_popup.string20')}</span>
							<p>
								{/* [개인정보 제3자 제공] */}{t('FQA.qna_privacy_popup.string21')}
								<br></br><br></br>{/* 회사는 상담자의 개인정보를 개인정보의 수집 이용 목적에서 고지한 범위 내에서 사용하며, 상담자의 사전 동의 없이 동 범위를 초과하여 이용하거나 원칙적으로 상담자의 개인정보를 제 3자에게 제공하지 않습니다. 단, 아래의 경우에는 예외로 합니다. */}
								{t('FQA.qna_privacy_popup.string22')}
								<br></br><br></br>{/* ① 상담자가 사전에 공개 또는 제3자 제공에 동의한 경우 */}
								{t('FQA.qna_privacy_popup.string23')}
								<br></br><br></br>{/* ② 법령의 규정에 의거하나, 수사, 조사 목적으로 법령에 정해진 절차와 방법에 따라 수사기관 및 감독 당국의 요구가 있는 경우 */}
								{t('FQA.qna_privacy_popup.string24')}
								<br></br><br></br>{/* 그 밖에 상담자의 개인정보가 제3자 제공이 필요한 경우에는 합당한 절차를 통한 상담자의 동의를 얻어 제3자에게 개인정보를 제공할 수 있습니다. */}
								{t('FQA.qna_privacy_popup.string25')}
							</p>
						</div>
						<button type="button" className={styles.confirm} onClick={closeQnaPrivacy}>{/* 확인 */}{t('Common.confirm')}</button>
					</div>
				</div>
			</div>
		</div>

		<div className={styles.qna_top_line_banner}>
			<div className={styles.inner}>
				<span>{/* 야미 문의하기 */}{t('FQA.Qna.title1')}</span>
			</div>
		</div>

		<div className={styles.qna_tab}>
            <div className={styles.inner}>
				{
					authState.isLoggedIn ? "" : <button className={styles.confirm_no_member_qna} type="button" onClick={openQnaNoMember}>{/* 비회원 답변 확인하기 */}{t('FQA.Qna.button1')}</button>
				}
                <div className={styles.qna_area}>
					<span className={styles.qna_tit}>{/* 문의하기 */}{t('FQA.Qna.title2')}</span>
					<ul className={styles.qna_form}>
						{
							authState.isLoggedIn ? "" 
							: 
							<>
							<li className={styles.for_no_member}>
								<span>{/* 이름 */}{t('FQA.Qna.string2')}</span>
								<input type="text" id={styles.qna_form_name_input} name="name" onChange={(e)=>handleChange(e)} value={vName} />
							</li>
							<li className={styles.for_no_member}>
								<span>{/* 이메일 */}{t('FQA.Qna.string3')}</span>
								<input type="email" id={styles.qna_form_email_input} name="email" onChange={(e)=>handleChange(e)} value={vEmail} />
							</li>
							<li className={`${styles.for_no_member} ${styles.password}`}>
								<span>{/* 비밀번호 */}{t('FQA.Qna.string4')}</span>
								<div>
									<input type="password" id={styles.qna_form_password_input} name="password" onChange={(e)=>handleChange(e)} value={vPassword} />
									<p className={styles.info}>{/* * 비회원 답변 확인 시 사용하실 비밀번호를 입력해 주세요 */}{t('FQA.Qna.string5')}</p>
								</div>
							</li>
							</>
						}
						
						<li>
							<span>{/* 문의유형 */}{t('FQA.Qna.string6')}</span>
							<select name="qna_form_categori" id={styles.qna_form_categori_select} onChange={(e)=>handleChange(e)} value={vCategory} >
								<option value="" disabled>{/* 선택 */}{t('Common.Select')}</option>
								<option value="1">{/* 홈페이지 */}{t('FQA.category.cate3')}</option>
								<option value="2">{/* 학습(앱) */}{t('FQA.category.cate2')}</option>
								<option value="3">{/* 결제 */}{t('FQA.category.cate1')}</option>
								<option value="4">{/* 기타 */}{t('FQA.category.cate4')}</option>
							</select>						
						</li>
						<li>
							<span>{/* 제목 */}{t('Common.Title')}</span>
							<input type="text" id={styles.qna_form_title_input} name="title" onChange={(e)=>handleChange(e)} value={vTitle} />
						</li>
						<li className={styles.content}>
							<span>{/* 내용 */}{t('Common.Content')}</span>
							<textarea name="qna_form_content" id={styles.qna_form_content_textarea} onChange={(e)=>handleChange(e)} value={vQuestion} ></textarea>
						</li>
					</ul>
					<div className={styles.privacy_agree_area}>
						<input type="checkbox" id={styles.qna_agree_checkbox_input} name="agreeCheck" checked={isChecked} onChange={(e)=>handleChecked(e)}/>
						<span>{/* 개인정보처리방침 동의 */}{t('FQA.Qna.string7')} <span className={styles.popup}>{/* [약관 보기] */}{t('FQA.Qna.string8')}</span></span>
					</div>
					<button className={styles.qna_submit} type="button" onClick={(e) => handleSubmit(e)}>{/* 문의하기 */}{t('FQA.Qna.button2')}</button>
				</div>
            </div>
			<div className={styles.line}></div>
        </div>

		<div className={styles.faq_tab}>
			<div className={styles.inner}>
				<span className={styles.faq_tit}>{/* 자주 묻는 질문 */}{t('FQA.title')}</span>
				<p className={styles.faq_txt}>{/* 궁금한 질문을 찾아 클릭하면 답변을 확인할 수 있습니다 */}{t('FQA.string1')}</p>
				<div className={styles.faq_srch_area}>
					<select name="faq_srch_categori" id={styles.faq_srch_select} defaultValue={'DEFAULT'}>
						<option value="DEFAULT" disabled>{/* 선택 */}{t('Common.Select')}</option>
						<option value="1">{/* 제목 */}{t('Common.Title')}</option>
						<option value="2">{/* 내용 */}{t('Common.Content')}</option>
					</select>
					<input type="text" id={styles.faq_input_id}/>
					<button type="button" onClick={faqSearch}>{/* 검색 */}{t('Common.Search')}</button>
				</div>
				<div className={styles.faq_area}>
					<div className={styles.faq_categori_area}>
						<select name="faq_categori" id={styles.faq_categori_select} defaultValue="전체">
							<option value="" disabled>{/* 선택 */}{t('Common.Select')}</option>
							<option value="전체">{/* 전체 */}{t('Common.Total')}</option>
							<option value="결제">{/* 결제 */}{t('FQA.category.cate1')}</option>
							<option value="학습(앱)">{/* 학습(앱) */}{t('FQA.category.cate2')}</option>
							<option value="홈페이지">{/* 홈페이지 */}{t('FQA.category.cate3')}</option>
							<option value="기타">{/* 기타 */}{t('FQA.category.cate4')}</option>
						</select>
					</div>
					<ul className={styles.faq_list_wrap}></ul>
					{/* <ul className={styles.faq_list_pagebtn_area}>
						<li className={`${styles.arrow_btn} ${styles.first}`}><a href="#"><img src="/assets/first_btn@2x.png" alt="첫번째 페이지로 이동"/></a></li>
						<li className={`${styles.arrow_btn} ${styles.prev}`}><a href="#"><img src="/assets/prev_btn@2x.png" alt="이전 페이지로 이동"/></a></li>
						<li className={styles.on}><a href="#">1</a></li>
						<li><a href="#">2</a></li>
						<li><a href="#">3</a></li>
						<li><a href="#">4</a></li>
						<li><a href="#">5</a></li>
						<li><a href="#">6</a></li>
						<li><a href="#">7</a></li>
						<li><a href="#">8</a></li>
						<li><a href="#">9</a></li>
						<li><a href="#">10</a></li>
						<li className={`${styles.arrow_btn} ${styles.next}`}><a href="#"><img src="/assets/next_btn@2x.png" alt="다음 페이지로 이동"/></a></li>
						<li className={`${styles.arrow_btn} ${styles.last}`}><a href="#"><img src="/assets/last_btn@2x.png" alt="마지막 페이지로 이동"/></a></li>
					</ul> */}
				</div>
			</div>
		</div>
		
		{/* Footer 분리됨 MainLayout Footer에 있음 */}
    </>
  );
}