import http from "../http-common";

const solutionGetAll = data => {

  if (data.condition !== undefined && data.keyword !== undefined)
    return http.get(`/bbs/solution/${data.board_page}/${data.condition}/${data.keyword}`);
  else
    return http.get(`/bbs/solution/${data.board_page}`);
    
};

const solutionCreate = data => {
  return http.post("/bbs/solution/add", data);
};

const solutionModify = data => {
  return http.put("/bbs/solution/edit", data);
};

const solutionReply = data => {
  return http.put("/bbs/solution/reply", data);
};

const solutionDelete = data => {
  return http.delete("/bbs/solution/del", {data : data});
};

const solutionSelect = data => {
  return http.get(`/bbs/solution/select/${data}`);
};

const solutionNonMemberGetAll = data => {

  if (data.condition !== undefined && data.keyword !== undefined)
    return http.get(`/bbs/solutionNonMemberList/${data.name}/${data.email}/${data.board_page}/${data.condition}/${data.keyword}`);
  else
    return http.get(`/bbs/solutionNonMemberList/${data.name}/${data.email}/${data.board_page}`);
    
};

const solutionNonMemberCreate = data => {
  return http.post("/bbs/solutionNonMember/add", data);
};

const solutionNonMemberModify = data => {
  return http.put("/bbs/solutionNonMember/edit", data);
};

const solutionNonMemberDelete = data => {
  return http.delete("/bbs/solutionNonMember/del", {data : data});
};

const solutionNonMemberSelect = data => {
  return http.get(`/bbs/solutionNonMemberSelect/${data}`);
};

const solutionCategory = () => {
  return http.get(`/bbs/solutionCategory`);
};

const solutionNonMemberCheck = data => {
  return http.post("/bbs/solutionNonMemberCheck", data);
};

const SoultionService = {
  solutionGetAll,
  solutionCreate,
  solutionModify,
  solutionReply,
  solutionDelete,
  solutionSelect,
  solutionNonMemberGetAll,
  solutionNonMemberCreate,
  solutionNonMemberModify,
  solutionNonMemberDelete,
  solutionNonMemberSelect,
  solutionCategory,
  solutionNonMemberCheck,
};

export default SoultionService;
