import React from 'react';
import { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { logout } from "../../actions/auth"
import styles from "../../css/common.module.css";
import $ from 'jquery';

import { useGoogleLogout } from 'react-google-login';

import Language from '../../common/Language';

import { useTranslation } from 'react-i18next'; // 1. react-i18next import 

const Header = () => {

	// const clientId = '42504825298-eavp5n14pprd0prdghkbc89f36l7k1r0.apps.googleusercontent.com';
	let clientId = `${process.env.REACT_APP_CLIENT_ID}`;

    const authState = useSelector(state => state.auth);
    // console.log("authState : ", authState);

	const dispatch = useDispatch();	

	const { t, i18n } = useTranslation(); // 3. useTranslation hook 선언

    // 마이페이지 클릭시 팝업
	const my_coverRef = useRef();
	const my_pageMy_menuRef = useRef();

	// 상단 메뉴 클릭시
	const menuRef = useRef();

	// 태블릿,모바일 메뉴 버튼 클릭시 메뉴 팝업
	const mobile_menu_btn_area_show_m_menuRef = useRef();
	const mobile_menu_btn_area_backRef = useRef();
	const mobile_menu_areaRef = useRef();

	// 로그인
	const my_pageRef = useRef();
	const memberRef = useRef();
	const login_wrapRef = useRef();
	const my_wrapRef = useRef();

	useEffect(() => {

		// let lang = document.getElementsByTagName('html')[0].getAttribute('lang');
		// console.log("lang : ", lang);

		// const elementToChangeLang = document.documentElement
		// const newLang = 'en';
		// elementToChangeLang.setAttribute('lang', newLang);
		// elementToChangeLang.setAttribute('lang', localStorage.getItem("language"));

		// 언어 설정이 없다면 언어를 설정합니다.
		if (localStorage.getItem("language") === null)
		{
			localStorage.setItem("language", "ko");
			i18n.changeLanguage("ko");    // 언어 설정 변경
		}

		// 여기에서 jQuery를 초기화 합니다.
		// jquery ready 호출. jquery3 부터 변경됨
		$(function(){

			// console.log("jquery ready!");

			// [현재 접속된 url 정보 및 접속 브라우저 확인]
			// [카카오톡 인앱 브라우저 >> 안드로이드 모바일 기종 인 경우 >> 크롬 브라우저 이동 실시]	var Url = location.href;
			let Agent = navigator.userAgent.toLowerCase();
			// console.log("");            
			// console.log("[window ready] : [접속 Url] : " + Url);
			// console.log("[window ready] : [접속 Agent] : " + Agent);
			// console.log("");
			//alert(Agent);
			if(Agent.includes("kakao"))// 카카오 브라우저로 실행 시킨 경우
			{ 
				// 먼저, 카카오 인앱 브라우저 닫기
				window.location.href = 'kakaotalk://inappbrowser/close';
				if(navigator.userAgent.match(/iPhone|iPad/i))// 아이폰 접속 경우
				{ 
					// console.log("");
					// console.log("[window ready] : [접속 모바일] : " + "[아이폰]");
					// console.log("");
				}
				else  // 안드로이드 접속 경우
				{
					// console.log("");
					// console.log("[window ready] : [접속 모바일] : " + "[안드로이드]");
					// console.log("");
					// 크롬으로 새창 열기
					window.location.href='intent://'+window.location.href.replace(/https?:\/\//i,'')+'#Intent;scheme=http;package=com.android.chrome;end';
				}
			}	
			//출처 https://kkh0977.tistory.com/1188
			
			//헤더의 메뉴 색 변경
			let headerUrl=window.location.href;
			// console.log("headerUrl : ", headerUrl);
			
			if(headerUrl.includes('Pay')===true)
			{
				// console.log('구매하기');
				$(`.${styles.header} .${styles.menu_list}`).removeClass(`${styles.on}`);
				$(`.${styles.header} .${styles.menu_list}`).eq(2).addClass(`${styles.on}`);
			}
			else if(headerUrl.includes('Download')===true)
			{
				// console.log('다운로드');
				$(`.${styles.header} .${styles.menu_list}`).removeClass(`${styles.on}`);
				$(`.${styles.header} .${styles.menu_list}`).eq(3).addClass(`${styles.on}`);
			}
			else if(headerUrl.includes('News')===true)
			{
				// console.log('새소식');
				$(`.${styles.header} .${styles.menu_list}`).removeClass(`${styles.on}`);
				$(`.${styles.header} .${styles.menu_list}`).eq(4).addClass(`${styles.on}`);
			}
			else if(headerUrl.includes('NewsContent')===true)
			{
				// console.log('새소식');
				$(`.${styles.header} .${styles.menu_list}`).removeClass(`${styles.on}`);
				$(`.${styles.header} .${styles.menu_list}`).eq(4).addClass(`${styles.on}`);
			}
			else if(headerUrl.includes('Qna')===true)
			{
				// console.log('문의하기');
				$(`.${styles.header} .${styles.menu_list}`).removeClass(`${styles.on}`);
				// $(`.${styles.header} .${styles.menu_list}:last-child`).addClass(`${styles.on}`);
				$(`.${styles.header} .${styles.menu_list}`).eq(5).addClass(`${styles.on}`);
			}
			else if(headerUrl.includes('NonMemberQnaInfo')===true)
			{
				// console.log('문의하기');
				$(`.${styles.header} .${styles.menu_list}`).removeClass(`${styles.on}`);
				// $(`.${styles.header} .${styles.menu_list}:last-child`).addClass(`${styles.on}`);
				$(`.${styles.header} .${styles.menu_list}`).eq(5).addClass(`${styles.on}`);
			}
			else if(headerUrl.includes('NonMemberQnaInfoMore')===true)
			{
				// console.log('문의하기');
				$(`.${styles.header} .${styles.menu_list}`).removeClass(`${styles.on}`);
				// $(`.${styles.header} .${styles.menu_list}:last-child`).addClass(`${styles.on}`);
				$(`.${styles.header} .${styles.menu_list}`).eq(5).addClass(`${styles.on}`);
			}
			else if(headerUrl.includes('/')===true && headerUrl.includes('#main')===false)
			{
				// console.log('야미코딩');
				$(`.${styles.header} .${styles.menu_list}`).removeClass(`${styles.on}`);
				$(`.${styles.header} .${styles.menu_list}`).eq(0).addClass(`${styles.on}`);
			}
			else if(headerUrl.includes('/')===true && headerUrl.includes('#main')===true)
			{
				// console.log('코딩수업');
				$(`.${styles.header} .${styles.menu_list}`).removeClass(`${styles.on}`);
				$(`.${styles.header} .${styles.menu_list}`).eq(1).addClass(`${styles.on}`);
			}
			else
			{
				// console.log('그 외의 페이지 입니다');
				$(`.${styles.header} .${styles.menu_list}`).removeClass(`${styles.on}`);
			};
			
			//야미코딩->코딩수업 이동시 메뉴 색 변경
			$(`.${styles.header} .${styles.menu} a`).eq(1).on('click',function()
			{
				$(`.${styles.header} .${styles.menu_list}`).removeClass(`${styles.on}`);
				$(`.${styles.header} .${styles.menu_list}`).eq(1).addClass(`${styles.on}`);
			});

			//마이페이지 클릭시 팝업
			$(my_coverRef.current).on('click',function()
			{	
				$(my_pageMy_menuRef.current).toggle();
			});

			$('html').on("click", function(e)
			{ 
				
				if(!$(my_coverRef.current).has(e.target).length)
				{
					$(my_pageRef.current).find(`.`+`${styles.my_menu}`).css('display','none');
				}

				if(!$(`.${styles.mobile_menu_btn_area}`).has(e.target).length)
				{
					mobileMenuClose();
				};
				
			});

			//메뉴 클릭
			$(menuRef.current).children('li').on('click',function()
			{
				// 하위요소의 css에서 on을 모두 제거한다.
				$(menuRef.current).find('li').children('a').css({"color":""});

				// 현재 선택한 요소만 css에서 on을 넣어준다.
				let tg=$(this);
				tg.children('a').css({"color":"#f15a24"});
			});
			
			//태블릿,모바일 메뉴 버튼 클릭시 메뉴 팝업으로 뜸
			$(mobile_menu_btn_area_show_m_menuRef.current).on('click',function()
			{
				$(mobile_menu_areaRef.current).animate({left:'0'},300);
			});
			
			//메뉴 닫힘
			$(mobile_menu_btn_area_backRef.current).on('click',function()
			{
				mobileMenuClose();
			});
			
			//헴버거 메뉴에서 메뉴 클릭시 햄버거 메뉴 닫힘
			$(`.${styles.header} .${styles.bottom_menu_area} a`).on('click',function()
			{
				mobileMenuClose();
			});
			
			function mobileMenuClose()
			{
				$(mobile_menu_areaRef.current).animate({left:'-100%'},300);
			}

		});

		// - 모바일
		// 로그인시 : 574번줄 display:none, 606번줄 display:block
		// 로그아웃시 : 574번줄 display:block, 606번줄 display:none

		// - pc
		// 로그인시 : 368번줄 visibility:hidden, 403번줄 display:block
		// 로그아웃시 : 368번줄 visibility:visible, 403번줄 display:none

		if (authState.isLoggedIn)
		{
			$(my_pageRef.current).css("display", "block");
			$(memberRef.current).css("visibility", "hidden");

			$(login_wrapRef.current).css("display", "none");
			$(my_wrapRef.current).css("display", "block");
		}
		else
		{
			$(my_pageRef.current).css("display", "none");
			$(memberRef.current).css("visibility", "visible");

			$(login_wrapRef.current).css("display", "block");
			$(my_wrapRef.current).css("display", "none");
		}

		// cleanup 함수
		return () => {
			
		}

	}, [authState.isLoggedIn]);

	const logOut = () => 
	{
		signOut();

		$(my_pageRef.current).css("display", "none");
		$(memberRef.current).css("visibility", "visible");

		dispatch(logout());

		window.location.reload();
	};

	const onLogoutSuccess = (res) => {
		console.log('Logged out Success');
		// alert('Logged out Successfully ✌');
		dispatch(logout());
		window.location.reload();
	};
	
	const onFailure = () => {
		console.log('Handle failure cases');
	};

	const { signOut } = useGoogleLogout({
		clientId,
		onLogoutSuccess,
		onFailure,
	});

	const returnMypage = () => {
		
		if (authState.isLoggedIn)
		{
			if (authState.user.type === 1)
			{
				return <><li className={`${styles.page} ${styles.p1}`}><a href={`/dashboard/NoticeList/notice`}>{/*마이페이지*/}{t('Common.mypage')}</a></li></>;
			}
			else if (authState.user.type === 4)
			{
				return <><li className={`${styles.page} ${styles.p1}`}><a href={`/GroupUserMypage/GroupReport`}>{/*마이페이지*/}{t('Common.mypage')}</a></li></>;
			}
			else
			{
				// return <><li className={`${styles.page} ${styles.p1}`}><a href={`/dashboard/NoticeListClient/notice`}>마이페이지</a></li></>;
				return <><li className={`${styles.page} ${styles.p1}`}><a href={`/UserMypage/Report`}>{/*마이페이지*/}{t('Common.mypage')}</a></li></>;
			}
		}
		else
		{
			return "";
		}
	};

	const returnMypageMobile = () => {
		
		if (authState.isLoggedIn)
		{
			if (authState.user.type === 1)
				return <><a href={`/dashboard/NoticeList/notice`}>{/*마이페이지*/}{t('Common.mypage')}</a></>;
			else if (authState.user.type === 4)
				return <><a href={`/GroupUserMypage/GroupReport`}>{/*마이페이지*/}{t('Common.mypage')}</a></>;
			else
				// return <><a href={`/dashboard/NoticeListClient/notice`}>마이페이지 ></a></>;
				return <><a href={`/UserMypage/Report`}>{/*마이페이지*/}{t('Common.mypage')}</a></>;
		}
		else
		{
			return "";
		}
	};

	const returnPayLink = () => {
		
		if (authState.isLoggedIn)
		{
			if (authState.user.type === 4)
				return <><li className={styles.menu_list}><a href="/GroupPay">{/*구매하기*/}{t('Main.topMenu.menu3')}</a></li></>;
			else
				// return <><a href={`/dashboard/NoticeListClient/notice`}>마이페이지 ></a></>;
				return <><li className={styles.menu_list}><a href="/Pay">{/*구매하기*/}{t('Main.topMenu.menu3')}</a></li></>;
		}
		else
		{
			return <><li className={styles.menu_list}><a href="/Pay">{/*구매하기*/}{t('Main.topMenu.menu3')}</a></li></>;
		}
	};

	const returnPayLinkMobile = () => {
		
		if (authState.isLoggedIn)
		{
			if (authState.user.type === 4)
				return <><a href="/GroupPay">{/*구매하기*/}{t('Main.topMenu.menu3')}</a></>;
			else
				// return <><a href={`/dashboard/NoticeListClient/notice`}>마이페이지 ></a></>;
				return <><a href="/Pay">{/*구매하기*/}{t('Main.topMenu.menu3')}</a></>;
		}
		else
		{
			return <><a href="/Pay">{/*구매하기*/}{t('Main.topMenu.menu3')}</a></>;
		}
	};

	// const userImgArray = ["/img/button1_on.png", "/img/button2_on.png", "/img/button3_on.png", "/img/button4_on.png", "/img/button5_on.png"];

	// const rendomUserImg = () => {
	// 	let sPick = Math.floor(Math.random() * userImgArray.length);
	// 	console.log("sPick : ", userImgArray[sPick]);
	// 	return userImgArray[sPick];
	// }

    return (
		<>
		<div className={styles.header}>
			<div className={styles.inner}>
				<div className={styles.mobile_menu_btn_area}>
					<img src="/assets/menu_btn.png" alt="메뉴" className={styles.show_m_menu} ref={mobile_menu_btn_area_show_m_menuRef}/>
					<div className={styles.mobile_menu_area} ref={mobile_menu_areaRef}>
						<div className={styles.mobile_menu_wrap}>
							<div className={styles.mobile_menu_top_area}>
								<a href="/"><img src="/assets/logo@2x.png" alt="logo"/></a>
								<img src="/assets/back_icon.png" alt="back" className={styles.back} ref={mobile_menu_btn_area_backRef}/>
							</div>
							<div className={styles.mobile_my_area}>
								<div className={styles.login_wrap} ref={login_wrapRef}>
									<div>
										<a href="/auth/login" className={styles.login}>{/*로그인*/}{t('Common.login')}</a>
										<a href="/auth/RegisterAgree" className={styles.join}>{/*회원가입*/}{t('Common.register')}</a>
									</div>
								</div>
								<div className={styles.my_wrap} ref={my_wrapRef}>
									<div className={styles.top_area}>
										<div className={styles.left_area}>
											<div className={styles.mobile_profile}>
												<img src="/assets/my_profile@2x.png" alt="프로필"/>
											</div>
										</div>
										<div className={styles.right_area}>
											<div className={styles.name}>
												<p>{authState.isLoggedIn ? authState.user.name : ""}</p>
												<span>님</span>
											</div>
										</div>
									</div>
									<div className={styles.bottom_area}>
										{returnMypageMobile()}
										<a href="#!" onClick={logOut}>{/*로그아웃*/}{t('Common.logout')}</a>
									</div>
								</div>
							</div>
							<div className={styles.bottom_menu_area}>
								<a href="/">{/*야미코딩*/}{t('Main.topMenu.menu1')}</a>
								<a href="/#main_coding_block">{/*코딩수업*/}{t('Main.topMenu.menu2')}</a>
								{returnPayLinkMobile()}
								<a href="/Download">{/*다운로드*/}{t('Main.topMenu.menu4')}</a>
								<a href="/News">{/*새소식*/}{t('Main.topMenu.menu5')}</a>
								<a href="/Qna">{/*문의하기*/}{t('Main.topMenu.menu6')}</a>
								<a href="/sub">English</a>
							</div>
							<div className={styles.bottom_menu_area}>
								<div className={styles.language}>
									<Language />
								</div>
							</div>
						</div>
					</div>
				</div>	{/* mobile_menu_btn_area */}
				<a className={styles.logo} href="/">
					<img src="/assets/logo@2x.png" alt="logo"/>
				</a>
				<div className={styles.menu_wrapper}>
					<ul className={`${styles.menu} ${styles.cf}`}>
						<li className={styles.menu_list}><a href="/">{/*야미코딩*/}{t('Main.topMenu.menu1')}</a></li>
						<li className={styles.menu_list}><a href="/#main_coding_block">{/*코딩수업*/}{t('Main.topMenu.menu2')}</a></li>
						{returnPayLink()}
						<li className={styles.menu_list}><a href="/Download">{/*다운로드*/}{t('Main.topMenu.menu4')}</a></li>
						<li className={styles.menu_list}><a href="/News">{/*새소식*/}{t('Main.topMenu.menu5')}</a></li>
						<li className={styles.menu_list}><a href="/Qna">{/*문의하기*/}{t('Main.topMenu.menu6')}</a></li>
						<li className={styles.menu_list}><a href="/sub">English</a></li>
					</ul>
				</div>
				<div className={`${styles.button} ${styles.cf}`}>
					<div className={styles.member} ref={memberRef}>
						<a href="/auth/login" className={styles.login}>{/*로그인*/}{t('Common.login')}</a>
						<a href="/auth/RegisterAgree" className={styles.join}>{/*회원가입*/}{t('Common.register')}</a>
					</div>
					<div id={styles.my_page} ref={my_pageRef}>
						<div className={styles.my_cover} ref={my_coverRef}>
							<span className={styles.my_nikname}>{authState.isLoggedIn ? authState.user.name : ""}</span>
							<div className={styles.my}><img src="/assets/my_profile@2x.png" alt="my"/></div>
						</div>
						<div className={styles.my_menu} ref={my_pageMy_menuRef}>
							<ul className={styles.cf}>
								<li>
									<div><img src="/assets/my_profile@2x.png" alt="my"/></div>
									<p>{authState.isLoggedIn ? authState.user.name : ""}</p>
								</li>
								{returnMypage()}
								<li className={`${styles.page} ${styles.p2}`}><a href="#!" onClick={logOut}>{/*로그아웃*/}{t('Common.logout')}</a></li>
							</ul>
						</div>
					</div>
					<div id={styles.my_page2}>
						<Language />
					</div>
				</div>
			</div>
			
		</div>	{/* mobile_menu_btn_area */}
		</>
    );
    
};

export default Header;