import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import { Link, Grid } from '@mui/material';

import { useDispatch, useSelector } from "react-redux";
import { knowledgeSelect, knowledgeDelete } from "../../../actions/knowledge";
import { knowledgeCategoryList } from "../../../actions/knowledge_category";

import { useEffect, useState } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import * as common from "../../../lib";

export default function ProductModify() {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    
    const idx  = useParams().idx;
    console.log("idx : ", idx);

    const category = useSelector(state => state.knowledge_category);
    console.log("category : ", category);

    const initialKnowledgeState = {
        ki_idx: null,  
        ki_title: null,
        kc_category_idx: null,
        ki_question: null, 
        ki_answer: null,
        ki_discription: null,
        items: null,
    };

    const [knowledge, setKnowledge] = useState(initialKnowledgeState);

    const handleDeleteProduct = (event, idx) => {
        console.log(idx);
  
        if (window.confirm("정말 삭제하시겠습니까?\n삭제하시면 그 문제를 푼 사용자 정보 또한 모두 삭제됩니다.") === true)   //확인
        {
          // 여기서 디스패치로~
          dispatch(knowledgeDelete(idx))
          .then(data => 
          {
              console.log("data : ", data);
              if (!data.success)
                  alert(data.msg);
  
              navigate("/dashboard/ProductList");
          })
          .catch(e => {
              console.log(e);
          });
        }
        else  //취소
        {
          return false;
        }
  
      };

    const returnCategory = (idx) => {
      for (let i=0; i<category.length; i++)
      {
        if (idx === category[i].kc_category_idx)
        {
          return category[i].kc_category_name;
        }
      }
    };

    const returnItem = (items) => {
      
      return (
        <>
          {items.map((item, index) => (
            <span key={index}>
              {index+1}. {item.ki_question_item} <br></br>
            </span>
          ))}
        </>
      );
    };

    useEffect(() => {
        
        console.log("dispatch productSelect");
        dispatch(knowledgeSelect(idx))
        .then(data => 
        {
            console.log("data : ", data[0]);
            setKnowledge({
              ki_idx: data[0].ki_idx,  
              ki_title: data[0].ki_title,
              kc_category_idx: data[0].kc_category_idx,
              ki_question: data[0].ki_question, 
              ki_answer: data[0].ki_answer,
              ki_discription: data[0].ki_discription,
              items: data[0].items,
            });
        })
        .catch(e => {
            console.log(e);
        });

        console.log("dispatch knowledgeCategoryList");
        // 여기서 디스패치로~
        dispatch(knowledgeCategoryList())
        .then(data => 
        {
            console.log("data : ", data);
        })
        .catch(e => {
            console.log(e);
        });

        return () => {      // 언마운트
            console.log("언마운트!");
        };

    }, [dispatch])

  return (
    <Card sx={{ minWidth: 275, maxWidth: 500 }}>
      <CardContent>
        <Grid container spacing={2}>
            <Grid item xs={4} md={3}>
            제목 : 
            </Grid>
            <Grid item xs={6} md={8}>
            {knowledge.ki_title || ""}
            </Grid>
            <Grid item xs={4} md={3}>
            질문 : 
            </Grid>
            <Grid item xs={6} md={8}>
            {knowledge.ki_question || ""}
            </Grid>
            <Grid item xs={4} md={3}>
            카테고리 : 
            </Grid>
            <Grid item xs={6} md={8}>
            { knowledge.kc_category_idx ? returnCategory(knowledge.kc_category_idx) : "" }
            </Grid>
            <Grid item xs={4} md={3}>
            정답 : 
            </Grid>
            <Grid item xs={6} md={8}>
            {knowledge.ki_answer || ""}
            </Grid>
            <Grid item xs={4} md={3}>
            정답 설명 : 
            </Grid>
            <Grid item xs={6} md={8}>
            {knowledge.ki_discription || ""}
            </Grid>
            <Grid item xs={4} md={3}>
            객관식 항목
            </Grid>
            <Grid item xs={6} md={8}>
            { knowledge.items ? returnItem(knowledge.items) : ""} 
            </Grid>
        </Grid>
      </CardContent>
      <CardActions>
        <Link href={`/dashboard/KnowledgeList`} underline="none">목록</Link>
        <Link href={`/dashboard/KnowledgeModify/${idx}`} underline="none">수정</Link>
        <Link onClick={(e)=>handleDeleteProduct(e, idx)} value={idx} underline="none">삭제</Link>
      </CardActions>
    </Card>
  );
}