import * as React from 'react';
import { useGoogleLogout } from 'react-google-login';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';

import Link from '@mui/material/Link';

import LeftMenu from './LeftMenu';
import LeftMenuAdmin from './LeftMenuAdmin';
import { Outlet } from 'react-router-dom';

import { useSelector, useDispatch } from "react-redux";
import { logout } from "../../actions/auth";

import { useNavigate } from 'react-router-dom';

const drawerWidth = 240;

const settings = ['사용자 정보', '패스워드 변경', '로그아웃'];

// const clientId =
//   '42504825298-eavp5n14pprd0prdghkbc89f36l7k1r0.apps.googleusercontent.com';

let clientId = `${process.env.REACT_APP_CLIENT_ID}`;

function ResponsiveDrawer(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleClickUserMenu = (event) => {

    switch (event.target.innerText) 
    {
      case "사용자 정보":
        console.log(event.target.innerText);
        navigate("/dashboard/profile");
        break;

      case "패스워드 변경":
        console.log(event.target.innerText);
        navigate("/dashboard/UserPasswordChange");
        break;

      case "로그아웃":
        console.log(event.target.innerText);
        logOut();
        break;

      default:
        break;
    }

    handleCloseUserMenu();
  }

  const auth = useSelector(state => state.auth);
  // console.log("auth : ", auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const logOut = () => 
  {
    if (auth.user.type === 3)  
    {
      signOut();
    }
    else
    {
      dispatch(logout());
    }
  }

  const onLogoutSuccess = (res) => {
    console.log('Logged out Success');
    alert('Logged out Successfully ✌');
    dispatch(logout());
  };

  const onFailure = () => {
    console.log('Handle failure cases');
  };

  const { signOut } = useGoogleLogout({
    clientId,
    onLogoutSuccess,
    onFailure,
  });

  const drawer = (
    <div>
      <Toolbar sx={{ml : "30px"}}><a href='/'><img src="/img/logo.png" width={'120px'}/></a></Toolbar>
      <Divider />
      { auth.user.type === 1 ? <LeftMenuAdmin /> : <LeftMenu /> }
    </div>
  );

  const userImgArray = ["/img/button1_on.png", "/img/button2_on.png", "/img/button3_on.png", "/img/button4_on.png", "/img/button5_on.png"];

  const rendomUserImg = () => {
    let sPick = Math.floor(Math.random() * userImgArray.length);
    // console.log("sPick : ", userImgArray[sPick]);
    return userImgArray[sPick];
  }

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            
          </Box>

          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ flexGrow: 1, display: { xs: 'block', sm: 'none' }, width:'0%', marginLeft:5}}
          >
            <Link href="/" color="inherit" underline="none"><img src="/img/logo.png" width={'90px'}/></Link>
          </Typography>

          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ flexGrow: 1, display: { xs: 'flex', md: 'block' } }}
          >
            
          </Typography>
          
          <Box sx={{ flexGrow: 0 }}>
            {auth.user.name} &nbsp;
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar alt="Remy Sharp" src={rendomUserImg()} />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem key={setting} onClick={handleClickUserMenu}>
                  <Typography textAlign="center">{setting}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>

        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
      >
        <Toolbar />

        <Outlet />
        
      </Box>
    </Box>
  );
}

ResponsiveDrawer.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default ResponsiveDrawer;