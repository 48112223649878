import * as React from 'react';
import { useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import styles from "../css/common.module.css";
import $ from 'jquery';
import * as common from "../lib";

import { solutionNonMemberSelect } from "../actions/solution";
import { useNavigate, useParams } from 'react-router-dom';

import { useTranslation, Trans } from 'react-i18next'; // 1. react-i18next import 

export default function NonMemberQnaInfoMore() {

	const authState = useSelector(state => state.auth);
    // console.log("authState : ", authState);
	const solutionState = useSelector(state => state.solution.select);
    // console.log("solutionState : ", solutionState);

	const paramIdx  = useParams().idx;
	const paramName  = useParams().name;
	const paramEmail  = useParams().email;

	const dispatch = useDispatch();	

	const { t, i18n } = useTranslation(); // 3. useTranslation hook 선언

	useEffect(() => {

		// 여기에서 jQuery를 초기화 합니다.
		$(document).ready(function(){

			// console.log("jquery ready!");

		});

		// 여기서 디스패치로~
		dispatch(solutionNonMemberSelect(paramIdx))
		.then(data => 
		{
			// console.log("data : ", data);
		})
		.catch(e => {
			console.log(e);
		});

	}, [$]);

  return (
    <>
      	{/* Header 분리됨 MainLayout Header에 있음 */}

          <div className={styles.mypage_top_line_banner}>
			<div className={styles.inner}>
				<span>{/* 비회원 문의내역 */}{t('FQA.NonQna.title1')}</span>
			</div>
		</div>
		<div className={`${styles.mypage_tab} ${styles.mypage_pay_info_more} ${styles.qna_info_more}`}>
			<div className={styles.inner}>
				<span className={styles.my_tit}>{/* 문의 상세정보 */}{t('FQA.NonQna.title3')}</span>
				<div className={`
									${styles.pay_info_more_area} 
									${solutionState?.answer === null ? `` : `${styles.complete}`}
								`}>
					 <ul>
                        <li></li>
                        <li></li>
						<li>
							<span className={styles.head}>{/* 문의날짜 */}{t('FQA.NonQna.string5')}</span>
							<p>{/*2022.9.30 - 11시 59분*/}{solutionState?.reg_date}</p>
						</li>
						<li>
                            <span className={styles.head}>{/* 이름 */}{t('FQA.NonQna.string4')}</span>
							<p>{solutionState?.name}</p>
						</li>
						<li>
                            <span className={styles.head}>{/* 이메일 */}{t('FQA.NonQna.string11')}</span>
							<p>{solutionState?.email}</p>
						</li>
						<li className={styles.answer_state}>
                            <span className={styles.head}>{/* 답변상태 */}{t('FQA.NonQna.string6')}</span>
							<p>
								{ 
									// solutionState?.answer === null ? "답변대기" : "답변완료" 
									solutionState?.answer === null ? t('FQA.NonQna.string8') : t('FQA.NonQna.string9') 
								}
							</p>
						</li>
                        <li>
                            <span className={styles.head}>{/* 문의유형 */}{t('FQA.NonQna.string12')}</span>
                            <p>{common.returnSolutionType(solutionState?.sc_category_idx)}</p>
                        </li>
                        <li>
                            <span className={styles.head}>{/* 제목 */}{t('FQA.NonQna.string3')}</span>
                            <p>{solutionState?.title}</p>
                        </li>
                        <li className={styles.question}>
                            <span className={styles.head}>{/* 문의내용 */}{t('FQA.NonQna.string13')}</span>
                            <p>{solutionState?.question}</p>
                        </li>
                        <li className={`${styles.answer} ${styles.answer_list}`}>
                            <span className={styles.head}>{/* 답변 */}{t('FQA.NonQna.string14')}</span>
                            <p>{solutionState?.answer || "-"}</p>
                        </li>
						<li className={styles.answer_list}>
                            <span className={styles.head}>{/* 답변날짜 */}{t('FQA.NonQna.string15')}</span>
                            <p>{/*2022.10.1 - 11시 59분*/}{solutionState?.answer_reg_date || "-"}</p>
                        </li>
					 </ul>
					<a href={`/NonMemberQnaInfo/${paramName}/${paramEmail}`}>{/* 확인 */}{t('Common.confirm')}</a>
				</div>
			</div>{/* //inner */}
		</div>{/* //mypage_tab */}
		
		{/* Footer 분리됨 MainLayout Footer에 있음 */}
    </>
  );
}