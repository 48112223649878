import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { Button } from '@mui/material';

import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';

import StudentEditDialog from './StudentEditDialog';
import StudentInfoDialog from './StudentInfoDialog';
import StudentLicenseChangeDialog from './StudentLicenseChangeDialog';
import StudentLicenseChangeAlertDialog from './StudentLicenseChangeAlertDialog';

import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";

import { newClassStudentList } from "../../actions/student";
import { userStudentUse } from "../../actions/users";

import * as common from "../../lib";

import { useTranslation, Trans } from 'react-i18next'; // 1. react-i18next import 

export default function StudentDataTable(props) 
{

  const dispatch = useDispatch();	

  const { t, i18n } = useTranslation(); // 3. useTranslation hook 선언

  const student_list = useSelector(state => state.student?.newListList);
  // console.log("student_list : ", student_list);
  const student_paging = useSelector(state => state.student?.newListPaging);
  // console.log("student_paging : ", student_paging);

  const [boardPage, setBoardPage] = useState(1);

  // 게시물의 번호를 계산하는 함수
  const listNumberReturn = (paging, index) => {
    let listNumber;
    return listNumber = ((paging.totalPageCount - index) - ((paging.curPage -1) * paging.page_size));
  }

  const studentStatusChange = (e, data) => {

    // console.log(data);
    // 상태를 변경합니다.

    let sendData = {
      mst_idx: data.mst_idx
    };
  
    // console.log("sendData : ", sendData);
  
    // 여기서 디스패치로~
    dispatch(userStudentUse(sendData))
    .then(data => 
    {
      // console.log("data : ", data);
      if (!data.success)
      {
        alert(data.msg);
        return;
      }
      else
      {
        const boardListSend = {
          board_page : boardPage,
          mc_idx: props.mcIdx,
          }
        
          // 여기서 디스패치로~
          dispatch(newClassStudentList(boardListSend))
          .then(data => 
          {
            // console.log("classListAll : ", data);
          })
          .catch(e => {
            console.log(e);
          });
      }
    })
    .catch(e => {
      console.log(e);
    });

  }

  let colorValue = `error`;

  const onPaging = (e, page) => {
    
    if (!page) return;
    // console.log("page : ", page);

    setBoardPage(page);
    
    const boardListSend = {
      board_page : page,
      mc_idx: props.mcIdx,
    }

    // 여기서 디스패치로~
    dispatch(newClassStudentList(boardListSend))
    .then(data => 
    {
        // console.log("classListAll : ", data);
    })
    .catch(e => {
        console.log(e);
    });
  }

  const onValidCheck = (state, date) => {

    let date1 = new Date(); // 오늘 날짜
    let date2 = new Date(date); // 비교 날짜

    if (state !== "F")
      return date1 > date2 ? <font style={{ color: 'red', fontSize: '12px' }}>({t('ClassStudentCommonDlg.string43')})</font> : "";
    else
      return <></>;
  }

  useEffect(() => {

    const boardListSend = {
      board_page : boardPage,
      mc_idx: props.mcIdx,
    }

		// 클래스의 리스트 불러오기
    dispatch(newClassStudentList(boardListSend))
    .then(data => 
    {
      // console.log("classListAll data : ", data);
    })
    .catch(e => {
        console.log(e);
    });

	}, []);

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table" size="small">
          <TableHead>
            <TableRow>
              <TableCell align="center">{/* 번호 */}{t('ClassStudentCommonDlg.string18')}</TableCell>
              <TableCell align="center">{/* 이름 */}{t('ClassStudentCommonDlg.string19')}</TableCell>
              <TableCell align="center">{/* 아이디 */}{t('ClassStudentCommonDlg.string1')}</TableCell>
              <TableCell align="center">{/* 기간 */}{t('ClassStudentCommonDlg.string26')}</TableCell>
              <TableCell align="center">{/* 이용권 */}{t('ClassStudentCommonDlg.string27')}</TableCell>
              <TableCell align="center">{/* 사용 */}{t('ClassStudentCommonDlg.string28')}</TableCell>
              <TableCell align="center">{/* 정보 */}{t('ClassStudentCommonDlg.string30')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          {
              student_list?.length !== 0 ? student_list && student_list
              .map((row, index) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                    <TableCell component="th" scope="row" align="center">
                      {listNumberReturn(student_paging, index)}
                    </TableCell>
                    <TableCell align="center"><StudentInfoDialog StudentRow={row} mstIdx={row.mst_idx} /></TableCell>
                    <TableCell align="center">
                      {row.mst_id}
                      {
                        // row.mm_state_cd === "F" ? `(무료 사용자)` :
                        // {
                        //   PG : row.mm_state === "A" && row.mm_state_cd === "A" ? `(유료결제 적용)` : `(사용중지)`,
                        //   COUPON : row.mm_state === "A" && row.mm_state_cd === "A" ? `(쿠폰 적용)` : `(사용중지)`,
                        //   INAPP : row.mm_state === "A" && row.mm_state_cd === "A" ? `(인앱 적용)` : `(사용중지)`,
                        //   Manual : row.mm_state === "A" && row.mm_state_cd === "A" ? `(단체상품 적용)` : `(사용중지)`,
                        // } [row.mm_type]

                        row.mm_state_cd === "F" ?  t('ClassStudentCommonDlg.string31') :
                        {
                          PG : row.mm_state === "A" && row.mm_state_cd === "A" ?  t('ClassStudentCommonDlg.string32') :  t('ClassStudentCommonDlg.string36'),
                          COUPON : row.mm_state === "A" && row.mm_state_cd === "A" ?  t('ClassStudentCommonDlg.string33') :  t('ClassStudentCommonDlg.string36'),
                          INAPP : row.mm_state === "A" && row.mm_state_cd === "A" ?  t('ClassStudentCommonDlg.string34') :  t('ClassStudentCommonDlg.string36'),
                          Manual : row.mm_state === "A" && row.mm_state_cd === "A" ?  t('ClassStudentCommonDlg.string35') :  t('ClassStudentCommonDlg.string36'),
                        } [row.mm_type]
                      }
                    </TableCell>
                    {/* <TableCell align="center">{row.mm_state_cd === "F" ? `무제한` : `${common.dateChangeReturn(row.mm_valid_end_date)} 까지`}</TableCell> */}
                    <TableCell align="center">{row.mm_state_cd === "F" ? t('ClassStudentCommonDlg.string37') : t('ClassStudentCommonDlg.string38', {date : common.dateChangeReturn(row.mm_valid_end_date)})} {onValidCheck(row.mm_state_cd, row.mm_valid_end_date)}</TableCell>
                    <TableCell align="center">
                      {
                        row.mm_state_cd === "F" ? 
                        <StudentLicenseChangeDialog myPackageList={props.myPackageList} RowData={row} currentPage={boardPage} mcIdx={props.mcIdx} />
                        : 
                        <StudentLicenseChangeAlertDialog myPackageList={props.myPackageList} RowData={row} currentPage={boardPage} mcIdx={props.mcIdx} /> 
                      }
                    </TableCell>
                    <TableCell align="center">
                      <Button variant="outlined" size="small" 
                      sx={{ color: row.mst_status === 1 ? '#ef5350' : '#1976d2', borderColor: row.mst_status === 1 ? '#ef5350' : '#1976d2' }}
                      onClick={(e)=>{studentStatusChange(e, row)}}>
                        {/* {row.mst_status === 1 ? '정지' : '사용'} */}
                        {row.mst_status === 1 ? t('ClassStudentCommonDlg.string29') : t('ClassStudentCommonDlg.string28')}
                      </Button>
                    </TableCell>
                    <TableCell align="center"><StudentEditDialog RowData={row} currentPage={boardPage} mcIdx={props.mcIdx} /></TableCell>
                  </TableRow>
                );
              })
              :
              <TableRow>
                <TableCell component="th" scope="row" align="center" colSpan="7">{/* 데이터 없음 */}{t('Common.No_data')}</TableCell>
              </TableRow>
            }
          </TableBody>
        </Table>
      </TableContainer>
      {
        student_list?.length !== 0 ? 
          <Stack spacing={2} sx={{ height: 60, display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
            <Pagination count={student_paging?.totalPage || 0} page={student_paging?.curPage || 0} onChange={onPaging} color="primary" />
          </Stack>
        :
        <></>
      }
      
    </Paper>
  );
}