import {
  CREATE_INAPP_PRODUCT,
  LIST_INAPP_PRODUCT,
  DELETE_INAPP_PRODUCT,
  MODIFY_INAPP_PRODUCT,
  SELECT_INAPP_PRODUCT,
} from "./types";

import InappProductService from "../services/InappProductService";

export const inappProductCreate = (data) => async (dispatch) => {
  
  try {
    
    const res = await InappProductService.inappProductCreate(data);

    // console.log("res : ", res);

    dispatch({
      type: CREATE_INAPP_PRODUCT,
      payload: res.data,
    });

    return Promise.resolve(res.data);

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};

export const inappProductList = () => async (dispatch) => {
  
  try {
    
    const res = await InappProductService.inappProductGetAll();

    // console.log("res : ", res);

    dispatch({
      type: LIST_INAPP_PRODUCT,
      payload: res.data.data,
    });

    return Promise.resolve(res.data.data);

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};

export const inappProductSelect = (prd_idx) => async (dispatch) => {
  
  try {
    
    const res = await InappProductService.inappProductSelect(prd_idx);

    // console.log("res : ", res);

    dispatch({
      type: SELECT_INAPP_PRODUCT,
      payload: res.data.data,
    });

    return Promise.resolve(res.data.data);

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};

export const inappProductDelete = (prd_idx) => async (dispatch) => {
  
  try {

    // console.log("productDelete! prd_idx : ", prd_idx);

    const res = await InappProductService.inappProductDelete(prd_idx);

    // console.log("res : ", res);

    if (res.data.success === false)
    {
      alert(res.data.msg);
      return Promise.resolve(prd_idx);
    }
    else
    {
      dispatch({
        type: DELETE_INAPP_PRODUCT,
        payload: { prd_idx },
      });
  
      return Promise.resolve(res.data);
    }

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};

export const inappProductModify = (data) => async (dispatch) => {
  
  try {
    
    const res = await InappProductService.inappProductModify(data);

    // console.log("res : ", res);

    dispatch({
      type: MODIFY_INAPP_PRODUCT,
      payload: res.data,
    });

    return Promise.resolve(res.data);

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};
