import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';

import Box from '@mui/material/Box';
import Input from '@mui/material/Input';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import UserTypeDlg from './UserTypeDlg';

import { useEffect, useState } from 'react';
import { useDispatch } from "react-redux";

import { categoryModify, categorySelect } from "../../../actions/category";

import { useNavigate, useParams } from 'react-router-dom';

const ariaLabel = { 'aria-label': 'description' };

export default function CategoryModify() {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const category_idx  = useParams().category_idx;
    console.log("category_idx : ", category_idx);

    const initialCategoryState = {
        category_name: null, 
        category_desc: null,
        category_users: null,
    };

    const [category, setCategory] = useState(initialCategoryState);
    const [chipData, setChipData] = useState(null);

    const handleInputChange = event => {
        const { name, value } = event.target;
        setCategory({ ...category, [name]: value });
    };

    const handleSubmit = (event) => {

        event.preventDefault();

        const { category_name, category_desc, category_users } = category;

        let userTypeArray = [0, 0, 0, 0];

        for (let i=0; i<category_users.length; i++)
        {
            if (category_users[i].label === "관리자")
                userTypeArray[0] = 1;
            else if (category_users[i].label === "일반")
                userTypeArray[1] = 1;
            else if (category_users[i].label === "소셜")
                userTypeArray[2] = 1;
            else if (category_users[i].label === "단체")
                userTypeArray[3] = 1;
        }

        console.log("userTypeArray : ", userTypeArray);

        let sendUserType = userTypeArray[0] + "-" + userTypeArray[1] + "-" + userTypeArray[2] + "-" + userTypeArray[3];
        console.log(sendUserType);

        let sendData = {
            category_idx: category_idx,
            category_name: category_name,
            category_desc: category_desc,
            category_users: sendUserType,
        }

        // 여기서 디스패치로~
        dispatch(categoryModify(sendData))
        .then(data => 
        {
            console.log("data : ", data);
            if (!data.success)
            {
                alert(data.msg);
                return;
            }
            
            navigate("/dashboard/categoryList");
        })
        .catch(e => {
            console.log(e);
        });
        
    };

    useEffect(() => {
        
        console.log("dispatch categorySelect");
        dispatch(categorySelect(category_idx))
        .then(data => 
        {
            console.log("data : ", data[0]);

            let splitString = data[0].category_permission.split("-");
            let userTypeArray = [{ key: 0, label: '사용자 유형' }];

            for (let i=0; i<splitString.length; i++)
            {
                
                if (splitString[i] === "1")
                {
                    let setString = "";

                    if (i === 0)
                        setString = "관리자";
                    else if (i === 1)
                        setString = "일반";
                    else if (i === 2)
                        setString = "단체";
                    else if (i === 3)
                        setString = "소셜";

                    userTypeArray.push({ key: i+1, label: setString });
                }
            }

            let setData = {
                category_name: data[0].category_name, 
                category_desc: data[0].category_desc, 
                category_users: null,
            };
            console.log("setData : ", setData);

            setCategory(setData);
            setChipData(userTypeArray);
        })
        .catch(e => {
            console.log(e);
        });

        return () => {      // 언마운트
            console.log("언마운트!");
        };

    }, [dispatch])

  return (
    <Card sx={{ minWidth: 275 }}>
      <CardContent>
        <Box
        sx={{
            '& > :not(style)': { m: 1, width: '400px' },
        }}
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit}
        >
        카테고리명 : 
            <Input 
                placeholder="카테고리 이름" 
                id="category_name" 
                name="category_name" 
                inputProps={ariaLabel} 
                onChange={handleInputChange} 
                value={category.category_name || ''} 
            />
        </Box>

        <Box
        sx={{
            '& > :not(style)': { m: 1, width: '400px' },
        }}
        noValidate
        autoComplete="off"
        >
        <Box>카테고리 설명 </Box>
        <TextareaAutosize
            aria-label="minimum height"
            minRows={6}
            placeholder="카테고리 설명을 달아주세요."
            style={{ width: 500 }}
            onChange={handleInputChange}
            id="category_desc" 
            name="category_desc"
            value={category.category_desc || ''}
        />
        </Box>
        <Box>
            <UserTypeDlg category={category} setCategory={setCategory} chipData={chipData} setChipData={setChipData} />
        </Box>
      </CardContent>
      <CardActions>
        <Button 
            onClick={handleSubmit} 
            size="small" 
            variant="contained"
            sx={{marginLeft:2}}
        >상품수정</Button>
        <Button 
            href={`/dashboard/CategoryList`} 
            size="small" 
            variant="contained"
            sx={{marginLeft:2}}
        >목록</Button>
      </CardActions>
    </Card>
  );
}