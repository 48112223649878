import {
  CREATE_NOTICE_BBS,
  LIST_NOTICE_BBS,
  DELETE_NOTICE_BBS,
  MODIFY_NOTICE_BBS,
  SELECT_NOTICE_BBS,
} from "./types";

import BBSNoticeService from "../services/BBSNoticeService";

export const bbsNoticeCreate = (data) => async (dispatch) => {
  
  try {
    
    const res = await BBSNoticeService.bbsNoticeCreate(data);

    // console.log("res : ", res);

    dispatch({
      type: CREATE_NOTICE_BBS,
      payload: res.data,
    });

    return Promise.resolve(res.data);

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};

export const bbsNoticeList = (data) => async (dispatch) => {
  
  try {
    
    const res = await BBSNoticeService.bbsNoticeGetAll(data);

    // console.log("res : ", res);

    dispatch({
      type: LIST_NOTICE_BBS,
      payload: res.data.data,
    });

    return Promise.resolve(res.data.data);

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};

export const bbsNoticeSelect = (bbs_name, bbs_idx) => async (dispatch) => {
  
  try {
    
    const res = await BBSNoticeService.bbsNoticeSelect(bbs_name, bbs_idx);

    // console.log("res : ", res);

    dispatch({
      type: SELECT_NOTICE_BBS,
      payload: res.data.data,
    });

    return Promise.resolve(res.data.data);

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};

export const bbsNoticeDelete = (data) => async (dispatch) => {
  
  try {

    const res = await BBSNoticeService.bbsNoticeDelete(data);

    // console.log("res : ", res);

    dispatch({
      type: DELETE_NOTICE_BBS,
      payload: res.data,
    });

    return Promise.resolve(res.data);

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};

export const bbsNoticeModify = (data) => async (dispatch) => {
  
  try {
    
    const res = await BBSNoticeService.bbsNoticeModify(data);

    // console.log("res : ", res);

    dispatch({
      type: MODIFY_NOTICE_BBS,
      payload: res.data,
    });

    return Promise.resolve(res.data);

  } catch (err) {
    // console.log("Error: ", err);
    return Promise.reject(err.response);
  }

};