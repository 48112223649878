import {
  CREATE_NOTICE_BBS,
  LIST_NOTICE_BBS,
  DELETE_NOTICE_BBS,
  MODIFY_NOTICE_BBS,
  SELECT_NOTICE_BBS,
} from "../actions/types";

const initialState = [];

const bbsNoticeReducer = (notice = initialState, action) => {
  const { type, payload } = action;

  // console.log("bbsNoticeReducer Call!");
  // console.log("type : ", type);
  // console.log("payload : ", payload);

  switch (type) {
    
    case CREATE_NOTICE_BBS:
      return [];

    case LIST_NOTICE_BBS:
      return payload;

    case SELECT_NOTICE_BBS:
      return payload;

    case MODIFY_NOTICE_BBS:
      return [];

    case DELETE_NOTICE_BBS:
      return [];

    default:
      return notice;
  }
};

export default bbsNoticeReducer;