import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import { Link, Grid } from '@mui/material';

import { useDispatch } from "react-redux";
import { paymentSelect, paymentCancel } from "../../../actions/payment";

import { useEffect, useState } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import * as common from "../../../lib";

import jQuery from 'jquery';

export default function PaymentView() {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    
    const pay_idx  = useParams().pay_idx;
    console.log("pay_idx : ", pay_idx);

    const initialPaymentState = {
      payment_idx: null,
      payment_no: null,
      payment_opt_cd: null,
      payment_amt: null,
      payment_date: null,
      payment_state: null,
      order_idx: null,
      order_no: null,
      order_count: null,
      order_amt: null,
      prduct_name: null,
      prduct_idx: null,
      member_idx: null,
      member_name: null,
    };

    const [payment, setPayment] = useState(initialPaymentState);

    const handleCancelOrder = (event, idx) => {
      console.log(idx);

      if (window.confirm("정말 취소하시겠습니까??") === true)   //확인
      {
        // 여기서 디스패치로~
        dispatch(paymentCancel(idx))
        .then(data => 
        {
            console.log("data : ", data);
            if (!data.success)
                alert(data.msg);

            navigate("/dashboard/OrderList");
        })
        .catch(e => {
            console.log(e);
        });
      }
      else  //취소
      {
        return false;
      }

    };

    const handleImgError = (e) => {
      e.target.src = "https://cdn-icons-png.flaticon.com/512/1474/1474713.png";
    };

    useEffect(() => {
        
        console.log("dispatch paymentSelect");
        dispatch(paymentSelect(pay_idx))
        .then(data => 
        {
            console.log("data : ", data[0]);
            setPayment({
              payment_idx: data[0].pay_idx,
              payment_no: data[0].pay_no,
              payment_opt_cd: data[0].pay_opt_cd,
              payment_amt: data[0].pay_amt,
              payment_date: data[0].pay_date,
              payment_state: data[0].pay_state,
              order_idx: data[0].ord_idx,
              order_no: data[0].ord_no,
              order_count: data[0].ord_count,
              order_amt: data[0].ord_amt,
              prduct_name: data[0].prd_name,
              prduct_idx: data[0].prd_idx,
              member_idx: data[0].mem_idx,
              member_name: data[0].mem_name,
            });
        })
        .catch(e => {
            console.log(e);
        });

    }, [dispatch])

  return (
    <Card sx={{ minWidth: 275, maxWidth: 500 }}>
      <CardContent>
        <Grid container spacing={2}>
            <Grid item xs={4} md={3}>
            결제번호 : 
            </Grid>
            <Grid item xs={6} md={8}>
            {payment.payment_no || ""}
            </Grid>
            <Grid item xs={4} md={3}>
            결제수단 : 
            </Grid>
            <Grid item xs={6} md={8}>
            {payment.payment_opt_cd || ""}
            </Grid>
            <Grid item xs={4} md={3}>
            결제상품 : 
            </Grid>
            <Grid item xs={6} md={8}>
            {payment.prduct_name || ""}
            </Grid>
            <Grid item xs={4} md={3}>
            결제금액 : 
            </Grid>
            <Grid item xs={6} md={8}>
            {payment.payment_amt || ""}
            </Grid>
            <Grid item xs={4} md={3}>
            결제일 : 
            </Grid>
            <Grid item xs={6} md={8}>
            {payment.payment_date || ""}
            </Grid>
            <Grid item xs={4} md={3}>
            결제상태
            </Grid>
            <Grid item xs={6} md={8}>
            {payment.payment_state ? common.returnPaymentState(payment.payment_state) : "" }
            </Grid>
            <Grid item xs={4} md={3}>
            결제자
            </Grid>
            <Grid item xs={6} md={8}>
            {payment.member_name || ""}
            </Grid>
            
        </Grid>
      </CardContent>
      <CardActions>
        <Link href={`/dashboard/PaymentList`} underline="none">목록</Link>
        {/* <Link onClick={(e)=>handleCancelOrder(e, ord_idx)} underline="none">결제하기</Link>
        <Link onClick={(e)=>handlePayment(e, ord_idx)} value={ord_idx} underline="none">주문 취소</Link> */}
      </CardActions>
    </Card>
  );
}