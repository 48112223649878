import {
  CREATE_YAME_NOTICE_BBS,
  LIST_YAME_NOTICE_BBS,
  DELETE_YAME_NOTICE_BBS,
  MODIFY_YAME_NOTICE_BBS,
  SELECT_YAME_NOTICE_BBS,
  OPEN_YAME_NOTICE_BBS,
} from "./types";

import BBSYameNoticeService from "../services/BBSYameNoticeService";

export const bbsNoticeCreate = (data) => async (dispatch) => {
  
  try {
    
    const res = await BBSYameNoticeService.bbsNoticeCreate(data);

    // console.log("res : ", res);

    dispatch({
      type: CREATE_YAME_NOTICE_BBS,
      payload: res.data,
    });

    return Promise.resolve(res.data);

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};

export const bbsNoticeList = (data) => async (dispatch) => {
  
  try {
    
    const res = await BBSYameNoticeService.bbsNoticeGetAll(data);

    // console.log("res : ", res);

    dispatch({
      type: LIST_YAME_NOTICE_BBS,
      payload: res.data.data,
    });

    return Promise.resolve(res.data.data);

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};

export const bbsNoticeSelect = (bbs_name, bbs_idx) => async (dispatch) => {
  
  try {
    
    const res = await BBSYameNoticeService.bbsNoticeSelect(bbs_name, bbs_idx);

    // console.log("res : ", res);

    dispatch({
      type: SELECT_YAME_NOTICE_BBS,
      payload: res.data.data,
    });

    return Promise.resolve(res.data.data);

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};

export const bbsNoticeDelete = (data) => async (dispatch) => {
  
  try {

    const res = await BBSYameNoticeService.bbsNoticeDelete(data);

    // console.log("res : ", res);

    dispatch({
      type: DELETE_YAME_NOTICE_BBS,
      payload: res.data,
    });

    return Promise.resolve(res.data);

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};

export const bbsNoticeOpen = (data) => async (dispatch) => {
  
  try {

    const res = await BBSYameNoticeService.bbsNoticeOpen(data);

    // console.log("res : ", res);

    dispatch({
      type: OPEN_YAME_NOTICE_BBS,
      payload: res.data,
    });

    return Promise.resolve(res.data);

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};

export const bbsNoticeModify = (data) => async (dispatch) => {
  
  try {
    
    const res = await BBSYameNoticeService.bbsNoticeModify(data);

    // console.log("res : ", res);

    dispatch({
      type: MODIFY_YAME_NOTICE_BBS,
      payload: res.data,
    });

    return Promise.resolve(res.data);

  } catch (err) {
    // console.log("Error: ", err);
    return Promise.reject(err.response);
  }

};