import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';

import styles from "../../css/common.module.css";

import ClassDataTable2 from './ClassDataTable2';

import ClassChart1 from './ClassChart1';
import ClassChart2 from './ClassChart2';
import ClassChart3 from './ClassChart3';
import ClassChart4 from './ClassChart4';
import ClassChart5 from './ClassChart5';
import StudentChart1 from './StudentChart1';

import ClassStudentChart2 from './ClassStudentChart2';

import { useDispatch, useSelector } from "react-redux";
import $ from 'jquery';
import {useReactToPrint} from 'react-to-print';

import { userKnowledgeAnswer } from "../../actions/users";
import { chartClassMissionTotal, chartClassMissionProgress, chartClassHumanAbility } from "../../actions/chart";

import { useTranslation, Trans } from 'react-i18next'; // 1. react-i18next import 

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function FullScreenDialog(props) {
  const [open, setOpen] = React.useState(false);

  const [mstIdx, setMstIdx] = useState(0);
	const [startDate, setStartDate] = useState(0);
	const [lastDate, setLastDate] = useState(0);
	const [abilityObject, setAbilityObject] = useState(null);

	const dispatch = useDispatch();	

  const { t, i18n } = useTranslation(); // 3. useTranslation hook 선언

	const missiontotal = useSelector(state => state.chart.chartClassMissionTotal);
    // console.log("missiontotal : ", missiontotal);

	const missionprogress = useSelector(state => state.chart.chartClassMissionProgress);
    // console.log("missionprogress : ", missionprogress);

	const knowledgeanswer = useSelector(state => state.users.knowledgeanswer);
    // console.log("knowledgeanswer : ", knowledgeanswer);

	const ability = useSelector(state => state.chart.chartClassHumanAbility);
    // console.log("ability : ", ability);

	const my_report_cognitive_ability_areaRef = useRef();

	const print_areaRef = useRef();

  const handleClickOpen = () => {

        // 여기서 디스패치로~
        dispatch(chartClassMissionTotal(props.mstIdx))
        .then(data => 
        {
            // console.log("chartClassMissionTotal data : ", data);
        })
        .catch(e => {
            console.log(e);
        });

        // 여기서 디스패치로~
        dispatch(chartClassMissionProgress(props.mstIdx))
        .then(data => 
        {
            // console.log("chartClassMissionProgress data : ", data);
        })
        .catch(e => {
            console.log(e);
        });

        // 여기서 디스패치로~
        dispatch(userKnowledgeAnswer(props.mstIdx))
        .then(data => 
        {
            // console.log("userKnowledgeAnswer data : ", data);
        })
        .catch(e => {
            console.log(e);
        });

        // 여기서 디스패치로~
        // dispatch(userTotalHumanAbility())
        // .then(data => 
        // {
        //     // console.log("userTotalHumanAbility data : ", data);
        // })
        // .catch(e => {
        //     console.log(e);
        // });

        dispatch(chartClassHumanAbility(props.mstIdx))
        .then(data => 
        {
            // console.log("userHumanAbility data : ", data.data);

            // 서버에서 완전한 데이터 였을 때의 값을 미리 만듭니다.
            let abilityArray = [{mii_indicators_type:'Lo', cnt:0},{mii_indicators_type:'Cr', cnt:0},{mii_indicators_type:'Im', cnt:0},{mii_indicators_type:'En', cnt:0},{mii_indicators_type:'Ma', cnt:0},{mii_indicators_type:'Sc', cnt:0}];
        
            for (let i=0; i<abilityArray.length; i++)
            {
              for (let j=0; j<data.data.length; j++)
              {
                if (abilityArray[i].mii_indicators_type === data.data[j].mii_indicators_type)
                  abilityArray[i].cnt = data.data[j].cnt;
              }
            }

            setAbilityObject(abilityArray);
        })
        .catch(e => {
            console.log(e);
        });
        

    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handlePrint = useReactToPrint
	({
		content: () => print_areaRef.current,
	});

  return (
    <div>
      <Button size="small" onClick={handleClickOpen}>
        {props.StudentRow.mst_name}
      </Button>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {/* 학습자 정보 */}{t('MypageReport.string28')}
            </Typography>
            <Button autoFocus color="inherit" onClick={handleClose}>
              {/* 닫기 */}{t('Common.close')}
            </Button>
          </Toolbar>
        </AppBar>
        <List>

            <div className={`${styles.mypage_tab} ${styles.mypage_report}`} ref={print_areaRef}>
              <div className={styles.inner}>
                <div className={styles.my_report_top_btn_area}>
                  {/* <select name="report_student_list" id={styles.report_student_list_select} defaultValue={'DEFAULT'} onChange={changeHandler}>
                    <option value="DEFAULT" disabled>선택</option>
                    {student?.map((row, index) =>
                      <option value={row.mst_idx} key={index}>{row.mst_name}({row.ord_stat_cd === 'A' ? '유료' : '무료'})</option>
                    )}
                    {/* <option value="1">우주최강김코딩(무료)</option>
                    <option value="2">우주최강김코딩(유료)</option> */}
                  {/*</select> */}
                  <button type="button" onClick={handlePrint}>{/* 인쇄하기 */}{t('Common.printing')}<img src="/assets/my_report_print@2x.png" alt="인쇄하기"/></button>
                </div>
                <span className={styles.my_tit}>{/* 학습 리포트 */}{t('MypageReport.title')}</span>
                <p className={styles.for_freeuser_info}>{/* * 무료체험은 데이터가 없어 학습 리포트의 지표가 0으로 표시됩니다.  */}{t('MypageReport.string1')}</p>
                <div className={styles.my_report_missonprogress_area}>
                  
                  <div className={styles.head}>
                    <span>{/* 야미코딩 각 구분별 미션 진행 */}{t('MypageReport.string2')}</span>
                    <p>{ startDate === 0 && lastDate === 0 ? "" : `${startDate || ""}  ~  ${lastDate || ""}` }</p>
                  </div>
                  
                  <div className={styles.wrap}>
                    <div className={styles.misson_progress}>
                      <div className={styles.misson_left}>
                        <img src="/assets/my_report_progress1@2x.png" alt="코딩블록 배우기"/>
                        <div className={styles.progress_wrap}>
                          <span className={styles.now}>{missionprogress?.data !== null ? missionprogress?.data[0]?.cnt || 0 : 0}</span>
                          <span>/{missiontotal?.data[0]?.cnt || 0}</span>
                        </div>
                      </div>
                      <div className={styles.misson_txt}>
                        <span className={styles.misson_name}>{/* 코딩블록 배우기 */}{t('MypageReport.string3')}</span>
                        <p>{/* 처음 사용자를 위해 코딩블록의 기본 적인 기능을 배웁니다. */}{t('MypageReport.string4')}</p>
                      </div>
                    </div>
                    <div className={styles.misson_progress}>
                      <div className={styles.misson_left}>
                        <img src="/assets/my_report_progress2@2x.png" alt="순차 수업"/>
                        <div className={styles.progress_wrap}>
                          <span className={styles.now}>{missionprogress?.data !== null ? missionprogress?.data[1]?.cnt || 0 : 0}</span>
                          <span>/{missiontotal?.data[1]?.cnt || 0}</span>
                        </div>
                      </div>
                      <div className={styles.misson_txt}>
                        <span className={styles.misson_name}>{/* 순차 수업 */}{t('MypageReport.string5')}</span>
                        <p>{/* 명령문을 하나씩 수행하는 과정의 미션들입니다. */}{t('MypageReport.string6')}</p>
                      </div>
                    </div>
                    <div className={styles.misson_progress}>
                      <div className={styles.misson_left}>
                        <img src="/assets/my_report_progress3@2x.png" alt="반복 수업"/>
                        <div className={styles.progress_wrap}>
                          <span className={styles.now}>{missionprogress?.data !== null ? missionprogress?.data[2]?.cnt || 0 : 0}</span>
                          <span>/{missiontotal?.data[2]?.cnt || 0}</span>
                        </div>
                      </div>
                      <div className={styles.misson_txt}>
                        <span className={styles.misson_name}>{/* 반복 수업 */}{t('MypageReport.string7')}</span>
                        <p>{/* 특정 횟수만큼 반복하거나, 조건이 만족할 때까지 반복 하는 과정의 미션들입니다. */}{t('MypageReport.string8')}</p>
                      </div>
                    </div>
                    <div className={styles.misson_progress}>
                      <div className={styles.misson_left}>
                        <img src="/assets/my_report_progress4@2x.png" alt="선택 수업"/>
                        <div className={styles.progress_wrap}>
                          <span className={styles.now}>{missionprogress?.data !== null ? missionprogress?.data[3]?.cnt || 0 : 0}</span>
                          <span>/{missiontotal?.data[3]?.cnt || 0}</span>
                        </div>
                      </div>
                      <div className={styles.misson_txt}>
                        <span className={styles.misson_name}>{/* 선택 수업 */}{t('MypageReport.string9')}</span>
                        <p>{/* 조건에 따라 명령문을 선택적으로 수행하는 과정의 미션들입니다. */}{t('MypageReport.string10')}</p>
                      </div>
                    </div>
                    <div className={styles.misson_progress}>
                      <div className={styles.misson_left}>
                        <img src="/assets/my_report_progress5@2x.png" alt="특별 미션"/>
                        <div className={styles.progress_wrap}>
                          <span className={styles.now}>{missionprogress?.data !== null ? missionprogress?.data[4]?.cnt || 0 : 0}</span>
                          <span>/{missiontotal?.data[4]?.cnt || 0}</span>
                        </div>
                      </div>
                      <div className={styles.misson_txt}>
                        <span className={styles.misson_name}>{/* 특별 미션 */}{t('MypageReport.string11')}</span>
                        <p>{/* 얼음, 바람, 물리엔진 적용 등 특수한 기능과 순차, 반복, 선택의 혼합 형태로 난이도가 높은 미션들입니다. */}{t('MypageReport.string12')}</p>
                      </div>
                    </div>
                  </div>

                  <div className={styles.wrap}>
                    <div className={styles.misson_progress}>
                      <div className={styles.misson_left}>
                        <img src="/assets/my_report_progress1@2x.png" alt="코딩블록 배우기"/>
                        <div className={styles.progress_wrap}>
                          <span className={styles.now}>{missionprogress?.data !== null ? missionprogress?.data[5]?.cnt || 0 : 0}</span>
                          <span>/{missiontotal?.data[5]?.cnt || 0}</span>
                        </div>
                      </div>
                      <div className={styles.misson_txt}>
                        <span className={styles.misson_name}>{/* 파트1 수업 */}{t('MypageReport.string29')}</span>
                        <p>{/* 파트1 수업은 인공지능 미션 수업입니다. */}{t('MypageReport.string30')}</p>
                      </div>
                    </div>
                    <div className={styles.misson_progress}>
                      <div className={styles.misson_left}>
                        <img src="/assets/my_report_progress2@2x.png" alt="순차 수업"/>
                        <div className={styles.progress_wrap}>
                          <span className={styles.now}>{missionprogress?.data !== null ? missionprogress?.data[6]?.cnt || 0 : 0}</span>
                          <span>/{missiontotal?.data[6]?.cnt || 0}</span>
                        </div>
                      </div>
                      <div className={styles.misson_txt}>
                        <span className={styles.misson_name}>{/* 파트2 수업 */}{t('MypageReport.string31')}</span>
                        <p>{/* 파트2 수업은 인공지능 미션 수업입니다. */}{t('MypageReport.string32')}</p>
                      </div>
                    </div>
                    <div className={styles.misson_progress}>
                      <div className={styles.misson_left}>
                        <img src="/assets/my_report_progress3@2x.png" alt="반복 수업"/>
                        <div className={styles.progress_wrap}>
                          <span className={styles.now}>{missionprogress?.data !== null ? missionprogress?.data[7]?.cnt || 0 : 0}</span>
                          <span>/{missiontotal?.data[7]?.cnt || 0}</span>
                        </div>
                      </div>
                      <div className={styles.misson_txt}>
                        <span className={styles.misson_name}>{/* 파트3 수업 */}{t('MypageReport.string33')}</span>
                        <p>{/* 파트3 수업은 인공지능 미션 수업입니다. */}{t('MypageReport.string34')}</p>
                      </div>
                    </div>
                    <div className={styles.misson_progress}>
                      <div className={styles.misson_left}>
                        <img src="/assets/my_report_progress4@2x.png" alt="선택 수업"/>
                        <div className={styles.progress_wrap}>
                          <span className={styles.now}>{missionprogress?.data !== null ? missionprogress?.data[8]?.cnt || 0 : 0}</span>
                          <span>/{missiontotal?.data[8]?.cnt || 0}</span>
                        </div>
                      </div>
                      <div className={styles.misson_txt}>
                        <span className={styles.misson_name}>{/* 파트4 수업 */}{t('MypageReport.string35')}</span>
                        <p>{/* 파트4 수업은 인공지능 미션 수업입니다. */}{t('MypageReport.string36')}</p>
                      </div>
                    </div>
                    <div className={styles.misson_progress}>
                      <div className={styles.misson_left}>
                        <img src="/assets/my_report_progress5@2x.png" alt="특별 미션"/>
                        <div className={styles.progress_wrap}>
                          <span className={styles.now}>{missionprogress?.data !== null ? missionprogress?.data[9]?.cnt || 0 : 0}</span>
                          <span>/{missiontotal?.data[9]?.cnt || 0}</span>
                        </div>
                      </div>
                      <div className={styles.misson_txt}>
                        <span className={styles.misson_name}>{/* 파트5 수업 */}{t('MypageReport.string37')}</span>
                        <p>{/* 파트5 수업은 인공지능 미션 수업입니다. */}{t('MypageReport.string38')}</p>
                      </div>
                    </div>
                  </div>

                </div>
                <div className={styles.my_report_study_time_area}>
                  <div className={styles.head}>
                    <span>{/* 학습 시간 */}{t('MypageReport.string13')}</span>
                    {/* <p>2022.05</p> */}
                  </div>
                  <div className={styles.wrap}>
                    <ul>
                      {/* <li>
                          <span className={styles.name}>학습일</span>
                        <span>0</span>
                      </li>
                      <li>
                        <span className={styles.name}>학습시간</span>
                        <span>0</span>
                      </li> */}
                      <li>
                        <span className={styles.name}>{/* 지식은행 문제 풀이 수 */}{t('MypageReport.string14')}</span>
                        <span>{knowledgeanswer?.data !== null ? knowledgeanswer?.data[0]?.cnt || 0 : 0}</span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className={styles.my_report_cognitive_ability_area} ref={my_report_cognitive_ability_areaRef}>
                  <div className={styles.head}>
                    <span>{/* 인지 능력 향상 */}{t('MypageReport.string15')}</span>
                  </div>
                  <div className={styles.wrap}>
                    <div className={styles.ability_area}>
                      <div className={styles.cognitive_ability}>
                        <div className={styles.left_area}>
                          <img src="/assets/my_report_recognition1@2x.png" alt="논리력 향상"/>
                        </div>
                        <div className={styles.right_area}>
                          <div className={styles.top_area}>
                            <span className={styles.name}>{/* 논리력 향상 */}{t('MypageReport.string16')}</span>
                            <div className={styles.graph_area}>
                              <div className={styles.graph_wrap}>
                                <div className={styles.graph}></div>
                              </div>
                              <span className={styles.ability_percent}>
                              {abilityObject && abilityObject.find(word => word.mii_indicators_type === 'Lo').cnt || 0} 
                              {/*returnAbility(ability?.data !== null ? ability?.data[3]?.cnt || 0 : 0, totalability?.data[3].cnt || 0, 0)*/}
                              </span>
                            </div>
                          </div>
                          <p>{/* 상황을 논리적으로 판단해서 문제를 해결합니다. */}{t('MypageReport.string17')}</p>
                        </div>
                      </div>
                      <div className={styles.cognitive_ability}>
                        <div className={styles.left_area}>
                          <img src="/assets/my_report_recognition2@2x.png" alt="창의력 향상" className={styles.creative_icon} />
                        </div>
                        <div className={styles.right_area}>
                          <div className={styles.top_area}>
                            <span className={styles.name}>{/* 창의력 향상 */}{t('MypageReport.string18')}</span>
                            <div className={styles.graph_area}>
                              <div className={styles.graph_wrap}>
                                <div className={styles.graph}></div>
                              </div>
                              <span className={styles.ability_percent}>
                              {abilityObject && abilityObject.find(word => word.mii_indicators_type === 'Cr').cnt || 0} 
                                {/*returnAbility(ability?.data !== null ? ability?.data[0]?.cnt || 0 : 0, totalability?.data[0].cnt || 0, 1)*/}
                              </span>
                            </div>
                          </div>
                          <p>{/* 평범한 방법이 아닌 창의적인 방법으로 문제를 해결합니다. */}{t('MypageReport.string19')}</p>
                        </div>
                      </div>
                      <div className={styles.cognitive_ability}>
                        <div className={styles.left_area}>
                          <img src="/assets/my_report_recognition3@2x.png" alt="상상력 향상"/>
                        </div>
                        <div className={styles.right_area}>
                          <div className={styles.top_area}>
                            <span className={styles.name}>{/* 상상력 향상 */}{t('MypageReport.string20')}</span>
                            <div className={styles.graph_area}>
                              <div className={styles.graph_wrap}>
                                <div className={styles.graph}></div>
                              </div>
                              <span className={styles.ability_percent}>
                                {abilityObject && abilityObject.find(word => word.mii_indicators_type === 'Im').cnt || 0} 
                                {/*ability?.data !== null ? ability?.data[2]?.cnt || 0 : 0*/}
                                {/*returnAbility(ability?.data !== null ? ability?.data[2]?.cnt || 0 : 0, totalability?.data[2].cnt || 0, 2)*/}
                              </span>
                            </div>
                          </div>
                          <p>{/* 주어진 문제를 상상력으로 요점을 파악해 문제를 해결합니다. */}{t('MypageReport.string21')}</p>
                        </div>
                      </div>
                    </div>
                    <div className={styles.ability_area}>
                      <div className={styles.cognitive_ability}>
                        <div className={styles.left_area}>
                          <img src="/assets/my_report_science@2x.png" alt="과학"/>
                        </div>
                        <div className={styles.right_area}>
                          <div className={styles.top_area}>
                            <span className={styles.name}>{/* 과학 */}{t('MypageReport.string22')}</span>
                            <div className={styles.graph_area}>
                              <div className={styles.graph_wrap}>
                                <div className={styles.graph}></div>
                              </div>
                              <span className={styles.ability_percent}>
                                {abilityObject && abilityObject.find(word => word.mii_indicators_type === 'Sc').cnt || 0} 
                                {/*ability?.data !== null ? ability?.data[5]?.cnt || 0 : 0*/}
                                {/*returnAbility(ability?.data !== null ? ability?.data[5]?.cnt || 0 : 0, totalability?.data[5].cnt || 0, 3)*/}
                              </span>
                            </div>
                          </div>
                          <p>{/* 문제의 구조와 법칙등을 관찰하여 문제를 해결합니다. */}{t('MypageReport.string23')}</p>
                        </div>
                      </div>
                      <div className={styles.cognitive_ability}>
                        <div className={styles.left_area}>
                          <img src="/assets/my_report_math@2x.png" alt="수학"/>
                        </div>
                        <div className={styles.right_area}>
                          <div className={styles.top_area}>
                            <span className={styles.name}>{/* 수학 */}{t('MypageReport.string24')}</span>
                            <div className={styles.graph_area}>
                              <div className={styles.graph_wrap}>
                                <div className={styles.graph}></div>
                              </div>
                              <span className={styles.ability_percent}>
                                {abilityObject && abilityObject.find(word => word.mii_indicators_type === 'Ma').cnt || 0} 
                                {/*ability?.data !== null ? ability?.data[4]?.cnt || 0 : 0*/}
                                {/*returnAbility(ability?.data !== null ? ability?.data[4]?.cnt || 0 : 0, totalability?.data[4].cnt || 0, 4)*/}
                              </span>
                            </div>
                          </div>
                          <p>{/* 숫자, 크기, 구조 등의 개념을 생각해서 문제를 해결합니다. */}{t('MypageReport.string25')}</p>
                        </div>
                      </div>
                      <div className={styles.cognitive_ability}>
                        <div className={styles.left_area}>
                          <img src="/assets/my_report_engineering@2x.png" alt="공학"/>
                        </div>
                        <div className={styles.right_area}>
                          <div className={styles.top_area}>
                            <span className={styles.name}>{/* 공학 */}{t('MypageReport.string26')}</span>
                            <div className={styles.graph_area}>
                              <div className={styles.graph_wrap}>
                                <div className={styles.graph}></div>
                              </div>
                              <span className={styles.ability_percent}>
                                {abilityObject && abilityObject.find(word => word.mii_indicators_type === 'En').cnt || 0}
                                {/*ability?.data !== null ? ability?.data[1]?.cnt || 0 : 0*/}
                                {/*returnAbility(ability?.data !== null ? ability?.data[1]?.cnt || 0 : 0, totalability?.data[1].cnt || 0, 5)*/}
                              </span>
                            </div>
                          </div>
                          <p>{/* 과학적 원리와 실용적 지식을 활용하여 문제를 해결합니다. */}{t('MypageReport.string27')}</p>
                        </div>
                      </div>
                    </div>{/* ability_area */} 
                  </div>{/* wrap */}
                </div>{/* my_report_cognitive_ability_area */}
              </div>{/* inner */}
            </div>
            <br></br>
            <Divider />
            <br></br>
        </List>

      </Dialog>
    </div>
  );
}





// import * as React from 'react';
// import Button from '@mui/material/Button';
// import Dialog from '@mui/material/Dialog';
// import List from '@mui/material/List';
// import Divider from '@mui/material/Divider';
// import AppBar from '@mui/material/AppBar';
// import Toolbar from '@mui/material/Toolbar';
// import IconButton from '@mui/material/IconButton';
// import Typography from '@mui/material/Typography';
// import CloseIcon from '@mui/icons-material/Close';
// import Slide from '@mui/material/Slide';

// import styles from "../../css/common.module.css";

// import ClassDataTable2 from './ClassDataTable2';

// import ClassChart1 from './ClassChart1';
// import ClassChart2 from './ClassChart2';
// import ClassChart3 from './ClassChart3';
// import ClassChart4 from './ClassChart4';
// import ClassChart5 from './ClassChart5';
// import StudentChart1 from './StudentChart1';

// import ClassStudentChart2 from './ClassStudentChart2';

// const Transition = React.forwardRef(function Transition(props, ref) {
//   return <Slide direction="up" ref={ref} {...props} />;
// });

// export default function FullScreenDialog(props) {
//   const [open, setOpen] = React.useState(false);

//   const handleClickOpen = () => {
//     setOpen(true);
//   };

//   const handleClose = () => {
//     setOpen(false);
//   };

//   return (
//     <div>
//       <Button size="small" onClick={handleClickOpen}>
//         {props.StudentRow.mst_name}
//       </Button>
//       <Dialog
//         fullScreen
//         open={open}
//         onClose={handleClose}
//         TransitionComponent={Transition}
//       >
//         <AppBar sx={{ position: 'relative' }}>
//           <Toolbar>
//             <IconButton
//               edge="start"
//               color="inherit"
//               onClick={handleClose}
//               aria-label="close"
//             >
//               <CloseIcon />
//             </IconButton>
//             <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
//               학습자 정보
//             </Typography>
//             <Button autoFocus color="inherit" onClick={handleClose}>
//               닫기
//             </Button>
//           </Toolbar>
//         </AppBar>
//         <List>

//             <div className={`${styles.mypage_tab} ${styles.mypage_report}`}>
//                 <div className={styles.inner}>
//                     <br></br><br></br>
//                     <span className={styles.my_tit}>학습자 대쉬보드</span>
//                     <p className={styles.for_freeuser_info}>* 무료체험은 데이터가 없어 학습 리포트의 지표가 0으로 표시됩니다.</p>
//                     <div className={styles.my_chart_missonprogress_area}>
//                         <div className={styles.head}>
//                             <span>{props.StudentRow.mst_id}의 학습 현황</span>
//                             {/* <p>쓰게 되면 쓰고</p> */}
//                         </div>
//                         <div className={styles.wrap}>
//                             <div className={styles.misson_progress}>
//                                 <div className={styles.misson_class_txt}>
//                                     <span className={styles.misson_class_name}>학습자 정보</span>
//                                     <p>학습자의 정보를 요약해서 보여줍니다.</p>
//                                 </div>
//                                 <div>
//                                     <ClassDataTable2 />
//                                 </div>
//                             </div>
//                             <div className={styles.misson_progress}>
//                                 <div className={styles.misson_class_txt}>
//                                     <span className={styles.misson_class_name}>총 미션 진행상황</span>
//                                     <p>전체 중에 몇%가 진행됬는지 표시합니다.</p>
//                                 </div>
//                                 <div>
//                                     <ClassChart3 />
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                     <div className={styles.my_chart_missonprogress_area}>
//                         <div className={styles.head}>
//                             <span>학습자 진행현황 Part1</span>
//                             {/* <p>쓰게 되면 쓰고</p> */}
//                         </div>
//                         <div className={styles.wrap}>
//                             <div className={styles.misson_progress}>
//                                 <div className={styles.misson_class_txt}>
//                                     <span className={styles.misson_class_name}>수학미션 진도상황</span>
//                                     <p>수학미션의 진도를 표시합니다.</p>
//                                 </div>
//                                 <div>
//                                     <ClassChart1 />
//                                 </div>
//                             </div>
//                             <div className={styles.misson_progress}>
//                                 <div className={styles.misson_class_txt}>
//                                     <span className={styles.misson_class_name}>인지능력 수치</span>
//                                     <p>미션을 수행한 후 인지능력을 레이더 그래프로 표시합니다.</p>
//                                 </div>
//                                 <div>
//                                     <ClassChart2 />
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                     <div className={styles.my_chart_missonprogress_area}>
//                         <div className={styles.head}>
//                             <span>클래스 실시간 현황</span>
//                             <p>쓰게 되면 쓰고</p>
//                         </div>
//                         <div className={styles.wrap}>
//                             <div className={styles.misson_progress}>
//                                 <div className={styles.misson_class_txt}>
//                                     <span className={styles.misson_class_name}>미션의 첫 터치 평균 T점수</span>
//                                     <p>미션을 수행할 때 첫 블록이 놓이는 시간의 평균 점수입니다.</p>
//                                 </div>
//                                 <div>
//                                     <ClassChart5 />
//                                 </div>
//                             </div>
//                             <div className={styles.misson_progress}>
//                                 <div className={styles.misson_class_txt}>
//                                     <span className={styles.misson_class_name}>미션의 완료 평균 T점수</span>
//                                     <p>미션을 수행할 때 미션이 완료될 때까지의 시간 평균 점수입니다.</p>
//                                 </div>
//                                 <div>
//                                     <ClassChart4 />
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                     <div className={styles.my_class_chart_missonprogress_area}>
//                             <div className={styles.head}>
//                             <span>미션진도 상황</span>
//                             {/* <p>쓰게 되면 쓰고</p> */}
//                             </div>
//                             <div className={styles.wrap}>
//                             <ClassStudentChart2 />
//                             </div>
//                     </div>
//                     <div className={styles.my_class_chart_missonprogress_area}>
//                             <div className={styles.head}>
//                             <span>미션진도 상황</span>
//                             {/* <p>쓰게 되면 쓰고</p> */}
//                             </div>
//                             <div className={styles.wrap}>
//                             <StudentChart1 />
//                             </div>
//                     </div>
//                 </div>
//             </div>
//             <br></br>
//             <Divider />
//             <br></br>
//         </List>

//       </Dialog>
//     </div>
//   );
// }