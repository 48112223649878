import http from "../http-common";

const paymentCreate = data => {
  return http.post("payment/register", data);
};

const paymentGetAll = () => {
  return http.get("payment/list");
};

const paymentCancel = data => {
  return http.post("payment/cancel", data);
};

const paymentSelect = data => {
  return http.get(`payment/select/${data}`);
};

const paymentVbankCancel = data => {
  return http.post("payment/vbankcancel", data);
};

const PaymentService = {
  paymentCreate,
  paymentGetAll,
  paymentCancel,
  paymentSelect,
  paymentVbankCancel,
};

export default PaymentService;
