import {
  CREATE_BBS,
  CREATE_REPLY_BBS,
  LIST_BBS,
  DELETE_BBS,
  MODIFY_BBS,
  SELECT_BBS,
} from "./types";

import BBSService from "../services/BBSService";

export const bbsCreate = (data) => async (dispatch) => {
  
  try {
    
    const res = await BBSService.bbsCreate(data);

    // console.log("res : ", res);

    dispatch({
      type: CREATE_BBS,
      payload: res.data,
    });

    return Promise.resolve(res.data);

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};

export const bbsReplyCreate = (data) => async (dispatch) => {
  
  try {
    
    const res = await BBSService.bbsReplyCreate(data);

    console.log("res : ", res);

    dispatch({
      type: CREATE_REPLY_BBS,
      payload: res.data,
    });

    return Promise.resolve(res.data);

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};

export const bbsList = (data) => async (dispatch) => {
  
  try {
    
    const res = await BBSService.bbsGetAll(data);

    // console.log("res : ", res);

    dispatch({
      type: LIST_BBS,
      payload: res.data.data,
    });

    return Promise.resolve(res.data.data);

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};

export const bbsSelect = (bbs_name, bbs_idx) => async (dispatch) => {
  
  try {
    
    const res = await BBSService.bbsSelect(bbs_name, bbs_idx);

    // console.log("res : ", res);

    dispatch({
      type: SELECT_BBS,
      payload: res.data.data,
    });

    return Promise.resolve(res.data.data);

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};

export const bbsDelete = (data) => async (dispatch) => {
  
  try {

    const res = await BBSService.bbsDelete(data);

    // console.log("res : ", res);

    dispatch({
      type: DELETE_BBS,
      payload: res.data,
    });

    return Promise.resolve(res.data);

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};

export const bbsModify = (data) => async (dispatch) => {
  
  try {
    
    const res = await BBSService.bbsModify(data);

    // console.log("bbsModify res : ", res);

    dispatch({
      type: MODIFY_BBS,
      payload: res.data,
    });

    return Promise.resolve(res.data);

  } catch (err) {
    // console.log("Error: ", err);
    return Promise.reject(err.response);
  }

};