import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import styles from "../../css/common.module.css";
import $ from 'jquery';

import { groupOrderList } from "../../actions/group_order";
import { paymentCancel, paymentVbankCancel } from "../../actions/payment";
import YameLoading from '../../common/YameLoding';

import * as common from "../../lib";

import { useTranslation, Trans } from 'react-i18next'; // 1. react-i18next import 

export default function GroupMypagePayInfo() {

	const order = useSelector(state => state.group_order);
    // console.log("order : ", order);

	const dispatch = useDispatch();	

	const { t, i18n } = useTranslation(); // 3. useTranslation hook 선언

	const mypage_tab_btn_areaRef =  useRef();
    const hover_bgRef =  useRef();
    const onRef =  useRef();

	const pay_info_list_pagebtn_areaRef =  useRef();

	const [Selected, setSelected] = useState("default");
	const [ordNo, setOrdNo] = useState("");
	const [payIdx, setPayIdx] = useState("");
	const [payNo, setPayNo] = useState("");
	const [impUid, setImpUid] = useState("");
	const [payAmt, setPayAmt] = useState("");
	const [payOptCd, setPayOptCd] = useState("");
	const [payDate, setPayDate] = useState("");

	const [display, setDisplay] = useState(false);
	const [loading, setLoading] = useState(false);

	useEffect(() => {

		// 여기에서 jQuery를 초기화 합니다.
		// jquery ready 호출. jquery3 부터 변경됨
		$(function(){
			// console.log("jquery ready!");

            //마이페이지 탭 버튼(활성화 효과)
			$(`.${styles.mypage_tab_btn_area} li`).on('click',function()
			{
				let tg=$(this);
				
				$(`.${styles.mypage_tab_btn_area} li`).removeClass(`${styles.on}`);
				tg.addClass(`${styles.on}`);
			});
            
            //마이페이지 탭 버튼(후버 효과)
            $(window).resize(function()
			{
				if (window.innerWidth < 1200) 
				{ 
					$(`.${styles.mypage_tab_btn_area} li.${styles.hover_bg}`).css('display','none');
				}
				else
				{
					let nowbtnNum=$(`.${styles.mypage_tab_btn_area} li.${styles.on}`).index();
					let hoverBg=$(`.${styles.mypage_tab_btn_area} li.${styles.hover_bg}`);

					$(`.${styles.mypage_tab_btn_area} ul`).on('mouseenter',function()
					{
						
						$(`.${styles.mypage_tab_btn_area} li`).removeClass(`${styles.on}`);
						hoverBg.css('display','block');
						
						$(`.${styles.mypage_tab_btn_area} li`).on('mouseenter',function()
						{
							let tgIndex=$(this).index();
							
							if(tgIndex===1)
							{
								hoverBg.stop().css('left','167px').css('width','116px');
							}
							else if(tgIndex===2)
							{
								hoverBg.stop().css('left','300px').css('width','150px');
							}
							else if(tgIndex===3)
							{
								hoverBg.stop().css('left','467px').css('width','116px');
							}
							else if(tgIndex===4)
							{
								hoverBg.stop().css('left','624px').css('width','102px');
							}
							else if(tgIndex===5)
							{
								hoverBg.stop().css('left','774px').css('width','102px');
							}
							else if(tgIndex===6)
							{
								hoverBg.stop().css('left','924px').css('width','102px');
							};
						});
						
					}).on('mouseleave',function()
					{
						$(`.${styles.mypage_tab_btn_area} li`).eq(nowbtnNum).addClass(`${styles.on}`);
						hoverBg.css('display','none');
						
						if(nowbtnNum===0)
						{
							hoverBg.css({left:'167px'}).css({width:'116px'});
						}
						else if(nowbtnNum===1)
						{
							hoverBg.css({left:'300px'}).css({width:'150px'});
						}
						else if(nowbtnNum===2)
						{
							hoverBg.css({left:'467px'}).css({width:'116px'});
						}
						else if(nowbtnNum===3)
						{
							hoverBg.css({left:'624px'}).css({width:'102px'});
						}
						else if(nowbtnNum===4)
						{
							hoverBg.css({left:'774px'}).css({width:'102px'});
						}
						else if(nowbtnNum===5)
						{
							hoverBg.css({left:'924px'}).css({width:'102px'});
						};

					});
					
				}
			}).resize();
			
			//결제 정보 페이지 페이징 버튼	
			$(pay_info_list_pagebtn_areaRef.current).find('li').on('click',function()
			{
				let tg=$(this);
				let num=tg.index();
				let prev;
				let next;
				let last;
				let nowBtn=$(pay_info_list_pagebtn_areaRef.current).children(`.${styles.on}`);
				let nowIndex=nowBtn.index();
				
				$(pay_info_list_pagebtn_areaRef.current).find('li').removeClass(`${styles.on}`);
				
				if(tg.hasClass(`${styles.arrow_btn}`)==true)
				{
					if(tg.hasClass(`${styles.first}`)==true)
					{
						tg=$(pay_info_list_pagebtn_areaRef.current).find('li').eq(2);
					}
					else if(tg.hasClass(`${styles.prev}`)==true)
					{
						prev=nowIndex-1;
						
						if(nowIndex===2)
						{
							tg=$(pay_info_list_pagebtn_areaRef.current).find('li').eq(2);
						}else
						{
							tg=$(pay_info_list_pagebtn_areaRef.current).find('li').eq(prev);
						};
						// console.log("prev click");
					}
					else if(tg.hasClass(`${styles.next}`)==true)
					{
						let lastNum=$(pay_info_list_pagebtn_areaRef.current).find('li').last().index();
						next=nowIndex+1;
						last=lastNum-2;
						
						if(nowIndex===last)
						{
							tg=$(pay_info_list_pagebtn_areaRef.current).find('li').eq(last);
						}else
						{
							tg=$(pay_info_list_pagebtn_areaRef.current).find('li').eq(next);
						};
						// console.log("next click");
					}
					else if(tg.hasClass(`${styles.last}`)==true)
					{
						last=num-2;
						tg=$(pay_info_list_pagebtn_areaRef.current).find('li').eq(last);
					};
					
					tg.addClass(`${styles.on}`);
					
				}
				else
				{
					tg.addClass(`${styles.on}`);
				};
			});

		});

		// 여기서 디스패치로~
        dispatch(groupOrderList())
        .then(data => 
        {
            // console.log("orderList data : ", data);
        })
        .catch(e => {
            console.log(e);
        });

	}, [$, dispatch]);


	const refundClick = (event, ord_no, pay_idx, pay_no, imp_uid, pay_amt, pay_opt_cd, pay_date) => {

		event.preventDefault();
		// console.log("ord_no : ", ord_no);
		// console.log("pay_idx : ", pay_idx);
		// console.log("pay_no : ", pay_no);
		// console.log("imp_uid : ", imp_uid);
		// console.log("pay_amt : ", pay_amt);
		// console.log("pay_opt_cd : ", pay_opt_cd);
		// console.log("pay_date : ", pay_date);

		setOrdNo(ord_no);
		setPayIdx(pay_idx);
		setPayNo(pay_no);
		setImpUid(imp_uid);
		setPayAmt(pay_amt);
		setPayOptCd(pay_opt_cd);
		setPayDate(pay_date)

		// 구매일로부터 일주일이 지났는지 체크합니다. 구매일로부터 1주일안에 환불이 가능합니다.
		// 일주일 전 구하기
		// let payDate = new Date(pay_date);		// 구매일
		// let payDate = new Date("2022-08-05 12:05:00");		// 구매일
		// console.log("payDate : ", payDate);
		// let lastWeek = new Date(payDate.setDate(payDate.getDate() + 7));	// 구매일로 일주일 후 날짜
		// console.log("lastWeek : ", lastWeek);
		// let nowDate = new Date();		// 오늘날짜
		// console.log("nowDate : ", nowDate);

		// if (lastWeek <= nowDate)
		// {
		// 	// console.log("구입일로 부터 일주일이 지났습니다. 환불이 불가능합니다. 약관을 참조하세요!");
		// 	return alert("구입일로 부터 일주일이 지났습니다. 환불이 불가능합니다. 약관을 참조하세요!");
		// }


		// dispatch(orderLastweek(ord_no))
        // .then(data => 
        // {
		// 	// 오늘로 부터 구입한지 얼마의 시간이 지났는지 체크합니다.
		// 	// console.log("data : ", data);
		// 	if (data > 7)
		// 	{
		// 		return alert("구입일로 부터 일주일이 지났습니다. 환불이 불가능합니다. 약관을 참조하세요!");
		// 	}
		// 	else
		// 	{
		// 		// 환불이 가능한지 검사합니다.
		// 		// 여기서 디스패치로~
		// 		dispatch(orderUse(ord_no))
		// 		.then(data => 
		// 		{
		// 			// console.log("data : ", data);
		// 			if (data === 0)
		// 			{
		// 				if (!window.confirm("환불가능합니다. 환불 하시겠습니까?")) 
		// 				{
		// 					// 취소(아니오) 버튼 클릭 시 이벤트
		// 					return;
		// 				} 
		// 				else 
		// 				{
		// 					if (pay_opt_cd === "vbank")		// 가상계좌의 경우엔 정보를 입력받아야 합니다.
		// 					{
		// 						//가상계좌 환불 팝업
		// 						blackBg(1);
		// 						$(`.${styles.refund_popup}`).css('display','block');

		// 						return;
		// 					}

		// 					// 확인(예) 버튼 클릭 시 이벤트
		// 					const cancelPay = 
		// 					{ 
		// 						pay_idx: pay_idx,
		// 						pay_no: pay_no,
		// 						merchant_uid: ord_no, // 주문번호
		// 						imp_uid: imp_uid, // 아임포트 고유번호
		// 						cancel_request_amount: parseInt(pay_amt), // 환불금액
		// 						reason: "야미 홈페이지에서 사용자의 요청에 의한 환불", // 환불사유
		// 						refund_holder: "", // [가상계좌 환불시 필수입력] 환불 수령계좌 예금주
		// 						refund_bank: "", // [가상계좌 환불시 필수입력] 환불 수령계좌 은행코드(예: KG이니시스의 경우 신한은행은 88번)
		// 						refund_account: "" // [가상계좌 환불시 필수입력] 환불 수령계좌 번호
		// 					}

		// 					// console.log("cancelPay : ", cancelPay);
		// 					setDisplay(true);

		// 					// 여기서 디스패치로~
		// 					dispatch(paymentCancel(cancelPay))
		// 					.then(data => 
		// 					{
		// 						// console.log("data : ", data);
								
		// 						if (!data.success)
		// 							alert(data.msg);
		// 						else
		// 						{
		// 							alert("환불이 정상적으로 이루어졌습니다.");
		// 							setLoading(true);
		// 						}

		// 						window.location.reload();
		// 					})
		// 					.catch(e => {
		// 						console.log(e);
		// 					});

		// 					return;
		// 				}
							
		// 			}
		// 			else
		// 			{
		// 				alert("패키지를 사용하시면 환불이 되지 않습니다. 약관을 참조하세요.");
		// 				return;
		// 			}
		// 		})
		// 		.catch(e => {
		// 			console.log(e);
		// 		});
		// 	}
		// })
        // .catch(e => {
        //     console.log(e);
        // });

		
	}

	//팝업의 확인 클릭시 팝업 닫힘 & 콘솔
	/*
	function continueVirtualRefund(event)
	{
		event.preventDefault();

		// console.log("ordNo : ", ordNo);
		// console.log("payIdx : ", payIdx);
		// console.log("payNo : ", payNo);
		// console.log("impUid : ", impUid);
		// console.log("payAmt : ", payAmt);
		// console.log("payOptCd : ", payOptCd);
		// console.log("payDate : ", payDate);

		let accountNumber=$(`#${styles.virtual_account_number}`).val();
		let holder=$(`#${styles.virtual_account_holder}`).val();
		let bankName=$(`#${styles.virtual_bank_name_select} option:selected`).text();
		let bankCode=$(`#${styles.virtual_bank_name_select}`).val();

		// console.log(`가상계좌 입금계좌번호 : ${accountNumber}
		// 가상계좌 예금주 : ${holder}
		// 은행명 : ${bankName} / 은행코드 : ${bankCode}`)

		const regBank = /^[0-9]+$/;
		accountNumber = accountNumber.replace(/-/g, '');

		if (holder === "")
			return alert("예금주가 비었습니다.");
		else if (accountNumber === "")
			return alert("계좌번호가 비었습니다.");
		else if (regBank.test(accountNumber) === false) 
			return alert('계좌번호는 숫자만 입력가능합니다.');
		else if (bankCode === "" || bankCode === null)
			return alert("은행이 선택되지 않았습니다.");

		const cancelPay = 
		{ 
			pay_idx: payIdx,
			pay_no: payNo,
			merchant_uid: ordNo, // 주문번호
			imp_uid: impUid, // 아임포트 고유번호
			cancel_request_amount: parseInt(payAmt), // 환불금액
			reason: "야미 홈페이지에서 사용자의 요청에 의한 환불", // 환불사유
			refund_holder: holder, // [가상계좌 환불시 필수입력] 환불 수령계좌 예금주
			refund_bank: bankCode, // [가상계좌 환불시 필수입력] 환불 수령계좌 은행코드(예: KG이니시스의 경우 신한은행은 88번)
			refund_account: accountNumber // [가상계좌 환불시 필수입력] 환불 수령계좌 번호
		}

		// console.log("cancelPay : ", cancelPay);
		setDisplay(true);

		// 여기서 디스패치로~
		dispatch(paymentVbankCancel(cancelPay))
		.then(data => 
		{
			// console.log("data : ", data);
			if (!data.success)
				alert(data.msg);
			else
			{
				alert("환불이 정상적으로 이루어졌습니다.");
				setLoading(true);
			}

			window.location.reload();
		})
		.catch(e => {
			console.log(e);
		});

		$(`#${styles.virtual_account_number}`).val('');
		$(`#${styles.virtual_account_holder}`).val('');
		$(`#${styles.virtual_bank_name_select}`).val('default');

		blackBg(2);
		$(`.${styles.refund_popup}`).css('display','none');
	}
	*/

	//팝업의 취소 클릭시 팝업 닫힘
	const closeRefundPopup = () =>
	{
		$(`#${styles.virtual_account_number}`).val('');
		$(`#${styles.virtual_account_holder}`).val('');
		$(`#${styles.virtual_bank_name_select}`).val('default');

		blackBg(2);
		$(`.${styles.refund_popup}`).css('display','none');
	}

	//검정 반투명 배경 제어
	const blackBg = (i) =>
	{
		if(i===1)
			{
				$(`.${styles.body_black}`).css('display','block');
				// $('body').css('overflow','hidden');
				// console.log('배경보임');
			}
			else
			{
				$(`.${styles.body_black}`).css('display','none');
				// $('body').css('overflow','visible');
				// console.log('배경꺼짐');
			};
	};

	const handleSelect = (e) => 
	{
		setSelected(e.target.value);
	};

	const onReadyClick = () => 
	{
		// alert("서비스 중비중입니다.");
		alert(`${t("Common.service_wait")}`);
	};
	

  return (
    <>
      	{/* Header 분리됨 MainLayout Header에 있음 */}

		{/* 로딩화면 */}
		<YameLoading display={display} loadingValue={loading} />

		{/* <div className={styles.body_black}></div>
		<div className={styles.refund_popup}>
			<div>
				<p>가상계좌 환불정보 입력</p>
				<input type="text" id={styles.virtual_account_holder} placeholder="환불 수령계좌 예금주"/>
				<input type="tel" id={styles.virtual_account_number} placeholder="환불 수령계좌 번호"/>
				<select name="virtual_bank_name" id={styles.virtual_bank_name_select} value={Selected} onChange={handleSelect}>
					<option value="default" disabled>은행명</option>
					<option value="04">KB국민은행</option>
					<option value="23">SC제일은행</option>
					<option value="39">경남은행</option>
					<option value="34">광주은행</option>
					<option value="03">기업은행</option>
					<option value="11">농협</option>
					<option value="31">대구은행</option>
					<option value="32">부산은행</option>
					<option value="02">산업은행</option>
					<option value="45">새마을금고</option>
					<option value="07">수협</option>
					<option value="88">신한은행</option>
					<option value="48">신협</option>
					<option value="81">외환은행</option>
					<option value="20">우리은행</option>
					<option value="71">우체국</option>
					<option value="37">전북은행</option>
					<option value="12">축협</option>
					<option value="90">카카오뱅크</option>
					<option value="89">케이뱅크</option>
					<option value="81">하나은행</option>
					<option value="27">한국씨티은행</option>
					<option value="92">토스뱅크</option>
				</select>
				<p className={styles.info}>* 가상계좌 환불을 요청하시면 해당 계좌로 환불 금액을 입금합니다. 이는 통상적으로 영업일 기준(주말, 공휴일 제외) 1~2일 소요됩니다.</p>
				<p className={styles.info}>* 본 정보는 서버에 저장되지않고 환불에만 사용됩니다.</p>
				<div className={styles.btn_area}>
					<button type="button" className={styles.confirm} onClick={(e)=>continueVirtualRefund(e)}>환불</button>
					<button type="button" className={styles.cancle} onClick={closeRefundPopup}>취소</button>
				</div>
			</div>
		</div> */}

		<div className={styles.mypage_top_line_banner}>
			<div className={styles.inner}>
				<span>{/* 학부모 페이지 */}{t('Common.Mypage_title2')}</span>
			</div>
		</div>
		<div className={styles.mypage_tab_btn_area} ref={mypage_tab_btn_areaRef}>
			<div className={styles.inner}>
				{/* <ul>
					<li className={styles.hover_bg} ref={hover_bgRef}></li>
					<li><a href="/GroupUserMypage/GroupReport">학습 리포트</a></li>
					<li><a href="/GroupUserMypage/GroupStudentInfo">학습자 정보/관리</a></li>
					<li><a href="/GroupUserMypage/GroupParentsInfo">학부모 정보</a></li>
					<li className={styles.on} ref={onRef}><a href="/GroupUserMypage/GroupPayInfo">결제 정보</a></li>
					<li><a href="/GroupUserMypage/GroupCouponInfo">쿠폰 관리</a></li>
					<li><a href="/GroupUserMypage/GroupQnaInfo">문의 내역</a></li>
				</ul> */}
				<ul>
					<li className={styles.hover_bg} ref={hover_bgRef}></li>
					<li><a href="/GroupUserMypage/GroupReport">{/* 클래스 관리 */}{t('Common.Group_SubMenu.menu1')}</a></li>
					<li><a href="/GroupUserMypage/GroupParentsInfo">{/* 관리자 정보 */}{t('Common.Group_SubMenu.menu2')}</a></li>
					<li className={styles.on} ref={onRef}><a href="/GroupUserMypage/GroupPayInfo">{/* 결제 관리 */}{t('Common.Group_SubMenu.menu3')}</a></li>
					<li><a href="/GroupUserMypage/GroupCouponInfo">{/* 쿠폰관리 */}{t('Common.Group_SubMenu.menu4')}</a></li>
					{/* <li><a href="/GroupUserMypage/GroupStudentInfo">공지사항</a></li>
					<li><a href="/GroupUserMypage/GroupQnaInfo">질문과 답변</a></li> */}
					<li><a href="#!" onClick={()=>onReadyClick()}>{/* 공지사항 */}{t('Common.Group_SubMenu.menu5')}</a></li>
					<li><a href="#!" onClick={()=>onReadyClick()}>{/* 질문과 답변 */}{t('Common.Group_SubMenu.menu6')}</a></li>
				</ul>
			</div>
		</div>
		<div className={`${styles.mypage_tab} ${styles.mypage_pay_info}`}>
			<div className={styles.inner}>
				<span className={styles.my_tit}>{/* 결제 정보 */}{t('MypagePayInfo.title')}</span>
				<div className={styles.pay_info_area}>
					<div className={styles.title}>
						<div></div>
						<p>{/* 결제 내역 */}{t('MypagePayInfo.string1')}</p>
					</div>
					{/* <p className={styles.title_info}>( 실시간 계좌와 가상 계좌는 환불 처리가 완료되어도 입금까지 영업일 기준(주말, 공휴일 제외) 1~2일 소요됩니다. )</p> */}
					<ul className={styles.pay_info_list_wrap}>
						<li className={styles.list_head}>
							<div>
								<span>{/* 주문일자 */}{t('MypagePayInfo.string5')}</span>
							</div>
							<div>
								<span>{/* 주문상품 */}{t('MypagePayInfo.string6')}</span>
							</div>
							<div>
								<span>{/* 주문자 */}{t('MypagePayInfo.string7')}</span>
							</div>
							<div>
								<span>{/* 결제금액 */}{t('MypagePayInfo.string8')}</span>
							</div>
							<div>
								<span>{/* 결제상태 */}{t('MypagePayInfo.string9')}</span>
							</div>
							<div></div> 
						</li>

						{
							order.filter(row => row.bord_stat_cd === 'A' || row.bord_stat_cd === 'W')?.length === 0
							? <li className={styles.list}>
								<ul
								style={{
									display:'flex',
									alignItems:'center',
									justifyContent:'center',
									width:'100%',
									height:'100%'
								}}>
									{/* 데이터 없음 */}{t('Common.No_data')}
								</ul>
							</li>
							: null

						}

						{
						
							order && order.filter(row => row.bord_stat_cd === 'A' || row.bord_stat_cd === 'W')?.map((row, index) => (
						
						<li className={styles.list} key={index}>
							<ul className={styles.top_area}>
								<li>
									<span className={styles.date}>{common.dateChangeReturn(row.bord_date || "0000-00-00")}</span>
								</li>
								<li>
									<p className={`${styles.name} ${styles.ellip}`}>{row.prd_name || ""} (PG)</p>
								</li>
							</ul>
							<ul className={styles.bottom_area}>
								<li>
									<span className={`${styles.student_name} ${styles.ellip}`}>{row.mem_name || ""}</span>
								</li>
								<li>
									<span className={styles.fee}>{common.priceToString(row.bpay_amt)}</span>
								</li>
								<li>
									<span className={styles.pay_condition}>
										{row.bpay_state === null ? common.returnOrderState(row.bord_stat_cd) : common.returnGroupPaymentState(row.bpay_state)} <br/>
										{/* <span className={styles.pay_method}>({common.returnPayOptCdState(row.pay_opt_cd || "")})</span> */}
									</span>
								</li>
								<li>
									<a href={`/GroupUserMypage/GroupPayInfoMore/${row.bord_idx}`}>{/* 결제 상세보기 */}{t('MypagePayInfo.string10')}</a>
									{/* {
										(row.pay_opt_cd !== "cancel" && row.bord_stat_cd === "A") ? 
											<button onClick={(e) => refundClick(e, row.ord_no, row.pay_idx, row.pay_no, row.imp_uid, row.pay_amt, row.pay_opt_cd, row.pay_date)}>결제취소</button> 
										: 
											""
									}						 */}
								</li>
							</ul>
						</li>

						))}

					</ul>
					{/* <ul className={styles.pay_info_list_pagebtn_area} ref={pay_info_list_pagebtn_areaRef}>
						<li className={`${styles.arrow_btn} ${styles.first}`}><a href="#!"><img src="/assets/first_btn@2x.png" alt="첫번째 페이지로 이동"/></a></li>
						<li className={`${styles.arrow_btn} ${styles.prev}`}><a href="#!"><img src="/assets/prev_btn@2x.png" alt="이전 페이지로 이동"/></a></li>
						<li className={styles.on}><a href="#!">1</a></li>
						<li><a href="#!">2</a></li>
						<li><a href="#!">3</a></li>
						<li><a href="#!">4</a></li>
						<li><a href="#!">5</a></li>
						<li><a href="#!">6</a></li>
						<li><a href="#!">7</a></li>
						<li><a href="#!">8</a></li>
						<li><a href="#!">9</a></li>
						<li><a href="#!">10</a></li>
						<li className={`${styles.arrow_btn} ${styles.next}`}><a href="#!"><img src="/assets/next_btn@2x.png" alt="다음 페이지로 이동"/></a></li>
						<li className={`${styles.arrow_btn} ${styles.last}`}><a href="#!"><img src="/assets/last_btn@2x.png" alt="마지막 페이지로 이동"/></a></li>
					</ul> */}
				</div>
			</div>{/* inner */}
		</div>{/* mypage_tab */}
		
		{/* Footer 분리됨 MainLayout Footer에 있음 */}
    </>
  );
}