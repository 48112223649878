import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

import Divider from '@mui/material/Divider';

import UploadFileIcon from '@mui/icons-material/UploadFile';

import { useCallback, useState, useEffect } from 'react';
import * as XLSX from "xlsx";

import { useTranslation, Trans } from 'react-i18next'; // 1. react-i18next import 

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { useDispatch, useSelector } from "react-redux";
import { couponUserList, couponUserSelect, couponBulkRegister } from "../../actions/coupon";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default React.forwardRef(function StudentExcelUploadDialog(props, ref) {
  const [open, setOpen] = React.useState(false);
  const [couponCheck, setCouponCheck] = React.useState(false);	// 엑셀 데이터 내에서 서로 중복된 아이디가 있는지 체크하는 변수입니다.

  // 강제로 재렌더링을 하게 하는 방법
  const [, updateState] = useState();	// 상태변수는 선언하지 않는다.
  const forceUpdate = useCallback(() => updateState({}), []);

  const { t, i18n } = useTranslation(); // 3. useTranslation hook 선언
  const dispatch = useDispatch();	

  const authState = useSelector(state => state.auth);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
	setUploadedFile(null);
  };

  const [uploadedFile, setUploadedFile] = useState(null);

  const handleDrop = useCallback(async (acceptedFiles, event) => {

	// console.log("loading....1");
    
	if (acceptedFiles.length > 0) 
	{
		// console.log("loading....2");
		const file = acceptedFiles[0];
		const reader = new FileReader();

		reader.readAsArrayBuffer(file);

		reader.onload = async (e) => 
		{
			// console.log("loading....3");
			const data = new Uint8Array(e.target.result);
			const workbook = XLSX.read(data, { type: "array", bookVBA: true });

			const sheetName = workbook.SheetNames[0];
			const sheet = workbook.Sheets[sheetName];
			const jsonData = XLSX.utils.sheet_to_json(sheet);

			// 아이디의 중복을 체크하기 위해서 별도의 배열을 선언해서 저장합니다.
			let arrayCouponNumber = [];

			// 칼럼의 이름이 정상적으로 불려왔는지 체크하는 변수
			let successSheetName = false;

			// console.log("sheet : ", sheet?.A1?.h);
			// console.log(jsonData);

			if (sheet?.A1?.h === "number" && sheet?.B1?.h === "coupon_number")
				successSheetName = true;

			if (successSheetName)	// 칼럼 이름이 정확해야지 데이터를 불러옵니다.
			{
				// 이용권을 빈 값으로 넣어줌
				for(let i=0; i<jsonData.length; i++)
				{
					jsonData[i].errorCheck = 1;

					arrayCouponNumber[i] = jsonData[i].coupon_number;
				}

				// 중복된 쿠폰 번호를 체크합니다.
				for(let i = 0; i < arrayCouponNumber.length; i++) 
				{
					const currElem = arrayCouponNumber[i];
					
					for(let j = i+1; j < arrayCouponNumber.length; j++) 
					{
						if(currElem === arrayCouponNumber[j])
						{
							setCouponCheck(true);
							break;
						}
					}
				}
				// console.log(file);
				setUploadedFile({ file, jsonData });

				event.target.value = "";	// value 초가화를 하면 같은 파일도 재업로드 시 다시 인식한다.

			}
			else
			{
				// return alert(`엑셀 데이터가 샘플과 맞지 않습니다. 엑셀 샘플 데이터를 다운받으셔서 작성해주세요.`);
				return alert(`${t("ClassStudentCommonDlg.alert.string37")}`);
			}
			
		};
    }

  }, []);

//   const handleLicenseChange = (event, index) => {

// 	event.preventDefault();

// 	let jsonData = uploadedFile.jsonData;
// 	let file = uploadedFile.file;
// 	jsonData[index].Ticket = {name:"샘플", mm_idx:1111};

// 	setUploadedFile({ file, jsonData });
//   }

//   const handleErrorConfirm = (event, index) => {

// 	event.preventDefault();

// 	let jsonData = uploadedFile.jsonData;
// 	console.log(jsonData[index].errorMsg);
//   }


  const handleCouponNumberCheck = (event, index) => {

	event.preventDefault();

	let jsonData = uploadedFile.jsonData;
	let file = uploadedFile.file;

	if(jsonData[index].coupon_number === undefined)
		return alert(`${t("MypageCoupon.alert.string5")}`);
		// return alert(`빈 값은 체크가 안됩니다.`);

	// console.log(jsonData[index].coupon_number);

	dispatch(couponUserSelect(jsonData[index].coupon_number))
	.then(data => 
	{
		// console.log("data : ", data);
		if(data === undefined)
		{
			// return alert(`이 쿠폰은 유효하지 않은 쿠폰입니다. 쿠폰번호를 다시 확인하세요.`);
			return alert(`${t("MypageCoupon.alert.string4")}`);
		}
		else if(data?.mem_idx === null)
		{
			jsonData[index].errorCheck = 0;	
			forceUpdate();			// 강제로 화면을 다시 렌더링시킵니다. 그렇지 않으면 두번을 눌러야 반영이 됩니다.
		}
		else
		{
			return alert(`${t("MypageCoupon.alert.string3", {number : jsonData[index].coupon_number})}`);
			// return alert(`{{number}}은 사용 중인 쿠폰 번호입니다.`);
		}
	})
	.catch(e => {
		console.log(e);
	});

	// setUploadedFile({ file, jsonData });
  }

  const handleStudentMake = (event) => {

	event.preventDefault();

	let errorCount = 0;

	for (let i=0; i<uploadedFile.jsonData.length; i++)
	{
		if (uploadedFile.jsonData[i].errorCheck === 1)
			errorCount++;
	}

	// console.log(errorCount);

	if (errorCount !== 0)
		return alert(`${t("MypageCoupon.alert.string4")}`);	
		// return alert("사용된 쿠폰번호를 수정해서 다시 업로드해주세요!");

	if (couponCheck)
		return alert(`${t("MypageCoupon.alert.string7")}`);
		// return alert("엑셀 데이터 내의 쿠폰번호가 중복되었습니다. 수정해서 다시 업로드해주세요!");

	// console.log(uploadedFile.jsonData);

	let memIdx = 0;

	if (authState.isLoggedIn)
	{
		memIdx = authState.user.idx;
	}
	else
	{
		// return alert("로그인이 필요합니다.");
		return alert(`${t("ClassStudentCommonDlg.alert.string13")}`);
	}

	// 전송하기 위한 데이터로 변환합니다.
	let sendData = [];
	
	for (let i=0; i<uploadedFile.jsonData.length; i++)
	{
		sendData[i] = {
			mem_idx: memIdx,
			coupon_number: uploadedFile.jsonData[i].coupon_number,
		};
	}

	dispatch(couponBulkRegister(sendData))
	.then(data => 
	{
		// console.log("couponBulkRegister data : ", data);
		// 사용가능한 이용권의 갯수
		if (!data.success)
		{
			return alert(data.msg);
		}
		else
		{
			const boardListSend = {
				board_page : 1,
				condition : "",
			  }

			// 패키지의 정보를 다시 불러옵니다.
			dispatch(couponUserList(boardListSend))
			.then(data => 
			{
				// console.log("couponUserList data : ", data);
				// return alert("쿠폰이 등록 되었습니다.");
				alert(`${t("MypageCoupon.alert.string2")}`);
				handleClose();
			})
			.catch(e => {
				console.log(e);
			});
		}
	})
	.catch(e => {
		console.log(e);
	});
  }

const PackageDataTransFuntion = (value, index) => {
    // console.log(value);
	// console.log(index);
	
	let jsonData = uploadedFile.jsonData;
	let file = uploadedFile.file;

	jsonData[index].Ticket = {name:value.mm_name, mm_idx:value.mm_idx};

	setUploadedFile({ file, jsonData });
	// console.log(jsonData);
}

const childFunction = (param) => {
    // console.log(`Child function called with parameter: ${param}`);
  };

  // 부모로부터 전달된 `ref`를 사용하여 함수 노출
  ref.current = {
    handleClickOpen,
	childFunction,
  };

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
		maxWidth="sm"
		fullWidth={true}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
          {t('MypageCoupon.string23')}
		  <Typography gutterBottom sx={{fontSize:15, marginTop: 2}}>
		  {/* * 작업 순서 : 팝업창 규칙 읽기 -> 엑셀 샘플 파일 다운로드 -> 엑셀 작성 -> 업로드 -> 쿠폰 번호 체크(사용중인지) -> 등록 (오류 발생시 안내를 참조해서 수정해서 재 업로드) */}{t('MypageCoupon.string24')}<br></br>
		  <a href="/assets/coupon_upload_sample.xlsx" download style={{fontSize:'14px', fontWeight:800, color:'#FF4500'}}>{/* [업로드 엑셀 샘플 다운로드] */}{t('ClassStudentCommonDlg.string47')}</a><br></br><br></br>
		  {/* 규칙1. 업로드 엑셀 안에 같은 쿠폰번호가 존재하면 업로드가 안됩니다. */}{t('MypageCoupon.string25')}<br></br>
		  {/* 규칙2. 이미 사용중인 쿠폰인지 체크를 한 쿠폰번호만 업로드 가능합니다. */}{t('MypageCoupon.string26')} <br></br>
		  {/* 규칙3. 반드시 샘플 엑셀을 다운받으셔서 규칙에 맞게 작성하신 후에 업로드해주세요. */}{t('MypageCoupon.string27')} <br></br>
		  <br></br>
		  <label for="file">
			<div style={
				{
					width: '130px',
					height: '25px',
					background: '#fff',
					border: '1px solid rgb(77,77,77)',
					borderRadius: '10px',
					fontWeight: '200',
					fontSize:'13px',
					cursor: 'pointer',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center'
				}
			}>{/* 파일 업로드하기 */}{t('ClassStudentCommonDlg.string53')}</div>
		  </label>
		  <input
			type="file"
			accept=".xlsx, .xls, .csv"
			onChange={(e) => handleDrop(e.target.files, e)}
			id="file"
			style={{display:'none'}}
			/>
			{/* 업로드 파일 */}{t('ClassStudentCommonDlg.string54')} : {uploadedFile?.file?.name || t('ClassStudentCommonDlg.string56')}
          </Typography>
        </BootstrapDialogTitle>
        <DialogContent dividers>
          
        </DialogContent>
		<Box
		component="form"
		sx={{
			'& .MuiTextField-root': { m: 1, width: '670px', marginTop: '20px', marginLeft: '20px', marginRight: '20px' },
		}}
		noValidate
		autoComplete="off"
		>
		<div>
			<TableContainer sx={{ maxHeight: 505 }}>
				<Table stickyHeader aria-label="sticky table" size="small">
				<TableHead>
					<TableRow>
					<TableCell align="center">{/* 번호 */}{t('ClassStudentCommonDlg.string18')}</TableCell>
					<TableCell align="center">{/* 쿠폰번호 */}{t('MypageCoupon.string21')}</TableCell>
					<TableCell align="center">{/* 사용 여부 */}{t('MypageCoupon.string28')}</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
				{
					uploadedFile && uploadedFile.jsonData.length !== 0
					? 
					uploadedFile.jsonData
					.map((row, index) => {
						return (
						<TableRow hover role="checkbox" tabIndex={-1} key={index}>
							<TableCell component="th" scope="row" align="center">
							{index+1}
							</TableCell>
							<TableCell align="center">{row.coupon_number}</TableCell>
							<TableCell align="center">
								{row.errorCheck === 1 ? <Button variant="outlined" color="error" size="small" onClick={(e)=> handleCouponNumberCheck(e, index)}>{/* 확인 */}{t('Common.confirm')}</Button> : /* "없음" */ t('ClassStudentCommonDlg.string56')}
							</TableCell>
						</TableRow>
						);
					})
					:
					<TableRow>
						<TableCell component="th" scope="row" align="center" colSpan="9">{/* 데이터 없음 */}{t('Common.No_data')}</TableCell>
					</TableRow>
					}
				</TableBody>
				</Table>
			</TableContainer>
		</div>
		</Box>
		<Divider />
		
        <DialogActions>
          <Button autoFocus onClick={(e)=> handleStudentMake(e)}>
			{/* 등록 */}{t('MypageCoupon.register')}
          </Button>
		  <Button autoFocus onClick={handleClose}>
            {/* 취소 */}{t('Common.cancel')}
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
});