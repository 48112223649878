import {
  LIST_REFUND,
} from "../actions/types";

const initialState = [];

const refundReducer = (refund = initialState, action) => {
  const { type, payload } = action;

  // console.log("refundReducer Call!");
  // console.log("type : ", type);
  // console.log("payload : ", payload);

  switch (type) {

    case LIST_REFUND:
      return payload;

    default:
      return refund;
  }
};

export default refundReducer;