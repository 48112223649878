import {
  LIST_RANKING,
  CREATE_RANKING,
  MODIFY_RANKING,
  DELETE_RANKING,
  SELECT_RANKING,
  MAKE_RANKING,
  LIST_CHALLENGE_RANKING,
  LIST_TOTAL_RANKING,
} from "../actions/types";

const initialState = [];

const rankingReducer = (ranking = initialState, action) => {
  const { type, payload } = action;

  // console.log("rankingReducer Call!");
  // console.log("type : ", type);
  // console.log("payload : ", payload);

  switch (type) {

    case LIST_RANKING:
      return {
        ...ranking,
        list : payload.list,
        paging : payload.paging,
      };

    case CREATE_RANKING:
      return [];

    case MODIFY_RANKING:
      return [];

    case DELETE_RANKING:
      return [];

    case SELECT_RANKING:
      return{
        ...ranking,
        select: payload,
      };

    case LIST_CHALLENGE_RANKING:
      return {
        ...ranking,
        challengelist : payload,
      };

    case MAKE_RANKING:
      return [];

    case LIST_TOTAL_RANKING:
      return {
        ...ranking,
        totallist : payload,
      };

    default:
      return ranking;
  }
};

export default rankingReducer;