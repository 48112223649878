import {
  LIST_INAPP_PAYMENT,
  SELECT_INAPP_PAYMENT,
  REFUND_INAPP_PAYMENT,
} from "./types";

import InappPaymentService from "../services/InappPaymentService";

export const inappPaymentList = () => async (dispatch) => {
  
  try {
    
    const res = await InappPaymentService.inappPaymentGetAll();

    // console.log("res : ", res);

    dispatch({
      type: LIST_INAPP_PAYMENT,
      payload: res.data.data,
    });

    return Promise.resolve(res.data.data);

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};

export const inappPaymentSelect = (ord_idx) => async (dispatch) => {
  
  try {
    
    const res = await InappPaymentService.inappPaymentSelect(ord_idx);

    // console.log("res : ", res);

    dispatch({
      type: SELECT_INAPP_PAYMENT,
      payload: res.data.data,
    });

    return Promise.resolve(res.data.data);

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};

export const inappPaymentRefund = (data) => async (dispatch) => {
  
  try {
    
    const res = await InappPaymentService.inappPaymentRefund(data);

    // console.log("res : ", res);

    dispatch({
      type: REFUND_INAPP_PAYMENT,
      payload: res.data,
    });

    return Promise.resolve(res.data);

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};

