import {
  LIST_NEWS,
  CREATE_NEWS,
  MODIFY_NEWS,
  DELETE_NEWS,
  SELECT_NEWS,
  LIST_NEWS_OUTSIDE,
  SELECT_NEWS_OUTSIDE,
  OPEN_NEWS,
} from "./types";

import NewsService from "../services/NewsService";

export const newsList = data => async (dispatch) => {
  
  try {
    
    const res = await NewsService.newsGetAll(data);

    // console.log("res : ", res);

    dispatch({
      type: LIST_NEWS,
      payload: res.data.data,
    });

    return Promise.resolve(res.data.data);

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};

export const newsCreate = (data) => async (dispatch) => {
  
  try {
    
    const res = await NewsService.newsCreate(data);

    // console.log("res : ", res);

    dispatch({
      type: CREATE_NEWS,
      payload: res.data,
    });

    return Promise.resolve(res.data);

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};

export const newsModify = (data) => async (dispatch) => {
  
  try {
    
    const res = await NewsService.newsModify(data);

    // console.log("res : ", res);

    dispatch({
      type: MODIFY_NEWS,
      payload: res.data,
    });

    return Promise.resolve(res.data);

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};

export const newsDelete = (data) => async (dispatch) => {
  
  try {

    const res = await NewsService.newsDelete(data);

    // console.log("res : ", res);

    if (res.data.success === false)
    {
      alert(res.data.msg);
      return Promise.resolve(res.data);
    }
    else
    {
      dispatch({
        type: DELETE_NEWS,
        payload: res.data,
      });
  
      return Promise.resolve(res.data);
    }

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};

export const newsSelect = data => async (dispatch) => {
  
  try {
    
    const res = await NewsService.newsSelect(data);

    // console.log("res : ", res);

    dispatch({
      type: SELECT_NEWS,
      payload: res.data.data,
    });

    return Promise.resolve(res.data.data);

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};

export const newsOutsideList = data => async (dispatch) => {
  
  try {
    
    const res = await NewsService.newsOutsideGetAll(data);

    // console.log("res : ", res);

    dispatch({
      type: LIST_NEWS_OUTSIDE,
      payload: res.data.data,
    });

    return Promise.resolve(res.data.data);

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};

export const newsOutsideSelect = data => async (dispatch) => {
  
  try {
    
    const res = await NewsService.newsOutsideSelect(data);

    // console.log("res : ", res);

    dispatch({
      type: SELECT_NEWS_OUTSIDE,
      payload: res.data.data,
    });

    return Promise.resolve(res.data.data);

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};

export const newsOpen = (data) => async (dispatch) => {
  
  try {

    const res = await NewsService.newsOpen(data);

    // console.log("res : ", res);

    dispatch({
      type: OPEN_NEWS,
      payload: res.data,
    });

    return Promise.resolve(res.data);

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};

