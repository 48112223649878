// 인증 관련 액션 타입
export const LOGIN_SUCCESS_AUTH = "LOGIN_SUCCESS_AUTH";
export const LOGIN_FAIL_AUTH = "LOGIN_FAIL_AUTH";
export const REGISTER_SUCCESS_AUTH = "REGISTER_SUCCESS_AUTH";
export const REGISTER_FAIL_AUTH = "REGISTER_FAIL_AUTH";
export const GROUP_REGISTER_SUCCESS_AUTH = "GROUP_REGISTER_SUCCESS_AUTH";
export const GROUP_REGISTER_FAIL_AUTH = "GROUP_REGISTER_SUCCESS_AUTH";
export const LOGOUT_AUTH = "LOGOUT_AUTH";
export const PASSWORD_CHANGE_AUTH = "PASSWORD_CHANGE_AUTH";
export const LOGIN_RELOAD_AUTH = "LOGIN_RELOAD_AUTH";
export const PASSWORD_UPDATE_AUTH = "PASSWORD_UPDATE_AUTH";
export const EMAIL_RESEND_AUTH = "EMAIL_RESEND_AUTH"; // 인증 메일을 재발송합니다.
export const EMAIL_CHANGE_AUTH = "EMAIL_CHANGE_AUTH"; // 인증 메일을 변경합니다. (학부모의 이메일을 변경합니다.)

// 사용자 관련 액션 타입
export const CREATE_USERS = "CREATE_USERS";
export const CREATE_GROUP_USERS = "CREATE_GROUP_USERS";
export const UPDATE_USERS = "UPDATE_USERS";
export const UPDATE_GROUP_USERS = "UPDATE_GROUP_USERS";
export const DELETE_USERS = "DELETE_USERS";
export const LIST_USERS = "LIST_USERS";
export const ME_USERS = "ME_USERS";
export const PASSWORD_CHANGE_USERS = "PASSWORD_CHANGE_USERS";
export const SELECT_USERS = "SELECT_USERS";
export const SELECT_GROUP_USERS = "SELECT_GROUP_USERS";
export const ACTIVE_USERS = "ACTIVE_USERS";
export const MISSION_TOTAL_USERS = "MISSION_TOTAL_USERS";
export const MISSION_PROGRESS_USERS = "MISSION_PROGRESS_USERS";
export const KNOWLEDGE_ANSWER_USERS = "KNOWLEDGE_ANSWER_USERS";
export const TOTAL_HUMANABILITY_USERS = "TOTAL_HUMANABILITY_USERS";
export const HUMANABILITY_USERS = "HUMANABILITY_USERS";
export const IDCHECK_USERS = "IDCHECK_USERS";               // 아이디 중복확인
export const UPDATE_PHONE_USERS = "UPDATE_PHONE_USERS";
export const LEAVE_USERS = "LEAVE_USERS";                   // 회원탈퇴
export const UPDATE_STUDENT_USE = "UPDATE_STUDENT_USE";     // 학습자의 상태 정보 업데이트 (정지 및 사용)
export const GROUP_LIST_USERS = "GROUP_LIST_USERS";         // 그룹 사용자 전용
export const GROUP_SELECT_USERS = "GROUP_SELECT_USERS";     // 그룹 사용자 전용
export const GROUP_INFO_USERS = "GROUP_INFO_USERS";     // 그룹 사용자 전용
export const GROUP_INFO_USERS_CLASS = "GROUP_INFO_USERS_CLASS";     // 그룹 클래스 관리에서 사용함
export const LIST_CLASS_USERS = "LIST_CLASS_USERS";     // 그룹 클래스에 속한 학습자의 리스트

// 카테고리 관련 액션 타입
export const CREATE_CATEGORY = "CREATE_CATEGORY";
export const LIST_CATEGORY = "LIST_CATEGORY";
export const DELETE_CATEGORY = "DELETE_CATEGORY";
export const MODIFY_CATEGORY = "MODIFY_CATEGORY";
export const SELECT_CATEGORY = "SELECT_CATEGORY";

// 제품 관련 액션 타입
export const CREATE_PRODUCT = "CREATE_PRODUCT";
export const LIST_PRODUCT = "LIST_PRODUCT";
export const DELETE_PRODUCT = "DELETE_PRODUCT";
export const MODIFY_PRODUCT = "MODIFY_PRODUCT";
export const SELECT_PRODUCT = "SELECT_PRODUCT";
export const LIST_PRODUCT_GROUP = "LIST_PRODUCT_GROUP";     // 그룹 전용
export const LIST_PRODUCT_GROUP_OPTION1 = "LIST_PRODUCT_GROUP_OPTION1";     // 그룹 전용
export const LIST_PRODUCT_GROUP_OPTION2 = "LIST_PRODUCT_GROUP_OPTION2";     // 그룹 전용

// 주문 관련 액션 타입
export const CREATE_ORDER = "CREATE_ORDER";
export const LIST_ORDER = "LIST_ORDER";
export const CANCEL_ORDER = "CANCEL_ORDER";
export const MODIFY_ORDER = "MODIFY_ORDER";
export const SELECT_ORDER = "SELECT_ORDER";
export const USE_ORDER = "USE_ORDER";
export const LASTWEEK_ORDER = "LASTWEEK_ORDER";
export const VBANKCHECK_ORDER = "VBANKCHECK_ORDER";

// 결제 관련 액션 타입
export const CREATE_PAYMENT = "CREATE_PAYMENT";
export const LIST_PAYMENT = "LIST_PAYMENT";
export const CANCEL_PAYMENT = "CANCEL_PAYMENT";
export const VBANK_CANCEL_PAYMENT = "VBANK_CANCEL_PAYMENT";
export const SELECT_PAYMENT = "SELECT_PAYMENT";

// 환불 관련 액션 타입
export const LIST_REFUND = "LIST_REFUND";

// 학습자 관련 액션 타입
export const CREATE_STUDENT = "CREATE_STUDENT";
export const UPDATE_STUDENT = "UPDATE_STUDENT";
export const DELETE_STUDENT = "DELETE_STUDENT";
export const LIST_STUDENT = "LIST_STUDENT";
export const SELECT_STUDENT = "SELECT_STUDENT";
export const COUNT_STUDENT = "COUNT_STUDENT";       // 현재 결제가 되고 환불이 없는 실제 사용자의 갯수
export const IDCHECK_STUDENT = "IDCHECK_STUDENT";   // 아이디 중복확인
export const ADD_STUDENT = "ADD_STUDENT";
export const PAYINFO_STUDENT = "PAYINFO_STUDENT";
export const PACKAGEINFO_STUDENT = "PACKAGEINFO_STUDENT";
export const ADDS_STUDENT = "ADDS_STUDENT";

export const NEW_LIST_STUDENT = "NEW_LIST_STUDENT";                 // 새로운 학습자의 리스트 (칼럼 변경 후 리스트) 일반용
export const NEW_LIST_CLASS_STUDENT = "NEW_LIST_CLASS_STUDENT";     // 새로운 학습자의 리스트 (칼럼 변경 후 리스트) 단체용 - 페이징 기능추가 및 클래스 칼럼 추가됨
export const NEW_LIST_CLASS_ADMIN_STUDENT = "NEW_LIST_CLASS_ADMIN_STUDENT";     // 새로운 학습자의 리스트 (칼럼 변경 후 리스트) 관리자용 - 페이징 기능추가 및 클래스 칼럼 추가됨

// 패키지 관련 액션 타입
export const LIST_PACKAGE = "LIST_PACKAGE";
export const USE_STUDENT_PACKAGE = "USE_STUDENT_PACKAGE";
export const USE_NOT_STUDENT_PACKAGE = "USE_NOT_STUDENT_PACKAGE";
export const USE_UPDATE_PACKAGE = "USE_UPDATE_PACKAGE";
export const STUDENT_INFO_PACKAGE = "STUDENT_INFO_PACKAGE";
export const STUDENT_LIST_PACKAGE = "STUDENT_LIST_PACKAGE";

export const STUDENT_LIST_PACKAGEINFO = "STUDENT_LIST_PACKAGEINFO";
export const STUDENT_INFO_PACKAGEINFO = "STUDENT_INFO_PACKAGEINFO";
export const USE_UPDATE_PACKAGEINFO = "USE_UPDATE_PACKAGEINFO";


// 게시판 관련 액션 타입
export const CREATE_BBS = "CREATE_BBS";
export const CREATE_REPLY_BBS = "CREATE_REPLY_BBS";
export const LIST_BBS = "LIST_BBS";
export const DELETE_BBS = "DELETE_BBS";
export const MODIFY_BBS = "MODIFY_BBS";
export const SELECT_BBS = "SELECT_BBS";

// 댓글 관련 액션 타입
export const CREATE_COMMENT = "CREATE_COMMENT";
export const LIST_COMMENT = "LIST_COMMENT";
export const DELETE_COMMENT = "DELETE_COMMENT";
export const MODIFY_COMMENT = "MODIFY_COMMENT";

// 공지사항 관련 액션 타입
export const CREATE_NOTICE_BBS = "CREATE_NOTICE_BBS";
export const LIST_NOTICE_BBS = "LIST_NOTICE_BBS";
export const DELETE_NOTICE_BBS = "DELETE_NOTICE_BBS";
export const MODIFY_NOTICE_BBS = "MODIFY_NOTICE_BBS";
export const SELECT_NOTICE_BBS = "SELECT_NOTICE_BBS";

// 야미 공지사항 관련 액션 타입
export const CREATE_YAME_NOTICE_BBS = "CREATE_YAME_NOTICE_BBS";
export const LIST_YAME_NOTICE_BBS = "LIST_YAME_NOTICE_BBS";
export const DELETE_YAME_NOTICE_BBS = "DELETE_YAME_NOTICE_BBS";
export const MODIFY_YAME_NOTICE_BBS = "MODIFY_YAME_NOTICE_BBS";
export const SELECT_YAME_NOTICE_BBS = "SELECT_YAME_NOTICE_BBS";
export const OPEN_YAME_NOTICE_BBS = "OPEN_YAME_NOTICE_BBS";

// 미션 맵 카테고리 관련 액션 타입
export const CREATE_MISSION_CATEGORY = "CREATE_MISSION_CATEGORY";
export const LIST_MISSION_CATEGORY = "LIST_MISSION_CATEGORY";
export const DELETE_MISSION_CATEGORY = "DELETE_MISSION_CATEGORY";
export const MODIFY_MISSION_CATEGORY = "MODIFY_MISSION_CATEGORY";
export const SELECT_MISSION_CATEGORY = "SELECT_MISSION_CATEGORY";

// 미션 맵 관련 액션 타입
export const CREATE_MISSION = "CREATE_MISSION";
export const LIST_MISSION = "LIST_MISSION";
export const DELETE_MISSION = "DELETE_MISSION";
export const MODIFY_MISSION = "MODIFY_MISSION";
export const SELECT_MISSION = "SELECT_MISSION";
export const ORDER_CHANGE_MISSION = "ORDER_CHANGE_MISSION";

// 제품에 설정된 미션맵 액션 타입
export const REGISTER_PRODUCT_MISSION = "REGISTER_PRODUCT_MISSION";
export const USE_PRODUCT_MISSION = "USE_PRODUCT_MISSION";
export const USENOT_PRODUCT_MISSION = "USENOT_PRODUCT_MISSION";

// 지식은행 카테고리 관련 액션 타입
export const CREATE_KNOWLEDGE_CATEGORY = "CREATE_KNOWLEDGE_CATEGORY";
export const LIST_KNOWLEDGE_CATEGORY = "LIST_KNOWLEDGE_CATEGORY";
export const DELETE_KNOWLEDGE_CATEGORY = "DELETE_KNOWLEDGE_CATEGORY";
export const MODIFY_KNOWLEDGE_CATEGORY = "MODIFY_KNOWLEDGE_CATEGORY";
export const SELECT_KNOWLEDGE_CATEGORY = "SELECT_KNOWLEDGE_CATEGORY";

// 지식은행 관련 액션 타입
export const CREATE_KNOWLEDGE = "CREATE_KNOWLEDGE";
export const LIST_KNOWLEDGE = "LIST_KNOWLEDGE";
export const DELETE_KNOWLEDGE = "DELETE_KNOWLEDGE";
export const MODIFY_KNOWLEDGE = "MODIFY_KNOWLEDGE";
export const SELECT_KNOWLEDGE = "SELECT_KNOWLEDGE";

// 쿠폰 관련 액션 타입
export const LIST_COUPON = "LIST_COUPON";
export const CREATE_COUPON = "CREATE_COUPON";
export const MODIFY_COUPON = "MODIFY_COUPON";
export const DELETE_COUPON = "DELETE_COUPON";
export const SELECT_COUPON = "SELECT_COUPON";
export const EXCEL_DOWNLOAD_COUPON = "EXCEL_DOWNLOAD_COUPON";
export const DETAIL_LIST_COUPON = "DETAIL_LIST_COUPON";
export const MANUAL_ADD_COUPON = "MANUAL_ADD_COUPON";
export const REGISTER_COUPON = "REGISTER_COUPON";
export const USER_LIST_COUPON = "USER_LIST_COUPON";
export const USER_SELECT_COUPON = "USER_SELECT_COUPON";
export const USER_ALL_COUPON = "USER_ALL_COUPON";
export const REGISTER_BULK_COUPON = "REGISTER_BULK_COUPON";

// 쿠폰에 설정된 미션맵 액션 타입
export const REGISTER_COUPON_MISSION = "REGISTER_COUPON_MISSION";
export const USE_COUPON_MISSION = "USE_COUPON_MISSION";
export const USENOT_COUPON_MISSION = "USENOT_COUPON_MISSION";
export const COUPON_LIST_MISSION = "COUPON_LIST_MISSION";

// 문의하기 관련 액션 타입
export const LIST_SOLUTION = "LIST_SOLUTION";
export const CREATE_SOLUTION = "CREATE_SOLUTION";
export const MODIFY_SOLUTION = "MODIFY_SOLUTION";
export const REPLY_SOLUTION = "REPLY_SOLUTION";
export const DELETE_SOLUTION = "DELETE_SOLUTION";
export const SELECT_SOLUTION = "SELECT_SOLUTION";
export const LIST_SOLUTION_NONMEMBER = "LIST_SOLUTION_NONMEMBER";
export const CREATE_SOLUTION_NONMEMBER = "CREATE_SOLUTION_NONMEMBER";
export const MODIFY_SOLUTION_NONMEMBER = "MODIFY_SOLUTION_NONMEMBER";
export const DELETE_SOLUTION_NONMEMBER = "DELETE_SOLUTION_NONMEMBER";
export const SELECT_SOLUTION_NONMEMBER = "SELECT_SOLUTION_NONMEMBER";
export const CATEGORY_SOLUTION = "CATEGORY_SOLUTION";
export const CHECK_SOLUTION_NONMEMBER = "CHECK_SOLUTION_NONMEMBER";

// 새소식 관련 액션 타입
export const LIST_NEWS = "LIST_NEWS";
export const CREATE_NEWS = "CREATE_NEWS";
export const MODIFY_NEWS = "MODIFY_NEWS";
export const DELETE_NEWS = "DELETE_NEWS";
export const SELECT_NEWS = "SELECT_NEWS";
export const LIST_NEWS_OUTSIDE = "LIST_NEWS_OUTSIDE";
export const SELECT_NEWS_OUTSIDE = "SELECT_NEWS_OUTSIDE";
export const OPEN_NEWS = "OPEN_NEWS";

// 랭킹 관련 액션 타입
export const LIST_RANKING = "LIST_RANKING";
export const CREATE_RANKING = "CREATE_RANKING";
export const MODIFY_RANKING = "MODIFY_RANKING";
export const DELETE_RANKING = "DELETE_RANKING";
export const SELECT_RANKING = "SELECT_RANKING";

export const MAKE_RANKING = "MAKE_RANKING";

export const LIST_CHALLENGE_RANKING = "LIST_CHALLENGE_RANKING";
export const LIST_TOTAL_RANKING = "LIST_TOTAL_RANKING";

// 인앱 제품 관련 액션 타입
export const CREATE_INAPP_PRODUCT = "CREATE_INAPP_PRODUCT";
export const LIST_INAPP_PRODUCT = "LIST_INAPP_PRODUCT";
export const DELETE_INAPP_PRODUCT = "DELETE_INAPP_PRODUCT";
export const MODIFY_INAPP_PRODUCT = "MODIFY_INAPP_PRODUCT";
export const SELECT_INAPP_PRODUCT = "SELECT_INAPP_PRODUCT";

// 인앱 제품에 설정된 미션맵 액션 타입
export const REGISTER_INAPP_PRODUCT_MISSION = "REGISTER_INAPP_PRODUCT_MISSION";
export const USE_INAPP_PRODUCT_MISSION = "USE_INAPP_PRODUCT_MISSION";
export const USENOT_INAPP_PRODUCT_MISSION = "USENOT_INAPP_PRODUCT_MISSION";
export const INAPP_PRODUCT_LIST_MISSION = "INAPP_PRODUCT_LIST_MISSION";

// 인앱 결제 내역 액션 타입
export const LIST_INAPP_PAYMENT = "LIST_INAPP_PAYMENT";
export const SELECT_INAPP_PAYMENT = "SELECT_INAPP_PAYMENT";
export const REFUND_INAPP_PAYMENT = "REFUND_INAPP_PAYMENT";

// 클래스 관련 액션 타입
export const CREATE_CLASS = "CREATE_CLASS";
export const UPDATE_CLASS = "UPDATE_CLASS";
export const DELETE_CLASS = "DELETE_CLASS";
export const LIST_CLASS = "LIST_CLASS";
export const SELECT_CLASS = "SELECT_CLASS";
export const NAME_CHECK_CLASS = "NAME_CHECK_CLASS";                         // 클래스 이름 중복 체크
export const LIST_CLASS_ADMIN = "LIST_CLASS_ADMIN";                         // 클래스 어드민용
export const MANAGEMENT_LIST_CLASS = "MANAGEMENT_LIST_CLASS";               // 클래스 관리 리스트
export const MANAGEMENT_SET_CLASS = "MANAGEMENT_SET_CLASS";                 // 클래스 관리 설정
export const MANAGEMENT_STATUS_CLASS = "MANAGEMENT_STATUS_CLASS";           // 클래스 관리 on/off 조회
export const MANAGEMENT_STATUS_SET_CLASS = "MANAGEMENT_STATUS_SET_CLASS";   // 클래스 관리 on/off 설정
export const MANAGEMENT_SAVED_LIST_CLASS = "MANAGEMENT_SAVED_LIST_CLASS";   // 클래스 관리 리스트 (클래스에 적용된 미션의 리스트)
export const HOMEWORK_LIST_CLASS = "HOMEWORK_LIST_CLASS";                   // 숙제 관리 리스트
export const HOMEWORK_SELECT_CLASS = "HOMEWORK_SELECT_CLASS";               // 숙제 관리 개별선택(내용)
export const HOMEWORK_INIT_CLASS = "HOMEWORK_INIT_CLASS";                   // 숙제 관리 초기화
export const HOMEWORK_COMMENT_SELECT = "HOMEWORK_COMMENT_SELECT";           // 숙제 관리 코멘트 조회
export const HOMEWORK_COMMENT_INSERT = "HOMEWORK_COMMENT_INSERT";           // 숙제 관리 코멘트 데이터 넣기
export const HOMEWORK_COMMENT_DELETE = "HOMEWORK_COMMENT_DELETE";           // 숙제 관리 코멘트 데이터 삭제

// 단체 주문 관련 액션 타입
export const CREATE_GROUP_ORDER = "CREATE_GROUP_ORDER";
export const LIST_GROUP_ORDER = "LIST_GROUP_ORDER";
export const CANCEL_GROUP_ORDER = "CANCEL_GROUP_ORDER";
export const SELECT_GROUP_ORDER = "SELECT_GROUP_ORDER";

// 단체 결제 관련 액션 타입
export const CREATE_GROUP_PAYMENT = "CREATE_GROUP_PAYMENT";
export const UPDATE_GROUP_PAYMENT = "UPDATE_GROUP_PAYMENT";
export const STATUS_GROUP_PAYMENT = "STATUS_GROUP_PAYMENT";

// 대쉬보드 차트 관련 액션 타입
export const CLASS_AVG_STUDYTIME = "CLASS_AVG_STUDYTIME";
export const CLASS_LAST_LOGIN = "CLASS_LAST_LOGIN";
export const CLASS_STUDENT_COUNT = "CLASS_STUDENT_COUNT";
export const CLASS_AGE_DIV = "CLASS_AGE_DIV";
export const CLASS_AGE_RANGE = "CLASS_AGE_RANGE";
export const CLASS_LEARNING_TIME = "CLASS_LEARNING_TIME";
export const CLASS_MISSIONSTATE_PROGRESS = "CLASS_MISSIONSTATE_PROGRESS";
export const CLASS_MISSIONSTATE_PROGRESS_TOTAL = "CLASS_MISSIONSTATE_PROGRESS_TOTAL";
export const CLASS_TOTAL_MISSION = "CLASS_TOTAL_MISSION";
export const CLASS_MISSION_PROGRESS = "CLASS_MISSION_PROGRESS";
export const CLASS_HUMAN_ABILITY = "CLASS_HUMAN_ABILITY";





