import http from "../http-common";

const orderCreate = data => {
  return http.post("payment/order", data);
};

const orderGetAll = () => {
  return http.get("payment/order");
};

const orderCancel = data => {
  return http.put("payment/order/cancel", {order_idx : data});
};

const orderModify = data => {
  return http.put("payment/order", data);
};

const orderSelect = data => {
  return http.get(`payment/order/${data}`);
};

const orderUse = data => {
  return http.get(`payment/order/use/${data}`);
};

const orderLastweek = data => {
  return http.get(`payment/order/lastweek/${data}`);
};

const orderVbankcheck = () => {
  return http.get(`payment/order/vbank/check`);
};

const OrderService = {
  orderCreate,
  orderGetAll,
  orderCancel,
  orderModify,
  orderSelect,
  orderUse,
  orderLastweek,
  orderVbankcheck,
};

export default OrderService;
