import http from "../http-common";

const knowledgeCategoryCreate = data => {
  return http.post("knowledge/category", data);
};

const knowledgeCategoryGetAll = () => {
  return http.get("knowledge/category");
};

const knowledgeCategoryDelete = data => {
  return http.delete("knowledge/category", {data : {category_idx : data}});
};

const knowledgeCategoryModify = data => {
  return http.put("knowledge/category", data);
};

const knowledgeCategorySelect = data => {
  return http.get(`knowledge/category/${data}`);
};

const KnowledgeCategoryService = {
  knowledgeCategoryCreate,
  knowledgeCategoryGetAll,
  knowledgeCategoryDelete,
  knowledgeCategoryModify,
  knowledgeCategorySelect,
};

export default KnowledgeCategoryService;
