import {
  REGISTER_COUPON_MISSION,
  USE_COUPON_MISSION,
  USENOT_COUPON_MISSION,
  COUPON_LIST_MISSION,
} from "./types";

import CouponMissionService from "../services/CouponMissionService";

export const couponMissionCreate = (data) => async (dispatch) => {
  
  try {
    
    const res = await CouponMissionService.couponMissionCreate(data);

    // console.log("res : ", res);

    dispatch({
      type: REGISTER_COUPON_MISSION,
      payload: res.data,
    });

    return Promise.resolve(res.data);

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};

export const couponMissionUse = (data) => async (dispatch) => {
  
  try {
    
    const res = await CouponMissionService.couponMissionUseGet(data);

    // console.log("res : ", res);

    dispatch({
      type: USE_COUPON_MISSION,
      payload: res.data.data,
    });

    return Promise.resolve(res.data.data);

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};

export const couponMissionUseNot = (data) => async (dispatch) => {
  
  try {
    
    const res = await CouponMissionService.couponMissionUseNotGet(data);

    // console.log("res : ", res);

    dispatch({
      type: USENOT_COUPON_MISSION,
      payload: res.data.data,
    });

    return Promise.resolve(res.data.data);

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};

export const couponMissionList = (data) => async (dispatch) => {
  
  try {
    
    const res = await CouponMissionService.couponMissionList(data);

    // console.log("res : ", res);

    dispatch({
      type: COUPON_LIST_MISSION,
      payload: res.data.data,
    });

    return Promise.resolve(res.data.data);

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};