import {
  REGISTER_PRODUCT_MISSION,
  USE_PRODUCT_MISSION,
  USENOT_PRODUCT_MISSION,
} from "./types";

import ProductMissionService from "../services/ProductMissionService";

export const productMissionCreate = (data) => async (dispatch) => {
  
  try {
    
    const res = await ProductMissionService.productMissionCreate(data);

    // console.log("res : ", res);

    dispatch({
      type: REGISTER_PRODUCT_MISSION,
      payload: res.data,
    });

    return Promise.resolve(res.data);

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};

export const productMissionUse = (data) => async (dispatch) => {
  
  try {
    
    const res = await ProductMissionService.productMissionUseGet(data);

    // console.log("res : ", res);

    dispatch({
      type: USE_PRODUCT_MISSION,
      payload: res.data.data,
    });

    return Promise.resolve(res.data.data);

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};

export const productMissionUseNot = (data) => async (dispatch) => {
  
  try {
    
    const res = await ProductMissionService.productMissionUseNotGet(data);

    // console.log("res : ", res);

    dispatch({
      type: USENOT_PRODUCT_MISSION,
      payload: res.data.data,
    });

    return Promise.resolve(res.data.data);

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};