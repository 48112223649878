import * as React from 'react';
import { useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import styles from "../css/common.module.css";
import $ from 'jquery';

import { newsOutsideSelect } from "../actions/news";
import { useNavigate, useParams } from 'react-router-dom';

import * as common from "../lib";

import { useTranslation, Trans } from 'react-i18next'; // 1. react-i18next import 

export default function News() {

	const authState = useSelector(state => state.auth);
    // console.log("authState : ", authState);

    const news_select = useSelector(state => state.news?.select?.select);
    // console.log("news_select : ", news_select);

    const idx  = useParams().idx;
    // console.log("idx : ", idx);
    let prevPage  = useParams().prevPage;
    // console.log("prevPage : ", prevPage);

    const { t, i18n } = useTranslation(); // 3. useTranslation hook 선언
  
    if (prevPage === undefined)
        prevPage = 1;

	const dispatch = useDispatch();	
    const navigate = useNavigate();

	useEffect(() => {

		// 여기에서 jQuery를 초기화 합니다.
		$(document).ready(function(){

			// console.log("jquery ready!");

		});

        // 여기서 디스패치로~
        dispatch(newsOutsideSelect(idx))
        .then(data => 
        {
        //   console.log("data : ", data);
        })
        .catch(e => {
          console.log(e);
        });

        return () => {      // 언마운트
            // console.log("언마운트!");
        };

	}, [$, dispatch]);

  return (
    <>
      	{/* Header 분리됨 MainLayout Header에 있음 */}

		<div className={styles.news_more_content_area}>
			<div className={styles.news_top_line_banner}>
				<div className={styles.inner}>
					<span>{/* 야미 새소식 */}{t("News.title")}</span>
				</div>
			</div>
			<div className={styles.news_more_content_wrap}>
                <div className={styles.inner}>
                    <div className={styles.news_more_content_article_area}>
                        <span className={styles.title}>{news_select?.title || ""}</span>
                        <div className={styles.article_info}>
                            <p>{news_select?.reg_date || ""}</p>
                            <span>by admin</span>
                        </div>
                        <div className={styles.article_content}>
                            {/* <img src="assets/3d_img1@2x.png" alt="썸네일"/> */}
                            <img src={common.returnImgSrc(news_select?.photo_path, news_select?.photo_file)} alt="썸네일"/>
                            <p>
                                {news_select?.contents || ""}
                            </p>
                            <br></br>
                            {
                                news_select?.link !== "" ? <p>{/* 원본 링크 */}{t("News.string1")} : <a href={news_select?.link} target='_blank'>{news_select?.link}</a></p> : <></>
                            }
                        </div>
                    </div>
                    <a href="/News" className={styles.bact_to_list}>{/* 목록 */}{t("Common.List")}</a>
                </div>
            </div>
		</div>{/* //news_area */}
		
		{/* Footer 분리됨 MainLayout Footer에 있음 */}
    </>
  );
}