import * as React from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';

import { useTranslation, Trans } from 'react-i18next'; // 1. react-i18next import 

const options = ['korea', 'english'];
const arrayFlag = ['/assets/Korea-flag.png', '/assets/United-states-flag.png'];

export default function SplitButton() {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const { t, i18n } = useTranslation(); // 3. useTranslation hook 선언 

  let language = localStorage.getItem("language");
  let itemNumber = 0;

  switch (language) {
    case "ko":
        itemNumber = 0;
        break;
    case "en":
        itemNumber = 1;
        break;
    default:
        itemNumber = 0;
  }

  const [selectedIndex, setSelectedIndex] = React.useState(itemNumber);

  const handleClick = () => {
    console.info(`You clicked ${options[selectedIndex]}`);
  };

  const handleMenuItemClick = (event, index) => {

    let setLanguage = "";

    switch (index) {
        case 0:
            setLanguage = "ko";
            break;
        case 1:
            setLanguage = "en";
            break;
        default:
            setLanguage = "ko";
      }

    // 메타 태그 언어 변경
    // const elementToChangeLang = document.documentElement;
		// elementToChangeLang.setAttribute('lang', 'en');

    i18n.changeLanguage(setLanguage)    // 언어 설정 변경
    localStorage.setItem("language", setLanguage);
    
    window.location.reload();

    setSelectedIndex(index);
    console.log(`You clicked ${index}`);

    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  return (
    <React.Fragment>
      <ButtonGroup variant="contained" ref={anchorRef} aria-label="split button">
        <Button onClick={handleClick}>{options[selectedIndex]}</Button>
        <Button
          size="small"
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          onClick={handleToggle}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper
        sx={{
          zIndex: 1,
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem sx={selectedIndex === 0 ? {width: 132, background: '#58a2e3'} : {width: 148, background: '#58a2e3'}}>
                  {options.map((option, index) => (
                    <MenuItem
                      key={option}
                      disabled={index === 2}
                      selected={index === selectedIndex}
                      onClick={(event) => handleMenuItemClick(event, index)}
                    >
                      {/* {option} <img src='/assets/Korea-flag.png' height={25} /> */}
                      <div style={{ display: 'flex', alignItems: 'center'}}>
                          <img src={arrayFlag[index]} height={25} />&nbsp;&nbsp;
                        <div style={{ color: '#FFFFFF'}}>{option}</div>
                      </div>
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </React.Fragment>
  );
}