import {
  CREATE_MISSION_CATEGORY,
  LIST_MISSION_CATEGORY,
  DELETE_MISSION_CATEGORY,
  MODIFY_MISSION_CATEGORY,
  SELECT_MISSION_CATEGORY,
} from "./types";

import MissionCategoryService from "../services/MissionCategoryService";

export const missionCategoryCreate = (category_name, category_desc) => async (dispatch) => {
  
  try {
    
    const res = await MissionCategoryService.missionCategoryCreate({ category_name, category_desc });

    // console.log("res : ", res);

    dispatch({
      type: CREATE_MISSION_CATEGORY,
      payload: res.data,
    });

    return Promise.resolve(res.data);

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};

export const missionCategoryList = () => async (dispatch) => {
  
  try {
    
    const res = await MissionCategoryService.missionCategoryGetAll();

    // console.log("res : ", res);

    dispatch({
      type: LIST_MISSION_CATEGORY,
      payload: res.data.data,
    });

    return Promise.resolve(res.data.data);

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};

export const missionCategorySelect = (category_idx) => async (dispatch) => {
  
  try {
    
    const res = await MissionCategoryService.missionCategorySelect(category_idx);

    // console.log("res : ", res);

    dispatch({
      type: SELECT_MISSION_CATEGORY,
      payload: res.data.data,
    });

    return Promise.resolve(res.data.data);

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};

export const missionCategoryDelete = (category_idx) => async (dispatch) => {
  
  try {

    // console.log("missionCategoryDelete! category_idx : ", category_idx);

    const res = await MissionCategoryService.missionCategoryDelete(category_idx);

    // console.log("res : ", res);

    if (res.data.success === false)
    {
      alert(res.data.msg);
      return Promise.resolve(category_idx);
    }
    else
    {
      dispatch({
        type: DELETE_MISSION_CATEGORY,
        payload: { category_idx },
      });
  
      return Promise.resolve(res.data.data);
    }

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};

export const missionCategoryModify = (category_idx, category_name, category_desc) => async (dispatch) => {
  
  try {
    
    const res = await MissionCategoryService.missionCategoryModify({ category_idx, category_name, category_desc });

    // console.log("res : ", res);

    dispatch({
      type: MODIFY_MISSION_CATEGORY,
      payload: res.data,
    });

    return Promise.resolve(res.data);

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};
