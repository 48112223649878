import * as React from 'react';
import { useState } from 'react';
import Button from '@mui/material/Button';

export default function ImgFileUpload(props) {

const initialImgFileState = {
    detailImageFile: null,
    detailImageUrl: null
};

const [imgFile, setImgFile] = useState(initialImgFileState);

    // set file reader function
const setImageFromFile = ({ file, setImageUrl }) => 
{
    let reader = new FileReader();

    reader.onload = function () 
    {
       setImageUrl({ result: reader.result });
    };

    reader.readAsDataURL(file);
 };

 const imageDelete = () => {

  // input file의 값을 초기화 합니다.
  let files = document.getElementsByName(props.FileName);
  // console.log("files : ", files);
  files[0].value = "";

  // 미리보기 이미지도 초기화 합니다.
  setImgFile(null, null);

  props.handleImgFileDelete(files);
    
 }

  return (
    <>
      <input
          type="file"
          name={props.FileName}
          id="detail_doc"
          //accept="image/*"
          accept=".jpg, .png, .pdf"
          onChange={({ target }) => 
          {
              if (target.files.length) 
              {
                console.log(target.files);
                console.log(target.value);

                if (target.files && target.files[0].size > (5 * 1024 * 1024)) 
                {
                  target.value = null;
                  setImgFile(null, null);
                  alert("파일 사이즈가 5mb 를 넘습니다.");
                }
                else    // 사이즈가 5M가 넘지 않으면....
                {
                  setImageFromFile
                  (
                    { 
                        file: target.files[0], 
                        setImageUrl: ({ result }) => setImgFile({detailImageFile: target.files[0], detailImageUrl: result}) 
                    }
                  );
  
                  props.handleImgFileChange(target.files[0]);
                }
              }
          }}
      /> <br></br>
      {imgFile?.detailImageFile && (
        <div>
          파일명 : {imgFile?.detailImageFile.name} {/*<img src={imgFile?.detailImageUrl} alt={imgFile?.detailImageFile.name} width={props.width} /> */} &nbsp;
          <Button onClick={imageDelete}>파일 삭제</Button>
        </div>
      )}
    </>
  );
}