import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import Divider from '@mui/material/Divider';

import { packageInfoList, packageInfoUseStudentModify } from "../../actions/package";
import { newClassStudentList } from "../../actions/student";

import { useDispatch } from "react-redux";

import { useTranslation, Trans } from 'react-i18next'; // 1. react-i18next import 

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function StudentLicenseChangeDialog(props) {
  const [open, setOpen] = React.useState(false);

  const dispatch = useDispatch();

  const { t, i18n } = useTranslation(); // 3. useTranslation hook 선언

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);

	// 이 코드가 없으면 에러가 납니다. 이 코드를 앞에 팝업창이 있는지 체크하는 것입니다.
	if (props.popupCheck === true)
	{
		props.handleClose();
	}
  };

  const handleSubmit = (event, data) => {

	event.preventDefault();
	
	// 이용권 사용에 대한 셋팅을 해줍니다.

	const SendJaon = {
		mst_idx: props.RowData.mst_idx,
		package_no: data.mm_idx,
	}

	// console.log("SendJaon : ", SendJaon);

	// 여기서 디스패치로~
	dispatch(packageInfoUseStudentModify(SendJaon))
	.then(data => 
	{
		// console.log("packageUseStudentModify data : ", data);

		const boardListSend = {
			board_page : props.currentPage,
			mc_idx: props.mcIdx,
		}

		// console.log("boardListSend : ", boardListSend);
	  
		// 패키지의 정보를 다시 불러옵니다.
		dispatch(packageInfoList())
		.then(data => 
		{
			// console.log("packageInfoList data : ", data);
			// 학습자 리스트를 다시 불러옵니다.
			dispatch(newClassStudentList(boardListSend))
			.then(data => 
			{
				// console.log("classListAll : ", data);

				handleClose();

				if (props.popupCheck === true)
				{
					props.handleClose();
				}
				
			})
			.catch(e => {
				console.log(e);
			});
		})
		.catch(e => {
			console.log(e);
		});
	})
	.catch(e => {
		console.log(e);
	});

	// setOpen(false);
};

  return (
    <div>
      <Button variant="outlined" size="small" onClick={handleClickOpen}>{/* 추가 */}{t('ClassStudentCommonDlg.string14')}</Button>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
			{/* 이용권 선택 */}{t('ClassStudentCommonDlg.string16')}
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>
		  	{/* * 구매하신 상품을 선택해 주세요. */}{t('ClassStudentCommonDlg.string17')}
          </Typography>
        </DialogContent>
		
		<Box
		component="form"
		sx={{
			display: 'flex',
    		alignItems: 'center',
			'& .MuiTextField-root': { m: 1, width: '350px', marginTop: '20px', marginLeft: '20px', marginRight: '5px'},
			'& .MuiButton-root': { m: 1, marginLeft: '10px', marginRight: '10px'},
		}}
		noValidate
		autoComplete="off"
		>
			<Paper sx={{ width: '100%', overflow: 'hidden' }}>
				<TableContainer sx={{ maxHeight: 440 }}>
					<Table stickyHeader aria-label="sticky table" size="small">
					<TableHead>
						<TableRow>
						<TableCell align="center">{/* 번호 */}{t('ClassStudentCommonDlg.string18')}</TableCell>
						<TableCell align="center">{/* 이름 */}{t('ClassStudentCommonDlg.string19')}</TableCell>
						<TableCell align="center">{/* 종류 */}{t('ClassStudentCommonDlg.string20')}</TableCell>
						<TableCell align="center">{/* 선택 */}{t('ClassStudentCommonDlg.string21')}</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
					{
						props.myPackageList?.filter(row => row?.mm_state === "W", row => row?.mm_state_cd === "A")?.sort((a, b) => b.mm_idx - a.mm_idx).length !== 0 
						? 
						props.myPackageList && props.myPackageList?.filter(row => row?.mm_state === "W", row => row?.mm_state_cd === "A")
						.map((row, index) => {
							return (
							<TableRow hover role="checkbox" tabIndex={-1} key={index}>
								<TableCell component="th" scope="row" align="center">
								{index+1}
								</TableCell>
								<TableCell align="center">{row.mm_name} ({row.expiration_date}{row.expiration_date_unit})</TableCell>
								<TableCell align="center">
								{
									{
										// PG : `유료결제`,
										// COUPON : `쿠폰`,
										// INAPP : `인앱`,
										// Manual : `단체 상품`,
										PG : t('ClassStudentCommonDlg.string22'),
										COUPON : t('ClassStudentCommonDlg.string23'),
										INAPP : t('ClassStudentCommonDlg.string24'),
										INAPP : t('ClassStudentCommonDlg.string24'),
										Manual : t('ClassStudentCommonDlg.string42'),
									} [row.mm_type]
								}
								</TableCell>
								<TableCell align="center">
									<Button onClick={(e)=> handleSubmit(e, row)}>{/* 선택 */}{t('ClassStudentCommonDlg.string21')}</Button>
								</TableCell>
							</TableRow>
							);
						})
						:
						<TableRow>
							<TableCell component="th" scope="row" align="center" colSpan="4">{/* 데이터 없음 */}{t('Common.No_data')}</TableCell>
						</TableRow>
						}
					</TableBody>
					</Table>
				</TableContainer>
				
			</Paper>
		</Box>

		<Divider />
		
        <DialogActions>
		  <Button autoFocus onClick={handleClose}>
            {/* 취소 */}{t('Common.cancel')}
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}