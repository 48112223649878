import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';

import Box from '@mui/material/Box';
import Input from '@mui/material/Input';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import Link from '@mui/material/Link';
import FormControl from '@mui/material/FormControl';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormLabel from '@mui/material/FormLabel';
import FormControlLabel from '@mui/material/FormControlLabel';

import ImgFileUpload from '../../../common/ImgFileUpload';

import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";

import { newsModify, newsSelect } from "../../../actions/news";

import { useNavigate, useParams } from 'react-router-dom';

import * as common from "../../../lib";

const ariaLabel = { 'aria-label': 'description' };

export default function NewsModify() {

    const idx  = useParams().idx;
    console.log("idx : ", idx);
    const prevPage  = useParams().prevPage;
    console.log("prevPage : ", prevPage);

    const [link, setLink] = useState("");
    const [contents, setContents] = useState("");
    const [open, setOpen] = useState(1);
    const [file, setFile] = useState(null);
    const [title, setTitle] = useState(null);
    const [fileName, setFileName] = useState(null);
    const [filePath, setFilePath] = useState(null);
    const [fileChange, setFileChange] = useState(false);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    // 리듀서 값들 저장
    const userInfo = useSelector(state => state.auth);
    console.log("userInfo : ", userInfo);

    const onLinkChange = (event) => {
        setLink(event.target.value);
    }

    const onTitleChange = (event) => {
        setTitle(event.target.value);
    }
    
    const onContentsChange = (event) => {
        setContents(event.target.value);
    }

    const onOpenChange = (event) => {
        setOpen(event.target.value);
    }

    const handleImgFileChange = (file) => {
        // console.log("file : ", file);
        setFile(file);
        setFileChange(true);
    };
    
    const onModifySubmit = (event) => {
    
        event.preventDefault();
        
        if(userInfo.isLoggedIn)
        {
    
            const formData = new FormData();
            formData.append('imgFile', file);
            formData.append('title', title);
            formData.append('contents', contents);
            formData.append('link', link);
            formData.append('open', open);
            formData.append('idx', idx);
    
            if (title === "")
            {
                alert("제목이 없습니다. 작성해주세요.");
                return;
            }
        
            // 여기서 디스패치로~
            dispatch(newsModify(formData))
            .then(data => 
            {
                console.log("newsModify data : ", data);
                if (!data.success)
                {
                    alert(data.msg);
                    return;
                }
                else
                {
                    navigate(`/dashboard/NewsList/${prevPage}`);
                }
                    
    
            })
            .catch(e => {
                console.log("newsModify error : ", e);
                console.log(e);
            });
        }
        else
        {
            alert("로그인 후에 수정을 할 수 있습니다.");
            return;
        }
    }

    useEffect(() => {

        // 여기서 디스패치로~
        dispatch(newsSelect(idx))
        .then(data => 
        {
            console.log("newsSelect data : ", data);

            setLink(data.select.link);
            setContents(data.select.contents);
            setOpen(data.select.open);
            setTitle(data.select.title);
            setFileName(data.select.photo_file);
            setFilePath(data.select.photo_path);
        })
        .catch(e => {
            console.log("newsSelect error : ", e);
            console.log(e);
        });

    }, [dispatch])


    const imageDelete = () => {

        // input file의 값을 초기화 합니다.
        let files = document.getElementsByName("imgFile");
        console.log("files : ", files);
        files[0].value = "";
      
        // 미리보기 이미지도 초기화 합니다.
        setFileName(null);
        setFilePath(null);
        setFileChange(true);
    }

  return (
    <Card sx={{ minWidth: 275 }}>
        <CardContent>
        <Box
            sx={{
                '& > :not(style)': { m: 1, width: '400px' },
            }}
            noValidate
            autoComplete="off"
            >
            야미 새소식 수정
        </Box>

        <Box
            sx={{
                '& > :not(style)': { m: 1, width: '400px' },
            }}
            noValidate
            autoComplete="off"
            >
            <FormControl>
            <Input 
                placeholder="제목" 
                id="title"
                name="title" 
                inputProps={ariaLabel} 
                onChange={onTitleChange} 
                value={title || ""}
            />
            </FormControl>
        </Box>
        
            <Box
            sx={{
                '& > :not(style)': { m: 1, width: '400px' },
            }}
            noValidate
            autoComplete="off"
            >
            <FormControl>
            <TextareaAutosize
                aria-label="minimum height"
                minRows={10}
                placeholder="공지 내용"
                style={{ width: 400 }}
                onChange={onContentsChange}
                id="contents" 
                name="contents"
                value={contents || ""}
            />
            </FormControl>
            </Box>
            <Box
            sx={{
                '& > :not(style)': { m: 1, width: '400px' },
            }}
            noValidate
            autoComplete="off"
            >
            <FormControl>
            <Input 
                placeholder="링크" 
                id="link"
                name="link" 
                inputProps={ariaLabel} 
                onChange={onLinkChange} 
                value={link || ""}
            />
            </FormControl>
            </Box>
            <Box
            sx={{
                '& > :not(style)': { m: 1, width: '400px' },
            }}
            noValidate
            autoComplete="off"
            >
                <FormControl>
                <FormLabel id="open">공개여부</FormLabel>
                <RadioGroup
                    row
                    aria-labelledby="open"
                    defaultValue="true"
                    name="open"
                    onChange={onOpenChange}
                    value={open || 0}
                >
                    <FormControlLabel value={1} control={<Radio />} label="공개" />
                    <FormControlLabel value={0} control={<Radio />} label="비공개" />
                </RadioGroup>
                </FormControl>
            </Box>
            <ImgFileUpload handleImgFileChange={handleImgFileChange} width="400" />
            {fileChange ? "" : <><img src={common.returnImgSrc(filePath, fileName)} width="400"/> <Button onClick={imageDelete}>이미지 삭제</Button></> }
        </CardContent>
        <CardActions>
            <Button onClick={onModifySubmit} size="small">수정</Button>
            <Link href={`/dashboard/NewsList/${prevPage}`} underline="none">목록</Link>
        </CardActions>
    </Card>
  );
}