import {
  CREATE_STUDENT,
  UPDATE_STUDENT,
  DELETE_STUDENT,
  LIST_STUDENT,
  SELECT_STUDENT,
  COUNT_STUDENT,
  IDCHECK_STUDENT,
  ADD_STUDENT,
  PAYINFO_STUDENT,
  NEW_LIST_STUDENT,
  PACKAGEINFO_STUDENT,
  NEW_LIST_CLASS_STUDENT,
  NEW_LIST_CLASS_ADMIN_STUDENT,
  ADDS_STUDENT,
} from "../actions/types";

const initialState = [];

const studentReducer = (student = initialState, action) => {
  const { type, payload } = action;

  // console.log("studentReducer Call!");
  // console.log("type : ", type);
  // console.log("payload : ", payload);

  switch (type) {
    
    case CREATE_STUDENT:
      return [];

    case LIST_STUDENT:
      return {
        ...student,
        list : payload,
      };

    case SELECT_STUDENT:
      return {
        ...student,
        select : payload,
      };

    case UPDATE_STUDENT:
      return payload;

    case DELETE_STUDENT:
      return [];

    case COUNT_STUDENT:
      return {
        ...student,
        count : payload,
      };

    case IDCHECK_STUDENT:
      return {
        ...student,
        checkid : payload,
      };

    case ADD_STUDENT:
      return [];

    case PAYINFO_STUDENT:
      return {
        ...student,
        payinfo : payload,
      };

    case NEW_LIST_STUDENT:
      return {
        ...student,
        newList : payload,
      };

    case PACKAGEINFO_STUDENT:
      return {
        ...student,
        packageinfo : payload,
      };

    case NEW_LIST_CLASS_STUDENT:
      return {
        ...student,
        newListList : payload.list,
        newListPaging : payload.paging,
      };

    case NEW_LIST_CLASS_ADMIN_STUDENT:
      return {
        ...student,
        newListList : payload.list,
        newListPaging : payload.paging,
      };

    case ADDS_STUDENT:
      return [];

    default:
      return student;
  }
};

export default studentReducer;