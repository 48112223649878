import {
  CREATE_CATEGORY,
  LIST_CATEGORY,
  DELETE_CATEGORY,
  MODIFY_CATEGORY,
  SELECT_CATEGORY,
} from "./types";

import CategoryService from "../services/CategoryService";

export const categoryCreate = (data) => async (dispatch) => {
  
  try {
    
    const res = await CategoryService.categoryCreate(data);

    // console.log("res : ", res);

    dispatch({
      type: CREATE_CATEGORY,
      payload: res.data,
    });

    return Promise.resolve(res.data);

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};

export const categoryList = () => async (dispatch) => {
  
  try {
    
    const res = await CategoryService.categoryGetAll();

    // console.log("res : ", res);

    dispatch({
      type: LIST_CATEGORY,
      payload: res.data.data,
    });

    return Promise.resolve(res.data.data);

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};

export const categorySelect = (category_idx) => async (dispatch) => {
  
  try {
    
    const res = await CategoryService.categorySelect(category_idx);

    // console.log("res : ", res);

    dispatch({
      type: SELECT_CATEGORY,
      payload: res.data.data,
    });

    return Promise.resolve(res.data.data);

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};

export const categoryDelete = (category_idx) => async (dispatch) => {
  
  try {

    // console.log("categoryDelete! category_idx : ", category_idx);

    const res = await CategoryService.categoryDelete(category_idx);

    // console.log("res : ", res);

    if (res.data.success === false)
    {
      alert(res.data.msg);
      return Promise.resolve(category_idx);
    }
    else
    {
      dispatch({
        type: DELETE_CATEGORY,
        payload: { category_idx },
      });
  
      return Promise.resolve(res.data.data);
    }

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};

export const categoryModify = (data) => async (dispatch) => {
  
  try {
    
    const res = await CategoryService.categoryModify(data);

    // console.log("res : ", res);

    dispatch({
      type: MODIFY_CATEGORY,
      payload: res.data,
    });

    return Promise.resolve(res.data);

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};
