import * as React from 'react';
import styles from "../../css/common.module.css";

import Grid from '@mui/material/Grid';
import { useState } from "react";
import { useDispatch } from "react-redux";
import { passwordChange } from "../../actions/auth";
import { useNavigate } from 'react-router-dom';

import { useTranslation, Trans } from 'react-i18next'; // 1. react-i18next import 

export default function EmailChange() {

    const initialAuthState = {
        email: ""
    };

    const [auth, setAuth] = useState(initialAuthState);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { t, i18n } = useTranslation(); // 3. useTranslation hook 선언

    const handleInputChange = event => {
        const { name, value } = event.target;
        setAuth({ ...auth, [name]: value });
    };

    return (
        <Grid container component={styles.main} sx={{ height: '100vh' }}>
            <div className={`${styles.login_bg_wrapper} ${styles.email_bg_wrapper}`}>
                <div className={`${styles.email_reset_area} ${styles.middle}`}>
                    <div className={styles.login_top}>
                        <a href="/" className={styles.logo}><img src="/assets/logo@2x.png" alt="logo"/></a>
                    </div>
                    <span className={styles.login_title}>{/* 이메일 문제 해결 */}<Trans i18nKey="Auth.email_change_string4"/></span>
                    <div className={`${styles.email_reset_wrap} ${styles.center_align}`}>
                        <p>{/* 인증 메일이 도착하지 않았다면 재발송을 눌러주세요. <br/>가입이 되어 있고 인증이 되지 않았다면 메일이 발송됩니다. */}
                        <Trans i18nKey="Auth.email_change_string5"/></p>
                        <a href="/auth/EmailResend" className={styles.ok}>{/* 인증 메일 재발송 */}<Trans i18nKey="Auth.email_change_string3"/></a>
                    </div>
                    <div className={`${styles.email_reset_wrap} ${styles.center_align}`}>
                        <p>{/* 가입한 메일에 문제가 있다면 변경하세요.  <br/>이메일을 변경해야 한다면 가입할 때 패스워드가 필요합니다. */}
                        <Trans i18nKey="Auth.email_change_string6"/></p>
                        <a href="/auth/EmailChangeSub" className={styles.ok}>{/* 이메일 변경 */}<Trans i18nKey="Auth.email_change_string7"/></a>
                    </div>
                </div>
            </div>
        </Grid>
    );
}