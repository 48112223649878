import {
  CREATE_STUDENT,
  UPDATE_STUDENT,
  DELETE_STUDENT,
  LIST_STUDENT,
  SELECT_STUDENT,
  COUNT_STUDENT,
  IDCHECK_STUDENT,
  ADD_STUDENT,
  PAYINFO_STUDENT,
  NEW_LIST_STUDENT,
  PACKAGEINFO_STUDENT,
  NEW_LIST_CLASS_STUDENT,
  NEW_LIST_CLASS_ADMIN_STUDENT,
  ADDS_STUDENT,
} from "./types";

import StudentService from "../services/StudentService";

export const studentCreate = (data) => async (dispatch) => {
  
  try {
    
    const res = await StudentService.studentCreate(data);

    // console.log("res : ", res);

    dispatch({
      type: CREATE_STUDENT,
      payload: res.data,
    });

    return Promise.resolve(res.data);

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};

export const studentList = () => async (dispatch) => {
  
  try {
    
    const res = await StudentService.studentGetAll();

    // console.log("res : ", res);

    dispatch({
      type: LIST_STUDENT,
      payload: res.data.data,
    });

    return Promise.resolve(res.data.data);

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};

export const studentSelect = (mst_idx) => async (dispatch) => {
  
  try {
    
    const res = await StudentService.studentSelect(mst_idx);

    // console.log("res : ", res);

    dispatch({
      type: SELECT_STUDENT,
      payload: res.data.data,
    });

    return Promise.resolve(res.data.data);

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};

export const studentDelete = (mst_idx) => async (dispatch) => {
  
  try {

    // console.log("studentDelete! mst_idx : ", mst_idx);

    const res = await StudentService.studentDelete(mst_idx);

    // console.log("res : ", res);

    dispatch({
      type: DELETE_STUDENT,
      payload: res.data,
    });
  
    return Promise.resolve(res.data);

  } catch (err) {
    // console.log("Error: ", err);
    return Promise.reject(err.response);
  }

};

export const studentModify = (data) => async (dispatch) => {
  
  try {
    
    const res = await StudentService.studentModify(data);

    // console.log("res : ", res);

    dispatch({
      type: UPDATE_STUDENT,
      payload: res.data,
    });

    return Promise.resolve(res.data);

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};

export const studentCount = () => async (dispatch) => {
  
  try {

    const res = await StudentService.studentGetCount();

    // console.log("res : ", res);

    dispatch({
      type: COUNT_STUDENT,
      payload: res.data,
    });

    return Promise.resolve(res.data);

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};

export const studentIdCheck = (data) => async (dispatch) => {
  
  try {
    
    const res = await StudentService.studentIdCheck(data);

    // console.log("res : ", res);

    dispatch({
      type: IDCHECK_STUDENT,
      payload: res.data,
    });

    return Promise.resolve(res.data);

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};

export const studentAddCreate = (data) => async (dispatch) => {
  
  try {
    
    const res = await StudentService.studentAddCreate(data);

    // console.log("res : ", res);

    dispatch({
      type: ADD_STUDENT,
      payload: res.data,
    });

    return Promise.resolve(res.data);

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};

export const studentPayInfo = (mst_idx) => async (dispatch) => {
  
  try {
    
    const res = await StudentService.studentPayInfo(mst_idx);

    // console.log("res : ", res);

    dispatch({
      type: PAYINFO_STUDENT,
      payload: res.data,
    });

    return Promise.resolve(res.data);

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};

export const newStudentList = () => async (dispatch) => {
  
  try {
    
    const res = await StudentService.newStudentGetAll();

    // console.log("res : ", res);

    dispatch({
      type: NEW_LIST_STUDENT,
      payload: res.data.data,
    });

    return Promise.resolve(res.data.data);

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};

export const studentPackageInfo = (mst_idx) => async (dispatch) => {
  
  try {
    
    const res = await StudentService.studentPackageInfo(mst_idx);

    // console.log("res : ", res);

    dispatch({
      type: PAYINFO_STUDENT,
      payload: res.data,
    });

    return Promise.resolve(res.data);

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};

export const newClassStudentList = (data) => async (dispatch) => {
  
  try {
    
    const res = await StudentService.newClassStudentGetAll(data);

    // console.log("res : ", res);

    dispatch({
      type: NEW_LIST_CLASS_STUDENT,
      payload: res.data.data,
    });

    return Promise.resolve(res.data.data);

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};

export const newClassAdminStudentList = (data) => async (dispatch) => {
  
  try {
    
    const res = await StudentService.newClassAdminStudentGetAll(data);

    // console.log("res : ", res);

    dispatch({
      type: NEW_LIST_CLASS_ADMIN_STUDENT,
      payload: res.data.data,
    });

    return Promise.resolve(res.data.data);

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};

export const studentAddsCreate = (data) => async (dispatch) => {
  
  try {
    
    const res = await StudentService.studentAddsCreate(data);

    // console.log("res : ", res);

    dispatch({
      type: ADDS_STUDENT,
      payload: res.data,
    });

    return Promise.resolve(res.data);

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};