import {
  CREATE_YAME_NOTICE_BBS,
  LIST_YAME_NOTICE_BBS,
  DELETE_YAME_NOTICE_BBS,
  MODIFY_YAME_NOTICE_BBS,
  SELECT_YAME_NOTICE_BBS,
  OPEN_YAME_NOTICE_BBS,
} from "../actions/types";

const initialState = [];

const bbsYameNoticeReducer = (yame_notice = initialState, action) => {
  const { type, payload } = action;

  // console.log("bbsNoticeReducer Call!");
  // console.log("type : ", type);
  // console.log("payload : ", payload);

  switch (type) {
    
    case CREATE_YAME_NOTICE_BBS:
      return [];

    case LIST_YAME_NOTICE_BBS:
      return {
        ...yame_notice,
        list : payload.list,
        paging : payload.paging,
      };

    case SELECT_YAME_NOTICE_BBS:
      return {
        ...yame_notice,
        select : payload,
      };

    case MODIFY_YAME_NOTICE_BBS:
      return [];

    case DELETE_YAME_NOTICE_BBS:
      return [];

    case OPEN_YAME_NOTICE_BBS:
      return [];

    default:
      return yame_notice;
  }
};

export default bbsYameNoticeReducer;