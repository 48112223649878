import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';

import { useState } from "react";
import { useDispatch } from "react-redux";
import { couponAddManual } from "../../../actions/coupon";
import { useNavigate } from 'react-router-dom';

export default function CouponDetailAddPopup(props) {

    const initialSendData = {
        amount: 0,
    };

  const [open, setOpen] = useState(false);
  const [sendData, setSendData] = useState(initialSendData);
  
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleInputChange = event => 
  {
    const { name, value } = event.target;
    console.log("event target name : ", name);
    console.log("event target value : ", value);

    setSendData({ ...sendData, [name]: value });

  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (sendData.amount < 1)
    {
        return alert("발급 수량을 하나 이상 선택해주세요.");
    }

    let Data = {
        cou_idx: props.cou_idx,
        cou_amount: sendData.amount
    };

    console.log("Data : ", Data);

    // 여기서 디스패치로~
    dispatch(couponAddManual(Data))
    .then(data => 
    {
        console.log("data : ", data);
        if (!data.success)
        {
            alert(data.msg);
            return;
        }
        else
        {
            alert("쿠폰 발급 완료");
            // navigate("/auth/login");

            // 데이터 초기화
            setSendData(initialSendData);

            props.onReloadWindow();
            handleClose();
        }
    })
    .catch(e => {
        console.log(e);
    });
    
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {

    // 데이터 초기화
    setSendData(initialSendData);
    setOpen(false);
  };

  return (
    <div>
      <Button variant="outlined" onClick={handleClickOpen}>
        {props.buttonText}
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>쿠폰 수동 발급</DialogTitle>
        <DialogContent>
          <DialogContentText>
            * 쿠폰수량제한 옵션을 켜면 수량이 제한되고 쿠폰수량제한이 없으면 무제한입니다.<br></br>
            * 쿠폰수량을 입력하고 발급을 하시면 발급 즉시 쿠폰의 갯수만큼 생성됩니다.<br></br>
            * 쿠폰수량제한 옵션이 껴져있는 경우 수동생성입니다.
          </DialogContentText>
            <Box
                component="span"
                sx={{
                    display: 'block',
                    p: 1,
                    m: 1,
                }}
            >
                <TextField
                    id="amount"
                    label="발급가능수량"
                    type="number"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    variant="standard"
                    name="amount"
                    value={sendData.amount}
                    helperText="쿠폰 발급수량을 입력하세요."
                    onChange={handleInputChange}
                    inputProps={{
                        inputMode: 'numeric', 
                        pattern: '[0-9]*',
                        min: 1,
                        max: 10000,
                        type: 'number',
                    }}
                />
                <br></br><br></br>
            </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>취소</Button>
          <Button onClick={handleSubmit}>발급</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}