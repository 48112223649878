import * as React from 'react';
import { useEffect } from 'react';
import styles from "../css/common.module.css";
import $ from 'jquery';

export default function Use() {

	useEffect(() => {

		// 여기에서 jQuery를 초기화 합니다.
		$(document).ready(function(){

		});

	}, [$]);

  	return (
    <>
		
		<div className={styles.wrap}>
			<div className={`${styles.privacy_area} ${styles.area1}`}>
				<span className={styles.tit}>Chapter 1 General Provisions</span>
				<br/><span className={styles.sub_tit}>Article 1 (Purpose)</span>
				<p>
				These terms and conditions are intended to prescribe rights, obligations, responsibilities, and other necessary matters between the company and its members in relation to the use of educational contents provided by Wicklubber Co., Ltd. and all services incidental thereto.
				</p>
				<span className={styles.sub_tit}>Article 2 (Definition of Terms)</span>
				<p>
				1. The terms used in these Terms and Conditions are defined as follows
				<br/><br/>* "Company" means a service provider through an online or mobile device.
				<br/>* "Member" means a person who enters into a contract of use under these terms and conditions and uses the services provided by the company.
				<br/>* The term "educational service" means the educational content provided by the company to its members and all services incidental thereto.
				<br/>* A "mobile device" is a device that can download or install content and includes mobile phones, smartphones, mobile information terminals (PDAs), tablets and PCs, and laptops.
				<br/>* The term "mobile education service" means the educational content provided by the company to its members through mobile devices and all services incidental thereto.
				<br/>* "Content" means any paid or free content (educational content and network services, applications, etc.) digitally produced by the Company in connection with the provision of educational services.
				<br/>* The term "account (ID)" means a combination of letters, numbers or special characters selected by members and assigned by the company for the identification of members and the use of educational services.
				<br/>* The term "account information" collectively refers to the general information, device information, usage fee payment information, etc. provided by the member to the company, such as the member's account, password, and name.
				<br/>* The term "character" means data selected and controlled by members according to the method provided by the company within the educational content for the use of educational services.
				<br/>* The term "password" means a combination of letters, numbers, or special characters that confirm that the member is a member who matches the account granted and is selected and managed as a secret by the member himself/herself to protect the member's information and rights.
				<br/>* The term "post" means any text, document, picture, voice, video, or any combination thereof posted by the member in the use of the service.
				<br/>The definitions of terms used in these Terms and Conditions shall be as prescribed by the relevant statutes and service-specific policies, except as prescribed in paragraph 1 of this Article, and those not prescribed are in accordance with general commercial practice.
				<br/>* In these Terms and Conditions, the "*" sign means the same as the "Ho".
				</p>
				<span className={styles.sub_tit}>Article 3 (Providing Company Information, etc.)</span>
				<p>
				The company will display the following items in the training service to make it easier for members to understand. However, the privacy policy and terms and conditions can be viewed by members through the connection screen

				<br/><br/>* Name of the trade name and representative
				<br/>* The address of the place of business (including the address of the place where the member's complaints can be handled)
				<br/>* Phone number, e-mail address
				<br/>* Business registration number
				<br/>* Mail order business report number
				<br/>* Personal information Processing Policy
				<br/>* Terms and Conditions of Service Use						
				</p>
				<span className={styles.sub_tit}>Article 4 (Effect and Change of Terms and Conditions)</span>
				<p>
				1. The company posts the contents of these terms and conditions in the training service or on its connection screen and on the service website (yamecoding.com ) so that members can know.
				<br/><br/>2. The Company will take measures to ensure that the Members have questions and answers regarding the terms and conditions with the Company.
				<br/><br/>3. The company shall make it easy for those who wish to use the educational service (hereinafter referred to as "user") to understand the contents of the terms and conditions, and obtain the consent of the user to easily understand important contents such as withdrawal of subscription, refund of overpayment, cancellation and termination of contract, company immunity, and compensation for damage to members.
				<br/><br/>4. The company may amend these terms and conditions to the extent that they do not violate related laws such as the Consumer Protection Act in Electronic Commerce, the Regulation of Terms and Conditions, the Game Industry Promotion Act, the Information and Communication Network Utilization Promotion Act, the Content Industry Promotion Act, etc.
				<br/><br/>5. When the company revises the terms and conditions, it shall be notified to the members by specifying the application date, the details of the revision, and the reason for the revision, and posting it on the education service or its connection screen at least seven days before the application date. However, if the changed content is unfavorable to the member or if it is a significant change, it shall be notified in the same manner as in the main text 30 days before the application date and notified to the member by the method of Article 34 (1). In this case, the contents before and after the revision are clearly compared and displayed to make it easier for members to understand.
				<br/><br/>6. If the company revises the terms and conditions, the member's consent to the application of the revised terms and conditions shall be checked after the notice of the revised terms and conditions. The company also notifies or notifies that if the member does not express his or her consent or rejection to the revised terms and conditions, and if the member does not express his or her intention to refuse by the enforcement date of the terms and conditions, it can be deemed to have agreed to the revised terms and conditions.
				<br/><br/>7. If the members do not agree to the application of the revised terms and conditions, the company or members may terminate the education service use contract.						
				</p>
				<span className={styles.sub_tit}>Article 5 (Rules outside of Terms and Conditions)</span>
				<p>
				Matters not stipulated in these terms and conditions and interpretation of these terms shall be governed by relevant laws and regulations such as the Consumer Protection Act in Electronic Commerce, etc., the Regulation of Terms and Conditions, the Game Industry Promotion Act, the Information and Communication Network Utilization Promotion and Information Protection Act, and the Content Industry Promotion Act.
				</p>
				<span className={styles.sub_tit}>Article 6 (Operation Policy)</span>
				<p>
				1. In order to protect the necessary matters and rights of members to apply the terms and conditions, and to maintain order within the educational contents, the company may determine the specific scope of the terms and conditions as the education service operation policy (hereinafter referred to as the "operation policy").
				<br/><br/>2. The company posts the contents of the operation policy on the education service or its connection screen and the service website (yamecoding.com ) so that members can know the contents of the operation policy.
				<br/><br/>3. Follow the procedure in Article 4 paragraph 5 when revising the operational policy. However, if the revision of the operation policy falls under any of the following subparagraphs, it shall be notified in advance by the method of Article 4 (2).
				<br/><br/>* Where the scope of the terms and conditions is specifically determined and the delegated matters are revised
				<br/>* In the case of amending matters not related to the rights and obligations of members
				<br/>* If the contents of the operation policy are not fundamentally different from those specified in the terms and conditions and the members revise the operation policy to the predictable extent						
				</p>
			</div>
			<div className={`${styles.privacy_area} ${styles.area2}`}>
				<span className={styles.tit}>Chapter 2 Conclusion of a Contract for Use</span>
				<br/><span className={styles.sub_tit}>Article 7 (Conclusion and Application of Use Contract)</span>
				<p>
				1. Anyone who wants to use the education service provided by the company must apply for use by filling out the application form provided by the company on the initial screen of the application or on the service website (yamecoding.com ).
				<br/><br/>2. Users must provide all the information required by the company when applying for use.
				<br/><br/>3. Users must enter their actual information in accordance with the relevant laws and regulations when applying for use under paragraph (1). If the real name or identification information is falsely entered or the name of another person is stolen, the members' rights under these terms and conditions cannot be claimed, and the company can cancel or terminate the use contract without a refund.
				<br/><br/>4. According to the company policy and related laws, the company may differentiate the scope and hours of use of the members' services. The Company may request additional information if necessary to provide optional services or to scale services.
				<br/><br/>5. The company accepts the application for use unless there is a reasonable reason when the user accurately enters the actual information and applies for use of the information requested by the company to the user.
				<br/><br/>6. The company may not approve any of the following applications for use or may cancel the approval after death.
				<br/><br/>* Where an application for use is filed in violation of Article 7
				<br/>* If the company uses the service in an unusual or indirect manner in a country where the service has not been provided
				<br/>* Where an application is made for the purpose of performing an act prohibited by the Act on the Promotion of the Game Industry, the Act on the Promotion of Information and Communication Network Utilization and Information Protection, etc. and the relevant statutes
				<br/>* Where he/she intends to use educational services for the purpose of pursuing illegal use or profit
				<br/>* Where it is deemed inappropriate to accept other grounds equivalent to each of the above subparagraphs
				<br/><br/>7. In any of the following cases, the company may withhold the consent until the grounds for such resolution are resolved
				<br/><br/>* If there is no room in the company's facilities or if there is a technical failure
				<br/>* In the event of a service failure or a failure in the means of payment of service usage fees
				<br/>* Where it is difficult to approve the application for use as a reason equivalent to each of the above subparagraphs						
				</p>
				<span className={styles.sub_tit}>Article 8 (Membership Account (ID) and Password)</span>
				<p>
				1. The company gives members a certain combination of letters, numbers, or special characters selected by members as their accounts for convenience such as information protection and service usage guidance.
				<br/><br/>2. Through the account information, the company performs all member management tasks such as the availability of the relevant member's services.
				<br/><br/>3. Members must manage their account information in full accordance with their duty of care as a good manager. Members are responsible for any damages caused by members neglecting to manage their account information or agreeing to use it to a third party.
				<br/><br/>4. Members are responsible for managing passwords, and if they want, they can be changed at any time for security reasons.
				<br/><br/>5. Members must change their passwords regularly
				</p>
				<span className={styles.sub_tit}>Article 9 (Providing and changing membership information)</span>
				<p>
				1. If a member is required to provide information to the company under these terms and conditions, he/she shall provide truthful information and shall not be protected from any disadvantages caused by the provision of false information.
				<br/><br/>2. Members can view and modify their personal information at any time through the personal information management screen. However, some information required for service management may be limited in modification.
				<br/><br/>3. Members must make modifications online or inform the company of the changes by other means when they apply for membership.
				<br/><br/>4. The company shall not be liable for any disadvantages caused by not informing the company of the changes to paragraph 3.						
				</p>
			</div>
			<div className={`${styles.privacy_area} ${styles.area3}`}>
				<span className={styles.tit}>Chapter 3 Personal Information Management</span>
				<br/><span className={styles.sub_tit}>Article 10 (Protection and Use of Personal Information)</span>
				<p>
				1. The company strives to protect the personal information of its members, including account information, as prescribed by relevant laws and regulations. The protection and use of member personal information is subject to the relevant laws and regulations and the personal information processing policy separately notified by the company.
				<br/><br/>2. Except for individual services provided as part of the service, the company's personal information processing policy does not apply to third-party services simply linked to the website and the website by education service.
				<br/><br/>3. The company does not provide the personal information of the members to others without their consent, except at the request of the relevant state agency, etc. under relevant laws and regulations.
				<br/><br/>4. The company shall not be responsible for any information including the account information of the member exposed due to reasons attributable to the member.						
				</p>
			</div>
			<div className={`${styles.privacy_area} ${styles.area4}`}>
				<span className={styles.tit}>Chapter 4 Obligations of the Parties to the Use Contract</span>
				<br/><span className={styles.sub_tit}>Article 11 (Company's Obligations)</span>
				<p>
				1. The Company shall faithfully comply with the exercise of rights and obligations stipulated in the relevant laws and regulations and these terms and conditions.
				<br/><br/>2. The company must have a security system to protect personal information (including credit information) so that members can use the service safely, and disclose and comply with the privacy policy. The company shall not disclose or provide members' personal information to third parties except as stipulated in these terms and conditions and the personal information processing.
				<br/><br/>3. In the event of a facility failure or data loss or damage while improving the service for continuous and stable service, the company will do its best to repair or recover it without delay unless there is an unavoidable reason such as a natural disaster, emergency, or a defect that cannot be resolved by the current technology.						
				</p>
				<span className={styles.sub_tit}>Article 12 (Obligation of Members)</span>
				<p>
				1. Members shall not engage in any of the following activities in relation to the use of services provided by the company.
				<br/><br/>* False information is entered when applying for use or changing membership information
				<br/>* Changes to information posted by the company
				<br/>* theft of other people's information (including personal information and payment information), such as purchasing paid content by stealing other people's credit cards, wired/wireless phones, bank accounts, etc., and fraudulent use of other members' IDs and passwords
				<br/>* The act of collecting, storing, posting, or disseminating other members' personal information without permission
				<br/>* Posting or disseminating one's personal information to others without permission
				<br/>* Production, distribution, use, and advertising of computer programs, devices, or devices not provided or approved by the company
				<br/>* To disrupt or destroy the normal operation of information (computer programs), computer software, hardware, or telecommunications equipment that is prohibited from being transmitted or posted by law The act of deliberately transmitting, posting, distributing, or using viruses, computer codes, files, programs, etc. designed for the purpose
				<br/>* Changes to educational services without being granted special rights by the company, adds and inserts other programs into the educational services, hacking and reverse designing servers, leaking and changing source codes or data, building separate servers, or arbitrarily changing and exploiting parts of websites
				<br/>* Use of services in speculative or other unhealthy ways
				<br/>* Use of educational services for purposes other than the original purpose, such as profit, sales, advertising, public relations, political activities, and campaigning without the consent of the company
				<br/>* obtained by using the company's services Unauthorized copying, distributing, encouraging, or commercial use of information, or exploiting known or unknown bugs to use services
				<br/>* Deceiving others to gain benefits, and harming others in connection with the use of the company's services
				<br/>* Violating the intellectual property rights or portrait rights of the company or others, defaming or damaging others
				<br/>* Other acts that violate relevant laws and regulations or go against good manners and social norms
				<br/>* The act of inducing or advertising the actions of subparagraphs 1 to 14
				<br/><br/>2. Members should note that the provisions of these Terms and Conditions, instructions for use, and educational services, You are obligated to check and comply with the company's notification.
				<br/><br/>3. Members are responsible for managing members' accounts, PCs, and mobile devices, and should not allow others to use them. The company is not responsible for any damages caused by poor management of the member's account, PC, and mobile device or consenting to use it to others.
				<br/><br/>4. The company is not responsible for any damages caused by carelessness
				<br/><br/>5. The company may determine the specific details of the actions in paragraphs 1 and 2 and the following in its operating policy, and the members must follow them
				<br/><br/>* How to use bulletin boards and services
				<br/>* Other matters deemed necessary by the company for the operation of educational services to the extent that it does not infringe on the essential rights of members to use educational services
				</p>
			</div>
			<div className={`${styles.privacy_area} ${styles.area5}`}>
				<span className={styles.tit}>Chapter 5 Service Use and Restrictions</span>
				<br/><span className={styles.sub_tit}>Article 13 (Providing and Suspending Services)</span>
				<p>
				1. In accordance with the provisions of Article 5, the company shall immediately make the service available to members who have completed the use contract. However, some services may start on a specified date, depending on the needs of the company.
				<br/><br/>2. When providing training services to members, the Company may provide additional services, including those prescribed in these Terms and Conditions.
				<br/><br/>3. The company can differentiate its use by classifying the members' ratings and subdividing the hours of use, the number of uses, and the range of services provided.
				<br/><br/>4. Training services are provided for a fixed period of time according to the company's sales policy. The company will inform you of the training service delivery time in an appropriate way on the application usage information screen or on the service website (yamecoding.com ).
				<br/><br/>5. Notwithstanding paragraph (4), the company may not provide educational services for a certain period of time in the following cases, and the company is not obligated to provide educational services during such period
				<br/><br/>* If necessary for maintenance inspection, replacement, regular inspection, or modification of game contents or educational services of information and communication facilities such as computers
				<br/>* If necessary to respond to electronic infringement incidents such as hacking, telecommunication accidents, abnormal game use behavior of members, and instability of educational services that have not been expected
				<br/>* Where the relevant laws prohibit the provision of educational services at a specific time or method
				<br/>* When it is impossible to provide normal educational services due to a natural disaster, emergency, power failure, failure of service facilities, or congestion of service use, etc
				<br/><br/>6. In the case of paragraph 5, item 1 of the company, You can stop the training service by setting a certain amount of time every week or every other week. In this case, the Company will notify the Member of the fact at least 24 hours in advance on the initial screen of the application or on the Service website (yamecoding.com ).
				<br/><br/>7. In the case of Paragraph 5, Item 2, the company may suspend the training service without prior notice. In such cases, the company may post-report the information on the initial screen of the application or on the service website (yamecoding.com ).
				<br/><br/>8. The Company shall not be liable for any damages incurred to the Members in connection with the use of free services provided by the Company. However, damages caused by intentional or gross negligence of the company are excluded.
				<br/><br/>9. In the case of paragraph 5 subparagraphs 3 through 4, the company may suspend all educational services due to technical and operational needs, and may notify this on the website 30 days ago and stop providing educational services. If there are unavoidable circumstances that cannot be notified in advance, notification can be made afterwards.
				<br/><br/>10. If the company terminates the education service pursuant to paragraph 10, members cannot claim damages for free services and paid services without a period of use, continuous paid use contracts, and paid fixed-term items. Paid items with no restrictions on the period of use shall be regarded as the period of use of the paid item until the end of the service.
				<br/><br/>11. For downloaded and installed applications or services used through the network, they are provided to suit the characteristics of the mobile device or mobile carrier. In the case of mobile device change, number change, or overseas roaming, it may not be possible to use all or part of the content, and in this case, the company is not responsible.
				<br/><br/>12. For downloaded and installed applications or services used through the network, background operations may occur. Additional charges may be incurred to suit the characteristics of the mobile device or mobile carrier and the company is not responsible for this.						
				</p>
				<span className={styles.sub_tit}>Article 14 (Change and discontinuation of services)</span>
				<p>
				1. Members may use training services provided by the Company in accordance with these terms and conditions, operating policies, and rules established by the Company.
				<br/><br/>2. The company may change the service according to operational or technical needs to facilitate the provision of educational services, and shall notify the relevant information within the educational service prior to the change. However, if there are unavoidable changes such as bugs, errors, urgent updates, etc., or if there are unavoidable circumstances such as server device defects or emergency security issues, it can be notified afterwards.
				<br/><br/>3. If it is difficult to continue the education service due to serious management reasons, such as the abolition of business due to business transfer, division, merger, etc., the expiration of the contract for game provision, and the deterioration of the profits of the education service, the company may suspend all services if necessary for technical operation. In this case, the date of interruption, reason for interruption, compensation conditions, etc. shall be notified through the game's initial screen or its connection screen by 30 days before the interruption date, and the member shall be notified by the method.
				<br/><br/>4. If the company terminates the education service pursuant to paragraph 5, members cannot claim damages for free services and paid services without a period of use, continuous paid use contracts, or fixed-term paid items. Paid items with no restrictions on the period of use shall be regarded as the period of use of the paid item until the end of the service
				</p>
				<span className={styles.sub_tit}>Article 15 (Change and discontinuation of services)</span>
				<p>
				1. For smooth and stable operation of the service and improvement of service quality, the company can collect and utilize device settings and specification information such as member PCs, running program information, and member mobile device information (settings, specifications, operating system, version, etc.) excluding member's personal information.
				<br/><br/>2. The company may request additional information from the members for the purpose of improving the service and introducing the service to the members. Members may accept or reject this request, and if the company makes this request, they will also be notified that they may reject this request.
				</p>
				<span className={styles.sub_tit}>Article 16 (Provisions of Advertisements)</span>
				<p>
				1. The company may place advertisements within the training service in relation to the operation of the service. In addition, only members who have agreed to receive can send advertising information by means of e-mail, text service (LMS/SMS), push notification, etc. In this case, the member may refuse to receive it at any time, and the company will not send advertising information when the member refuses to receive it.
				<br/><br/>2. You can link to advertisements or services provided by others through banners or links within the services provided by the company.
				<br/><br/>3. When linked to advertisements or services provided by others pursuant to paragraph 2, the service provided in the relevant area is not the company's service area, so the company does not guarantee reliability, stability, etc., and the company is not responsible for any damage to its members. However, this is not the case if the company intentionally or grossly negligently fails to facilitate the occurrence of damage or take measures to prevent damage.
				</p>
				<span className={styles.sub_tit}>Article 17 (Attribution of Copyright, etc.)</span>
				<p>
				1. Copyright and other intellectual property rights for content produced by the company in the education service are owned by the company, and the company only grants members the right to use them under the conditions set by the company in relation to the education service.
				<br/><br/>2. Members shall not use or allow a third party to use the information obtained by using the company's educational services to which intellectual property rights belong to the company or provider without prior consent from the company or provider.
				<br/><br/>* Use for profit by means of copying, transferring, publishing, distributing, broadcasting, and other methods
				<br/>* Use of other terms and conditions or for prohibited purposes by the Company's policy
				<br/><br/>3. Members allow the Company to use images, sounds, and all materials and information (hereinafter referred to as "user content") shown within or related to the educational content or uploaded through the client or educational service by the following ways and conditions.
				<br/><br/>* Use of the user's content, changing the editing format, and other modifications (publication, reproduction, performance, transmission, distribution, broadcasting) is not available in any form, and is also limited to the period and region of use.
				<br/>* Do not sell, lend, or transfer user content for the purpose of transaction without the prior consent of the user who produced the user content
				<br/><br/>4. For the user content of members who are not shown in the educational content and are not integrated with the educational service, the company shall not use it without the explicit consent of the members, and the members may delete these user content at any time.
				<br/><br/>5. If the company deems that the posts or contents of the posts in the educational services posted or registered by the members are prohibited under Article 12, it may delete them or refuse to move or register them without prior notice.
				<br/><br/>6. This section (Article 15) is valid while the Company operates the training service and will continue to apply after the withdrawal of membership.					
				</p>
				<span className={styles.sub_tit}>Article 18 (Products to Use)</span>
				<p>
				1. The company may provide educational services (hereinafter referred to as "free services") that members can use without paying a separate fee and educational services (hereinafter referred to as "paid services") that the company pays in advance, and members can select and use educational services. Paid content purchased by members within a mobile training service is available only on mobile devices that have downloaded or installed the application.
				<br/><br/>2. The payment method for the use of educational services can be done through the method determined in advance by the company.
				<br/><br/>3. Members who apply for the use of paid services must faithfully pay the price according to the method set by the company.
				<br/><br/>4. The period of use of the paid service purchased by the member will be maintained while the paid account is maintained. However, if the period of use is set separately when purchasing a paid service, it shall be followed accordingly, and if the service is suspended pursuant to Article 14 (5), the period of use of paid content without a fixed period shall be until the date of suspension of the service announced when the service is suspended.						
				</p>
				<span className={styles.sub_tit}>Article 19 (Paid Content)</span>
				<p>
				1. Paid content includes all paid content specified by the company, including items, additional missions, and music.
				<br/><br/>2. The company may set limits on charging, holding, and using members' items according to payment companies (mobile carriers, credit card companies, etc.) and their policies.
				<br/><br/>3. The company may refuse to accept the member's request for payment or may cancel the approval after the fact in any of the following cases.
				<br/><br/>* 1. When the paid fee is not paid or the payer cannot be confirmed
				<br/>* 2. When you steal other people's payment information
				<br/>* 3. If the legal representative does not agree to a minor's request for a charge
				<br/>* 4. Where consent is deemed inappropriate for reasons equivalent to subparagraphs 1 to 3					
				</p>
				<span className={styles.sub_tit}>Article 20 (Restrictions on Service Use for Members)</span>
				<p>
				1. The company may restrict members from using educational services according to the following categories. The specific reasons for the violation of the obligations of members subject to restrictions on use shall be determined in the individual operation policies in accordance with Article 26.
				<br/><br/>* Restrict some permissions on accounts: Restricting certain permissions on member accounts, such as writing bulletin boards for a period of time or permanently
				<br/>* Restrictions on account usage: Restrictions on the use of member accounts for a certain period of time or permanently
				<br/>* Restrictions on the use of members: Restrictions on the use of educational services by members for a certain period of time or permanently
				<br/><br/>2. If the company's restrictions on use are justifiable, the company will not compensate the member for damages caused by the restrictions on use.
				<br/><br/>3. Even if another person uses the member's account (ID) to violate the terms and conditions or operating policies, the member's use of educational services may be restricted according to the criteria of paragraph 1.
				<br/><br/>4. In order to improve service and protect membership information, the company may delete user information, such as accounts that have not used training content for a certain period of time or longer as separately notified by the operating policy. However, the company must notify the members seven days before the information is deleted.						
				</p>
				<span className={styles.sub_tit}>Article 21 (Restrictions on Use as Provisional Measures)</span>
				<p>
				1. The company may suspend its account until the investigation into the following matters has been completed..
				<br/><br/>* When you receive a legitimate report that your account has been hacked or stolen
				<br/>* When an illegal program user, workplace, etc. is reasonably suspected of being an offender
				<br/>* Where provisional measures of the account are required for reasons equivalent to each of the above subparagraphs
				<br/><br/>2. In the case of paragraph 1, the company pays a certain amount in proportion to the period of use of educational services after the investigation is completed and extends the period of use of educational services for members by a suspended period. However, this is not the case if it is found to be an offense under paragraph (1)					
				</p>
				<span className={styles.sub_tit}>Article 22 (Grounds and Procedures for Restriction of Use Measures)</span>
				<p>
				1. The company determines specific reasons and procedures for restricting use in consideration of various circumstances such as the content, degree, frequency, and consequences of the violation as an operation policy.
				<br/><br/>If the company imposes restrictions on use under Article 20, Paragraph 1, the following matters shall be notified to the members in advance. However, if urgent action is needed, it can be notified afterwards.
				<br/><br/>* Reasons for Restricting Use
				<br/>* Types and duration of restricted use measures
				<br/>* How to file an objection to restrictions on use				
				</p>
				<span className={styles.sub_tit}>Article 23 (Procedures for filing objections to restrictions on use)</span>
				<p>
				1. If a member wishes to object to the Company's restriction of use, he/she shall submit an objection to the Company in writing, e-mail or equivalent within 15 days from the date of notification of this measure.
				<br/><br/>2. The company shall respond to the reasons for dissatisfaction in writing, e-mail, telephone, or equivalent within 15 days from the date of receipt of the objection under paragraph (1). However, if it is difficult to answer within this period, the company will notify the reason and processing schedule.
				<br/><br/>3. The company will take action accordingly if reasons for dissatisfaction are reasonable.				
				</p>
				<span className={styles.sub_tit}>Article 24 (Installation of Computer Programs)</span>
				<p>
				1. The Company may request members to install computer programs provided by the Company, if necessary to provide services. The Company shall notify the members of the program in an appropriate manner and obtain consent for the installation of the program, including capacity, functionality, removal method, and impact on other programs before installing the program. However, if a member has set the browser option to install ActiveX programs automatically, the company considers that the member has agreed to install the ActiveX programs and can install them without confirmation.
				<br/><br/>2. If the company patches or updates the program to the extent that it does not significantly change the information provided in paragraph 1, it may omit the notice of important matters and consent.
				<br/><br/>3. Companies can use technology to relay transfer data between members for smooth data transfer when installing or updating computer programs. Members agree to relay data to other members via PC resources (storage, network equipment, etc.).						
				</p>
			</div>
			<div className={`${styles.privacy_area} ${styles.area6}`}>
				<span className={styles.tit}>Chapter VI Withdrawal of Subscription, Termination and Termination of Contracts</span>
				<br/><span className={styles.sub_tit}>Article 25 (Mobile Payment)</span>
				<p>
				1. In principle, the imposition and payment of the purchase price for contents are subject to the policies or methods set by mobile carriers or open market operators. In addition, limits for each payment method may be granted or adjusted in accordance with the policies set by the company or the open market operator or by the government.
				<br/><br/>2. In the case of paying the purchase price of contents in foreign currency, the actual charge may vary from the price indicated in the store of the service due to exchange rate, commission, etc.				
				</p>
				<span className={styles.sub_tit}>Article 26 (Withdrawal of Subscription, etc.)</span>
				<p>
				1. Members who have signed a contract with the company regarding the use of paid services can withdraw their subscription within 7 days from the late date of the purchase contract or the available date of paid education services without any additional fees or penalties. However, the service is not used. (See Article 30)
				<br/><br/>2. A member may not withdraw his/her subscription under paragraph (1) against the intention of the company in any of the following cases.
				<br/><br/>* When goods, etc. are lost or damaged due to a cause that the member is responsible for
				<br/>* When the value of goods, etc. has significantly decreased due to the use or partial consumption of consumers
				<br/>- Paid content used or applied immediately upon purchase
				<br/>- If the act of opening can be viewed as use, or if there is an act of opening content whose utility is determined at the time of opening
				<br/>* When the delivery of content is initiated. However, in the case of a contract consisting of provisional content, this does not apply to the part where the provision has not been initiated.
				<br/><br/>3. In the case of goods, etc. that cannot be withdrawn from subscription pursuant to paragraph (2) 2 through (3), the company shall take measures to ensure that the exercise of the right to withdraw subscription, etc. is not hindered by clearly stating the fact in the packaging of goods, etc. or other places where members can easily understand, or by providing products used for testing. However, if a member is unable to withdraw his/her subscription pursuant to paragraph (2) 3, the exercise of the right to withdraw his/her subscription shall not be hindered by providing test-use products, etc. along with an indication that it is impossible to withdraw his/her subscription.
				If the company fails to take such measures, members may withdraw their subscription despite the reasons for the restriction on the withdrawal of subscription under paragraph (2) 2 through (3).
				<br/><br/>4. Notwithstanding the provisions of paragraphs 1 to 3, members may withdraw their subscription within three months from the date of purchase or the date of use of the paid service, or within 30 days from the date on which they knew or knew the fact, if the contents of the paid service are different from the contents of the advertisement or the contract.
				<br/><br/>5. When an application-related member withdraws the subscription, the company checks the purchase details through a platform operator or an open market operator. The Company may also contact the Member through the information provided by the Member to ascertain the Member's legitimate reasons for withdrawal, and may require additional proof.
				<br/><br/>6. When a minor member enters into a paid education service contract requiring payment, the company notifies that the minor himself or his legal representative may cancel the contract without the consent of the legal representative, and when a minor enters into a contract without the consent of the legal representative, the minor himself or his legal representative may cancel the contract with the company. However, it cannot be canceled if a minor enters into a paid education service contract with the property permitted by the legal representative to set the scope and dispose of it, or if a minor tricked him into believing that he was an adult or that he had the consent of the legal representative.
				<br/><br/>7. Whether the parties to the educational service use contract are minors is judged based on the device in which payment has been made, the information on the payment executioner, and the name of the payment method. The Company may also request the submission of documents proving that it is a minor or legal representative to verify that it is a legitimate cancellation.						
				</p>
				<span className={styles.sub_tit}>Article 27 (Refund of Overpayments)</span>
				<p>
				1. In the event of an overcharge, the company must refund the entire overcharge in the same way as the payment of the usage price. However, if a refund is not possible in the same way, it will be notified in advance.
				<br/><br/>2. In the event of an overcharge due to the company's responsible reasons, the company will refund the entire overcharge regardless of contract cost or commission. However, in the event of an overcharge due to the responsible cause of the member, the cost required for the company to refund the overcharge shall be borne by the member to a reasonable extent.
				<br/><br/>3. The company is responsible for proving that the usage fee has been properly charged in case the member refuses to refund the charge claimed by the member.
				<br/><br/>4. In the case of the application, the refund will be made according to the refund policy of each open market operator or company depending on the type of operating system of the mobile device using the service.
				<br/><br/>5. The company may contact the member through the information provided by the member to handle the refund of overpayments and may request the provision of necessary information.
				<br/><br/>6. The company handles the refund procedure for charges in accordance with the content user protection guidelines.						
				</p>
				<span className={styles.sub_tit}>Article 28 (Cancellation and Termination of Members)</span>
				<p>
				1. The Company may terminate the contract after prior notice to the Member if the Member violates the Member's obligations set forth in these Terms and Conditions. However, if a member violates the current law or damages the company intentionally or with gross negligence, the contract may be terminated without prior notice.
				<br/><br/>2. If the measure of paragraph 1 has been applied more than three times to the account (ID) held by the member, the company may terminate the contract for all accounts (ID) in the member's name.
				<br/><br/>3. In order to protect members' personal information and prevent account theft, the company may destroy the personal information of the account (ID) one year after the last service use date and terminate the use contract, or store and manage the personal information of the account (ID) separately from other users' personal information and restrict the use of the service.
				<br/><br/>4. If a member fails to apply for resumption of service two years after the restriction of service under paragraph 3, the company may terminate the service contract and delete the relevant information for the account (ID).
				<br/><br/>5. When the company terminates the use contract, the company shall notify the member of the following matters in writing, by e-mail, or by an equivalent method.
				<br/><br/>* Reason for Cancellation
				<br/>* Cancellation Date
				<br/><br/>6. In the case of the proviso to paragraph 1, the member loses the right to use the paid service and cannot claim a refund or compensation for damages					
				</p>
			</div>
			<div className={`${styles.privacy_area} ${styles.area7}`}>
				<span className={styles.tit}>Chapter 7 Compensation for Damages and Disclaimer, etc</span>
				<br/><span className={styles.sub_tit} id="refund_policy">Article 29 (Compensation for Damages)</span>
				<p>
				1. If the company intentionally or grossly negligently damages the member, it is liable to compensate for the damage.
				<br/><br/>2. The company handles matters concerning the standards, scope, methods and procedures for compensation for damage to members due to defects in paid services, etc. in accordance with the content user protection guidelines. However, it shall be followed when purchasing a paid service or if the damage compensation is separately determined in these terms and conditions.
				<br/><br/>3. If the paid service purchased by the member from the company is lost due to reasons attributable to the company, the company shall restore it to the state before the loss. If it is impossible or difficult to restore it, the company shall provide the paid service with the same similar value or refund the paid service purchase price at the company's choice.
				<br/><br/>4. If a member causes damages to the company in violation of these terms and conditions, the member shall be liable to compensate the company for such damages.				
				</p>
				<span className={styles.sub_tit}>Article 30 (Refund)</span>
				<p>
				1. If a member requests a refund for reasons such as termination of a paid service contract purchased or withdrawal of subscription, the following amounts may be refunded.
				<br/><br/>* Refund must not be in full use to be refunded.
				<br/>* You can get a refund without using the paid service after payment, but you cannot get a refund if you have used some of them.
				<br/>* Refund can be made within 1 week from the date of purchase.
				<br/>* You can apply for and refund virtual accounts up to 2 times a day.
				<br/><br/>2. Refund standards separately notified by the company apply to products that have not set refund standards in paragraph 1 or products that have been notified by the company by setting refund standards different from those in paragraph 1.
				<br/><br/>3. The company shall refund the amount specified in paragraph 1 within 5 business days from the date the member expresses his intention to refund under paragraph 1. However, in the case of payment methods that require payment confirmation (e.g., mobile phone payment, etc.), a refund should be made within 5 business days from the payment confirmation date.
				</p>
				<span className={styles.sub_tit}>Article 31 (Disclaimer of the Company)</span>
				<p>
				1. The Company is exempt from liability if it is unable to provide services due to wartime, events, natural disasters, emergencies, technical defects or force majeure reasons that cannot be resolved by current technology.
				<br/><br/>2. The company is exempted from liability for suspension of educational services, disability of use, and termination of the contract due to reasons attributable to members.
				<br/><br/>3. The Company is exempt from liability in the event of damage to its members due to the key telecommunications service provider's failure to stop or provide telecommunications services normally, unless the Company is intentionally or seriously negligent.
				<br/><br/>4. The company is exempt from liability in the event of a suspension or failure of educational services due to unavoidable reasons such as repair, replacement, regular inspection, and construction of pre-announced educational service facilities, unless there is intentional or serious negligence of the company.
				<br/><br/>5. The company is exempt from liability for any problems arising from the member's computer environment or from the network environment without the intention or gross negligence of the company.
				<br/><br/>6. The company is exempt from liability for information, data, reliability of facts, accuracy, etc. posted or transmitted by members or third parties on the training service or on the website, unless the company commits intentional or gross negligence.
				<br/><br/>7. The Company is not obligated to intervene in disputes arising from the mediation of educational services between the Members or between the Members and a third party, nor is it liable to compensate for any damages resulting therefrom.
				<br/><br/>8. In the case of free services among the educational services provided by the company, the company shall not compensate for damages unless the company has intentional or gross negligence.
				<br/><br/>9. Some educational services of this educational service may be provided through educational services provided by other operators, and the company is exempted from liability for damages caused by educational services provided by other operators unless the company has intentional or gross negligence.
				<br/><br/>10. The company is exempt from liability for damages caused by members' choice or use of education services unless the company has intentional or gross negligence.
				<br/><br/>11. The Company will be exempted from liability for the loss of the Member's application cyber assets unless the Company commits intentional or gross negligence.
				<br/><br/>12. In case of damage caused by a member's computer error, or in case of damage caused by incorrect or incomplete entry of personal information and e-mail address, the company is exempted from liability unless there is intentional or gross negligence of the company.
				<br/><br/>13. The company may limit the hours of use of educational services according to educational services or members in accordance with relevant laws, government policies, etc., and will be exempted from liability for all matters related to the use of educational services arising from these restrictions and restrictions.
				<br/><br/>14. The Company is not responsible for third-party payments resulting from members' failure to manage passwords. However, this is not the case for intentional or gross negligence of the company.
				<br/><br/>15. If a member does not have access to all or part of the content due to a change in his or her mobile device, a change in his or her mobile device's number, operating system version, overseas roaming, carrier change, etc., the Company is not responsible for this. However, this is not the case for intentional or gross negligence of the company.
				<br/><br/>16. If a member deletes any content or account information provided by the company, the company is not responsible for this. However, this is not the case for intentional or gross negligence of the company.
				<br/><br/>17. The Company shall not be liable for any damages caused by the use of services by temporary members. However, this is not the case for intentional or gross negligence of the company.					
				</p>
				<span className={styles.sub_tit}>Article 32 (Notice and Notice to Members)</span>
				<p>
				1. If the company notifies the member, it can be done with the member's e-mail address, text message (LMS/SMS), etc.
				<br/><br/>2. When notifying all members, the company may replace the notice under paragraph (1) by posting it on the training service for at least 7 days or presenting a pop-up screen.					
				</p>
				<span className={styles.sub_tit}>Article 33 (Trial Rights and Jurisdiction Act)</span>
				<p>
				These terms and conditions are governed and interpreted in accordance with Korean law. Where a lawsuit is filed due to a dispute between the company and its members, the court in accordance with the procedures prescribed by the statute shall be the competent court.					
				</p>
				<span className={styles.sub_tit}>Article 34 (Complaint Handling and Dispute Resolution of Members)</span>
				<p>
				1. In consideration of the members' convenience, the company guides them on how to present their opinions or complaints within the training service or on their connection screen. The company operates a dedicated staff to handle these members' opinions or complaints.
				<br/><br/>2. If the company objectively recognizes that a member's opinion or complaint is justified, the company shall promptly deal with it within a reasonable period of time. However, if it takes a long time to process, the member shall be notified of the reasons and processing schedule that will take a long time in the education service or notified in accordance with Article 32 (1).
				<br/><br/>3. In the event of a dispute between the company and its members and mediation by a third dispute mediation agency, the company may faithfully prove the measures taken to the members, such as restrictions on use, and comply with the mediation of the mediation agency.
				</p>
			</div>

		</div>
				
    </>
  );
}