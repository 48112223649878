import http from "../http-common";

const bbsNoticeCreate = data => {
  return http.post("bbs/notice/add", data);
};

const bbsNoticeGetAll = data => {
    console.log("bbsNoticeGetAll data : ", data);
    return http.get(`bbs/notice/${data.board_name}/${data.board_page}`);
};

const bbsNoticeModify = data => {
  return http.put("bbs/notice/edit", data);
};

const bbsNoticeSelect = (bbs_name, idx) => {
  return http.get(`bbs/notice/select/${bbs_name}/${idx}`);
};

const bbsNoticeDelete = data => {
  return http.delete("bbs/notice/del", {data : data});
};

const BBSNoticeService = {
  bbsNoticeCreate,
  bbsNoticeGetAll,
  bbsNoticeDelete,
  bbsNoticeModify,
  bbsNoticeSelect,
};

export default BBSNoticeService;
