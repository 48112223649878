import {
  CREATE_USERS,
  CREATE_GROUP_USERS,
  UPDATE_USERS,
  UPDATE_GROUP_USERS, 
  DELETE_USERS,
  LIST_USERS,
  ME_USERS,
  PASSWORD_CHANGE_USERS,
  SELECT_USERS,
  SELECT_GROUP_USERS,
  ACTIVE_USERS,
  MISSION_TOTAL_USERS,
  MISSION_PROGRESS_USERS,
  KNOWLEDGE_ANSWER_USERS,
  TOTAL_HUMANABILITY_USERS,
  HUMANABILITY_USERS,
  IDCHECK_USERS,
  UPDATE_PHONE_USERS,
  LEAVE_USERS,
  UPDATE_STUDENT_USE,
  GROUP_LIST_USERS,
  GROUP_SELECT_USERS,
  GROUP_INFO_USERS,
  GROUP_INFO_USERS_CLASS,
  LIST_CLASS_USERS,
} from "../actions/types";

const initialState = [];

const usersReducer = (users = initialState, action) => {
  const { type, payload } = action;

  // console.log("userReducer Call!");
  // console.log("type : ", type);
  // console.log("payload : ", payload);

  switch (type) {

    case CREATE_USERS:
      return [];

    case CREATE_GROUP_USERS:
      return [];

    case UPDATE_USERS:
      return [];

    case UPDATE_GROUP_USERS:
      return [];

    case DELETE_USERS:
      return [];

    case ACTIVE_USERS:
      return [];

    case LIST_USERS:
      return {
        ...users,
        list : payload.list,
        paging : payload.paging,
      };

    case SELECT_USERS:
      return{
        ...users,
        user: payload,
      };

    case SELECT_GROUP_USERS:
      return{
        ...users,
        usergroup: payload,
      };
    
    case ME_USERS:
      return{
        ...users,
        profile: payload,
      };

    case PASSWORD_CHANGE_USERS:
      return [];

    case MISSION_TOTAL_USERS:
      return{
        ...users,
        missiontotal: payload,
      };

    case MISSION_PROGRESS_USERS:
      return{
        ...users,
        missionprogress: payload,
      };
    
    case KNOWLEDGE_ANSWER_USERS:
      return{
        ...users,
        knowledgeanswer: payload,
      };

    case TOTAL_HUMANABILITY_USERS:
      return{
        ...users,
        totalability: payload,
      };

    case HUMANABILITY_USERS:
      return{
        ...users,
        ability: payload,
      };

    case IDCHECK_USERS:
      return {
        ...users,
        checkid : payload,
      };

    case UPDATE_PHONE_USERS:
      return [];

    case LEAVE_USERS:
      return [];

    case UPDATE_STUDENT_USE:
      return [];

    case GROUP_LIST_USERS:
      return {
        ...users,
        list : payload.list,
        paging : payload.paging,
        totalInfo : payload.totalInfo,
      };

    case GROUP_SELECT_USERS:
      return{
        ...users,
        user: payload,
      };

    case GROUP_INFO_USERS:
      return{
        ...users,
        info: payload,
      };

    case GROUP_INFO_USERS_CLASS:
      return{
        ...users,
        info: payload,
      };

    case LIST_CLASS_USERS:
      return{
        ...users,
        classuser: payload,
      };

    default:
      return users;
  }
};

export default usersReducer;