import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { DialogActions, DialogContent } from '@mui/material';
import TransferList from './TransferList';
import { useSelector, useDispatch } from "react-redux";
import { inappProductMissionCreate } from "../../../actions/inapp_product_mission";

function SimpleDialog(props) {
  const { onClose, open, ina_idx, mis_count, total_mission } = props;
  const [useCount, setUseCount] = React.useState(0);
  const [missionSaveData, setMissionSaveData] = React.useState(null);

  const dispatch = useDispatch();
  const mission = useSelector(state => state.inapp_product_mission);

  const handleClose = () => {
    setMissionSaveData(null);
    onClose();
  };

  const handleSave = () => {

    // console.log("useCount : ", useCount);
    // console.log("missionSaveData : ", missionSaveData);
    // console.log("mission : ", mission);

    let missionArray = new Array();

    if (missionSaveData !== null)
    {
      for (let i=0; i<missionSaveData.length; i++)
      {
        let string1 = mission.find(x => x.mis_id === missionSaveData[i]).mis_idx;
        let string2 = mission.find(x => x.mis_id === missionSaveData[i]).mis_id;
        // console.log(string1);
        // console.log(string2);
        missionArray[i] = {mis_idx: string1};
      }
  
      const SendJaon = {
        ina_idx: ina_idx,
        mission: missionArray
      }
  
      // console.log("SendJaon : ", SendJaon);

      // // 여기서 디스패치로~  
      dispatch(inappProductMissionCreate(SendJaon))
      .then(data => 
      {
          // console.log("data : ", data);
          setMissionSaveData(null);
          onClose();
      })
      .catch(e => {
          console.log(e);
      });

    }    
  };

  return (
    <Dialog onClose={handleClose} open={open} maxWidth={'md'}>
      <DialogTitle>미션 설정 ({useCount || 0}/{total_mission || 0})</DialogTitle>
      <DialogContent>
        <TransferList ina_idx={ina_idx} setUseCount={setUseCount} setMissionSaveData={setMissionSaveData}/>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleSave}>저장</Button>
        <Button variant="outlined" onClick={handleClose}>닫기</Button>
      </DialogActions>
    </Dialog>
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default function PackageDialog(props) {
  const { ina_idx, mis_count, total_mission } = props;

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
    props.refreshFunction();
  };

  return (
    <div>
      설정된 미션 {mis_count} / {total_mission}
      <Button variant="outlined" onClick={handleClickOpen} sx={{marginLeft:2}}>
        패키지 미션 설정
      </Button>
      <SimpleDialog
        open={open}
        onClose={handleClose}
        ina_idx={ina_idx}
        mis_count={mis_count}
        total_mission={total_mission}
      />
    </div>
  );
}