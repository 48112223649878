import {
  CREATE_GROUP_ORDER,
  LIST_GROUP_ORDER,
  CANCEL_GROUP_ORDER,
  SELECT_GROUP_ORDER,
} from "../actions/types";

const initialState = [];

const groupOrderReducer = (group_order = initialState, action) => {
  const { type, payload } = action;

  // console.log("groupOrderReducer Call!");
  // console.log("type : ", type);
  // console.log("payload : ", payload);

  switch (type) {
    
    case CREATE_GROUP_ORDER:
      return group_order;

    case LIST_GROUP_ORDER:
      return payload;

    case CANCEL_GROUP_ORDER:
      return group_order;

    case SELECT_GROUP_ORDER:
      return payload;

    default:
      return group_order;
  }
};

export default groupOrderReducer;