import {
  CREATE_KNOWLEDGE,
  LIST_KNOWLEDGE,
  DELETE_KNOWLEDGE,
  MODIFY_KNOWLEDGE,
  SELECT_KNOWLEDGE,
} from "../actions/types";

const initialState = [];

const knowledgeReducer = (knowledge = initialState, action) => {
  const { type, payload } = action;

  // console.log("knowledgeReducer Call!");
  // console.log("type : ", type);
  // console.log("payload : ", payload);

  switch (type) {
    
    case CREATE_KNOWLEDGE:
      return [];

    case LIST_KNOWLEDGE:
      return payload;

    case SELECT_KNOWLEDGE:
      return payload;

    case MODIFY_KNOWLEDGE:
      return [];
      // return knowledge.map((knowledge) => {
      //   if (knowledge.category_idx === payload.category_idx) {
      //     return {
      //       ...knowledge,
      //       ...payload,
      //     };
      //   } else {
      //     return knowledge;
      //   }
      // });

    case DELETE_KNOWLEDGE:
      // return knowledge.filter(({ category_idx }) => category_idx !== payload.category_idx);
      return [];

    default:
      return knowledge;
  }
};

export default knowledgeReducer;