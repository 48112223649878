import * as React from 'react';
import styles from "../css/common.module.css";

export default function Price() {

  return (
    <>
		<div id={styles.price_title}>
			<div className={styles.inner}>
				<span className={styles.tit}>우리아이 <span className={styles.sub_color}>코딩교육</span><br/><span className={styles.color}>야미코딩</span>으로 시작해요!</span>
			</div>
		</div>
		<div id={styles.price_content}>
			<div className={styles.inner}>
				<span className={styles.tit}><span className={styles.color}>야미코딩</span>만의 <span className={styles.sub_color}>독보적이고 탄탄한 콘텐츠</span></span>
				<div className={styles.price_cont_wrap}>
					<div className={`${styles.price_cont} ${styles.cont1}`}>
						<p>물리엔진을 이용한 다양한 미션들!</p>
						<ul className={styles.cf}>
							<li># 물리엔진</li>
							<li># 얼음</li>
							<li># 바람</li>
						</ul>
						<div className={styles.moniter}>
							<img src="img/screen-9.jpg" alt="다양한 미션들 이미지"/>
						</div>
					</div>
					<div className={`${styles.price_cont} ${styles.cont2}`}>
						<p>아이들의 흥미를 유발하는 게임형 콘텐츠!</p>
						<ul className={styles.cf}>
							<li># 재미있는</li>
							<li># 게임같은</li>
						</ul>
						<div className={styles.moniter}>
							<img src="img/screen-20.jpg" alt="게임형 콘텐츠 이미지"/>
						</div>
					</div>
					<div className={`${styles.price_cont} ${styles.cont3}`}>
						<p>마음대로 꾸밀 수 있는 귀여운 캐릭터들!</p>
						<ul className={styles.cf}>
							<li># 야미프렌즈</li>
							<li># 야미로봇</li>
							<li># 커스텀</li>
						</ul>
						<div className={styles.moniter}>
							<img src="img/screen-19.jpg" alt="커스텀 이미지"/>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div id={styles.price_banner}>
			<div className={styles.price_banner_bg}></div>
			<div className={styles.inner}>
				<div className={styles.img}><img src="img/screen-3.png" alt="실행화면"/></div>
				<span className={styles.tit}>언제, 어디서나<br/><span className={styles.color}>야미코딩</span>으로 공부해요!</span>
			</div>
		</div>
		<div id={styles.order}>
			<div className={styles.inner}>
				<span className={styles.tit}><span className={styles.color}>야미코딩</span> 패키지 구입</span>
				<div className={styles.order_box_wrap}>
					<img src="img/price_card1.png" alt="가격1 이미지"/>
					<img src="img/price_card2.png" alt="가격2 이미지"/>
				</div>
				<div className={`${styles.order_box_wrap} ${styles.order_box_wrap_mobile}`}>
					<img src="img/price_card1_mobile.png" alt="가격1 이미지"/>
					<img src="img/price_card2_mobile.png" alt="가격2 이미지"/>
				</div>
				<a href="#" className={styles.order_btn}>구매하기</a>
				<ul className={styles.cf}>
					<li><span>-</span><p>패키지 1개당 1유저 사용 가능합니다.</p></li>
					<li><span>-</span><p>패키지 구매 시 이용 가능 기간은 6개월입니다.</p></li>
					<li><span>-</span><p>결제 후 유료서비스를 사용하지 않은 상태에서 환불이 가능하나 일부라도 사용한 상태에서는 환불이 불가합니다.</p></li>
					<li><span>-</span><p>환불 정책은 <a href="/Use#refund_policy">이용약관 제7장 30조 1항</a>을 참조해 주세요.</p></li>
					<li><span>-</span><p>학습자 계정으로는 웹에서 로그인할 수 없습니다.</p></li>
				</ul>
			</div>
		</div>
		<div id={styles.price_process}>
			<div className={styles.inner}>
				<span className={styles.tit}>이용과정 안내</span>
				<div className={`${styles.cf} ${styles.process_box_wrap}`}>
					<div className={`${styles.process_box} ${styles.box1}`}>
						<img src="img/process_icon1.png" alt="회원가입하기"/>
					</div>
					<div className={`${styles.process_box} ${styles.box2}`}>
						<img src="img/process_icon2.png" alt="학습패키지구매"/>
					</div>
					<div className={`${styles.process_box} ${styles.box3}`}>
						<img src="img/process_icon3.png" alt="학습자 계정 생성"/>
					</div>
					<div className={`${styles.process_box} ${styles.box4}`}>
						<img src="img/process_icon4.png" alt="패키지에 학습자 적용"/>
					</div>
					<div className={`${styles.process_box} ${styles.box5}`}>
						<img src="img/process_icon5.png" alt="앱에서 로그인"/>
					</div>
				</div>
				<div className={`${styles.cf} ${styles.process_txt}`}>
					<p className={styles.txt1}>1. 학부모 홈페이지 가입</p>
					<p className={styles.txt2}>2. 학습 패키지 구매</p>
					<p className={styles.txt3}>3. 학습자 아이디/비밀번호 생성</p>
					<p className={styles.txt4}>4. 패키지 상품에 학습자 적용</p>
					<p className={styles.txt5}>5. 학습자 계정으로 앱에서 로그인</p>
				</div>
				<div className={`${styles.cf} ${styles.pc_arrow} ${styles.arrow_wrap}`}>
					<img src="img/arrow_right.png" alt="arrow" className={styles.arrow1}/>
					<img src="img/arrow_right.png" alt="arrow" className={styles.arrow2}/>
					<img src="img/arrow_right.png" alt="arrow" className={styles.arrow3}/>
					<img src="img/arrow_right.png" alt="arrow" className={styles.arrow4}/>
				</div>
			</div>
			<div className={styles.price_process_mobile_img_area}>
				<img className={styles.price_process_tablet} src="img/price_process_tablet.png" alt="이용과정 타블렛 이미지"/>
				<img className={styles.price_process_mobile} src="img/price_process_mobile.png" alt="이용과정 모바일 이미지"/>
			</div>
		</div>
    </>
  );
}