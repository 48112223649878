import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

import { useState } from 'react';
import { useDispatch } from "react-redux";
import { orderCreate } from "../actions/order";

import { useNavigate } from 'react-router-dom';

import * as common from "../lib";

export default function ProductItem(props) {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const initialOrderState = {
      prd_idx: parseInt(props.product.prd_idx),
      ord_count: 1,
      ord_amt: parseInt(props.product.sell_prc_unit),
      default_amt: parseInt(props.product.sell_prc_unit),
  };

  // console.log(props.product);

  const [order, setCategory] = useState(initialOrderState);
  const [product, setProduct] = useState(props.product);

  const handleInputChange = event => 
  {
    let { name, value } = event.target;
    // console.log("name : ", name);
    // console.log("value : ", parseInt(value));
    // console.log("typeof(value) : ", typeof(value));
    // console.log("order.ord_count : ", order.ord_count);
    // console.log("typeof(order.ord_count) : ", typeof(order.ord_count));

    if (name === "ord_count")
    {  
      order.ord_amt = value * order.default_amt;
      setCategory({ ...order, [name]: parseInt(value) });
    }
    else
    {
      setCategory({ ...order, [name]: value });
    }
  };

  const handleSubmit = (event) => {

    event.preventDefault();

    const sendOrder = {
      product_idx:order.prd_idx,
      order_count:order.ord_count,
      order_amount:order.ord_amt
    };

    // 여기서 디스패치로~
    dispatch(orderCreate(sendOrder))
    .then(data => 
    {
        // console.log("data : ", data);
        if (!data.success)
        {
          alert(data.msg);
          return;
        }
            
        if (!window.confirm("주문 관리 리스트로 이동하겠습니까?")) 
        {
            // 취소(아니오) 버튼 클릭 시 이벤트
            return;
            
        } else {
            // 확인(예) 버튼 클릭 시 이벤트
            navigate("/dashboard/OrderList");
        }
    })
    .catch(e => {
        console.log(e);
    });
    
};

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: { xs: 'column', md: 'row' },
        alignItems: 'center',
        bgcolor: 'background.paper',
        overflow: 'hidden',
        borderRadius: '12px',
        boxShadow: 1,
        fontWeight: 'bold',
      }}
    >
      <Box
        component="img"
        sx={{
          height: 233,
          width: 350,
          maxHeight: { xs: 233, md: 167 },
          maxWidth: { xs: 350, md: 250 },
        }}
        alt="The house from the offer."
        src={common.returnImgSrc(product.prd_photo_path, product.prd_photo_file) || ""}
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: { xs: 'center', md: 'flex-start' },
          m: 3,
          minWidth: { md: 180 }
        }}
      >
        <Box component="span" sx={{ fontSize: 16, mt: 1 }}>
          {product.prd_name || ""}
        </Box>
        <Box component="span" sx={{ color: 'primary.main', fontSize: 22 }}>
          {/* 1유저 6개월 <strike>98,000원</strike> <br></br>58,800원 (40% sale) */}
          {product.prd_desc || ""}
        </Box>
        <Box
          sx={{
            mt: 1.5,
            p: 0.5,
            display: 'flex',
            fontSize: 12,
            alignItems: 'center',
            '& svg': {
              fontSize: 21,
              mr: 0.5,
            },
          }}
        >
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: { xs: 'center', md: 'flex-start' },
          m: 3,
          minWidth: { md: 100 }
        }}
      >
        {
          order.default_amt !== 0
          ? 
          <Box>
            <TextField
            id="outlined-number"
            label="개수"
            type="number"
            name="ord_count"
            value={ order.ord_count || "" }
            sx={{ width: 100 }}
            InputProps={{ inputProps: { min: 1 } }}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={handleInputChange}
          />
          </Box>
          :
          ""
        }

        <Box component="span" sx={{ fontSize: 16, mt: 1 }}>
          총금액 : {common.priceToString(order.ord_amt || order.default_amt)}원
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: { xs: 'center', md: 'flex-start' },
          m: 3,
          minWidth: { md: 100 }
        }}
      >
        <Box>
          <Button variant="contained" onClick={handleSubmit}>주문하기</Button>
        </Box>
      </Box>
    </Box>
  );
}