import * as React from 'react';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import styles from "../css/common.module.css";
import $ from 'jquery';
import { orderCreate, orderVbankcheck, orderCancel } from "../actions/order";
import http from "../http-common";
import { useLocation } from 'react-router-dom';

import { useTranslation, Trans } from 'react-i18next'; // 1. react-i18next import 

import * as common from "../lib";

export default function Main() {

	const authState = useSelector(state => state.auth);
    // console.log("authState : ", authState);

	const dispatch = useDispatch();	
	const location = useLocation();

	const { t, i18n } = useTranslation(); // 3. useTranslation hook 선언

	// console.log('location state : ', location.state);

	const [orderData, setOrderData] = useState(location?.state?.OrderData);
	const [payMethod, setPayMethod] = useState("card");
	const [inputEmail, setInputEmail] = useState(location?.state?.OrderData?.email);

	useEffect(() => {

		// 여기에서 jQuery를 초기화 합니다.
		$(document).ready(function(){

			// console.log("jquery ready!");
			$(window).scrollTop(0);

			// console.log("orderData : ", orderData);
		});

	}, [$]);

	const gotoUrl = (event, url) =>
	{
		event.preventDefault();
		window.location.href = url;
	}

	const payOnClick = (event) =>
	{
		event.preventDefault();

		// 결제수단이 가상계좌이면 오늘 하루 한도를 초과했는지 체크합니다. 
		// 하루에 가상계좌를 신청할 수 있는 횟수는 2회입니다.
		if (payMethod === "vbank")
		{
			dispatch(orderVbankcheck())
			.then(data => 
			{
				// console.log(data);
				// console.log(typeof(data));
				if (data >= 2)
				{
					// alert("오늘 신청하신 가상계좌 횟수는 " + data + "회입니다. \n가상계좌는 하루에 신청할 수 있는 회수가 2회로 제한되어 있습니다.");
					alert(`${t("Pay.alert.pay_string8", {data : data})}`);
					document.location.reload();
				}
				
			})
			.catch(e => {
				console.log(e);
			});
		}

		const sendOrder = {
			product_idx:orderData.product_idx,
			order_count:orderData.order_count,
			order_amount:orderData.order_amount
		};

		// console.log("sendOrder : ", sendOrder);
		
		// 여기서 디스패치로~
		dispatch(orderCreate(sendOrder))
		.then(data => 
		{
			let order = null;

			// console.log("data : ", data);
			if (!data.success)
			{
				alert(data.msg);
				return;
			}
			else
			{
				order = data.data;

				// 결제 창 호출
				const IMP = window.IMP; // 생략 가능
				// IMP.init("imp80753747"); // Example: imp00000000
				IMP.init(process.env.REACT_APP_IMP_UID); // Example: imp00000000

				// console.log("order : ", order);
				
				let mobileCompleteUrl = process.env.REACT_APP_API_URL + "/payment/mobile/complete";
				let param = { // param
					pg: process.env.REACT_APP_PAY_PG,
					pay_method: payMethod,
					merchant_uid: order.ord_no,
					name: order.prd_name,
					amount: order.ord_amt,
					buyer_email: inputEmail,
					buyer_name: order.mem_name,
					buyer_tel: order.mem_phone,
					m_redirect_url: mobileCompleteUrl,
				};

				// console.log("param : ", param);

				// IMP.request_pay(param, callback) 결제창 호출
				IMP.request_pay(param, rsp => { // callback

					// console.log("rsp : ", rsp);

					if (rsp.success) // 결제 성공 시: 결제 승인 또는 가상계좌 발급에 성공한 경우
					{ 
						// axios로 HTTP 요청
						const sendData = { imp_uid: rsp.imp_uid, merchant_uid: rsp.merchant_uid };
					
						http.post("payment/complete", sendData
						).then((data) => {
							// 서버 결제 API 성공시 로직
							// console.log(data);

							switch(data.data.status) 
							{
								case "vbankIssued":
									// 가상계좌 발급 시 로직
									// alert("가상계좌가 발급되었습니다. 결제 상세보기를 확인하시고 입금해주시면 처리가 완료됩니다.");
									alert(`${t("Pay.alert.pay_string9")}`);
									window.location.href = "/UserMypage/PayInfo";
									break;
								case "success":
									// 결제 성공 시 로직
									// alert("결제가 완료되었습니다. 결제내역을 확인해주세요.");
									alert(`${t("Pay.alert.pay_string10")}`);
									window.location.href = "/UserMypage/PayInfo";
									break;
							}
						})
					} 
					else 
					{
						// 결제에 실패하면 생성된 주문서도 삭제합니다.
						dispatch(orderCancel(order.ord_idx))
						.then(data => 
						{
							// console.log("data : ", data);
							// if (!data.success)
							// 	alert(data.msg);
						})
						.catch(e => {
							console.log(e);
						});

						// alert(`결제에 실패하였습니다. 에러 내용: ${rsp.error_msg}`);
						alert(`${t("Pay.alert.pay_string11", {msg : rsp.error_msg})}`);
					}
				});
			}

		})
		.catch(e => {
			console.log(e);
		});
	}

	const handleChange = (e) => 
	{
		// console.log(`*****handleChange*****`);
		// console.log(`선택한 값 : ${e.target.value}`);
		setPayMethod(e.target.value);
	};

	const handleEmailChange = (e) => 
	{
		// console.log(`*****handleChange*****`);
		// console.log(`선택한 값 : ${e.target.value}`);
		setInputEmail(e.target.value);
	};

  return (
    <>
      	{/* Header 분리됨 MainLayout Header에 있음 */}

		<div className={`${styles.pay_area} ${styles.order_area}`}>
			<div className={styles.pay_top_line_banner}>
				<div className={styles.inner}>
					<span>{/* 야미 구매하기 */}{t("Pay.title")}</span>
				</div>
			</div>
            <div className={styles.inner}>
                <div className={styles.order_wrap}>
                    <span className={styles.title}>{/* 결제 정보 */}{t("Pay.payment.pay_string1")}</span>
                    <ul>
                        <li>
                            <div className={styles.left_area}>
                                <span>{/* 상품명 */}{t("Pay.payment.pay_string2")}</span>
                            </div>
                            <div className={styles.right_area}>
                                <span>{orderData?.product_name || ""}</span>
                            </div>
                        </li>
                        <li>
                            <div className={styles.left_area}>
                                <span>{/* 이용기간 */}{t("Pay.payment.pay_string3")}</span>
                            </div>
                            <div className={styles.right_area}>
                                <span>{orderData?.period2 || ""}</span>
                            </div>
                        </li>
                        <li>
                            <div className={styles.left_area}>
                                <span>{/* 상품 금액 */}{t("Pay.payment.pay_string4")}</span>
                            </div>
                            <div className={styles.right_area}>
                                <span className={styles.order_price}>{common.priceToString(orderData?.sell_prc_unit || 0)}</span>
                            </div>
                        </li>
                        <li>
                            <div className={styles.left_area}>
                                <span>{/* 학습자(수량) */}{t("Pay.payment.pay_string5")}</span>
                            </div>
                            <div className={styles.right_area}>
                                <span>{orderData?.order_count || ""}{/* 명 */}{t("Pay.payment.person")}</span>
                            </div>
                        </li>
                        <li className={styles.order_total_price_wrap}>
                            <div className={styles.left_area}>
                                <span>{/* 총 결제 금액 */}{t("Pay.payment.pay_string6")}</span>
                            </div>
                            <div className={styles.right_area}>
                                <span className={styles.order_total_price}>{common.priceToString(orderData?.order_amount || 0)}</span>
                            </div>
                        </li>
                        <li className={styles.how_to_pay_wrap}>
                            <div className={styles.left_area}>
                                <span>{/* 결제 수단 */}{t("Pay.payment.pay_string7")}</span>
                            </div>
                            <div className={styles.right_area}>
                                <label className={styles.radio_btn_area}>
                                    <input type="radio" name="how_to_pay" value="card" defaultChecked onChange={(e)=>handleChange(e)}/>
                                    {/* 신용카드 */}{t("Pay.payment.pay_string8")}
                                </label>
                                <label className={styles.radio_btn_area}>
                                    <input type="radio" name="how_to_pay" value="trans" onChange={(e)=>handleChange(e)} />
                                    {/* 실시간 계좌이체 */}{t("Pay.payment.pay_string9")}
                                </label>
                                <label className={styles.radio_btn_area}>
                                    <input type="radio" name="how_to_pay" value="vbank" onChange={(e)=>handleChange(e)} />
                                    {/* 가상계좌 */}{t("Pay.payment.pay_string10")}
                                </label>
                            </div>
                        </li>
                    </ul>
                    <div className={styles.customer_info_title}>
                        <div></div>
                        <p>{/* 주문자 정보 */}{t("Pay.payment.pay_string11")}</p>
                    </div>
                    <ul className={styles.customer_info_wrap}>
                        <li>
                            <div className={styles.left_area}>
                                <span>{/* 이름 */}{t("Pay.payment.pay_string12")}</span>
                            </div>
                            <div className={styles.right_area}>
								<input type="text" defaultValue={orderData?.name || ""} readOnly/>
                            </div>
                        </li>
                        <li>
                            <div className={styles.left_area}>
                                <span>{/* 휴대폰 번호 */}{t("Pay.payment.pay_string13")}</span>
                            </div>
                            <div className={styles.right_area}>
								<input type="text" defaultValue={orderData?.phone || ""} readOnly/>
                            </div>
                        </li>
                        <li className={styles.vertical}>
                            <div className={styles.left_area}>
                                <span>{/* 이메일 */}{t("Pay.payment.pay_string14")}</span>
                            </div>
                            <div className={styles.right_area}>
								<input type="email" value={inputEmail || ""}  id={styles.order_customer_eamil} onChange={(e)=>handleEmailChange(e)} />
								<p>{/* * 애플 임시메일은 결제정보 수신 이메일을 변경하세요. */}{t("Pay.payment.pay_string15")} <br />
									{/* 결제정보의 정상적인 수신을 위해 애플 임시메일(@privaterelay.appleid.com)은 꼭 수정부탁드립니다. */}{t("Pay.payment.pay_string16")}</p>
                            </div>
                        </li>
                    </ul>
                    <div className={styles.btn_area}>
                        <button type="button" onClick={(e) => gotoUrl(e, '/Pay')}>{/* 취소 */}{t("Pay.payment.pay_string17")}</button>
                        <button type="button" onClick={(e) => payOnClick(e)}>{/* 결제 */}{t("Pay.payment.pay_string18")}</button>
                    </div>
                </div>
            </div>
		</div>{/* //pay_area */}
		
		{/* Footer 분리됨 MainLayout Footer에 있음 */}
    </>
  );
}