import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';

import Box from '@mui/material/Box';
import Input from '@mui/material/Input';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import UserTypeDlg from './UserTypeDlg';

import { useState } from 'react';
import { useDispatch } from "react-redux";

import { categoryCreate } from "../../../actions/category";

import { useNavigate } from 'react-router-dom';

const ariaLabel = { 'aria-label': 'description' };

export default function CategoryWrite() {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const initialCategoryState = {
        category_name: null, 
        category_desc: null,
        category_users: null,
    };

    const [category, setCategory] = useState(initialCategoryState);
    const [chipData, setChipData] = useState([{ key: 0, label: '사용자 유형' }]);

    const handleInputChange = event => {
        const { name, value } = event.target;
        setCategory({ ...category, [name]: value });
    };

    const handleSubmit = (event) => {

        event.preventDefault();

        const { category_name, category_desc, category_users } = category;

        console.log("category_name : ", category_name);
        console.log("category_desc : ", category_desc);
        console.log("category_users : ", category_users);

        let userTypeArray = [0, 0, 0, 0];

        for (let i=0; i<category_users.length; i++)
        {
            if (category_users[i].label === "관리자")
                userTypeArray[0] = 1;
            else if (category_users[i].label === "일반")
                userTypeArray[1] = 1;
            else if (category_users[i].label === "소셜")
                userTypeArray[2] = 1;
            else if (category_users[i].label === "단체")
                userTypeArray[3] = 1;
        }

        console.log("userTypeArray : ", userTypeArray);

        let sendUserType = userTypeArray[0] + "-" + userTypeArray[1] + "-" + userTypeArray[2] + "-" + userTypeArray[3];
        console.log(sendUserType);

        let sendData = {
            category_name: category_name,
            category_desc: category_desc,
            category_users: sendUserType,
        }

        // 여기서 디스패치로~
        dispatch(categoryCreate(sendData))
        .then(data => 
        {
            console.log("data : ", data);
            if (!data.success)
                alert(data.msg);
            
            navigate("/dashboard/categoryList");
        })
        .catch(e => {
            console.log(e);
        });
    };

  return (
    <Card sx={{ minWidth: 275 }}>
      <CardContent>
        <Box
        sx={{
            '& > :not(style)': { m: 1, width: '400px' },
        }}
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit}
        >
        카테고리명 : <Input placeholder="카테고리 이름" id="category_name" name="category_name" inputProps={ariaLabel} onChange={handleInputChange} />
        </Box>
        <Box
        sx={{
            '& > :not(style)': { m: 1, width: '400px' },
        }}
        noValidate
        autoComplete="off"
        >
        <Box>카테고리 설명 </Box>
        <TextareaAutosize
            aria-label="minimum height"
            minRows={6}
            placeholder="카테고리 설명을 달아주세요."
            style={{ width: 500 }}
            onChange={handleInputChange}
            id="category_desc" 
            name="category_desc"
        />
        </Box>
        <Box>
            <UserTypeDlg category={category} setCategory={setCategory} chipData={chipData} setChipData={setChipData} />
        </Box>
      </CardContent>
      <CardActions>
        <Button 
            onClick={handleSubmit} 
            size="small" 
            variant="contained"
            sx={{marginLeft:2}}
        >상품등록</Button>
        <Button 
            href={`/dashboard/CategoryList`} 
            size="small" 
            variant="contained"
            sx={{marginLeft:2}}
        >목록</Button>
      </CardActions>
    </Card>
  );
}