import http from "../http-common";

const groupPaymentCreate = data => {

  //전송할 데이터에 파일이 있을경우
  const config = {
    headers : {
        'context-type' : 'multipart/form-data'
    }
  }

  return http.post("payment/group/payment/complete", data, config);
};

const groupPaymentUpdate = data => {

  //전송할 데이터에 파일이 있을경우
  const config = {
    headers : {
        'context-type' : 'multipart/form-data'
    }
  }

  return http.put("payment/group/payment/complete", data, config);
};

const groupPaymentStatus = data => {
  return http.put("payment/group/payment/status", data);
};

const PaymentService = {
  groupPaymentCreate,
  groupPaymentUpdate,
  groupPaymentStatus,
};

export default PaymentService;
