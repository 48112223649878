import * as React from 'react';
import Chart from "react-apexcharts";

import { useTranslation, Trans } from 'react-i18next'; // 1. react-i18next import 

export default function BasicTable(props) {

      const { t, i18n } = useTranslation(); // 3. useTranslation hook 선언

      let labelsDataArray = [];
      let ageDataArray = [];
      let ageDataArray2 = [];
      let ageDataArray3 = [];
      let ageDataArray4 = [];
      let ageDataArray5 = [];
      let ageDataArray6 = [];
      let ageDataArray7 = [];
      let ageDataArray8 = [];
      let ageDataArray9 = [];
      let ageDataArray10 = [];

      // console.log(props.RowData);

      for (let i=0; i<props.RowData?.length; i++)
      {
        labelsDataArray.push(props.RowData[i].mst_id);
        ageDataArray.push(props.RowData[i].cnt1);
        ageDataArray2.push(props.RowData[i].cnt2);
        ageDataArray3.push(props.RowData[i].cnt3);
        ageDataArray4.push(props.RowData[i].cnt4);
        ageDataArray5.push(props.RowData[i].cnt5);
        ageDataArray6.push(props.RowData[i].cnt6);
        ageDataArray7.push(props.RowData[i].cnt7);
        ageDataArray8.push(props.RowData[i].cnt8);
        ageDataArray9.push(props.RowData[i].cnt9);
        ageDataArray10.push(props.RowData[i].cnt10);
      }

      let state = {
          
          series: [
          {
            // name: '튜토리얼',
            name: `${t("ClassStudentChart.string6")}`,
            data: ageDataArray
          }, {
            // name: '순차',
            name: `${t("ClassStudentChart.string7")}`,
            data: ageDataArray2
          }, {
            // name: '반복',
            name: `${t("ClassStudentChart.string8")}`,
            data: ageDataArray3
          }, {
            // name: '선택',
            name: `${t("ClassStudentChart.string9")}`,
            data: ageDataArray4
          }, {
            // name: '스페셜',
            name: `${t("ClassStudentChart.string10")}`,
            data: ageDataArray5
          }, {
            // name: '파트1',
            name: `${t("ClassStudentChart.string11")}`,
            data: ageDataArray6
          }, {
            // name: '파트2',
            name: `${t("ClassStudentChart.string12")}`,
            data: ageDataArray7
          }, {
            // name: '파트3',
            name: `${t("ClassStudentChart.string13")}`,
            data: ageDataArray8
          }, {
            // name: '파트5',
            name: `${t("ClassStudentChart.string14")}`,
            data: ageDataArray9
          }, {
            // name: '파트5',
            name: `${t("ClassStudentChart.string15")}`,
            data: ageDataArray10
          }
          ],
          options: {
            chart: {
              type: 'bar',
              height: 350,
              stacked: true,
            },
            responsive: [{
              breakpoint: 480,
              options: {
                legend: {
                  position: 'bottom',
                  offsetX: -10,
                  offsetY: 0
                }
              }
            }],
            xaxis: {
              categories: labelsDataArray,
            },
            fill: {
              opacity: 1
            },
            legend: {
              position: 'right',
              offsetX: 0,
              offsetY: 50
            },
          },
    
    };

  return (
    <>
        <Chart options={state.options} series={state.series} type="bar" height={380} />
    </>
  );
}

