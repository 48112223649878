import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { DialogActions, DialogContent } from '@mui/material';
import TransferList from '../../../common/TransferList';

function SimpleDialog(props) {
  const { onClose, open, packageNo } = props;
  const [useCount, setUseCount] = React.useState(0);

  console.log("SimpleDialog packageNo : ", packageNo);

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>사용자 설정 ({useCount}/{props.orderCount || 0})</DialogTitle>
      <DialogContent>
        <TransferList packageNo={packageNo} orderCount={props.orderCount} setUseCount={setUseCount} />
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleClose}>닫기</Button>
      </DialogActions>
    </Dialog>
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default function PackageDialog(props) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
    props.refreshFunction();
  };

  return (
    <div>
      <Button variant="outlined" onClick={handleClickOpen}>
        패키지 사용자 설정
      </Button>
      <SimpleDialog
        open={open}
        onClose={handleClose}
        packageNo={props.packageNo}
        orderCount={props.orderCount}
      />
    </div>
  );
}