import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import styles from "../../css/common.module.css";
import $ from 'jquery';
import { useParams } from 'react-router-dom';

import { couponUserList, couponRegister } from "../../actions/coupon";

import * as common from "../../lib";

import { useTranslation, Trans } from 'react-i18next'; // 1. react-i18next import 

import GroupMypageCouponExcelUploadDialog from './GroupMypageCouponExcelUploadDialog';

export default function MypagePayInfo() {

    const coupon = useSelector(state => state.coupon);
    // console.log("coupon : ", coupon);

	const dispatch = useDispatch();	

	const { t, i18n } = useTranslation(); // 3. useTranslation hook 선언

	const mypage_tab_btn_areaRef =  useRef();
    const hover_bgRef =  useRef();
    const onRef =  useRef();

	const childRef = useRef();

	let board_page  = useParams().page;
    // console.log("board_page : ", board_page);
    let board_condition  = useParams().condition;
    // console.log("board_condition : ", board_condition);

    if (board_page === undefined)
      board_page = 1;

    if (board_condition === undefined)
      board_condition = "";
    else if(board_condition === "선택")
      board_condition = "";

	const [couponValue, setCouponValue] = useState("");
	const [couponState, setCouponState] = useState("");

	useEffect(() => {

		// 여기에서 jQuery를 초기화 합니다.
		// jquery ready 호출. jquery3 부터 변경됨
		$(function(){
			// console.log("jquery ready!");

            //마이페이지 탭 버튼(활성화 효과)
			$(`.${styles.mypage_tab_btn_area} li`).on('click',function()
			{
				let tg=$(this);
				
				$(`.${styles.mypage_tab_btn_area} li`).removeClass(`${styles.on}`);
				tg.addClass(`${styles.on}`);
			});
            
            //마이페이지 탭 버튼(후버 효과)
            $(window).resize(function()
			{
				if (window.innerWidth < 1200) 
				{ 
					$(`.${styles.mypage_tab_btn_area} li.${styles.hover_bg}`).css('display','none');
				}
				else
				{
					let nowbtnNum=$(`.${styles.mypage_tab_btn_area} li.${styles.on}`).index();
					let hoverBg=$(`.${styles.mypage_tab_btn_area} li.${styles.hover_bg}`);

					$(`.${styles.mypage_tab_btn_area} ul`).on('mouseenter',function()
					{
						
						$(`.${styles.mypage_tab_btn_area} li`).removeClass(`${styles.on}`);
						hoverBg.css('display','block');
						
						$(`.${styles.mypage_tab_btn_area} li`).on('mouseenter',function()
						{
							let tgIndex=$(this).index();
							
							if(tgIndex===1)
							{
								hoverBg.stop().css('left','167px').css('width','116px');
							}
							else if(tgIndex===2)
							{
								hoverBg.stop().css('left','300px').css('width','150px');
							}
							else if(tgIndex===3)
							{
								hoverBg.stop().css('left','467px').css('width','116px');
							}
							else if(tgIndex===4)
							{
								hoverBg.stop().css('left','624px').css('width','102px');
							}
							else if(tgIndex===5)
							{
								hoverBg.stop().css('left','774px').css('width','102px');
							}
							else if(tgIndex===6)
							{
								hoverBg.stop().css('left','924px').css('width','102px');
							};
						});
						
					}).on('mouseleave',function()
					{
						$(`.${styles.mypage_tab_btn_area} li`).eq(nowbtnNum).addClass(`${styles.on}`);
						hoverBg.css('display','none');
						
						if(nowbtnNum===0)
						{
							hoverBg.css({left:'167px'}).css({width:'116px'});
						}
						else if(nowbtnNum===1)
						{
							hoverBg.css({left:'300px'}).css({width:'150px'});
						}
						else if(nowbtnNum===2)
						{
							hoverBg.css({left:'467px'}).css({width:'116px'});
						}
						else if(nowbtnNum===3)
						{
							hoverBg.css({left:'624px'}).css({width:'102px'});
						}
						else if(nowbtnNum===4)
						{
							hoverBg.css({left:'774px'}).css({width:'102px'});
						}
						else if(nowbtnNum===5)
						{
							hoverBg.css({left:'924px'}).css({width:'102px'});
						};

					});
					
				}
			}).resize();
			
			//결제 정보 페이지 페이징 버튼	
			$(`.${styles.list_pagebtn_area} li`).on('click',function() 
			{
				let tg=$(this);
				let num=tg.index();
				let prev;
				let next;
				let last;
				let nowBtn=$(`.${styles.list_pagebtn_area}`).children(`.${styles.on}`);
				let nowIndex=nowBtn.index();
				
				$(`.${styles.list_pagebtn_area} li`).removeClass(`${styles.on}`);
				
				if(tg.hasClass(`${styles.arrow_btn}`)==true)
				{
					if(tg.hasClass(`${styles.first}`)==true)
					{
						tg=$(`.${styles.list_pagebtn_area} li`).eq(2);
					}
					else if(tg.hasClass(`${styles.prev}`)==true)
					{
						prev=nowIndex-1;
						
						if(nowIndex===2)
						{
							tg=$(`.${styles.list_pagebtn_area} li`).eq(2);
						}else
						{
							tg=$(`.${styles.list_pagebtn_area} li`).eq(prev);
						};
						// console.log("prev click");
					}
					else if(tg.hasClass(`${styles.next}`)==true)
					{
						let lastNum=$(`.${styles.list_pagebtn_area} li`).last().index();
						next=nowIndex+1;
						last=lastNum-2;
						
						if(nowIndex===last)
						{
							tg=$(`.${styles.list_pagebtn_area} li`).eq(last);
						}else
						{
							tg=$(`.${styles.list_pagebtn_area} li`).eq(next);
						};
						// console.log("next click");
					}
					else if(tg.hasClass(`${styles.last}`)==true)
					{
						last=num-2;
						tg=$(`.${styles.list_pagebtn_area} li`).eq(last);
					};
					
					tg.addClass(`${styles.on}`);
					
				}
				else
				{
					tg.addClass(`${styles.on}`);
				};
			});

			// 쿠폰 리스트 수량
			// $(`.${styles.coupon_count}`).text($(`.${styles.coupon_list_wrap} .${styles.list}`).length);

			//쿠폰 카테고리 셀렉트 값 콘솔
			$(`#${styles.coupon_categori_select}`).on('change',function()
			{
				let coupon_categori=$(`#${styles.coupon_categori_select}`).val();
				// console.log(coupon_categori);

				//보유쿠폰 수량체크
				// let couponCount=$(`.${styles.coupon_list_wrap} .${styles.list}`).length;
				// console.log(couponCount);
				// $(`.${styles.coupon_count}`).text(couponCount);
			});

		});

		const boardListSend = {
			board_page : board_page,
			condition : board_condition,
		  }

		// 여기서 디스패치로~
        dispatch(couponUserList(boardListSend))
        .then(data => 
        {
            // console.log("data : ", data);
        })
        .catch(e => {
            console.log(e);
        });

	}, [$, dispatch]);

	const onCouponRegister = (event) => 
	{
		event.preventDefault();

		// console.log(couponValue);

		if (couponValue === "")
		{
			// return alert("쿠폰번호를 입력해주세요.");
			return alert(`${t("MypageCoupon.alert.string1")}`);
		}

		const sendData = {
			cou_number : couponValue
		  }

		// 여기서 디스패치로~
        dispatch(couponRegister(sendData))
        .then(data => 
        {
            // console.log("data : ", data);
			if (!data.success)
			{
				window.location.reload();
				return alert(data.msg);
			}
			else
			{
				window.location.reload();
				// return alert("쿠폰이 등록 되었습니다.");
				return alert(`${t("MypageCoupon.alert.string2")}`);
			}
        })
        .catch(e => {
            console.log(e);
        });
	}
	
	const onChangeCouponValue = (event) => {
		setCouponValue(event.target.value);
	}

	const onChangeState = (event) => 
	{
		event.preventDefault();

		// console.log(event.target.value);
		setCouponState(event.target.value);

		// const boardListSend = {
		// 	board_page : board_page,
		// 	condition : event.target.value,
		//   }

		// // 여기서 디스패치로~
        // dispatch(couponUserList(boardListSend))
        // .then(data => 
        // {
        //     // console.log("data : ", data);
        // })
        // .catch(e => {
        //     console.log(e);
        // });

		window.location.href = `/GroupUserMypage/GroupCouponInfo/1/${event.target.value}`;
		
	}

	const pagingReturn = (paging) =>
	{
		// console.log("paging : ", paging);

		const rendering = () => {
			const result = [];
			for (let i = paging.startPage; i <= paging.endPage; i++) {

				if (i === paging.curPage)
					result.push(<li className={styles.on} key={i}><a href={`/GroupUserMypage/GroupCouponInfo/${i}${board_condition ? "/" + board_condition : ""}`}>{i}</a></li>);
				else
					result.push(<li key={i}><a href={`/GroupUserMypage/GroupCouponInfo/${i}${board_condition ? "/" + board_condition : ""}`}>{i}</a></li>);
			}
			return result;
		};

		// 이전 페이지
		let prevPage = 0;

		if (paging.totalPage === 1 || paging.curPage === 1)
			prevPage = 1;
		else
			prevPage = paging.curPage - 1;

		// console.log("prevPage : ", prevPage);

		// 다음 페이지
		let nextPage = 0;

		if (paging.totalPage === 1)
			nextPage = 1;
		else if (paging.curPage === paging.totalPage)
			nextPage = paging.endPage;
		else
			nextPage = paging.curPage + 1;

		// console.log("nextPage : ", nextPage);

		return (
		<ul className={styles.list_pagebtn_area}>
			<li className={`${styles.arrow_btn} ${styles.first}`}><a href={`/GroupUserMypage/GroupCouponInfo/1${board_condition ? "/" + board_condition : ""}`}><img src="/assets/first_btn@2x.png" alt="첫번째 페이지로 이동"/></a></li>
			<li className={`${styles.arrow_btn} ${styles.prev}`}><a href={`/GroupUserMypage/GroupCouponInfo/${prevPage}${board_condition ? "/" + board_condition : ""}`}><img src="/assets/prev_btn@2x.png" alt="이전 페이지로 이동"/></a></li>
			{
				rendering()
			}
			<li className={`${styles.arrow_btn} ${styles.next}`}><a href={`/GroupUserMypage/GroupCouponInfo/${nextPage}${board_condition ? "/" + board_condition : ""}`}><img src="/assets/next_btn@2x.png" alt="다음 페이지로 이동"/></a></li>
			<li className={`${styles.arrow_btn} ${styles.last}`}><a href={`/GroupUserMypage/GroupCouponInfo/${paging.totalPage}${board_condition ? "/" + board_condition : ""}`}><img src="/assets/last_btn@2x.png" alt="마지막 페이지로 이동"/></a></li>
		</ul>
		);
	};

	const onReadyClick = () => 
	{
		// alert("서비스 중비중입니다.");
		alert(`${t("Common.service_wait")}`);
	};

	const handleButtonClick = () => {
		// `ref`를 통해 하위 컴포넌트 함수 호출
		// childRef.current.childFunction('Hello from ParentComponent');
		childRef.current.handleClickOpen();
	  };

  return (
    <>
      	{/* Header 분리됨 MainLayout Header에 있음 */}

		<GroupMypageCouponExcelUploadDialog ref={childRef} />
		<div className={styles.mypage_top_line_banner}>
			<div className={styles.inner}>
				<span>{/* 학부모 페이지 */}{t('Common.Mypage_title2')}</span>
			</div>
		</div>
		<div className={styles.mypage_tab_btn_area} ref={mypage_tab_btn_areaRef}>
			<div className={styles.inner}>
				<ul>
					<li className={styles.hover_bg} ref={hover_bgRef}></li>
					<li><a href="/GroupUserMypage/GroupReport">{/* 클래스 관리 */}{t('Common.Group_SubMenu.menu1')}</a></li>
					<li><a href="/GroupUserMypage/GroupParentsInfo">{/* 관리자 정보 */}{t('Common.Group_SubMenu.menu2')}</a></li>
					<li><a href="/GroupUserMypage/GroupPayInfo">{/* 결제 관리 */}{t('Common.Group_SubMenu.menu3')}</a></li>
					<li className={styles.on} ref={onRef}><a href="/GroupUserMypage/GroupCouponInfo">{/* 쿠폰관리 */}{t('Common.Group_SubMenu.menu4')}</a></li>
					{/* <li><a href="/GroupUserMypage/GroupStudentInfo">공지사항</a></li>
					<li><a href="/GroupUserMypage/GroupQnaInfo">질문과 답변</a></li> */}
					<li><a href="#!" onClick={()=>onReadyClick()}>{/* 공지사항 */}{t('Common.Group_SubMenu.menu5')}</a></li>
					<li><a href="#!" onClick={()=>onReadyClick()}>{/* 질문과 답변 */}{t('Common.Group_SubMenu.menu6')}</a></li>
				</ul>
			</div>
		</div>
		<div className={`${styles.mypage_tab} ${styles.mypage_coupon}`}>
			<div className={styles.inner}>
				<span className={styles.my_tit}>{/* 쿠폰 관리 */}{t('MypageCoupon.title')}</span>
                <p className={styles.coupon_title_info}>{/* 쿠폰은 등록한 날부터 사용일이 차감됩니다 */}{t('MypageCoupon.string1')}</p>
                <div className={styles.coupon_register_area}>
                    <input type="text" value={couponValue} onChange={onChangeCouponValue} id={styles.coupon_register_input} /* placeholder="쿠폰 번호를 입력해 주세요" */ placeholder={t('MypageCoupon.string2')} />
                    <button type="button" onClick={(e)=>onCouponRegister(e)}>{/* 등록 */}{t('MypageCoupon.register')}</button>
					<button type="button" onClick={(e)=>handleButtonClick(e)}>{/* 대량등록 */}{t('MypageCoupon.string29')}</button>
                </div>
				<div className={styles.coupon_list_area}>
                    <div className={styles.coupon_list_top_area}>
                        <div className={styles.title}>
                            <div></div>
                            <p>{/* 보유쿠폰의 개수 */}{t('MypageCoupon.string3')}</p>
                            <span className={styles.coupon_count}>&nbsp; {coupon?.userpaging?.totalPageCount || 0}</span>
                        </div>
                        <select name="coupon_categori" id={styles.coupon_categori_select} value={board_condition === undefined ? couponState : board_condition} onChange={onChangeState}>
							<option value="">{/* 전체 */}{t('MypageCoupon.string4')}</option>
							<option value="W">{/* 사용대기 */}{t('MypageCoupon.string5')}</option>
							<option value="A">{/* 사용중 */}{t('MypageCoupon.string6')}</option>
							<option value="S">{/* 중지상태 */}{t('MypageCoupon.string7')}</option>
						</select>
                    </div>
					<ul className={styles.coupon_list_wrap}>
						<li className={styles.list_head}>
							<div className={styles.coupon_name}>
								<span>{/* 쿠폰명 */}{t('MypageCoupon.string8')}</span>
							</div>
							<div className={styles.coupon_use_date}>
								<span>{/* 사용기간 */}{t('MypageCoupon.string9')}</span>
							</div>
							<div className={styles.coupon_state}>
								<span>{/* 쿠폰상태 */}{t('MypageCoupon.string10')}</span>
							</div>
							<div></div> 
						</li>
						{
							coupon?.userlist?.length === 0
							? <li className={styles.no_list}>
								<span>{/* 보유한 쿠폰이 없습니다 */}{t('MypageCoupon.string11')}</span>
							</li>
							: null
						}

						{coupon?.userlist && coupon?.userlist.map((row, index) => (
							<li className={styles.list} key={index}>
								<div className={styles.top_area}>
									<span className={styles.coupon_name}>{row.cou_name}</span>
									{
										row.cou_valid_end_date === null ? "" : <span className={styles.coupon_d_day}>{common.retunDday(row.cou_valid_end_date)}</span>
									}
								</div>
								<div className={styles.middle_area}>
									<div className={styles.coupon_use_date}>
										{
											row.cou_valid_end_date === null ? <span>{/* 사용전 */}{t('MypageCoupon.string12')}</span> :
											<span>{common.dateChangeReturn(row.cou_valid_start_date)} ~ {common.dateChangeReturn(row.cou_valid_end_date)}</span>
										}
									</div>
									<div className={styles.coupon_state}>
										<span>{common.returnCouponState(row.cou_state)}</span>
									</div>
								</div>
								<div className={styles.bottom_area}>
									<a href={`/GroupUserMypage/GroupCouponInfoDetail/${row.cou_number}/${board_page}${board_condition ? "/" + board_condition : ""}`}>{/* 쿠폰 상세보기 */}{t('MypageCoupon.string13')}</a>
								</div>
							</li>
						))}
						{/* <li className={styles.no_list}>
							<span>보유한 쿠폰이 없습니다</span>
						</li> */}
						{/* <li className={styles.list}>
							<div className={styles.top_area}>
								<span className={styles.coupon_name}>[오픈이벤트]야미 30일 이용가능 쿠폰</span>
								<span className={styles.coupon_d_day}>D-12</span>
							</div>
							<div className={styles.middle_area}>
								<div className={styles.coupon_use_date}>
									<span>2022.09.01 ~ 2022.10.01</span>
								</div>
								<div className={styles.coupon_state}>
									<span>사용중</span>
								</div>
							</div>
							<div className={styles.bottom_area}>
								<a href="coupon_info_more.html">쿠폰 상세보기</a>
							</div>
						</li> */}
						{/* <li className={styles.list}>
							<div className={styles.top_area}>
								<span className={styles.coupon_name}>[오픈이벤트]야미 30일 이용가능 쿠폰야미 30일 이용가능 쿠폰야미 30일 이용가능 쿠폰</span>
								<span className={styles.coupon_d_day}>D-12</span>
							</div>
							<div className={styles.middle_area}>
								<div className={styles.coupon_use_date}>
									<span>2022.09.01 ~ 2022.10.01</span>
								</div>
								<div className={styles.coupon_state}>
									<span>사용중</span>
								</div>
							</div>
							<div className={styles.bottom_area}>
								<a href="coupon_info_more.html">쿠폰 상세보기</a>
							</div>
						</li> */}
						{/* <li className={`${styles.list} ${styles.end}`}>
							<div className={styles.top_area}>
								<span className={styles.coupon_name}>[야미 오픈 축하 기념]야미 30일 이용가능 체험판 쿠폰야미 30일 이용가능 체험판 쿠폰</span>
								<span className={styles.coupon_d_day}>D-12</span>
							</div>
							<div className={styles.middle_area}>
								<div className={styles.coupon_use_date}>
									<span>2022.08.01 ~ 2022.09.01</span>
								</div>
								<div className={styles.coupon_state}>
									<span>기간만료</span>
								</div>
							</div>
							<div className={styles.bottom_area}>
								<a href="coupon_info_more.html">쿠폰 상세보기</a>
							</div>
						</li> */}
					</ul>
					{/* <button type="button" className={styles.list_more_btn}>+ 더보기</button> */}
					{coupon?.userpaging && coupon?.userpaging?.totalPageCount > 0 ? pagingReturn(coupon?.userpaging) : ""}
					{/* <ul className={styles.list_pagebtn_area}>
						<li className={`${styles.arrow_btn} ${styles.first}`}><a href="#"><img src="/assets/first_btn@2x.png" alt="첫번째 페이지로 이동"/></a></li>
						<li className={`${styles.arrow_btn} ${styles.prev}`}><a href="#"><img src="/assets/prev_btn@2x.png" alt="이전 페이지로 이동"/></a></li>
						<li className={styles.on}><a href="#">1</a></li>
						<li><a href="#">2</a></li>
						<li><a href="#">3</a></li>
						<li><a href="#">4</a></li>
						<li><a href="#">5</a></li>
						<li><a href="#">6</a></li>
						<li><a href="#">7</a></li>
						<li><a href="#">8</a></li>
						<li><a href="#">9</a></li>
						<li><a href="#">10</a></li>
						<li className={`${styles.arrow_btn} ${styles.next}`}><a href="#"><img src="/assets/next_btn@2x.png" alt="다음 페이지로 이동"/></a></li>
						<li className={`${styles.arrow_btn} ${styles.last}`}><a href="#"><img src="/assets/last_btn@2x.png" alt="마지막 페이지로 이동"/></a></li>
					</ul> */}
					<div className={styles.coupon_info_area}>
						<div className={styles.top_area}>
							<span className={styles.icon}>!</span>
							<span>{/* 쿠폰 안내 */}{t('MypageCoupon.string14')}</span>
						</div>
						<ul className={styles.bottom_area}>
							<li>{/* - 쿠폰은 사용하는 날짜부터 사용일이 차감됩니다. */}{t('MypageCoupon.string15')}</li>
							<li>{/* - 쿠폰 번호를 '쿠폰 관리'페이지 상단에 입력하시고 '등록'버튼을 클릭하시면 쿠폰이 등록됩니다. */}{t('MypageCoupon.string16')}</li>
							<li>{/* - 쿠폰에 대한 상세정보는 '쿠폰 상세정보'페이지에서 확인하실 수 있습니다. */}{t('MypageCoupon.string17')}</li>
						</ul>
					</div>
				</div>
			</div> {/* inner */}
		</div> {/* mypage_tab */}
		
		{/* Footer 분리됨 MainLayout Footer에 있음 */}
    </>
  );
}