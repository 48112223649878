import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';

import { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { knowledgeList, knowledgeDelete } from "../../../actions/knowledge";
import { knowledgeCategoryList } from "../../../actions/knowledge_category";

import { useNavigate } from 'react-router-dom';

import * as common from "../../../lib";

export default function ProductList() {

    const knowledge = useSelector(state => state.knowledge);
    console.log("knowledge : ", knowledge);
    
    const category = useSelector(state => state.knowledge_category);
    console.log("category : ", category);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleWriteKnowledge = (event) => {
      navigate("/dashboard/KnowledgeWrite");
    };

    const returnCategory = (idx) => {
      for (let i=0; i<category.length; i++)
      {
        if (idx === category[i].kc_category_idx)
        {
          return category[i].kc_category_name;
        }
      }
    }

    const handleDeleteKnowledge = (event, idx) => {
      console.log(idx);

      if (window.confirm("정말 삭제하시겠습니까?\n삭제하시면 그 문제를 푼 사용자 정보 또한 모두 삭제됩니다.") === true)   //확인
      {
        // 여기서 디스패치로~
        dispatch(knowledgeDelete(idx))
        .then(data => 
        {
            console.log("data : ", data);
            if (!data.success)
            {
              alert(data.msg);
              return;
            }

            // navigate("/dashboard/KnowledgeList");
            dispatch(knowledgeList())
            .then(data => 
            {
                console.log("data : ", data);
            })
            .catch(e => {
                console.log(e);
            });

            dispatch(knowledgeCategoryList())
            .then(data => 
            {
                console.log("data : ", data);
            })
            .catch(e => {
                console.log(e);
            });

        })
        .catch(e => {
            console.log(e);
        });
      }
      else  //취소
      {
        return false;
      }

    };

    useEffect(() => {

        // 여기서 디스패치로~
        dispatch(knowledgeList())
        .then(data => 
        {
            console.log("data : ", data);
        })
        .catch(e => {
            console.log(e);
        });

        dispatch(knowledgeCategoryList())
        .then(data => 
        {
            console.log("data : ", data);
        })
        .catch(e => {
            console.log(e);
        });
        
        return () => {      // 언마운트
            console.log("언마운트!");
        };

    }, [dispatch])

  return (
    <Card sx={{ minWidth: 275 }}>
      <CardContent>
      지식은행 리스트
      <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="center">번호</TableCell>
            <TableCell align="center">제목</TableCell>
            <TableCell align="center">카테고리</TableCell>
            <TableCell align="center">수정</TableCell>
            <TableCell align="center">삭제</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {knowledge && knowledge.map((row, index) => (
            <TableRow
              key={index}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row" align="center">
                {row.ki_idx}
              </TableCell>
              <TableCell align="center"><Link href={`/dashboard/KnowledgeView/${row.ki_idx}`} underline="none">{row.ki_title}</Link></TableCell>
              <TableCell align="center">{returnCategory(row.kc_category_idx)}</TableCell>
              <TableCell align="center"><Link href={`/dashboard/KnowledgeModify/${row.ki_idx}`} underline="none">수정</Link></TableCell>
              <TableCell align="center"><Link onClick={(e)=>handleDeleteKnowledge(e, row.ki_idx)} value={row.ki_idx} underline="none">삭제</Link></TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      </TableContainer>

      </CardContent>
      <CardActions>
        <Button onClick={handleWriteKnowledge} size="small">추가</Button>
      </CardActions>
    </Card>
  );
}