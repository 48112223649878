import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';

import Box from '@mui/material/Box';
import Input from '@mui/material/Input';
import TextareaAutosize from '@mui/material/TextareaAutosize';

import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import {
    userMe
  } from "../../../actions/users";

import { categoryModify, categorySelect, categoryDelete } from "../../../actions/category";

import { useNavigate, useParams } from 'react-router-dom';
import { Link } from '@mui/material';

const ariaLabel = { 'aria-label': 'description' };

export default function CategoryView() {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const category_idx  = useParams().category_idx;
    console.log("category_idx : ", category_idx);

    const initialCategoryState = {
        category_name: null, 
        category_desc: null
    };

    const [category, setCategory] = useState(initialCategoryState);

    const handleDeleteCategory = (event, idx) => {
        console.log(idx);
  
        if (window.confirm("정말 삭제하시겠습니까??") === true)   //확인
        {
          // 여기서 디스패치로~
          dispatch(categoryDelete(idx))
          .then(data => 
          {
              console.log("data : ", data);
          })
          .catch(e => {
              console.log(e);
          });
  
          navigate("/dashboard/categoryList");  
        }
        else  //취소
        {
          return false;
        }
  
      };

    useEffect(() => {
        
        console.log("dispatch categorySelect");
        dispatch(categorySelect(category_idx))
        .then(data => 
        {
            console.log("data : ", data[0]);
            setCategory({category_name: data[0].category_name, category_desc: data[0].category_desc});
        })
        .catch(e => {
            console.log(e);
        });

        return () => {      // 언마운트
            console.log("언마운트!");
        };

    }, [dispatch])

  return (
    <Card sx={{ minWidth: 275 }}>
      <CardContent>
        <Box
        sx={{
            '& > :not(style)': { m: 1, width: '400px' },
        }}
        >
        카테고리 이름 : {category.category_name || ''}
        </Box>
        <Box
        sx={{
            '& > :not(style)': { m: 1, width: '400px' },
        }}
        >
        카테고리 설명 : {category.category_desc || ''}
        </Box>
      </CardContent>
      <CardActions>
        <Link href={`/dashboard/CategoryList`} underline="none">목록</Link>
        <Link href={`/dashboard/CategoryModify/${category_idx}`} underline="none">수정</Link>
        <Link onClick={(e)=>handleDeleteCategory(e, category_idx)} value={category_idx} underline="none">삭제</Link>
      </CardActions>
    </Card>
  );
}