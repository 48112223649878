import React from 'react';
import { useGoogleLogout } from 'react-google-login';
import { useDispatch } from "react-redux";
import { logout } from "../../actions/auth";

// 라이브
// const clientId = '42504825298-eavp5n14pprd0prdghkbc89f36l7k1r0.apps.googleusercontent.com';
// 테스트
// const clientId = '42504825298-k19tgk27i479770tidlsfk00shclgjl5.apps.googleusercontent.com';
let clientId = `${process.env.REACT_APP_CLIENT_ID}`;

function GoogleLogoutButton() {

    const dispatch = useDispatch();

  const onLogoutSuccess = (res) => {
    // console.log('Logged out Success');
    alert('Logged out Successfully ✌');
    dispatch(logout());
  };

  const onFailure = () => {
    // console.log('Handle failure cases');
  };

  const { signOut } = useGoogleLogout({
    clientId,
    onLogoutSuccess,
    onFailure,
  });

  return (
    <button onClick={signOut} className="button">
      <img src="GoogleLogout.png" alt="google out" className="icon"></img>
    </button>
  );
}

export default GoogleLogoutButton;