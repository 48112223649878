import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import { Link, Grid, Typography, Divider, Button } from '@mui/material';

import { useDispatch } from "react-redux";
import { groupOrderSelect } from "../../../actions/group_order";
import { groupPaymentStatus } from "../../../actions/group_payment";

import { useEffect, useState } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import * as common from "../../../lib";
import http from "../../../http-common";

// 결제 정보에 대한 컴포넌트
const MyPayment = (props) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
  
    const handleDownload = async (download, filename) => 
    {
      const response = await fetch(download);
      const blob = await response.blob();
      const url = URL.createObjectURL(blob);
  
      const link = document.createElement('a');
      link.href = url;
      link.download = filename; // 다운로드할 파일명
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

    };

    const handlePayment = (e) => {

      const PaymentData = {
        bpay_state: "active",             // 결제상태로 변경함
        bpay_idx: props.payment.bpay_idx, 
      };

      dispatch(groupPaymentStatus(PaymentData))
        .then(data => 
        {
            console.log("data : ", data);

            if (!data.success)
              alert(data.msg);

            navigate(`/dashboard/GroupOrderList`);
        })
        .catch(e => {
            console.log(e);
        });
      
    };
  
  return (
          <>
            <Grid item xs={12}> <Divider variant="middle" /> </Grid>
            <Grid item xs={12}> <Typography variant="h6" align="center">결제 정보</Typography> </Grid>
            <Grid item xs={12}> <Divider variant="middle" /> </Grid>
            <Grid item xs={4} md={3}>
            결제번호 
            </Grid>
            <Grid item xs={6} md={8}>
            {props.payment.bpay_no || ""}
            </Grid>
            <Grid item xs={4} md={3}>
            결제금액
            </Grid>
            <Grid item xs={6} md={8}>
            {common.priceToString(props.payment.bpay_amt) || 0}원
            </Grid>
            <Grid item xs={4} md={3}>
            결제 승인 일시
            </Grid>
            <Grid item xs={6} md={8}>
            {props.payment.bpay_date || "-"}
            </Grid>
            <Grid item xs={4} md={3}>
            결제상태 
            </Grid>
            <Grid item xs={6} md={8}>
            {common.returnGroupPaymentState(props.payment.bpay_state) || ""}
            </Grid>
            <Grid item xs={4} md={3}>
            입금계좌정보
            </Grid>
            <Grid item xs={6} md={8}>
            {props.payment.bank_name || ""} (예금주 : {props.payment.bank_owner || ""}) <br></br>계좌번호{props.payment.bank_num || ""}
            </Grid>
            <Grid item xs={4} md={3}>
            사업자등록증 사본
            </Grid>
            <Grid item xs={6} md={8}>
            {props.payment.bl_photo_file === null || props.payment.bl_photo_file === "" ? "" : 
            <a href="#!" onClick={() => handleDownload(process.env.REACT_APP_IMG_URL + props.payment.bl_photo_path + "/" + props.payment.bl_photo_file, props.payment.bl_photo_file)}>통장사본 다운로드</a> }
            </Grid>
            <Grid item xs={4} md={3}>
            통장사본
            </Grid>
            <Grid item xs={6} md={8}>
            {props.payment.bc_photo_file === null || props.payment.bc_photo_file === "" ? "" : 
            <a href="#!" onClick={() => handleDownload(process.env.REACT_APP_IMG_URL + props.payment.bc_photo_path + "/" + props.payment.bc_photo_file, props.payment.bc_photo_file)}>사업자등록증 다운로드</a> }
            </Grid>
            <Grid item xs={4} md={3}>
            승인여부
            </Grid>
            <Grid item xs={6} md={8}>
            {/* { props.payment.bpay_idx !== null && props.payment.bpay_state === "confirm"  ? <Button onClick={(e)=>handlePayment(e)} variant="outlined">결제 승인</Button> : "승인완료" } */}
            {
              {
                wait : `결제대기`,
                confirm : <Button onClick={(e)=>handlePayment(e)} variant="outlined">결제 승인</Button>,
                active : `결제완료`,
                cancel : `취소`,
              } [props.payment.bpay_state]
            }
            </Grid>
          </>
  );
};

// 환불 정보에 대한 컴포넌트
const MyRefund = (props) =>{
  return (
          <>
            <Grid item xs={12}> <Divider variant="middle" /> </Grid>
            <Grid item xs={12}> <Typography variant="h6" align="center">환불 정보</Typography> </Grid>
            <Grid item xs={12}> <Divider variant="middle" /> </Grid>
            <Grid item xs={4} md={3}>
            결제번호 : 
            </Grid>
            <Grid item xs={6} md={8}>
            {props.refund.payment_no || ""}
            </Grid>
            <Grid item xs={4} md={3}>
            환불사유 : 
            </Grid>
            <Grid item xs={6} md={8}>
            {props.refund.refund_desc || ""}
            </Grid>
            <Grid item xs={4} md={3}>
            환불금액 : 
            </Grid>
            <Grid item xs={6} md={8}>
            &#8361; {common.priceToString(props.refund.refund_amt) || ""}
            </Grid>
            <Grid item xs={4} md={3}>
            환불날짜 : 
            </Grid>
            <Grid item xs={6} md={8}>
            {props.refund.refund_date || ""}
            </Grid>
            <Grid item xs={4} md={3}>
            처리상태 : 
            </Grid>
            <Grid item xs={6} md={8}>
            {common.returnRefundState(props.refund.refund_stat_cd) || ""}
            </Grid>
            <Grid item xs={4} md={3}>
            영수증 확인 url : 
            </Grid>
            <Grid item xs={6} md={8}>
            <Link href={props.refund.receipt_url || "#"} underline="none" target="_blank">영수증 확인</Link>
            </Grid>
          </>
  );
};

export default function ProductModify() {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    
    const ord_idx  = useParams().ord_idx;
    // console.log("ord_idx : ", ord_idx);

    const initialOrderState = {
      bord_idx: null,
      bord_no: null,
      bord_count: null,
      bord_amt: null,
      bord_stat_cd: null,
      bord_date: null,
      bord_valid_date: null,
      prd_name: null,
      prd_idx: null,
      prd_desc: null,
      sell_prc_unit: null,
      prd_photo_file: null,
      prd_photo_path: null,
      mem_idx: null,
      mem_name: null,
      mem_email: null,
      mem_phone: null,
      bpay_idx: null,
      bpay_no: null,
      bpay_amt: null,
      bpay_date: null,
      bpay_state: null,
      bank_num: null,
      bank_owner: null,
      bank_name: null,
      tax_email: null,
      bl_photo_file: null,
      bl_photo_path: null,
      bc_photo_file: null,
      bc_photo_path: null,
      option1: null,
      option2: null
    };

    const [order, setOrder] = useState(initialOrderState);
    const [totalPrice, setTotalPrice] = useState(0);
	  const [totalPriceString, setTotalPriceString] = useState("");

    const handleCancelOrder = (event, idx) => {
      console.log(idx);

      if (window.confirm("정말 취소하시겠습니까??") === true)   //확인
      {
        // 여기서 디스패치로~
        // dispatch(groupOrderCancel(idx))
        // .then(data => 
        // {
        //     // console.log("data : ", data);
        //     if (!data.success)
        //         alert(data.msg);

        //     navigate("/dashboard/OrderList");
        // })
        // .catch(e => {
        //     console.log(e);
        // });
      }
      else  //취소
      {
        return false;
      }

    };

    const handleImgError = (e) => {
      e.target.src = "https://cdn-icons-png.flaticon.com/512/1474/1474713.png";
    };

    useEffect(() => {
        
        // console.log("dispatch orderSelect");
        dispatch(groupOrderSelect(ord_idx))
        .then(data => 
        {
            console.log("data : ", data[0]);
            setOrder({
              bord_idx: data[0].bord_idx,
              bord_no: data[0].bord_no,
              bord_count: data[0].bord_count,
              bord_amt: data[0].bord_amt,
              bord_stat_cd: data[0].bord_stat_cd,
              bord_date: data[0].bord_date,
              bord_valid_date: data[0].bord_valid_date,
              prd_name: data[0].prd_name,
              prd_idx: data[0].prd_idx,
              prd_desc: data[0].prd_desc,
              sell_prc_unit: data[0].sell_prc_unit,
              prd_photo_file: data[0].prd_photo_file,
              prd_photo_path: data[0].prd_photo_path,
              mem_idx: data[0].mem_idx,
              mem_name: data[0].mem_name,
              mem_email: data[0].mem_email,
              mem_phone: data[0].mem_phone,
              bpay_idx: data[0].bpay_idx,
              bpay_no: data[0].bpay_no,
              bpay_amt: data[0].bpay_amt,
              bpay_date: data[0].bpay_date,
              bpay_state: data[0].bpay_state,
              bank_num: data[0].bank_num,
              bank_owner: data[0].bank_owner,
              bank_name: data[0].bank_name,
              tax_email: data[0].tax_email,
              bl_photo_file: data[0].bl_photo_file,
              bl_photo_path: data[0].bl_photo_path,
              bc_photo_file: data[0].bc_photo_file,
              bc_photo_path: data[0].bc_photo_path,
              option1: data[0].option1,
              option2: data[0].option2
            });

            setTotalPriceString(totalPriceRetrun(data[0].option1, data[0].option2));
        })
        .catch(e => {
            console.log(e);
        });

    }, [dispatch])

    const totalPriceRetrun = (option1, option2) =>
    {
      let price1 = (parseInt(option1[0].bopo1_amt) * parseInt(option1[0].bopo1_count));
      let price2 = 0;
      let totalPrice = 0;

      for (let i=0; i<option2.length; i++)
      {
        price2 += (parseInt(option2[i].bopo2_price) * parseInt(option2[i].bopo2_count));
      }

      totalPrice = price1 + price2;

      // 부과세 계산
      let SAL_VAT   = (totalPrice)*0.1;    // 부가세(VAT)
      let SAL_PRICE = Math.round(totalPrice + SAL_VAT); // 단가(반올림)
      SAL_VAT   = Math.round(SAL_VAT);    // 부가세(반올림)

      setTotalPrice(SAL_PRICE);
      
      return common.priceToString(SAL_PRICE) + "원 (" + common.priceToString(SAL_VAT) + "원 부과세 포함된 가격)";
    };

  return (
    <Card sx={{ minWidth: 275, maxWidth: 700 }}>
      <CardContent>
        <Grid container spacing={2}>
            <Grid item xs={12}> <Typography variant="h6" align="center">주문 정보</Typography> </Grid>
            <Grid item xs={12}> <Divider variant="middle" /> </Grid>

            <Grid item xs={4} md={3}>
            주문번호 : 
            </Grid>
            <Grid item xs={6} md={8}>
            {order.bord_no || ""}
            </Grid>
            <Grid item xs={4} md={3}>
            주문자 : 
            </Grid>
            <Grid item xs={6} md={8}>
            {order.mem_name || ""}
            </Grid>
            <Grid item xs={4} md={3}>
            주문일 : 
            </Grid>
            <Grid item xs={6} md={8}>
            {order.bord_date || ""}
            </Grid>
            <Grid item xs={4} md={3}>
            주문상태 : 
            </Grid>
            <Grid item xs={6} md={8}>
            {common.returnOrderState(order.bord_stat_cd) || ""}
            </Grid>
            <Grid item xs={4} md={3}>
            상품명 : 
            </Grid>
            <Grid item xs={6} md={8}>
            {order.prd_name || ""}
            </Grid>
            <Grid item xs={4} md={3}>
            상품설명 : 
            </Grid>
            <Grid item xs={6} md={8}>
            {order.prd_desc || ""}
            </Grid>
            <Grid item xs={4} md={3}>
            제품 이미지
            </Grid>
            <Grid item xs={6} md={8}>
            {order.prd_photo_file ? <img src={common.returnImgSrc(order.prd_photo_path, order.prd_photo_file)} width="150"  onError={handleImgError} /> : "제품 이미지가 없습니다." }
            </Grid>
            <Grid item xs={4} md={3}>
            옵션1 주문 <br></br> (앱 이용권)
            </Grid>
            <Grid item xs={6} md={8}>
            {
									order?.option1?.length !== 0 
									? 
										"앱 이용권 " + common.priceToString(order?.option1?.[0].bopo1_amt || "") + "원 * " + order?.option1?.[0].bopo1_count + "개 = " + 
										(common.priceToString(parseInt(order?.option1?.[0].bopo1_amt) * parseInt(order?.option1?.[0].bopo1_count) || "")) + "원"
									: 
										""
						}
            </Grid>
            <Grid item xs={4} md={3}>
            옵션2 주문 <br></br> (기타 사용료)
            </Grid>
            <Grid item xs={6} md={8}>
            {
							order?.option2?.length !== 0 ? order?.option2 && order?.option2?.map((row, index) => {
								return (
									<div key={index}>
											{row.bopo2_name}({common.priceToString(row.bopo2_price) || ""}) * 
											{row.bopo2_count} = {common.priceToString((parseInt(row.bopo2_price) * parseInt(row.bopo2_count) || ""))}원
									</div>
								);
							})
							:
							<></>
						}
            </Grid>
            <Grid item xs={4} md={3}>
            총 주문금액
            </Grid>
            <Grid item xs={6} md={8}>
            {totalPriceString || "" }
            </Grid>

            {order.bpay_idx !== null ? <MyPayment payment={order} /> : ""}
            {/* {order.refund_stat_cd === "cancelled" ? <MyRefund refund={order} /> : ""} */}
        </Grid>
      </CardContent>
      <CardActions>
        <Link href={`/dashboard/GroupOrderList`} underline="none">목록</Link>
        {/* { order.bpay_idx !== null && order.bpay_state === "confirm"  ? <Button onClick={(e)=>handlePayment(e, ord_idx)} variant="outlined">결제 승인</Button> : "추가 정보 입력이 필요합니다." } */}
        {/* <Button onClick={(e)=>handleCancelOrder(e, ord_idx)} variant="outlined">주문 취소</Button> */}
      </CardActions>
    </Card>
  );
}