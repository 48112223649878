import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';

import Box from '@mui/material/Box';

import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";

import { rankingSelect, rankingDelete } from "../../../actions/ranking";

import { useNavigate, useParams } from 'react-router-dom';
import { Link } from '@mui/material';

import * as common from "../../../lib";

export default function NewsView() {

  const idx  = useParams().idx;
  console.log("idx : ", idx);
  let prevPage  = useParams().prevPage;
  console.log("prevPage : ", prevPage);
  
  if (prevPage === undefined)
    prevPage = 1;

  // 리듀서 값들 저장
  const userInfo = useSelector(state => state.auth);
  console.log("userInfo : ", userInfo);

  const ranking_select = useSelector(state => state.ranking?.select);
  console.log("ranking_select : ", ranking_select);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const onDelete = (event) => {

      event.preventDefault();
      
      if(userInfo.isLoggedIn)
      {
        const sendDelete = {
          idx: idx,
          delete: 1,
        }
    
        console.log("sendDelete : ", sendDelete);
    
        if (window.confirm("정말 삭제하시겠습니까??") == true){    //확인
    
          // 여기서 디스패치로~
          dispatch(rankingDelete(sendDelete))
          .then(data => 
          {
            console.log("rankingDelete data : ", data);
    
            if (!data.success)
            {
              alert(data.msg);
              return;
            }
            else
            {
              navigate(`/dashboard/RankingList/${prevPage}`);
            }
              
          })
          .catch(e => {
            console.log(e);
          });	
        }
        else
        {   //취소
        
          return;
        
        }
      }
      else
      {
        alert("로그인 후에 삭제할 수 있습니다.");
        return;
      }
    }

    useEffect(() => {
        
        // 여기서 디스패치로~
        dispatch(rankingSelect(idx))
        .then(data => 
        {
          console.log("data : ", data);
        })
        .catch(e => {
          console.log(e);
        });

        return () => {      // 언마운트
            console.log("언마운트!");
        };

    }, [dispatch])

  return (
    <Card sx={{ minWidth: 275 }}>
      <CardContent>
        <Box
            sx={{
                '& > :not(style)': { m: 1, width: '400px' },
            }}
            >
            야미 랭킹 설정 보기<br></br><br></br>
        </Box>
        <Box>
        회차 : {ranking_select?.order || ""}
        </Box>
        <Box
        sx={{height:'35px', wordBreak: 'break-all',
            '& > :not(style)': { m: 1, width: '100%' },
        }}
        >
        랭킹 도전 시작일 : {ranking_select?.ranking_start_date || ""}
        </Box>
        <Box
        sx={{height:'35px', wordBreak: 'break-all',
            '& > :not(style)': { m: 1, width: '100%' },
        }}
        >
        랭킹 도전 종료일 : {ranking_select?.ranking_end_date || ""}
        </Box>
        <Box
        sx={{height:'35px', wordBreak: 'break-all',
            '& > :not(style)': { m: 1, width: '100%' },
        }}
        >
        순위 노출 시작일 : {ranking_select?.open_start_date || ""}
        </Box>
        <Box
        sx={{height:'35px', wordBreak: 'break-all',
            '& > :not(style)': { m: 1, width: '100%' },
        }}
        >
        순위 노출 종료일 : {ranking_select?.open_end_date || ""}
        </Box>
        <Box
        sx={{height:'35px', wordBreak: 'break-all',
            '& > :not(style)': { m: 1, width: '100%' },
        }}
        >
        문제 출제번호 : {ranking_select?.question_number_start || ""} ~ {ranking_select?.question_number_end || ""}
        </Box>
        <Box
        sx={{height:'35px',
            '& > :not(style)': { m: 1, width: '100%' },
        }}
        >
        공개 여부 : {ranking_select?.open === 0 ? "close" : "open" || ""}
        </Box>
        <Box
        sx={{height:'35px',
            '& > :not(style)': { m: 1, width: '100%' },
        }}
        >
        작성날짜 : {ranking_select?.reg_date || ""}
        </Box>
        <Divider />
        <Box
        sx={{ marginTop: '20px',
              whiteSpace: 'pre-line',
              overflowX: 'auto',
              wordBreak: 'break-all',
            '& > :not(style)': { m: 1 },
        }}
        >
        </Box>
      </CardContent>
      <CardActions>
        <Link href={`/dashboard/RankingList/${prevPage}`} underline="none">목록</Link>
        <Link href={`/dashboard/RankingModify/${idx}/${prevPage}`} underline="none">수정</Link>
        <Link onClick={(e)=>onDelete(e)} value={idx} underline="none">삭제</Link>
      </CardActions>
    </Card>
  );
}