import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import { useTranslation, Trans } from 'react-i18next'; // 1. react-i18next import 

// import { useEffect, useRef, useState } from 'react';
// import { useDispatch, useSelector } from "react-redux";
// import { chartClassAvgStudyTime, chartClassLastLogin, chartClassStudentCount, chartClassAgeDiv, chartClassAgeRange } from "../../actions/chart";

// function createData(studentName, className, lastLogin) {
//   return { studentName, className, lastLogin };
// }

// const rows = [
// 	  createData('student1', '클래스A', '2023년 5월 30일 12시 33분'),
//     createData('student2', '클래스B', '2023년 5월 30일 12시 33분'),
//     createData('student3', '클래스C', '2023년 5월 30일 12시 33분'),
//     createData('student4', '클래스D', '2023년 5월 30일 12시 33분'),
//     createData('student5', '클래스E', '2023년 5월 30일 12시 33분'),
//     createData('student5', '클래스E', '2023년 5월 30일 12시 33분'),
// ];

export default function BasicTable(props) {

  const { t, i18n } = useTranslation(); // 3. useTranslation hook 선언

    // console.log("props.RowData : ", props.RowData);

  // const [rowData, setRowData] = useState([]);

  // const dispatch = useDispatch();	

  // const chartClassAvgStudyTimeData = useSelector(state => state.chart.chartClassAvgStudyTime);
  // console.log("chartClassAvgStudyTimeData : ", chartClassAvgStudyTimeData);

  // useEffect(() => {
    
  //   dispatch(chartClassAvgStudyTime())
  //   .then(data => 
  //   {
  //       console.log("chartClassAvgStudyTime data : ", data);
  //       setRowData(data);
  //   })
  //   .catch(e => {
  //       console.log(e);
  //   });

  // }, []);

  return (
    <TableContainer component={Paper} sx={{ marginTop: 2 }}>
        <Table sx={{ minWidth: 500 }} size="small" aria-label="a dense table">
            <TableHead>
            <TableRow>
                <TableCell align="center">{/* 학습자 아이디 */}{t('ClassCommonDlg.string10')}</TableCell>
                <TableCell align="center">{/* 클래스명 */}{t('ClassCommonDlg.string3')}</TableCell>
                <TableCell align="center">{/* 접속시간 */}{t('ClassCommonDlg.string11')}</TableCell>
            </TableRow>
            </TableHead>
            <TableBody>
              {
                props.RowData?.length !== 0 ? props.RowData && props.RowData?.map((row, index) => (
                  <TableRow
                  key={index}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                  <TableCell component="th" scope="row" align="center">
                      {row.mst_id}
                  </TableCell>
                  <TableCell align="center">{row.mc_name}</TableCell>
                  <TableCell align="center">{row.mst_lastlogin_date}</TableCell>
                  </TableRow>
              ))
              :
              <TableRow>
                <TableCell component="th" scope="row" align="center" colSpan="3">{/* 데이터 없음 */}{t('Common.No_data')}</TableCell>
              </TableRow>
            }
            </TableBody>
        </Table>
    </TableContainer>
  );
}