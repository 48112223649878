import {
  CREATE_GROUP_PAYMENT,
  UPDATE_GROUP_PAYMENT,
  STATUS_GROUP_PAYMENT,
} from "./types";



import GroupPaymentService from "../services/GroupPaymentService";

export const groupPaymentCreate = (data) => async (dispatch) => {
  
  try {
    
    const res = await GroupPaymentService.groupPaymentCreate(data);

    // console.log("res : ", res);

    dispatch({
      type: CREATE_GROUP_PAYMENT,
      payload: res.data,
    });

    return Promise.resolve(res.data);

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};

export const groupPaymentUpdate = (data) => async (dispatch) => {
  
  try {

    // console.log("paymentCancel! data : ", data);

    const res = await GroupPaymentService.groupPaymentUpdate(data);

    // console.log("res : ", res);

    if (res.data.success === false)
    {
      // alert(res.data.msg);
      return Promise.resolve(res.data);
    }
    else
    {
      dispatch({
        type: UPDATE_GROUP_PAYMENT,
        payload: "",
      });
  
      return Promise.resolve(res.data);
    }

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};

export const groupPaymentStatus = (data) => async (dispatch) => {
  
  try {

    // console.log("paymentVbankCancel! data : ", data);

    const res = await GroupPaymentService.groupPaymentStatus(data);

    // console.log("res : ", res);

    if (res.data.success === false)
    {
      // alert(res.data.msg);
      return Promise.resolve(res.data);
    }
    else
    {
      dispatch({
        type: STATUS_GROUP_PAYMENT,
        payload: "",
      });
  
      return Promise.resolve(res.data);
    }

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};
