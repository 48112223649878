import {
  CLASS_AVG_STUDYTIME,
  CLASS_LAST_LOGIN,
  CLASS_STUDENT_COUNT,
  CLASS_AGE_DIV,
  CLASS_AGE_RANGE,
  CLASS_LEARNING_TIME,
  CLASS_MISSIONSTATE_PROGRESS,
  CLASS_MISSIONSTATE_PROGRESS_TOTAL,
  CLASS_TOTAL_MISSION,
  CLASS_MISSION_PROGRESS,
  CLASS_HUMAN_ABILITY,
} from "./types";



import ChartService from "../services/ChartService";

export const chartClassAvgStudyTime  = (data) => async (dispatch) => {
  
  try {
    
    const res = await ChartService.chartClassAvgStudyTime(data);

    // console.log("res : ", res);

    dispatch({
      type: CLASS_AVG_STUDYTIME,
      payload: res.data.data,
    });

    return Promise.resolve(res.data.data);

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};

export const chartClassLastLogin  = (data) => async (dispatch) => {
  
  try {
    
    const res = await ChartService.chartClassLastLogin(data);

    // console.log("res : ", res);

    dispatch({
      type: CLASS_LAST_LOGIN,
      payload: res.data.data,
    });

    return Promise.resolve(res.data.data);

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};

export const chartClassStudentCount  = (data) => async (dispatch) => {
  
  try {
    
    const res = await ChartService.chartClassStudentCount(data);

    // console.log("res : ", res);

    dispatch({
      type: CLASS_STUDENT_COUNT,
      payload: res.data.data,
    });

    return Promise.resolve(res.data.data);

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};

export const chartClassAgeDiv  = (data) => async (dispatch) => {
  
  try {
    
    const res = await ChartService.chartClassAgeDiv(data);

    // console.log("res : ", res);

    dispatch({
      type: CLASS_AGE_DIV,
      payload: res.data.data,
    });

    return Promise.resolve(res.data.data);

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};

export const chartClassAgeRange  = (data) => async (dispatch) => {
  
  try {
    
    const res = await ChartService.chartClassAgeRange(data);

    // console.log("res : ", res);

    dispatch({
      type: CLASS_AGE_RANGE,
      payload: res.data.data,
    });

    return Promise.resolve(res.data.data);

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};

export const chartClassLearningTime  = (data, userIdx) => async (dispatch) => {
  
  try {
    
    const res = await ChartService.chartClassLearningTime(data, userIdx);

    // console.log("res : ", res);

    dispatch({
      type: CLASS_LEARNING_TIME,
      payload: res.data.data,
    });

    return Promise.resolve(res.data.data);

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};

export const chartClassMissionStateProgress  = (data, userIdx) => async (dispatch) => {
  
  try {
    
    const res = await ChartService.chartClassMissionStateProgress(data, userIdx);

    // console.log("res : ", res);

    dispatch({
      type: CLASS_MISSIONSTATE_PROGRESS,
      payload: res.data.data,
    });

    return Promise.resolve(res.data.data);

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};

export const chartClassMissionStateProgressTotal  = (data, userIdx) => async (dispatch) => {
  
  try {
    
    const res = await ChartService.chartClassMissionStateProgressTotal(data, userIdx);

    // console.log("res : ", res);

    dispatch({
      type: CLASS_MISSIONSTATE_PROGRESS_TOTAL,
      payload: res.data.data,
    });

    return Promise.resolve(res.data.data);

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};

export const chartClassMissionTotal  = (data) => async (dispatch) => {
  
  try {
    
    const res = await ChartService.chartClassMissionTotal(data);

    // console.log("res : ", res);

    dispatch({
      type: CLASS_TOTAL_MISSION,
      payload: res.data,
    });

    return Promise.resolve(res.data);

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};

export const chartClassMissionProgress  = (data) => async (dispatch) => {
  
  try {
    
    const res = await ChartService.chartClassMissionProgress(data);

    // console.log("res : ", res);

    dispatch({
      type: CLASS_MISSION_PROGRESS,
      payload: res.data,
    });

    return Promise.resolve(res.data);

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};

export const chartClassHumanAbility  = (data) => async (dispatch) => {
  
  try {
    
    const res = await ChartService.chartClassHumanAbility(data);

    // console.log("res : ", res);

    dispatch({
      type: CLASS_HUMAN_ABILITY,
      payload: res.data,
    });

    return Promise.resolve(res.data);

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};
