import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';

import { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { refundList } from "../../../actions/refund";

import * as common from "../../../lib";

export default function RefundList() {

    const refund = useSelector(state => state.refund);
    console.log("refund : ", refund);

    const dispatch = useDispatch();

    useEffect(() => {

        // 여기서 디스패치로~
        dispatch(refundList())
        .then(data => 
        {
            console.log("data : ", data);
        })
        .catch(e => {
            console.log(e);
        });

    }, [dispatch])

  return (
    <Card sx={{ minWidth: 275 }}>
      <CardContent>
      환불 히스토리
      <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="center">번호</TableCell>
            <TableCell align="center">주문번호</TableCell>
            <TableCell align="center">결제번호</TableCell>
            <TableCell align="center">환불금액</TableCell>
            <TableCell align="center">날짜</TableCell>
            <TableCell align="center">환불이유</TableCell>
            <TableCell align="center">결과</TableCell>
            <TableCell align="center">메세지</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {refund && refund.map((row, index) => (
            <TableRow
              key={index}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } ,
              "& th, & td": {
                color: row.ref_stat_cd === "failed" ? 'white' : 'black',
                backgroundColor: row.ref_stat_cd === "failed" ? 'red' : 'white'
              }}}
            >
              <TableCell component="th" scope="row" align="center">
                {row.ref_idx || ""}
              </TableCell>
              <TableCell align="center">{row.ord_no || ""}</TableCell>
              <TableCell align="center">{row.pay_no || ""}</TableCell>
              <TableCell align="center">&#8361; {common.priceToString(row.ref_amt) || ""}</TableCell>
              <TableCell align="center">{row.ref_date || ""}</TableCell>
              <TableCell align="center">{row.ref_desc || "-"}</TableCell>
              <TableCell align="center">{common.returnRefundState(row.ref_stat_cd) || ""}</TableCell>
              {/* {
                row.ref_stat_cd === "failed" ? 
                <TableCell align="center" style={{backgroundColor:'red', color: 'white',}}> {common.returnRefundState(row.ref_stat_cd) || ""} </TableCell>
                :
                <TableCell align="center">{common.returnRefundState(row.ref_stat_cd) || ""}</TableCell>
              } */}
              <TableCell align="center">{row.ref_err_msg || "-"}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      </TableContainer>

      </CardContent>
      <CardActions>
        
      </CardActions>
    </Card>
  );
}