import http from "../http-common";

const inappProductMissionCreate = data => {
  return http.post("mission/inappProduct", data);
};

const inappProductMissionUseGet = data => {
  return http.get(`mission/inappProduct/use/${data}`);
};

const inappProductMissionUseNotGet = data => {
  return http.get(`mission/inappProduct/useNot/${data}`);
};

const inappProductMissionList = (data) => {
  if (data === undefined)
    return http.get(`missionInappMapList`);
  else
    return http.get(`missionInappMapList/${data}`);
};

const InappProductMissionService = {
  inappProductMissionCreate,
  inappProductMissionUseGet,
  inappProductMissionUseNotGet,
  inappProductMissionList,
};

export default InappProductMissionService;
