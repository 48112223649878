import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import { FormControl, TextField, Select, MenuItem, InputLabel } from '@mui/material';

import { useState, useEffect } from 'react';

import { useDispatch, useSelector } from "react-redux";
import { knowledgeModify, knowledgeSelect } from "../../../actions/knowledge";
import { knowledgeCategoryList } from "../../../actions/knowledge_category";
import { useNavigate, useParams } from 'react-router-dom';

export default function ProductWrite() {

    const initialKnowledgeState = {
        ki_idx: null,  
        ki_title: null,
        kc_category_idx: null,
        ki_question: null, 
        ki_answer: null,
        ki_discription: null,
        items: [],
    };

    const [knowledge, setKnowledge] = useState(initialKnowledgeState);

    const category = useSelector(state => state.knowledge_category);
    console.log("category : ", category);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const idx  = useParams().idx;
    console.log("idx : ", idx);

    const handleInputChange = event => {

        const { name, value } = event.target;
        if (name === "item1")
        {
            let items = [...knowledge.items];
            items[0] = {"ki_orderby": 1, "ki_question_item": value};
            setKnowledge({ ...knowledge, ["items"]: items });
        }
        else if (name === "item2")
        {
            let items = [...knowledge.items];
            items[1] = {"ki_orderby": 2, "ki_question_item": value};
            setKnowledge({ ...knowledge, ["items"]: items });
        }
        else if (name === "item3")
        {
            let items = [...knowledge.items];
            items[2] = {"ki_orderby": 3, "ki_question_item": value};
            setKnowledge({ ...knowledge, ["items"]: items });
        }
        else if (name === "item4")
        {
            let items = [...knowledge.items];
            items[3] = {"ki_orderby": 4, "ki_question_item": value};
            setKnowledge({ ...knowledge, ["items"]: items });
        }
        else if (name === "item5")
        {
            let items = [...knowledge.items];
            items[4] = {"ki_orderby": 5, "ki_question_item": value};
            setKnowledge({ ...knowledge, ["items"]: items });
        }
        else
        {
            setKnowledge({ ...knowledge, [name]: value });
        }
    };

    const handleSubmit = (event) => {

        event.preventDefault();

        console.log("knowledge : ", knowledge);

        const sendData = {
            ki_idx: knowledge.ki_idx,
            category_idx: knowledge.kc_category_idx,
            title: knowledge.ki_title,
            question: knowledge.ki_question,
            answer: knowledge.ki_answer,
            discription: knowledge.ki_discription,
            items: knowledge.items,
        }
        console.log("sendData : ", sendData);

        // 여기서 디스패치로~
        dispatch(knowledgeModify(sendData))
        .then(data => 
        {
            console.log("data : ", data);
            if (!data.success)
                alert(data.msg);
            
            navigate("/dashboard/KnowledgeList");
        })
        .catch(e => {
            console.log(e);
        });
    };

    useEffect(() => {

        console.log("dispatch knowledgeCategoryList");
        // 여기서 디스패치로~
        dispatch(knowledgeSelect(idx))
        .then(data => 
        {
            console.log("data : ", data[0]);

            setKnowledge({
              ki_idx: data[0].ki_idx,  
              ki_title: data[0].ki_title,
              kc_category_idx: data[0].kc_category_idx,
              ki_question: data[0].ki_question, 
              ki_answer: data[0].ki_answer,
              ki_discription: data[0].ki_discription,
              items: data[0].items,
            });
        })
        .catch(e => {
            console.log(e);
        });

        dispatch(knowledgeCategoryList())
        .then(data => 
        {
            console.log("data : ", data);
        })
        .catch(e => {
            console.log(e);
        });

        return () => {      // 언마운트
            console.log("언마운트!");
        };

    }, [dispatch])

  return (
    <Card sx={{ minWidth: 360, width:'100%' }}>
      <CardContent>
      <FormControl sx={{minWidth:360}}>
            <TextField
            id="ki_title"
            label="제목"
            helperText="제목을 입력해주세요."
            variant="standard"
            name="ki_title"
            onChange={handleInputChange}
            value={knowledge.ki_title || ""}
            />
            
            <TextField
            id="ki_question"
            label="질문"
            helperText="질문을 입력해주세요."
            variant="standard"
            name="ki_question"
            onChange={handleInputChange}
            value={knowledge.ki_question || ""}
            />
            
            <br></br>

            <FormControl sx={{width:250}}>
            <InputLabel id="kc_category_idx">카테고리</InputLabel>
            <Select
            labelId="kc_category_idx"
            id="kc_category_idx"
            value={knowledge.kc_category_idx || ""}
            label="카테고리"
            onChange={handleInputChange}
            name="kc_category_idx"
            >
            {category && category.map((item, index) => (
              <MenuItem value={item.kc_category_idx} key={index}>
                {item.kc_category_name}
              </MenuItem>
            ))}
            </Select>
            </FormControl>
            
            <br></br>

            <TextField
            id="ki_answer"
            label="정답"
            type="number"
            InputLabelProps={{
                shrink: true,
            }}
            variant="standard"
            name="ki_answer"
            onChange={handleInputChange}
            sx={{width:50}}
            inputProps={{
                inputMode: 'numeric', 
                pattern: '[0-9]*',
                step: 1,
                min: 1,
                max: 5,
                type: 'number',
            }}
            value={knowledge.ki_answer || ""}
            />
            
            <TextField
            id="ki_discription"
            label="정답 설명"
            helperText="정답 설명을 입력해주세요."
            variant="standard"
            name="ki_discription"
            onChange={handleInputChange}
            value={knowledge.ki_discription || ""}
            />

            <TextField
            id="item1"
            label="객관식 항목 1"
            helperText="객관식 항목1을 입력해주세요."
            variant="standard"
            name="item1"
            onChange={handleInputChange}
            value={knowledge.items[0]?.ki_question_item || ""}
            />
            <TextField
            id="item2"
            label="객관식 항목 2"
            helperText="객관식 항목2을 입력해주세요."
            variant="standard"
            name="item2"
            onChange={handleInputChange}
            value={knowledge.items[1]?.ki_question_item || ""}
            />
            <TextField
            id="item3"
            label="객관식 항목 3"
            helperText="객관식 항목3을 입력해주세요."
            variant="standard"
            name="item3"
            onChange={handleInputChange}
            value={knowledge.items[2]?.ki_question_item || ""}
            />
            <TextField
            id="item4"
            label="객관식 항목 4"
            helperText="객관식 항목4을 입력해주세요."
            variant="standard"
            name="item4"
            onChange={handleInputChange}
            value={knowledge.items[3]?.ki_question_item || ""}
            />
            <TextField
            id="item5"
            label="객관식 항목 5"
            helperText="객관식 항목5을 입력해주세요."
            variant="standard"
            name="item5"
            onChange={handleInputChange}
            value={knowledge.items[4]?.ki_question_item || ""}
            />
        </FormControl>
      </CardContent>
      <CardActions>
        <Button onClick={handleSubmit} size="small">지식 수정</Button>
      </CardActions>
    </Card>
  );
}