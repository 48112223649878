import {
  CREATE_CLASS,
  UPDATE_CLASS,
  DELETE_CLASS,
  LIST_CLASS,
  SELECT_CLASS,
  NAME_CHECK_CLASS,
  LIST_CLASS_ADMIN,
  MANAGEMENT_LIST_CLASS,
  MANAGEMENT_SET_CLASS,
  MANAGEMENT_STATUS_CLASS,
  MANAGEMENT_STATUS_SET_CLASS,
  MANAGEMENT_SAVED_LIST_CLASS,
  HOMEWORK_LIST_CLASS,
  HOMEWORK_SELECT_CLASS,
  HOMEWORK_INIT_CLASS,
  HOMEWORK_COMMENT_SELECT,
  HOMEWORK_COMMENT_INSERT,
  HOMEWORK_COMMENT_DELETE,
} from "../actions/types";

const initialState = [];

const classReducer = (classReducer = initialState, action) => {
  const { type, payload } = action;

  // console.log("classReducer Call!");
  // console.log("type : ", type);
  // console.log("payload : ", payload);

  switch (type) {

    case CREATE_CLASS:
      return classReducer;

    case UPDATE_CLASS:
      return classReducer;

    case DELETE_CLASS:
      return classReducer;

    case LIST_CLASS:
      return {
        ...classReducer,
        list : payload.list,
        paging : payload.paging,
      };

    case SELECT_CLASS:
      return{
        ...classReducer,
        classReducer: payload,
      };

    case NAME_CHECK_CLASS:
      return{
        ...classReducer,
        checkResult: payload,
      };

    case LIST_CLASS_ADMIN:
      return {
        ...classReducer,
        adminlist : payload,
      };

    case MANAGEMENT_LIST_CLASS:
      return {
        ...classReducer,
        managementList : payload.list,
        managementPaging : payload.paging,
      };

    case MANAGEMENT_SET_CLASS:
      return classReducer;

    case MANAGEMENT_STATUS_CLASS:
      return {
        ...classReducer,
        managementStatus : payload,
      };

    case MANAGEMENT_STATUS_SET_CLASS:
      return classReducer;

    case MANAGEMENT_SAVED_LIST_CLASS:
      return {
        ...classReducer,
        managementSavedList : payload,
      };

    case HOMEWORK_LIST_CLASS:
      return {
        ...classReducer,
        homeworkList : payload.list,
        homeworkPaging : payload.paging,
      };

    case HOMEWORK_SELECT_CLASS:
      return {
        ...classReducer,
        studentScore : payload.studentScore,
        studentMissionScore : payload.studentMissionScore,
        homeworkMission : payload.homeworkMission,
      };

    case HOMEWORK_INIT_CLASS:
      return {
        ...classReducer,
        homeworkList : null,
        homeworkPaging : null,
        studentScore : null,
        studentMissionScore : null,
        homeworkMission : null,
      };

    case HOMEWORK_COMMENT_INSERT:
      return classReducer;

    case HOMEWORK_COMMENT_SELECT:
      return {
        ...classReducer,
        homeworkComment : payload,
      };

    case HOMEWORK_COMMENT_DELETE:
      return classReducer;

    default:
      return classReducer;
  }
};

export default classReducer;