import React from 'react';
import styles from "../../css/common.module.css";

const Footer = () => {

    return (
        <>
            <section className={styles.footer_section}>
			<div className={styles.footer}>
				<div className={styles.inner90}>
					<div className={styles.f_left}>
						<ul>
							<li><img src="/assets/f_logo@2x.png" alt="f_logo"/></li>
							<li>
								<a target="_blank" rel="noopener noreferrer" href="http://www.weclover.co.kr/">Company introduction</a>
								{/* <a href="/Use">이용약관</a>
								<a href="/Privacy">개인정보처리방침</a> */}
							</li>
							<li>
							Weclover Co., Ltd (CEO:Lee Sungjin)&nbsp;&nbsp;|&nbsp;&nbsp;Weclover on the 4th floor of Gwanak S Valley Sillim Venture Startup Center, 26, Hoam-ro 22-gil, Gwanak-gu, Seoul <br/>
							{/* 통신판매업신고 : 제2018-서울관악-0254호&nbsp;&nbsp;|&nbsp;&nbsp;사업자등록번호 : 179-87-00821 */}
							 | Phone number : (+82)02-875-3330
							</li>
							<li>© 2022.weclover Inc. all rights reserved.</li>
						</ul>
					</div>
					<div className={styles.f_right}>
						<ul>
							{/* <li className={styles.faq}><a href="/Faq">자주묻는질문</a></li> */}
							{/* <li class="faq">채팅상담</li> */}
							<li className={styles.inquiry}>Partnership</li>
							<li className={styles.inquiry}>admin@weclover.co.kr</li>
						</ul>
					</div>
				</div>
			</div>
		    </section>
        </>
    );
    
};

export default Footer;