import {
  CREATE_BBS,
  CREATE_REPLY_BBS,
  LIST_BBS,
  DELETE_BBS,
  MODIFY_BBS,
  SELECT_BBS,
} from "../actions/types";

const initialState = [];

const bbsReducer = (bbs = initialState, action) => {
  const { type, payload } = action;

  // console.log("bbsReducer Call!");
  // console.log("type : ", type);
  // console.log("payload : ", payload);

  switch (type) {
    
    case CREATE_BBS:
      return [];

    case CREATE_REPLY_BBS:
      return [];

    case LIST_BBS:
      return payload;

    case SELECT_BBS:
      return payload;

    case MODIFY_BBS:
      return [];

    case DELETE_BBS:
      return [];

    default:
      return bbs;
  }
};

export default bbsReducer;