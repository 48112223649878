import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';

import { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { solutionList, solutionDelete } from "../../../actions/solution";

import { useNavigate, useParams } from 'react-router-dom';

export default function SolutionList() {

    const solution_list = useSelector(state => state.solution.list);
    // console.log("solution_list : ", solution_list);
    const solution_paging = useSelector(state => state.solution.paging);
    // console.log("solution_paging : ", solution_paging);
    let board_page  = useParams().prevPage;
    // console.log("board_page : ", board_page);

    if (board_page === undefined)
          board_page = 1;

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleWriteNotice = (event) => {
      navigate(`/dashboard/SolutionWrite`);
    };

    const handleDelete = (event, idx) => {

      event.preventDefault();

      // console.log(idx);

      const sendDelete = {
        idx: idx,
      }
  
      // console.log("sendDelete : ", sendDelete);

      if (window.confirm("정말 삭제하시겠습니까??") === true)   //확인
      {
        // 여기서 디스패치로~
        dispatch(solutionDelete(sendDelete))
        .then(data => 
        {
            // console.log("data : ", data);
            
            const boardListSend = {
              board_page : board_page,
            }
      
            // 여기서 디스패치로~
            dispatch(solutionList(boardListSend))
            .then(data => 
            {
                // console.log("data : ", data);
                navigate(`/dashboard/SolutionList/${board_page}`);
            })
            .catch(e => {
                console.log(e);
            });
        })
        .catch(e => {
            console.log(e);
        });

      }
      else  //취소
      {
        return false;
      }

    };

    // const handleOpenNotice = (event, idx, open) => {

    //   event.preventDefault();

    //   console.log(idx);

    //   const sendOpen = {
    //     table_name: board_name,
    //     idx: idx,
    //     open: !open,
    //   }
  
    //   console.log("sendOpen : ", sendOpen);

    //   if (window.confirm("오픈 상태를 변경하시겠습니까?") === true)   //확인
    //   {
    //     // 여기서 디스패치로~
    //     dispatch(bbsNoticeOpen(sendOpen))
    //     .then(data => 
    //     {
    //         console.log("data : ", data);
            
    //         const boardListSend = {
    //           board_name : board_name,
    //           board_page : board_page,
    //         }
      
    //         // 여기서 디스패치로~
    //         dispatch(bbsNoticeList(boardListSend))
    //         .then(data => 
    //         {
    //             console.log("data : ", data);
    //             navigate(`/dashboard/YameNoticeList/${board_name}/${board_page}`);
    //         })
    //         .catch(e => {
    //             console.log(e);
    //         });
    //     })
    //     .catch(e => {
    //         console.log(e);
    //     });

    //     // navigate(`/dashboard/NoticeList/${board_name}/${board_page}`);
    //   }
    //   else  //취소
    //   {
    //     return false;
    //   }

    // };

    // 게시물의 번호를 계산하는 함수
    const listNumberReturn = (paging, index) => {
      let listNumber;
      return listNumber = ((paging.totalPageCount - index) - ((paging.curPage -1) * paging.page_size));
    }

    const onPaging = (e, page) => {
      if (!page) return;
      console.log("page : ", page);
      
      const boardListSend = {
        board_page : page,
      }

      // 여기서 디스패치로~
      dispatch(solutionList(boardListSend))
      .then(data => 
      {
          // console.log("data : ", data);
          navigate(`/dashboard/SolutionList/${page}`);
      })
      .catch(e => {
          console.log(e);
      });
    }

    useEffect(() => {

        const boardListSend = {
          board_page : board_page,
        }

        // 여기서 디스패치로~
        dispatch(solutionList(boardListSend))
        .then(data => 
        {
            // console.log("data : ", data);
        })
        .catch(e => {
            console.log(e);
        });
        
        return () => {      // 언마운트
            console.log("언마운트!");
        };

    }, [dispatch])

  return (
    <Card sx={{ minWidth: 275 }}>
      <CardContent>
      문의내역 관리
      <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="center">번호</TableCell>
            <TableCell align="center">제목</TableCell>
            <TableCell align="center">등록일</TableCell>
            <TableCell align="center">답변일</TableCell>
            <TableCell align="center">수정</TableCell>
            <TableCell align="center">답변</TableCell>
            <TableCell align="center">삭제</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {solution_list?.length !== 0 ? solution_list && solution_list.map((row, index) => (
            <TableRow
              key={index}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row" align="center">
                {listNumberReturn(solution_paging, index)}
              </TableCell>
              <TableCell align="center"><Link href={`/dashboard/SolutionView/${row.idx}/${board_page}`} underline="none">{row.title}</Link></TableCell>
              <TableCell align="center">{row.reg_date}</TableCell>
              <TableCell align="center">{row.answer_reg_date === null || row.answer_reg_date === "" ? "-" : row.answer_reg_date  }</TableCell>
              <TableCell align="center"><Link href={`/dashboard/SolutionModify/${row.idx}/${board_page}`} underline="none">수정</Link></TableCell>
              <TableCell align="center"><Link href={`/dashboard/SolutionReply/${row.idx}/${board_page}`} underline="none">답변</Link></TableCell>
              <TableCell align="center"><Link onClick={(e)=>handleDelete(e, row.idx)} value={row.idx} underline="none">삭제</Link></TableCell>
            </TableRow>
          )) :  
            <TableRow
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row" align="center" colSpan="7">데이터 없음</TableCell>
            </TableRow>
        }
        </TableBody>
      </Table>
      </TableContainer>
      {
        solution_list?.length !== 0 ? 
          <Stack spacing={2} sx={{ height: 60, display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
            <Pagination count={solution_paging?.totalPage || 0} page={solution_paging?.curPage || 0} onChange={onPaging} color="primary" />
          </Stack>
        :
        <></>
      }
      </CardContent>
      {/* <CardActions sx={{ height: 30}}>
        <Button onClick={handleWriteNotice} size="small">공지사항 추가</Button>
      </CardActions> */}
    </Card>
  );
}