import * as React from 'react';
import Chart from "react-apexcharts";

import { useTranslation, Trans } from 'react-i18next'; // 1. react-i18next import 

export default function BasicTable(props) {

      const { t, i18n } = useTranslation(); // 3. useTranslation hook 선언

      let labelsDataArray = [];
      let ageDataArray = [];

      if (props.RowData?.length !== 0)
      {
        // 데이터 생성
        for (let i=0; i<props.RowData?.length; i++)
        {
          labelsDataArray.push(props.RowData[i].birthday);
          ageDataArray.push(props.RowData[i].cnt);
        }
      }

      let state = {
          
        series: [
          {
            name: "",
            data: ageDataArray,
          },
        ],
        options: {
          chart: {
            type: 'bar',
            height: 350,
          },
          plotOptions: {
            bar: {
              borderRadius: 0,
              horizontal: true,
              distributed: true,
              barHeight: '80%',
              isFunnel: true,
            },
          },
          colors: [
            '#F44F5E',
            '#E55A89',
            '#D863B1',
            '#CA6CD8',
            '#B57BED',
            '#8D95EB',
            '#62ACEA',
            '#4BC3E6',
          ],
          dataLabels: {
            enabled: true,
            formatter: function (val, opt) {
              // return opt.w.globals.labels[opt.dataPointIndex] + "세 (" + ageDataArray[opt.dataPointIndex] + "명)"
              return `${t("ClassChart.string5", {count1 : opt.w.globals.labels[opt.dataPointIndex], count2 : ageDataArray[opt.dataPointIndex]})}`
            },
            dropShadow: {
              enabled: true,
            },
          },
          // title: {
          //   text: 'Pyramid Chart',
          //   align: 'middle',
          // },
          xaxis: {
            categories: labelsDataArray,
          },
          legend: {
            show: false,
          },
        },
      
      
      };

  return (
    <>
        <Chart options={state.options} series={state.series} type="bar" height={280} width={480} />
    </>
  );
}

// import React, { Component } from "react";
// import Chart from "react-apexcharts";

// class App extends Component {
//   constructor(props) {
//     super(props);

//     this.state = {
          
//       series: [44, 55, 41, 17, 15],
//       options: {
//         chart: {
//           type: 'donut',
//         },
//         plotOptions: {
//           pie: {
//             startAngle: -90,
//             endAngle: 90,
//             offsetY: 10
//           }
//         },
//         grid: {
//           padding: {
//             bottom: -80
//           }
//         },
//         responsive: [{
//           breakpoint: 480,
//           options: {
//             chart: {
//               width: 200
//             },
//             legend: {
//               position: 'bottom'
//             }
//           }
//         }]
//       },
    
    
//     };
//   }

//   render() {
//     return (
      
//         <Chart
//           options={this.state.options} series={this.state.series} type="donut"
//           height={300}
//         />
//     );
//   }
// }

// export default App;