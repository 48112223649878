import React from 'react';
import styles from "../css/common.module.css";

import { useTranslation } from 'react-i18next'; // 1. react-i18next import 

const NotFound = () => {

    const { t, i18n } = useTranslation(); // 3. useTranslation hook 선언

    return (
        <>
            <div className={styles.not_found_area}>
                <img src="/assets/404-3@2x.png" alt="404 img" />
                <p>{/* 페이지를 찾을 수 없어요 */}{t('Common.Page_error1')}</p>
                <a href="/">{/* 홈페이지로 돌아가기 */}{t('Common.Page_error2')}</a>
            </div>
        </>
    );
    
};

export default NotFound;