import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Button } from '@mui/material';

import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';

import ClassEditDialog from './ClassEditDialog';
import ClassManagement from './ClassManagement';
import ClassHomework from './ClassHomework';

import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";

import { classListAll } from "../../actions/class";

import { useTranslation, Trans } from 'react-i18next'; // 1. react-i18next import 

export default function ClassDataTable(props) 
{

  const dispatch = useDispatch();	

  const { t, i18n } = useTranslation(); // 3. useTranslation hook 선언

  const classReducer_list = useSelector(state => state.classReducer?.list);
  // console.log("classReducer_list : ", classReducer_list);
  const classReducer_paging = useSelector(state => state.classReducer?.paging);
  // console.log("classReducer_paging : ", classReducer_paging);

  const [boardPage, setBoardPage] = useState(props.classDataTablePageNumber || 1);

  // 게시물의 번호를 계산하는 함수
  const listNumberReturn = (paging, index) => {
    let listNumber;
    return listNumber = ((paging.totalPageCount - index) - ((paging.curPage -1) * paging.page_size));
  }

  const onPaging = (e, page) => {
    
    if (!page) return;
    // console.log("page : ", page);

    setBoardPage(page);
    
    const boardListSend = {
      board_page : page,
    }

    // 여기서 디스패치로~
    dispatch(classListAll(boardListSend))
    .then(data => 
    {
        // console.log("classListAll : ", data);
    })
    .catch(e => {
        console.log(e);
    });
  }

  useEffect(() => {

    const boardListSend = {
      board_page : boardPage,
    }

		// 클래스의 리스트 불러오기
    dispatch(classListAll(boardListSend))
    .then(data => 
    {
      // console.log("classListAll data : ", data);
    })
    .catch(e => {
        console.log(e);
    });

	}, []);

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table" size="small">
          <TableHead>
            <TableRow>
              <TableCell align="center">{/* 번호 */}{t('ClassCommonDlg.string9')}</TableCell>
              <TableCell align="center">{/* 클래스명 */}{t('ClassCommonDlg.string3')}</TableCell>
              <TableCell align="center">{/* 학교 */}{t('ClassCommonDlg.string4')}</TableCell>
              <TableCell align="center">{/* 학년 */}{t('ClassCommonDlg.string5')}</TableCell>
              <TableCell align="center">{/* 반 */}{t('ClassCommonDlg.string6')}</TableCell>
              <TableCell align="center">{/* 수정 */}{t('ClassCommonDlg.string8')}</TableCell>
              <TableCell align="center">{/* 수업관리 */}{t('ClassCommonDlg.string12')}</TableCell>
              <TableCell align="center">{/* 숙제관리 */}{t('ClassCommonDlg.string13')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              classReducer_list?.length !== 0 ? classReducer_list && classReducer_list
              .map((row, index) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                    <TableCell component="th" scope="row" align="center">
                      {listNumberReturn(classReducer_paging, index)}
                    </TableCell>
                    <TableCell align="center"><Button size="small" href={`/GroupUserMypage/GroupMypageClassReport/${row.mc_idx}/${row.mc_name}/${boardPage}`}>{row.mc_name}</Button></TableCell>
                    <TableCell align="center">{row.mc_school}</TableCell>
                    <TableCell align="center">{row.mc_grade}</TableCell>
                    <TableCell align="center">{row.mc_class}</TableCell>
                    <TableCell align="center"><ClassEditDialog RowData={row} currentPage={boardPage} /></TableCell>
                    <TableCell align="center"><ClassManagement RowData={row} currentPage={boardPage} /></TableCell>
                    <TableCell align="center"><ClassHomework RowData={row} currentPage={boardPage} /></TableCell>
                  </TableRow>
                );
              })
              :
              <TableRow>
                <TableCell component="th" scope="row" align="center" colSpan="6">{/* 데이터 없음 */}{t('Common.No_data')}</TableCell>
              </TableRow>
            }
          </TableBody>
        </Table>
      </TableContainer>
      {
        classReducer_list?.length !== 0 ? 
          <Stack spacing={2} sx={{ height: 60, display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
            <Pagination count={classReducer_paging?.totalPage || 0} page={classReducer_paging?.curPage || 0} onChange={onPaging} color="primary" />
          </Stack>
        :
        <></>
      }
    </Paper>
  );
}