import {
  REGISTER_INAPP_PRODUCT_MISSION,
  USE_INAPP_PRODUCT_MISSION,
  USENOT_INAPP_PRODUCT_MISSION,
  INAPP_PRODUCT_LIST_MISSION,
} from "./types";

import InappProductMissionService from "../services/InappProductMissionService";

export const inappProductMissionCreate = (data) => async (dispatch) => {
  
  try {
    
    const res = await InappProductMissionService.inappProductMissionCreate(data);

    // console.log("res : ", res);

    dispatch({
      type: REGISTER_INAPP_PRODUCT_MISSION,
      payload: res.data,
    });

    return Promise.resolve(res.data);

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};

export const inappProductMissionUse = (data) => async (dispatch) => {
  
  try {
    
    const res = await InappProductMissionService.inappProductMissionUseGet(data);

    // console.log("res : ", res);

    dispatch({
      type: USE_INAPP_PRODUCT_MISSION,
      payload: res.data.data,
    });

    return Promise.resolve(res.data.data);

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};

export const inappProductMissionUseNot = (data) => async (dispatch) => {
  
  try {
    
    const res = await InappProductMissionService.inappProductMissionUseNotGet(data);

    // console.log("res : ", res);

    dispatch({
      type: USENOT_INAPP_PRODUCT_MISSION,
      payload: res.data.data,
    });

    return Promise.resolve(res.data.data);

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};

export const inappProductMissionList = (data) => async (dispatch) => {
  
  try {
    
    const res = await InappProductMissionService.inappProductMissionList(data);

    // console.log("res : ", res);

    dispatch({
      type: INAPP_PRODUCT_LIST_MISSION,
      payload: res.data.data,
    });

    return Promise.resolve(res.data.data);

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};