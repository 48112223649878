import {
  LIST_NEWS,
  CREATE_NEWS,
  MODIFY_NEWS,
  DELETE_NEWS,
  SELECT_NEWS,
  LIST_NEWS_OUTSIDE,
  SELECT_NEWS_OUTSIDE,
  OPEN_NEWS,
} from "../actions/types";

const initialState = [];

const newsReducer = (news = initialState, action) => {
  const { type, payload } = action;

  // console.log("newsReducer Call!");
  // console.log("type : ", type);
  // console.log("payload : ", payload);

  switch (type) {

    case LIST_NEWS:
      return {
        ...news,
        list : payload.list,
        paging : payload.paging,
      };

    case CREATE_NEWS:
      return [];

    case MODIFY_NEWS:
      return [];

    case DELETE_NEWS:
      return [];

    case SELECT_NEWS:
      return{
        ...news,
        select: payload,
      };

    case LIST_NEWS_OUTSIDE:
      return {
        ...news,
        list : payload.list,
        paging : payload.paging,
      };

    case SELECT_NEWS_OUTSIDE:
      return{
        ...news,
        select: payload,
      };

    case OPEN_NEWS:
      return [];

    default:
      return news;
  }
};

export default newsReducer;