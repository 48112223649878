import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Button from '@mui/material/Button';
import { FormControl, TextField, Select, MenuItem, InputLabel } from '@mui/material';

import Stack from '@mui/material/Stack';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import MobileDatePicker from '@mui/lab/MobileDatePicker';

import { useEffect, useState } from 'react';

import { useDispatch, useSelector } from "react-redux";
import { orderCreate } from "../../../actions/order";
import { productList, productDelete } from "../../../actions/product";
import { useNavigate } from 'react-router-dom';
import ProductItem from "../../../common/ProductItem";

import * as common from "../../../lib";

export default function PaymentWrite() {

  const product = useSelector(state => state.product);
  console.log("product : ", product);

  const initialOrderState = {
      product_idx: null, 
      order_count: null,
      order_amount: null,
  };

  const [order, setOrder] = useState(initialOrderState);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {

    // 여기서 디스패치로~
    dispatch(productList())
    .then(data => 
    {
        console.log("data : ", data);
    })
    .catch(e => {
        console.log(e);
    });
    
    return () => {      // 언마운트
        console.log("언마운트!");
    };

}, [dispatch])

  return (
    <Card sx={{ minWidth: 275 }}>
      <CardHeader
        title="패키지 구매 리스트"
        subheader="패키지 구매 후에 학습자 생성이 가능합니다."
      />
      <CardContent>
        {product && product.map((row, index) => (
          <ProductItem key={index} product={row} />
        ))}
      </CardContent>
    </Card>
  );
}