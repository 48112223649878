import {
  CREATE_MISSION_CATEGORY,
  LIST_MISSION_CATEGORY,
  DELETE_MISSION_CATEGORY,
  MODIFY_MISSION_CATEGORY,
  SELECT_MISSION_CATEGORY,
} from "../actions/types";

const initialState = [];

const missionCategoryReducer = (mission_category = initialState, action) => {
  const { type, payload } = action;

  // console.log("missionCategoryReducer Call!");
  // console.log("type : ", type);
  // console.log("payload : ", payload);

  switch (type) {
    
    case CREATE_MISSION_CATEGORY:
      return [];

    case LIST_MISSION_CATEGORY:
      return payload;

    case SELECT_MISSION_CATEGORY:
      return payload;

    case MODIFY_MISSION_CATEGORY:
      return mission_category.map((mission_category) => {
        if (mission_category.category_idx === payload.category_idx) {
          return {
            ...mission_category,
            ...payload,
          };
        } else {
          return mission_category;
        }
      });

    case DELETE_MISSION_CATEGORY:
      return mission_category.filter(({ category_idx }) => category_idx !== payload.category_idx);

    default:
      return mission_category;
  }
};

export default missionCategoryReducer;