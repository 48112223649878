import axios from "axios";
import * as jwt from 'jsonwebtoken';
import { logout } from "./actions/auth";
import reduxStore from './store';

const axiosApiInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Content-type": "application/json",
  }
});

// For GET requests
axiosApiInstance.interceptors.request.use(
  async (config) => {

    const {dispatch} = reduxStore; // direct access to redux store.
    const user = JSON.parse(localStorage.getItem("user"));
    // console.log("axios.interceptors.request.use user : ", user);
    let language = localStorage.getItem("language");
    // console.log("axios.interceptors.request.language : ", language);

    if (language === null || language === undefined)
      language = "ko";

    if (user && user.accessToken) 
    {
      const decode = jwt.decode(user.accessToken);
      const nowDate = new Date().getTime() / 1000;

      // console.log("decode : ", decode);
      // console.log("decode.exp : ", decode.exp);
      // console.log("nowDate : ", nowDate);
      
      // 토큰 만료시간이 지났다면
      if (decode.exp < nowDate) {

        // console.log("만료시간 지남");
        const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/users/refreshToken`, { id: user.email }, {
            headers: {
              refresh: `Bearer ${user.refreshToken}`,
              Accept: 'application/json',
            },
          });
        // 리프레쉬 토큰 발급 서버 요청
        // console.log("리프레쉬 토큰으로 엑새스 발급 서버 요청 data : ", data);

        if(data.success)
        {
          const { accessToken } = data;
        
          // 다시 받아온 엑세스 토큰으로 헤더값을 변경합니다.
          config.headers = {
            Authorization: `Bearer ${accessToken}`,
            Accept: 'application/json',
          };

          // 다시 받아온 엑세스 토큰으로 로컬스토리지 엑세스 토큰값을 변경합니다.
          user.accessToken = accessToken;
          localStorage.setItem("user", JSON.stringify(user));
        }
        else
        {
          // console.log("모든 토큰 만료 다시 로그인!");
          dispatch(logout());   // 디스패치를 직접 호출해서 로그아웃 시킴
        }
      }
      else
      {
        // console.log("만료시간 남음");
        config.headers = {
          Authorization: `Bearer ${user.accessToken}`,
          Accept: 'application/json',
        };
      }
    }

    config.headers["Yame-Language"] = language;

    return config;
  },
  (error) => {
    Promise.reject(error);
  },
);

// For POST requests
axiosApiInstance.interceptors.response.use(
   (res) => {
      // Add configurations here
      if (res.status === 201) {
        //  console.log('Posted Successfully');
      }
      return res;
   },
   (err) => {
      return Promise.reject(err);
   }
);


export default axiosApiInstance;