import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';

import { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { packageList, packageStudentInfo } from "../../../actions/package";

import { useNavigate } from 'react-router-dom';

import PackageDialog from "./PackageDialog";

export default function PackageList() {

    const myPackage = useSelector(state => state.myPackage.list);
    console.log("myPackage : ", myPackage);

    const studentInfo = useSelector(state => state.myPackage.studentInfo);
    console.log("studentInfo : ", studentInfo);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleImgError = (e) => {
      e.target.src = "https://cdn-icons-png.flaticon.com/512/1474/1474713.png";
    }

    const refreshFunction = () => {
      //window.location.reload();

      // 여기서 디스패치로~
      dispatch(packageList())
      .then(data => 
      {
          console.log("data : ", data);
      })
      .catch(e => {
          console.log(e);
      });

      // 여기서 디스패치로~
      dispatch(packageStudentInfo())
      .then(data => 
      {
          console.log("data : ", data);
      })
      .catch(e => {
          console.log(e);
      });

    };

    useEffect(() => {

        // 여기서 디스패치로~
        dispatch(packageList())
        .then(data => 
        {
            console.log("data : ", data);
        })
        .catch(e => {
            console.log(e);
        });

        // 여기서 디스패치로~
        dispatch(packageStudentInfo())
        .then(data => 
        {
            console.log("data : ", data);
        })
        .catch(e => {
            console.log(e);
        });

    }, [dispatch])

  return (
    <Card sx={{ minWidth: 275 }}>
      <CardContent>
      {
        studentInfo && `패키지 관리 리스트 (총 학습자 : ${studentInfo.total}, 사용 학습자 : ${studentInfo.used}, 미사용 학습자 : ${studentInfo.unused})`
      }
      <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="center">번호</TableCell>
            <TableCell align="center">주문번호</TableCell>
            <TableCell align="center">상품명</TableCell>
            <TableCell align="center">사용갯수</TableCell>
            <TableCell align="center">설정</TableCell>
            <TableCell align="center">유효기간</TableCell>
            <TableCell align="center">패키지 상태</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {myPackage && myPackage.map((row, index) => (
            <TableRow
              key={index}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row" align="center">
                {row.ord_idx || ""}
              </TableCell>
              <TableCell align="center"><Link href={`/dashboard/OrderView/${row.ord_idx || ""}`} underline="none">{row.ord_no || ""}</Link></TableCell>
              {/* <TableCell align="center"><img src={common.returnImgSrc(row.prd_photo_path, row.prd_photo_file)} width="30" height="30" onError={handleImgError} /></TableCell> */}
              <TableCell align="center">{row.prd_name || ""}</TableCell>
              <TableCell align="center">{row.student_count || "0"}/{row.ord_stat_cd === 'F' ? ((row.ord_count * row.default_amount) || 0) : (row.ord_count || 0) }</TableCell>
              <TableCell align="center"><PackageDialog packageNo={row.ord_no} orderCount={row.ord_stat_cd === 'F' ? ((row.ord_count * row.default_amount) || 0) : (row.ord_count || 0)} refreshFunction={refreshFunction} /></TableCell>
              <TableCell align="center">{row.ord_valid_date || ""}</TableCell>
              <TableCell align="center">{row.ord_stat_cd && row.ord_stat_cd === 'A' ? "유료 결제완료" : "무료"}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      </TableContainer>

      </CardContent>
      <CardActions>
        
      </CardActions>
    </Card>
  );
}