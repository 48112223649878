import {
  CREATE_PAYMENT,
  LIST_PAYMENT,
  CANCEL_PAYMENT,
  SELECT_PAYMENT,
  VBANK_CANCEL_PAYMENT,
} from "../actions/types";

const initialState = [];

const paymentReducer = (payment = initialState, action) => {
  const { type, payload } = action;

  // console.log("paymentReducer Call!");
  // console.log("type : ", type);
  // console.log("payload : ", payload);

  switch (type) {
    
    case CREATE_PAYMENT:
      return [];

    case LIST_PAYMENT:
      return payload;

    case SELECT_PAYMENT:
      return payload;

    case CANCEL_PAYMENT:
      return payment;

    case VBANK_CANCEL_PAYMENT:
      return payment;

    default:
      return payment;
  }
};

export default paymentReducer;