import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';

import TextareaAutosize from '@mui/material/TextareaAutosize';

import Stack from '@mui/material/Stack';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import MobileDatePicker from '@mui/lab/MobileDatePicker';

import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';

import ImgFileUpload from '../../../common/ImgFileUpload';

import { useState } from "react";
import { useDispatch } from "react-redux";
import { couponCreate } from "../../../actions/coupon";
import { useNavigate } from 'react-router-dom';

export default function CouponWritePopup(props) {

    const initialSendData = {
        name: "", 
        desc: "",
        amount: 0,
        limit: false,
        start_date: null,
        end_date: null,
        expiration_date: 0,
        expiration_date_unit: null,
        file: null,
    };

  const [open, setOpen] = useState(false);
  const [sendData, setSendData] = useState(initialSendData);
  const [checkboxDisabled, setCheckboxDisabled] = useState(true);
  const [datePickerDisabled, setDatePickerDisabled] = useState(false);
  
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleInputChange = event => 
  {
    const { name, value } = event.target;
    console.log("event target name : ", name);
    console.log("event target value : ", value);
    
    if (name === "limit")
    {
        setSendData({ ...sendData, [name]: value });
        setCheckboxDisabled(!event.target.checked);
    }
    else if (name === "expiration_date")
    {
        if (value == 0)
            setDatePickerDisabled(false);
        else
            setDatePickerDisabled(true);

        setSendData({ ...sendData, [name]: value });
    }
    else
    {
        setSendData({ ...sendData, [name]: value });
    }

  };

    const handleStartDateChange = (newValue) => {
        setSendData({ ...sendData, start_date: newValue });
    };

    const handleEndDateChange = (newValue) => {
        setSendData({ ...sendData, end_date: newValue });
    };

    const handleImgFileChange = (file) => {
        console.log("file : ", file);
        setSendData({ ...sendData, file: file });
    };

  const handleSubmit = (event) => {
    event.preventDefault();

    const formData = new FormData();
    formData.append('imgFile', sendData.file);
    formData.append('name', sendData.name);
    formData.append('desc', sendData.desc);
    formData.append('amount', sendData.amount);
    formData.append('limit', sendData.limit === "false" ? 1 : 0);
    formData.append('start_date', sendData.start_date);
    formData.append('end_date', sendData.end_date);
    formData.append('expiration_date', sendData.expiration_date);
    formData.append('expiration_date_unit', sendData.expiration_date_unit);

    console.log("sendData : ", sendData);

    if (sendData.start_date !== null)
        formData.append('start_date', sendData.start_date.toISOString().split("T")[0]);
    else
        formData.append('start_date', sendData.start_date);
    
    if (sendData.end_date !== null)
        formData.append('end_date', sendData.end_date.toISOString().split("T")[0]);
    else
        formData.append('end_date', sendData.end_date);

    if (sendData.start_date === null && sendData.end_date === null && sendData.expiration_date === 0)
    {
        return alert("유효시간과 시작일, 종료일 중에 하나만 선택해주세요!");
    }

    if (sendData.expiration_date_unit === null)
    {
        return alert("유효시간의 단위를 선택해주세요!");
    }

    // 여기서 디스패치로~
    dispatch(couponCreate(formData))
    .then(data => 
    {
        console.log("data : ", data);
        if (!data.success)
        {
            alert(data.msg);
            return;
        }
        else
        {
            alert("쿠폰 발급 완료");
            // navigate("/auth/login");

            // 데이터 초기화
            setSendData(initialSendData);

            props.onReloadWindow();
            handleClose();
        }
    })
    .catch(e => {
        console.log(e);
    });
    
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {

    // 데이터 초기화
    setSendData(initialSendData);
    setOpen(false);
  };

  return (
    <div>
      <Button variant="outlined" onClick={handleClickOpen}>
        {props.buttonText}
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>쿠폰 발급</DialogTitle>
        <DialogContent>
          <DialogContentText>
            * 쿠폰수량제한 옵션을 켜면 수량이 제한되고 쿠폰수량제한이 없으면 무제한입니다.<br></br>
            * 쿠폰수량을 입력하고 발급을 하시면 발급 즉시 쿠폰의 갯수만큼 생성됩니다.<br></br>
            * 쿠폰수량제한 옵션이 껴져있는 경우 수동생성입니다.
          </DialogContentText>
            <Box
                component="span"
                sx={{
                    display: 'block',
                    p: 1,
                    m: 1,
                }}
            >
                <TextField
                    required
                    style={{ width: 500 }}
                    id="name"
                    label="쿠폰명"
                    name="name"
                    autoFocus
                    onChange={handleInputChange}
                    value={sendData.name}
                    size="small"
                />
            </Box>
            <Box
                component="span"
                sx={{
                    display: 'block',
                    p: 1,
                    m: 1,
                }}
            >
                <TextareaAutosize
                    aria-label="coupon desc"
                    minRows={5}
                    name="desc"
                    value={sendData.desc}
                    onChange={handleInputChange}
                    placeholder="쿠폰설명"
                    style={{ width: 500 }}
                />
                <FormGroup>
                    <FormControlLabel control={<Checkbox />} 
                    name="limit"
                    value={sendData.limit}
                    onChange={handleInputChange}
                    label="발급수량제한(체크 시 쿠폰수량제한)" />
                </FormGroup>
                <br></br>
                <TextField
                    id="amount"
                    label="발급가능수량"
                    type="number"
                    disabled={checkboxDisabled}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    variant="standard"
                    name="amount"
                    value={sendData.amount}
                    helperText="발급가능수량 만큼 쿠폰이 생성됩니다."
                    onChange={handleInputChange}
                    inputProps={{
                        inputMode: 'numeric', 
                        pattern: '[0-9]*',
                        min: 1,
                        max: 10000,
                        type: 'number',
                    }}
                />
                <br></br><br></br>
                <FormControl sx={{ width: '120px' }}>
                <InputLabel id="expiration_date_unit">유효단위</InputLabel>
                    <Select
                    labelId="expiration_date_unit"
                    id="expiration_date_unit"
                    label="유효단위"
                    onChange={handleInputChange}
                    name="expiration_date_unit"
                    value={sendData.expiration_date_unit}
                    >
                        <MenuItem value={'day'}>일</MenuItem>
                        <MenuItem value={'month'}>월</MenuItem>
                    </Select>
                </FormControl>
                <br></br><br></br>
                <TextField
                    id="expiration_date"
                    label="유효기간"
                    type="number"
                    value={sendData.expiration_date}
                    helperText="유효기간으로 기본 단위는 1달(월)입니다."
                    InputLabelProps={{
                        shrink: true,
                    }}
                    variant="standard"
                    name="expiration_date"
                    onChange={handleInputChange}
                    inputProps={{
                        inputMode: 'numeric', 
                        pattern: '[0-9]*',
                        min: 1,
                        max: 12,
                        type: 'number',
                    }}
                />
            <br></br><br></br>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Stack spacing={3}>
                    <MobileDatePicker
                    label="시작일"
                    inputFormat="yyyy/MM/dd"
                    value={sendData.start_date || ""}
                    onChange={handleStartDateChange}
                    renderInput={(params) => <TextField {...params} />}
                    name="start_date"
                    disabled={datePickerDisabled}
                    />
                    <MobileDatePicker
                    label="종료일"
                    inputFormat="yyyy/MM/dd"
                    value={sendData.end_date || ""}
                    onChange={handleEndDateChange}
                    renderInput={(params) => <TextField {...params} />}
                    name="end_date"
                    disabled={datePickerDisabled}
                    />
                </Stack>
            </LocalizationProvider>
            <br></br>
            <ImgFileUpload handleImgFileChange={handleImgFileChange}  width="400" />
            </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>취소</Button>
          <Button onClick={handleSubmit}>발급</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}