import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';

import { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { rankingList, rankingDelete, rankingMake } from "../../../actions/ranking";

import { useNavigate, useParams } from 'react-router-dom';

export default function RankingList() {

    const ranking_list = useSelector(state => state.ranking?.list);
    console.log("ranking_list : ", ranking_list);
    const ranking_paging = useSelector(state => state.ranking?.paging);
    console.log("ranking_paging : ", ranking_paging);
    let board_page  = useParams().prevPage;
    console.log("board_page : ", board_page);

    if (board_page === undefined)
          board_page = 1;

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleWriteRanking = (event) => {
      navigate(`/dashboard/RankingWrite`);
    };

    const handleDeleteRanking = (event, idx) => {

      event.preventDefault();

      console.log(idx);

      const sendDelete = {
        idx: idx,
        delete: 1,
      }
  
      console.log("sendDelete : ", sendDelete);

      if (window.confirm("정말 삭제하시겠습니까??") === true)   //확인
      {
        // 여기서 디스패치로~
        dispatch(rankingDelete(sendDelete))
        .then(data => 
        {
            console.log("data : ", data);
            
            const boardListSend = {
              board_page : board_page,
            }
      
            // 여기서 디스패치로~
            dispatch(rankingList(boardListSend))
            .then(data => 
            {
                console.log("data : ", data);
                navigate(`/dashboard/RankingList/${board_page}`);
            })
            .catch(e => {
                console.log(e);
            });
        })
        .catch(e => {
            console.log(e);
        });

        // navigate(`/dashboard/NoticeList/${board_name}/${board_page}`);
      }
      else  //취소
      {
        return false;
      }

    };

    const handleRankingMake = (event, idx) => {

      event.preventDefault();

      console.log(idx);

      const sendData = {
        idx: idx,
      }
  
      console.log("sendData : ", sendData);

      if (window.confirm("랭킹 통계를 만들까요?") === true)   //확인
      {
        // 여기서 디스패치로~
        dispatch(rankingMake(sendData))
        .then(data => 
        {
            console.log("data : ", data);
            
            const boardListSend = {
              board_page : board_page,
            }
      
            // 여기서 디스패치로~
            dispatch(rankingList(boardListSend))
            .then(data => 
            {
                console.log("data : ", data);
                navigate(`/dashboard/RankingList/${board_page}`);
            })
            .catch(e => {
                console.log(e);
            });
        })
        .catch(e => {
            console.log(e);
        });

        // navigate(`/dashboard/NoticeList/${board_name}/${board_page}`);
      }
      else  //취소
      {
        return false;
      }

    };

    // 게시물의 번호를 계산하는 함수
    const listNumberReturn = (paging, index) => {
      let listNumber;
      return listNumber = ((paging.totalPageCount - index) - ((paging.curPage -1) * paging.page_size));
    }

    const onPaging = (e, page) => {
      if (!page) return;
      console.log("page : ", page);
      
      const boardListSend = {
        board_page : page,
      }

      // 여기서 디스패치로~
      dispatch(rankingList(boardListSend))
      .then(data => 
      {
          console.log("data : ", data);
          navigate(`/dashboard/RankingList/${page}`);
      })
      .catch(e => {
          console.log(e);
      });
    }

    useEffect(() => {

        const boardListSend = {
          board_page : board_page,
        }

        // 여기서 디스패치로~
        dispatch(rankingList(boardListSend))
        .then(data => 
        {
            console.log("data : ", data);
        })
        .catch(e => {
            console.log(e);
        });
        
        return () => {      // 언마운트
            console.log("언마운트!");
        };

    }, [dispatch])

  return (
    <Card sx={{ minWidth: 275 }}>
      <CardContent>
      야미 랭킹 환경설정
      <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="center">번호</TableCell>
            <TableCell align="center">회차</TableCell>
            <TableCell align="center">수정</TableCell>
            <TableCell align="center">삭제</TableCell>
            <TableCell align="center">통계</TableCell>
            <TableCell align="center">순위</TableCell>
            <TableCell align="center">점수 데이터</TableCell>
            <TableCell align="center">등록일</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {ranking_list?.length !== 0 ? ranking_list && ranking_list.map((row, index) => (
            <TableRow
              key={index}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row" align="center">
                {listNumberReturn(ranking_paging, index)}
              </TableCell>
              <TableCell align="center"><Link href={`/dashboard/RankingView/${row.idx}/${board_page}`} underline="none">{row.order}회차</Link></TableCell>
              <TableCell align="center"><Link href={`/dashboard/RankingModify/${row.idx}/${board_page}`} underline="none">수정</Link></TableCell>
              <TableCell align="center"><Link onClick={(e)=>handleDeleteRanking(e, row.idx)} value={row.idx} underline="none">삭제</Link></TableCell>
              <TableCell align="center"><Link onClick={(e)=>handleRankingMake(e, row.idx)} value={row.idx} underline="none">생성</Link></TableCell>
              <TableCell align="center"><Link href={`/dashboard/RankingTotalList/${row.idx}/${row.order}/${board_page}`} underline="none">확인</Link></TableCell>
              <TableCell align="center"><Link href={`/dashboard/RankingChallengeList/${row.idx}/${row.order}/${board_page}`} underline="none">확인</Link></TableCell>
              <TableCell align="center">{row.reg_date}</TableCell>
            </TableRow>
          )) 
          :  
            <TableRow
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row" align="center" colSpan="7">데이터 없음</TableCell>
            </TableRow>
        }
        </TableBody>
      </Table>
      </TableContainer>
      {
        ranking_list?.length !== 0 ? 
          <Stack spacing={2} sx={{ height: 60, display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
            <Pagination count={ranking_paging?.totalPage || 0} page={ranking_paging?.curPage || 0} onChange={onPaging} color="primary" />
          </Stack>
        :
        <></>
      }
      </CardContent>
      <CardActions sx={{ height: 30}}>
        <Button onClick={handleWriteRanking} size="small">랭킹 추가</Button>
      </CardActions>
    </Card>
  );
}