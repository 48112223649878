import http from "../http-common";

const inappPaymentGetAll = () => {
  return http.get("payment/InappList");
};

const inappPaymentSelect = data => {
  return http.get(`payment/InappList/select/${data}`);
};

const inappPaymentRefund = data => {
  return http.post("payment/inappRefund", data);
};

const InappPaymentService = {
  inappPaymentGetAll,
  inappPaymentSelect,
  inappPaymentRefund,
};

export default InappPaymentService;
