import http from "../http-common";

const missionCategoryCreate = data => {
  return http.post("mission/category", data);
};

const missionCategoryGetAll = () => {
  return http.get("mission/category");
};

const missionCategoryDelete = data => {
  return http.delete("mission/category", {data : {category_idx : data}});
};

const missionCategoryModify = data => {
  return http.put("mission/category", data);
};

const missionCategorySelect = data => {
  return http.get(`mission/category/select/${data}`);
};

const MissionCategoryService = {
  missionCategoryCreate,
  missionCategoryGetAll,
  missionCategoryDelete,
  missionCategoryModify,
  missionCategorySelect,
};

export default MissionCategoryService;
