import * as React from 'react';
import { useEffect } from 'react';
import styles from "../css/common.module.css";
import $ from 'jquery';

export default function Use() {

	useEffect(() => {

		// 여기에서 jQuery를 초기화 합니다.
		$(document).ready(function(){

		});

	}, [$]);

  	return (
    <>
		<div className={styles.wrap}>
			<div className={styles.privacy_info}>
				<p>
				<span className={styles.color}>Weclover Co., Ltd.</span>is (hereinafter referred to as 'company') We value your personal information and comply with the relevant laws and regulations such as the Act on Promotion of Information and Communication Network Utilization and Information Protection, etc. and the Personal Information Protection Act.
				<br/>Through the Personal Information Processing Policy, the company will inform you of the purpose and method of using the personal information provided by you and what measures are being taken to protect your personal information.
				<br/><br/>If the company revises its privacy policy, it will make an announcement on its website (or individual announcement)
				</p>
			</div>
			<div className={`${styles.privacy_area} ${styles.area1}`}>
				<span className={styles.tit}>1. Items and methods of collecting personal information to be collected</span>
				<p>
				(1) Items of personal information collected
				<br/><br/>* The following information will be collected when registering as a member
				<br/>- When using the website: email address (account name), nickname, password, mobile phone number, name
				<br/><br/>* The following information can be collected during the service use process
				<br/>- Service usage records, access logs, IP information, defective usage records, mobile device information (model name, mobile carrier information, OS information, language and country information, device unique identification number, advertising ID, etc.)
				<br/><br/>* The following information may be collected when providing services that require age verification and self-authentication.
				<br/>- Mobile phone identity verifier: Name, date of birth, gender, duplicate subscription confirmation information (DI), connection information (CI), domestic and foreign information
				<br/>- Children under the age of 14 and youth under the age of 18 users: Personal authentication information of legal representatives (e-mail of legal representatives, name, date of birth, gender, duplicate subscription confirmation information (DI), connection information (CI), personal authentication information (name, date of birth, gender, duplicate subscription confirmation information)
				<br/><br/>* The company may collect additional information when using the following customer-related services, and in this case, a separate personal information collection and use consent are obtained.<br/>
				</p>
				<div className={styles.box_wrap}>
					<div className={styles.title}>
						<span>Service Name</span>
						<span>Personal information items you collect</span>
					</div>
					<div className={styles.list}>
						<span className={styles.name}>Participation in the event</span>
						<span>
						-Shipping Product: Address, Phone Number<br/>
						-Mobile Product: Mobile Phone Number
						</span>
					</div>
					<div className={styles.list}>
						<span className={styles.name}>Customer Care Services<br/>(Kakao Talk Service Center)</span>
						<span>
						-Mandatory: Mobile phone number, nickname (name)<br/>
						-Selections: Contact information (name, phone number) stored in your device address book, profile picture
						</span>
					</div>
					<div className={styles.list}>
						<span className={styles.name}>When you pay for it</span>
						<span>
						- When paying on a mobile phone: Mobile carrier<br/>
						- When making a real-time account transfer payment: Bank name<br/>
						- In case of refund: bank name, depositor name, account number, contact information<br/>
						- When registering coupons: Contact, coupon information<br/>
						- Internet banking payment: Date of birth<br/>
						- Credit card: Card company name
						</span>
					</div>
				</div>
				<p>
				(2) How to Collect Personal Information<br/><br/>
				It is collected through the collection tool through the homepage membership registration, service use (including mobile services), membership information modification, telephone, fax, customer center counseling, event application, provision from partner companies, and creation information collection tool.
				</p>
			</div>
			<div className={`${styles.privacy_area} ${styles.area2}`}>
				<span className={styles.tit}>2. Processing of unique identification information</span>
				<p>
				(1) Unique identification information refers to resident registration number, passport number, driver's license number, and alien registration number as "information prescribed by Presidential Decree" in the Personal Information Protection Act and the Enforcement Decree.<br/><br/>
				(2) The company collects and processes unique identification information for the purposes of each of the following subparagraphs, and when collected, it will be collected with separate consent.<br/><br/>
				* imposition of tax charges on winners of in-kind prizes
				<br/>* If required by other laws
				<br/><br/>(3) The collected unique identification information is not used and provided for purposes other than those prescribed by paragraph (2), except as otherwise provided by law, and is encrypted and managed securely when stored.
				</p>
			</div>
			<div className={`${styles.privacy_area} ${styles.area3}`}>
				<span className={styles.tit}>3. Purpose of collecting and using personal information</span>
				<p>
				The company utilizes the collected personal information for the following purposes.<br/><br/>

				(1) Fee settlement based on performance of contract and service provision, content provision, purchase and payment, refund, delivery of goods, self-certification, collection of charges<br/><br/>
				(2) Member management<br/><br/>
				Identification of identity according to the use of membership services, personal identification, prevention of illegal use and unauthorized use of bad members, confirmation of intention to join, confirmation of age, confirmation of consent of legal representatives when collecting personal information of children under the age of 14, confirmation of consent of users under the age of 18 when registering as members, confirmation of identification of legal representatives, handling complaints, and handling complaints such as customer counseling, preservation of records for dispute mediation, and delivery of notices<br/><br/>

				(3) Use it for marketing and statistical analysis<br/><br/>
				Development and specialization of new services (products), delivery of promotional information such as events, provision of services and advertisements based on demographic characteristics, identification of access frequency, or statistical analysis of members' use of services						</p>
			</div>
			<div className={`${styles.privacy_area} ${styles.area4}`}>
				<span className={styles.tit}>4. Period of retention and use of personal information</span>
				<p>
				In principle, after the purpose of collecting and using personal information is achieved, the information is destroyed without delay. (However, the company will keep personal information for 15 days after requesting withdrawal to minimize damage caused by account theft, etc.) In addition, if it is necessary to preserve it even after the purpose of collection and use is achieved in accordance with the relevant laws and regulations, the company shall keep the membership information for a certain period as prescribed by the relevant laws and regulations as follows<br/><br/>

				- Record of display/advertisement: 6 months (Act on Consumer Protection in Electronic Commerce, etc.)<br/>
				- Record of contract or withdrawal of subscription: 5 years (Act on Consumer Protection in Electronic Commerce, etc.)<br/>
				- Record of payment and supply of goods: 5 years (Act on Consumer Protection in Electronic Commerce, etc.)<br/>
				- Record of consumer complaints or disputes: 3 years (Act on Consumer Protection in Electronic Commerce, etc.)<br/>
				- Record of website visits: 3 months (Communications Secret Protection Act)						
				</p>
			</div>
			<div className={`${styles.privacy_area} ${styles.area5}`}>
				<span className={styles.tit}>5. Procedures and methods for destroying personal information</span>
				<p>
				In principle, the company shall destroy the relevant information without delay after the purpose of collecting and using personal information is achieved, and the procedure and method of destruction are as follows.<br/><br/>

				(1) procedure for destruction<br/>
				- The information entered by the user for membership registration, etc., will be stored for a certain period of time according to the internal policy and other reasons for information protection under relevant laws (see retention and usage period) after the purpose is achieved (after a 15-day grace period when applying for withdrawal).<br/><br/>
				(2) Method of destruction
				<br/>- Personal information stored in electronic file format is deleted using a technical method that does not allow the record to be played back.
				<br/>- The personal information printed on the document shall be shredded with a grinder or destroyed by incineration.					
				</p>
			</div>
			<div className={`${styles.privacy_area} ${styles.area6}`}>
				<span className={styles.tit}>6. Separate storage and management of personal information of long-term unused accounts</span>
				<p>
				For the past year, accounts without service usage records are converted to long-term unused accounts, and the personal information of those accounts is stored and managed separately.<br/>
				(However, CAT messenger phone number accounts that have not been certified will be deleted immediately without separation or storage.)<br/>
				If personal information is stored and managed separately due to long-term unused account conversion, all services, including game use, are not available, and you must go through a separate return procedure to use the service again.<br/>
				If you use the service again before the expiration of the period, the conversion of long-term unused accounts will be canceled, so if you do not want to convert long-term unused accounts or keep your personal information separately, you can log in to the YAM-E app or use the YAM-E app for a moment.<br/><br/>

				- Destination of segregated storage and management: Account with no service usage record for one year<br/>
				- Expiration date: When a year arrives without a service usage record<br/>
				- Items of personal information stored and managed separately: Account information, contact information, personal information, service use records, payment records, counseling records, etc					
				</p>
			</div>
			<div className={`${styles.privacy_area} ${styles.area7}`}>
				<span className={styles.tit}>7. provision of personal information</span>
				<p>
				In order to implement the service, the company is entrusted to an external professional company as follows.<br/><br/>

				Trustee: Weclover Co., Ltd<br/>
				Purpose of consignment: handling complaints such as customer counseling and handling complaints, sending DMs, sending SMS, operating and managing the event prize delivery service<br/><br/>
				The company uses the user's personal information within the scope notified in "3. Purpose of Collection and Use of Personal Information", and if personal information is to be provided to a third party, consent is obtained according to laws and regulations. However, exceptions are made to the following cases.<br/><br/>

				- Where there is a request from an investigative agency in accordance with the provisions of laws and regulations or in accordance with the procedures and methods prescribed by laws and regulations for the purpose of investigation<br/>
				- Where it is necessary for the settlement of charges according to the provision of services<br/>
				- Where there are special provisions in other laws					
				</p>
			</div>
			<div className={`${styles.privacy_area} ${styles.area8}`}>
				<span className={styles.tit}>8. Rights of users and legal representatives and methods of exercising them</span>
				<p>
				Users and legal representatives can inquire or modify the personal information of registered children or children under the age of 14 at any time, and can also request cancellation of the subscription. Click "Change Personal Information" (or "Modify Member Information" to inquire/modify personal information of users or children under the age of 14), and click "Apply for withdrawal of membership" to view, correct, or leave directly. Or contact the person in charge of personal information protection in writing, by phone, or by e-mail, and we will take action without delay.<br/>
				If a user requests correction of an error in personal information, the relevant personal information will not be used or provided until the correction is completed. In addition, if incorrect personal information has already been provided to a third party, the correction processing result will be notified to the third party without delay so that the correction can be made. The company processes personal information terminated or deleted at the request of the user or legal representative in accordance with the provisions of "3. Period of Retention and Use of Personal Information to be Collected" and processes it so that it cannot be viewed or used for other purposes					
				</p>
			</div>
			<div className={`${styles.privacy_area} ${styles.area9}`}>
				<span className={styles.tit}>9. Matters concerning the installation and operation of devices that automatically collect personal information, such as Internet access information files, and refusal thereof</span>
				<p>
				The company operates "cookies" that store and find users' information from time to time. A cookie is a tiny text file sent to your browser by the server used to run your company's website and is stored on your computer's hard disk. The company uses cookies for the following purposes.<br/><br/>

				(1) Purpose of using cookies, etc<br/>
				We provide personalized services by analyzing the frequency of access between members and non-members, identifying the user's preferences and areas of interest, tracking the trace, and identifying the number of visits<br/><br/>

				(2) To reject cookie settings<br/>
				Users have the option of installing cookies. As a result, users can either allow all cookies by setting options in their web browser, go through confirmation whenever they are saved, or refuse to save all cookies.					
				</p>
				<div className={styles.txt_wrap}>
					<p>
					[How to set cookies]<br/><br/>
					① Internet Explorer: Tools → Internet Options → Personal Information → [Advanced] at the top of the web browser
					<br/>② Chrome: You can set it through the ⋮ → Settings → Show Advanced Settings → Personal Information [Content Settings] menu in the top right of the web browser.
					<br/>However, if a member refuses to save cookies, there may be difficulties in providing some services							
					</p>
				</div>
			</div>
			<div className={`${styles.privacy_area} ${styles.area10}`}>
				<span className={styles.tit}>10. Rights of users and legal representatives and methods of exercising them</span>
				<p>
				(1) Technical Measures for the Protection of Personal Information<br/><br/>
				The company is taking the following technical measures to ensure safety so that personal information is not lost, stolen, leaked, altered or damaged in processing the user's personal information.<br/>

				<br/>Your personal information is protected by the password, and only you can know the password of your account, and you can check and change your personal information only by the person who knows the password.
				<br/>In order to prevent the leakage of user's personal information in preparation for external intrusion such as hacking, the company is currently using a device that blocks intrusion from outside to prevent attacks such as hacking. In particular, servers that hold your personal information maintain the highest level of security, such as managing them separately without directly connecting to external Internet lines.
				<br/>The company has a system that backs up systems and data in case of emergency.
				<br/>The company is using a vaccination program to take measures to prevent damage caused by computer viruses. Vaccine programs are updated periodically, and if a sudden virus emerges, it is provided as soon as the vaccine is released to prevent personal information from being infringed.
				<br/><br/>(2) Management Measures for Personal Information Protection
				<br/>The company limits users' access to personal information to a minimum number of people. The minimum number of people is as follows.
				<br/><br/>* A person who conducts marketing work directly with the user
				<br/>* A person who performs personal information management duties, such as the personal information complaint handling department and the person in charge
				<br/>* A person who is inevitable to process personal information for other business purposes
				<br/><br/>The company provides regular in-house and outsourced training on new security technologies and personal information protection obligations for employees who process personal information.
				<br/>The company prepares internal procedures to prevent information leakage by humans through security commitments of all employees when joining the company, and to audit the implementation of personal information processing policies and compliance of employees.
				<br/>The company thoroughly takes over tasks of personal information-related controllers while maintaining security, and clarifies responsibility for personal information accidents after entering and leaving the company.
				<br/>The company controls access by setting computer rooms and data storage rooms as special protection zones.
				<br/>The company is not responsible for personal information leakage caused by personal mistakes or inherent risks of the Internet. Users must properly manage their accounts and passwords to protect their personal information and take responsibility for them.						
				</p>
			</div>
			<div className={`${styles.privacy_area} ${styles.area11}`}>
				<span className={styles.tit}>11. Change of privacy policy</span>
				<p>
				If there are any changes to the personal information processing policy (addition, deletion, and modification), the company notifies you through a notice on the website. In addition, we compare the changes before and after so that customers can easily check the changes.
				</p>
			</div>
			<div className={`${styles.privacy_area} ${styles.area12}`}>
				<span className={styles.tit}>12. Contact information of the person in charge of personal information protection and the person in charge of handling personal information grievances</span>
				<p>
				In order to protect the personal information of customers and to handle complaints related to personal information, the company has designated relevant departments and personal information protection officers as follows.<br/>

				<br/>a person in charge of personal information protection
				<br/><br/>* a person information protection officer
				<br/>Name: Park Yoon-hwan
				<br/>Contact information : 02-875-3330
				<br/>e-mail : admin@weclover.co.kr
				<br/>※ Destroy the personal information to the person in charge of personal information protection.
				<br/><br/>* Department in charge of personal information protection
				<br/>Department Name: Development Team
				<br/>Person in charge: Person in charge of personal information protection and person in charge
				<br/>Contact information : 02-875-3330
				<br/><br/>Users can report all complaints related to personal information protection arising from using the company's services to the person in charge of personal information protection or the person in charge of handling personal information grievances. The company will promptly and fully respond to users' reports.

				<br/><br/>If you need to report or consult other personal information infringement, please contact the agency below.

				<br/><br/>1. Personal Information Infringement Reporting Center(privacy.kisa.or.kr / ☎ without a country(korea) code 118)
				<br/>2. Cyber Investigation Division of Supreme Prosecutors' Office(spo.go.kr / ☎ without a country code(korea) 1301)
				<br/>3. National Police Agency Cyber Security Department(cyberbureau.police.go.kr / ☎ without a country code(korea) 182)
				<br/>4. Personal Information Dispute Mediation Committee(kopico.go.kr / ☎ without a country code(korea) 1833-6972)						
				</p>
			</div>
		</div>
    </>
  );
}