import {
  CREATE_GROUP_PAYMENT,
  UPDATE_GROUP_PAYMENT,
  STATUS_GROUP_PAYMENT,
} from "../actions/types";

const initialState = [];

const groupPaymentReducer = (group_payment = initialState, action) => {
  const { type, payload } = action;

  // console.log("paymentReducer Call!");
  // console.log("type : ", type);
  // console.log("payload : ", payload);

  switch (type) {
    
    case CREATE_GROUP_PAYMENT:
      return payload;

    case UPDATE_GROUP_PAYMENT:
      return payload;

    case STATUS_GROUP_PAYMENT:
      return payload;

    default:
      return group_payment;
  }
};

export default groupPaymentReducer;