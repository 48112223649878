import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { missionList, missionDelete, missionOrderChange } from "../../../actions/mission";
import { missionCategoryList } from "../../../actions/mission_category";

import { useNavigate, useParams } from 'react-router-dom';

import * as common from "../../../lib";

function SimpleDialog(props) {

  const { onClose, orderby, open, selectMission, currentIdx } = props;

  const handleClose = () => {
    onClose();
  };

  const handleChange = (event) => {
    onClose(event.target.value);
  };

  const drawSelectItem = () => {
                
    let returnValue = [];
    
    for (let i=0; i<selectMission.length; i++)
    {
      if (selectMission[i].mis_orderby !== currentIdx)
        returnValue[i] = <MenuItem value={selectMission[i].mis_orderby} key={i}>{selectMission[i].mis_orderby}</MenuItem>;
    }

    return returnValue;
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>순서 변경</DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ marginBottom: 3 }}>
          변경할 순서를 정해주세요!
        </DialogContentText>
          
        <Box sx={{ minWidth: 120 }}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">순서</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={orderby}
              label="순서"
              onChange={handleChange}
            >
              {drawSelectItem()}
            </Select>
          </FormControl>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>취소</Button>
      </DialogActions>
    </Dialog>
  );
}

export default function MissionList() {

    const [open, setOpen] = useState(false);
    const [orderby, setOrderby] = useState('');
    const [categoryIdx, setCategoryIdx] = useState('');
    const [currentIdx, setCurrentIdx] = useState('');
    const [selectMission, setselectMission] = useState('');
    const [listCategoryIdx, setListCategoryIdx] = useState('');

    // const handleClickOpen = () => {
    //   setOpen(true);
    // };

    const handleClose = (value) => {

      const sendData = {
        "category_idx":categoryIdx,
        "current_orderby": value,
        "change_orderby": currentIdx
      }

      console.log("sendData : ", sendData);

      if(value !== "" && value !== null && value !== undefined)
      {
        dispatch(missionOrderChange(sendData))
        .then(data => 
        {
            console.log("data : ", data);
  
            dispatch(missionList(list_category_idx))
            .then(data => 
            {
                console.log("data : ", data);
            })
            .catch(e => {
                console.log(e);
            });
        })
        .catch(e => {
            console.log(e);
        });
      }

      setOpen(false);
    };

    const mission = useSelector(state => state.mission);
    console.log("mission : ", mission);
    
    const category = useSelector(state => state.mission_category);
    console.log("category : ", category);

    let list_category_idx  = useParams().list_category_idx;
    console.log("list_category_idx : ", list_category_idx);

    if (list_category_idx === undefined)
      list_category_idx = 1;

    console.log("list_category_idx : ", list_category_idx);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleWriteMission = (event) => {
      navigate(`/dashboard/MissionWrite/${list_category_idx}`);
    };

    const returnCategory = (idx) => {
      for (let i=0; i<category.length; i++)
      {
        if (idx === category[i].mis_category_idx)
        {
          return category[i].mis_category_name;
        }
      }
    };

    const changeOrderby = (event, category_idx, current_idx) => {
      
      setCategoryIdx(category_idx);
      setCurrentIdx(current_idx);
      setselectMission(mission.filter(({ mis_category_idx }) => mis_category_idx === category_idx));
      setOpen(true);
    };

    const handleDeleteMission = (event, idx) => {
      console.log(idx);

      if (window.confirm("정말 삭제하시겠습니까??") === true)   //확인
      {
        // 여기서 디스패치로~
        dispatch(missionDelete(idx))
        .then(data => 
        {
            console.log("data : ", data);
            if (!data.success)
            {
              alert(data.msg);
              return;
            }

            // navigate("/dashboard/KnowledgeList");
            dispatch(missionList(list_category_idx))
            .then(data => 
            {
                console.log("data : ", data);
            })
            .catch(e => {
                console.log(e);
            });

            dispatch(missionCategoryList())
            .then(data => 
            {
                console.log("data : ", data);
            })
            .catch(e => {
                console.log(e);
            });

        })
        .catch(e => {
            console.log(e);
        });
      }
      else  //취소
      {
        return false;
      }

    };

    const handleInputChange = event => {

      const { name, value } = event.target;
      setListCategoryIdx(value);

      navigate(`/dashboard/MissionList/${value}`);
      
      dispatch(missionList(value))
      .then(data => 
      {
          console.log("data : ", data);
      })
      .catch(e => {
          console.log(e);
      });

    };

    useEffect(() => {

        // 여기서 디스패치로~
        dispatch(missionList(list_category_idx))
        .then(data => 
        {
            console.log("data : ", data);
        })
        .catch(e => {
            console.log(e);
        });

        dispatch(missionCategoryList())
        .then(data => 
        {
            console.log("data : ", data);
        })
        .catch(e => {
            console.log(e);
        });

        setListCategoryIdx(list_category_idx);
        
        return () => {      // 언마운트
            console.log("언마운트!");
        };

    }, [dispatch])

  return (
    <Card sx={{ minWidth: 275 }}>
      <CardContent>
      맵 리스트 <br></br><br></br>
      <Box sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center'
        }}
      >
        <Box sx={{ minWidth: 200, width:200}}>
          <FormControl fullWidth>
            <InputLabel id="category_idx">카테고리</InputLabel>
            <Select
              labelId="category_idx"
              id="category_idx"
              name="category_idx"
              value={listCategoryIdx || ""}
              onChange={handleInputChange}
              label="카테고리"
            >
              {category && category.map((item, index) => (
              <MenuItem value={item.mis_category_idx} key={index}>
                  {item.mis_category_name}
              </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Box>
      <br></br>

      <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="center">번호</TableCell>
            <TableCell align="center">제목</TableCell>
            <TableCell align="center">카테고리</TableCell>
            <TableCell align="center">순서</TableCell>
            <TableCell align="center">등급</TableCell>
            <TableCell align="center">순서변경</TableCell>
            <TableCell align="center">수정</TableCell>
            <TableCell align="center">삭제</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {mission && mission.map((row, index) => (
            <TableRow
              key={index}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row" align="center">
                {row.mis_idx}
              </TableCell>
              <TableCell align="center"><Link href={`/dashboard/MissionView/${row.mis_idx}/${list_category_idx}`} underline="none">{row.mis_id}</Link></TableCell>
              <TableCell align="center">{returnCategory(row.mis_category_idx)}</TableCell>
              <TableCell align="center">{row.mis_orderby}</TableCell>
              <TableCell align="center">{common.returnMissionRating(row.mis_rating)}</TableCell>
              <TableCell align="center"><Link onClick={(e)=>changeOrderby(e, row.mis_category_idx, row.mis_orderby)} value={row.mis_idx} underline="none">변경</Link></TableCell>
              <TableCell align="center"><Link href={`/dashboard/MissionModify/${row.mis_idx}/${list_category_idx}`} underline="none">수정</Link></TableCell>
              <TableCell align="center"><Link onClick={(e)=>handleDeleteMission(e, row.mis_idx)} value={row.mis_idx} underline="none">삭제</Link></TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      </TableContainer>
      <SimpleDialog
        orderby={orderby}
        selectMission={selectMission}
        currentIdx={currentIdx}
        open={open}
        onClose={handleClose}
      />
      </CardContent>
      <CardActions>
        <Button onClick={handleWriteMission} size="small">추가</Button>
      </CardActions>
    </Card>
  );
}