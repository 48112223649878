import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import { Link, Grid } from '@mui/material';
import Typography from '@mui/material/Typography';

import { useDispatch } from "react-redux";
import { inappPaymentSelect } from "../../../actions/inapp_payment";

import { useEffect, useState } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import * as common from "../../../lib";

import jQuery from 'jquery';

export default function InappPaymentView() {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    
    const inp_idx  = useParams().inp_idx;
    console.log("inp_idx : ", inp_idx);

    const initialPaymentState = {
      inp_idx: null,
      inp_no: null,
      inp_package_name: null,
      inp_product_id: null,
      inp_purchase_token: null,
      inp_stat_cd: null,
      inp_type: null,
      inp_cancel: null,
      inp_count: null,
      inp_date: null,
      ina_idx: null,
      ina_name: null,
      mem_email: null,
      mem_idx: null,
      mem_name: null,
      sell_prc_unit: null,
      detail:[],
    };

    const [payment, setPayment] = useState(initialPaymentState);

    const handleImgError = (e) => {
      e.target.src = "https://cdn-icons-png.flaticon.com/512/1474/1474713.png";
    };

    useEffect(() => {
        
        console.log("dispatch inappPaymentSelect");
        dispatch(inappPaymentSelect(inp_idx))
        .then(data => 
        {
            console.log("data : ", data);

            setPayment({
              inp_idx: data.inp_idx,
              inp_no: data.inp_no,
              inp_package_name: data.inp_package_name,
              inp_product_id: data.inp_product_id,
              inp_purchase_token: data.inp_purchase_token,
              inp_stat_cd: data.inp_stat_cd,
              inp_type: data.inp_type,
              inp_cancel: data.inp_cancel,
              inp_count: data.inp_count,
              inp_date: data.inp_date,
              ina_idx: data.ina_idx,
              ina_name: data.ina_name,
              mem_email: data.mem_email,
              mem_idx: data.mem_idx,
              mem_name: data.mem_name,
              sell_prc_unit: data.sell_prc_unit,
              detail : data.detail,
            });
        })
        .catch(e => {
            console.log(e);
        });

    }, [dispatch])

  return (
    <Card sx={{ minWidth: 275, maxWidth: 750 }}>
      <CardContent>
        <Grid container spacing={2}>
            <Grid item xs={4} md={3}>
            상품명 : 
            </Grid>
            <Grid item xs={6} md={8}>
            {payment.ina_name || ""}
            </Grid>
            <Grid item xs={4} md={3}>
            인앱 상품번호 : 
            </Grid>
            <Grid item xs={6} md={8}>
            {payment.inp_no || ""}
            </Grid>
            <Grid item xs={4} md={3}>
            인앱 패키지명 : 
            </Grid>
            <Grid item xs={6} md={8}>
              {payment.inp_package_name || ""}
            </Grid>
            <Grid item xs={4} md={3}>
            인앱 상품 아이디 : 
            </Grid>
            <Grid item xs={6} md={8}>
            {payment.inp_product_id || ""}
            </Grid>
            <Grid item xs={4} md={3}>
            인앱 토큰값 : 
            </Grid>
            <Grid item xs={6} md={8}>
              <Typography>{payment.inp_purchase_token || ""}</Typography>
            </Grid>
            <Grid item xs={4} md={3}>
            인앱 상태 : 
            </Grid>
            <Grid item xs={6} md={8}>
            {common.returnInappStatus(payment.inp_stat_cd) || ""}
            </Grid>
            <Grid item xs={4} md={3}>
            인앱 타입 : 
            </Grid>
            <Grid item xs={6} md={8}>
            {payment.inp_type || ""}
            </Grid>
            <Grid item xs={4} md={3}>
            구입개수 : 
            </Grid>
            <Grid item xs={6} md={8}>
            {payment.inp_count || ""}
            </Grid>
            <Grid item xs={4} md={3}>
            구입금액
            </Grid>
            <Grid item xs={6} md={8}>
            &#8361; {payment.inp_count ? common.priceToString(payment.inp_count * payment.sell_prc_unit) : 0 }
            </Grid>
            <Grid item xs={4} md={3}>
            결제날짜 : 
            </Grid>
            <Grid item xs={6} md={8}>
            {payment.inp_date || ""}
            </Grid>
            <Grid item xs={4} md={3}>
            인앱 구매자 : 
            </Grid>
            <Grid item xs={6} md={8}>
            {payment.mem_name || ""}
            </Grid>
            <Grid item xs={4} md={3}>
            인앱 구매자 이메일 : 
            </Grid>
            <Grid item xs={6} md={8}>
            {payment.mem_email || ""}
            </Grid>
            <Grid item xs={4} md={3}>
            * 인앱 사용자 목록 *
            </Grid>
            <Grid item xs={6} md={8}>
            </Grid>
            {payment.detail && payment.detail.map((row, index) => (
              <Grid container spacing={1} key={index}>
                <Grid item xs={4} md={3}>
                번호 : 
                </Grid>
                <Grid item xs={6} md={8}>
                  {index+1}
                </Grid>
                <Grid item xs={4} md={3}>
                인앱 사용자 : 
                </Grid>
                <Grid item xs={6} md={8}>
                  {row.mst_idx || "없음"}
                </Grid>
                <Grid item xs={4} md={3}>
                인앱 유효기간 : 
                </Grid>
                <Grid item xs={6} md={8}>
                  {row.inpd_valid_date || "없음"}
                </Grid>
                <Grid item xs={4} md={3}>
                인앱 상태 : 
                </Grid>
                <Grid item xs={6} md={8}>
                  {common.returnInappDetailStatus(row.inpd_stat_cd)}
                </Grid>
              </Grid>
            ))}
            
        </Grid>
      </CardContent>
      <CardActions>
        <Link href={`/dashboard/InappPaymentList`} underline="none">목록</Link>
      </CardActions>
    </Card>
  );
}