import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';

import Box from '@mui/material/Box';
import Input from '@mui/material/Input';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import Link from '@mui/material/Link';

import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";

import { bbsNoticeModify, bbsNoticeSelect } from "../../../actions/notice";

import { useNavigate, useParams } from 'react-router-dom';

const ariaLabel = { 'aria-label': 'description' };

export default function NoticeModify() {

    const board_name  = useParams().board_name;
    console.log("board_name : ", board_name);
    const idx  = useParams().idx;
    console.log("idx : ", idx);
    const prevPage  = useParams().prevPage;
    console.log("prevPage : ", prevPage);

    const [title, setTitle] = useState("");
    const [contents, setContents] = useState("");
    const [writeIdx, setWriteIdx] = useState("");

    const dispatch = useDispatch();
    const navigate = useNavigate();

    // 리듀서 값들 저장
    const userInfo = useSelector(state => state.auth);
    console.log("userInfo : ", userInfo);

    const onTitleChange = (event) => {
        setTitle(event.target.value);
    }
    
    const onContentsChange = (event) => {
        setContents(event.target.value);
    }
    
    const onModifySubmit = (event) => {
    
        event.preventDefault();
        
        if(userInfo.isLoggedIn)
        {
            if (userInfo.user.idx !== writeIdx)
            {
                alert("수정은 작성자만 가능합니다.");
                return;
            }
    
            const sendModify = {
                table_name: board_name,
                idx: idx,
                title: title,
                contents: contents
            }
    
            if (sendModify.title === "")
            {
                alert("제목이 없습니다. 작성해주세요.");
                return;
            }
    
            if (sendModify.contents === "")
            {
                alert("내용이 없습니다. 작성해주세요.");
                return;
            }
    
            console.log("sendModify : ", sendModify);
        
            // 여기서 디스패치로~
            dispatch(bbsNoticeModify(sendModify))
            .then(data => 
            {
                console.log("bbsModify data : ", data);
                if (!data.success)
                {
                    alert(data.msg);
                    return;
                }
                else
                {
                    navigate(`/dashboard/NoticeList/${board_name}/${prevPage}`);
                }
                    
    
            })
            .catch(e => {
                console.log("bbsModify error : ", e);
                console.log(e);
            });
        }
        else
        {
            alert("로그인 후에 수정을 할 수 있습니다.");
            return;
        }
    }

    useEffect(() => {

        // 여기서 디스패치로~
        dispatch(bbsNoticeSelect(board_name, idx))
        .then(data => 
        {
            console.log("bbsSelect data : ", data);
            setTitle(data.select.title);
            setContents(data.select.contents);
            setWriteIdx(data.select.mem_idx);
        })
        .catch(e => {
            console.log("bbsSelect error : ", e);
            console.log(e);
        });

    }, [dispatch])

  return (
    <Card sx={{ minWidth: 275 }}>
      <CardContent>
      <Box
        sx={{
            '& > :not(style)': { m: 1, width: '400px' },
        }}
        noValidate
        autoComplete="off"
        >
        공지사항 수정
        </Box>
        <Box
        sx={{
            '& > :not(style)': { m: 1, width: '400px' },
        }}
        noValidate
        autoComplete="off"
        >
        <Input 
            placeholder="공지 제목" 
            id="title"
            name="title" 
            inputProps={ariaLabel} 
            onChange={onTitleChange} 
            value={title}
            maxLength="200" 
        />
        </Box>
        <Box
        sx={{
            '& > :not(style)': { m: 1, width: '400px' },
        }}
        noValidate
        autoComplete="off"
        >
        <TextareaAutosize
            aria-label="minimum height"
            minRows={10}
            placeholder="공지 내용"
            style={{ width: 450 }}
            onChange={onContentsChange}
            id="contents" 
            name="contents"
            value={contents}
        />
        </Box>
      </CardContent>
      <CardActions>
        <Button onClick={onModifySubmit} size="small">수정</Button>
        <Link href={`/dashboard/NoticeList/${board_name}/${prevPage}`} underline="none">목록</Link>
      </CardActions>
    </Card>
  );
}