import React, { useState } from 'react';
import PopupDom from '../common/PopupDom';
import PopupPostCode from '../common/PopupPostCode';
 
const Test = () => {
	// 팝업창 상태 관리
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [addressData1, setAddressData1] = useState(null);
    const [addressData2, setAddressData2] = useState(null);
    const [addressData3, setAddressData3] = useState(null);

    if (addressData1 !== null)
        console.log("addressData1 : ", addressData1);
    if (addressData2 !== null)
        console.log("addressData2 : ", addressData2);
    if (addressData3 !== null)
        console.log("addressData3 : ", addressData3);
 
	// 팝업창 열기
    const openPostCode = () => {
        setIsPopupOpen(true)
    };
 
	// 팝업창 닫기
    const closePostCode = () => {
        setIsPopupOpen(false)
    };
 
    return(
        <div>
        	{/* 버튼 클릭 시 팝업 생성 */}
            <button type='button' onClick={openPostCode}>우편번호 검색</button>
            {/* 팝업 생성 기준 div */}
            <div id='popupDom'>
                {isPopupOpen && (
                    <PopupDom>
                        <PopupPostCode onClose={closePostCode} 
                        setAddressData1={setAddressData1}
                        setAddressData2={setAddressData2}
                        setAddressData3={setAddressData3}
                        />
                    </PopupDom>
                )}
            </div>
        </div>
    )
}
 
export default Test;