import React from 'react';
import Header from './Header';
import Footer from './Footer';
import { useSelector } from "react-redux";

const DashboardLayout = () => {

    // 로그인이 되어 있지 않으면 로그인화면으로~
    const authState = useSelector(state => state.auth);
    // console.log("redux state : ", authState);

    if (!authState.isLoggedIn)
    {
        // console.log(" isLoggedIn check ");
        document.location.href = "/auth/login";
    }

    return (
        <div>
        <Header />
        <Footer />
        </div>
    );
};

export default DashboardLayout;