import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import { Link, Grid, Typography, Divider } from '@mui/material';

import { useDispatch } from "react-redux";
import { studentSelect } from "../../../actions/student";

import { useEffect, useState } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import * as common from "../../../lib";

import http from "../../../http-common";

export default function StudentView() {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    
    const mst_id  = useParams().mst_id;
    console.log("mst_id : ", mst_id);

    const initialStudentState = {
      id: '',
      name: '', 
      nickname: '',
      rep_character: '',
      lastlogin_date: '',
    };

    const [student, setStudent] = useState(initialStudentState);

    // const handleCancelOrder = (event, idx) => {
    //   console.log(idx);

    //   if (window.confirm("정말 취소하시겠습니까??") === true)   //확인
    //   {
    //     // 여기서 디스패치로~
    //     dispatch(orderCancel(idx))
    //     .then(data => 
    //     {
    //         console.log("data : ", data);
    //         if (!data.success)
    //             alert(data.msg);

    //         navigate("/dashboard/OrderList");
    //     })
    //     .catch(e => {
    //         console.log(e);
    //     });
    //   }
    //   else  //취소
    //   {
    //     return false;
    //   }

    // };

    const handleDeleteStudent = (event, id) => {

    }

    const handleModifyStudent = (event, id) => {

    }

    useEffect(() => {
        
        console.log("dispatch studentSelect");
        dispatch(studentSelect(mst_id))
        .then(data => 
        {
            console.log("data : ", data);
            setStudent({
              id:  data.id,
              name:  data.name, 
              nickname:  data.nickname,
              rep_character:  data.rep_character,
              lastlogin_date:  data.lastlogin_date,
            });
        })
        .catch(e => {
            console.log(e);
        });

    }, [dispatch])

  return (
    <Card sx={{ minWidth: 275, maxWidth: 500 }}>
      <CardContent>
        <Grid container spacing={2}>
            <Grid item xs={12}> <Divider variant="middle" /> </Grid>
            <Grid item xs={12}> <Typography variant="h6" align="center">학습자 정보</Typography> </Grid>
            <Grid item xs={12}> <Divider variant="middle" /> </Grid>

            <Grid item xs={4} md={3}>
            아이디 : 
            </Grid>
            <Grid item xs={6} md={8}>
            {student.id || ""}
            </Grid>
            <Grid item xs={4} md={3}>
            이름 : 
            </Grid>
            <Grid item xs={6} md={8}>
            {student.name || ""}
            </Grid>
            <Grid item xs={4} md={3}>
            닉네임 : 
            </Grid>
            <Grid item xs={6} md={8}>
            {student.nickname || ""}
            </Grid>
            <Grid item xs={4} md={3}>
            대표 캐릭터 : 
            </Grid>
            <Grid item xs={6} md={8}>
            {student.rep_character || ""}
            </Grid>
            <Grid item xs={4} md={3}>
            최근 로그인 : 
            </Grid>
            <Grid item xs={6} md={8}>
            {student.lastlogin_date || ""}
            </Grid>
        </Grid>
      </CardContent>
      <CardActions>
        <Link href={`/dashboard/studentList`} underline="none">목록</Link>
        <Link onClick={(e)=>handleDeleteStudent(e, student.id)} underline="none">삭제하기</Link>
        <Link href={`/dashboard/StudentModify/${student.id}`} underline="none">수정하기!</Link>
      </CardActions>
    </Card>
  );
}