import {
  REGISTER_COUPON_MISSION,
  USE_COUPON_MISSION,
  USENOT_COUPON_MISSION,
  COUPON_LIST_MISSION,
} from "../actions/types";

const initialState = [];

const couponMissionReducer = (coupon_mission = initialState, action) => {
  const { type, payload } = action;

  // console.log("productMissionReducer Call!");
  // console.log("type : ", type);
  // console.log("payload : ", payload);

  switch (type) {

    case REGISTER_COUPON_MISSION:
      return [];

    case USE_COUPON_MISSION:
      return {
        ...coupon_mission,
        useMission : payload,
      };

    case USENOT_COUPON_MISSION:
      return {
        ...coupon_mission,
        useNotMission : payload,
      };

    case COUPON_LIST_MISSION:
      return payload;

    default:
      return coupon_mission;
  }
};

export default couponMissionReducer;