import * as React from 'react';
import styles from "../../css/common.module.css";

import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useDispatch, useSelector } from "react-redux";
import { bbsList } from "../../actions/bbs";

import { useNavigate } from 'react-router-dom';

import * as common from "../../lib";

export default function List() {

// 페이지 파라미터로 넘어온 값들 저장
const board_name  = useParams().board_name;
console.log("board_name : ", board_name);
let board_page  = useParams().page;
console.log("board_page : ", board_page);
let board_condition  = useParams().condition;
console.log("board_condition : ", board_condition);
let board_keyword  = useParams().keyword;
console.log("board_keyword : ", board_keyword);

if (board_condition === undefined)
	board_condition = "";
else if(board_condition === "선택")
	board_condition = "";

if (board_keyword === undefined)
	board_keyword = "";

const [boardName, setBoardName] = useState(board_name);
const [searchCondition, setSearchCondition] = useState(board_condition);
const [searchKeyword, setSearchKeyword] = useState(board_keyword);

// 리듀서 값들 저장
const bbs = useSelector(state => state.bbs);
console.log("bbs : ", bbs);
const userInfo = useSelector(state => state.auth);
console.log("userInfo : ", userInfo);

const dispatch = useDispatch();
const navigate = useNavigate();

const replyReturn = (step, title) =>
{
	let n = 0;
	let returnValue = "";
	let spaceValue = " ";
	
	while (n < step) 
	{
		n++;
		spaceValue += "  ";
		returnValue += "[RE:]";
	}

	if (step > 0)
		return <>{spaceValue}{returnValue + title}</>;
	else
		return <>{returnValue + title}</>;
}

const pagingReturn = (paging) =>
{
	console.log("paging : ", paging);

	const rendering = () => {
		const result = [];
		for (let i = paging.startPage; i <= paging.endPage; i++) {

			if (i === paging.curPage)
				result.push(<a href={`/BoardList/${board_name}/${i}`} className={`${styles.list_btn} ${styles.btn1} ${styles.on}`} key={i}>{i}</a>);	
			else
				result.push(<a href={`/BoardList/${board_name}/${i}`} className={`${styles.list_btn} ${styles.btn1}`} key={i}>{i}</a>);	
		}
		return result;
	};

	return (
	<>
	<a href={`/BoardList/${board_name}/1`} className={`${styles.move} ${styles.first}`}><img src="/img/first_btn.png" alt="first"/></a>
	<a href={`/BoardList/${board_name}/${paging.curPage-1}`} className={`${styles.move} ${styles.prev}`}><img src="/img/prev_btn.png" alt="prev"/></a>
	{
        rendering()
    }
	<a href={`/BoardList/${board_name}/${paging.curPage+1}`} className={`${styles.move} ${styles.next}`}><img src="/img/next_btn.png" alt="next"/></a>
	<a href={`/BoardList/${board_name}/${paging.totalPage}`} className={`${styles.move} ${styles.last}`}><img src="/img/last_btn.png" alt="last"/></a>
	</>
	);
}

// 게시물의 번호를 계산하는 함수
const listNumberReturn = (index) => {
	let listNumber;
	return listNumber = ((bbs.paging.totalPageCount - index) - ((bbs.paging.curPage -1) * bbs.paging.page_size));	// eslint-disable-line no-unused-vars
}

// 말줄임 함수
const ellipsisReturn = (string, length) => {	// eslint-disable-line no-unused-vars

    let str = string;
    if (str.length > length) {
        str = str.substr(0, length - 2) + '...';
    }
    console.log(str);
	return str;
}

const onSearchKeywordChange = (event) => {
	setSearchKeyword(event.target.value);
}

const onSearchConditionChange = (event) => {
	setSearchCondition(event.target.value);
}

const onSearchList = (event) => {

	event.preventDefault();

	if (board_name === "news")
	{
		setBoardName("새소식");
	}
	else if (board_name === "info")
	{
		setBoardName("자료실");
	}

	if (board_page === undefined)
		board_page = 1;

	if (searchCondition === "선택" || searchCondition === "")
	{
		setSearchCondition("");
		alert("검색 조건을 선택해주세요!");
		return;
	}

	if (searchKeyword === "")
	{
		setSearchCondition("");
		alert("검색어를 입력해주세요!");
		return;
	}

	const boardListSend = {
		board_name : board_name,
		board_page : board_page, 
		condition : searchCondition,
		keyword : searchKeyword
	}

	console.log("onSearchList boardListSend : ", boardListSend);

	// 여기서 디스패치로~
	dispatch(bbsList(boardListSend))
	.then(data => 
	{
		console.log("data : ", data);
		if (searchCondition !== "" && searchKeyword !== "")
			navigate(`/BoardList/${boardListSend.board_name}/${boardListSend.board_page}/${boardListSend.condition}/${boardListSend.keyword}`);
		else
			navigate(`/BoardList/${boardListSend.board_name}/${boardListSend.board_page}`);
	})
	.catch(e => {
		console.log(e);
	});

}

useEffect(() => {

	if (board_name === "news")
	{
		setBoardName("새소식");
	}
	else if (board_name === "info")
	{
		setBoardName("자료실");
	}
	
	if (board_page === undefined)
		board_page = 1;
	
	setSearchCondition(board_condition);
	setSearchKeyword(board_keyword);

	const boardListSend = {
		board_name : board_name,
		board_page : board_page, 
		condition : searchCondition,
		keyword : searchKeyword
	}

	console.log("useEffect boardListSend : ", boardListSend);

	// 여기서 디스패치로~
	dispatch(bbsList(boardListSend))
	.then(data => 
	{
		console.log("data : ", data);
	})
	.catch(e => {
		console.log(e);
	});

	return () => {      // 언마운트
		console.log("언마운트!");
	};

}, [])

  return (
    <>
      	{/* Header 분리됨 MainLayout Header에 있음 */}
		  <div id={styles.board}>
			<div className={styles.inner}>
				<span className={styles.tit}>{boardName || ""}</span>
				<div className={styles.srch_area}>
					<div className={styles.srch_categori_area}>
						<select name="condition" id={styles.srch_categori} onChange={(e) => onSearchConditionChange(e)} value={searchCondition || ""}>
							<option>선택</option>
							<option value="title">제목</option>
							<option value="contents">내용</option>
						</select>
					</div>
					<div className={styles.input_area}>
						<input name="keyword" type="text" onChange={onSearchKeywordChange} value={searchKeyword || ""} />
						<a href="#" onClick={onSearchList}><img src="/img/srch_icon.png" alt="검색하기"/></a>
					</div>
					{ common.checkAuth(userInfo) ? <a href={"/BoardWrite/" + board_name} className={styles.write_btn}>글쓰기</a> : "" }
					
				</div>
				<div className={styles.list_box}>
					<div className={`${styles.list_head} ${styles.cf}`}>
						<div className={styles.li_h1}><span>번호</span></div>
						<div className={styles.li_h2}><span>제목</span></div>
						<div className={styles.li_h3}><span>작성자</span></div>
						<div className={styles.li_h4}><span>등록일</span></div>
						<div className={styles.li_h5}><span>조회수</span></div>
					</div>
					<ul className={styles.cf}>
					{bbs.list && bbs.list.map((row, index) => (
						<li className={`${styles.list} ${styles.list1} ${styles.cf}`} key={row.idx}>
							<div className={styles.number}>
								<span>{listNumberReturn(index)}</span>
							</div>
							<div className={styles.list_title}>
								{ searchCondition !== "" && searchKeyword !== "" 
									? 
									<a href={`/BoardView/${board_name}/${row.idx}/${bbs.paging.curPage}/${searchCondition}/${searchKeyword}`}>{replyReturn(row.step, row.title)}</a> 
									: 
									<a href={`/BoardView/${board_name}/${row.idx}/${bbs.paging.curPage}`}>{replyReturn(row.step, row.title)}</a>
								}
								<span className={styles.re}>{row.comment !== 0 && `(${row.comment})`}</span>
							</div>
							<div className={styles.writer}>
								<span>{row.name}</span>
							</div>
							<div className={styles.date}>
								<span>{common.dateChangeReturn(row.reg_date)}</span>
							</div>
							<div className={styles.view}>
								<span>{row.hit}</span>
							</div>
						</li>
					))}
					</ul>
					<div className={`${styles.btn_area} ${styles.cf}`}>
					{bbs.paging && bbs.paging.totalPageCount > 0 ? pagingReturn(bbs.paging) : ""}
					</div>
				</div>
			</div>
		</div>
		{/* Footer 분리됨 MainLayout Footer에 있음 */}
    </>
  );
}