import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';

import { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { paymentList, paymentCancel } from "../../../actions/payment";

import { useNavigate } from 'react-router-dom';

import * as common from "../../../lib";

import http from "../../../http-common";

export default function PaymentList() {

    const payment = useSelector(state => state.payment);
    console.log("payment : ", payment);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleCancelPayment = (event, payIdx, payNo, ordNo, impUid, payAmt) => {

      if (window.confirm("정말 환불 하시겠습니까??") === true)   //확인
      {
        const cancelPay = 
        { 
          pay_idx: payIdx,
          pay_no: payNo,
          merchant_uid: ordNo, // 주문번호
          imp_uid: impUid, // 아임포트 고유번호
          cancel_request_amount: parseInt(payAmt), // 환불금액
          reason: "테스트 결제 환불", // 환불사유
          refund_holder: "", // [가상계좌 환불시 필수입력] 환불 수령계좌 예금주
          refund_bank: "", // [가상계좌 환불시 필수입력] 환불 수령계좌 은행코드(예: KG이니시스의 경우 신한은행은 88번)
          refund_account: "" // [가상계좌 환불시 필수입력] 환불 수령계좌 번호
        }

        console.log("cancelPay : ", cancelPay);

        // // 여기서 디스패치로~
        dispatch(paymentCancel(cancelPay))
        .then(data => 
        {
            console.log("data : ", data);
            
            if (!data.success)
                alert(data.msg);

            // navigate("/dashboard/PaymentList");
            window.location.reload();
        })
        .catch(e => {
            console.log(e);
        });
      }
      else  //취소
      {
        return false;
      }

    };

    useEffect(() => {

        // 여기서 디스패치로~
        dispatch(paymentList())
        .then(data => 
        {
            console.log("data : ", data);
        })
        .catch(e => {
            console.log(e);
        });

    }, [dispatch])

  return (
    <Card sx={{ minWidth: 275 }}>
      <CardContent>
      결제 리스트
      <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="center">번호</TableCell>
            <TableCell align="center">결제번호</TableCell>
            <TableCell align="center">주문번호</TableCell>
            <TableCell align="center">결제수단</TableCell>
            <TableCell align="center">결제금액</TableCell>
            <TableCell align="center">결제상태</TableCell>
            <TableCell align="center">환불</TableCell>
            <TableCell align="center">주문자</TableCell>
            <TableCell align="center">결제일</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {payment && payment.map((row, index) => (
            <TableRow
              key={index}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row" align="center">
                {row.pay_idx}
              </TableCell>
              <TableCell align="center"><Link href={`/dashboard/PaymentView/${row.pay_idx}`} underline="none">{row.pay_no}</Link></TableCell>
              <TableCell align="center"><Link href={`/dashboard/OrderView/${row.ord_idx}`} underline="none">{row.ord_no}</Link></TableCell>
              <TableCell align="center">{row.pay_opt_cd}</TableCell>
              <TableCell align="center">&#8361; {common.priceToString(row.pay_amt)}</TableCell>
              <TableCell align="center">{row.ref_stat_cd === "cancelled" ? "환불" : common.returnPaymentState(row.pay_state)}</TableCell>
              <TableCell align="center">
                {
                  row.ref_stat_cd === "cancelled" ? 
                  "완료"
                  : <Link onClick={(e)=>handleCancelPayment(e, row.pay_idx, row.pay_no, row.ord_no, row.imp_uid, row.pay_amt)} value={row} underline="none">환불</Link>
                }
              </TableCell> 
              {/* <TableCell align="center">
                <Link onClick={(e)=>handleCancelPayment(e, row.pay_idx, row.pay_no, row.ord_no, row.imp_uid, row.pay_amt)} value={row} underline="none">환불</Link>
              </TableCell>  */}
              <TableCell align="center">{row.mem_name}</TableCell>
              <TableCell align="center">{row.pay_date}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      </TableContainer>

      </CardContent>
      <CardActions>
        {/* <Button onClick={handleWriteOrder} size="small">패키지 구매</Button> */}
      </CardActions>
    </Card>
  );
}