import {
  LIST_INAPP_PAYMENT,
  SELECT_INAPP_PAYMENT,
  REFUND_INAPP_PAYMENT,
} from "../actions/types";

const initialState = [];

const inappPaymentReducer = (inapp_payment = initialState, action) => {
  const { type, payload } = action;

  // console.log("paymentReducer Call!");
  // console.log("type : ", type);
  // console.log("payload : ", payload);

  switch (type) {
    
    case LIST_INAPP_PAYMENT:
      return payload;

    case SELECT_INAPP_PAYMENT:
      return payload;

    case REFUND_INAPP_PAYMENT:
      return [];

    default:
      return inapp_payment;
  }
};

export default inappPaymentReducer;