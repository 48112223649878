import * as React from 'react';
import styles from "../../css/common.module.css";

import Grid from '@mui/material/Grid';
import { useState } from "react";
import { useDispatch } from "react-redux";
import { passwordChange } from "../../actions/auth";
import { useNavigate } from 'react-router-dom';

import { useTranslation, Trans } from 'react-i18next'; // 1. react-i18next import 

export default function EmailChange() {

    const initialAuthState = {
        email: ""
    };

    const [auth, setAuth] = useState(initialAuthState);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { t, i18n } = useTranslation(); // 3. useTranslation hook 선언

    const handleInputChange = event => {
        const { name, value } = event.target;
        setAuth({ ...auth, [name]: value });
    };

    return (
        <Grid container component={styles.main} sx={{ height: '100vh' }}>
            <div className={`${styles.login_bg_wrapper} ${styles.email_bg_wrapper}`}>
                <div className={`${styles.email_reset_area} ${styles.middle}`}>
                    <div className={styles.login_top}>
                        <a href="/" className={styles.logo}><img src="/assets/logo@2x.png" alt="logo"/></a>
                    </div>
                    <span className={styles.login_sub_content}>{/* 환영합니다.<br></br>야미코딩 단체회원 가입이 완료되었습니다. */}
                    <Trans i18nKey="Auth.register_complete_string6"/></span>
                    <br></br>
                    <span className={styles.login_sub_content}>
                        {/* 1. 이메일 인증을 진행해주세요. */}
                        <Trans i18nKey="Auth.register_complete_string7"/><br></br>
                        {/* 2. 관리자의 승인 절차가 필요합니다. */}
                        <Trans i18nKey="Auth.register_complete_string8"/><br></br>
                        {/* 아래의 번호로 연락 부탁드립니다. */}
                        <Trans i18nKey="Auth.register_complete_string9"/><br></br>
                        (82+)02-875-3330</span>
                    <br></br>
                    <div className={`${styles.email_reset_wrap} ${styles.center_align}`}>
                        <p>{/* 가입하실 때 작성하신 이메일로 인증메일이 발송되었습니다. <br/>메일을 확인해주세요. */}
                        <Trans i18nKey="Auth.register_complete_string3"/></p><br></br>
                        <a href="/auth/login" className={styles.ok}>{/* 로그인 */}<Trans i18nKey="Auth.login"/></a>
                    </div>
                    <div className={`${styles.email_reset_wrap} ${styles.center_align}`}>
                        <br></br>
                        <p>{/* 이메일을 받지 못하시거나  <br/>이메일을 변경해야 한다면 가입할 때 패스워드가 필요합니다. */}
                        <Trans i18nKey="Auth.register_complete_string4"/></p>
                        <a href="/auth/EmailChange" className={styles.ok}>{/* 이메일 문제 해결하기 */}
                        <Trans i18nKey="Auth.register_complete_string5"/></a>
                    </div>
                </div>
            </div>
        </Grid>
    );
}