import * as React from 'react';
import Chart from "react-apexcharts";

import { useTranslation, Trans } from 'react-i18next'; // 1. react-i18next import 

export default function BasicTable(props) {

      const { t, i18n } = useTranslation(); // 3. useTranslation hook 선언

      let labelsDataArray = [];
      let ageDataArray = [];

      if (props.RowData?.length !== 0)
      {
        // 데이터 생성
        for (let i=0; i<props.RowData?.length; i++)
        {
          // labelsDataArray.push(props.RowData[i].mst_gender === 1 ? "남자" : "여자");
          labelsDataArray.push(props.RowData[i].mst_gender === 1 ? `${t("ClassChart.string3")}` : `${t("ClassChart.string4")}`);
          ageDataArray.push(props.RowData[i].cnt);
        }
      }

      let state = {
          
        series: ageDataArray,
        options: {
          chart: {
            height: 380,
            type: 'pie',
          },
          labels: labelsDataArray,
          responsive: [{
            breakpoint: 480,
            options: {
              chart: {
                width: 200
              },
              legend: {
                position: 'bottom'
              }
            }
          }]
        },
      
      
      };

  return (
    <>
        <Chart
             options={state.options} series={state.series} type="pie"
             height={300}
         />
    </>
  );
}

// import React, { Component } from "react";
// import Chart from "react-apexcharts";

// class App extends Component {
//   constructor(props) {
//     super(props);

//     this.state = {
          
//       series: [44, 55, 13, 43, 22],
//       options: {
//         chart: {
//           height: 380,
//           type: 'pie',
//         },
//         labels: ['Team A', 'Team B', 'Team C', 'Team D', 'Team E'],
//         responsive: [{
//           breakpoint: 480,
//           options: {
//             chart: {
//               width: 200
//             },
//             legend: {
//               position: 'bottom'
//             }
//           }
//         }]
//       },
    
    
//     };
//   }

//   render() {
//     return (
      
//         <Chart
//             options={this.state.options} series={this.state.series} type="pie"
//             height={300}
//         />
//     );
//   }
// }

// export default App;