import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
// import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

import Divider from '@mui/material/Divider';

import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormLabel from '@mui/material/FormLabel';
import FormControlLabel from '@mui/material/FormControlLabel';

import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";

import { studentModify, newClassStudentList } from "../../actions/student";

import { useTranslation, Trans } from 'react-i18next'; // 1. react-i18next import 

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function StudentEditDialog(props) {
  
	const [open, setOpen] = useState(false);

	const [name, setName] = useState("");
	const [id, setId] = useState("");
	const [nickname, setNickname] = useState("");
	const [birthdayYear, setBirthdayYear] = useState("");
	const [birthdayMonth, setBirthdayMonth] = useState("01");
	const [birthdayDay, setBirthdayDay] = useState("01");
	const [gender, setGender] = useState(1);
	const [password, setPassword] = useState("");
	const [repassword, setRepassword] = useState("");
	const [email, setEmail] = useState("");

	const [fontSizeValue, setFontSizeValue] = useState(null);

	const [helperText, setHelperText] = useState("");

	const dispatch = useDispatch();	

	const { t, i18n } = useTranslation(); // 3. useTranslation hook 선언

	const authState = useSelector(state => state.auth);
    // console.log("authState : ", authState);

  const handleClickOpen = () => {

	// 생년월일 처리
	let birthdayArray;
	let year = "";
	let month = "";
	let day = "";

	if (props.RowData.mst_birthday !== null)
	{
		birthdayArray = props.RowData.mst_birthday.split("-");
		year = birthdayArray[0] || "";
		// month = birthdayArray[1] || "";
		// day = birthdayArray[2] || "";
		month = "01";
		day = "01";
	}

	// 성별 처리
	let gender = 1;

	if (props.RowData.mst_gender !== null)
		gender = props.RowData.mst_gender;

	setId(props.RowData.mst_id);
	setName(props.RowData.mst_name);
	setNickname(props.RowData.mst_nickname);
	setBirthdayYear(year);
	setBirthdayMonth(month);
	setBirthdayDay(day);
	setGender(gender);
	setEmail(props.RowData.msa_email);
    
	setOpen(true);

  };
  const handleClose = () => {
    
	setOpen(false);
	
	// 생년월일이 초기화가 안되서 초기화함.
	setBirthdayYear("");
	setBirthdayMonth("");
	setBirthdayDay("");
	
  };

  const handleSubmit = (event) => {

	event.preventDefault();
	
	// console.log("name : ", name);
	// console.log("id : ", id);
	// console.log("nickname : ", nickname);
	// console.log("birthdayYear : ", birthdayYear);
	// console.log("birthdayMonth : ", birthdayMonth);
	// console.log("birthdayDay : ", birthdayDay);
	// console.log("gender : ", gender);
	// console.log("password : ", password);
	// console.log("repassword : ", repassword);
	// console.log("idCheck : ", idCheck);

	// 임시 사용자의 정보는 반드시 변경되어야 사용이 가능합니다.
	// 그 조건을 만족해야지 업데이트를 하게 됩니다.

	if (name === "")
	{
		// return alert("이름을 입력해주세요!");
		return alert(`${t("ClassStudentCommonDlg.alert.string6")}`);
	}
	else if (nickname === "" || nickname === null)
	{
		// return alert("닉네임을 입력해주세요!");
		return alert(`${t("ClassStudentCommonDlg.alert.string7")}`);
	}
	else if (birthdayYear === "")
	{
		// return alert("출생년도를 선택해주세요!");
		return alert(`${t("ClassStudentCommonDlg.alert.string8")}`);
	}
	else if (gender === "")
	{
		// return alert("성별을 선택해주세요!");
		return alert(`${t("ClassStudentCommonDlg.alert.string9")}`);
	}		
	// else if (email === "")
	// {
	// 	return alert("부모님의 이메일 주소를 넣어주세요");
	// }

	if (password !== "" || repassword !== "")
	{
		// 정규 표현식으로 비밀번호 생성 규칙에 맞는지 체크합니다.
		let reg = new RegExp(/(?=.*\d{1,20})(?=.*[~`!@#$%\^&*()-+=]{1,20})(?=.*[a-zA-Z]{1,20}).{8,20}$/);
					
		// console.log(reg.test(join.password));
		if( !reg.test(password) ) 
		{
			// return  alert("비밀번호 생성 규칙은 영문, 숫자, 특수문자 포함 8자 이상 20자가지 입니다.");
			return alert(`${t("ClassStudentCommonDlg.alert.string10")}`);
		}

		if (password !== repassword)
		{
			// alert("비밀번호가 일치하지 않습니다.");
			alert(`${t("ClassStudentCommonDlg.alert.string11")}`);
			return;
		}
	}
	else
	{
		// 비밀번호가 둘 다 있어야지 변경합니다.
		// 아니면 초기화 합니다.
		setPassword("");
		setRepassword("");
	}

	const sendData = {
		id: id,
		name:name,
		nickname:nickname,
		birthday:birthdayYear + "-" + birthdayMonth + "-" + birthdayDay,
		gender:gender,
		password:password,
		email: email,
	};

	// console.log("sendData : ", sendData);

	// 여기서 디스패치로~
	dispatch(studentModify(sendData))
	.then(data => 
	{
		// console.log("data : ", data);
		if (!data.success)
		{
			alert(data.msg);
			return;
		}
		else
		{
			const boardListSend = {
				board_page : props.currentPage,
				mc_idx: props.mcIdx,
			  }
		  
			  // 여기서 디스패치로~
			  dispatch(newClassStudentList(boardListSend))
			  .then(data => 
			  {
				  // console.log("classListAll : ", data);
			  })
			  .catch(e => {
				  console.log(e);
			  });

			//   alert("학습자 수정 완료.");
				alert(`${t("ClassStudentCommonDlg.alert.string18")}`);
			  handleClose();
		}
	})
	.catch(e => {
		console.log(e);
	});
	
};

const handleInputChange = event => {

	const { name, value } = event.target;

	// console.log("name : " + name + " vlaue : " + value);

	if (name === "name")
	{
		setName(value);
	}
	else if (name === "nickname")
	{
		setNickname(value);
	}
	
	else if (name === "gender")
	{
		setGender(value);
	}
	else if (name === "password")
	{
		setPassword(value);
	}
	else if (name === "repassword")
	{
		setRepassword(value);
	}
	else if (name === "email")
	{
		setEmail(value);
	}
};

const hasError = (passwordEntered) =>
{
	// 정규 표현식으로 비밀번호 생성 규칙에 맞는지 체크합니다.
	let reg = new RegExp(/(?=.*\d{1,20})(?=.*[~`!@#$%\^&*()-+=]{1,20})(?=.*[a-zA-Z]{1,20}).{8,20}$/);
	// console.log(reg.test(join.password));
	return !reg.test(password);
}
    
const hasNotSameError = passwordEntered =>
	password != repassword ? true : false;

const getYears = () => 
{
	let date=new Date();
	let selYear=date.getFullYear();

	//올해 기준으로 -30년을 보여줌
	let stY=Number(selYear)-80;
	let nY=Number(selYear);

	const result = [];
	let index = 0;

	// for(let y=stY; y<=nY; y++)
	for(let y=nY; y>=stY; y--)
	{
		// result.push(<MenuItem value={y} key={index++}>{y}년</MenuItem>);
		result.push(<MenuItem value={y} key={index++}>{y}</MenuItem>);
	}

	return result;
};

const onChangeYear = (event) => {
	event.preventDefault();

	// console.log(event.target.value);
	setBirthdayYear(event.target.value);
}

// const onChangeMonth = (event) => {
// 	event.preventDefault();

// 	// console.log(event.target.value.padStart(2, '0'));
// 	setBirthdayMonth(event.target.value.padStart(2, '0'));
// }

// const onChangeDay = (event) => {
// 	event.preventDefault();

// 	// console.log(event.target.value.padStart(2, '0'));
// 	setBirthdayDay(event.target.value.padStart(2, '0'));
// }



useEffect(() => {

      let isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent) ? true : false;
			
	  if(!isMobile) 
	  {
		  //PC
          setFontSizeValue({style: {fontSize: 15}});
      }
      else 
      {
          // mobile
          setFontSizeValue({style: {fontSize: 12}});
      }

      // console.log(typeof(fontSizeValue));

	//   console.log(props.RowData);

  }, []);

  return (
    <div>
      <Button variant="outlined" size='small' onClick={handleClickOpen}>{/* 수정 */}{t('ClassStudentCommonDlg.string25')}</Button>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
          {/* 학습자 정보 수정 */}{t('ClassStudentCommonDlg.title2')}
        </BootstrapDialogTitle>
		<Box
		component="form"
		sx={{
			display: 'flex',
    		alignItems: 'center',
			'& .MuiTextField-root': { m: 1, width: '260px', marginTop: '20px', marginLeft: '20px'},
			// '& .MuiButton-root': { m: 1},
		}}
		noValidate
		autoComplete="off"
		>
			<TextField
                required
                fullWidth
                id="id"
                // label="아이디"
				label={t('ClassStudentCommonDlg.string1')}
                name="id"
                autoComplete="username"
                onChange={handleInputChange}
                InputLabelProps={fontSizeValue} // font size of input label
				inputProps={{ maxLength: 50 }}
				helperText={helperText}
				disabled
				value={id || ""}
            />
		</Box>
		<Box
		component="form"
		sx={{
			'& .MuiTextField-root': { m: 1, width: '250px', marginTop: '20px', marginLeft: '20px' },
		}}
		noValidate
		autoComplete="off"
		>
		<div>
			<TextField
                required
                fullWidth
                id="name"
                // label="이름"
				label={t('ClassStudentCommonDlg.string3')}
                name="name"
                onChange={handleInputChange}
                InputLabelProps={fontSizeValue} // font size of input label
				value={name || ""}
            />
		</div>
		</Box>
		<Box
		component="form"
		sx={{
			'& .MuiTextField-root': { m: 1, width: '250px', marginTop: '20px', marginLeft: '20px', marginBottom: '20px' },
		}}
		noValidate
		autoComplete="off"
		>
		<div>
			<TextField
                  required
                  fullWidth
                  id="nickname"
                //   label="닉네임"
				  label={t('ClassStudentCommonDlg.string4')}
                  name="nickname"
                  onChange={handleInputChange}
                  inputProps={{ maxLength: 7 }}
                  InputLabelProps={fontSizeValue} // font size of input label
				  value={nickname || ""}
              />
		</div>
		</Box>
		<Box
		component="form"
		sx={{
			'& .MuiTextField-root': { m: 1, width: '250px', marginTop: '20px', marginLeft: '20px', marginBottom: '20px' },
		}}
		noValidate
		autoComplete="off"
		>
		<div>
			<TextField
                  fullWidth
                  id="email"
                //   label="부모님 이메일 주소"
				  label={t('ClassStudentCommonDlg.string5')}
                  name="email"
                  onChange={handleInputChange}
                  inputProps={{ maxLength: 50 }}
                  InputLabelProps={fontSizeValue} // font size of input label
				  value={email || ""}
              />
		</div>
		</Box>
		<Box
		component="form"
		sx={{
			'& .MuiTypography-root': { m: 1, width: '450px',  marginLeft: '20px', fontSize: '14px' },
			'& .MuiFormLabel-root': { m: 1, width: '450px', marginLeft: '20px' },
		}}
		noValidate
		autoComplete="off"
		>
		<div>
			<FormLabel id="birthday">{/* 생년월일 */}{t('ClassStudentCommonDlg.string6')}</FormLabel>
			<Typography variant="subtitle1" gutterBottom>{/* 생년월일, 성별 데이터를 정확하게 입력해주시면 학습자의 데이터 분석에 많은 도움이 됩니다. */}
			{t('ClassStudentCommonDlg.string7')}</Typography>
			<FormControl sx={{ m: 1, minWidth: 120, marginLeft: '20px' }}>
              <Select
                value={birthdayYear}
                onChange={(e)=>onChangeYear(e)}
                displayEmpty
                name='year'
                inputProps={{ 'aria-label': 'Without label' }}
              >
                <MenuItem value="" disabled>
                  <em>{/* 년 */}{t('ClassStudentCommonDlg.string15')}</em>
                </MenuItem>
                {getYears()}
              </Select>
            </FormControl>
			{/* <FormControl sx={{ m: 1, minWidth: 80 }}>
              <Select
                value={birthdayMonth}
                onChange={(e)=>onChangeMonth(e)}
                displayEmpty
                name='month'
                inputProps={{ 'aria-label': 'Without label' }}
              >
                <MenuItem value="" disabled>
                  <em>월</em>
                </MenuItem>
                <MenuItem value={`01`}>1월</MenuItem>
                <MenuItem value={`02`}>2월</MenuItem>
                <MenuItem value={`03`}>3월</MenuItem>
                <MenuItem value={`04`}>4월</MenuItem>
                <MenuItem value={`05`}>5월</MenuItem>
                <MenuItem value={`06`}>6월</MenuItem>
                <MenuItem value={`07`}>7월</MenuItem>
                <MenuItem value={`08`}>8월</MenuItem>
                <MenuItem value={`09`}>9월</MenuItem>
                <MenuItem value={`10`}>10월</MenuItem>
                <MenuItem value={`11`}>11월</MenuItem>
                <MenuItem value={`12`}>12월</MenuItem>
              </Select>
            </FormControl>
            <FormControl sx={{ m: 1, minWidth: 80 }}>
              <Select
                value={birthdayDay}
                onChange={(e)=>onChangeDay(e)}
                displayEmpty
                name='day'
                inputProps={{ 'aria-label': 'Without label' }}
              >
                <MenuItem value="" disabled>
                  <em>일</em>
                </MenuItem>
                <MenuItem value={`01`}>1</MenuItem>
                <MenuItem value={`02`}>2</MenuItem>
                <MenuItem value={`03`}>3</MenuItem>
                <MenuItem value={`04`}>4</MenuItem>
                <MenuItem value={`05`}>5</MenuItem>
                <MenuItem value={`06`}>6</MenuItem>
                <MenuItem value={`07`}>7</MenuItem>
                <MenuItem value={`08`}>8</MenuItem>
                <MenuItem value={`09`}>9</MenuItem>
                <MenuItem value={`10`}>10</MenuItem>
                <MenuItem value={`11`}>11</MenuItem>
                <MenuItem value={`12`}>12</MenuItem>
                <MenuItem value={`13`}>13</MenuItem>
                <MenuItem value={`14`}>14</MenuItem>
                <MenuItem value={`15`}>15</MenuItem>
                <MenuItem value={`16`}>16</MenuItem>
                <MenuItem value={`17`}>17</MenuItem>
                <MenuItem value={`18`}>18</MenuItem>
                <MenuItem value={`19`}>19</MenuItem>
                <MenuItem value={`20`}>20</MenuItem>
                <MenuItem value={`21`}>21</MenuItem>
                <MenuItem value={`22`}>22</MenuItem>
                <MenuItem value={`23`}>23</MenuItem>
                <MenuItem value={`24`}>24</MenuItem>
                <MenuItem value={`25`}>25</MenuItem>
                <MenuItem value={`26`}>26</MenuItem>
                <MenuItem value={`27`}>27</MenuItem>
                <MenuItem value={`28`}>28</MenuItem>
                <MenuItem value={`29`}>29</MenuItem>
                <MenuItem value={`30`}>30</MenuItem>
                <MenuItem value={`31`}>31</MenuItem>
              </Select>
            </FormControl> */}
		</div>
		</Box>

		<Box
		component="form"
		sx={{
			'div': { m: 1, width: '250px', marginTop: '10px', marginLeft: '20px' },
		}}
		noValidate
		autoComplete="off"
		>
		<div>
			<FormLabel id="gender">{/* 성별 */}{t('ClassStudentCommonDlg.string8')}</FormLabel>
            <RadioGroup
              row
              aria-labelledby="gender"
              name="gender"
              value={gender}
              onChange={handleInputChange}
            >
              <FormControlLabel value="1" control={<Radio />} /* label="남자" */ label={t('ClassStudentCommonDlg.string9')} />
              <FormControlLabel value="2" control={<Radio />} /* label="여자" */ label={t('ClassStudentCommonDlg.string10')} />
            </RadioGroup>
		</div>
		</Box>

		<Box
		component="form"
		sx={{
			'& .MuiTextField-root': { m: 1, width: '450px', marginTop: '20px', marginLeft: '20px', marginRight: '20px' },
		}}
		noValidate
		autoComplete="off"
		>
		<div>
			<TextField
                required
                fullWidth
                name="password"
                // label="비밀번호 영문,숫자,특수문자 조합 8~20자리 권장"
				label={t('ClassStudentCommonDlg.string11')}
                type="password"
                id="password"
                error={hasError('password')} // 해당 텍스트필드에 error 핸들러 추가
                onChange={handleInputChange}
                autoComplete="new-password"
                InputLabelProps={fontSizeValue} // font size of input label
                inputProps={{ maxLength: 100 }}
              />
			  <p style={{fontSize: '13px', paddingLeft: '20px'}}>{t('ClassStudentCommonDlg.string11')}</p>
		</div>
		</Box>

		<Box
		component="form"
		sx={{
			'& .MuiTextField-root': { m: 1, width: '450px', marginTop: '20px', marginLeft: '20px', marginRight: '20px' },
			'div': { marginBottom: '10px' },
		}}
		noValidate
		autoComplete="off"
		>
		<div>
			<TextField
                required
                fullWidth
                name="repassword"
                // label="비밀번호 확인"
				label={t('ClassStudentCommonDlg.string12')}
                type="password"
                id="repassword"
                error={hasNotSameError('repassword')} // 해당 텍스트필드에 error 핸들러 추가
                helperText={
                    // hasNotSameError('repassword') ? "입력한 비밀번호와 일치하지 않습니다." : null
					hasNotSameError('repassword') ? t('ClassStudentCommonDlg.string13') : null
                } // 에러일 경우에만 안내 문구 표시
                onChange={handleInputChange}
                autoComplete="new-password"
                InputLabelProps={fontSizeValue} // font size of input label
                inputProps={{ maxLength: 100 }}
              />
		</div>
		</Box>

		<Divider />
		
        <DialogActions>
          <Button onClick={handleSubmit}>
            {/* 수정 */}{t('ClassStudentCommonDlg.string25')}
          </Button>
		  <Button onClick={handleClose}>
            {/* 취소 */}{t('Common.cancel')}
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}