import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import { Link, Grid } from '@mui/material';

import { useDispatch, useSelector } from "react-redux";
import { productSelect, productDelete } from "../../../actions/product";
import { categoryList } from "../../../actions/category";

import { useEffect, useState } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import * as common from "../../../lib";

export default function ProductModify() {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    
    const prd_idx  = useParams().prd_idx;
    console.log("prd_idx : ", prd_idx);

    const category = useSelector(state => state.category);
    console.log("category : ", category);

    const initialProductState = {
        prd_idx: null,
        category_idx: null, 
        product_name: null,
        product_desc: null,
        default_amount: 1,
        expiration_date_unit: null,
        expiration_date: 1,
        sell_prc_unit: 1,
        sell_start_dt: null,
        sell_end_dt: null,
        file: null,
        fileName: null,
        filePath: null,
        mis_count: null,
        total_mission: null,
        option1: [],
        option2: [],
    };

    const [product, setProduct] = useState(initialProductState);

    const handleDeleteProduct = (event, idx) => {
        
      console.log(idx);
  
      if (window.confirm("정말 삭제하시겠습니까??") === true)   //확인
      {
        // 여기서 디스패치로~
        dispatch(productDelete(idx))
        .then(data => 
        {
            console.log("data : ", data);
            if (!data.success)
                alert(data.msg);

            navigate("/dashboard/ProductList");
        })
        .catch(e => {
            console.log(e);
        });
      }
      else  //취소
      {
        return false;
      }
  
    };

    useEffect(() => {
        
        console.log("dispatch productSelect");
        dispatch(productSelect(prd_idx))
        .then(data => 
        {
            console.log("data : ", data[0]);
            setProduct({
                prd_idx: data[0].prd_idx,
                category_idx: data[0].category_idx, 
                product_name: data[0].prd_name,
                product_desc: data[0].prd_desc,
                default_amount: data[0].default_amount,
                expiration_date_unit: data[0].expiration_date_unit,
                expiration_date: data[0].expiration_date,
                sell_prc_unit: data[0].sell_prc_unit,
                sell_start_dt: data[0].sell_start_dt,
                sell_end_dt: data[0].sell_end_dt,
                fileName: data[0].prd_photo_file,
                filePath: data[0].prd_photo_path,
                mis_count: data[0].mis_count,
                total_mission: data[0].total_mission,
                option1: data[0].option1 === undefined ? [] : data[0].option1,
                option2: data[0].option2 === undefined ? [] : data[0].option2,
            });
        })
        .catch(e => {
            console.log(e);
        });

        console.log("dispatch categoryList");
        // 여기서 디스패치로~
        dispatch(categoryList())
        .then(data => 
        {
            console.log("data : ", data);
        })
        .catch(e => {
            console.log(e);
        });

        return () => {      // 언마운트
            console.log("언마운트!");
        };

    }, [dispatch])

  return (
    <Card sx={{ minWidth: 275, maxWidth: 700 }}>
      <CardContent>
        <Grid container spacing={2}>
            <Grid item xs={4} md={3}>
            상품명 : 
            </Grid>
            <Grid item xs={6} md={8}>
            {product.product_name || ""}
            </Grid>
            <Grid item xs={4} md={3}>
            상품설명 : 
            </Grid>
            <Grid item xs={6} md={8}>
            {product.product_desc || ""}
            </Grid>
            <Grid item xs={4} md={3}>
            기본수량 : 
            </Grid>
            <Grid item xs={6} md={8}>
            {product.default_amount || ""}개
            </Grid>
            <Grid item xs={4} md={3}>
            유효기간 : 
            </Grid>
            <Grid item xs={6} md={8}>
            {product.expiration_date || ""}{product.expiration_date_unit === "month" ? '개월' : '일' || ""}
            </Grid>
            <Grid item xs={4} md={3}>
            카테고리
            </Grid>
            <Grid item xs={6} md={8}>
            {product.category_idx ? common.returnCategoryName(category, product.category_idx) : "" }
            </Grid>
            <Grid item xs={4} md={3}>
            가격
            </Grid>
            <Grid item xs={6} md={8}>
            {common.priceToString(product.sell_prc_unit) || ""}원
            </Grid>
            <Grid item xs={4} md={3}>
            옵션1
            </Grid>
            <Grid item xs={6} md={8}>
            {
              product.option1.map(((item, index) => (
                <div key={index}
                    style={{
                        marginBottom:'15px'
                    }}
                >
              {index+1}. 날짜 : {item.expiration_date} {item.expiration_date_unit}, 수량 : {item.po1_count_start === 0 ?  '' : item.po1_count_start + ' ~ '} {item.po1_count_end === 0 ?  '' : item.po1_count_end} {item.po1_count_start === 0 ?  '(이상)' : ''} ({item.po1_amt} 원)
                
                </div>
              )))
            }
            </Grid>
            <Grid item xs={4} md={3}>
            옵션2
            </Grid>
            <Grid item xs={6} md={8}>
            {
              product.option2.map(((item, index) => (
                <div key={index}
                    style={{
                        marginBottom:'15px'
                    }}
                >
              {index+1}. {item.po2_name} ({item.po2_price} 원)
                
                </div>
              )))
            }
            </Grid>
            <Grid item xs={4} md={3}>
            판매 시작일
            </Grid>
            <Grid item xs={6} md={8}>
            {product.sell_start_dt || "없음"}
            </Grid>
            <Grid item xs={4} md={3}>
            판매 종료일
            </Grid>
            <Grid item xs={6} md={8}>
            {product.sell_end_dt || "없음"}
            </Grid>
            <Grid item xs={4} md={3}>
            설정된 미션
            </Grid>
            <Grid item xs={6} md={8}>
            {product.mis_count || 0} / {product.total_mission || 0}
            </Grid>
            <Grid item xs={4} md={3}>
            제품 이미지
            </Grid>
            <Grid item xs={6} md={8}>
            {product.fileName ? <img src={common.returnImgSrc(product.filePath, product.fileName)} width="150" /> : "" }
            </Grid>
        </Grid>
      </CardContent>
      <CardActions>
        <Link href={`/dashboard/ProductList`} underline="none">목록</Link>
        <Link href={`/dashboard/ProductModify/${prd_idx}`} underline="none">수정</Link>
        <Link onClick={(e)=>handleDeleteProduct(e, prd_idx)} value={prd_idx} underline="none">삭제</Link>
      </CardActions>
    </Card>
  );
}