import {
  LIST_SOLUTION,
  CREATE_SOLUTION,
  MODIFY_SOLUTION,
  REPLY_SOLUTION, 
  DELETE_SOLUTION,
  SELECT_SOLUTION,
  LIST_SOLUTION_NONMEMBER,
  CREATE_SOLUTION_NONMEMBER,
  MODIFY_SOLUTION_NONMEMBER,
  DELETE_SOLUTION_NONMEMBER,
  SELECT_SOLUTION_NONMEMBER,
  CATEGORY_SOLUTION,
  CHECK_SOLUTION_NONMEMBER,
} from "../actions/types";

const initialState = [];

const solutionReducer = (solution = initialState, action) => {
  const { type, payload } = action;

  // console.log("solutionReducer Call!");
  // console.log("type : ", type);
  // console.log("payload : ", payload);

  switch (type) {

    case LIST_SOLUTION:
      return {
        ...solution,
        list : payload.list,
        paging : payload.paging,
      };

    case CREATE_SOLUTION:
      return [];

    case MODIFY_SOLUTION:
      return [];

    case REPLY_SOLUTION:
      return [];

    case DELETE_SOLUTION:
      return [];

    case SELECT_SOLUTION:
      return{
        ...solution,
        select: payload,
      };

    case LIST_SOLUTION_NONMEMBER:
      return {
        ...solution,
        list : payload.list,
        paging : payload.paging,
      };

    case CREATE_SOLUTION_NONMEMBER:
      return [];

    case MODIFY_SOLUTION_NONMEMBER:
      return [];

    case DELETE_SOLUTION_NONMEMBER:
      return [];

    case SELECT_SOLUTION_NONMEMBER:
      return{
        ...solution,
        select: payload,
      };

    case CATEGORY_SOLUTION:
      return{
        ...solution,
        category: payload,
      };

    case CHECK_SOLUTION_NONMEMBER:
      return [];

    default:
      return solution;
  }
};

export default solutionReducer;