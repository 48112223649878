import * as React from 'react';
import { useEffect } from 'react';
import styles from "../css/common.module.css";
import $ from 'jquery';

export default function Use() {

	useEffect(() => {

		// 여기에서 jQuery를 초기화 합니다.
		$(document).ready(function(){

		});

	}, [$]);

  	return (
    <>
		<div className={styles.wrap}>
			<div className={styles.privacy_info}>
				<p>
				‘<span className={styles.color}>주식회사 위클러버</span>'는 (이하 '회사'라 합니다.) 고객님의 개인정보를 중요시하며, ‘정보통신망 이용촉진 및 정보보호 등에 관한 법률’ 및 ‘개인정보보호법’ 등 회사가 준수하여야 할 관련 법령상의 개인정보보호 규정을 준수하고 있습니다.
				<br/>회사는 개인정보처리방침을 통하여 고객님께서 제공하시는 개인정보가 어떠한 용도와 방식으로 이용되고 있으며, 개인정보보호를 위해 어떠한 조치가 취해지고 있는지 알려드립니다.
				<br/><br/>회사는 개인정보처리방침을 개정하는 경우 웹사이트 공지사항(또는 개별공지)을 통하여 공지할 것입니다
				</p>
			</div>
			<div className={`${styles.privacy_area} ${styles.area1}`}>
				<span className={styles.tit}>1. 수집하는 개인정보의 항목 및 수집방법</span>
				<p>
				(1) 수집하는 개인정보의 항목
				<br/><br/>* 회원 가입 시 아래와 같은 정보가 수집됩니다
				<br/>- 웹사이트 이용 시: 이메일 주소(계정명), 닉네임, 비밀번호, 핸드폰 번호, 이름
				<br/><br/>* 서비스 이용과정에서 아래와 같은 정보들이 수집될 수 있습니다
				<br/>- 서비스 이용기록, 접속 로그, IP 정보, 불량 이용기록, 모바일 기기정보 (모델명, 이동통신사 정보, OS 정보, 언어 및 국가정보, 기기 고유 식별번호, 광고 ID 등)
				<br/><br/>* 연령 확인 및 본인인증이 필요한 서비스 제공 시 아래와 같은 정보가 수집될 수 있습니다.
				<br/>- 휴대폰 본인 인증자: 이름, 생년월일, 성별, 중복가입확인정보(DI), 연계정보(CI), 내·외국인정보
				<br/>- 만 14 세 미만 아동과 만 18 세 미만 청소년 이용자 : 법정대리인 본인인증 정보(법정대리인 이메일, 이름, 생년월일, 성별, 중복가입확인정보(DI), 연계정보(CI)), 본인인증 정보(이름, 생년월일, 성별, 중복가입확인정보)
				<br/><br/>* 회사는 아래의 고객관련 서비스 이용 시 부가적인 정보를 수집할 수 있으며, 이 경우 별도의 개인정보 수집 및 이용 동의를 받습니다.<br/>
				</p>
				<div className={styles.box_wrap}>
					<div className={styles.title}>
						<span>서비스명</span>
						<span>수집하는 개인정보 항목</span>
					</div>
					<div className={styles.list}>
						<span className={styles.name}>이벤트 참가</span>
						<span>
						-배송 상품: 주소, 전화번호<br/>
						-모바일 상품: 휴대폰번호
						</span>
					</div>
					<div className={styles.list}>
						<span className={styles.name}>고객관리 서비스<br/>(카카오톡 서비스센터)</span>
						<span>
						-필수항목: 휴대전화번호 , 닉네임(이름)<br/>
						-선택항목: 단말기 주소록 내에 저장된 연락처 정보(이름 , 전화번호) , 프로필 사진
						</span>
					</div>
					<div className={styles.list}>
						<span className={styles.name}>유료 결제시</span>
						<span>
						- 휴대전화 결제 시: 이동통신사<br/>
						- 실시간 계좌이체 결제 시: 은행명<br/>
						- 환불시: 은행명 , 입금자명 , 계좌번호 , 연락처<br/>
						- 쿠폰 등록 시: 연락처 , 쿠폰정보<br/>
						- 인터넷뱅킹 결제 시: 생년월일<br/>
						- 신용카드 시: 카드사명
						</span>
					</div>
				</div>
				<p>
				(2) 개인정보 수집방법<br/><br/>
				홈페이지 회원가입, 서비스 이용(모바일 서비스 포함), 회원정보 수정, 전화, 팩스, 고객센터 상담, 이벤트 응모, 협력회사로부터의 제공, 생성정보 수집 툴을 통한 수집 툴을 통해 수집합니다.
				</p>
			</div>
			<div className={`${styles.privacy_area} ${styles.area2}`}>
				<span className={styles.tit}>2. 고유식별정보의 처리</span>
				<p>
				(1) 고유식별정보란 개인정보보호법 및 시행령에서“ 대통령령으로 정하는 정보"로 주민등록번호, 여권번호, 운전면허번호, 외국인등록번호를 말합니다.<br/><br/>
				(2) 회사는 아래 각호의 용도로 고유식별정보를 수집하여 처리하고 있으며, 수집 시 별도의 동의를 거쳐 수집하게 됩니다.<br/><br/>
				* 현물 경품 당첨자의 제세공과금 부과
				<br/>* 기타 법률에서 요구할 경우
				<br/><br/>(3) 수집된 고유식별정보는 법률에서 다르게 규정하고 있는 경우를 제외하고 (2)항에 의해 정해진 목적 외 용도로 사용∙제공되지 않으며 저장 시 암호화 되어 안전하게 관리됩니다.
				</p>
			</div>
			<div className={`${styles.privacy_area} ${styles.area3}`}>
				<span className={styles.tit}>3. 개인정보의 수집 및 이용목적</span>
				<p>
				회사는 수집한 개인정보를 다음의 목적을 위해 활용합니다.<br/><br/>

				(1) 서비스 제공에 관한 계약 이행 및 서비스 제공에 따른 요금정산, 콘텐츠 제공, 구매 및 요금 결제, 환불, 물품배송, 본인인증, 요금추심<br/><br/>
				(2) 회원 관리<br/><br/>
				회원제 서비스 이용에 따른 본인확인, 개인 식별, 불량회원의 부정 이용 방지와 비인가 사용방지, 가입 의사 확인, 연령확인, 만 14 세 미만 아동의 개인정보 수집 시 법정 대리인 동의여부 확인, 만 18 세 미만 이용자의 회원 가입 시 동의 여부 확인, 법정대리인 본인 확인, 불만처리 및 고객상담 등 민원처리, 분쟁 조정을 위한 기록보존, 고지사항 전달<br/><br/>

				(3) 마케팅 및 통계 분석에 활용<br/><br/>
				신규 서비스(제품) 개발 및 특화, 이벤트 등 홍보성 정보 전달, 인구통계학적 특성에 따른 서비스 제공 및 광고 게재, 접속 빈도 파악 또는 회원의 서비스 이용에 대한 통계 분석						</p>
			</div>
			<div className={`${styles.privacy_area} ${styles.area4}`}>
				<span className={styles.tit}>4. 개인정보의 보유 및 이용기간</span>
				<p>
				원칙적으로, 개인정보 수집 및 이용목적이 달성된 후에는 해당 정보를 지체 없이 파기합니다. (단, 회사는 계정 도용 등으로 인한 피해를 최소화하기 위하여 회원탈퇴 요청 후, 15 일간 개인정보를 보유 합니다.) 또한, 관련 법령의 규정에 따라 수집 및 이용목적 달성 후에도 보존할 필요가 있는 경우 회사는 아래와 같이 관계법령에서 정한 일정한 기간 동안 회원정보를 보관합니다<br/><br/>

				- 표시/광고에 관한 기록 : 6 개월 (전자상거래등에서의 소비자보호에 관한 법률)<br/>
				- 계약 또는 청약철회 등에 관한 기록 : 5 년 (전자상거래등에서의 소비자보호에 관한 법률)<br/>
				- 대금결제 및 재화 등의 공급에 관한 기록 : 5 년 (전자상거래등에서의 소비자보호에 관한 법률)<br/>
				- 소비자의 불만 또는 분쟁처리에 관한 기록 : 3 년 (전자상거래등에서의 소비자보호에 관한 법률)<br/>
				- 웹사이트 방문에 관한 기록 : 3 개월 (통신비밀보호법)						
				</p>
			</div>
			<div className={`${styles.privacy_area} ${styles.area5}`}>
				<span className={styles.tit}>5. 개인정보의 파기절차 및 방법</span>
				<p>
				회사는 원칙적으로 개인정보 수집 및 이용목적이 달성된 후에는 해당 정보를 지체없이 파기하며, 파기절차 및 방법은 다음과 같습니다.<br/><br/>

				(1) 파기절차<br/>
				- 이용자가 회원가입 등을 위해 입력하신 정보는 목적이 달성된 후(탈퇴 신청 시 15 일 유예기간 후) 내부 방침 및 기타 관련 법령에 의한 정보보호 사유에 따라(보유 및 이용기간 참조) 일정 기간 저장된 후 파기됩니다.<br/><br/>
				(2) 파기방법
				<br/>- 전자적 파일형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제합니다.
				<br/>- 서면 상에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다.					
				</p>
			</div>
			<div className={`${styles.privacy_area} ${styles.area6}`}>
				<span className={styles.tit}>6. 장기 미이용 계정의 개인정보 분리 저장 · 관리</span>
				<p>
				최근 1 년 동안 서비스 이용기록이 없는 계정을 장기 미이용 계정으로 전환하고, 해당 계정의 개인정보를 분리하여 저장∙관리됩니다.<br/>
				(다만, 본인 인증을 받지 않은 CAT 메신저 전화번호 계정은 분리·보관없이 즉시삭제 됩니다.)<br/>
				장기 미이용 계정 전환으로 개인정보가 분리 저장∙관리될 경우 게임 이용을 포함한 모든 서비스 이용이 불가능하며, 다시 서비스를 이용하기 위해서는 별도의 복귀 절차를 거쳐야 합니다.<br/>
				기간 만료일 전까지 서비스를 다시 이용하실 경우 장기 미이용 계정 전환이 취소되니, 장기 미이용 계정 전환과 개인정보 분리 보관을 원치 않으시는 경우 YAM-E 앱에 로그인하거나 잠깐이라도 YAM-E 앱을 이용하시면 됩니다.<br/><br/>

				- 분리 저장ᆞ관리 대상 : 1 년 동안 서비스 이용 기록이 없는 계정<br/>
				- 기간 만료일 : 서비스 이용 기록이 없이 1 년이 도래하는 시점<br/>
				- 분리 저장ᆞ관리되는 개인정보의 항목 : 계정정보, 연락처, 인적사항, 서비스이용 기록, 결제기록, 상담기록 등					
				</p>
			</div>
			<div className={`${styles.privacy_area} ${styles.area7}`}>
				<span className={styles.tit}>7. 개인정보의 제공</span>
				<p>
				회사는 서비스 이행을 위해 아래와 같이 외부 전문업체에 위탁하여 운영하고 있습니다.<br/><br/>

				수탁업체: 주식회사 위클러버<br/>
				위탁목적: 고객상담, 불만처리 등 민원처리, DM 발송, SMS 발송, 이벤트 경품배송 업무 운영, 관리 대행<br/><br/>
				회사는 이용자의 개인정보를 ‘3. 개인정보의 수집목적 및 이용목적’에서 고지한 범위 내에서 사용하며, 개인정보를 제 3 자에 제공해야 하는 경우 법령에 따른 동의를 받고 있습니다. 단 다음의 경우는 예외로 하고 있습니다.<br/><br/>

				- 법령의 규정에 의거하거나, 수사 목적으로 법령에 정해진 절차와 방법에 따라 수사기관의 요구가 있는 경우<br/>
				- 서비스의 제공에 따른 요금정산을 위하여 필요한 경우<br/>
				- 다른 법률에 특별한 규정이 있는 경우					
				</p>
			</div>
			<div className={`${styles.privacy_area} ${styles.area8}`}>
				<span className={styles.tit}>8. 이용자 및 법정대리인의 권리와 그 행사방법</span>
				<p>
				이용자 및 법정 대리인은 언제든지 등록되어 있는 자신 혹은 당해 만 14 세 미만 아동의 개인정보를 조회하거나 수정할 수 있으며 가입 해지를 요청할 수도 있습니다. 이용자 혹은 만 14 세 미만 아동의 개인정보 조회/수정을 위해서는 ‘개인정보 변경’(또는 ‘회원정보 수정’ 등)을 클릭하고, 가입해지(동의철회)를 위해서는 ‘회원탈퇴 신청’을 클릭하여 본인 확인 절차를 거치신 후 직접 열람, 정정 또는 탈퇴가 가능 합니다. 혹은 개인정보보호책임자에게 서면, 전화 또는 이메일로 연락하시면 지체없이 조치하겠습니다.<br/>
				이용자가 개인정보의 오류에 대한 정정을 요청하신 경우에는 정정을 완료하기 전까지 당해 개인정보를 이용 또는 제공하지 않습니다. 또한 잘못된 개인정보를 제 3 자에게 이미 제공한 경우에는 정정 처리결과를 제 3 자에게 지체 없이 통지하여 정정이 이루어지도록 하겠습니다. 회사는 이용자 혹은 법정 대리인의 요청에 의해 해지 또는 삭제된 개인정보는 ‘3. 수집하는 개인정보의 보유 및 이용기간’에 명시된 바에 따라 처리하고 그 외의 용도로 열람 또는 이용할 수 없도록 처리하고 있습니다					
				</p>
			</div>
			<div className={`${styles.privacy_area} ${styles.area9}`}>
				<span className={styles.tit}>9. 인터넷 접속정보파일 등 개인정보를 자동으로 수집하는 장치의 설치∙운영 및 그 거부에 관한 사항</span>
				<p>
				회사는 이용자의 정보를 수시로 저장하고 찾아내는 ‘쿠키(cookie)’ 등을 운용합니다. 쿠키란 회사의 웹사이트를 운영하는데 이용되는 서버가 이용자의 브라우저에 보내는 아주 작은 텍스트 파일로서 이용자의 컴퓨터 하드디스크에 저장됩니다. 회사는 다음과 같은 목적을 위해 쿠키를 사용합니다.<br/><br/>

				(1) 쿠키 등 사용 목적<br/>
				회원과 비회원의 접속 빈도나 방문 시간 등을 분석, 이용자의 취향과 관심분야를 파악 및 자취 추적, 방문 회수 파악 등을 통한 개인 맞춤 서비스를 제공합니다<br/><br/>

				(2) 쿠키 설정 거부 방법<br/>
				이용자는 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서, 이용자는 웹브라우저에서 옵션을 설정함으로써 모든 쿠키를 허용하거나, 쿠키가 저장될 때마다 확인을 거치거나, 아니면 모든 쿠키의 저장을 거부할 수도 있습니다.					
				</p>
				<div className={styles.txt_wrap}>
					<p>
					[쿠키설정 방법]<br/><br/>
					① Internet Explorer: 웹브라우저 상단의 [도구] → [인터넷 옵션] → [개인정보] → [고급]
					<br/>② Chrome: 웹브라우저 우측 상단의 [⋮] → [설정] → [고급설정 표시] → 개인정보[콘텐츠 설정] 메뉴를 통해 설정하실 수 있습니다.
					<br/>다만, 회원께서 쿠키 저장을 거부하실 경우 일부 서비스 제공에 어려움이 있을 수 있습니다							
					</p>
				</div>
			</div>
			<div className={`${styles.privacy_area} ${styles.area10}`}>
				<span className={styles.tit}>10. 이용자 및 법정대리인의 권리와 그 행사방법</span>
				<p>
				(1) 개인정보보호를 위한 기술적 대책<br/><br/>
				회사는 이용자의 개인정보를 처리함에 있어 개인정보가 분실, 도난, 유출, 변조 또는 훼손되지 않도록 안전성 확보를 위하여 다음과 같은 기술적 대책을 강구하고 있습니다.<br/>

				<br/>이용자의 개인정보는 비밀번호에 의해 보호되고 있고 이용자 계정의 암호는 오직 본인만이 알 수 있으며, 개인정보 확인 및 변경도 비밀번호를 알고 있는 본인에 의해서만 가능합니다.
				<br/>회사는 해킹 등 외부침입에 대비하여 이용자의 개인정보가 유출되는 것을 막기 위해 현재 외부로부터 침입을 차단하는 장치를 이용하여 해킹 등의 공격등을 막고 있습니다. 특히, 이용자의 개인 정보를 가지고 있는 서버는 외부의 인터넷라인과 직접 연결하지 않고 별도로 관리하는 등 최고 수준의 보안을 유지하고있습니다.
				<br/>회사는 만약의 사태에 대비하여 시스템과 데이터를 백업하는 체제를 갖추고 있습니다.
				<br/>회사는 백신프로그램을 이용하여 컴퓨터바이러스에 의한 피해를 방지하기 위한 조치를 취하고 있습니다. 백신프로그램은 주기적으로 업데이트되며, 갑작스런 바이러스가 출현할 경우 백신이 나오는 즉시 이를 제공함으로써 개인정보가 침해되는 것을 방지하고 있습니다.
				<br/><br/>(2) 개인정보보호를 위한 관리적 대책
				<br/>회사는 이용자의 개인정보에 대한 접근권한을 최소한의 인원으로 제한하고 있습니다. 그 최소한의 인원에 해당하는 자는 다음과 같습니다.
				<br/><br/>* 이용자를 직접 상대로 하여 마케팅업무를 수행하는 자
				<br/>* 개인정보고충 처리 부서 및 담당자 등 개인정보관리 업무를 수행하는 자
				<br/>* 기타 업무상 개인정보의 처리가 불가피한 자
				<br/><br/>회사는 개인정보를 처리하는 직원을 대상으로 새로운 보안기술 습득 및 개인정보보호의무 등에 관해 정기적인 사내교육 및 외부위탁 교육을 실시하고 있습니다.
				<br/>회사는 입사 시 전 직원의 보안서약서를 통하여 사람에 의한 정보유출을 사전에 방지하고 개인정보 처리방침에 대한 이행사항 및 직원의 준수여부를 감사하기 위한 내부절차를 마련하고 있습니다.
				<br/>회사는 개인정보 관련 처리자의 업무인수인계는 보안이 유지된 상태에서 철저하게 이뤄지고 있으며 입사 및 퇴사 후 개인정보 사고에 대한 책임을 명확화하고 있습니다.
				<br/>회사는 전산실 및 자료보관실 등을 특별보호구역으로 설정하여 출입을 통제하고 있습니다.
				<br/>회사는 이용자 개인의 실수 혹은 인터넷의 본질적인 위험성으로 인하여 야기되는 개인정보 유출에 대해 책임을 지지 않습니다. 이용자는 본인의 개인정보를 보호하기 위해서 자신의 계정과 비밀번호를 적절하게 관리하고, 그에 대한 책임을 져야 합니다.						
				</p>
			</div>
			<div className={`${styles.privacy_area} ${styles.area11}`}>
				<span className={styles.tit}>11. 개인정보 처리방침의 변경</span>
				<p>
				회사는 개인정보처리방침의 변경(내용 추가, 삭제 및 수정)이 있을 경우 홈페이지 공지사항을 통해 고지하고 있습니다. 또한 변경된 내용을 고객이 쉽게 확인할 수 있도록 변경 전∙후를 비교하여 공개하고 있습니다.
				</p>
			</div>
			<div className={`${styles.privacy_area} ${styles.area12}`}>
				<span className={styles.tit}>12. 개인정보보호책임자 및 개인정보 고충처리책임자의 연락처</span>
				<p>
				회사는 고객의 개인정보를 보호하고 개인정보와 관련한 불만을 처리하기 위하여 아래와 같이 관련 부서 및 개인정보보호책임자를 지정하고 있습니다.<br/>

				<br/>개인정보보호책임자
				<br/><br/>* 개인정보 보호책임자
				<br/>성명 :박윤환
				<br/>연락처 : 02-875-3330
				<br/>이메일 : admin@weclover.co.kr
				<br/>※ 개인정보 보호 담당에 그 개인정보를 파기합니다.
				<br/><br/>* 개인정보 보호 담당부서
				<br/>부서명 : 개발팀
				<br/>담당자 : 개인정보 보호책임자 및 관리책임자
				<br/>연락처 : 02-875-3330
				<br/><br/>이용자는 회사의 서비스를 이용하면서 발생하는 모든 개인정보보호 관련 민원을 개인정보보호책임자 혹은 개인정보 고충처리책임자에게 신고하실 수 있습니다. 회사는 이용자들의 신고사항에 대해 신속하게 충분한 답변을 드릴 것입니다.

				<br/><br/>기타 개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래 기관에 문의하시기 바랍니다.

				<br/><br/>1. 개인정보침해신고센터(privacy.kisa.or.kr / ☎ 국번없이 118)
				<br/>2. 대검찰청 사이버수사과(spo.go.kr / ☎ 국번없이 1301)
				<br/>3. 경찰청 사이버안전국(cyberbureau.police.go.kr / ☎ 국번없이 182)
				<br/>4. 개인정보분쟁조정위원회(kopico.go.kr / ☎ 국번없이 1833-6972)						
				</p>
			</div>
		</div>
    </>
  );
}