import {
  CREATE_KNOWLEDGE_CATEGORY,
  LIST_KNOWLEDGE_CATEGORY,
  DELETE_KNOWLEDGE_CATEGORY,
  MODIFY_KNOWLEDGE_CATEGORY,
  SELECT_KNOWLEDGE_CATEGORY,
} from "./types";

import KnowledgeCategoryService from "../services/KnowledgeCategoryService";

export const knowledgeCategoryCreate = (category_name, category_desc) => async (dispatch) => {
  
  try {
    
    const res = await KnowledgeCategoryService.knowledgeCategoryCreate({ category_name, category_desc });

    // console.log("res : ", res);

    dispatch({
      type: CREATE_KNOWLEDGE_CATEGORY,
      payload: res.data,
    });

    return Promise.resolve(res.data);

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};

export const knowledgeCategoryList = () => async (dispatch) => {
  
  try {
    
    const res = await KnowledgeCategoryService.knowledgeCategoryGetAll();

    // console.log("res : ", res);

    dispatch({
      type: LIST_KNOWLEDGE_CATEGORY,
      payload: res.data.data,
    });

    return Promise.resolve(res.data.data);

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};

export const knowledgeCategorySelect = (category_idx) => async (dispatch) => {
  
  try {
    
    const res = await KnowledgeCategoryService.knowledgeCategorySelect(category_idx);

    // console.log("res : ", res);

    dispatch({
      type: SELECT_KNOWLEDGE_CATEGORY,
      payload: res.data.data,
    });

    return Promise.resolve(res.data.data);

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};

export const knowledgeCategoryDelete = (category_idx) => async (dispatch) => {
  
  try {

    // console.log("knowledgeCategoryDelete! category_idx : ", category_idx);

    const res = await KnowledgeCategoryService.knowledgeCategoryDelete(category_idx);

    // console.log("res : ", res);

    if (res.data.success === false)
    {
      alert(res.data.msg);
      return Promise.resolve(category_idx);
    }
    else
    {
      dispatch({
        type: DELETE_KNOWLEDGE_CATEGORY,
        payload: { category_idx },
      });
  
      return Promise.resolve(res.data.data);
    }

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};

export const knowledgeCategoryModify = (category_idx, category_name, category_desc) => async (dispatch) => {
  
  try {
    
    const res = await KnowledgeCategoryService.knowledgeCategoryModify({ category_idx, category_name, category_desc });

    // console.log("res : ", res);

    dispatch({
      type: MODIFY_KNOWLEDGE_CATEGORY,
      payload: res.data,
    });

    return Promise.resolve(res.data);

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};
