import {
  CREATE_MISSION,
  LIST_MISSION,
  DELETE_MISSION,
  MODIFY_MISSION,
  SELECT_MISSION,
  ORDER_CHANGE_MISSION,
} from "../actions/types";

const initialState = [];

const missionReducer = (mission = initialState, action) => {
  const { type, payload } = action;

  // console.log("missionReducer Call!");
  // console.log("type : ", type);
  // console.log("payload : ", payload);

  switch (type) {
    
    case CREATE_MISSION:
      return [];

    case LIST_MISSION:
      return payload;

    case SELECT_MISSION:
      return payload;

    case ORDER_CHANGE_MISSION:
      return [];

    case MODIFY_MISSION:
      return [];
      // return knowledge.map((knowledge) => {
      //   if (knowledge.category_idx === payload.category_idx) {
      //     return {
      //       ...knowledge,
      //       ...payload,
      //     };
      //   } else {
      //     return knowledge;
      //   }
      // });

    case DELETE_MISSION:
      // return knowledge.filter(({ category_idx }) => category_idx !== payload.category_idx);
      return [];

    default:
      return mission;
  }
};

export default missionReducer;