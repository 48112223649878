import {
  CREATE_COMMENT,
  LIST_COMMENT,
  DELETE_COMMENT,
  MODIFY_COMMENT,
} from "./types";

import CommentService from "../services/CommentService";

export const commentCreate = (data) => async (dispatch) => {
  
  try {
    
    const res = await CommentService.commentCreate(data);

    // console.log("res : ", res);

    dispatch({
      type: CREATE_COMMENT,
      payload: res.data,
    });

    return Promise.resolve(res.data);

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};

export const commentList = (bbs_name, page) => async (dispatch) => {
  
  try {
    
    const res = await CommentService.commentGetAll(bbs_name, page);

    // console.log("res : ", res);

    dispatch({
      type: LIST_COMMENT,
      payload: res.data.data,
    });

    return Promise.resolve(res.data.data);

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};

export const commentDelete = (category_idx) => async (dispatch) => {
  
  try {

    const res = await CommentService.commentDelete(category_idx);

    // console.log("res : ", res);

    if (res.data.success === false)
    {
      alert(res.data.msg);
      return Promise.resolve(category_idx);
    }
    else
    {
      dispatch({
        type: DELETE_COMMENT,
        payload: { category_idx },
      });
  
      return Promise.resolve(res.data.data);
    }

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};

export const commentModify = (data) => async (dispatch) => {
  
  try {
    
    const res = await CommentService.commentModify(data);

    // console.log("res : ", res);

    dispatch({
      type: MODIFY_COMMENT,
      payload: res.data,
    });

    return Promise.resolve(res.data);

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};
