import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';

import Box from '@mui/material/Box';

import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";

import { bbsNoticeSelect, bbsNoticeDelete } from "../../../actions/notice";

import { useNavigate, useParams } from 'react-router-dom';
import { Link } from '@mui/material';

export default function NoticeView() {

  const board_name  = useParams().board_name;
  console.log("board_name : ", board_name);
  const idx  = useParams().idx;
  console.log("idx : ", idx);
  let prevPage  = useParams().prevPage;
  console.log("prevPage : ", prevPage);
  
  if (prevPage === undefined)
    prevPage = 1;

  const [boardName, setBoardName] = useState(board_name);

  // 리듀서 값들 저장
  const userInfo = useSelector(state => state.auth);
  console.log("userInfo : ", userInfo);

  const notice = useSelector(state => state.notice);
  console.log("notice : ", notice);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const onDelete = (event) => {

      event.preventDefault();
      
      if(userInfo.isLoggedIn)
      {
        const sendDelete = {
          table_name: board_name,
          idx: idx,
        }
    
        console.log("sendDelete : ", sendDelete);
    
        if (window.confirm("정말 삭제하시겠습니까??") == true){    //확인
    
          // 여기서 디스패치로~
          dispatch(bbsNoticeDelete(sendDelete))
          .then(data => 
          {
            console.log("bbsDelete data : ", data);
    
            if (!data.success)
            {
              alert(data.msg);
              return;
            }
            else
            {
              navigate(`/dashboard/NoticeList/${board_name}/${prevPage}`);
            }
              
          })
          .catch(e => {
            console.log(e);
          });	
        }
        else
        {   //취소
        
          return;
        
        }
      }
      else
      {
        alert("로그인 후에 삭제할 수 있습니다.");
        return;
      }
    }

    useEffect(() => {
        
        // 여기서 디스패치로~
        dispatch(bbsNoticeSelect(board_name, idx))
        .then(data => 
        {
          console.log("data : ", data);
        })
        .catch(e => {
          console.log(e);
        });

        return () => {      // 언마운트
            console.log("언마운트!");
        };

    }, [dispatch])

  return (
    <Card sx={{ minWidth: 275 }}>
      <CardContent>
        <Box
        sx={{height:'35px',
            '& > :not(style)': { m: 1, width: '100%' },
        }}
        >
        제목 : {notice.length !== 0 ? notice.select.title : ""}
        </Box>
        <Box
        sx={{height:'35px', wordBreak: 'break-all',
            '& > :not(style)': { m: 1, width: '100%' },
        }}
        >
        작성자 : {notice.length !== 0 ? notice.select.name : ""}
        </Box>
        <Box
        sx={{height:'35px',
            '& > :not(style)': { m: 1, width: '100%' },
        }}
        >
        작성날짜 : {notice.length !== 0 ? notice.select.reg_date : ""}
        </Box>
        <Divider />
        <Box
        sx={{ marginTop: '20px',
              whiteSpace: 'pre-line',
              overflowX: 'auto',
              wordBreak: 'break-all',
            '& > :not(style)': { m: 1, width: '100%' },
        }}
        >
        {notice.length !== 0 ? notice.select.contents : ""}
        </Box>
      </CardContent>
      <CardActions>
        <Link href={`/dashboard/NoticeList/notice/${prevPage}`} underline="none">목록</Link>
        <Link href={`/dashboard/NoticeModify/${board_name}/${idx}/${prevPage}`} underline="none">수정</Link>
        <Link onClick={(e)=>onDelete(e)} value={idx} underline="none">삭제</Link>
      </CardActions>
    </Card>
  );
}