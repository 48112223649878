import * as React from 'react';
import { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from "react-redux";
import styles from "../../css/common.module.css";
import $ from 'jquery';
import { useNavigate, useParams } from 'react-router-dom';

import { orderSelect } from "../../actions/order";
import * as common from "../../lib";

import { useTranslation, Trans } from 'react-i18next'; // 1. react-i18next import 

export default function MypagePayInfoMore() {

	const authState = useSelector(state => state.auth);
    // console.log("authState : ", authState);

	const ord_idx  = useParams().ord_idx;

	let board_page  = useParams().page;
    // console.log("board_page : ", board_page);
	let board_condition  = useParams().condition;
    // console.log("board_condition : ", board_condition);

	const { t, i18n } = useTranslation(); // 3. useTranslation hook 선언

	const initialOrderState = {
		order_idx: null,
		order_count: null, 
		order_amt: null,
		order_stat_cd: null,
		order_date: null,
		ord_valid_date: null,
		product_name: null,
		product_idx: null,
		product_desc: null,
		sell_prc_unit: null,
		filePath: null,
		fileName: null,
		member_idx: null,
		member_name: null,
		payment_no: null,
		payment_opt_cd: null,
		payment_amt: null,
		payment_date: null,
		payment_state: null,
		receipt_url: null,
		channel: null,
		refund_amt: null,
		refund_desc: null,
		refund_stat_cd: null,
		refund_date: null
	  };
  
	const [order, setOrder] = useState(initialOrderState);

	const dispatch = useDispatch();	

	const mypage_tab_btn_areaRef =  useRef();
    const hover_bgRef =  useRef();
    const onRef =  useRef();

	const pay_info_list_pagebtn_areaRef =  useRef();

	useEffect(() => {

		// 여기에서 jQuery를 초기화 합니다.
		// jquery ready 호출. jquery3 부터 변경됨
		$(function(){
			// console.log("jquery ready!");

            //마이페이지 탭 버튼(활성화 효과)
			$(`.${styles.mypage_tab_btn_area} li`).on('click',function()
			{
				let tg=$(this);
				
				$(`.${styles.mypage_tab_btn_area} li`).removeClass(`${styles.on}`);
				tg.addClass(`${styles.on}`);
			});
            
            //마이페이지 탭 버튼(후버 효과)
            $(window).resize(function()
			{
				if (window.innerWidth < 1200) 
				{ 
					$(`.${styles.mypage_tab_btn_area} li.${styles.hover_bg}`).css('display','none');
				}
				else
				{
					let nowbtnNum=$(`.${styles.mypage_tab_btn_area} li.${styles.on}`).index();
					let hoverBg=$(`.${styles.mypage_tab_btn_area} li.${styles.hover_bg}`);

					$(`.${styles.mypage_tab_btn_area} ul`).on('mouseenter',function()
					{
						
						$(`.${styles.mypage_tab_btn_area} li`).removeClass(`${styles.on}`);
						hoverBg.css('display','block');
						
						$(`.${styles.mypage_tab_btn_area} li`).on('mouseenter',function()
						{
							let tgIndex=$(this).index();
							
							if(tgIndex===1)
							{
								hoverBg.stop().css('left','167px').css('width','116px');
							}
							else if(tgIndex===2)
							{
								hoverBg.stop().css('left','300px').css('width','150px');
							}
							else if(tgIndex===3)
							{
								hoverBg.stop().css('left','467px').css('width','116px');
							}
							else if(tgIndex===4)
							{
								hoverBg.stop().css('left','624px').css('width','102px');
							}
							else if(tgIndex===5)
							{
								hoverBg.stop().css('left','774px').css('width','102px');
							}
							else if(tgIndex===6)
							{
								hoverBg.stop().css('left','924px').css('width','102px');
							};
						});
						
					}).on('mouseleave',function()
					{
						$(`.${styles.mypage_tab_btn_area} li`).eq(nowbtnNum).addClass(`${styles.on}`);
						hoverBg.css('display','none');
						
						if(nowbtnNum===0)
						{
							hoverBg.css({left:'167px'}).css({width:'116px'});
						}
						else if(nowbtnNum===1)
						{
							hoverBg.css({left:'300px'}).css({width:'150px'});
						}
						else if(nowbtnNum===2)
						{
							hoverBg.css({left:'467px'}).css({width:'116px'});
						}
						else if(nowbtnNum===3)
						{
							hoverBg.css({left:'624px'}).css({width:'102px'});
						}
						else if(nowbtnNum===4)
						{
							hoverBg.css({left:'774px'}).css({width:'102px'});
						}
						else if(nowbtnNum===5)
						{
							hoverBg.css({left:'924px'}).css({width:'102px'});
						};

					});
					
				}
			}).resize();
			
			//결제 정보 페이지 페이징 버튼	
			$(pay_info_list_pagebtn_areaRef.current).find('li').on('click',function()
			{
				let tg=$(this);
				let num=tg.index();
				let prev;
				let next;
				let last;
				let nowBtn=$(pay_info_list_pagebtn_areaRef.current).children(`${styles.on}`);
				let nowIndex=nowBtn.index();
				
				$(pay_info_list_pagebtn_areaRef.current).children('li').removeClass(`${styles.on}`);
				
				if(tg.hasClass(`${styles.arrow_btn}`)==true)
				{
					if(tg.hasClass(`${styles.first}`)==true)
					{
						tg=$(pay_info_list_pagebtn_areaRef.current).find('li').eq(2);
					}
					else if(tg.hasClass(`${styles.prev}`)==true)
					{
						prev=nowIndex-1;
						
						if(nowIndex===2)
						{
							tg=$(pay_info_list_pagebtn_areaRef.current).find('li').eq(2);
						}else
						{
							tg=$(pay_info_list_pagebtn_areaRef.current).find('li').eq(prev);
						};
					}
					else if(tg.hasClass(`${styles.next}`)==true)
					{
						let lastNum=$(pay_info_list_pagebtn_areaRef.current).find('li').last().index();
						next=nowIndex+1;
						last=lastNum-2;
						
						if(nowIndex===last)
						{
							tg=$(pay_info_list_pagebtn_areaRef.current).find('li').eq(last);
						}else
						{
							tg=$(pay_info_list_pagebtn_areaRef.current).find('li').eq(next);
						};
					}
					else if(tg.hasClass(`${styles.last}`)==true)
					{
						last=num-2;
						tg=$(pay_info_list_pagebtn_areaRef.current).find('li').eq(last);
					};
					
					tg.addClass(`${styles.on}`);
					
				}
				else
				{
					tg.addClass(`${styles.on}`);
				};
			});

		});

		// console.log("dispatch orderSelect");
        dispatch(orderSelect(ord_idx))
        .then(data => 
        {
            // console.log("data : ", data[0]);

			setOrder({
				order_idx: data[0].ord_idx,
				order_no: data[0].ord_no,
				order_count: data[0].ord_count, 
				order_amt: data[0].ord_amt,
				order_stat_cd: data[0].ord_stat_cd,
				order_date: data[0].ord_date,
				ord_valid_date: data[0].ord_valid_date,
				product_name: data[0].prd_name,
				product_idx: data[0].prd_idx,
				product_desc: data[0].prd_desc,
				sell_prc_unit: data[0].sell_prc_unit,
				filePath: data[0].prd_photo_path,
				fileName: data[0].prd_photo_file,
				member_idx: data[0].mem_idx,
				member_name: data[0].mem_name,
				member_email: data[0].mem_email,
				member_phone: data[0].mem_phone,
				payment_no: data[0].pay_no,
				payment_opt_cd: data[0].pay_opt_cd,
				payment_amt: data[0].pay_amt,
				payment_date: data[0].pay_date,
				payment_state: data[0].pay_state,
				receipt_url: data[0].receipt_url,
				channel: data[0].channel,
				vbank_num: data[0].vbank_num,
				vbank_date: data[0].vbank_date,
				vbank_name: data[0].vbank_name,
				refund_amt: data[0].ref_amt,
				refund_desc: data[0].ref_desc,
				refund_stat_cd: data[0].ref_stat_cd,
				refund_date: data[0].ref_date
			});
            
        })
        .catch(e => {
            console.log(e);
        });

	}, [$, dispatch]);

	const vBankNumCopy = (event, value) =>
	{
		event.preventDefault();

		const t = document.createElement("textarea");
		document.body.appendChild(t);
		t.value = value;
		t.select();
		document.execCommand('copy');
		document.body.removeChild(t);

		// return alert("복사완료!");
		return alert(`${t("MypagePayInfo.alert.string10")}`);
	}

	// console.log("order : ", order);

  return (
    <>
      	{/* Header 분리됨 MainLayout Header에 있음 */}

		<div className={styles.mypage_top_line_banner}>
			<div className={styles.inner}>
				<span>{/* 학부모 페이지 */}{t('Common.Mypage_title1')}</span>
			</div>
		</div>
		<div className={styles.mypage_tab_btn_area} ref={mypage_tab_btn_areaRef}>
			<div className={styles.inner}>
				<ul>
					<li className={styles.hover_bg} ref={hover_bgRef}></li>
					<li><a href="/UserMypage/Report">{/* 학습 리포트 */}{t('Common.SubMenu.menu1')}</a></li>
					<li><a href="/UserMypage/StudentInfo">{/* 학습자 정보/관리 */}{t('Common.SubMenu.menu2')}</a></li>
					<li><a href="/UserMypage/ParentsInfo">{/* 학부모 정보 */}{t('Common.SubMenu.menu3')}</a></li>
					<li className={styles.on} ref={onRef}><a href="/UserMypage/PayInfo">{/* 결제 정보 */}{t('Common.SubMenu.menu4')}</a></li>
					<li><a href="/UserMypage/CouponInfo">{/* 쿠폰 관리 */}{t('Common.SubMenu.menu5')}</a></li>
					<li><a href="/UserMypage/QnaInfo">{/* 문의 내역 */}{t('Common.SubMenu.menu6')}</a></li>
				</ul>
			</div>
		</div>
		<div className={`${styles.mypage_tab} ${styles.mypage_pay_info_more}`}>
			<div className={styles.inner}>
				<span className={styles.my_tit}>{/* 결제 상세정보 */}{t('MypagePayInfo.string26')}</span>
				<div className={styles.pay_info_more_area}>
					 <ul>
						<li>
							<span className={styles.head}>{/* 주문일자 */}{t('MypagePayInfo.string5')}</span>
							<p>{common.payDateChangeReturn(order.order_date)}{/*2022.03.25 - 16시35분*/}</p>
						</li>
						<li>
							<span className={styles.head}>{/* 만료일 */}{t('MypagePayInfo.string27')}</span>
							<p>{common.payDateChangeReturn(order.ord_valid_date)}{/*2022.04.25 - 16시35분*/}</p>
						</li>
						<li>
							<span className={styles.head}>{/* 주문번호 */}{t('MypagePayInfo.string28')}</span>
							<p>{order.order_no}</p>
						</li>
						<li>
							<span className={styles.head}>{/* 주문자 */}{t('MypagePayInfo.string7')}</span>
							<p>{order.member_name}</p>
						</li>
						<li>
							<span className={styles.head}>{/* 상품명 */}{t('MypagePayInfo.string29')}</span>
							<p>{order.product_name}</p>
						</li>
						<li>
							<span className={styles.head}>{/* 상품설명 */}{t('MypagePayInfo.string30')}</span>
							<p>{order.product_desc}</p>
						</li>
						<li>
							<span className={styles.head}>{/* 가격 */}{t('MypagePayInfo.string31')}</span>
							<p>{common.priceToString(order.sell_prc_unit)}{/* 원 */}{t('MypagePayInfo.string32')}</p>
						</li>
						<li>
							<span className={styles.head}>{/* 수량 */}{t('MypagePayInfo.string33')}</span>
							<p>{order.order_count || ""} {/* 개 */}{t('MypagePayInfo.string34')}</p>
						</li>
						{
							order.vbank_num !== null ?
							<>
								<li className={styles.info_txt}>
									<span className={styles.head}>{/* 가상계좌 <br/>입금계좌번호 */}<Trans i18nKey="MypagePayInfo.string35"/></span>
									<div>
										<div className={styles.top_area}>
											<p>{order.vbank_num || ""}</p> 
											<button  type="button"  className={styles.fake_account_number_copy} onClick={(e) => vBankNumCopy(e, order.vbank_num || "")}>{/* 복사 */}{t('MypagePayInfo.string36')}</button>
										</div>
										<p className={styles.info}>{/* * 가상계좌는 입금 후 확인까지 최대 5분이 소요됩니다. */}{t('MypagePayInfo.string37')}</p>
									</div>
									
								</li>
								<li>
									<span className={styles.head}>{/* 가상계좌 <br/>입금기한 */}<Trans i18nKey="MypagePayInfo.string38"/></span>
									<p>{order.vbank_date || ""}</p>
								</li>
								<li>
									<span className={styles.head}>{/* 가상계좌 <br/>은행명 */}<Trans i18nKey="MypagePayInfo.string39"/></span>
									<p>{order.vbank_name || ""}</p>
								</li>
							</>
							:
							""
						}
						{
							order.order_stat_cd === "A" ?
								<li className={styles.pay_more_total}>
									<span className={styles.head}>{/* 총 결제금액 */}{t('MypagePayInfo.string26')}</span>
									<p>{common.priceToString(order.order_amt) || ""}{/* 원 */}{t('MypagePayInfo.string32')}</p>
								</li>
							:
							""
						}
						<li className={styles.pay_more_total}>
							<span className={styles.head}>{/* 결제상태 */}{t('MypagePayInfo.string9')}</span>
							{/* <p>{order.refund_stat_cd === "cancelled" ? "환불" : common.returnOrderState(order.order_stat_cd) || ""}</p> */}
							<p>{order.refund_stat_cd === "cancelled" ? t('Common.Refund') : common.returnOrderState(order.order_stat_cd) || ""}</p>
						</li>
						{
							order.order_stat_cd === "A" ?
								<li>
									<span className={styles.head}>{/* 영수증 */}{t('MypagePayInfo.string41')}</span>
									<p><a href={order.receipt_url || "#!"} target="_blank">{/* 영수증 확인 */}{t('MypagePayInfo.string42')}</a></p>
								</li>			
							:
							""
						}
						{
							order.refund_stat_cd === "cancelled" ?
								<>
								<li>
									<span className={styles.head}>{/* 환불일 */}{t('MypagePayInfo.string43')}</span>
									<p>{common.payDateChangeReturn(order.refund_date)}</p>
								</li>
								<li className={styles.pay_more_refund}>
									<span className={styles.head}>{/* 환불금액 */}{t('MypagePayInfo.string44')}</span>
									<div>
										<p>-{common.priceToString(order.refund_amt)}{/* 원 */}{t('MypagePayInfo.string32')}</p>
										<p className={styles.info}>{/* * 실시간 계좌와 가상 계좌는 환불 처리가 완료되어도 입금까지 영업일 기준(주말, 공휴일 제외) 1~2일 소요됩니다. */}{t('MypagePayInfo.string45')}</p>
									</div>
								</li>	
								</>	
							:
							""
						}
					 </ul>
					<a href={`/UserMypage/PayInfo${board_page ? "/" + board_page : ""}${board_condition ? "/" + board_condition : ""}`}>{/* 확인 */}{t('Common.confirm')}</a>
				</div>
			</div>{/* inner */}
		</div>{/* mypage_tab */}
		
		{/* Footer 분리됨 MainLayout Footer에 있음 */}
    </>
  );
}