import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';

import Box from '@mui/material/Box';
import Input from '@mui/material/Input';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import Link from '@mui/material/Link';

import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";

import { missionCategoryCreate } from "../../../actions/mission_category";

import { useNavigate } from 'react-router-dom';

const ariaLabel = { 'aria-label': 'description' };

export default function CategoryWrite() {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const initialCategoryState = {
        category_name: null
    };

    const [category, setCategory] = useState(initialCategoryState);

    const handleInputChange = event => {
        const { name, value } = event.target;
        setCategory({ ...category, [name]: value });
    };

    const handleSubmit = (event) => {

        event.preventDefault();

        const { category_name } = category;

        // 여기서 디스패치로~
        dispatch(missionCategoryCreate(category_name))
        .then(data => 
        {
            console.log("data : ", data);
            if (!data.success)
                alert(data.msg);
            
            navigate("/dashboard/MissionCategoryList");
        })
        .catch(e => {
            console.log(e);
        });
        
    };

    useEffect(() => {
        
        return () => {      // 언마운트
            console.log("언마운트!");
        };

    }, [])

  return (
    <Card sx={{ minWidth: 275 }}>
      <CardContent>
        <Box
        sx={{
            '& > :not(style)': { m: 1, width: '400px' },
        }}
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit}
        >
        카테고리 이름 : <Input placeholder="카테고리 이름" id="category_name" name="category_name" inputProps={ariaLabel} onChange={handleInputChange} />
        </Box>
        <Box
        sx={{
            '& > :not(style)': { m: 1, width: '400px' },
        }}
        noValidate
        autoComplete="off"
        >
        </Box>
      </CardContent>
      <CardActions>
        <Button onClick={handleSubmit}>카테고리 등록</Button>
        <Link href={`/dashboard/MissionCategoryList`} underline="none">목록</Link>
      </CardActions>
    </Card>
  );
}