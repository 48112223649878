import http from "../http-common";

const groupOrderCreate = data => {
  return http.post("payment/group/order", data);
};

const groupOrderGetAll = () => {
  return http.get("payment/group/order");
};

const groupOrderCancel = data => {
  return http.put("payment/group/order/cancel", {order_idx : data});
};

const groupOrderSelect = data => {
  return http.get(`payment/group/order/${data}`);
};

const OrderService = {
  groupOrderCreate,
  groupOrderGetAll,
  groupOrderCancel,
  groupOrderSelect,
};

export default OrderService;
