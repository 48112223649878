import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import { Link, Grid } from '@mui/material';

import { useDispatch, useSelector } from "react-redux";
import { missionSelect, missionDelete } from "../../../actions/mission";
import { missionCategoryList } from "../../../actions/mission_category";

import { useEffect, useState } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import * as common from "../../../lib";

export default function ProductModify() {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    
    const idx  = useParams().idx;
    console.log("idx : ", idx);

    let list_category_idx  = useParams().list_category_idx;
    console.log("list_category_idx : ", list_category_idx);

    if (list_category_idx === undefined)
      list_category_idx = 1;

    console.log("list_category_idx : ", list_category_idx);

    const category = useSelector(state => state.mission_category);
    console.log("category : ", category);

    const initialMissionState = {
      id: null,  
      category_idx: null,
      orderby: null,
      type: "G", 
      item_reward: null,
      indicators: [],
      use_code_block: [],
      core_code_block: [],
      rating: null,
    };

    const [mission, setMission] = useState(initialMissionState);

    const handleDeleteMission = (event, idx) => {
        console.log(idx);
  
        if (window.confirm("정말 삭제하시겠습니까??") === true)   //확인
        {
          // 여기서 디스패치로~
          dispatch(missionDelete(idx))
          .then(data => 
          {
              console.log("data : ", data);
              if (!data.success)
                  alert(data.msg);
  
              navigate(`/dashboard/MissionList/${list_category_idx}`);
          })
          .catch(e => {
              console.log(e);
          });
        }
        else  //취소
        {
          return false;
        }
  
      };

    const returnCategory = (idx) => {
      for (let i=0; i<category.length; i++)
      {
        if (idx === category[i].mis_category_idx)
        {
          return category[i].mis_category_name;
        }
      }
    };

    const returnType = (type) => {
      
      if (type === "G")
      {
        return "일반 미션";
      }
      else if (type === "E")
      {
        return "기타 미션";
      }
      
    };

    const returnIndicators = (items) => {
      
      return (
        <>
          {items.map((item, index) => (
            <span key={index}>
              {index+1}. {item.type}:{item.point} <br></br>
            </span>
          ))}
        </>
      );
    };

    const returnCode = (items) => {
      
      return (
        <>
          {items.map((item, index) => (
            <span key={index}>
              {index+1}. {item.id} <br></br>
            </span>
          ))}
        </>
      );
    };

    useEffect(() => {
        
        console.log("dispatch missionSelect");
        dispatch(missionSelect(idx))
        .then(data => 
        {
            console.log("missionSelect data : ", data[0]);
            setMission({
              id: data[0].mis_id,  
              category_idx: data[0].mis_category_idx,
              orderby: data[0].mis_orderby,
              type: data[0].mis_type, 
              item_reward: data[0].mis_item_reward,
              indicators: data[0].indicators,
              use_code_block: data[0].use_code_block,
              core_code_block: data[0].core_code_block,
              rating: data[0].mis_rating,
            });
        })
        .catch(e => {
            console.log(e);
        });

        console.log("dispatch missionCategoryList");
        // 여기서 디스패치로~
        dispatch(missionCategoryList())
        .then(data => 
        {
            console.log("data : ", data);
        })
        .catch(e => {
            console.log(e);
        });

        return () => {      // 언마운트
            console.log("언마운트!");
        };

    }, [dispatch])

  return (
    <Card sx={{ minWidth: 275, maxWidth: 700 }}>
      <CardContent>
        <Grid container spacing={2}>
            <Grid item xs={4} md={3}>
            미션 아이디 : 
            </Grid>
            <Grid item xs={6} md={8}>
            {mission.id || ""}
            </Grid>
            <Grid item xs={4} md={3}>
            카테고리 : 
            </Grid>
            <Grid item xs={6} md={8}>
            { mission.category_idx ? returnCategory(mission.category_idx) : "" }
            </Grid>
            <Grid item xs={4} md={3}>
            등급 : 
            </Grid>
            <Grid item xs={6} md={8}>
            { mission.rating ? common.returnMissionRating(mission.rating) : "" }
            </Grid>
            <Grid item xs={4} md={3}>
            순서 : 
            </Grid>
            <Grid item xs={6} md={8}>
            {mission.orderby || ""}
            </Grid>
            <Grid item xs={4} md={3}>
            타입 : 
            </Grid>
            <Grid item xs={6} md={8}>
            {returnType(mission.type) || ""}
            </Grid>
            <Grid item xs={4} md={3}>
            아이템 리워드 : 
            </Grid>
            <Grid item xs={6} md={8}>
            {mission.item_reward || ""}
            </Grid>
            <Grid item xs={4} md={3}>
            지표
            </Grid>
            <Grid item xs={6} md={8}>
            { mission.indicators ? returnIndicators(mission.indicators) : ""} 
            </Grid>
            <Grid item xs={4} md={3}>
            주요코딩 블록
            </Grid>
            <Grid item xs={6} md={8}>
            { mission.use_code_block ? returnCode(mission.use_code_block) : ""} 
            </Grid>
            <Grid item xs={4} md={3}>
            핵심코딩 블록
            </Grid>
            <Grid item xs={6} md={8}>
            { mission.core_code_block ? returnCode(mission.core_code_block) : ""} 
            </Grid>
        </Grid>
      </CardContent>
      <CardActions>
        <Link href={`/dashboard/MissionList/${list_category_idx}`} underline="none">목록</Link>
        <Link href={`/dashboard/MissionModify/${idx}/${list_category_idx}`} underline="none">수정</Link>
        <Link onClick={(e)=>handleDeleteMission(e, idx)} value={idx} underline="none">삭제</Link>
      </CardActions>
    </Card>
  );
}