import * as React from 'react';
import styles from "../../css/common.module.css";

import { useDispatch, useSelector } from "react-redux";
import { bbsReplyCreate } from "../../actions/bbs";

import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useNavigate } from 'react-router-dom';

export default function Reply() {

const [boardName, setBoardName] = useState("");
const [title, setTitle] = useState("");
const [contents, setContents] = useState("");
const board_name  = useParams().board_name;
console.log("board_name : ", board_name);
const bbs_idx  = useParams().bbs_idx;
console.log("bbs_idx : ", bbs_idx);
const prevPage  = useParams().prevPage;
console.log("prevPage : ", prevPage);

// 리듀서 값들 저장
const userInfo = useSelector(state => state.auth);
console.log("userInfo : ", userInfo);

const dispatch = useDispatch();
const navigate = useNavigate();

const onTitleChange = (event) => {
	setTitle(event.target.value);
}

const onContentsChange = (event) => {
	setContents(event.target.value);
}

const onWriteSubmit = (event) => {

	event.preventDefault();
	
	if(userInfo.isLoggedIn)
	{
		const sendReply = {
			table_name: board_name,
			bbs_idx: bbs_idx,
			mem_idx: userInfo.user.idx,
			name: userInfo.user.name,
			title: title,
			contents: contents
		}

		if (sendReply.title === "")
		{
			alert("제목이 없습니다. 작성해주세요.");
			return;
		}

		if (sendReply.contents === "")
		{
			alert("내용이 없습니다. 작성해주세요.");
			return;
		}

		console.log("sendReply : ", sendReply);
	
		// 여기서 디스패치로~
		dispatch(bbsReplyCreate(sendReply))
		.then(data => 
		{
			console.log("data : ", data);
			if (!data.success)
			{
				alert(data.msg);
				return;
			}
			else
				navigate(`/BoardList/${board_name}/${prevPage}`);

		})
		.catch(e => {
			console.log(e);
		});
	}
	else
	{
		alert("로그인 후에 댓글을 달 수 있습니다.");
		return;
	}
}

useEffect(() => {

	if (board_name === "news")
	{
		setBoardName("새소식");
	}
	else if (board_name === "info")
	{
		setBoardName("자료실");
	}

}, [])

  return (
    <>
      	{/* Header 분리됨 MainLayout Header에 있음 */}
		  <div className={styles.new_write_area}>
			<div className={styles.inner}>
				<span className={styles.tit}>답글 글쓰기</span>
				<div className={styles.wrap}>
					<div className={styles.title_area}>
						<span className={styles.tit_info}>제목</span>
						<input type="text" onChange={onTitleChange} />
					</div>
					<div className={styles.content_area}>
						<span className={styles.tit_info}>내용</span>
						<textarea name="write" id={styles.write_textarea} maxLength="2000" onChange={onContentsChange}>
						</textarea>
					</div>
				</div>
				<div className={`${styles.btn_area} ${styles.cf}`}>
					<a href={`/BoardList/${board_name}`} className={styles.re}>목록</a>
					<a href="#" className={styles.finish} onClick={onWriteSubmit} >쓰기</a>
				</div>
			</div>
		</div>
		{/* Footer 분리됨 MainLayout Footer에 있음 */}
    </>
  );
}