import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import Button from '@mui/material/Button';

import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";

import { couponExcelDownload } from "../actions/coupon";

export default function ReactExcelDownload (props) {

    const dispatch = useDispatch();

    // 여기서 데이터를 만듭니다.
    // const [excelData, setExcelData] = useState([]);

    useEffect(() => {
  
        // 언마운트
        return () => {
            // console.log("언마운트!");
        };
  
      }, []);

  const excelFileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const excelFileExtension = '.xlsx';    
  const excelFileName = '작성자';
  let excelData = [];

  const excelDownload = (cou_idx) => {

        dispatch(couponExcelDownload(cou_idx))
        .then(data => 
        {
            // console.log("data : ", data);

            for (let i=0; i<data.length; i++)
            {
                let stateValue = "";

                if (data[i].cou_state === 'W')
                {
                    stateValue = "사용대기";
                }
                else if (data[i].cou_state === 'A')
                {
                    stateValue = "사용중";
                }
                else if (data[i].cou_state === 'S')
                {
                    stateValue = "중지상태";
                }

                // 등록 중 처리
                if (data[i].cou_state === 'W' && data[i].mem_email !== null)
                {
                    stateValue = "사용등록";
                }

                let memType = "";

                if (data[i].mem_type === 1)
                {
                    memType = "관리자";
                }
                else if (data[i].mem_type === 2)
                {
                    memType = "일반";
                }
                else if (data[i].mem_type === 3)
                {
                    memType = "소셜";
                }
                else if (data[i].mem_type === 4)
                {
                    memType = "단체";
                }


                excelData.push({
                    cou_idx: i+1,
                    cou_number: data[i].cou_number,
                    cou_name: data[i].cou_name,
                    cou_state:  stateValue,
                    mem_email: data[i].mem_email,
                    mem_name: data[i].mem_name,
                    mem_type: memType,
                    mst_id: data[i].mst_id,
                    cou_valid_start_date: data[i].cou_valid_start_date,
                    cou_valid_end_date: data[i].cou_valid_end_date,
                });
            }

            // console.log("excelData : ", excelData);

            const ws = XLSX.utils.aoa_to_sheet([
              [`쿠폰 리스트`],
              [],
              ['번호', '쿠폰번호', '쿠폰명',  '쿠폰상태', '사용자 이메일',  '사용자 이름', '사용자 유형', '적용된 학습자', '유효시간 시작일', '유효기간 종료일']
            ]);
      
            excelData.map((data) => 
            {
              XLSX.utils.sheet_add_aoa(
                ws,
                [
                  [
                    data.cou_idx,
                    data.cou_number,
                    data.cou_name,
                    data.cou_state,
                    data.mem_email,
                    data.mem_name,
                    data.mem_type,
                    data.mst_id,
                    data.cou_valid_start_date,
                    data.cou_valid_end_date
                  ]
                ],
                {origin: -1}
              );
              ws['!cols'] = [ // 행 사이즈
                { wpx: 50 },
                { wpx: 200 },
                { wpx: 200 },
                { wpx: 80 },
                { wpx: 200 },
                { wpx: 100 },
                { wpx: 100 },
                { wpx: 100 },
                { wpx: 150 },
                { wpx: 150 }
              ]
              return false;
            });
      
            const wb = {Sheets: { data: ws }, SheetNames: ['data']};
            const excelButter = XLSX.write(wb, { bookType: 'xlsx', type: 'array'});
            const excelFile = new Blob([excelButter], { type: excelFileType});
            FileSaver.saveAs(excelFile, excelFileName + excelFileExtension);
            
        })
        .catch(e => {
            console.log(e);
        });

        return;
  }

	return (
    	<div>
        	<Button variant="outlined" onClick={() => excelDownload(props.cou_idx)}>엑셀 다운로드</Button>
        </div>
    );
};