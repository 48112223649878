import * as React from 'react';
import Chart from "react-apexcharts";

import { useTranslation, Trans } from 'react-i18next'; // 1. react-i18next import 

export default function BasicTable(props) {

      const { t, i18n } = useTranslation(); // 3. useTranslation hook 선언

      let labelsDataArray = [];
      let ageDataArray = [];
      let ageDataArray2 = [];
      let ageDataArray3 = [];

      // console.log(props.RowData);

      for (let i=0; i<props.RowData?.length; i++)
      {
        labelsDataArray.push(props.RowData[i].mst_id);
        ageDataArray.push(props.RowData[i].cnt1);
        ageDataArray2.push(props.RowData[i].cnt2);
        ageDataArray3.push(props.RowData[i].cnt3);
      }

      let state = {
          
          series: [{
            // name: '초급',
            name: `${t("ClassStudentChart.string3")}`,
            data: ageDataArray
          }, {
            // name: '중급',
            name: `${t("ClassStudentChart.string4")}`,
            data: ageDataArray2
          }, {
            // name: '고급',
            name: `${t("ClassStudentChart.string5")}`,
            data: ageDataArray3
          }],
          options: {
            chart: {
              type: 'bar',
              height: 350,
              stacked: true,
            },
            responsive: [{
              breakpoint: 480,
              options: {
                legend: {
                  position: 'bottom',
                  offsetX: -10,
                  offsetY: 0
                }
              }
            }],
            xaxis: {
              categories: labelsDataArray,
            },
            fill: {
              opacity: 1
            },
            legend: {
              position: 'right',
              offsetX: 0,
              offsetY: 50
            },
          },
    
    };

  return (
    <>
        <Chart options={state.options} series={state.series} type="bar" height={380} />
    </>
  );
}


// import React, { Component } from "react";
// import ReactApexChart from "react-apexcharts";

// class App extends Component {
//   constructor(props) {
//     super(props);

//     this.state = {
          
//       series: [{
//         name: '초급',
//         data: [44, 55, 41, 67, 22]
//       }, {
//         name: '중급',
//         data: [13, 23, 20, 8, 13]
//       }, {
//         name: '고급',
//         data: [11, 17, 15, 15, 21]
//       }],
//       options: {
//         chart: {
//           type: 'bar',
//           height: 350,
//           stacked: true,
//           stackType: '100%'
//         },
//         responsive: [{
//           breakpoint: 480,
//           options: {
//             legend: {
//               position: 'bottom',
//               offsetX: -10,
//               offsetY: 0
//             }
//           }
//         }],
//         xaxis: {
//           categories: ['student001', 'student002', 'student003', 'student004', 'student005'],
//         },
//         fill: {
//           opacity: 1
//         },
//         legend: {
//           position: 'right',
//           offsetX: 0,
//           offsetY: 50
//         },
//       },
    
    
//     };

//   }

//   render() {
//     return (
//         <div id="chart">
//             <ReactApexChart options={this.state.options} series={this.state.series} type="bar" height={380} />
//         </div>
        
//     );
//   }
// }

// export default App;