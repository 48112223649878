import * as React from 'react';
import Chart from "react-apexcharts";

import { useTranslation, Trans } from 'react-i18next'; // 1. react-i18next import 

export default function BasicTable(props) {

      const { t, i18n } = useTranslation(); // 3. useTranslation hook 선언

      let labelsDataArray = [];
      let ageDataArray = [];
      let ageDataArray2 = [];

      if (props.RowData?.length !== 0)
      {
        // 데이터 생성
        for (let i=0; i<props.RowData?.length; i++)
        {
          labelsDataArray.push(props.RowData[i].mst_id);
          ageDataArray.push(props.RowData[i].max_date3);
          ageDataArray2.push(props.RowData[i].max_date1);
        }
      }

      let state = {
          
        series: [{
          name: '',
          data: ageDataArray
        }],
        options: {
          chart: {
            height: 350,
            type: 'bar',
          },
          responsive: [{
            breakpoint: 480,
          }],
          plotOptions: {
            bar: {
              borderRadius: 10,
              dataLabels: {
                position: 'top', // top, center, bottom
              },
            }
          },
          dataLabels: {
            enabled: true,
            formatter: function (val, opt) {
              // return val + "분 (" + ageDataArray2[opt.dataPointIndex] + ")";
              return val + `${t("ClassStudentChart.string2", {minute : ageDataArray2[opt.dataPointIndex]})}`;
              
              // return ageDataArray2[opt.dataPointIndex];
            },
            offsetY: -20,
            style: {
              fontSize: '12px',
              colors: ["#304758"]
            }
          },
          
          xaxis: {
            categories: labelsDataArray,
            position: 'center',
            axisBorder: {
              show: false
            },
            axisTicks: {
              show: false
            },
            crosshairs: {
              fill: {
                type: 'gradient',
                gradient: {
                  colorFrom: '#D8E3F0',
                  colorTo: '#BED1E6',
                  stops: [0, 100],
                  opacityFrom: 0.4,
                  opacityTo: 0.5,
                }
              }
            },
            tooltip: {
              enabled: true,
            }
          },
          yaxis: {
            axisBorder: {
              show: false
            },
            axisTicks: {
              show: false,
            },
            labels: {
              show: true,
              formatter: function (val) {
                // return val + "분";
                return val + `${t("ClassStudentChart.string1")}`;
              },
            }
          
          },
        },
      };

  return (
    <>
        <Chart options={state.options} series={state.series} type="bar" height={380} />
    </>
  );
}

// import React, { Component } from "react";
// import Chart from "react-apexcharts";

// class App extends Component {
//   constructor(props) {
//     super(props);

//     this.state = {
          
//       series: [{
//         name: '분',
//         data: [2.3, 3.1, 4.0, 10.1, 4.0, 3.6, 3.2, 2.3, 1.4, 0.8, 0.5, 0.2, 2.3, 3.1, 4.0, 10.1, 4.0, 3.6, 3.2, 2.3, 1.4, 0.8, 0.5, 0.2]
//       }],
//       options: {
//         chart: {
//           height: 350,
//           type: 'bar',
//         },
//         responsive: [{
//           breakpoint: 480,
//         }],
//         plotOptions: {
//           bar: {
//             borderRadius: 10,
//             dataLabels: {
//               position: 'top', // top, center, bottom
//             },
//           }
//         },
//         dataLabels: {
//           enabled: true,
//           formatter: function (val) {
//             return val + "분";
//           },
//           offsetY: -20,
//           style: {
//             fontSize: '12px',
//             colors: ["#304758"]
//           }
//         },
        
//         xaxis: {
//           categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec","Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
//           position: 'center',
//           axisBorder: {
//             show: false
//           },
//           axisTicks: {
//             show: false
//           },
//           crosshairs: {
//             fill: {
//               type: 'gradient',
//               gradient: {
//                 colorFrom: '#D8E3F0',
//                 colorTo: '#BED1E6',
//                 stops: [0, 100],
//                 opacityFrom: 0.4,
//                 opacityTo: 0.5,
//               }
//             }
//           },
//           tooltip: {
//             enabled: true,
//           }
//         },
//         yaxis: {
//           axisBorder: {
//             show: false
//           },
//           axisTicks: {
//             show: false,
//           },
//           labels: {
//             show: false,
//             formatter: function (val) {
//               return val + "%";
//             }
//           }
        
//         },
//       },
    
    
//     };
//   }

//   render() {
//     return (
//         <div>
//             <Chart options={this.state.options} series={this.state.series} type="bar" height={380} />
//         </div>
        
//     );
//   }
// }

// export default App;