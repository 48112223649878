import * as React from 'react';
import styles from "../../css/common.module.css";

import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useNavigate } from 'react-router-dom';

import { useDispatch, useSelector } from "react-redux";
import { bbsSelect, bbsDelete } from "../../actions/bbs";
import { commentList, commentCreate, commentModify, commentDelete } from "../../actions/comment";

import * as common from "../../lib";

export default function Write() {

// 페이지 파라미터로 넘어온 값들 저장
const board_name  = useParams().board_name;
console.log("board_name : ", board_name);
const idx  = useParams().idx;
console.log("idx : ", idx);
const prevPage  = useParams().prevPage;
console.log("prevPage : ", prevPage);
let board_condition  = useParams().condition;
console.log("board_condition : ", board_condition);
let board_keyword  = useParams().keyword;
console.log("board_keyword : ", board_keyword);

if (board_condition === undefined)
	board_condition = "";
else if(board_condition === "선택")
	board_condition = "";

if (board_keyword === undefined)
	board_keyword = "";

const [boardName, setBoardName] = useState(board_name);
const [submitComment, setsubmitComment] = useState("");
let [commentInput, setCommentInput] = useState("");
let [commentInputBool, setCommentInputBool] = useState();
const [searchCondition, setSearchCondition] = useState(board_condition);
const [searchKeyword, setSearchKeyword] = useState(board_keyword);

// 리듀서 값들 저장
const userInfo = useSelector(state => state.auth);
console.log("userInfo : ", userInfo);

const bbs = useSelector(state => state.bbs);
console.log("bbs : ", bbs);

const comment = useSelector(state => state.comment);
console.log("comment : ", comment);

const dispatch = useDispatch();
const navigate = useNavigate();

const onCommentChange = (event) => {
	event.preventDefault();
	setsubmitComment(event.target.value);
}

const onCommentEditChange = (event) => {
	event.preventDefault();
	setCommentInput(event.target.value)
}

const handleCommentModifyClick=(event, index, comment, mem_idx)=>{

	if (mem_idx !== userInfo.user.idx)
	{
		alert("수정은 작성자 본인만 할 있습니다.");
		return;
	}
	event.preventDefault();
	setCommentInputBool(index);
	setCommentInput(comment);
}

const handleCommentDeleteClick=(event, idx, bbs_idx, mem_idx)=>{

	event.preventDefault();

	if (mem_idx !== userInfo.user.idx)
	{
		alert("삭제는 작성자 본인만 할 있습니다.");
		return;
	}

	if(userInfo.isLoggedIn)
	{
		const sendComment = {
			table_name: board_name,
			idx: idx
		}

		console.log("sendComment : ", sendComment);

		if (window.confirm("정말 삭제하시겠습니까??") == true){    //확인
			// 여기서 디스패치로~
			dispatch(commentDelete(sendComment))
			.then(data => 
			{
				console.log("data : ", data);

				// 여기서 디스패치로~
				dispatch(commentList(board_name, bbs_idx))
				.then(data => 
				{
					console.log("data : ", data);
				})
				.catch(e => {
					console.log(e);
				});
			})
			.catch(e => {
				console.log(e);
			});
		}
		else
		{
			return;
		}
	}
	else
	{
		alert("로그인 후에 댓글 삭제할 수 있습니다.");
		return;
	}
}

const handleCommentCancelClick=(event)=>{
	event.preventDefault();
	setCommentInputBool();
	setCommentInput("");
}

const onCommentSubmit = (event) => {

	event.preventDefault();
	setsubmitComment("");
	
	if(userInfo.isLoggedIn)
	{
		const sendComment = {
			table_name: board_name,
			bbs_idx: idx,
			mem_idx: userInfo.user.idx,
			name: userInfo.user.name,
			contents: submitComment
		}
	
		// 여기서 디스패치로~
		dispatch(commentCreate(sendComment))
		.then(data => 
		{
			console.log("data : ", data);

			// 여기서 디스패치로~
			dispatch(commentList(board_name, idx))
			.then(data => 
			{
				console.log("data : ", data);
			})
			.catch(e => {
				console.log(e);
			});
		})
		.catch(e => {
			console.log(e);
		});
	}
	else
	{
		alert("로그인 후에 댓글을 달 수 있습니다.");
		return;
	}
}

const onDelete = (event) => {

	event.preventDefault();
	
	if(userInfo.isLoggedIn)
	{
		const sendDelete = {
			table_name: board_name,
			idx: idx,
		}

		console.log("sendDelete : ", sendDelete);

		if (window.confirm("정말 삭제하시겠습니까??") == true){    //확인

			// 여기서 디스패치로~
			dispatch(bbsDelete(sendDelete))
			.then(data => 
			{
				console.log("bbsDelete data : ", data);

				if (!data.success)
				{
					alert(data.msg);
					return;
				}
				else
				{
					if (searchCondition !== "" && searchKeyword !== "" )
						navigate(`/BoardList/${board_name}/${prevPage}/${searchCondition}/${searchKeyword}`);
					else
						navigate(`/BoardList/${board_name}/${prevPage}`);
				}
					
			})
			.catch(e => {
				console.log(e);
			});	
		}
		else
		{   //취소
		
			return;
		
		}
	}
	else
	{
		alert("로그인 후에 댓글을 달 수 있습니다.");
		return;
	}
}

const onCommentModifySubmit = (event, idx, bbs_idx) => {

	event.preventDefault();

	setCommentInputBool();
	setCommentInput("");
	
	if(userInfo.isLoggedIn)
	{
		const sendComment = {
			table_name: board_name,
			idx: idx,
			comment: commentInput
		}

		console.log("sendComment : ", sendComment);
	
		// 여기서 디스패치로~
		dispatch(commentModify(sendComment))
		.then(data => 
		{
			console.log("data : ", data);

			// 여기서 디스패치로~
			dispatch(commentList(board_name, bbs_idx))
			.then(data => 
			{
				console.log("data : ", data);
			})
			.catch(e => {
				console.log(e);
			});
		})
		.catch(e => {
			console.log(e);
		});
	}
	else
	{
		alert("로그인 후에 댓글 수정을 할 수 있습니다.");
		return;
	}
}

const replyReturn = (step, title) =>
{
	let n = 0;
	let returnValue = "";
	let spaceValue = <>&nbsp;&nbsp;&nbsp;&nbsp;</>;
	
	while (n < step) 
	{
		n++;
		returnValue += "[RE:]";
	}

	if (step > 0)
		return <>{spaceValue}{returnValue + title}</>;
	else
		return <>{returnValue + title}</>;
}

useEffect(() => {

	if (board_name === "news")
	{
		setBoardName("새소식");
	}
	else if (board_name === "info")
	{
		setBoardName("자료실");
	}

	// 여기서 디스패치로~
	dispatch(bbsSelect(board_name, idx))
	.then(data => 
	{
		console.log("data : ", data);
	})
	.catch(e => {
		console.log(e);
	});

	// 여기서 디스패치로~
	dispatch(commentList(board_name, idx))
	.then(data => 
	{
		console.log("data : ", data);
	})
	.catch(e => {
		console.log(e);
	});
	
	return () => {      // 언마운트
		console.log("언마운트!");
	}

}, [])

  return (
    <>
      	{/* Header 분리됨 MainLayout Header에 있음 */}
		  <div className={styles.new_view_area}>
			<div className={styles.inner}>
				<span className={styles.tit}>{boardName || ""}</span>
				<div className={`${styles.btn_area} ${styles.btn_area_top} ${styles.cf}`}>
				{ common.checkAuth(userInfo) ? <>
					<a href={`/BoardReply/${board_name}/${idx}/${prevPage}`} className={styles.reply}>답글</a>
					{ 
						searchCondition !== "" && searchKeyword !== "" ?
						<a href={`/BoardModify/${board_name}/${idx}/${prevPage}/${searchCondition}/${searchKeyword}`} className={styles.fix}>수정</a>
						:
						<a href={`/BoardModify/${board_name}/${idx}/${prevPage}`} className={styles.fix}>수정</a>
					}
					<a href="#" className={styles.delete} onClick={onDelete}>삭제</a>
					</>
				: "" }
				</div>
				<div className={styles.wrap}>
					<div className={`${styles.title} ${styles.cf}`}>
						<div className={styles.tit}>
							<span className={styles.info}>제목</span>
						</div>
						<div className={styles.cont}>
							<p className={styles.enter}>{bbs.select && bbs.select.title || ""}</p>
						</div>
					</div>
					<div className={`${styles.writer} ${styles.cf}`}>
						<div className={styles.tit}>
							<span className={styles.info}>작성자</span>
						</div>
						<div className={styles.cont}>
							<p className={styles.enter}>{bbs.select && bbs.select.name || ""}</p>
						</div>
					</div>
					<div className={`${styles.date} ${styles.cf}`}>
						<div className={styles.tit}>
							<span className={styles.info}>작성날짜</span>
						</div>
						<div className={styles.cont}>
							<p>{bbs.select && common.dateChangeReturn(bbs.select.reg_date) || ""}</p>
						</div>
					</div>
					<pre className={styles.cont_txt}>{bbs.select && bbs.select.contents || ""}</pre>
				</div>
				<div className={`${styles.btn_area} ${styles.btn_area_bottom} ${styles.cf}`}>
					{
						bbs.pre !== undefined ? <a href={`/BoardView/${board_name}/${bbs.pre && bbs.pre || 0}/${prevPage}`} className={styles.prev}>이전글</a> : ""
					}
					{ searchCondition !== "" && searchKeyword !== "" 
						? 
						<a href={`/BoardList/${board_name}/${prevPage}/${searchCondition}/${searchKeyword}`} className={styles.list}>목록</a>
						: 
						<a href={`/BoardList/${board_name}/${prevPage}`} className={styles.list}>목록</a>
					}
					{
						bbs.next !== undefined ? <a href={`/BoardView/${board_name}/${bbs.next && bbs.next || 0}/${prevPage}`} className={styles.next}>다음글</a> : ""
					}
				</div>
				<div className={styles.comment_area}>
					<span className={styles.tit}>댓글<span>(<span className={styles.reNum}>{comment && comment.length}</span>)</span></span>
					<div className={styles.input_area}>
						<textarea name="comment" id={styles.comment_input} maxLength="500" onChange={onCommentChange} value={submitComment}></textarea>
						<a href="#" onClick={onCommentSubmit}>등록</a>
					</div>
					<div className={styles.comment_wrap}>
						<ul className={styles.cf}>
							{comment && comment.map((row, index) => (
							<li key={index}>
								<div>
									<div>
										<div className={styles.img}><img src="/img/my.png" alt="프로필"/></div>
										<span className={styles.name}>{row.name}</span>
										<span className={styles.date}>{common.dateChangeReturn(row.reg_date)}</span>
										{commentInputBool === index ? 
										<>
										<div className={styles.comment_fix_wrap}>
											<textarea 
												name="comment" 
												id={styles.comment_fix_txtbox} 
												maxLength="500" 
												value={commentInput} 
												onChange={onCommentEditChange}
											/> 
											<div className={styles.comment_fix_btn}>
												<a href="#" className={styles.cancle} onClick={(event)=>handleCommentCancelClick(event)}>취소</a>
												<a href="#" className={styles.ok} onClick={(event)=>onCommentModifySubmit(event, row.idx, idx)}>등록</a>
											</div>
										</div>
										</>
										: 
										<>
										<div className={styles.comment}>
											<pre>{row.comment}</pre>
										</div>
										<div className={`${styles.comment_btn_area} ${styles.cf}`}>
											<button type="button" className={styles.fix} onClick={(event)=>handleCommentModifyClick(event, index, row.comment, row.mem_idx)}>수정</button>
											<button type="button" className={styles.delete} onClick={(event)=>handleCommentDeleteClick(event, row.idx, idx, row.mem_idx)}>삭제</button>
										</div>
										</>
										}
									</div>
								</div>
							</li>
							))}
						</ul>
					</div>
				</div>
			</div>
		</div>
		{/* Footer 분리됨 MainLayout Footer에 있음 */}
    </>
  );
}