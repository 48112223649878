import http from "../http-common";

const knowledgeCreate = data => {
  return http.post("knowledge", data);
};

const knowledgeGetAll = () => {
  return http.get("knowledge");
};

const knowledgeDelete = data => {
  return http.delete("knowledge", {data : {ki_idx : data}});
};

const knowledgeModify = data => {
  return http.put("knowledge", data);
};

const knowledgeSelect = data => {
  return http.get(`knowledge/select/${data}`);
};

const KnowledgeService = {
  knowledgeCreate,
  knowledgeGetAll,
  knowledgeDelete,
  knowledgeModify,
  knowledgeSelect,
};

export default KnowledgeService;
