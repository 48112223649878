import {
  LIST_COUPON,
  CREATE_COUPON,
  MODIFY_COUPON,
  DELETE_COUPON,
  SELECT_COUPON,
  EXCEL_DOWNLOAD_COUPON,
  DETAIL_LIST_COUPON,
  MANUAL_ADD_COUPON,
  REGISTER_COUPON,
  USER_LIST_COUPON,
  USER_SELECT_COUPON,
  USER_ALL_COUPON,
  REGISTER_BULK_COUPON,
} from "./types";

import CouponService from "../services/CouponService";

export const couponList = data => async (dispatch) => {
  
  try {
    
    const res = await CouponService.couponGetAll(data);

    // console.log("res : ", res);

    dispatch({
      type: LIST_COUPON,
      payload: res.data.data,
    });

    return Promise.resolve(res.data.data);

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};

export const couponCreate = (data) => async (dispatch) => {
  
  try {
    
    const res = await CouponService.couponCreate(data);

    // console.log("res : ", res);

    dispatch({
      type: CREATE_COUPON,
      payload: res.data,
    });

    return Promise.resolve(res.data);

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};

export const couponModify = (data) => async (dispatch) => {
  
  try {
    
    const res = await CouponService.couponModify(data);

    // console.log("res : ", res);

    dispatch({
      type: MODIFY_COUPON,
      payload: res.data,
    });

    return Promise.resolve(res.data);

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};

export const couponDelete = (data) => async (dispatch) => {
  
  try {

    const res = await CouponService.couponDelete(data);

    // console.log("res : ", res);

    if (res.data.success === false)
    {
      alert(res.data.msg);
      return Promise.resolve(res.data);
    }
    else
    {
      dispatch({
        type: DELETE_COUPON,
        payload: res.data,
      });
  
      return Promise.resolve(res.data);
    }

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};

export const couponSelect = data => async (dispatch) => {
  
  try {
    
    const res = await CouponService.couponSelect(data);

    // console.log("res : ", res);

    dispatch({
      type: SELECT_COUPON,
      payload: res.data.data,
    });

    return Promise.resolve(res.data.data);

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};

export const couponExcelDownload = data => async (dispatch) => {
  
  try {
    
    const res = await CouponService.couponExcelDownload(data);

    // console.log("res : ", res);

    dispatch({
      type: EXCEL_DOWNLOAD_COUPON,
      payload: res.data.data,
    });

    return Promise.resolve(res.data.data);

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};

export const couponDetailList = data => async (dispatch) => {
  
  try {
    
    const res = await CouponService.couponDetailGetAll(data);

    // console.log("res : ", res);

    dispatch({
      type: DETAIL_LIST_COUPON,
      payload: res.data.data,
    });

    return Promise.resolve(res.data.data);

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};

export const couponAddManual = (data) => async (dispatch) => {
  
  try {
    
    const res = await CouponService.couponAddManual(data);

    // console.log("res : ", res);

    dispatch({
      type: MANUAL_ADD_COUPON,
      payload: res.data,
    });

    return Promise.resolve(res.data);

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};

export const couponRegister = (data) => async (dispatch) => {
  
  try {
    
    const res = await CouponService.couponRegister(data);

    // console.log("res : ", res);

    dispatch({
      type: REGISTER_COUPON,
      payload: res.data,
    });

    return Promise.resolve(res.data);

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};

export const couponBulkRegister = (data) => async (dispatch) => {
  
  try {
    
    const res = await CouponService.couponBulkRegister(data);

    // console.log("res : ", res);

    dispatch({
      type: REGISTER_BULK_COUPON,
      payload: res.data,
    });

    return Promise.resolve(res.data);

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};

export const couponUserList = data => async (dispatch) => {
  
  try {
    
    const res = await CouponService.couponUserGetAll(data);

    // console.log("res : ", res);

    dispatch({
      type: USER_LIST_COUPON,
      payload: res.data.data,
    });

    return Promise.resolve(res.data.data);

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};

export const couponUserSelect = data => async (dispatch) => {
  
  try {
    
    const res = await CouponService.couponUserSelect(data);

    // console.log("res : ", res);

    dispatch({
      type: USER_SELECT_COUPON,
      payload: res.data.data,
    });

    return Promise.resolve(res.data.data);

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};

export const couponAllList = data => async (dispatch) => {
  
  try {
    
    const res = await CouponService.couponCommonAll(data);

    // console.log("res : ", res);

    dispatch({
      type: USER_ALL_COUPON,
      payload: res.data.data,
    });

    return Promise.resolve(res.data.data);

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};
