import * as React from 'react';
import { useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import styles from "../css/common.module.css";
import $ from 'jquery';

import { useTranslation, Trans } from 'react-i18next'; // 1. react-i18next import 

export default function Faq() {

	const authState = useSelector(state => state.auth);
    // console.log("authState : ", authState);

	const dispatch = useDispatch();	

	const { t, i18n } = useTranslation(); // 3. useTranslation hook 선언

	let language = localStorage.getItem("language");
    // console.log("axios.interceptors.request.language : ", language);

	let faqArr=[];

	if (language === 'en')
	{
		faqArr=[
			,{"index":1,"categori":"학습(앱)","title":"How do I download YAM-E?","answer":"YAM-E supports both Android and iOS. Android will be available in the Play Store from September, and iOS is preparing to be released. <br>If you receive an e-mail from the parent information management on my page, you can receive news of the release."}
			,{"index":2,"categori":"학습(앱)","title":"Should YAM-E be connected to the Internet?","answer":"YAM-E is an app that you can't use unless you have an Internet connection. Please check the internet before using it."}
			,{"index":3,"categori":"학습(앱)","title":"What are the specifications for Android?","answer":"Device used: Galaxy S5 to Galaxy S22 <br>Operating System: OS Android 5.1 to Android 12"}
			,{"index":4,"categori":"학습(앱)","title":"Is there a specific device for simple login?","answer":"Google login is only available on Android and Apple login is only available on Apple. Please select the appropriate simple login for the device to run the YAM-E coding app."}
			,{"index":5,"categori":"홈페이지","title":"How do I download the membership manual?","answer":"I share the membership manual. Download it.  <a href='https://yamecoding.com/download/YAME%20%EC%82%AC%EC%9A%A9%EA%B0%80%EC%9D%B4%EB%93%9C(%EC%95%B1%EB%8B%A4%EC%9A%B4%EB%A1%9C%EB%93%9C%20%EB%B0%8F%20%ED%9A%8C%EC%9B%90%EA%B0%80%EC%9E%85)%20Ver%201.0.pdf' download> [Download]</a> <br>Click to open the window and see the document and to download it to your PC, right-click the link and click Save As."}
			,{"index":6,"categori":"홈페이지","title":"I can't log in after signing up.","answer":"After signing up for membership, you will receive a verification email to the email you signed up for. You need to verify your email to log in."}
			,{"index":7,"categori":"홈페이지","title":"How do I download the coupon registration and usage?","answer":"I share the coupon registration and usage manual. Download it.  <a href='https://yamecoding.com/download/YAME%20%EC%82%AC%EC%9A%A9%EA%B0%80%EC%9D%B4%EB%93%9C(%EC%BF%A0%ED%8F%B0%EB%93%B1%EB%A1%9D%20%EB%B0%A9%EB%B2%95%EA%B3%BC%20%EC%82%AC%EC%9A%A9)%20Ver%201.0.pdf' download> [Download]</a> <br>Click to open the window and see the document and to download it to your PC, right-click the link and click Save As."}
			,{"index":8,"categori":"홈페이지","title":"How do I download the paid payment manual?","answer":"I'm sharing the manual on how to pay. Download it.  <a href='https://yamecoding.com/download/YAME%20%EC%82%AC%EC%9A%A9%EA%B0%80%EC%9D%B4%EB%93%9C(%EC%9C%A0%EB%A3%8C%EA%B2%B0%EC%A0%9C%20%EB%B0%A9%EB%B2%95)%20Ver%201.0.pdf' download> [Download]</a> <br>Click to open the window and see the document and to download it to your PC, right-click the link and click Save As."}
			,{"index":9,"categori":"홈페이지","title":"Is there no study report for free learners?","answer":"Learning reports are for paid users. Provides information about paid users as a report."}
			,{"index":10,"categori":"결제","title":"How can I make a group purchase?","answer":"More than 30 learners must use the group purchase. For group purchases, please contact us by email or phone at the bottom of the website."}
			,{"index":11,"categori":"결제","title":"There's a problem with the payment. Where do I ask?","answer":"If there is a payment error, please contact Inicis. If you have any problems using Yami after the payment is successful, please contact us by email or phone at the bottom of the website."}		
			,{"index":12,"categori":"기타","title":"I'd like to ask YAM-E directly and get feedback.","answer":"If you have any questions, you can log in and log in to leave them. I'll answer it. Please go to the link. <a href='/Qna'> [Shortcut to Contact]</a>"}		
			,{"index":13,"categori":"결제","title":"How can I get a refund?","answer":"You can get a refund right away if you didn't use Yami within a week from the date of payment. My Page > You can get a refund from the payment information."}
		];
	}
	else
	{
		faqArr=[
			,{"index":1,"categori":"학습(앱)","title":"야미는 어떻게 다운로드 하나요?","answer":"야미는 Android와 ios를 모두 지원합니다. Android는 9월부터 플레이스토어에서 사용가능하시고 ios는 출시 준비 중입니다. <br>마이페이지의 학부모 정보관리에서 이메일 수신을 해주시면 출시 소식을 받으실 수 있습니다."}
			,{"index":2,"categori":"학습(앱)","title":"야미는 인터넷에 연결되어야 하나요?","answer":"야미는 인터넷에 연결이 되지 않으면 사용할 수 없는 앱입니다. 반드시 인터넷을 확인하시고 사용해주세요."}
			,{"index":3,"categori":"학습(앱)","title":"안드로이드 사용 스펙은 어떻게 되나요?","answer":"사용기기 : galaxy S5 ~ galaxy S22까지 <br>운영체제 : OS Android 5.1 ~ Android 12까지"}
			,{"index":4,"categori":"학습(앱)","title":"간편 로그인은 사용기기가 정해져 있나요?","answer":"간편 로그인 중 구글 로그인은 안드로이드에서만 사용 가능하고 애플 로그인은 애플에서만 사용 가능합니다. 야미 코딩 앱을 실행할 기기에 맞는 간편 로그인을 선택해 주세요."}
			,{"index":5,"categori":"홈페이지","title":"회원가입 메뉴얼 다운로드 어떻게 하나요?","answer":"회원가입 메뉴얼 공유드립니다. 다운로드 하세요.  <a href='https://yamecoding.com/download/YAME%20%EC%82%AC%EC%9A%A9%EA%B0%80%EC%9D%B4%EB%93%9C(%EC%95%B1%EB%8B%A4%EC%9A%B4%EB%A1%9C%EB%93%9C%20%EB%B0%8F%20%ED%9A%8C%EC%9B%90%EA%B0%80%EC%9E%85)%20Ver%201.0.pdf' download> [다운로드]</a> <br>클릭하시면 창이 열리면서 문서가 보이고 PC에 다운받으실려면 링크에 마우스 오른쪽 버튼을 누르시고 다른이름으로 저장하기를 누르세요."}
			,{"index":6,"categori":"홈페이지","title":"회원가입 후 로그인이 안됩니다.","answer":"회원가입 후에 가입하신 이메일로 인증메일이 갑니다. 이메일 인증을 해주셔야 로그인이 됩니다."}
			,{"index":7,"categori":"홈페이지","title":"쿠폰등록과 사용법은 다운로드 어떻게 하나요?","answer":"쿠폰등록과 사용법 메뉴얼 공유드립니다. 다운로드 하세요.  <a href='https://yamecoding.com/download/YAME%20%EC%82%AC%EC%9A%A9%EA%B0%80%EC%9D%B4%EB%93%9C(%EC%BF%A0%ED%8F%B0%EB%93%B1%EB%A1%9D%20%EB%B0%A9%EB%B2%95%EA%B3%BC%20%EC%82%AC%EC%9A%A9)%20Ver%201.0.pdf' download> [다운로드]</a> <br>클릭하시면 창이 열리면서 문서가 보이고 PC에 다운받으실려면 링크에 마우스 오른쪽 버튼을 누르시고 다른이름으로 저장하기를 누르세요."}
			,{"index":8,"categori":"홈페이지","title":"유료결제방법 메뉴얼 다운로드 어떻게 하나요?","answer":"유료결제방법 메뉴얼 공유드립니다. 다운로드 하세요.  <a href='https://yamecoding.com/download/YAME%20%EC%82%AC%EC%9A%A9%EA%B0%80%EC%9D%B4%EB%93%9C(%EC%9C%A0%EB%A3%8C%EA%B2%B0%EC%A0%9C%20%EB%B0%A9%EB%B2%95)%20Ver%201.0.pdf' download> [다운로드]</a> <br>클릭하시면 창이 열리면서 문서가 보이고 PC에 다운받으실려면 링크에 마우스 오른쪽 버튼을 누르시고 다른이름으로 저장하기를 누르세요."}
			,{"index":9,"categori":"홈페이지","title":"무료 학습자는 학습 리포트가 안나오나요?","answer":"학습 리포트는 유료 사용자용입니다. 유료 사용자의 정보를 리포트로 제공합니다."}
			,{"index":10,"categori":"결제","title":"단체 구매는 어떻게 하나요?","answer":"30명 이상의 학습자는 단체구매를 이용하셔야 합니다. 단체 구매는 홈페이지 하단의 문의메일이나 전화로 문의 부탁드립니다."}
			,{"index":11,"categori":"결제","title":"결제에 문제가 생겼어요. 어디에 문의하나요?","answer":"결제 오류가 발생하시면 이니시스에 문의부탁드립니다. 만약 결제성공 후 야미의 이용에 문제가 생기시면 홈페이지 하단의 문의메일이나 전화로 문의 부탁드립니다."}		
			,{"index":12,"categori":"기타","title":"야미에 직접 문의하고 하고 피드백 받고 싶어요.","answer":"문의사항은 로그인 및 로그인으로 문의를 남길 수 있습니다. 답변을 해드립니다. 링크로 이동해주세요. <a href='/Qna'> [문의사항 바로가기]</a>"}		
			,{"index":13,"categori":"결제","title":"환불은 어떻게 받아요?","answer":"환불은 결제하신 날짜로 부터 일주일 안에 야미를 사용하지 않았으면 바로 환불 가능합니다. 마이페이지 > 결제정보에서 환불하시면 됩니다."}
		];
	}

	useEffect(() => {

		// 여기에서 jQuery를 초기화 합니다.
		$(document).ready(function(){

			// console.log("jquery ready!");
			
			//검색창 엔터키 이벤트
			$(`#${styles.faq_input_id}`).on('keydown', function(e)
			{
				if (e.code === 'Enter')
				{
					$(`.${styles.faq_srch_area} button`)[0].click();
				}
			});

			// FAQ 리스트 출력 변수
			let outputHtml = "";
			
			// FAQ 리스트 반복문 (전체 출력)className={styles.faq_tab}
			faqArr.map((x)=> 
			{
				// console.log(x.categori);
				outputHtml += `<li class=${styles.list}>
					<div class=${styles.question_area}>
						<span>Q</span>
						<p>${x.title}</p>
						<img src="assets/faq_arrow.png" alt="arrow"/>
					</div>
					<div class=${styles.answer_area}>
						<p class=${styles.answer}>${x.answer}</p>
					</div>
				</li>`;
			})

			// FAQ 리스트 출력
			// console.log('outputHtml : ', outputHtml);
			$(`.${styles.faq_list_wrap}`).html(outputHtml);

			//카테고리(셀렉트 값) 변경될때마다 해당 질문 뿌려주기
			$(`#${styles.faq_categori_select}`).on('change', function()
			{
				let th=$(this).val();
				$(`#${styles.faq_input_id}`).val("");

				outputHtml = "";
				$(`.${styles.faq_list_wrap}`).html(outputHtml);

				if(th==="결제")
				{
					faqArr.filter((x)=> x.categori==="결제").map((x)=> 
					{
						// console.log(x.categori);
						outputHtml += `<li class=${styles.list}>
							<div class=${styles.question_area}>
								<span>Q</span>
								<p>${x.title}</p>
								<img src="assets/faq_arrow.png" alt="arrow"/>
							</div>
							<div class=${styles.answer_area}>
								<p class=${styles.answer}>${x.answer}</p>
							</div>
						</li>`;
					})
				}
				else if(th==="학습(앱)")
				{
					faqArr.filter((x)=> x.categori==="학습(앱)").map((x)=> 
					{
						// console.log(x.categori);
						outputHtml += `<li class=${styles.list}>
							<div class=${styles.question_area}>
								<span>Q</span>
								<p>${x.title}</p>
								<img src="assets/faq_arrow.png" alt="arrow"/>
							</div>
							<div class=${styles.answer_area}>
								<p class=${styles.answer}>${x.answer}</p>
							</div>
						</li>`;
					})
				}
				else if(th==="홈페이지")
				{
					faqArr.filter((x)=> x.categori==="홈페이지").map((x)=> 
					{
						// console.log(x.categori);
						outputHtml += `<li class=${styles.list}>
							<div class=${styles.question_area}>
								<span>Q</span>
								<p>${x.title}</p>
								<img src="assets/faq_arrow.png" alt="arrow"/>
							</div>
							<div class=${styles.answer_area}>
								<p class=${styles.answer}>${x.answer}</p>
							</div>
						</li>`;
					})
				}
				else if(th==="기타")
				{
					faqArr.filter((x)=> x.categori==="기타").map((x)=> 
					{
						// console.log(x.categori);
						outputHtml += `<li class=${styles.list}>
							<div class=${styles.question_area}>
								<span>Q</span>
								<p>${x.title}</p>
								<img src="assets/faq_arrow.png" alt="arrow"/>
							</div>
							<div class=${styles.answer_area}>
								<p class=${styles.answer}>${x.answer}</p>
							</div>
						</li>`;
					})
				}
				else
				{
					faqArr.map((x)=> 
					{
						// console.log(x.categori);
						outputHtml += `<li class=${styles.list}>
							<div class=${styles.question_area}>
								<span>Q</span>
								<p>${x.title}</p>
								<img src="assets/faq_arrow.png" alt="arrow"/>
							</div>
							<div class=${styles.answer_area}>
								<p class=${styles.answer}>${x.answer}</p>
							</div>
						</li>`;
					})
				};
				
				// FAQ 리스트 출력
				// console.log('outputHtml : ', outputHtml);
				$(`.${styles.faq_list_wrap}`).html(outputHtml);

			});


			//질문 영역 클릭시 답변 영역 보이도록, 답변은 하나만 보이도록
			$(document).on('click',`.${styles.faq_list_wrap} li`,function()
			{
				let tg=$(this);
				let activeList=$(`.${styles.faq_list_wrap}`).find(`li.${styles.on}`);
				// console.log('tg :', tg);
				// console.log('activeList :', activeList);
				
				if(activeList.index()===tg.index())
				{
					//현재 활성화된 질문을 또 클릭했을 경우
					$(`.${styles.faq_list_wrap} li`).removeClass(`${styles.on}`);
				}
				else
				{
					//현재 활성화된 질문이 아닌 다른 질문을 클릭한 경우
					$(`.${styles.faq_list_wrap} li`).removeClass(`${styles.on}`);
					tg.addClass(`${styles.on}`);
				};
			});



		});

	}, [$]);


	//FAQ 검색기능
	function faqSearch()
	{
		let keyword=$(`#${styles.faq_input_id}`).val();
		let srchSelect=$(`#${styles.faq_srch_select}`).val();
		// console.log(srchSelect);
		
		if(keyword==="")
		{
			//검색어를 입력하지 않았을때
			// alert('검색어를 입력해주세요.');
			alert(`${t("FQA.alert.string1")}`);
		}
		else
		{
			if(srchSelect===null)
			{
				//검색 카테고리를 선택하지 않았을때
				// alert('검색하실 내용을 선택해주세요.');
				alert(`${t("FQA.alert.string2")}`);
			}
			else if(srchSelect==="1")
			{
				//검색 카테고리가 제목 일때
				$(`#${styles.faq_categori_select}`).val("");
				let outputHtml = "";
		
				if(faqArr.filter((x)=> x.title.includes(keyword)===true).length>0)
				{
					faqArr.filter((x)=> x.title.includes(keyword)===true).map((x)=> 
					{
						// console.log(x.categori);
						outputHtml += `<li class=${styles.list}>
							<div class=${styles.question_area}>
								<span>Q</span>
								<p>${x.title}</p>
								<img src="assets/faq_arrow.png" alt="arrow"/>
							</div>
							<div class=${styles.answer_area}>
								<p class=${styles.answer}>${x.answer}</p>
							</div>
						</li>`;
					})
				}
				else
				{
					// console.log('검색 결과 없음');
					// outputHtml = `<li class=${styles.no_result}>검색결과가 없습니다</li>`;
					outputHtml = `<li class=${styles.no_result}>${t('FQA.string2')}</li>`;
				};	
		
				$(`.${styles.faq_list_wrap}`).html(outputHtml);
			}
			else if(srchSelect==="2")
			{
				//검색 카테고리가 내용 일때
				$(`#${styles.faq_categori_select}`).val("");
				let outputHtml = "";
				
				if(faqArr.filter((x)=> x.answer.includes(keyword)===true).length>0)
				{
					faqArr.filter((x)=> x.answer.includes(keyword)===true).map((x)=> 
					{
						// console.log(x.categori);
						outputHtml += `<li class=${styles.list}>
							<div class=${styles.question_area}>
								<span>Q</span>
								<p>${x.title}</p>
								<img src="assets/faq_arrow.png" alt="arrow"/>
							</div>
							<div class=${styles.answer_area}>
								<p class=${styles.answer}>${x.answer}</p>
							</div>
						</li>`;
					})
				}
				else
				{
					// console.log('검색 결과 없음');
					// outputHtml = `<li class=${styles.no_result}>검색결과가 없습니다</li>`;
					outputHtml = `<li class=${styles.no_result}>${t('FQA.string2')}</li>`;
				};	
		
				$(`.${styles.faq_list_wrap}`).html(outputHtml);
			}
			else
			{
				$(`#${styles.faq_categori_select}`).val("");
				let outputHtml = "";
				// outputHtml = `<li class=${styles.no_result}>검색결과가 없습니다</li>`;
				outputHtml = `<li class=${styles.no_result}>${t('FQA.string2')}</li>`;
				$(`.${styles.faq_list_wrap}`).html(outputHtml);
			};
		};

	};

  return (
    <>
      	{/* Header 분리됨 MainLayout Header에 있음 */}

		  <div className={styles.faq_top_line_banner}>
			<div className={styles.inner}>
				<span>FAQ</span>
			</div>
		</div>
		<div className={styles.faq_tab}>
			<div className={styles.inner}>
				<span className={styles.faq_tit}>{/* 자주 묻는 질문 */}{t('FQA.title')}</span>
				<p className={styles.faq_txt}>{/* 궁금한 질문을 찾아 클릭하면 답변을 확인할 수 있습니다. */}{t('FQA.string1')}</p>
				<div className={styles.faq_srch_area}>
					<select name="faq_srch_categori" id={styles.faq_srch_select} defaultValue={'DEFAULT'}>
						<option value="DEFAULT" disabled>{/* 선택 */}{t('Common.Select')}</option>
						<option value="1">{/* 제목 */}{t('Common.Title')}</option>
						<option value="2">{/* 내용 */}{t('Common.Content')}</option>
					</select>
					<input type="text" id={styles.faq_input_id}/>
					<button type="button" onClick={faqSearch}>{/* 검색 */}{t('Common.Search')}</button>
				</div>
				<div className={styles.faq_area}>
					<div className={styles.faq_categori_area}>
						<select name="faq_categori" id={styles.faq_categori_select} defaultValue="전체">
							<option value="" disabled>{/* 선택 */}{t('Common.Select')}</option>
							<option value="전체">{/* 전체 */}{t('Common.Total')}</option>
							<option value="결제">{/* 결제 */}{t('FQA.category.cate1')}</option>
							<option value="학습(앱)">{/* 학습(앱) */}{t('FQA.category.cate2')}</option>
							<option value="홈페이지">{/* 홈페이지 */}{t('FQA.category.cate3')}</option>
							<option value="기타">{/* 기타 */}{t('FQA.category.cate4')}</option>
						</select>
					</div>
					<ul className={styles.faq_list_wrap}></ul>
					{/* <ul className={styles.faq_list_pagebtn_area}>
						<li className={`${styles.arrow_btn} ${styles.first}`}><a href="#"><img src="assets/first_btn@2x.png" alt="첫번째 페이지로 이동"/></a></li>
						<li className={`${styles.arrow_btn} ${styles.prev}`}><a href="#"><img src="assets/prev_btn@2x.png" alt="이전 페이지로 이동"/></a></li>
						<li className={styles.on}><a href="#">1</a></li>
						<li><a href="#">2</a></li>
						<li><a href="#">3</a></li>
						<li><a href="#">4</a></li>
						<li><a href="#">5</a></li>
						<li><a href="#">6</a></li>
						<li><a href="#">7</a></li>
						<li><a href="#">8</a></li>
						<li><a href="#">9</a></li>
						<li><a href="#">10</a></li>
						<li className={`${styles.arrow_btn} ${styles.next}`}><a href="#"><img src="assets/next_btn@2x.png" alt="다음 페이지로 이동"/></a></li>
						<li className={`${styles.arrow_btn} ${styles.last}`}><a href="#"><img src="assets/last_btn@2x.png" alt="마지막 페이지로 이동"/></a></li>
					</ul> */}
				</div>
			</div>
		</div>
		
		{/* Footer 분리됨 MainLayout Footer에 있음 */}
    </>
  );
}