import {
  LIST_REFUND,
} from "./types";

import RefundService from "../services/RefundService";

export const refundList = () => async (dispatch) => {
  
  try {
    
    const res = await RefundService.refundGetAll();

    // console.log("res : ", res);

    dispatch({
      type: LIST_REFUND,
      payload: res.data.data,
    });

    return Promise.resolve(res.data.data);

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};

