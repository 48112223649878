import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

import UserModifyPopup from '../UserModifyPopup';

export default function BasicCard(props) {

  const profile = props.profile;

  // 회원유형 (1) admin, (2) public, (3) social, (4) group
  let authString = "";

  if ( profile && profile.mem_type === 2)
    authString = "일반 사용자";
  else if( profile && profile.mem_type === 1)
    authString = "관리자";
  else if( profile && profile.mem_type === 4)
    authString = "단체 사용자";
  else if( profile && profile.mem_type === 3)
    authString = "소셜 사용자";

  // 단체 유형 (1) 개인사업자, (2) 영리법인, (3) 비영리법인, (4) 기타공식단체, (5) 비공식단체(예: 동아리)
  let companyTypeString = "";

  if ( profile && profile.mg_type === 1)
    companyTypeString = "개인사업자";
  else if( profile && profile.mg_type === 2)
    companyTypeString = "영리법인";
  else if( profile && profile.mg_type === 3)
    companyTypeString = "비영리법인";
  else if( profile && profile.mg_type === 4)
    companyTypeString = "기타공식단체";
  else if( profile && profile.mg_type === 5)
    companyTypeString = "비공식단체(예: 동아리)";

  const onReloadWindow = () => {
    window.location.reload();
    // if (auth.isLoggedIn)
    // {
    //     const boardListSend = {
    //       board_page : board_page,
    //       condition : searchCondition,
    //       keyword : searchKeyword,
    //     }

    //     console.log("dispatch userListAll");
    //     console.log("usersListSend : ", boardListSend);
    //     dispatch(userListAll(boardListSend))
    //     .then(data => 
    //     {
    //         console.log("data : ", data);
    //     })
    //     .catch(e => {
    //         console.log(e);
    //     });
    // }
  }

  return (
    <Card sx={{ minWidth: 275 }}>
      <CardContent>
        <Typography variant="body" sx={{ lineHeight: '40px'}}>
            이메일 : {profile && profile.mem_email}
            <br />
            이름 : {profile && profile.mem_name}
            <br />
            닉네임 : {profile && profile.mem_nickname}
            <br />
            권한 : {profile && authString}
            <br />
            핸드폰 : {profile && profile.mem_phone}
            <br />
            상태 : {profile && profile.mem_status === 1 ? "사용중" : "정지중"}
            <br />
            {
              (()=> {
                if(profile && profile.mem_type === 4)
                {
                  return (
                    <>
                    회사 이름 : {profile && profile.mg_name}
                    <br />
                    사업자번호 : {profile && profile.mg_biz_code}
                    <br />
                    회사 전화번호 : {profile && profile.mg_phone}
                    <br />
                    팩스 번호 : {profile && profile.mg_fax}
                    <br />
                    법인형태 : {profile && companyTypeString}
                    <br />
                    우편번호 : {profile && profile.mg_addr_zip}
                    <br />
                    주소 : {profile && profile.mg_addr_part1} {profile && profile.mg_addr_detail}
                    <br />
                    </>
                  );
                }
              })()
            }
            등록일 : {profile && profile.mem_reg_date}
            <br />
            최근 로그인 : {profile && profile.mem_lastlogin_date}
        </Typography>
      </CardContent>
      <CardActions>
        <UserModifyPopup buttonText="수정" userData={profile} onReloadWindow={props.onReloadWindow} />
      </CardActions>
    </Card>
  );
}