import {
  CREATE_KNOWLEDGE_CATEGORY,
  LIST_KNOWLEDGE_CATEGORY,
  DELETE_KNOWLEDGE_CATEGORY,
  MODIFY_KNOWLEDGE_CATEGORY,
  SELECT_KNOWLEDGE_CATEGORY,
} from "../actions/types";

const initialState = [];

const knowledgeCategoryReducer = (knowledge_category = initialState, action) => {
  const { type, payload } = action;

  // console.log("knowledgeCategoryReducer Call!");
  // console.log("type : ", type);
  // console.log("payload : ", payload);

  switch (type) {
    
    case CREATE_KNOWLEDGE_CATEGORY:
      return [];

    case LIST_KNOWLEDGE_CATEGORY:
      return payload;

    case SELECT_KNOWLEDGE_CATEGORY:
      return payload;

    case MODIFY_KNOWLEDGE_CATEGORY:
      return knowledge_category.map((knowledge_category) => {
        if (knowledge_category.category_idx === payload.category_idx) {
          return {
            ...knowledge_category,
            ...payload,
          };
        } else {
          return knowledge_category;
        }
      });

    case DELETE_KNOWLEDGE_CATEGORY:
      return knowledge_category.filter(({ category_idx }) => category_idx !== payload.category_idx);

    default:
      return knowledge_category;
  }
};

export default knowledgeCategoryReducer;