import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';

import TextareaAutosize from '@mui/material/TextareaAutosize';

import ImgFileUpload from '../../../common/ImgFileUpload';

import { useState } from "react";
import { useDispatch } from "react-redux";
import { couponModify } from "../../../actions/coupon";
import { useNavigate } from 'react-router-dom';

import * as common from "../../../lib";

export default function CouponModifyPopup(props) {

    const initialSendData = {
        idx: "",
        name: "", 
        desc: "",
        photo_file:null,
        photo_path:null,
        fileChange:false,
    };

  const [open, setOpen] = useState(false);
  const [sendData, setSendData] = useState(initialSendData);
  const [file, setFile] = useState(null);
  
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleInputChange = event => 
  {
    const { name, value } = event.target;
    // console.log("event target name : ", name);
    // console.log("event target value : ", value);
    
    setSendData({ ...sendData, [name]: value });
  };

    const handleImgFileChange = (file) => {
        // console.log("file : ", file);
        // setSendData({ ...sendData, file: file });
        setFile(file);
        setSendData({ ...sendData, fileChange: true });
    };

    const imageDelete = () => {

        // input file의 값을 초기화 합니다.
        let files = document.getElementsByName("imgFile");
        // console.log("files : ", files);
        files[0].value = "";
      
        // 미리보기 이미지도 초기화 합니다.
        setSendData({ ...sendData, photo_file: null });
        setSendData({ ...sendData, photo_path: null });
        setSendData({ ...sendData, fileChange: true });
    }

  const handleSubmit = (event) => {
    event.preventDefault();

    // console.log("sendData : ", sendData);

    const formData = new FormData();
    formData.append('imgFile', file);
    formData.append('name', sendData.name);
    formData.append('desc', sendData.desc);
    formData.append('idx', sendData.idx);

    // 여기서 디스패치로~
    dispatch(couponModify(formData))
    .then(data => 
    {
        // console.log("data : ", data);
        if (!data.success)
        {
            alert(data.msg);
            return;
        }
        else
        {
            alert("쿠폰 수정 완료");
            // navigate("/auth/login");

            // 데이터 초기화
            setSendData(initialSendData);

            props.onReloadWindow();
            handleClose();
        }
    })
    .catch(e => {
        console.log(e);
    });
    
  }

  const handleClickOpen = () => {

    // console.log(props.userData);

    const initialSendData = {
        idx: props.userData.cou_idx, 
        name: props.userData.cou_name, 
        desc: props.userData.cou_desc,
        photo_file:props.userData.cou_photo_file,
        photo_path:props.userData.cou_photo_path,
        fileChange:false,
    };

    setSendData(initialSendData);

    setOpen(true);
  };

  const handleClose = () => {

    // 데이터 초기화
    setSendData(initialSendData);
    setOpen(false);
  };

  return (
    <div>
      <Button variant="outlined" onClick={handleClickOpen}>
        {props.buttonText}
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>쿠폰 수정</DialogTitle>
        <DialogContent>
          <DialogContentText>
            * 쿠폰의 정보수정은 쿠폰명, 쿠폰설명, 이미지만 수정이 가능합니다.<br></br>
            * 쿠폰의 수정에서 반드시 쿠폰으로 가능한 미션의 설정을 해주세요.<br></br>
            (미션을 설정하지 않은 경우 그 쿠폰을 등록해도 미션을 수행 못합니다.)
          </DialogContentText>
            <Box
                component="span"
                sx={{
                    display: 'block',
                    p: 1,
                    m: 1,
                }}
            >
                <TextField
                    required
                    style={{ width: 500 }}
                    id="name"
                    label="쿠폰명"
                    name="name"
                    autoFocus
                    onChange={handleInputChange}
                    value={sendData.name}
                    size="small"
                />
            </Box>
            <Box
                component="span"
                sx={{
                    display: 'block',
                    p: 1,
                    m: 1,
                }}
            >
                <TextareaAutosize
                    aria-label="coupon desc"
                    minRows={5}
                    name="desc"
                    value={sendData.desc}
                    onChange={handleInputChange}
                    placeholder="쿠폰설명"
                    style={{ width: 500 }}
                />
            <br></br>
            <ImgFileUpload handleImgFileChange={handleImgFileChange}  width="400" />
            {sendData.fileChange ? "" : <><img src={common.returnImgSrc(sendData.photo_path, sendData.photo_file)} width="400"/> <Button onClick={imageDelete}>이미지 삭제</Button></> }
            </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>취소</Button>
          <Button onClick={handleSubmit}>수정</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}