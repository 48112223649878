import React from 'react';
import { useRef, useEffect } from "react";
import DaumPostcode from "react-daum-postcode";
import styles from "../css/common.module.css";
 
const PopupPostCode = (props) => {

  const el = useRef();

	// 우편번호 검색 후 주소 클릭 시 실행될 함수, data callback 용
    const handlePostCode = (data) => {
        let fullAddress = data.address;
        let extraAddress = ''; 
        
        if (data.addressType === 'R') {
          if (data.bname !== '') {
            extraAddress += data.bname;
          }
          if (data.buildingName !== '') {
            extraAddress += (extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName);
          }
          fullAddress += (extraAddress !== '' ? ` (${extraAddress})` : '');
        }
        // console.log(data)
        // console.log(fullAddress)
        // console.log(data.zonecode)
        props.setAddressFun(data, fullAddress, data.zonecode);
        props.onClose()
    }
 
    // const postCodeStyle = {
    //     display: "block",
    //     position: "relative",
    //     top: "10%",
    //     width: "600px",
    //     height: "600px",
    //     padding: "7px",
    //   };

    let isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent) ? true : false;
    let windowWith = 0
			
    if(!isMobile)     // PC
    {
      windowWith = 470;
    }
    else // mobile
    {
      windowWith = 'auto';
    }

    const postCodeStyle = 
    {
      height:470,
      width:windowWith
    };

    const handleCloseModal = e => {
      props.onClose()
    }

    useEffect(() => 
    {
      let classname = document.getElementById("dimmed_layer_wrapper");
      classname.addEventListener('click', handleCloseModal, false);
      // window.addEventListener('click', handleCloseModal);

      return () => 
      {
        // let classname = document.getElementById("dimmed_layer_wrapper");
        // classname.removeEventListener('click', handleCloseModal, false);
        // window.removeEventListener('click', handleCloseModal);
      }
    }, []);
 
    return(
        <div className={styles.dimmed_layer_wrapper} id="dimmed_layer_wrapper">
            <div className={styles.full_layer} ref={el}>
                <div className={styles.common_alert}> 
                    <DaumPostcode style={postCodeStyle} jsOptions={{ animation: true, hideMapBtn: true }} onComplete={handlePostCode} />
                    <div className={styles.popClosePosition}>
                      <div type='button' onClick={() => {props.onClose()}} className={styles.postCode_btn}>닫기</div>
                    </div>
                </div>
            </div>
        </div>
        // <div>
        //     <DaumPostcode style={postCodeStyle} jsOptions={{ animation: true, hideMapBtn: true }} onComplete={handlePostCode} />
        //     <button type='button' onClick={() => {props.onClose()}} className='postCode_btn'>닫기</button>
        // </div>
    )
}
 
export default PopupPostCode;