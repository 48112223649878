import {
  CREATE_CLASS,
  UPDATE_CLASS,
  DELETE_CLASS,
  LIST_CLASS,
  SELECT_CLASS,
  NAME_CHECK_CLASS,
  LIST_CLASS_ADMIN,
  MANAGEMENT_LIST_CLASS,
  MANAGEMENT_SET_CLASS,
  MANAGEMENT_STATUS_CLASS,
  MANAGEMENT_STATUS_SET_CLASS,
  MANAGEMENT_SAVED_LIST_CLASS,
  HOMEWORK_LIST_CLASS,
  HOMEWORK_SELECT_CLASS,
  HOMEWORK_INIT_CLASS,
  HOMEWORK_COMMENT_SELECT,
  HOMEWORK_COMMENT_INSERT,
  HOMEWORK_COMMENT_DELETE,
} from "./types";

import ClassService from "../services/ClassService";

export const classCreate = (data) => async (dispatch) => {
  
  try {
    
    const res = await ClassService.classCreate(data);

    // console.log("res : ", res);

    dispatch({
      type: CREATE_CLASS,
      payload: res.data,
    });

    return Promise.resolve(res.data);

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};

export const classModify = (data) => async (dispatch) => {
  
  try {
    
    const res = await ClassService.classModify(data);

    // console.log("res : ", res);

    dispatch({
      type: UPDATE_CLASS,
      payload: res.data,
    });

    return Promise.resolve(res.data);

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};

export const classDelete = (data) => async (dispatch) => {
  
  try {

    const res = await ClassService.classDelete(data);

    // console.log("res : ", res);

    if (res.data.success === false)
    {
      alert(res.data.msg);
      return Promise.resolve(res.data);
    }
    else
    {
      dispatch({
        type: DELETE_CLASS,
        payload: res.data,
      });
  
      return Promise.resolve(res.data);
    }

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};

export const classListAll = data => async (dispatch) => {
  
  try {
    
    const res = await ClassService.classListAll(data);

    // console.log("res : ", res);

    dispatch({
      type: LIST_CLASS,
      payload: res.data.data,
    });

    return Promise.resolve(res.data.data);

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};

export const classSelect = data => async (dispatch) => {
  
  try {
    
    const res = await ClassService.classSelect(data);

    // console.log("res : ", res);

    dispatch({
      type: SELECT_CLASS,
      payload: res.data.data,
    });

    return Promise.resolve(res.data.data);

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};

export const classNameCheck = data => async (dispatch) => {
  
  try {
    
    const res = await ClassService.classNameCheck(data);

    // console.log("res : ", res);

    dispatch({
      type: NAME_CHECK_CLASS,
      payload: res.data,
    });

    return Promise.resolve(res.data);

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};

export const classAdminListAll = data => async (dispatch) => {
  
  try {
    
    const res = await ClassService.classAdminListAll(data);

    // console.log("res : ", res);

    dispatch({
      type: LIST_CLASS_ADMIN,
      payload: res.data.data,
    });

    return Promise.resolve(res.data.data);

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};

export const classManagementListAll = data => async (dispatch) => {
  
  try {
    
    const res = await ClassService.classManagementListAll(data);

    // console.log("res : ", res);

    dispatch({
      type: MANAGEMENT_LIST_CLASS,
      payload: res.data.data,
    });

    return Promise.resolve(res.data.data);

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};

export const classManagementSet = (data) => async (dispatch) => {
  
  try {
    
    const res = await ClassService.classManagementSet(data);

    // console.log("res : ", res);

    dispatch({
      type: MANAGEMENT_SET_CLASS,
      payload: res.data,
    });

    return Promise.resolve(res.data);

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};

export const classManagementStatusGet = data => async (dispatch) => {
  
  try {
    
    const res = await ClassService.classManagementStatusGet(data);

    // console.log("res : ", res);

    dispatch({
      type: MANAGEMENT_STATUS_CLASS,
      payload: res.data.data,
    });

    return Promise.resolve(res.data.data);

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};

export const classManagementStatusSet = (data) => async (dispatch) => {
  
  try {
    
    const res = await ClassService.classManagementStatusSet(data);

    // console.log("res : ", res);

    dispatch({
      type: MANAGEMENT_STATUS_SET_CLASS,
      payload: res.data,
    });

    return Promise.resolve(res.data);

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};

export const classManagementSavedListAll = data => async (dispatch) => {
  
  try {
    
    const res = await ClassService.classManagementSavedListAll(data);

    // console.log("res : ", res);

    dispatch({
      type: MANAGEMENT_SAVED_LIST_CLASS,
      payload: res.data.data,
    });

    return Promise.resolve(res.data.data);

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};

export const classHomeworkListAll = data => async (dispatch) => {
  
  try {
    
    const res = await ClassService.classHomeworkListAll(data);

    // console.log("res : ", res);

    dispatch({
      type: HOMEWORK_LIST_CLASS,
      payload: res.data.data,
    });

    return Promise.resolve(res.data.data);

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};

export const classHomeworkSelectAll = data => async (dispatch) => {
  
  try {
    
    const res = await ClassService.classHomeworkSelectAll(data);

    // console.log("res : ", res);

    dispatch({
      type: HOMEWORK_SELECT_CLASS,
      payload: res.data.data,
    });

    return Promise.resolve(res.data.data);

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};

export const classHomeworkInit = () => async (dispatch) => {
  
  try {
    
    // const res = await ClassService.classHomeworkSelectAll(data);

    // console.log("res : ", res);

    dispatch({
      type: HOMEWORK_INIT_CLASS,
      payload: null,
    });

    return Promise.resolve(null);

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};

export const classHomeworkCommentSelect = (data) => async (dispatch) => {
  
  try {
    
    const res = await ClassService.classHomeworkCommentSelectect(data);

    // console.log("res : ", res);

    dispatch({
      type: HOMEWORK_COMMENT_SELECT,
      payload: res.data.data,
    });

    return Promise.resolve(res.data.data);

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};

export const classHomeworkCommentInsert = (data) => async (dispatch) => {
  
  try {
    
    const res = await ClassService.classHomeworkCommentInsert(data);

    // console.log("res : ", res);

    dispatch({
      type: HOMEWORK_COMMENT_INSERT,
      payload: res.data,
    });

    return Promise.resolve(res.data);

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};

export const classHomeworkCommentDelete = (data) => async (dispatch) => {
  
  try {

    const res = await ClassService.classHomeworkCommentDelete(data);

    // console.log("res : ", res);

      dispatch({
        type: HOMEWORK_COMMENT_DELETE,
        payload: res.data,
      });
  
      return Promise.resolve(res.data);

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};