import http from "../http-common";

const newsGetAll = data => {

  if (data.condition !== undefined && data.keyword !== undefined)
    return http.get(`/bbs/news/${data.board_page}/${data.condition}/${data.keyword}`);
  else
    return http.get(`/bbs/news/${data.board_page}`);
    
};

const newsCreate = data => {
  return http.post("/bbs/news/add", data);
};

const newsModify = data => {
  return http.put("/bbs/news/edit", data);
};

const newsDelete = data => {
  return http.delete("/bbs/news/del", {data : data});
};

const newsSelect = data => {
  return http.get(`/bbs/news/select/${data}`);
};

const newsOutsideGetAll = data => {

  if (data.condition !== undefined && data.keyword !== undefined)
    return http.get(`/bbs/newsOutside/${data.board_page}/${data.condition}/${data.keyword}`);
  else
    return http.get(`/bbs/newsOutside/${data.board_page}`);
    
};

const newsOutsideSelect = data => {
  return http.get(`/bbs/newsOutside/select/${data}`);
};

const newsOpen = data => {
  return http.put("bbs/news/use", {data : data});
};

const NewsService = {
  newsGetAll,
  newsCreate,
  newsModify,
  newsDelete,
  newsSelect,
  newsOutsideGetAll,
  newsOutsideSelect,
  newsOpen,
};

export default NewsService;
