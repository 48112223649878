import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';

import { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { inappProductList, inappProductDelete } from "../../../actions/inapp_product";

import { useNavigate } from 'react-router-dom';

import * as common from "../../../lib";

export default function InappProductList() {

    const inapp_product = useSelector(state => state.inapp_product);
    console.log("inapp_product : ", inapp_product);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleWriteProduct = (event) => {
      navigate("/dashboard/InappProductWrite");
    };

    const handleDeleteProduct = (event, idx) => {
      console.log(idx);

      if (window.confirm("정말 삭제하시겠습니까??") === true)   //확인
      {
        // 여기서 디스패치로~
        dispatch(inappProductDelete(idx))
        .then(data => 
        {
            console.log("data : ", data);
            if (!data.success)
                alert(data.msg);

            navigate("/dashboard/InappProductList");
        })
        .catch(e => {
            console.log(e);
        });
      }
      else  //취소
      {
        return false;
      }

    };

    useEffect(() => {

        // 여기서 디스패치로~
        dispatch(inappProductList())
        .then(data => 
        {
            console.log("data : ", data);
        })
        .catch(e => {
            console.log(e);
        });
        
        return () => {      // 언마운트
            console.log("언마운트!");
        };

    }, [dispatch])

  return (
    <Card sx={{ minWidth: 275 }}>
      <CardContent>
      인앱 상품 리스트
      <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="center">번호</TableCell>
            <TableCell align="center">상품명</TableCell>
            <TableCell align="center">상품이미지</TableCell>
            <TableCell align="center">기본수량</TableCell>
            <TableCell align="center">단가</TableCell>
            <TableCell align="center">유효기간</TableCell>
            <TableCell align="center">미션</TableCell>
            <TableCell align="center">시작일</TableCell>
            <TableCell align="center">종료일</TableCell>
            <TableCell align="center">수정</TableCell>
            <TableCell align="center">삭제</TableCell>
            <TableCell align="center">등록자</TableCell>
            <TableCell align="center">등록일</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {inapp_product && inapp_product.map((row, index) => (
            <TableRow
              key={index}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row" align="center">
                {row.ina_idx}
              </TableCell>
              <TableCell align="center"><Link href={`/dashboard/InappProductView/${row.ina_idx}`} underline="none">{row.ina_name}</Link></TableCell>
              <TableCell align="left"><img src={common.returnImgSrc(row.ina_photo_path, row.ina_photo_file)} width="30" height="30" /></TableCell>
              <TableCell align="center">{row.default_amount}</TableCell>
              <TableCell align="center">{row.sell_prc_unit}</TableCell>
              <TableCell align="center">{row.expiration_date}{row.expiration_date_unit === "month" ? '개월' : '일'}</TableCell>
              <TableCell align="center">{row.mis_count}/{row.total_mission}</TableCell>
              <TableCell align="center">{row.sell_start_dt}</TableCell>
              <TableCell align="center">{row.sell_end_dt}</TableCell>
              <TableCell align="right"><Link href={`/dashboard/InappProductModify/${row.ina_idx}`} underline="none">수정</Link></TableCell>
              <TableCell align="right"><Link onClick={(e)=>handleDeleteProduct(e, row.ina_idx)} value={row.ina_idx} underline="none">삭제</Link></TableCell>
              <TableCell align="center">{row.reg_id}</TableCell>
              <TableCell align="center">{row.reg_date}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      </TableContainer>

      </CardContent>
      <CardActions>
        <Button onClick={handleWriteProduct} size="small">상품 추가</Button>
      </CardActions>
    </Card>
  );
}