import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';

import Box from '@mui/material/Box';
import Input from '@mui/material/Input';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import Link from '@mui/material/Link';
import FormControl from '@mui/material/FormControl';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormLabel from '@mui/material/FormLabel';
import FormControlLabel from '@mui/material/FormControlLabel';

import { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { bbsNoticeCreate } from "../../../actions/yame_notice";

import { useNavigate, useParams } from 'react-router-dom';

import ImgFileUpload from '../../../common/ImgFileUpload';

const ariaLabel = { 'aria-label': 'description' };

export default function NoticeWrite() {

    const [link, setLink] = useState("");
    const [contents, setContents] = useState("");
    const [open, setOpen] = useState(1);
    const [file, setFile] = useState(null);
    const [title, setTitle] = useState(null);

    const board_name  = useParams().board_name;
    console.log("board_name : ", board_name);

    // 리듀서 값들 저장
    const userInfo = useSelector(state => state.auth);
    // console.log("userInfo : ", userInfo);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const onLinkChange = (event) => {
        setLink(event.target.value);
    }

    const onTitleChange = (event) => {
        setTitle(event.target.value);
    }
    
    const onContentsChange = (event) => {
        setContents(event.target.value);
    }

    const onOpenChange = (event) => {
        setOpen(event.target.value);
    }

    const handleImgFileChange = (file) => {
        // console.log("file : ", file);
        setFile(file);
    };

    const onWriteSubmit = (event) => {

        event.preventDefault();
        
        if(userInfo.isLoggedIn)
        {
            if (title === "")
            {
                alert("제목이 없습니다. 작성해주세요.");
                return;
            }
            
            const formData = new FormData();
            formData.append('imgFile', file);
            formData.append('table_name', board_name);
            formData.append('title', title);
            formData.append('contents', contents);
            formData.append('link', link);
            formData.append('open', open);
        
            // 여기서 디스패치로~
            dispatch(bbsNoticeCreate(formData))
            .then(data => 
            {
                console.log("data : ", data);
                if (!data.success)
                {
                    alert(data.msg);
                    return;
                }
                else
                    navigate(`/dashboard/YameNoticeList/${board_name}`);
    
            })
            .catch(e => {
                console.log(e);
            });
        }
        else
        {
            alert("로그인 후에 공지를 작성할 수 있습니다.");
            return;
        }
    }

  return (
    <Card sx={{ minWidth: 275 }}>
      <CardContent>
      <Box
        sx={{
            '& > :not(style)': { m: 1, width: '400px' },
        }}
        noValidate
        autoComplete="off"
        >
        <FormControl>
        <Input 
            placeholder="제목" 
            id="title"
            name="title" 
            inputProps={ariaLabel} 
            onChange={onTitleChange} 
        />
        </FormControl>
      </Box>
      <Box
        sx={{
            '& > :not(style)': { m: 1, width: '400px' },
        }}
        noValidate
        autoComplete="off"
        >
        야미 공지사항 쓰기
        </Box>
        <Box
        sx={{
            '& > :not(style)': { m: 1, width: '400px' },
        }}
        noValidate
        autoComplete="off"
        >
        <FormControl>
        <TextareaAutosize
            aria-label="minimum height"
            minRows={10}
            placeholder="공지 내용"
            style={{ width: 400 }}
            onChange={onContentsChange}
            id="contents" 
            name="contents"
        />
        </FormControl>
        </Box>
        <Box
        sx={{
            '& > :not(style)': { m: 1, width: '400px' },
        }}
        noValidate
        autoComplete="off"
        >
        <FormControl>
        <Input 
            placeholder="링크" 
            id="link"
            name="link" 
            inputProps={ariaLabel} 
            onChange={onLinkChange} 
        />
        </FormControl>
        </Box>
        <Box
        sx={{
            '& > :not(style)': { m: 1, width: '400px' },
        }}
        noValidate
        autoComplete="off"
        >
            <FormControl>
            <FormLabel id="open">공개여부</FormLabel>
            <RadioGroup
                row
                aria-labelledby="open"
                defaultValue="true"
                name="open"
                onChange={onOpenChange}
                value={open}
            >
                <FormControlLabel value={1} control={<Radio />} label="공개" />
                <FormControlLabel value={0} control={<Radio />} label="비공개" />
            </RadioGroup>
            </FormControl>
        </Box>
        <ImgFileUpload handleImgFileChange={handleImgFileChange} width="400" />
      </CardContent>
      <CardActions>
        <Button onClick={onWriteSubmit} size="small">쓰기</Button>
        <Link href={`/dashboard/YameNoticeList/${board_name}`} underline="none">목록</Link>
      </CardActions>
    </Card>
  );
}