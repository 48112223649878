import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import styles from "../../css/common.module.css";
import $ from 'jquery';
import { useParams } from 'react-router-dom';

import { solutionList } from "../../actions/solution";

import * as common from "../../lib";

import { useTranslation, Trans } from 'react-i18next'; // 1. react-i18next import 

export default function MypageQnaInfo() {

    const solutionState = useSelector(state => state.solution);
    // console.log("solutionState : ", solutionState);

	const mypage_tab_btn_areaRef =  useRef();
    const hover_bgRef =  useRef();
    const onRef =  useRef();

	const dispatch = useDispatch();	

	const { t, i18n } = useTranslation(); // 3. useTranslation hook 선언

	let board_page  = useParams().page;
    // console.log("board_page : ", board_page);
    let board_condition  = useParams().condition;
    // console.log("board_condition : ", board_condition);

    if (board_page === undefined)
      board_page = 1;

    if (board_condition === undefined)
      board_condition = "";
    else if(board_condition === "선택")
      board_condition = "";

	useEffect(() => {

		// 여기에서 jQuery를 초기화 합니다.
		// jquery ready 호출. jquery3 부터 변경됨
		$(function(){
			// console.log("jquery ready!");

            //마이페이지 탭 버튼(활성화 효과)
			$(`.${styles.mypage_tab_btn_area} li`).on('click',function()
			{
				let tg=$(this);
				
				$(`.${styles.mypage_tab_btn_area} li`).removeClass(`${styles.on}`);
				tg.addClass(`${styles.on}`);
			});
            
            //마이페이지 탭 버튼(후버 효과)
            $(window).resize(function()
			{
				if (window.innerWidth < 1200) 
				{ 
					$(`.${styles.mypage_tab_btn_area} li.${styles.hover_bg}`).css('display','none');
				}
				else
				{
					let nowbtnNum=$(`.${styles.mypage_tab_btn_area} li.${styles.on}`).index();
					let hoverBg=$(`.${styles.mypage_tab_btn_area} li.${styles.hover_bg}`);

					$(`.${styles.mypage_tab_btn_area} ul`).on('mouseenter',function()
					{
						
						$(`.${styles.mypage_tab_btn_area} li`).removeClass(`${styles.on}`);
						hoverBg.css('display','block');
						
						$(`.${styles.mypage_tab_btn_area} li`).on('mouseenter',function()
						{
							let tgIndex=$(this).index();
							
							if(tgIndex===1)
							{
								hoverBg.stop().css('left','167px').css('width','116px');
							}
							else if(tgIndex===2)
							{
								hoverBg.stop().css('left','300px').css('width','150px');
							}
							else if(tgIndex===3)
							{
								hoverBg.stop().css('left','467px').css('width','116px');
							}
							else if(tgIndex===4)
							{
								hoverBg.stop().css('left','624px').css('width','102px');
							}
							else if(tgIndex===5)
							{
								hoverBg.stop().css('left','774px').css('width','102px');
							}
							else if(tgIndex===6)
							{
								hoverBg.stop().css('left','924px').css('width','102px');
							};
						});
						
					}).on('mouseleave',function()
					{
						$(`.${styles.mypage_tab_btn_area} li`).eq(nowbtnNum).addClass(`${styles.on}`);
						hoverBg.css('display','none');
						
						if(nowbtnNum===0)
						{
							hoverBg.css({left:'167px'}).css({width:'116px'});
						}
						else if(nowbtnNum===1)
						{
							hoverBg.css({left:'300px'}).css({width:'150px'});
						}
						else if(nowbtnNum===2)
						{
							hoverBg.css({left:'467px'}).css({width:'116px'});
						}
						else if(nowbtnNum===3)
						{
							hoverBg.css({left:'624px'}).css({width:'102px'});
						}
						else if(nowbtnNum===4)
						{
							hoverBg.css({left:'774px'}).css({width:'102px'});
						}
						else if(nowbtnNum===5)
						{
							hoverBg.css({left:'924px'}).css({width:'102px'});
						};

					});
					
				}
			}).resize();
			
			//결제 정보 페이지 페이징 버튼	
			$(`.${styles.list_pagebtn_area} li`).on('click',function() 
			{
				let tg=$(this);
				let num=tg.index();
				let prev;
				let next;
				let last;
				let nowBtn=$(`.${styles.list_pagebtn_area}`).children(`.${styles.on}`);
				let nowIndex=nowBtn.index();
				
				$(`.${styles.list_pagebtn_area} li`).removeClass(`${styles.on}`);
				
				if(tg.hasClass(`${styles.arrow_btn}`)==true)
				{
					if(tg.hasClass(`${styles.first}`)==true)
					{
						tg=$(`.${styles.list_pagebtn_area} li`).eq(2);
					}
					else if(tg.hasClass(`${styles.prev}`)==true)
					{
						prev=nowIndex-1;
						
						if(nowIndex===2)
						{
							tg=$(`.${styles.list_pagebtn_area} li`).eq(2);
						}else
						{
							tg=$(`.${styles.list_pagebtn_area} li`).eq(prev);
						};
						// console.log("prev click");
					}
					else if(tg.hasClass(`${styles.next}`)==true)
					{
						let lastNum=$(`.${styles.list_pagebtn_area} li`).last().index();
						next=nowIndex+1;
						last=lastNum-2;
						
						if(nowIndex===last)
						{
							tg=$(`.${styles.list_pagebtn_area} li`).eq(last);
						}else
						{
							tg=$(`.${styles.list_pagebtn_area} li`).eq(next);
						};
						// console.log("next click");
					}
					else if(tg.hasClass(`${styles.last}`)==true)
					{
						last=num-2;
						tg=$(`.${styles.list_pagebtn_area} li`).eq(last);
					};
					
					tg.addClass(`${styles.on}`);
					
				}
				else
				{
					tg.addClass(`${styles.on}`);
				};
			});

			// 쿠폰 리스트 수량
			// $(`.${styles.coupon_count}`).text($(`.${styles.coupon_list_wrap} .${styles.list}`).length);

			//쿠폰 카테고리 셀렉트 값 콘솔
			$(`#${styles.coupon_categori_select}`).on('change',function()
			{
				let coupon_categori=$(`#${styles.coupon_categori_select}`).val();
				// console.log(coupon_categori);

				//보유쿠폰 수량체크
				// let couponCount=$(`.${styles.coupon_list_wrap} .${styles.list}`).length;
				// console.log(couponCount);
				// $(`.${styles.coupon_count}`).text(couponCount);
			});

		});

		const sendData = {
			board_page: board_page
		};

		// console.log("sendData : ", sendData);

		// 여기서 디스패치로~
		dispatch(solutionList(sendData))
		.then(data => 
		{
			// console.log("data : ", data);
		})
		.catch(e => {
			console.log(e);
		});

	}, [$, dispatch]);

	const pagingReturn = (paging) =>
	{
		// console.log("paging : ", paging);

		const rendering = () => {
			const result = [];
			for (let i = paging.startPage; i <= paging.endPage; i++) {

				if (i === paging.curPage)
					result.push(<li className={styles.on} key={i}><a href={`/UserMypage/QnaInfo/${i}${board_condition ? "/" + board_condition : ""}`}>{i}</a></li>);
				else
					result.push(<li key={i}><a href={`/UserMypage/QnaInfo/${i}${board_condition ? "/" + board_condition : ""}`}>{i}</a></li>);
			}
			return result;
		};

		// 이전 페이지
		let prevPage = 0;

		if (paging.totalPage === 1 || paging.curPage === 1)
			prevPage = 1;
		else
			prevPage = paging.curPage - 1;

		// console.log("prevPage : ", prevPage);

		// 다음 페이지
		let nextPage = 0;

		if (paging.totalPage === 1)
			nextPage = 1;
		else if (paging.curPage === paging.totalPage)
			nextPage = paging.endPage;
		else
			nextPage = paging.curPage + 1;

		// console.log("nextPage : ", nextPage);

		return (
		<ul className={styles.list_pagebtn_area}>
			<li className={`${styles.arrow_btn} ${styles.first}`}><a href={`/UserMypage/QnaInfo/1${board_condition ? "/" + board_condition : ""}`}><img src="/assets/first_btn@2x.png" alt="첫번째 페이지로 이동"/></a></li>
			<li className={`${styles.arrow_btn} ${styles.prev}`}><a href={`/UserMypage/QnaInfo/${prevPage}${board_condition ? "/" + board_condition : ""}`}><img src="/assets/prev_btn@2x.png" alt="이전 페이지로 이동"/></a></li>
			{
				rendering()
			}
			<li className={`${styles.arrow_btn} ${styles.next}`}><a href={`/UserMypage/QnaInfo/${nextPage}${board_condition ? "/" + board_condition : ""}`}><img src="/assets/next_btn@2x.png" alt="다음 페이지로 이동"/></a></li>
			<li className={`${styles.arrow_btn} ${styles.last}`}><a href={`/UserMypage/QnaInfo/${paging.totalPage}${board_condition ? "/" + board_condition : ""}`}><img src="/assets/last_btn@2x.png" alt="마지막 페이지로 이동"/></a></li>
		</ul>
		);
	}

  return (
    <>
      	{/* Header 분리됨 MainLayout Header에 있음 */}

		<div className={styles.mypage_top_line_banner}>
			<div className={styles.inner}>
				<span>{/* 학부모 페이지 */}{t('Common.Mypage_title1')}</span>
			</div>
		</div>
		<div className={styles.mypage_tab_btn_area} ref={mypage_tab_btn_areaRef}>
			<div className={styles.inner}>
				<ul>
					<li className={styles.hover_bg} ref={hover_bgRef}></li>
					<li><a href="/UserMypage/Report">{/* 학습 리포트 */}{t('Common.SubMenu.menu1')}</a></li>
					<li><a href="/UserMypage/StudentInfo">{/* 학습자 정보/관리 */}{t('Common.SubMenu.menu2')}</a></li>
					<li><a href="/UserMypage/ParentsInfo">{/* 학부모 정보 */}{t('Common.SubMenu.menu3')}</a></li>
					<li><a href="/UserMypage/PayInfo">{/* 결제 정보 */}{t('Common.SubMenu.menu4')}</a></li>
					<li><a href="/UserMypage/CouponInfo">{/* 쿠폰 관리 */}{t('Common.SubMenu.menu5')}</a></li>
					<li className={styles.on} ref={onRef}><a href="/UserMypage/QnaInfo">{/* 문의 내역 */}{t('Common.SubMenu.menu6')}</a></li>
				</ul>
			</div>
		</div>
		<div className={`${styles.mypage_tab} ${styles.mypage_qna_info}`}>
			<div className={styles.inner}>
				<span className={styles.my_tit}>{/* 문의 내역 */}{t('FQA.Qna.title3')}</span>
				<div className={styles.qna_info_area}>
					<div className={styles.title}>
						<div></div>
						<p>{/* 나의 문의 내역 */}{t('FQA.Qna.title4')}</p>
					</div>
					<ul className={styles.qna_info_list_wrap}>
						<li className={styles.list_head}>
							<div className={styles.qna_number}>
								<span>{/* 번호 */}{t('FQA.Qna.string9')}</span>
							</div>
							<div className={styles.qna_title}>
								<span>{/* 제목 */}{t('FQA.Qna.string10')}</span>
							</div>
							<div className={styles.qna_name}>
								<span>{/* 이름 */}{t('FQA.Qna.string11')}</span>
							</div>
							<div className={styles.qna_date}>
								<span>{/* 문의날짜 */}{t('FQA.Qna.string12')}</span>
							</div>
							<div className={styles.qna_state}>
								<span>{/* 답변상태 */}{t('FQA.Qna.string13')}</span>
							</div>
							<div></div> 
						</li>
						{/* <li className={styles.no_list}>
							<span>문의 내역이 없습니다</span>
						</li> */}
						{
							solutionState.list?.length === 0
							? <li className={styles.no_list}>
							<span>{/* 문의 내역이 없습니다 */}{t('FQA.Qna.string14')}</span>
						</li>
							: null

						}
						{/* <li className={styles.list}>
                            <div className={styles.number_area}>
                                <span>2</span>
                            </div>
                            <div className={styles.right_area}>
                                <div className={styles.top_area}>
                                    <span className={styles.qna_title}>회원가입 후 이메일 인증이 안되요</span>
                                </div>
                                <div className={styles.middle_area}>
                                    <div className={styles.name}>
                                        <span>김코딩</span>
                                    </div>
                                    <span className={styles.date}>2022.09.01</span>
                                    <span className={styles.state}>답변대기</span>
                                </div>
                                <div className={styles.bottom_area}>
                                    <a href="/UserMypage/QnaInfoMore">문의 상세보기</a>
                                </div>
                            </div>
						</li>
						<li className={`${styles.list} ${styles.complete}`}>
                            <div className={styles.number_area}>
                                <span>1</span>
                            </div>
                            <div className={styles.right_area}>
                                <div className={styles.top_area}>
                                    <span className={styles.qna_title}>회원가입 후 이메일 인증이 안되요회원가입 후 이메일 인증이 안되요회원가입 후 이메일 인증이 안되요</span>
                                </div>
                                <div className={styles.middle_area}>
                                    <div className={styles.name}>
                                        <span>김코딩</span>
                                    </div>
                                    <span className={styles.date}>2022.09.01</span>
                                    <span className={styles.state}>답변완료</span>
                                </div>
                                <div className={styles.bottom_area}>
                                    <a href="/UserMypage/QnaInfoMore">문의 상세보기</a>
                                </div>
                            </div>
						</li> */}
						{
							solutionState.list && solutionState.list?.map((row, index) => (

								<li className=
									{`
										${styles.list} 
										${row.answer === null ? `` : `${styles.complete}`}
									`}
									key={index} >
									<div className={styles.number_area}>
										<span>{index+1}</span>
									</div>
									<div className={styles.right_area}>
										<div className={styles.top_area}>
											<span className={styles.qna_title}>{row.title}</span>
										</div>
										<div className={styles.middle_area}>
											<div className={styles.name}>
												<span>{row.mem_name}</span>
											</div>
											<span className={styles.date}>{common.dateChangeReturn(row.reg_date)}</span>
											<span className={styles.state}>
												{
													// row.answer === null || row.answer === "" ? "답변대기" : "답변완료"
													row.answer === null || row.answer === "" ? t('FQA.Qna.string15') : t('FQA.Qna.string16')
												}
											</span>
										</div>
										<div className={styles.bottom_area}>
											<a href={`/UserMypage/QnaInfoMore/${row.idx}/${board_page}${board_condition ? "/" + board_condition : ""}`}>{/* 문의 상세보기 */}{t('FQA.Qna.string17')}</a>
										</div>
									</div>
								</li>

							))
						}
					</ul>
					{solutionState?.paging && solutionState?.paging?.totalPageCount > 0 ? pagingReturn(solutionState?.paging) : ""}
					{/* <button type="button" className={styles.list_more_btn}>+ 더보기</button>
					<ul className={styles.list_pagebtn_area}>
						<li className={`${styles.arrow_btn} ${styles.first}`}><a href="#"><img src="/assets/first_btn@2x.png" alt="첫번째 페이지로 이동"/></a></li>
						<li className={`${styles.arrow_btn} ${styles.prev}`}><a href="#"><img src="/assets/prev_btn@2x.png" alt="이전 페이지로 이동"/></a></li>
						<li className={styles.on}><a href="#">1</a></li>
						<li><a href="#">2</a></li>
						<li><a href="#">3</a></li>
						<li><a href="#">4</a></li>
						<li><a href="#">5</a></li>
						<li><a href="#">6</a></li>
						<li><a href="#">7</a></li>
						<li><a href="#">8</a></li>
						<li><a href="#">9</a></li>
						<li><a href="#">10</a></li>
						<li className={`${styles.arrow_btn} ${styles.next}`}><a href="#"><img src="/assets/next_btn@2x.png" alt="다음 페이지로 이동"/></a></li>
						<li className={`${styles.arrow_btn} ${styles.last}`}><a href="#"><img src="/assets/last_btn@2x.png" alt="마지막 페이지로 이동"/></a></li>
					</ul> */}
				</div>
			</div>{/* //inner */}
		</div>{/* //mypage_tab */}
		
		{/* Footer 분리됨 MainLayout Footer에 있음 */}
    </>
  );
}