import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import { FormControl, TextField, Select, MenuItem, InputLabel } from '@mui/material';

import Stack from '@mui/material/Stack';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import MobileDatePicker from '@mui/lab/MobileDatePicker';
import Divider from '@mui/material/Divider';

import { useState, useEffect } from 'react';

import ImgFileUpload from '../../../common/ImgFileUpload';

import { useDispatch, useSelector } from "react-redux";
import { productCreate } from "../../../actions/product";
import { categoryList } from "../../../actions/category";
import { useNavigate } from 'react-router-dom';

export default function ProductWrite() {

    const initialProductState = {
        category_idx: null, 
        product_name: null,
        product_desc: null,
        default_amount: 1,
        expiration_date: 1,
        expiration_date_unit: null,
        sell_prc_unit: 1,
        sell_start_dt: null,
        sell_end_dt: null,
        file: null,
        fileName: null,
        option1: [],
        option2: [],
    };

    const [product, setProduct] = useState(initialProductState);

    const category = useSelector(state => state.category);
    console.log("category : ", category);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleInputChange = event => {
        const { name, value } = event.target;
        setProduct({ ...product, [name]: value });
    };

    const handleStartDateChange = (newValue) => {
        setProduct({ ...product, sell_start_dt: newValue });
    };

    const handleEndDateChange = (newValue) => {
        setProduct({ ...product, sell_end_dt: newValue });
    };

    const handleImgFileChange = (file) => {
        console.log("file : ", file);
        setProduct({ ...product, file: file, fileName: file.name });
    };

    const handleOption1Change = event => {
        const { name, value } = event.target;

        console.log("name : ", name);
        console.log("value : ", value);

        let nameSplit = name.split("-");
        let index = parseInt(nameSplit[0]);
        let selectType = nameSplit[1];
        let option1 = [...product.option1];

        if (selectType === "po1_count_start")
            option1[index] = {"po1_count_start": value, "po1_count_end": product.option1[index].po1_count_end,
            "po1_amt": product.option1[index].po1_amt, "expiration_date_unit": product.option1[index].expiration_date_unit, "expiration_date": product.option1[index].expiration_date};
        else if (selectType === "po1_count_end")
            option1[index] = {"po1_count_start": product.option1[index].po1_count_start, "po1_count_end": value,
            "po1_amt": product.option1[index].po1_amt, "expiration_date_unit": product.option1[index].expiration_date_unit, "expiration_date": product.option1[index].expiration_date};
        else if (selectType === "po1_amt")
            option1[index] = {"po1_count_start": product.option1[index].po1_count_start, "po1_count_end": product.option1[index].po1_count_end,
            "po1_amt": value, "expiration_date_unit": product.option1[index].expiration_date_unit, "expiration_date": product.option1[index].expiration_date};
        else if (selectType === "expiration_date_unit")
            option1[index] = {"po1_count_start": product.option1[index].po1_count_start, "po1_count_end": product.option1[index].po1_count_end,
            "po1_amt": product.option1[index].po1_amt, "expiration_date_unit": value, "expiration_date": product.option1[index].expiration_date};
        else if (selectType === "expiration_date")
            option1[index] = {"po1_count_start": product.option1[index].po1_count_start, "po1_count_end": product.option1[index].po1_count_end,
            "po1_amt": product.option1[index].po1_amt, "expiration_date_unit": product.option1[index].expiration_date_unit, "expiration_date": value};

        setProduct({ ...product, ["option1"]: option1 });
    }

    const handleOption2Change = event => {
        const { name, value } = event.target;

        console.log("name : ", name);
        console.log("value : ", value);

        let nameSplit = name.split("-");
        let index = parseInt(nameSplit[0]);
        let selectType = nameSplit[1];
        let option2 = [...product.option2];

        if (selectType === "po2_name")
            option2[index] = {"po2_name": value, "po2_price": product.option2[index].po2_price};
        else if (selectType === "po2_price")
            option2[index] = {"po2_name": product.option2[index].po2_name, "po2_price": value};

        setProduct({ ...product, ["option2"]: option2 });
    }

    const clickDelOption1 = (event, index) => {
        event.preventDefault();

        product.option1.splice(index, 1);
        setProduct({...product, ["option1"]: product.option1});
    };

    const clickAddOption1 = () => {
        setProduct({...product, ["option1"]: product.option1.concat([{"po1_count_start": "", "po1_count_end": "", "po1_amt": "", "expiration_date_unit": "", "expiration_date": ""}])});
    };

    const clickDelOption2 = (event, index) => {
        event.preventDefault();

        product.option2.splice(index, 1);
        setProduct({...product, ["option2"]: product.option2});
    };

    const clickAddOption2 = () => {
        setProduct({...product, ["option2"]: product.option2.concat([{"po2_name": "", "po2_name": ""}])});
    };

    const handleSubmit = (event) => {

        event.preventDefault();

        const formData = new FormData();
        formData.append('imgFile', product.file);
        formData.append('fileName', product.fileName);
        formData.append('product_name', product.product_name);
        formData.append('category_idx', product.category_idx);
        formData.append('product_desc', product.product_desc);
        formData.append('default_amount', product.default_amount);
        formData.append('expiration_date', product.expiration_date);
        formData.append('expiration_date_unit', product.expiration_date_unit);
        formData.append('sell_prc_unit', product.sell_prc_unit);
        formData.append('option1', JSON.stringify(product.option1));
        formData.append('option2', JSON.stringify(product.option2));

        console.log(product.option1);
        console.log(product.option2);
        
        if (product.sell_start_dt !== null)
            formData.append('sell_start_dt', product.sell_start_dt.toISOString().split("T")[0]);
        else
            formData.append('sell_start_dt', product.sell_start_dt);
        
        if (product.sell_end_dt !== null)
            formData.append('sell_end_dt', product.sell_end_dt.toISOString().split("T")[0]);
        else
            formData.append('sell_end_dt', product.sell_end_dt);

        //console.log(product.sell_start_dt.toISOString().split("T")[0]);

        // 여기서 디스패치로~
        dispatch(productCreate(formData))
        .then(data => 
        {
            console.log("data : ", data);
            if (!data.success)
                alert(data.msg);
            
            navigate("/dashboard/ProductList");
        })
        .catch(e => {
            console.log(e);
        });
    };

    useEffect(() => {

        // 여기서 디스패치로~
        dispatch(categoryList())
        .then(data => 
        {
            console.log("data : ", data);
        })
        .catch(e => {
            console.log(e);
        });
        
        return () => {      // 언마운트
            console.log("언마운트!");
        };

    }, [dispatch])

  return (
    <Card sx={{ minWidth: 275 }}>
      <CardContent>
      <FormControl>
            <TextField
            id="name"
            label="상품 이름"
            helperText="상품 이름을 입력해주세요."
            variant="standard"
            name="product_name"
            onChange={handleInputChange}
            />
            
            <TextField
            id="product_desc"
            label="상품 설명"
            helperText="상품 설명을 입력해주세요."
            variant="standard"
            name="product_desc"
            onChange={handleInputChange}
            />
            
            <br></br>

            <FormControl fullWidth>
            <InputLabel id="category_idx">카테고리</InputLabel>
            <Select
            labelId="category_idx"
            id="category_idx"
            label="카테고리"
            onChange={handleInputChange}
            name="category_idx"
            value={product.category_idx || ""}
            >
            {category && category.map((item, index) => (
            <MenuItem value={item.category_idx} key={index}>
                {item.category_name}
            </MenuItem>
            ))}
            </Select>
            </FormControl>
            
            <br></br>

            <TextField
            id="default_amount"
            label="기본 수량"
            type="number"
            InputLabelProps={{
                shrink: true,
            }}
            variant="standard"
            name="default_amount"
            value={product.default_amount || 0}
            helperText="기본 수량은 패키지의 수량을 뜻합니다. 무료 패키지의 설정입니다."
            onChange={handleInputChange}
            inputProps={{
                inputMode: 'numeric', 
                pattern: '[0-9]*',
                step: 1,
                min: 1,
                max: 10000,
                type: 'number',
            }}
            />
            <br></br>

            <TextField
            id="sell_prc_unit"
            label="단가"
            type="number"
            value={product.sell_prc_unit || 0}
            helperText="단가를 0으로 설정하면 무료 패키지가 됩니다."
            InputLabelProps={{
                shrink: true,
            }}
            variant="standard"
            name="sell_prc_unit"
            onChange={handleInputChange}
            inputProps={{
                inputMode: 'numeric', 
                pattern: '[0-9]*',
                min: 0,
                type: 'number',
            }}
            />
            <br></br>

            <FormControl sx={{ width: '120px' }}>
            <InputLabel id="expiration_date_unit">유효단위</InputLabel>
                <Select
                labelId="expiration_date_unit"
                id="expiration_date_unit"
                label="유효단위"
                onChange={handleInputChange}
                name="expiration_date_unit"
                value={product.expiration_date_unit || ''}
                >
                    <MenuItem value={'day'}>일</MenuItem>
                    <MenuItem value={'month'}>월</MenuItem>
                </Select>
            </FormControl>
            
            <br></br>
            <TextField
            id="expiration_date"
            label="패키지 유효기간"
            type="number"
            value={product.expiration_date || 0}
            helperText="패키지의 유효기간으로 기본 단위는 1달(월)입니다."
            InputLabelProps={{
                shrink: true,
            }}
            variant="standard"
            name="expiration_date"
            onChange={handleInputChange}
            inputProps={{
                inputMode: 'numeric', 
                pattern: '[0-9]*',
                min: 1,
                max: 12,
                step: 1,
                type: 'number',
            }}
            />

            <br></br>

            <Button onClick={clickAddOption1}
            style={{
                marginBottom:'10px'
            }}
            >옵션1 추가</Button>

            {
              product.option1.map(((item, index) => (
                <div key={index}
                    style={{
                        marginBottom:'15px'
                    }}
                >
                <Divider></Divider>
                <br></br>
                <FormControl sx={{ width: '120px' }}>
                <InputLabel id={`${index}-expiration_date_unit`}>유효단위</InputLabel>
                    <Select
                    labelId={`${index}-expiration_date_unit`}
                    id={`${index}-expiration_date_unit`}
                    label="유효단위"
                    onChange={handleOption1Change}
                    name={`${index}-expiration_date_unit`}
                    value={product.option1[index].expiration_date_unit || ""}
                    >
                        <MenuItem value={'day'}>일</MenuItem>
                        <MenuItem value={'month'}>월</MenuItem>
                    </Select>
                </FormControl>
                <TextField 
                  sx={{marginRight:'10px', marginLeft:'10px', width:'100px'}}
                  id={`${index}-expiration_date`}
                  name={`${index}-expiration_date`}
                  label={`유효기간`}
                  onChange={handleOption1Change}  
                  inputProps={{ maxLength: 10, inputMode: 'numeric', pattern: '[0-9]*' }}
                  value={product.option1[index].expiration_date || ""}
                />
                <Button onClick={(e)=>{clickDelOption1(e, index)}}>삭제</Button>
                <br></br><br></br>
                <TextField 
                  sx={{marginRight:'10px', width:'100px'}}
                  id={`${index}-po1_count_start`}
                  name={`${index}-po1_count_start`}
                  label={`수량 시작`}
                  onChange={handleOption1Change} 
                  inputProps={{ maxLength: 100000, inputMode: 'numeric', pattern: '[0-9]*' }}
                  type="text"
                  value={product.option1[index].po1_count_start || ""}
                />
                <TextField 
                  sx={{marginRight:'10px', width:'100px'}}
                  id={`${index}-po1_count_end`}
                  name={`${index}-po1_count_end`}
                  label={`수량 끝`}
                  onChange={handleOption1Change}
                  inputProps={{ maxLength: 100000, inputMode: 'numeric', pattern: '[0-9]*' }}
                  value={product.option1[index].po1_count_end || ""}
                />
                <br></br><br></br>
                <TextField 
                  id={`${index}-po1_amt`}
                  name={`${index}-po1_amt`}
                  label={`가격`}
                  onChange={handleOption1Change}  
                  inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                  value={product.option1[index].po1_amt || ""}
                />
                <br></br>
                
                </div>
              )))
            }

            <Button onClick={clickAddOption2}
            style={{
                marginBottom:'10px'
            }}
            >옵션2 추가</Button>

            {
              product.option2.map(((item, index) => (
                <div key={index}
                    style={{
                        marginBottom:'15px'
                    }}
                >
                <Divider></Divider>
                <br></br>
                <TextField 
                  sx={{marginRight:'10px', width:'180px'}}
                  id={`${index}-po2_name`}
                  name={`${index}-po2_name`}
                  label={`옵션의 이름`}
                  onChange={handleOption2Change} 
                  inputProps={{ maxLength: 100 }}
                  type="text"
                  value={product.option2[index].po2_name || ""}
                />
                <TextField 
                  sx={{marginRight:'10px', marginLeft:'10px', width:'180px'}}
                  id={`${index}-po2_price`}
                  name={`${index}-po2_price`}
                  label={`옵션의 가격`}
                  onChange={handleOption2Change}  
                  inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                  value={product.option2[index].po2_price || ""}
                />
                <Button onClick={(e)=>{clickDelOption2(e, index)}}>삭제</Button>
                <br></br>
                </div>
              )))
            }

            <br></br>

            <Divider sx={{marginBottom:'10px'}}/>
            판매 기간이 정해져있는 상품의 설정
            <Divider sx={{marginTop:'10px'}} />
            <br></br>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Stack spacing={3}>
                    <MobileDatePicker
                    label="판매 시작일"
                    inputFormat="MM/dd/yyyy"
                    value={product.sell_start_dt || ""}
                    onChange={handleStartDateChange}
                    renderInput={(params) => <TextField {...params} />}
                    name="sell_start_dt"
                    />
                    <MobileDatePicker
                    label="판매 종료일"
                    inputFormat="MM/dd/yyyy"
                    value={product.sell_end_dt || ""}
                    onChange={handleEndDateChange}
                    renderInput={(params) => <TextField {...params} />}
                    name="sell_end_dt"
                    />
                </Stack>
            </LocalizationProvider>

        <br></br>
        <br></br>
        <ImgFileUpload handleImgFileChange={handleImgFileChange} />
        </FormControl>
      </CardContent>
      <CardActions>
        <Button onClick={handleSubmit} size="small">상품등록</Button>
      </CardActions>
    </Card>
  );
}