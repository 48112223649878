import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguaeDetector from "i18next-browser-languagedetector";

import translationEn from './translation.en'
import translationKo from './translation.ko'

const resource =  {
    en: {
        translation: translationEn
    },
    ko: {
        translation: translationKo
    },
};

i18n
  .use(LanguaeDetector) // 사용자 언어 탐지
  .use(initReactI18next) // i18n 객체를 react-18next에 전달
  .init({
    // for all options read: https://www.i18next.com/overview/configuration-options
    debug: true,
    fallbackLng: "ko",
    interpolation: {
      escapeValue: false,
    },
    resources: resource,
    react: {
      transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'p', 'span', '/n', 'a', 'download'],
    },
  });

export default i18n;