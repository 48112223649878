import http from "../http-common";

const categoryCreate = data => {
  return http.post("payment/category", data);
};

const categoryGetAll = () => {
  return http.get("payment/category");
};

const categoryDelete = data => {
  return http.delete("payment/category", {data : {category_idx : data}});
};

const categoryModify = data => {
  return http.put("payment/category", data);
};

const categorySelect = data => {
  return http.get(`payment/category/${data}`);
};

const CategoryService = {
  categoryCreate,
  categoryGetAll,
  categoryDelete,
  categoryModify,
  categorySelect,
};

export default CategoryService;
