import http from "../http-common";

const missionCreate = data => {
  return http.post("mission", data);
};

const missionGetAll = (data) => {
  if (data === undefined)
    return http.get(`missionMapList`);
  else
    return http.get(`missionMapList/${data}`);
};

const missionDelete = data => {
  return http.delete("mission", {data : {mis_idx : data}});
};

const missionModify = data => {
  return http.put("mission", data);
};

const missionOrderChange = data => {
  return http.put("mission/orderbyChange", data);
};

const missionSelect = data => {
  return http.get(`mission/select/${data}`);
};

const MissionService = {
  missionCreate,
  missionGetAll,
  missionDelete,
  missionModify,
  missionSelect,
  missionOrderChange,
};

export default MissionService;
