import React from 'react';

import { useMediaQuery } from '@mui/material';
import MiniDrawer from './MiniDrawer';
import ResponsiveDrawer from './ResponsiveDrawer';

const Header = () => {

    // 인자로 주어진 미디어쿼리 조건에 따라 true와 false를 리턴한다.
    const isMobile = useMediaQuery("(max-width: 600px)");

    return (
        <>
            {/* 미디어 쿼리 설정 */}
            {/* { isMobile ? <ResponsiveDrawer /> : <MiniDrawer /> } */}
            <ResponsiveDrawer />
        </>
    );
    
};

export default Header;