import {
  REGISTER_INAPP_PRODUCT_MISSION,
  USE_INAPP_PRODUCT_MISSION,
  USENOT_INAPP_PRODUCT_MISSION,
  INAPP_PRODUCT_LIST_MISSION,
} from "../actions/types";

const initialState = [];

const inappProductMissionReducer = (inapp_product_mission = initialState, action) => {
  const { type, payload } = action;

  // console.log("productMissionReducer Call!");
  // console.log("type : ", type);
  // console.log("payload : ", payload);

  switch (type) {

    case REGISTER_INAPP_PRODUCT_MISSION:
      return [];

    case USE_INAPP_PRODUCT_MISSION:
      return {
        ...inapp_product_mission,
        useMission : payload,
      };

    case USENOT_INAPP_PRODUCT_MISSION:
      return {
        ...inapp_product_mission,
        useNotMission : payload,
      };

    case INAPP_PRODUCT_LIST_MISSION:
      return payload;

    default:
      return inapp_product_mission;
  }
};

export default inappProductMissionReducer;