import * as React from 'react';
import styles from "../../css/common.module.css";

import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useDispatch, useSelector } from "react-redux";
import { bbsSelect, bbsModify } from "../../actions/bbs";

import { useNavigate } from 'react-router-dom';

export default function Modify() {

const board_name  = useParams().board_name;
console.log("board_name : ", board_name);
const idx  = useParams().idx;
console.log("idx : ", idx);
const prevPage  = useParams().prevPage;
console.log("prevPage : ", prevPage);
let board_condition  = useParams().condition;
console.log("board_condition : ", board_condition);
let board_keyword  = useParams().keyword;
console.log("board_keyword : ", board_keyword);

if (board_condition === undefined)
	board_condition = "";
else if(board_condition === "선택")
	board_condition = "";

if (board_keyword === undefined)
	board_keyword = "";

const [boardName, setBoardName] = useState(""); // eslint-disable-line no-unused-vars
const [title, setTitle] = useState("");
const [contents, setContents] = useState("");
const [writeIdx, setWriteIdx] = useState("");
const [searchCondition, setSearchCondition] = useState(board_condition);	// eslint-disable-line no-unused-vars
const [searchKeyword, setSearchKeyword] = useState(board_keyword);			// eslint-disable-line no-unused-vars

// 리듀서 값들 저장
const userInfo = useSelector(state => state.auth);
console.log("userInfo : ", userInfo);

const dispatch = useDispatch();
const navigate = useNavigate();

const onTitleChange = (event) => {
	setTitle(event.target.value);
}

const onContentsChange = (event) => {
	setContents(event.target.value);
}

const onModifySubmit = (event) => {

	event.preventDefault();
	
	if(userInfo.isLoggedIn)
	{
		if (userInfo.user.idx !== writeIdx)
		{
			alert("수정은 작성자만 가능합니다.");
			return;
		}

		const sendModify = {
			table_name: board_name,
			idx: idx,
			title: title,
			contents: contents
		}

		if (sendModify.title === "")
		{
			alert("제목이 없습니다. 작성해주세요.");
			return;
		}

		if (sendModify.contents === "")
		{
			alert("내용이 없습니다. 작성해주세요.");
			return;
		}

		console.log("sendModify : ", sendModify);
	
		// 여기서 디스패치로~
		dispatch(bbsModify(sendModify))
		.then(data => 
		{
			console.log("bbsModify data : ", data);
			if (!data.success)
			{
				alert(data.msg);
				return;
			}
			else
			{
				if (searchCondition !== "" && searchKeyword !== "" )
						navigate(`/BoardView/${board_name}/${idx}/${prevPage}/${searchCondition}/${searchKeyword}`);
					else
						navigate(`/BoardView/${board_name}/${idx}/${prevPage}`);
			}
				

		})
		.catch(e => {
			console.log("bbsModify error : ", e);
			console.log(e);
		});
	}
	else
	{
		alert("로그인 후에 수정을 할 수 있습니다.");
		return;
	}
}

useEffect(() => {

	if (board_name === "news")
	{
		setBoardName("새소식");
	}
	else if (board_name === "info")
	{
		setBoardName("자료실");
	}

	// 여기서 디스패치로~
	dispatch(bbsSelect(board_name, idx))
	.then(data => 
	{
		console.log("bbsSelect data : ", data);
		setTitle(data.select.title);
		setContents(data.select.contents);
		setWriteIdx(data.select.mem_idx);
	})
	.catch(e => {
		console.log("bbsSelect error : ", e);
		console.log(e);
	});

}, [board_name, idx, dispatch])

  return (
    <>
      	{/* Header 분리됨 MainLayout Header에 있음 */}
		  <div className={styles.new_write_area}>
			<div className={styles.inner}>
				<span className={styles.tit}>글수정</span>
				<div className={styles.wrap}>
					<div className={styles.title_area}>
						<span className={styles.tit_info}>제목</span>
						<input type="text" value={title}  onChange={onTitleChange} />
					</div>
					<div className={styles.content_area}>
						<span className={styles.tit_info}>내용</span>
						<textarea name="write" id={styles.write_textarea} maxLength="2000" onChange={onContentsChange}  value={contents} />
					</div>
				</div>
				<div className={`${styles.btn_area} ${styles.cf}`}>
					<a href={`/BoardList/${board_name}/${prevPage}`} className={styles.re}>목록</a>
					<a href="#" className={styles.finish} onClick={onModifySubmit}>수정</a>
				</div>
			</div>
		</div>
		{/* Footer 분리됨 MainLayout Footer에 있음 */}
    </>
  );
}