import * as React from 'react';
import { useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import styles from "../css/common.module.css";
import $ from 'jquery';
import * as common from "../lib";

import { solutionNonMemberList } from "../actions/solution";
import { useNavigate, useParams } from 'react-router-dom';

import { useTranslation, Trans } from 'react-i18next'; // 1. react-i18next import 

export default function NonMemberQnaInfo() {

	const authState = useSelector(state => state.auth);
    // console.log("authState : ", authState);
	const solutionState = useSelector(state => state.solution);
    // console.log("solutionState : ", solutionState);

	const dispatch = useDispatch();	
	const paramName  = useParams().name;
	const paramEmail  = useParams().email;

	const { t, i18n } = useTranslation(); // 3. useTranslation hook 선언

	useEffect(() => {

		// 여기에서 jQuery를 초기화 합니다.
		$(document).ready(function(){

			// console.log("jquery ready!");

            //결제 정보 페이지 페이징 버튼	
			$(`.${styles.list_pagebtn_area} li`).on('click',function() 
			{
				let tg=$(this);
				let num=tg.index();
				let prev;
				let next;
				let last;
				let nowBtn=$(`.${styles.list_pagebtn_area}`).children(`.${styles.on}`);
				let nowIndex=nowBtn.index();
				
				$(`.${styles.list_pagebtn_area} li`).removeClass(`${styles.on}`);
				
				if(tg.hasClass(`${styles.arrow_btn}`)==true)
				{
					if(tg.hasClass(`${styles.first}`)==true)
					{
						tg=$(`.${styles.list_pagebtn_area} li`).eq(2);
					}
					else if(tg.hasClass(`${styles.prev}`)==true)
					{
						prev=nowIndex-1;
						
						if(nowIndex===2)
						{
							tg=$(`.${styles.list_pagebtn_area} li`).eq(2);
						}else
						{
							tg=$(`.${styles.list_pagebtn_area} li`).eq(prev);
						};
						// console.log("prev click");
					}
					else if(tg.hasClass(`${styles.next}`)==true)
					{
						let lastNum=$(`.${styles.list_pagebtn_area} li`).last().index();
						next=nowIndex+1;
						last=lastNum-2;
						
						if(nowIndex===last)
						{
							tg=$(`.${styles.list_pagebtn_area} li`).eq(last);
						}else
						{
							tg=$(`.${styles.list_pagebtn_area} li`).eq(next);
						};
						// console.log("next click");
					}
					else if(tg.hasClass(`${styles.last}`)==true)
					{
						last=num-2;
						tg=$(`.${styles.list_pagebtn_area} li`).eq(last);
					};
					
					tg.addClass(`${styles.on}`);
					
				}
				else
				{
					tg.addClass(`${styles.on}`);
				};
			});

		});

		const sendData = {
			name: paramName,
			email: paramEmail,
			board_page: 1
		};

		// console.log("sendData : ", sendData);

		// 여기서 디스패치로~
		dispatch(solutionNonMemberList(sendData))
		.then(data => 
		{
			// console.log("data : ", data);
		})
		.catch(e => {
			console.log(e);
		});

	}, [$]);

  return (
    <>
      	{/* Header 분리됨 MainLayout Header에 있음 */}

          <div className={styles.mypage_top_line_banner}>
			<div className={styles.inner}>
				<span>{/* 비회원 문의내역 */}{t('FQA.NonQna.title1')}</span>
			</div>
		</div>
		<div className={`${styles.mypage_tab} ${styles.mypage_qna_info} ${styles.no_member_qna_info}`}>
			<div className={styles.inner}>
				<span className={styles.my_tit}>{/* 문의 내역 */}{t('FQA.NonQna.title2')}</span>
				<div className={styles.qna_info_area}>
					<div className={styles.title}>
						<div></div>
						<p>{/* 비회원 문의 내역 */}{t('FQA.NonQna.string1')}</p>
					</div>
					<ul className={styles.qna_info_list_wrap}>
						<li className={styles.list_head}>
							<div className={styles.qna_number}>
								<span>{/* 번호 */}{t('FQA.NonQna.string2')}</span>
							</div>
							<div className={styles.qna_title}>
								<span>{/* 제목 */}{t('FQA.NonQna.string3')}</span>
							</div>
							<div className={styles.qna_name}>
								<span>{/* 이름 */}{t('FQA.NonQna.string4')}</span>
							</div>
							<div className={styles.qna_date}>
								<span>{/* 문의날짜 */}{t('FQA.NonQna.string5')}</span>
							</div>
							<div className={styles.qna_state}>
								<span>{/* 답변상태 */}{t('FQA.NonQna.string6')}</span>
							</div>
							<div></div> 
						</li>
						{
							solutionState.list?.length === 0
							? <li className={styles.no_list}>
							<span>{/* 문의 내역이 없습니다 */}{t('FQA.NonQna.string7')}</span>
						</li>
							: null

						}
						{/* <li className={styles.no_list}>
							<span>문의 내역이 없습니다</span>
						</li> */}

						{
							solutionState.list && solutionState.list?.map((row, index) => (

								<li className=
									{`
										${styles.list} 
										${row.answer === null ? `` : `${styles.complete}`}
									`}
									key={index} >
									<div className={styles.number_area}>
										<span>{index+1}</span>
									</div>
									<div className={styles.right_area}>
										<div className={styles.top_area}>
											<span className={styles.qna_title}>{row.title}</span>
										</div>
										<div className={styles.middle_area}>
											<div className={styles.name}>
												<span>{row.name}</span>
											</div>
											<span className={styles.date}>{common.dateChangeReturn(row.reg_date)}</span>
											<span className={styles.state}>
												{
													// row.answer === null || row.answer === "" ? "답변대기" : "답변완료"
													row.answer === null || row.answer === "" ? t('FQA.NonQna.string8') : t('FQA.NonQna.string9')
												}
											</span>
										</div>
										<div className={styles.bottom_area}>
											<a href={`/NonMemberQnaInfoMore/${row.idx}/${row.name}/${row.email}`}>{/* 문의 상세보기 */}{t('FQA.NonQna.string10')}</a>
										</div>
									</div>
								</li>

							))
						}
						{/* <li className={styles.list}>
                            <div className={styles.number_area}>
                                <span>2</span>
                            </div>
                            <div className={styles.right_area}>
                                <div className={styles.top_area}>
                                    <span className={styles.qna_title}>회원가입 후 이메일 인증이 안되요</span>
                                </div>
                                <div className={styles.middle_area}>
                                    <div className={styles.name}>
                                        <span>김코딩</span>
                                    </div>
                                    <span className={styles.date}>2022.09.01</span>
                                    <span className={styles.state}>답변대기</span>
                                </div>
                                <div className={styles.bottom_area}>
                                    <a href="/NonMemberQnaInfoMore">문의 상세보기</a>
                                </div>
                            </div>
						</li>
						<li className={`${styles.list} ${styles.complete}`}>
                            <div className={styles.number_area}>
                                <span>1</span>
                            </div>
                            <div className={styles.right_area}>
                                <div className={styles.top_area}>
                                    <span className={styles.qna_title}>회원가입 후 이메일 인증이 안되요회원가입 후 이메일 인증이 안되요회원가입 후 이메일 인증이 안되요</span>
                                </div>
                                <div className={styles.middle_area}>
                                    <div className={styles.name}>
                                        <span>김코딩</span>
                                    </div>
                                    <span className={styles.date}>2022.09.01</span>
                                    <span className={styles.state}>답변완료</span>
                                </div>
                                <div className={styles.bottom_area}>
                                    <a href="/NonMemberQnaInfoMore">문의 상세보기</a>
                                </div>
                            </div>
						</li> */}
					</ul>
					{/* <button type="button" className={styles.list_more_btn}>+ 더보기</button>
					<ul className={styles.list_pagebtn_area}>
						<li className={`${styles.arrow_btn} ${styles.first}`}><a href="#"><img src="/assets/first_btn@2x.png" alt="첫번째 페이지로 이동"/></a></li>
						<li className={`${styles.arrow_btn} ${styles.prev}`}><a href="#"><img src="/assets/prev_btn@2x.png" alt="이전 페이지로 이동"/></a></li>
						<li className={styles.on}><a href="#">1</a></li>
						<li><a href="#">2</a></li>
						<li><a href="#">3</a></li>
						<li><a href="#">4</a></li>
						<li><a href="#">5</a></li>
						<li><a href="#">6</a></li>
						<li><a href="#">7</a></li>
						<li><a href="#">8</a></li>
						<li><a href="#">9</a></li>
						<li><a href="#">10</a></li>
						<li className={`${styles.arrow_btn} ${styles.next}`}><a href="#"><img src="/assets/next_btn@2x.png" alt="다음 페이지로 이동"/></a></li>
						<li className={`${styles.arrow_btn} ${styles.last}`}><a href="#"><img src="/assets/last_btn@2x.png" alt="마지막 페이지로 이동"/></a></li>
					</ul> */}
				</div>
			</div>{/* //inner */}
		</div>{/* //mypage_tab */}
		
		{/* Footer 분리됨 MainLayout Footer에 있음 */}
    </>
  );
}