import * as React from 'react';
import styles from "../../css/common.module.css";

import Grid from '@mui/material/Grid';
import { useState } from "react";
import { useDispatch } from "react-redux";
import { passwordChange } from "../../actions/auth";
import { useNavigate } from 'react-router-dom';

import TextField from '@mui/material/TextField';

import { useTranslation, Trans } from 'react-i18next'; // 1. react-i18next import 

export default function PasswordChange() {

    const initialAuthState = {
        email: ""
    };

    const [auth, setAuth] = useState(initialAuthState);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { t, i18n } = useTranslation(); // 3. useTranslation hook 선언

    const handleInputChange = event => {
        const { name, value } = event.target;
        setAuth({ ...auth, [name]: value });
    };

    const handleSubmit = (event) => {

        event.preventDefault();

        const { email } = auth;

        if (email === "")
        {
            // return alert("이메일을 입력해주세요!");
            return alert(`${t("Auth.alert.string1")}`);
        }

        const regEmail = /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/;
        if (regEmail.test(email) === false) 
        {
            // return alert('입력된 값은 이메일 형식이 아닙니다.');
            return alert(`${t("Auth.alert.string7")}`);
        }

        let senedData = {
            email: email
        }

        // 여기서 디스패치로~
        dispatch(passwordChange(senedData))
        .then(data => 
        {
            // console.log("data : ", data);
            
            if (!data.success)
            {
                alert(data.msg);
                return;
            }

            // alert("이메일로 임시패스워드가 발송되었습니다.");
            alert(`${t("Auth.alert.string22")}`);
            navigate("/auth/login");
        })
        .catch(e => {
            console.log(e);
        });
    };

    return (
        <Grid container component={styles.main} sx={{ height: '100vh' }}>
            <div className={`${styles.login_bg_wrapper} ${styles.password_bg_wrapper}`}>
                <div className={`${styles.password_reset_area} ${styles.middle}`}>
                    <div className={styles.login_top}>
                        <a href="/" className={styles.logo}><img src="/assets/logo@2x.png" alt="logo"/></a>
                    </div>
                    <span className={styles.login_title}>{/* 비밀번호 재설정 */}<Trans i18nKey="Auth.password_change"/></span> 
                    <div className={`${styles.password_reset_wrap} ${styles.center_align}`}>
                        <p>{/* 회원가입 시 입력한 메일을 입력해 주세요. <br/>메일로 받으신 임시 비밀번호는 반드시 변경해 주시기 바랍니다. */}
                        <Trans i18nKey="Auth.password_change_string1"/></p>
                        <div className={`${styles.password_reset_input_area} ${styles.input_area}`}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label="Email Address"
                                name="email"
                                autoComplete="email"
                                autoFocus
                                onChange={handleInputChange}
                            />
                        </div>
                        <a href="#" className={styles.ok} onClick={handleSubmit}>{/* 임시 비밀번호 받기 */}
                            <Trans i18nKey="Auth.password_change_string2"/>
                        </a>
                    </div>
                </div>
            </div>
        </Grid>
    );
}