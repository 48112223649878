import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { logout } from "../actions/auth"
import styles from "../css/common.module.css";
import $ from 'jquery';
import http from "../http-common";

export default function Main() {

	const authState = useSelector(state => state.auth);
    // console.log("authState : ", authState);

	const [popupSuccess, setPopupSuccess] = useState(0);
	const [popupMsg, setPopupMsg] = useState("");

	const dispatch = useDispatch();	

	useEffect(() => {

		// 여기에서 jQuery를 초기화 합니다.
		$(document).ready(function(){

			// console.log("jquery ready!");

			// 출시 후 배너 활성화하면 '출시 후 삭제'주석 부분 삭제
			//타이틀(메인배너) 페이드 인아웃 배너
			let banner=$(`.${styles.bn}`);
			let bnBtn=$(`.${styles.bn_btn}`);
			let currentBn=0;
			let setIntervalId1=setInterval(function(){ bnMove() },6000);

			function bnMove()
			{
				let prev=banner.eq(currentBn);
				let prevShowBounce=prev.find(`.${styles.showBounce}`);
				let prevBounceIn=prev.find(`.${styles.bounceIn}`);
				let prevBnImg=prevBounceIn.find('img');

				bnBtn.removeClass(`${styles.on}`);
				prev.removeClass(`${styles.active}`);
				prevShowBounce.removeClass(`${styles.on}`);
				prevBounceIn.removeClass(`${styles.on}`);
				prevBnImg.removeClass(`${styles.on}`);
				
				currentBn++;
				if(currentBn==banner.length){currentBn=0};

				let next=banner.eq(currentBn);
				let nextShowBounce=next.find(`.${styles.showBounce}`);
				let nextBounceIn=next.find(`.${styles.bounceIn}`);
				let nextBnImg=nextBounceIn.find('img');

				next.addClass(`${styles.active}`);
				bnBtn.eq(currentBn).addClass(`${styles.on}`);
				nextShowBounce.addClass(`${styles.on}`);
				nextBounceIn.addClass(`${styles.on}`);
				nextBnImg.addClass(`${styles.on}`);
			}
			
			//버튼 클릭시 해당 배너로 전환
			bnBtn.on('click',function(){
				let tg=$(this);
				let i=tg.index();

				clearInterval(setIntervalId1);

				bnBtn.removeClass(`${styles.on}`);
				tg.addClass(`${styles.on}`);
				bnmoveByBtn(i);

				$(`.${styles.bn_play}`).removeClass(`${styles.stop}`);

				setIntervalId1=setInterval(function(){ bnMove() },6000);

				return false;
			});

			function bnmoveByBtn(i){
				if(currentBn===i){return};
				let prevBn=banner.eq(currentBn);
				let nextBn=banner.eq(i);

				prevBn.removeClass(`${styles.active}`);
				banner.eq(currentBn).find(`.${styles.showBounce}`).removeClass(`${styles.on}`);
				banner.eq(currentBn).find(`.${styles.bounceIn}`).removeClass(`${styles.on}`);
				banner.eq(currentBn).find(`.${styles.bounceIn} img`).removeClass(`${styles.on}`);

				nextBn.addClass(`${styles.active}`);
				banner.eq(i).find(`.${styles.showBounce}`).addClass(`${styles.on}`);
				banner.eq(i).find(`.${styles.bounceIn}`).addClass(`${styles.on}`);
				banner.eq(i).find(`.${styles.bounceIn} img`).addClass(`${styles.on}`);

				currentBn=i;
			};

			//배너 재생&멈춤 버튼
			$(`.${styles.bn_play}`).on('click',function()
			{
				let btn=$(`.${styles.bn_play}`);
				let play=btn.children(`.${styles.play}`);
				
				if(play.css('display')==='none')
				{
					//배너 멈춤
					btn.addClass(`${styles.stop}`);
					clearInterval(setIntervalId1);
					// console.log('배너 멈춤')
				}
				else
				{
					//배너 재생
					btn.removeClass(`${styles.stop}`);
					setIntervalId1=setInterval(function(){ bnMove() },6000);
					// console.log('배너 재생')
				};
			});

			//한번 스크롤할때마다 한페이지씩 이동
			let mHtml=$('html');
			let page=1;
			let pageEnd=$(`.${styles.content_wrapper}`).children('section').length;
			
			mHtml.on('click',function(e)
			{ 
				
				if(!$(`.${styles.introduce_area} .${styles.ym_who_btn_area}`).has(e.target).length)
				{
					// console.log('레이어팝업 외의 영역입니다');
					$(`.${styles.introduce_area} .${styles.ym_who_btn}`).removeClass(`${styles.on}`);
					$(`.${styles.introduce_area} .${styles.ym_who_popup}`).removeClass(`${styles.on}`);
				}
				
			});
			
			let isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent) ? true : false;
			
			if(!isMobile) 
			{
				//PC
				$('section').css('top','0').css('position','relative');
		
				//창 크기 변동될때마다 페이지 화면에 다시 맞춤
				$( window ).resize( function()
				{
					let posTop =(page-1) * $(window).height();
					mHtml.animate({scrollTop : posTop},10);
				});

				//스크롤시 탑 배경 생성
				$(`.${styles.content_wrapper}`).css({paddingTop:'0'});
				$(`.${styles.header}`).css({background:'rgba(255, 255, 255, 0.7)'});

				//풀페이지 스크롤
				window.addEventListener("wheel", function(e)
				{
					e.preventDefault();
				},{passive : false});	

				let currentUrl=window.location.href; //현재 url
		
				if(currentUrl.includes('#main')===true) //현재 url이 코딩수업 페이지면
				{
					// console.log('코딩수업페이지 입니다');
					page=7;
				}
				else
				{
					// console.log('코딩수업페이지가 아닙니다');
				};
				
				$(`.${styles.header} .${styles.menu_list}`).eq(1).on('click',function()
				{
					page=7;
				});
				
				$(window).on("wheel", function(e) 
				{
					if(mHtml.is(":animated")) return;
					// deltaY는 휠의 이동량
					// deltaY > 0 는 휠을 아래로 내릴때
					if(e.originalEvent.deltaY > 0) {
						if(page == pageEnd) return; 
						page++;
					} else if(e.originalEvent.deltaY < 0) {
						if(page == 1) return;
						page--;
					}
					let posTop =(page-1) * $(window).height();
					mHtml.animate({scrollTop : posTop},500);
				})

				//휠 이벤트 방법1 출처 https://itstudy-mary.tistory.com/371

				//스크롤 이벤트
				$(window).on('scroll',function(event){
					
					let sct=$(this).scrollTop();
					
					//스크롤시 탑 배경 생성
					if(sct>=300)
					{
						$(`.${styles.header}`).css({background:'#ffffff'});
					}
					else
					{
						$(`.${styles.header}`).css({background:'rgba(255, 255, 255, 0.7)'});
					}
					
					//페이지 이동 플로팅 버튼-스크롤
					let btn=$(`.${styles.floating_paging_btn_wrap} li`);
					let num=page-2;
					
					btn.removeClass(`${styles.on}`);
					btn.eq(num).addClass(`${styles.on}`);
					
					//스크롤시 애니메이션
					$(`.${styles.main_full_area}`).find(`.${styles.fadeInUp}`).removeClass(`${styles.fadeInUp}`);
					$(`.${styles.ym_myroom_area} .${styles.graph_area1}  .${styles.wrap} .${styles.bar}`).removeClass(`${styles.on}`);
					$(`.${styles.ym_myroom_area} .${styles.graph} img`).removeClass(`${styles.on}`); 
					$(`.${styles.ym_myroom_area} .${styles.graph_area3} .${styles.counting}`).val(0);
					
					if(page==1)
					{
						btn.removeClass(`${styles.on}`);

					}
					else if(page==2)
					{
						$(`.${styles.introduce_area} .${styles.inner90}`).addClass(`${styles.fadeInUp}`);
					}
					else if(page==3)
					{
						$(`.${styles.ym_world_area} .${styles.index_tit}`).addClass(`${styles.fadeInUp}`);
						$(`.${styles.ym_world_area} p.txt`).addClass(`${styles.fadeInUp}`);
						$(`.${styles.ym_world_area} .${styles.bottom_area}`).addClass(`${styles.fadeInUp}`);
					}
					else if(page==4)
					{
						$(`.${styles.ym_difference_area} .${styles.sub_title}`).addClass(`${styles.fadeInUp}`);
						$(`.${styles.ym_difference_area} .${styles.index_tit}`).addClass(`${styles.fadeInUp}`);
						$(`.${styles.ym_difference_area} .${styles.tabbtn_area}`).addClass(`${styles.fadeInUp}`);
						$(`.${styles.ym_difference_area} .${styles.middle_area}`).addClass(`${styles.fadeInUp}`);
						$(`.${styles.ym_difference_area} .${styles.img_area}`).addClass(`${styles.fadeInUp}`);
					}
					else if(page==5)
					{
						$(`.${styles.ym_myroom_area} .${styles.index_tit}`).addClass(`${styles.fadeInUp}`);
						$(`.${styles.ym_myroom_area} p.txt`).addClass(`${styles.fadeInUp}`);
						$(`.${styles.ym_myroom_area} .${styles.bottom_area}`).addClass(`${styles.fadeInUp}`);
						
						let currentBox=$(`.${styles.ym_myroom_area} .${styles.box}.${styles.on}`).index();
				
						if(currentBox===0)
						{
							//1번째 박스가 활성화상태일때
							$(`.${styles.ym_myroom_area} .${styles.graph_area1} .${styles.wrap} .${styles.bar}`).addClass(`${styles.on}`);
						}
						else if(currentBox===1)
						{
							//2번째 박스가 활성화상태일때
							$(`.${styles.ym_myroom_area} .${styles.graph} img`).addClass(`${styles.on}`);
						}
						else
						{
							//3번째 박스가 활성화상태일때
							myroomCounting();
						};
						
					}
					else if(page==6)
					{
						$(`.${styles.brain_music_area} .${styles.sub_title}`).addClass(`${styles.fadeInUp}`);
						$(`.${styles.brain_music_area} .${styles.index_tit}`).addClass(`${styles.fadeInUp}`);
						$(`.${styles.brain_music_area} ul`).addClass(`${styles.fadeInUp}`);
					}
					else if(page==7)
					{
						$(`.${styles.coding_block_area} .${styles.index_tit}`).addClass(`${styles.fadeInUp}`);
						$(`.${styles.coding_block_area} .${styles.bottom_area}`).addClass(`${styles.fadeInUp}`);
					}
					else if(page==8)
					{
						$(`.${styles.yf_area} .${styles.sub_title}`).addClass(`${styles.fadeInUp}`);
						$(`.${styles.yf_area} .${styles.index_tit}`).addClass(`${styles.fadeInUp}`);
						$(`.${styles.yf_area} .${styles.content_area}`).addClass(`${styles.fadeInUp}`); 
					};
					
				});
				
			} 
			else 
			{
				//MOBILE
				window.addEventListener("touchmove", function(e)
				{
					e.preventDefault();
				},{passive : false});	

				//풀페이지 스크롤 모바일 touchmove
				let ts;
				
				page=0;
				$(`.${styles.header}`).css({background:'rgba(255, 255, 255, 0.7)'});
				$('section').css('top','100vh').css('position','fixed');
				$('section').eq(0).css('top','0');
				$(`.${styles.footer_section}`).css('position','fixed').css('top','100vh').css('height','100vh').css('padding-top','80px').css('padding-bottom','8vh').css('background','#1a1a1a');
				$('.footer_section').css('position','fixed').css('top','100vh').css('height','100vh').css('padding-top','80px').css('padding-bottom','8vh');
				$(`.${styles.footer}`).css('height','100%');
				$(`.${styles.floating_paging_btn_wrap}`).css('display','none');

				//화면 크기 변동되면 첫페이지로 이동
				$( window ).resize( function()
				{
					$('section').css('top','100vh').css('z-index','1');
					$('section').eq(0).css('z-index','30').css('top','0');
					page=0;

					landscapeJudgment();
				});
				
				//기기의 가로 모드/세로 모드 판단하여 가로일때 헤더 숨김
				function landscapeJudgment()
				{
					if($(window).width() < 1200)
					{
						if (window.matchMedia('(orientation: portrait)').matches) 
						{
							// Portrait 모드일 때 실행할 스크립트
							// 폭과 높이가 같으면 Portrait 모드로 인식돼요
							// alert('세로');
							$(`.${styles.header} .${styles.logo}`).css('display','block');
						} 
						else 
						{
							// Landscape 모드일 때 실행할 스크립트
							// alert('가로');
							$(`.${styles.header}`).css('background','none');
							$(`.${styles.header} .${styles.logo}`).css('display','none');

							if(page==0)
							{
								$(`.${styles.header} .${styles.logo}`).css('display','block');
							}
							else
							{
								$(`.${styles.header} .${styles.logo}`).css('display','none');
							};
						}
					}
					else
					{
						// console.log('모바일/태블릿 기기지만 큰화면');
					};
					
				}

				//다른 페이지에서 코딩수업 페이지로 이동시 페이지 스크롤
		
				function scrollCodingclass()
				{
					$(`.${styles.mobile_menu_area}`).animate({left:'-100%'},300);
					$(`.${styles.header}`).css('background','#ffffff');
					$('section').not(`.${styles.yf_area}`).not(`.${styles.footer_section}`).css('z-index','1').stop().animate({top : '-100vh'},500);
					$(`.${styles.yf_area}`).css('z-index','1').css('top', '100vh');
					$(`.${styles.footer_section}`).css('z-index','1').css('top', '100vh');
					$('section').eq(6).css('z-index','30').stop().animate({top : 0},500);
					page=6;
				}
				
				let currentUrl=window.location.href; //현재 url
				
				if(currentUrl.includes('#main')===true) //현재 url이 코딩수업 페이지면
				{
					scrollCodingclass();
					landscapeJudgment();
					// alert('코딩수업페이지');
				}
				else
				{
					// console.log('메인페이지 입니다');
					// alert('메인페이지');
				};
				
				//메인페이지에서 코딩수업 메뉴 클릭시 페이지 스크롤
				$(`.${styles.header} .${styles.bottom_menu_area} a`).eq(1).on('click',function()
				{
					scrollCodingclass();
					landscapeJudgment();
				});

				
				$('html, body').bind('touchstart', function(e)
				{
					e.stopPropagation();
					ts = e.originalEvent.touches[0].clientY;
					
				});

				$('html, body').bind('touchend', function(e)
				{
					e.stopPropagation();
					
					let te = e. originalEvent.changedTouches[0].clientY;
					
					if(ts > te + 10)
					{
						//console.log('touch down');//아래->위로 드래그
						if(page == pageEnd-1) return; 
						page++;
						// console.log('다음페이지로');
						
						if(page>0)
						{
							$(`.${styles.header}`).css({background:'#ffffff'});
						};
						
						if(page==4)
						{
							$(`.${styles.ym_myroom_area} .${styles.graph} img`).addClass(`${styles.on}`);
						};


						if(page==4)
						{
							let currentBox=$(`.${styles.ym_myroom_area} .${styles.box}.${styles.on}`).index();
					
							if(currentBox===0)
							{
								//1번째 박스가 활성화상태일때
								$(`.${styles.ym_myroom_area} .${styles.graph_area1} .${styles.wrap} .${styles.bar}`).addClass(`${styles.on}`);
							}
							else if(currentBox===1)
							{
								//2번째 박스가 활성화상태일때
								$(`.${styles.ym_myroom_area} .${styles.graph} img`).addClass(`${styles.on}`);
							}
							else
							{
								//3번째 박스가 활성화상태일때
								myroomCounting();
							};
						}
						else
						{
							$(`.${styles.ym_myroom_area} .${styles.graph_area1} .${styles.wrap} .${styles.bar}`).removeClass(`${styles.on}`);
							$(`.${styles.ym_myroom_area} .${styles.graph} img`).removeClass(`${styles.on}`);
							$(`.${styles.ym_myroom_area} .${styles.graph_area3} .${styles.counting}`).val(0);
						};

						landscapeJudgment();

						$('section').eq(page-1).css('z-index','1').stop().animate({top : '-100vh'}, 450);
						$('section').eq(page).css('z-index','30').stop().animate({top : 0}, 400);
					} 
					else if(ts < te - 10)
					{
						//console.log('touch up');//위->아래로 드래그
						if(page == 0) return; 
						page--;
						// console.log('전페이지로');
						
						if(page==0)
						{
							$(`.${styles.header}`).css({background:'rgba(255, 255, 255, 0.7)'});
						}
						else if(page==4)
						{
							let currentBox=$(`.${styles.ym_myroom_area} .${styles.box}.${styles.on}`).index();
					
							if(currentBox===0)
							{
								//1번째 박스가 활성화상태일때
								$(`.${styles.ym_myroom_area} .${styles.graph_area1} .${styles.wrap} .${styles.bar}`).addClass(`${styles.on}`);
							}
							else if(currentBox===1)
							{
								//2번째 박스가 활성화상태일때
								$(`.${styles.ym_myroom_area} .${styles.graph} img`).addClass(`${styles.on}`);
							}
							else
							{
								//3번째 박스가 활성화상태일때
								myroomCounting();
							};
						}
						else
						{
							$(`.${styles.ym_myroom_area} .${styles.graph_area1} .${styles.wrap} .${styles.bar}`).removeClass(`${styles.on}`);
							$(`.${styles.ym_myroom_area} .${styles.graph} img`).removeClass(`${styles.on}`);
							$(`.${styles.ym_myroom_area} .${styles.graph_area3} .${styles.counting}`).val(0);
						};

						$('section').eq(page+1).css('z-index','1').stop().animate({top : '100vh'},450);
						$('section').eq(page).css('z-index','30').stop().animate({top : 0},400);

						landscapeJudgment();
					}
				});
				
			}
			
			//페이지 이동 플로팅 버튼-클릭
			$(`.${styles.floating_paging_btn_wrap} li`).on('click',function(){
				let tg=$(this);
				let num=tg.index();
				
				$(`.${styles.floating_paging_btn_wrap} li`).removeClass(`${styles.on}`);
				tg.addClass(`${styles.on}`);
				page=num+2;
				
				let posTop =(page-1) * $(window).height();
				mHtml.animate({scrollTop : posTop},500);
				
			});
			
			//야미소개 클릭 이벤트
			$(`.${styles.introduce_area} .${styles.ym_who_btn}`).on('click',function(){
				let tg=$(this);
				let popup=$(`.${styles.introduce_area} .${styles.ym_who_popup}`);
				
				if(popup.hasClass(`${styles.on}`)==true)
				{
					popup.removeClass(`${styles.on}`);
					tg.removeClass(`${styles.on}`);
				}
				else
				{
					popup.addClass(`${styles.on}`);
					tg.addClass(`${styles.on}`);
				};
				
			});
			
			
			//세계관 클릭 이벤트
			$(`.${styles.ym_world_area} .${styles.plus_wrap} .${styles.plus}`).on('click',function(){
				let tg=$(this);
				let number=tg.index();
				let building=$(`.${styles.ym_world_area} .${styles.building}`);
				
				$(`.${styles.ym_world_area} .${styles.plus_wrap} .${styles.plus}`).removeClass(`${styles.on}`);
				building.removeClass(`${styles.on}`).removeClass(`${styles.showBounce}`);
				tg.addClass(`${styles.on}`);
				building.eq(number).addClass(`${styles.on}`).addClass(`${styles.showBounce}`);
				
			});
			//물리엔진 클릭 이벤트
			$(`.${styles.ym_difference_area} .${styles.tabbtn_area} .${styles.btn}`).on('click',function(){
				let tg=$(this);
				let number=tg.index();
				let infoTxt=$(`.${styles.ym_difference_area} .${styles.info_txt_wrap} p`);
				let bottomImgL=$(`.${styles.ym_difference_area} .${styles.img_area} .${styles.left_img} img`);
				let bottomImgR=$(`.${styles.ym_difference_area} .${styles.img_area} .${styles.right_img} img`);
				
				$(`.${styles.ym_difference_area} .${styles.tabbtn_area} .${styles.btn}`).removeClass(`${styles.on}`);
				infoTxt.removeClass(`${styles.on}`);
				bottomImgL.removeClass(`${styles.on}`).removeClass(`${styles.bounceIn}`);
				bottomImgR.removeClass(`${styles.on}`).removeClass(`${styles.bounceIn}`);
				tg.addClass(`${styles.on}`);
				infoTxt.eq(number).addClass(`${styles.on}`);
				bottomImgL.eq(number).addClass(`${styles.on}`).addClass(`${styles.bounceIn}`);
				bottomImgR.eq(number).addClass(`${styles.on}`).addClass(`${styles.bounceIn}`);
				
			});
			//마이룸 클릭 이벤트
			$(`.${styles.ym_myroom_area} .${styles.box}`).on('click',function(){
				let tg=$(this);
				let number=tg.index();
				let graph1=$(`.${styles.ym_myroom_area} .${styles.graph_area1} .${styles.wrap} .${styles.bar}`);
				let graph2=$(`.${styles.ym_myroom_area} .${styles.graph} img`);
				let graph3=$(`.${styles.ym_myroom_area} .${styles.graph_area3} .${styles.counting}`);
				
				$(`.${styles.ym_myroom_area} .${styles.box}`).removeClass(`${styles.on}`);
				graph1.removeClass(`${styles.on}`);
				graph2.removeClass(`${styles.on}`);
				graph3.val(0);
				tg.addClass(`${styles.on}`);
				
				if(number===0)
				{
					graph1.stop(500).addClass(`${styles.on}`);
				}
				else if(number===1)
				{
					graph2.stop(500).addClass(`${styles.on}`);
				}
				else if(number===2)
				{
					myroomCounting();
				};
			});

			function myroomCounting()
			{
				// 숫자 카운트 효과
				let untilNum1=67;
				let untilNum2=23;
				let untilNum3=47;
				let countingTg1=$(`.${styles.graph_area3} .${styles.counting}`).eq(0);
				let countingTg2=$(`.${styles.graph_area3} .${styles.counting}`).eq(1);
				let countingTg3=$(`.${styles.graph_area3} .${styles.counting}`).eq(2);
			
				$({ val : 0 }).animate({ val : untilNum1 }, {
				duration:2000,
				step: function() {
					var num = numberWithCommas(Math.floor(this.val));
					countingTg1.text(num);
				},
				complete: function() {
					var num = numberWithCommas(Math.floor(this.val));
					countingTg1.text(num);
				}
				});
				$({ val : 0 }).animate({ val : untilNum2 }, {
				duration:1000,
				step: function() {
					var num = numberWithCommas(Math.floor(this.val));
					countingTg2.text(num);
				},
				complete: function() {
					var num = numberWithCommas(Math.floor(this.val));
					countingTg2.text(num);
				}
				});
				$({ val : 0 }).animate({ val : untilNum3 }, {
				duration:1000,
				step: function() {
					var num = numberWithCommas(Math.floor(this.val));
					countingTg3.text(num);
				},
				complete: function() {
					var num = numberWithCommas(Math.floor(this.val));
					countingTg3.text(num);
				}
				});

				function numberWithCommas(x) {
					return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
				}
				
				// 출처:https://codepen.io/gahyun/pen/yQXKON

			};
			
			//뇌음악 버튼 이미지 교체
			$(`.${styles.brain_music_area} .${styles.play_wrap}`).on('click',function()
			{	
				let tg=$(this);
				let wrap=tg.parent('li');
				// console.log(`${styles.on}`);
				let play=tg.children(`.${styles.play}`);
				let stop=tg.children(`.${styles.stop}`);
				
				$(`.${styles.brain_music_area} .${styles.wrap} li`).removeClass(`${styles.on}`);
				wrap.addClass(`${styles.on}`);
				
				if(play.css('visibility')==='visible')
				{
					$(`.${styles.brain_music_area} .${styles.play_wrap} .${styles.stop}`).removeClass(`${styles.on}`);
					$(`.${styles.brain_music_area} .${styles.play_wrap} .${styles.play}`).addClass(`${styles.on}`);
					play.removeClass(`${styles.on}`);
					stop.addClass(`${styles.on}`);
				}
				else
				{
					stop.removeClass(`${styles.on}`);
					play.addClass(`${styles.on}`);
					$(`.${styles.brain_music_area} .${styles.wrap} li`).removeClass(`${styles.on}`);
				};
				
			});
			
			//3가지요소 클릭 이벤트
			$(`.${styles.coding_block_area} .${styles.cont}`).on('click',function(){
				let tg=$(this);
				let number=tg.index();
				let arrow1=$(`.${styles.coding_block_area} .${styles.arrow1}`);
				let arrow2=$(`.${styles.coding_block_area} .${styles.arrow2}`);
				
				$(`.${styles.coding_block_area} .${styles.cont}`).removeClass(`${styles.on}`);
				$(`.${styles.coding_block_area} .${styles.arrow_wrap}`).removeClass(`${styles.on}`);
				tg.addClass(`${styles.on}`);
				
				if(number===0)
				{
					arrow1.addClass(`${styles.on}`);
				}
				else if(number===2)
				{
					arrow2.addClass(`${styles.on}`);
				};
			});

			//야미프렌즈 클릭 이벤트
			$(`.${styles.yf_area} .${styles.btn_area} li`).on('click',function()
			{
				let tg=$(this);
				let number=tg.index();
				let now=$(`.${styles.yf_area} .${styles.btn_area}`).find(`.${styles.on}`).index();
				let yfImg=$(`.${styles.yf_area} .${styles.yf_img_area} img`);
				let album=$(`.${styles.yf_area} .${styles.yf_info_area} .${styles.album}`);
				let albumImg1=$(`.${styles.yf_area} .${styles.yf_info_area} .${styles.album1} img`);
				let albumImg2=$(`.${styles.yf_area} .${styles.yf_info_area} .${styles.album2} img`);
				let yfTxt=$(`.${styles.yf_area} .${styles.yf_info_area} .${styles.txt_box}`);
				
				$(`.${styles.yf_area} .${styles.btn_area} li`).removeClass(`${styles.on}`);
				yfImg.removeClass(`${styles.on}`).removeClass(`${styles.bounceIn}`);
				$(`.${styles.yf_area} .${styles.yf_info_area} .${styles.album} img`).removeClass(`${styles.on}`);
				yfTxt.removeClass(`${styles.on}`);
				
				tg.addClass(`${styles.on}`);
				yfImg.eq(number).addClass(`${styles.on}`).addClass(`${styles.bounceIn}`);
				albumImg1.eq(number).addClass(`${styles.on}`);
				albumImg2.eq(number).addClass(`${styles.on}`);
				yfTxt.eq(number).addClass(`${styles.on}`);
				
				// console.log("number : ", number);
				// console.log("now : ", now);
				if(number===now)
				{
					return;
				}
				else
				{
					if(album.hasClass(`${styles.on}`)===true)
					{
						album.removeClass(`${styles.on}`);
					}
					else
					{
						album.addClass(`${styles.on}`);
					};
				};
				
				
			});

			// 쿠키 읽고 화면 보이게
			if (getStorage("today")) 
			{
				$(`.${styles.notice_popup_bg}`).css('display','none');
			} 
			else 
			{
				$(`.${styles.notice_popup_bg}`).css('display','block');
			}
		});

	}, [$]);

	//뇌음악 음악
	const brainMusic=new Audio();
	brainMusic.src="audio/01.mp3"; 

	function musicOnly(i)
	{
		brainMusic.src="audio/0"+i+".mp3";
		brainMusic.play();
	}

	//이메일 알림 신청 배너 확인 팝업
	const emailNotification = (e) =>
	{
		e.preventDefault();

		// 사용자가 입력한 이메일 값을 가져옵니다
		let userEmail = $(`#${styles.banner_email_input}`).val();
		// console.log("userEmail : ", userEmail);

		const regEmail = /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/;
		if (userEmail === "")
		{
			setPopupSuccess(1);
			setPopupMsg("이메일을 입력해주세요.");
			blackBg(1);
			$(`.${styles.email_banner_popup}`).css('display','block');
			return false;
		}
		else if (regEmail.test(userEmail) === false)
		{
			setPopupSuccess(1);
			setPopupMsg("입력된 값은 이메일 형식이 아닙니다.");
			blackBg(1);
			$(`.${styles.email_banner_popup}`).css('display','block');
			return false;
		}

		// axios로 HTTP 요청
		const sendData = { email: userEmail };

		http.post("emailCollection", sendData
		).then((data) => {
			
			// console.log(data);

			if (!data.data.success)
			{
				setPopupSuccess(1);
				setPopupMsg(data.data.msg);
			}
			else
			{
				setPopupSuccess(0);
				setPopupMsg("");
			}
		})

		// blackBg(1);
		// $(`.${styles.email_banner_popup}`).css('display','block');
	}

	//팝업의 확인 클릭시 팝업 닫힘
	const closeEmailPopup = () =>
	{
		$(`#${styles.banner_email_input}`).val('');

		setPopupMsg("");
		blackBg(2);
		$(`.${styles.email_banner_popup}`).css('display','none');
	}

	//검정 반투명 배경 제어
	const blackBg = (i) =>
	{
		if(i===1)
			{
				$(`.${styles.body_black}`).css('display','block');
				// $('body').css('overflow','hidden');
				// console.log('배경보임');
			}
			else
			{
				$(`.${styles.body_black}`).css('display','none');
				// $('body').css('overflow','visible');
				// console.log('배경꺼짐');
			};
	};

	//공지팝업 닫기
	const closeNoticePopup = () =>
	{
		setStorage("today", 1);
		$(`.${styles.notice_popup_bg}`).css('display','none');
		
		// let checkbox=$(`#${styles.notice_popup_no_more_check}`);
		
		// if(checkbox.is(':checked')==true)
		// {
		// 	// console.log("closeNoticePopup");
		// 	// 로컬 스토리지에 today라는 이름으로 1일(24시간 뒤) 동안 보이지 않게
		// 	setStorage("today", 1);
		
		// 	$(`.${styles.notice_popup_bg}`).css('display','none');
		// }
		// else
		// {
		// 	$(`.${styles.notice_popup_bg}`).css('display','none');
		// 	// console.log('그냥 닫기');
		// };
	}

	$(`#${styles.notice_popup_no_more_check}`).on('change',function()
	{
		// console.log($('#notice_popup_no_more_check').val());
	});

	// 스토리지에 데이터 쓰기(이름, 만료일)
	const setStorage = (name, exp) =>
	{
		// console.log("setStorage");
		// 만료 시간 구하기(exp를 ms단위로 변경)
		let date = new Date();
		date = date.setTime(date.getTime() + exp * 24 * 60 * 60 * 1000);

		// 로컬 스토리지에 저장하기
		// (값을 따로 저장하지 않고 만료 시간을 저장)
		localStorage.setItem(name, date);
	}
	
	// 스토리지 읽어오기
	const getStorage = (name) =>
	{
		// console.log("getStorage");

		let now = new Date();
		now = now.setTime(now.getTime());

		// 현재 시각과 스토리지에 저장된 시각을 각각 비교하여
		// 시간이 남아 있으면 true, 아니면 false 리턴
		return parseInt(localStorage.getItem(name)) > now;
	}

	const style = {
		color: "#1e83d6"
	}

  return (
    <>
      	{/* Header 분리됨 MainLayout Header에 있음 */}

		{/* 이메일 수집 확인 팝업 */}
		{/* <div className={styles.body_black}></div>
		<div className={styles.notice_popup_bg}>
			<div>
				<div className={styles.notice_popup_area}>
					<div className={styles.notice_popup}>
						<div className={styles.notice_popup_content}>
							<p>
								긴급 공지<br></br><br></br>현재 발견되고 있는 증상에 대한 안내<br></br><br></br>증상<br></br>
								안드로이드 버전 12가 설치된 기기에서 앱 설치는 진행되지만 실행시 작동 중지되는 현상이 발생하고 있습니다.
								<br></br><br></br>해결 방법<br></br>안드로이드 최신 업데이트를 확인하시고 업데이트를 진행해 주시기 바랍니다.
								<br></br>아래의 해결 방법링크를 클릭하시면 해결방법을 보실 수 있습니다.
								<br></br><br></br><span><a href="/notice.html" target='_blank' style={style}>[해결방법 클릭!]</a></span>
								<br></br><br></br>이와 관련해 문제가 지속될 경우 이메일로 문의 주시기 바랍니다.
								<br></br><br></br><span>admin@weclover.co.kr</span>
							</p>
							<p className={styles.mobile_horizontal}>
								긴급 공지<br></br><br></br>현재 발견되고 있는 증상에 대한 안내<br></br><br></br>증상<br></br>
								안드로이드 버전 12가 설치된 기기에서 앱 설치는 진행되지만 실행시 작동 중지되는 현상이 발생하고 있습니다.
								<br></br><br></br>해결 방법<br></br>안드로이드 최신 업데이트를 확인하시고 업데이트를 진행해 주시기 바랍니다.
								<br></br>아래의 해결 방법링크를 클릭하시면 해결방법을 보실 수 있습니다.
								<br></br><br></br><span ><a href="/notice.html" target='_blank' style={style}>[해결방법 클릭!]</a></span>
								<br></br><br></br>이와 관련해 문제가 지속될 경우 이메일로 문의 주시기 바랍니다.
								<br></br><br></br><span>admin@weclover.co.kr</span>
							</p>
						</div>
						<button type="button" onClick={closeNoticePopup}></button>
					</div>
					<div className={styles.no_more_show_wrap}>
						<input type="checkbox" id={styles.notice_popup_no_more_check}/>
						<p className={styles.no_more_show}>오늘 하루 보지 않기</p>
					</div>
				</div>
			</div>
		</div> */}
		<div className={styles.body_black}></div>
		<div className={styles.notice_popup_bg}>
			<div>
				<div className={styles.notice_popup_area}>
					<div className={styles.notice_popup}>
						<div className={styles.notice_popup_content}>
							<p>
								<img src='/assets/yame_faq_popup.png' alt="yame faq popup" width={350} usemap="#coupon_down" />
								<map name="coupon_down">
									<area shape="rect" coords="80,290,300,350" href="/Faq" alt="FAQ 바로가기" />
								</map>
							</p>
							<p className={styles.mobile_horizontal}>
								<img src='/assets/yame_faq_popup.png' alt="yame faq popup" width={350} usemap="#coupon_down" />
								<map name="coupon_down">
									<area shape="rect" coords="80,290,300,350" href="/Faq" alt="FAQ 바로가기" />
								</map>
							</p>
						</div>
						<button type="button" onClick={closeNoticePopup}></button>
					</div>
					{/* <div className={styles.no_more_show_wrap}>
						<input type="checkbox" id={styles.notice_popup_no_more_check}/>
						<p className={styles.no_more_show}>오늘 하루 보지 않기</p>
					</div> */}
				</div>
			</div>
		</div>
		
		{/* <div className={styles.email_banner_popup}>
			<div>
				{
					popupSuccess === 0
					? <p>신청이 완료되었습니다. <br/>감사합니다.</p>
					: <p>{popupMsg}</p>
				}
				<div className={styles.btn_area}>
					<button type="button" className={styles.confirm} onClick={closeEmailPopup}>확인</button>
				</div>
			</div>
		</div> */}
		
		<section className={`${styles.main_full_area} ${styles.main_banner_area}`}>
			<ul className={styles.bn_wrap}>
				<li className={`${styles.bn} ${styles.mathupdate} ${styles.active}`}>
					<div className={styles.bn_content_wrap}>
						<div className={styles.inner90}>
							<img src="/assets/math_text1_eng.png" alt="txt" className={`${styles.txt1} ${styles.showBounce}`}/>
							{/* <a href='https://cafe.naver.com/weyam?iframe_url_utf8=%2FArticleRead.nhn%253Fclubid%3D30240592%2526menuid%3D36%2526boardtype%3DC%2526page%3D1%2526articleid%3D105%2526referrerAllArticles%3Dfalse' target='_blank' className={`${styles.button} ${styles.showBounce}`}>
								<img src="/assets/math_button.png" alt="txt" />
							</a> */}
							<img src="/assets/math_img2_eng.png" alt="txt" className={`${styles.txt2} ${styles.showBounce}`}/>
							{/* <img src="/assets/math_text2.png" alt="txt" className={`${styles.txt3} ${styles.showBounce}`}/> */}
						</div>
					</div>
				</li>
				{/* <li className={`${styles.bn} ${styles.open}`}>
					<div className={styles.bn_content_wrap}>
						<div className={styles.inner90}>
							<div className={styles.top_area}>
								<span className={`${styles.title} ${styles.showBounce} ${styles.on}`}>정식 출시</span>
								<p className={`${styles.showBounce} ${styles.sb2} ${styles.on}`}>야미코딩이 드디어 <span className={styles.strong}>정식 출시</span> 되었습니다.</p>
								<p className={`${styles.second_p} ${styles.showBounce} ${styles.sb3} ${styles.on}`}>현재 안드로이드로 플레이 가능합니다.</p>
								<p className={`${styles.input_info} ${styles.showBounce} ${styles.sb4} ${styles.on}`}>IOS 버전은 개발 중이며 조금만 기다려주시기 바랍니다. <br className={styles.enter}/>아래에서 이메일을 신청하시면 IOS 출시 소식을 빨리 받아보실 수 있습니다.</p>
								<div className={`${styles.open_email_input_area} ${styles.showBounce} ${styles.sb5} ${styles.on}`}>
									<input type="email" id={styles.banner_email_input} placeholder="이메일"/>
									<button type="button" onClick={(e)=> emailNotification(e)}>신청하기</button>
								</div>
							</div>
							<div className={styles.img_area}>
								<div className={styles.base}>
									<img className={`${styles.showBounce} ${styles.sb7} ${styles.on}`} src="/assets/open_banner_img_bg.png" alt="open banner bg" />
								</div>
								<div className={styles.top}>
									<img className={`${styles.bounceIn} ${styles.on}`} src="/assets/open_banner_friends.png" alt="open banner friends" />
								</div>
							</div>
						</div>
					</div>
				</li> */}
				{/* <li className={`${styles.bn} ${styles.open}`}>
					<div className={styles.bn_content_wrap}>
						<div className={styles.inner90}>
							<div className={styles.img_area}>
								<div className={styles.base}>
									<img className={`${styles.showBounce} ${styles.sb7} ${styles.on}`} src="/assets/ios_banner_img_eng.png" alt="ios banner img" />
								</div>
								<div className={styles.bottom}>
									<a href='https://apps.apple.com/kr/app/%EC%95%BC%EB%AF%B8-%EC%BD%94%EB%94%A9-3d%EC%BD%94%EB%94%A9%EA%B5%90%EC%9C%A1-%EC%84%9C%EB%B9%84%EC%8A%A4/id1631781495' target="_blank" rel='noopener noreferrer'>
										<img className={`${styles.bounceIn} ${styles.on}`} src="/assets/ios_banner_btn_eng.png" alt="ios banner button" />
									</a>
								</div>
							</div>
						</div>
					</div>
				</li> */}
				{/* <li className={`${styles.bn} ${styles.sale}`}>
					<div className={styles.bn_content_wrap}>
						<div className={styles.inner90}>
							<img src="/assets/sale_bn_txt1.png" alt="txt" className={`${styles.txt1} ${styles.showBounce}`}/>
							<img src="/assets/sale_bn_txt2.png" alt="txt" className={`${styles.txt2} ${styles.showBounce}`}/>
							<div className={styles.img_area}>
								<div className={`${styles.coin1} ${styles.bounceIn}`}>
									<img src="/assets/sale_bn_coin1.png" alt="coin" className={`${styles.bn_baloon1}`}/>
								</div>
								<div className={`${styles.coin2} ${styles.bounceIn}`}>
									<img src="/assets/sale_bn_coin2.png" alt="coin" className={`${styles.bn_baloon2}`}/>
								</div>
								<div className={`${styles.coin3} ${styles.bounceIn}`}>
									<img src="/assets/sale_bn_coin3.png" alt="coin" className={`${styles.bn_baloon2}`}/>
								</div>
								<div className={`${styles.coin4} ${styles.bounceIn}`}>
									<img src="/assets/sale_bn_coin4.png" alt="coin" className={`${styles.bn_baloon1}`}/>
								</div>
								<div className={`${styles.coin5} ${styles.bounceIn}`}>
									<img src="/assets/sale_bn_coin5.png" alt="coin" className={`${styles.bn_baloon1}`}/>
								</div>
								<div className={`${styles.coin6} ${styles.bounceIn} ${styles.on}`}>
									<img src="/assets/sale_bn_coin6.png" alt="coin" className={`${styles.bn_baloon2}`}/>
								</div>
								<div className={`${styles.coin7} ${styles.bounceIn}`}>
									<img src="/assets/sale_bn_coin7.png" alt="coin" className={`${styles.bn_baloon1}`}/>
								</div>
								<div className={`${styles.coin8} ${styles.bounceIn}`}>
									<img src="/assets/sale_bn_coin8.png" alt="coin" className={`${styles.bn_baloon1}`}/>
								</div>
								<div className={`${styles.coin9} ${styles.bounceIn}`}>
									<img src="/assets/sale_bn_coin9.png" alt="coin" className={`${styles.bn_baloon2}`}/>
								</div>
								<div className={`${styles.coin10} ${styles.bounceIn}`}>
									<img src="/assets/sale_bn_coin10.png" alt="coin" className={`${styles.bn_baloon2} ${styles.on}`}/>
								</div>
							</div>
						</div>
						<div className={styles.bottom_coin_area}></div>
					</div>
				</li> */}
				{/* <li className={`${styles.bn} ${styles.sale}`}>
					<div className={styles.bn_content_wrap}>
						<div className={styles.inner90}>
							<img src="/assets/sale_bn_txt2.png" alt="txt" className={`${styles.txt2} ${styles.showBounce}`}/>
						</div>
						<div className={styles.bottom_coin_area}></div>
					</div>
				</li> */}
				<li className={`${styles.bn} ${styles.popular}`}>
					<div className={styles.bn_content_wrap}>
						<div className={styles.inner90}>
							<img src="/assets/popular_bn_txt.png" alt="txt" className={`${styles.txt} ${styles.showBounce}`}/>
							<img src="/assets/popular_bn_tropy.png" alt="트로피" className={`${styles.tropy} ${styles.bounceIn}`}/>
							<div className={styles.img_area}>
								<div className={styles.top}>
									<div className={`${styles.star1} ${styles.bounceIn}`}>
										<img src="/assets/popular_bn_star1.png" alt="별" className={styles.bn_baloon1}/>
									</div>
									<div className={`${styles.star2} ${styles.bounceIn}`}>
										<img src="/assets/popular_bn_star2.png" alt="별" className={styles.bn_baloon2}/>
									</div>
									<div className={`${styles.star3} ${styles.bounceIn}`}>
										<img src="/assets/popular_bn_star3.png" alt="별" className={styles.bn_baloon1}/>
									</div>
									<div className={`${styles.star4} ${styles.bounceIn}`}>
										<img src="/assets/popular_bn_star4.png" alt="별" className={styles.bn_baloon2}/>
									</div>
									<div className={`${styles.star5} ${styles.bounceIn}`}>
										<img src="/assets/popular_bn_star5.png" alt="별" className={styles.bn_baloon1}/>
									</div>
									<div className={`${styles.star6} ${styles.bounceIn}`}>
										<img src="/assets/popular_bn_star6.png" alt="별" className={styles.bn_baloon2}/>
									</div>
								</div>
								<div className={styles.bottom}>
									<div className={`${styles.star1} ${styles.bounceIn}`}>
										<img src="/assets/popular_bn_bottom_star1.png" alt="별" className={styles.bn_baloon2}/>
									</div>
									<div className={`${styles.star2} ${styles.bounceIn}`}>
										<img src="/assets/popular_bn_bottom_star2.png" alt="별" className={styles.bn_baloon1}/>
									</div>
									<div className={`${styles.star3} ${styles.bounceIn}`}>
										<img src="/assets/popular_bn_bottom_star3.png" alt="별" className={styles.bn_baloon2}/>
									</div>
									<div className={`${styles.star4} ${styles.bounceIn}`}>
										<img src="/assets/popular_bn_bottom_star4.png" alt="별" className={styles.bn_baloon1}/>
									</div>
									<div className={`${styles.star5} ${styles.bounceIn}`}>
										<img src="/assets/popular_bn_bottom_star5.png" alt="별" className={styles.bn_baloon2}/>
									</div>
									<div className={`${styles.star6} ${styles.bounceIn}`}>
										<img src="/assets/popular_bn_bottom_star6.png" alt="별" className={styles.bn_baloon1}/>
									</div>
									<div className={`${styles.star7} ${styles.bounceIn}`}>
										<img src="/assets/popular_bn_bottom_star7.png" alt="별" className={styles.bn_baloon2}/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</li>
			</ul>
			{/* <div className={styles.bn_btn_wrap}>
					<div className={`${styles.bn_btn} ${styles.on}`}></div>
					<div className={styles.bn_btn}></div>
					<div className={styles.bn_btn}></div>
			</div> */}
			<div className={styles.bn_btn_wrap}>
				<div className={styles.bn_btn_area}>
					<div className={`${styles.bn_btn} ${styles.on}`}></div>
					{/* <div className={styles.bn_btn}></div> */}
					<div className={styles.bn_btn}></div>	
				</div>
				<div className={styles.bn_play}> 
					<img src="/assets/bn_play.png" alt="배너 멈춤" className={styles.stop}/>
					<img src="/assets/bn_stop.png" alt="배너 재생" className={styles.play}/>
				</div>
			</div>
		</section>
		<section className={`${styles.main_full_area} ${styles.introduce_area}`}>
			<div className={styles.inner90}>
				<div className={styles.top_area}>
					<img src="/assets/intro_img@2x.png" alt="우수상"/>
					<div className={styles.right_area}>
						<span className={styles.sub_title}>
						<span className={styles.stakato}>Based on the lessons</span>&nbsp;&nbsp; and &nbsp;&nbsp;<span className={styles.stakato}>know-how</span> &nbsp;&nbsp;learned through pilot training,
						{/* <span className={styles.stakato}>교</span>
						<span className={styles.stakato}>훈</span>과, 
						<span className={styles.stakato}>노</span>
						<span className={styles.stakato}>하</span>
						<span className={styles.stakato}>우</span> */}
						</span>
						<span className={styles.index_tit}>YAM-E coding is released.</span>
						<p>YAM-E has completed successful pilot training and testing at several institutions/organizations. <br/>
						It gained high popularity and support from elementary school students, who are the main targets,
						<br/> It was especially well received by the teachers in charge of education. 
						<br/>It also achieved great achievements in receiving the Excellence Award at the Hackathon Ceremony of EduTech's <br/>demonstration R&D company held by the Korea Educational Research Information Service.</p>
					</div>
					<div className={styles.ym_who_popup}>
						<div className={styles.ym_wyo_box}>
							<span>elementary school student</span>
							<p>Everyone can now be a user of the 'YAM-E' Service, from the basic stage of starting block coding for basic courses to the advanced stage of learning the program language to become an engineer and developer.</p>
						</div>
						<div className={styles.ym_wyo_box}>
							<span>Coding instructors and teachers</span>
							<p>Provides a variety of fun, project-based content to coding instructors and teachers who need to teach children block coding. Don't get stressed out by preparing for the school plan or training according to the block anymore, and teach it excitedly through "YAM-E."</p>
						</div>
						<div className={styles.ym_wyo_box}>
							<span>school parent</span>
							<p>The world's first individual dashboard. Real-time monitoring of how our children grow up in five areas of the brain's activation, five areas (creativity, imagination, logic, reasoning, and cooperation) and five subjects of STEAM (science, technology, engineering, art, and mathematics).</p>
						</div>
					</div>
				</div>
				<div className={styles.ym_who_btn_area}>
					<div className={styles.ym_who_btn}>
						<span>Who needs YAM-E Coding?</span>
						<div className={styles.hover_round}></div>
					</div>
				</div>
			</div>
		</section>
		<section className={`${styles.main_full_area} ${styles.ym_world_area}`}>
			<div className={styles.inner90}>
				<div className={styles.top_area}>
					<span className={styles.index_tit}>
						{/* <span className={styles.br}>월드를 <span className={styles.stakato}>탐</span><span className={styles.stakato}>험</span>하며 </span>재미있게 배우는 블록 코딩 */}
						<span className={styles.br}>Block coding for fun <span className={styles.stakato}>exploring &nbsp;</span> the world</span>
					</span>
					{/* <p className={styles.txt}>누가 왜 만들었는지 알 수 없는 코딩블록으로 <br/>만들어진 세상에서 월드를 위협하는 오류와 버그를 해결해 줄 <br/>코딩에 관심이 있는 아이들을 야미코딩에 초대합니다</p> */}
					<p className={styles.txt}>We invite children interested in coding to YAM-E Coding to solve errors<br/> and bugs that threaten the world in a world made of coding blocks<br/> where we don't know who made it and why.</p>
				</div>
				<div className={styles.bottom_area}>
					<div className={styles.left_area}>
						<img src="/assets/Lobby.png" alt="loby" className={styles.loby}/>
						<div className={styles.plus_wrap}>
							<div className={`${styles.plus} ${styles.on}`}>
								<img src="/assets/plus_on@2x.png" alt="plus"/>
								<img src="/assets/plus@2x.png" alt="plus"/>
							</div>
							<div className={styles.plus}>
								<img src="/assets/plus_on@2x.png" alt="plus"/>
								<img src="/assets/plus@2x.png" alt="plus"/>
							</div>
							<div className={styles.plus}>
								<img src="/assets/plus_on@2x.png" alt="plus"/>
								<img src="/assets/plus@2x.png" alt="plus"/>
							</div>
							<div className={styles.plus}>
								<img src="/assets/plus_on@2x.png" alt="plus"/>
								<img src="/assets/plus@2x.png" alt="plus"/>
							</div>
							<div className={styles.plus}>
								<img src="/assets/plus_on@2x.png" alt="plus"/>
								<img src="/assets/plus@2x.png" alt="plus"/>
							</div>
						</div>
					</div>
					<div className={styles.right_area}>
						<div className={styles.building_wrap}>
							<div className={`${styles.building} ${styles.on}`}>
								{/* <span>마이룸</span>
								<img src="/assets/Lobby_Home.png" alt="building"/>
								<p>캐릭터와 닉네임을 변경하고 학습 현황을 <br/>한눈에 알아볼 수 있습니다</p> */}
								<span>My room</span>
								<img src="/assets/Lobby_Home.png" alt="building"/>
								<p>You can change characters and nicknames and <br/>see the learning status at a glance.</p>
							</div>
							<div className={styles.building}>
								{/* <span>뮤직룸</span>
								<img src="/assets/Lobby_Musicroom.png" alt="building"/>
								<p>뇌음악연구소에서 작곡한 곡들로 지친 <br/>아이들의 뇌를 쉬게 해줍니다</p> */}
								<span>Music room</span>
								<img src="/assets/Lobby_Musicroom.png" alt="building"/>
								<p>Songs written by the Brain Music Institute rest <br/>the brain of tired children.</p>
							</div>
							<div className={styles.building}>
								{/* <span>지식 은행</span>
								<img src="/assets/Lobby_Bank.png" alt="building"/>
								<p>소프트웨어 지식을 쌓을 수 있는 문제 <br/>풀이가 매일 매일 업데이트 됩니다</p> */}
								<span>Knowledge Bank</span>
								<img src="/assets/Lobby_Bank.png" alt="building"/>
								<p>Problem solvers that help you gain <br/>software knowledge are updated daily.</p>
							</div>
							<div className={styles.building}>
								{/* <span>루시의 옷가게</span>
								<img src="/assets/Lobby_Cloth.png" alt="building"/>
								<p>여러 가지 장식이나 코스튬을 이용하여<br/>동물 친구들을 꾸밀 수 있습니다</p> */}
								<span>Lucy's clothing store</span>
								<img src="/assets/Lobby_Cloth.png" alt="building"/>
								<p>You can decorate your animal friends <br/>with various decorations or costumes.</p>
							</div>
							<div className={styles.building}>
								{/* <span>컨트롤 미션</span>
								<img src="/assets/Lobby_Game.png" alt="building"/>
								<p>컨트롤러로 조작하는 수학, 영어, 과학 <br/>등의 교육적인 미션이 준비됩니다</p> */}
								<span>Control mission</span>
								<img src="/assets/Lobby_Game.png" alt="building"/>
								<p>Educational missions such as math, English,<br/> and science, which are controlled <br/>by the controller, are prepared.</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
		<section className={`${styles.main_full_area} ${styles.ym_difference_area}`}>
			<div className={styles.inner90}>
				{/* <span className={styles.sub_title}>천재적 창의력으로</span>
				<span className={styles.index_tit}>정답보다 최적의 해결방법을 찾자!</span>
				<div className={styles.tabbtn_area}>
					<span className={`${styles.btn} ${styles.on}`}>삼각함수</span>
					<span className={styles.btn}>물리엔진 + 환경</span>
				</div>
				<div className={styles.middle_area}>
					<div className={styles.info_txt_wrap}>
						<p className={styles.on}>정답만 있는 기존 서비스는 가라! <br/>야미코딩은 삼각형 빗변(삼각함수)를 이용, <br/>나만의 방법으로 코딩하여 <br/>최적의 해답을 찾을 수 있도록 합니다.</p>
						<p>월드의 환경에 따라 기술 적용을 다르게 하여 <br/>아이들이 물리 현상을 간접 체험하도록 합니다</p>
					</div>
				</div> */}
				<span className={styles.sub_title}>Let's find the best solution than the right answer</span>
				<span className={styles.index_tit}>with genius creativity!</span>
				<div className={styles.tabbtn_area}>
					<span className={`${styles.btn} ${styles.on}`}>a trigonometric function</span>
					<span className={styles.btn}>a physical engine + Environment</span>
				</div>
				<div className={styles.middle_area}>
					<div className={styles.info_txt_wrap}>
						<p className={styles.on}>Go away for existing services with only answers! <br/>YAM-E coding uses the triangle hypotenuse (triangle function) to code <br/>in your own way so that you can find the best answer.</p>
						<p>Depending on the world's environment, <br/>the application of technology is different so that <br/>children can experience physical phenomena indirectly.</p>
					</div>
				</div>
				<div className={styles.img_area}>
					<div className={styles.left_img}>
						<img src="/assets/1_1.gif" alt="삼각함수" className={styles.on}/>
						<img src="/assets/2_1.gif" alt="물리엔진 "/>
					</div>
					<div className={styles.right_img}>
						<img src="/assets/1_2.gif" alt="삼각함수" className={styles.on}/>
						<img src="/assets/2_2.gif" alt="물리엔진 "/>
					</div>
				</div>
			</div>
		</section>
		<section className={`${styles.main_full_area} ${styles.ym_myroom_area}`}>
			<div className={styles.inner90}>
				{/* <span className={styles.index_tit}><span className={styles.br}>마이룸에서 <span className={styles.stakato}>한</span><span className={styles.stakato}>눈</span>에 보는 </span>학습 현황</span>
				<p className={styles.txt}>선생님과 학부모님은 야미 홈페이지의 <br/>마이 페이지에서 아이들의 학습 상황을 확인하실 수 있습니다</p>
				<div className={styles.bottom_area}>
					<div className={styles.box}>
						<span className={styles.cover}>수업 진행 상황</span>
						<div className={styles.content}>
							<span>수업 진행 상황</span>
							<p>그래프를 통해 현재 수업 진행 상황을 직관적으로 볼 수 있습니다</p>
							<div className={styles.bottom_area}>
								<div className={styles.graph_area1}>
									<img src="/assets/myroom_now_graph1@2x.png" alt="그래프"/>
									<div className={styles.wrap}>
										<div className={styles.bar}></div>
									</div>
								</div>
								<img src="/assets/myroom_now_graph2@2x.png" alt="그래프" className={styles.graph_area1_graph}/>
							</div>
						</div>
					</div> */}
				<span className={styles.index_tit}><span className={styles.br}>Learning Status <span className={styles.stakato}>Viewed by All &nbsp;</span> in My Room</span></span>
				<p className={styles.txt}>Teachers and parents can check their children's <br/>learning status on My Page on the YAM-E website.</p>
				<div className={styles.bottom_area}>
					<div className={styles.box}>
						<span className={styles.cover}>the progress of the class</span>
						<div className={styles.content}>
							<span>the progress of the class</span>
							<p>The graph provides an intuitive view of the current class progress.</p>
							<div className={styles.bottom_area}>
								<div className={styles.graph_area1}>
									<img src="/assets/myroom_now_graph1@2x.png" alt="그래프"/>
									<div className={styles.wrap}>
										<div className={styles.bar}></div>
									</div>
								</div>
								<img src="/assets/myroom_now_graph2@2x.png" alt="그래프" className={styles.graph_area1_graph}/>
							</div>
						</div>
					</div>
					<div className={`${styles.box} ${styles.on}`}>
						<span className={styles.cover}>Software learning</span>
						<div className={styles.content}>
							<span>Software learning</span>
							<p>You can see how much our children's ability to solve scientific, mathematical, and engineering problems has grown.</p>
							<div className={styles.graph_area2}>
								<div className={styles.graph}>
									<img src="/assets/myroom_graph1_head@2x.png" alt="그래프1" className={styles.head}/>
									<img src="/assets/myroom_graph1_body@2x.png" alt="그래프1"/>
									<img src="/assets/myroom_graph1_body@2x.png" alt="그래프1"/>
									<img src="/assets/myroom_graph1_body@2x.png" alt="그래프1"/>
									<img src="/assets/myroom_graph1_body@2x.png" alt="그래프1"/>
									<img src="/assets/myroom_graph1_body@2x.png" alt="그래프1"/>
									<img src="/assets/myroom_graph1_body@2x.png" alt="그래프1"/>
									<img src="/assets/myroom_graph1_body@2x.png" alt="그래프1"/>
									<img src="/assets/myroom_graph1_body@2x.png" alt="그래프1"/>
									<img src="/assets/myroom_graph1_body@2x.png" alt="그래프1"/>
									<img src="/assets/myroom_graph1_body@2x.png" alt="그래프1"/>
									<img src="/assets/myroom_graph1_body@2x.png" alt="그래프1"/>
									<img src="/assets/myroom_graph1_body@2x.png" alt="그래프1"/>
									<img src="/assets/myroom_graph1_body@2x.png" alt="그래프1"/>
									<img src="/assets/myroom_graph1_body@2x.png" alt="그래프1"/>
									<img src="/assets/myroom_graph1_body@2x.png" alt="그래프1"/>
									<img src="/assets/myroom_graph1_body@2x.png" alt="그래프1"/>
								</div>
								<div className={styles.graph}>
									<img src="/assets/myroom_graph2_head@2x.png" alt="그래프2" className={styles.head}/>
									<img src="/assets/myroom_graph2_body@2x.png" alt="그래프2"/>
									<img src="/assets/myroom_graph2_body@2x.png" alt="그래프2"/>
									<img src="/assets/myroom_graph2_body@2x.png" alt="그래프2"/>
									<img src="/assets/myroom_graph2_body@2x.png" alt="그래프2"/>
									<img src="/assets/myroom_graph2_body@2x.png" alt="그래프2"/>
									<img src="/assets/myroom_graph2_body@2x.png" alt="그래프2"/>
									<img src="/assets/myroom_graph2_body@2x.png" alt="그래프2"/>
									<img src="/assets/myroom_graph2_body@2x.png" alt="그래프2"/>
								</div>
								<div className={styles.graph}>
									<img src="/assets/myroom_graph3_head@2x.png" alt="그래프3" className={styles.head}/>
									<img src="/assets/myroom_graph3_body@2x.png" alt="그래프3"/>
									<img src="/assets/myroom_graph3_body@2x.png" alt="그래프3"/>
									<img src="/assets/myroom_graph3_body@2x.png" alt="그래프3"/>
									<img src="/assets/myroom_graph3_body@2x.png" alt="그래프3"/>
									<img src="/assets/myroom_graph3_body@2x.png" alt="그래프3"/>
									<img src="/assets/myroom_graph3_body@2x.png" alt="그래프3"/>
									<img src="/assets/myroom_graph3_body@2x.png" alt="그래프3"/>
									<img src="/assets/myroom_graph3_body@2x.png" alt="그래프3"/>
									<img src="/assets/myroom_graph3_body@2x.png" alt="그래프3"/>
									<img src="/assets/myroom_graph3_body@2x.png" alt="그래프3"/>
									<img src="/assets/myroom_graph3_body@2x.png" alt="그래프3"/>
									<img src="/assets/myroom_graph3_body@2x.png" alt="그래프3"/>
								</div>
							</div>
						</div>
					</div>
					<div className={styles.box}>
						<span className={styles.cover}>cognitive ability</span>
						<div className={styles.content}>
							<span>cognitive ability</span>
							<p>As we solve the mission, we can see how much our children's cognitive skills have improved.</p>
							<div className={`${styles.bottom_area} ${styles.graph_area3}`}>
								<img src="/assets/myroom_anility_1@2x.png" alt="인지능력"/>
								<div className={`${styles.count_wrap} ${styles.count1}`}>
									<span className={styles.counting}></span>
								</div>
								<div className={`${styles.count_wrap} ${styles.count2}`}>
									<span className={styles.counting}></span>
								</div>
								<div className={`${styles.count_wrap} ${styles.count3}`}>
									<span className={styles.counting}></span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
		<section className={`${styles.main_full_area} ${styles.brain_music_area}`}>
			<div className={styles.inner90}>
				<div className={styles.wrap}>
					<span className={styles.sub_title}>Brain Music Research Institute YAM-E's BGM production!</span>
					<span className={styles.index_tit}>YAM-E also thinks of our children in music.</span>
					<ul>
						<li>
							<div className={styles.play_wrap}>
								<img src="/assets/play_2@2x.png" alt="stop" className={styles.stop} onClick={() => brainMusic.pause()}/>
								<img src="/assets/stop@2x.png" alt="play" className={`${styles.play} ${styles.on}`} onClick={() => musicOnly(1)}/>
							</div>
							<div className={styles.right_area}>
								<span>Increased concentration</span>
								<p>It helps you concentrate while taking a calm mood.</p>
							</div>
						</li>
						<li>
							<div className={styles.play_wrap}>
								<img src="/assets/play_2@2x.png" alt="stop" className={styles.stop} onClick={() => brainMusic.pause()}/>
								<img src="/assets/stop@2x.png" alt="play" className={`${styles.play} ${styles.on}`} onClick={() => musicOnly(2)}/>
							</div>
							<div className={styles.right_area}>
								<span>emotional stability</span>
								<p>It gives children the feeling of resting in their own world.</p>
							</div>
						</li>
						<li>
							<div className={styles.play_wrap}>
								<img src="/assets/play_2@2x.png" alt="stop" className={styles.stop} onClick={() => brainMusic.pause()}/>
								<img src="/assets/stop@2x.png" alt="play" className={`${styles.play} ${styles.on}`} onClick={() => musicOnly(3)}/>
							</div>
							<div className={styles.right_area}>
								<span>Mind up</span>
								<p>It puts positive energy into children's bodies and minds.</p>
							</div>
						</li>
					</ul>
				</div>
			</div>
		</section>
		<section id="main_coding_block" className={`${styles.main_full_area} ${styles.coding_block_area}`}>
			<div className={styles.inner90}>
				{/* <span className={styles.index_tit}><span className={styles.br}>야미는 <span className={styles.stakato}>3</span><span className={styles.stakato}>가</span><span className={styles.stakato}>지</span> 핵심 요소로</span> 수업을 진행합니다</span> */}
				<span className={styles.index_tit}><span className={styles.br}>YAM-E teaches the class with <span className={styles.stakato}>three key elements</span></span></span>
				<div className={styles.bottom_area}>
					<div className={`${styles.cont} ${styles.on}`}>
						<img src="/assets/num1.png" alt="num" className={styles.num}/>
						<span className={styles.title}>Presenting new features</span>
						<span className={styles.sub_tit}>(Improvement of logic)</span>
						<p>Allows you to learn the features before<br/>encountering a new mission.</p>
						<img src="/assets/blockcoding_icon1@2x.png" alt="icon"/>
					</div>
					<div className={`${styles.arrow_wrap} ${styles.arrow1} ${styles.on}`}>
						<img src="/assets/arrow_right@2x.png" alt="arrow"/>
					</div>
					<div className={styles.cont}>
						<img src="/assets/num2.png" alt="num" className={styles.num}/>
						<span className={styles.title}>Functional Learning</span>
						<span className={styles.sub_tit}>(Improving Imagination)</span>
						<p>Learn new features.</p>
						<img src="/assets/blockcoding_icon2@2x.png" alt="icon"/>
					</div>
					<div className={`${styles.arrow_wrap} ${styles.arrow2}`}>
						<img src="/assets/arrow_right@2x.png" alt="arrow"/>
					</div>
					<div className={styles.cont}>
						<img src="/assets/num3.png" alt="num" className={styles.num}/>
						<span className={styles.title}>applied learning</span>
						<span className={styles.sub_tit}>(Improving Creativity)</span>
						<p>If you are presented with new functions and have mastered them, you will proceed with repeated <br/>application learning so that you do not forget those skills. 
						<br/>As a result, no matter what mission you encounter, you will naturally develop <br/>the ability to solve problems on your own without being embarrassed.</p>
						<img src="/assets/blockcoding_icon3@2x.png" alt="icon"/>
					</div>
				</div>
			</div>
		</section>
		<section className={`${styles.main_full_area} ${styles.yf_area}`}>
			<div className={styles.inner90}>
				{/* <span className={styles.sub_title}>코딩 수업을 <span className={styles.stakato}>재</span><span className={styles.stakato}>미</span>있게 즐길 수 있도록</span> */}
				<span className={styles.sub_title}>So that you can <span className={styles.stakato}>enjoy&nbsp;</span> the coding class</span>
				<span className={styles.index_tit}>YAM-E Friends is with the kids.</span>
				<div className={styles.content_area}>
					<ul className={styles.btn_area}>
						<li className={styles.on}>
							<img src="/assets/yf_btn1@2x.png" alt="케니"/>
						</li>
						<li>
							<img src="/assets/yf_btn2@2x.png" alt="루이"/>
						</li>
						<li>
							<img src="/assets/yf_btn3@2x.png" alt="벤"/>
						</li>
						<li>
							<img src="/assets/yf_btn4@2x.png" alt="도로시"/>
						</li>
						<li>
							<img src="/assets/yf_btn5@2x.png" alt="딘"/>
						</li>
					</ul>
					<div className={styles.yf_img_area}>
						<img src="/assets/YF_02_1.png" alt="케니" className={styles.on}/>
						<img src="/assets/YF_01_1.png" alt="루이"/>
						<img src="/assets/YF_04_1.png" alt="벤"/>
						<img src="/assets/YF_06_1.png" alt="도로시"/>
						<img src="/assets/YF_05_1.png" alt="딘"/>
					</div>
					<div className={styles.yf_info_area}>
						<div className={styles.album_area}>
							<div className={`${styles.album} ${styles.album1}`}>
								<img src="/assets/yf_keni_1.png" alt="케니"  className={styles.on}/>
								<img src="/assets/yf_rui_1.png" alt="루이"/>
								<img src="/assets/yf_ven_1.png" alt="벤"/>
								<img src="/assets/yf_doroty_1.png" alt="도로시"/>
								<img src="/assets/yf_din_1.png" alt="딘"/>
							</div>
							<div className={`${styles.album} ${styles.album2}`}>
								<img src="/assets/yf_keni_2.png" alt="케니"  className={styles.on}/>
								<img src="/assets/yf_rui_2.png" alt="루이"/>
								<img src="/assets/yf_ven_2.png" alt="벤"/>
								<img src="/assets/yf_doroty_2.png" alt="도로시"/>
								<img src="/assets/yf_din_2.png" alt="딘"/>
							</div>
						</div>
						<div className={styles.txt_area}>
							<div className={`${styles.txt_box} ${styles.on}`}>
								<span>Kenny</span>
								<p>Kenny's big ears, which are sweet and full of positive energy, <br/>listen to his friends' stories well.</p>
							</div>
							<div className={styles.txt_box}>
								<span>Louis</span>
								<p>Confident! Louis, the brilliant blue duck, never gets discouraged! <br/>He's always full of confidence.</p>
							</div>
							<div className={styles.txt_box}>
								<span>Ben</span>
								<p>Did everyone wake up already? <br/>Ben likes to lie on the grass and look at the clouds in the sky. <br/>Strangely, butterflies gather around Ben.</p>
							</div>
							<div className={styles.txt_box}>
								<span>Dorothy</span>
								<p>I will conquer the entire universe with my cuteness! <br/>I have a good relationship with someone in four dimensions.</p>
							</div>
							<div className={styles.txt_box}>
								<span>Dean</span>
								<p>He's smart and very good with his hands. <br/>I'm especially good at making things, but sometimes... <br/>They make weird things, too.</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
		<section className={`${styles.main_full_area} ${styles.yame_sns_area}`}>
			<div className={styles.inner90}>
				<div className={styles.yame_sns_title_area}>
					<img src="/assets/yame_sns_rui@2x.png" alt="루이"/>
					<span>YAM-E Coding SNS</span>
				</div>
				<div className={styles.yame_youtube_area}>
					<a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/channel/UCm-2bRy6jSI5Wg4MY4tS6hg">
						<img src="/assets/youtube_sumnail@2x.png" alt="유튜브 썸네일"/>
						<div className={styles.right_area}>
							<div className={styles.top_area}>
								<img src="/assets/sns_youtube_icon@2x.png" alt="youtube logo"/>
								<span>YAM-E Coding Youtube</span>
							</div>
							<p>You can enjoy learning videos, experiences, <br></br> and cute character animations related to coding blocks.</p>
						</div> 
					</a>
				</div>
				<div className={styles.yame_cafe_area}>
					<div className={styles.cafe_top_area}>
						<img src="/assets/naver_cafe_icon@2x.png" alt="cafo logo"/>
						<div className={styles.top_area}>
							<span>YAM-E Coding Naver Cafe</span>
							<p>Visit the cafe to exchange new information and information with other learners.</p>
						</div>
					</div>
					<a target="_blank" rel="noopener noreferrer" href="https://cafe.naver.com/weyam">Visit</a>
				</div>
			</div>
		</section>
		<ul className={styles.floating_paging_btn_wrap}>
			<li>Introducing YAM-E</li>
			<li>View of the world</li>
			<li>YAM-E Features</li>
			<li>My room</li>
			<li>Brain music</li>
			<li>Coding class</li>
			<li>YAM-E Friends</li>
			<li>SNS</li>
		</ul>

		{/* Footer 분리됨 MainLayout Footer에 있음 */}
    </>
  );
}