import * as React from 'react';
import Chart from "react-apexcharts";

import { useTranslation, Trans } from 'react-i18next'; // 1. react-i18next import 

export default function BasicTable(props) {

      const { t, i18n } = useTranslation(); // 3. useTranslation hook 선언

      let dataArray = [];

      if (props.RowData?.length !== 0)
      {
        // 데이터 생성
        for (let i=0; i<props.RowData?.length; i++)
        {
          dataArray.push({x: props.RowData[i].mc_name,y: props.RowData[i].cnt});
        }
      }

      let state = {
          
        series: [{
          // name: "학생수",
          name: `${t("ClassChart.string1")}`,
          data: dataArray,
        }],
        options: {
          chart: {
            type: 'bar',
            width: '100%'
          },
          xaxis: {
            type: 'category',
            labels: {
              formatter: function(val) {
                // return "Q" + dayjs(val).quarter()
                return val
              }
            },
            group: {
              style: {
                fontSize: '10px',
                fontWeight: 700
              },
              groups: [
                // { title: '학생의 수', cols: props.RowData?.length },
                { title: `${t("ClassChart.string2")}`, cols: props.RowData?.length },
              ]
            }
          },
        //   title: {
        //       text: 'Grouped Labels on the X-axis',
        //   },
          tooltip: {
            x: {
              formatter: function(val) {
                // return "Q" + dayjs(val).quarter() + " " + dayjs(val).format("YYYY")
                return val
              }  
            }
          },
        },
      
      };

  return (
    <>
        <Chart
        options={state.options}
        series={state.series}
        type="bar"
        height={280}
          />
    </>
  );
}

// import React, { Component } from "react";
// import Chart from "react-apexcharts";

// class App extends Component {
//   constructor(props) {
//     super(props);

//     console.log("props.RowData : ", props.RowData);

//     let dataArray = [];

//     // if (props.RowData?.length !== 0)
//     // {
//     //   // 데이터 생성
//     //   for (let i=0; i<props.RowData?.length; i++)
//     //   {
//     //     dataArray.push({x: props.RowData[i].mc_name,y: props.RowData[i].cnt});
//     //   }
//     // }

// /*
//   [{
//     x: '2019/01/01',
//     y: 400
//   }, {
//     x: '2019/04/01',
//     y: 430
//   }, {
//     x: '2019/07/01',
//     y: 448
//   }, {
//     x: '2019/10/01',
//     y: 470
//   }, {
//     x: '2019/10/01',
//     y: 470
//   }]
//  */

//     this.state = {
          
//         series: [{
//           name: "학생수",
//           data: dataArray,
//         }],
//         options: {
//           chart: {
//             type: 'bar',
//             width: '100%'
//           },
//           xaxis: {
//             type: 'category',
//             labels: {
//               formatter: function(val) {
//                 // return "Q" + dayjs(val).quarter()
//                 return val
//               }
//             },
//             group: {
//               style: {
//                 fontSize: '10px',
//                 fontWeight: 700
//               },
//               groups: [
//                 { title: '학생의 수', cols: 4 },
//               ]
//             }
//           },
//         //   title: {
//         //       text: 'Grouped Labels on the X-axis',
//         //   },
//           tooltip: {
//             x: {
//               formatter: function(val) {
//                 // return "Q" + dayjs(val).quarter() + " " + dayjs(val).format("YYYY")
//                 return val
//               }  
//             }
//           },
//         },
      
//       };
//   }

//   render() {
//     return (
      
//         <Chart
//             options={this.state.options}
//             series={this.state.series}
//             type="bar"
//             height={280}
//         />
//     );
//   }
// }

// export default App;