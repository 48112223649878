import http from "../http-common";

const studentCreate = data => {
  return http.post("student/student", data);
};

const studentGetAll = () => {
  return http.get("users/commonStudent");
};

const studentDelete = data => {
  return http.delete("student/student", {data : {mst_idx : data}});
};

const studentModify = data => {
  return http.put("student/student", data);
};

const studentSelect = data => {
  return http.get(`student/student/${data}`);
};

const studentGetCount = () => {
  return http.get("users/commonStudent/studentCount");
};

const studentIdCheck = data => {
  return http.get(`student/studentIDCheck/${data}`);
};

const studentAddCreate = data => {
  return http.post("student/studentAdd", data);
};

const studentPayInfo = data => {
  return http.get(`student/studentPayInfo/${data}`);
};

const newStudentGetAll = () => {
  return http.get("users/commonParentUnderStudent");
};

const studentPackageInfo = data => {
  return http.get(`student/studentPackageInfo/${data}`);
};

const newClassStudentGetAll = data => {

  if (data.condition !== "" && data.keyword !== "")
    return http.get(`users/commonClassParentUnderStudent/${data.mc_idx}/${data.board_page}/${data.condition}/${data.keyword}`);
  else
    return http.get(`users/commonClassParentUnderStudent/${data.mc_idx}/${data.board_page}`);
    
};

const newClassAdminStudentGetAll = data => {

  if (data.condition !== undefined && data.keyword !== undefined)
    return http.get(`users/commonClassAdminParentUnderStudent/${data.user_idx}/${data.mc_idx}/${data.board_page}/${data.condition}/${data.keyword}`);
  else
    return http.get(`users/commonClassAdminParentUnderStudent/${data.user_idx}/${data.mc_idx}/${data.board_page}`);
    
};

const studentAddsCreate = data => {
  return http.post("student/studentAdds", data);
};

const StudentService = {
  studentCreate,
  studentGetAll,
  studentDelete,
  studentModify,
  studentSelect,
  studentGetCount,
  studentIdCheck,
  studentAddCreate,
  studentPayInfo,
  newStudentGetAll,
  studentPackageInfo,
  newClassStudentGetAll,
  newClassAdminStudentGetAll,
  studentAddsCreate,
};

export default StudentService;
