import http from "../http-common";

const couponGetAll = data => {

  if (data.condition !== "" && data.keyword !== "")
    return http.get(`coupon/${data.board_page}/${data.condition}/${data.keyword}`);
  else
    return http.get(`coupon/${data.board_page}`);
    
};

const couponCreate = data => {
  return http.post("coupon", data);
};

const couponModify = data => {
  return http.put("coupon", data);
};

const couponDelete = data => {
  return http.delete("coupon", {data : data});
};

const couponSelect = data => {
  return http.get(`coupon/select/${data}`);    
};

const couponExcelDownload = data => {
  return http.get(`couponListExcelDownload/${data}`);    
};

const couponDetailGetAll = data => {

  if (data.condition !== "" && data.keyword !== "")
    return http.get(`couponList/${data.idx}/${data.board_page}/${data.condition}/${data.keyword}`);
  else
    return http.get(`couponList/${data.idx}/${data.board_page}`);
    
};

const couponAddManual = data => {
  return http.post("couponManualRegister", data);
};

const couponRegister = data => {
  return http.post("couponRegister", data);
};

const couponBulkRegister = data => {
  return http.post("couponBulkRegister", data);
};

const couponUserGetAll = data => {

  if (data.condition !== "")
    return http.get(`couponUserList/${data.board_page}/${data.condition}`);
  else
    return http.get(`couponUserList/${data.board_page}`);
    
};

const couponUserSelect = data => {
  return http.get(`couponUserSelect/${data}`);    
};

const couponCommonAll = data => {
  return http.get(`commonCouponList`);    
};

const CouponService = {
  couponGetAll,
  couponCreate,
  couponModify,
  couponDelete,
  couponSelect,
  couponExcelDownload, 
  couponDetailGetAll,
  couponAddManual,
  couponRegister,
  couponBulkRegister,
  couponUserGetAll,
  couponUserSelect,
  couponCommonAll,
};

export default CouponService;
