import http from "../http-common";

const productMissionCreate = data => {
  return http.post("mission/product", data);
};

const productMissionUseGet = data => {
  return http.get(`mission/product/use/${data}`);
};

const productMissionUseNotGet = data => {
  return http.get(`mission/product/useNot/${data}`);
};

const ProductMissionService = {
  productMissionCreate,
  productMissionUseGet,
  productMissionUseNotGet,
};

export default ProductMissionService;
