import {
  CLASS_AVG_STUDYTIME,
  CLASS_LAST_LOGIN,
  CLASS_STUDENT_COUNT,
  CLASS_AGE_DIV,
  CLASS_AGE_RANGE,
  CLASS_LEARNING_TIME,
  CLASS_MISSIONSTATE_PROGRESS,
  CLASS_MISSIONSTATE_PROGRESS_TOTAL,
  CLASS_TOTAL_MISSION,
  CLASS_MISSION_PROGRESS,
  CLASS_HUMAN_ABILITY,
} from "../actions/types";

const initialState = [];

const chartReducer = (chart = initialState, action) => {
  const { type, payload } = action;

  // console.log("productReducer Call!");
  // console.log("type : ", type);
  // console.log("payload : ", payload);

  switch (type) {

    case CLASS_AVG_STUDYTIME:
      return {
        ...chart,
        chartClassAvgStudyTime : payload
      };

    case CLASS_LAST_LOGIN:
      return {
        ...chart,
        chartClassLastLogin : payload
      };

    case CLASS_STUDENT_COUNT:
      return {
        ...chart,
        chartClassStudentCount : payload
      };

    case CLASS_AGE_DIV:
      return {
        ...chart,
        chartClassAgeDiv : payload
      };

    case CLASS_AGE_RANGE:
      return {
        ...chart,
        chartClassAgeRange : payload
      };

    case CLASS_LEARNING_TIME:
      return {
        ...chart,
        chartClassLearningTime : payload
      };

    case CLASS_MISSIONSTATE_PROGRESS:
      return {
        ...chart,
        chartClassMissionStateProgress : payload
      };

    case CLASS_MISSIONSTATE_PROGRESS_TOTAL:
      return {
        ...chart,
        chartClassMissionStateProgressTotal : payload
      };

    case CLASS_TOTAL_MISSION:
      return {
        ...chart,
        chartClassMissionTotal : payload
      };

    case CLASS_MISSION_PROGRESS:
      return {
        ...chart,
        chartClassMissionProgress : payload
      };

    case CLASS_HUMAN_ABILITY:
      return {
        ...chart,
        chartClassHumanAbility : payload
      };

    default:
      return chart;
  }
};

export default chartReducer;