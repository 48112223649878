import http from "../http-common";

const couponMissionCreate = data => {
  return http.post("couponMission", data);
};

const couponMissionUseGet = data => {
  return http.get(`couponMission/use/${data}`);
};

const couponMissionUseNotGet = data => {
  return http.get(`couponMission/useNot/${data}`);
};

const couponMissionList = (data) => {
  if (data === undefined)
    return http.get(`missionCouponMapList`);
  else
    return http.get(`missionCouponMapList/${data}`);
};

const couponMissionService = {
  couponMissionCreate,
  couponMissionUseGet,
  couponMissionUseNotGet,
  couponMissionList,
};

export default couponMissionService;
