import http from "../http-common";

const rankingGetAll = data => {

  if (data.condition !== "" && data.keyword !== "")
    return http.get(`rankingSet/${data.board_page}/${data.condition}/${data.keyword}`);
  else
    return http.get(`rankingSet/${data.board_page}`);
    
};

const rankingCreate = data => {
  return http.post("rankingSet", data);
};

const rankingModify = data => {
  return http.put("rankingSet", data);
};

const rankingDelete = data => {
  return http.delete("rankingSet", {data : data});
};

const rankingSelect = data => {
  return http.get(`rankingSet/select/${data}`);    
};

const rankingMake = data => {
  return http.post("rankingCreate", data);
};

const rankingChallengeList = data => {
  
    return http.get(`rankingChallenge/${data}`);
    
};

const rankingTotalRanking = data => {

    return http.get(`rankingTotal/${data}`);  
};

const CouponService = {
  rankingGetAll,
  rankingCreate,
  rankingModify,
  rankingDelete,
  rankingSelect,
  rankingMake,
  rankingChallengeList,
  rankingTotalRanking,
};

export default CouponService;
