import http from "../http-common";

const productCreate = data => {
  
  //전송할 데이터에 파일이 있을경우
  const config = {
    headers : {
        'context-type' : 'multipart/form-data'
    }
  }

  return http.post("payment/product", data, config);
};

const productGetAll = () => {
  return http.get("payment/product");
};

const productDelete = data => {
  return http.delete("payment/product", {data : {prd_idx : data}});
};

const productModify = data => {

  //전송할 데이터에 파일이 있을경우
  const config = {
    headers : {
        'context-type' : 'multipart/form-data'
    }
  }

  return http.put("payment/product", data, config);
};

const productSelect = data => {
  return http.get(`payment/product/${data}`);
};

const productGroupGetAll = () => {
  return http.get("payment/group/product");
};

const productGroupOption1GetAll = data => {
  return http.get(`payment/group/option1/product/${data}`);
};

const productGroupOption2GetAll = data => {
  return http.get(`payment/group/option2/product/${data}`);
};

const ProductService = {
  productCreate,
  productGetAll,
  productDelete,
  productModify,
  productSelect,
  productGroupGetAll,
  productGroupOption1GetAll,
  productGroupOption2GetAll
};

export default ProductService;
