import * as React from "react";

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
// import DashboardIcon from '@mui/icons-material/Dashboard';
import CampaignIcon from '@mui/icons-material/Campaign';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import AssessmentIcon from '@mui/icons-material/Assessment';
import CategoryIcon from '@mui/icons-material/Category';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import ArticleIcon from '@mui/icons-material/Article';
import CoPresentIcon from '@mui/icons-material/CoPresent';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import MapIcon from '@mui/icons-material/Map';
import ChangeHistoryIcon from '@mui/icons-material/ChangeHistory';

import { useNavigate } from 'react-router-dom';

// const MenuArray = ['대쉬보드', '공지사항', '결제 정보', '학습자 정보', '카테고리 관리', '상품관리', '주문관리', '환불 히스토리'];
// const MenuUrlArray = ['/dashboard', '/dashboard/notice', '/dashboard/PaymentList', '/dashboard/student', '/dashboard/CategoryList', '/dashboard/ProductList', '/dashboard/OrderList', '/dashboard/RefundList'];
// const MenuIconArray = [<DashboardIcon />, <CampaignIcon />, <CreditCardIcon />, <AssessmentIcon />, <CategoryIcon />, <ShoppingBasketIcon />, <LibraryBooksIcon />, <ArticleIcon />];

const MenuArray = [
  '공지사항 관리', 
  '맵 카테고리 관리', 
  '맵 관리', 
  '지식은행 카테고리', 
  '지식은행 관리', 
  '사용자 관리', 
  '단체 사용자 관리', 
  '학습자 관리', 
  '상품 카테고리 관리', 
  '상품관리', 
  '주문관리', 
  '결제관리', 
  '환불 히스토리', 
  '야미 공지사항 관리', 
  '쿠폰 관리',
  '문의내역 관리',
  '뉴스 관리',
  '랭킹 관리',
  '인앱 상품 관리',
  '인앱 내역 관리',
  '단체 주문 관리',
];
const MenuUrlArray = [
  '/dashboard/NoticeList/notice', 
  '/dashboard/MissionCategoryList', 
  '/dashboard/MissionList', 
  '/dashboard/KnowledgeCategoryList', 
  '/dashboard/KnowledgeList',
  '/dashboard/UserList', 
  '/dashboard/Group/UserList', 
  '/dashboard/StudentList', 
  '/dashboard/CategoryList', 
  '/dashboard/ProductList', 
  '/dashboard/OrderList', 
  '/dashboard/PaymentList', 
  '/dashboard/RefundList', 
  '/dashboard/YameNoticeList/yame_notice', 
  '/dashboard/CouponList',
  '/dashboard/SolutionList',
  '/dashboard/NewsList',
  '/dashboard/RankingList',
  '/dashboard/InappProductList', 
  '/dashboard/InappPaymentList', 
  '/dashboard/GroupOrderList', 
];
const MenuIconArray = [
<CampaignIcon />, 
<ChangeHistoryIcon />, 
<MapIcon />, 
<ChangeHistoryIcon />, 
<AccountBalanceIcon />, 
<CoPresentIcon />, 
<CoPresentIcon />, 
<AssessmentIcon />, 
<CategoryIcon />, 
<ShoppingBasketIcon />, 
<LibraryBooksIcon />, 
<CreditCardIcon />, 
<ArticleIcon />, 
<CampaignIcon />, 
<CampaignIcon />,
<CampaignIcon />,
<CampaignIcon />,
<CampaignIcon />,
<CampaignIcon />,
<CampaignIcon />,
<CreditCardIcon />, 
];

export default function LeftMenuAdmin() {

  const navigate = useNavigate();

  const handleLeftNav = (index) => {
    
    switch (index)
    {
      // dashboard : 대쉬보드
      // case 0:
      //   navigate(MenuUrlArray[0]);
      //   break;

      // 공지사항 관리
      case 0:
        navigate(MenuUrlArray[0]);
        break;

      // 맵 카테고리 관리
      case 1:
        navigate(MenuUrlArray[1]);
        break;

      // 맵 관리
      case 2:
        navigate(MenuUrlArray[2]);
        break;

      // 지식은행 카테고리 관리
      case 3:
        navigate(MenuUrlArray[3]);
        break;

      // 지식은행 관리
      case 4:
        navigate(MenuUrlArray[4]);
        break;

      // 사용자 관리
      case 5:
        navigate(MenuUrlArray[5]);
        break;

      // 단체 사용자 관리
      case 6:
        navigate(MenuUrlArray[6]);
        break;

      // 학습자 관리
      case 7:
        navigate(MenuUrlArray[7]);
        break;

      // 상품 카테고리 관리
      case 8:
        navigate(MenuUrlArray[8]);
        break;

      // 상품관리
      case 9:
        navigate(MenuUrlArray[9]);
        break;

      // 주문관리
      case 10:
        navigate(MenuUrlArray[10]);
        break;

      // 결제관리
      case 11:
        navigate(MenuUrlArray[11]);
        break;

      // 환불 히스토리
      case 12:
        navigate(MenuUrlArray[12]);
        break;

      // 야미 공지사항 관리
      case 13:
        navigate(MenuUrlArray[13]);
        break;

      // 쿠폰 관리
      case 14:
        navigate(MenuUrlArray[14]);
        break;

      // 문의사항 관리
      case 15:
        navigate(MenuUrlArray[15]);
        break;

      // 뉴스 관리
      case 16:
        navigate(MenuUrlArray[16]);
        break;

      // 랭킹 관리
      case 17:
        navigate(MenuUrlArray[17]);
        break;

      // 인앱 상품 관리
      case 18:
        navigate(MenuUrlArray[18]);
        break;

      // 인앱 내역 관리
      case 19:
        navigate(MenuUrlArray[19]);
        break;

      // 단체 주문 내역 관리
      case 20:
        navigate(MenuUrlArray[20]);
        break;

      default:
        break;
    }
  };

    return (
        <div>
        <List>
          {MenuArray.map((text, index) => (
            <ListItem button key={text} onClick={() => handleLeftNav(index)}>
              <ListItemIcon>
                {MenuIconArray[index]}
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItem>
          ))}
        </List>
        </div>
    );
}