import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';

import { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { groupOrderList } from "../../../actions/group_order";

import { useNavigate } from 'react-router-dom';

import * as common from "../../../lib";

export default function OrderList() {

    const order = useSelector(state => state.group_order);
    // console.log("order : ", order);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleWriteOrder = (event) => {
      navigate("/dashboard/OrderWrite");
    };

    const handleCancelOrder = (event, idx) => {
      // console.log(idx);

      if (window.confirm("정말 취소하시겠습니까??") === true)   //확인
      {
        // 여기서 디스패치로~
        // dispatch(orderCancel(idx))
        // .then(data => 
        // {
        //     console.log("data : ", data);
        //     if (!data.success)
        //         alert(data.msg);

        //     navigate("/dashboard/OrderList");
        // })
        // .catch(e => {
        //     console.log(e);
        // });
      }
      else  //취소
      {
        return false;
      }

    };

    const handleImgError = (e) => {
      e.target.src = "https://cdn-icons-png.flaticon.com/512/1474/1474713.png";
    }

    useEffect(() => {

        // 여기서 디스패치로~
        dispatch(groupOrderList())
        .then(data => 
        {
            // console.log("data : ", data);
        })
        .catch(e => {
            console.log(e);
        });

    }, [dispatch])

  return (
    <Card sx={{ minWidth: 275 }}>
      <CardContent>
      패키지 구입 리스트
      <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="center">번호</TableCell>
            <TableCell align="center">주문번호</TableCell>
            <TableCell align="center">상품명</TableCell>
            <TableCell align="center">주문자</TableCell>
            <TableCell align="center">주문금액</TableCell>
            <TableCell align="center">주문상태</TableCell>
            <TableCell align="center">주문일</TableCell>
            <TableCell align="center">결제완료일</TableCell>
            <TableCell align="center">취소</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {order && order.map((row, index) => (
            <TableRow
              key={index}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row" align="center">
                {row.bord_idx}
              </TableCell>
              <TableCell align="center"><Link href={`/dashboard/GroupOrderView/${row.bord_idx}`} underline="none">{row.bord_no}</Link></TableCell>
              <TableCell align="center">{row.prd_name}</TableCell>
              <TableCell align="center">{row.mem_name}</TableCell>
              <TableCell align="center">&#8361; {common.priceToString(row.bpay_amt)}</TableCell>
              <TableCell align="center">{row.bpay_state === null ? common.returnOrderState(row.bord_stat_cd) : common.returnGroupPaymentState(row.bpay_state)}</TableCell>
              <TableCell align="center">{row.bord_date || "-"}</TableCell>
              <TableCell align="center">{row.bpay_date || "-"}</TableCell>
              <TableCell align="center">
                {
                  row.ord_stat_cd === "A" || row.ord_stat_cd === "F" ? <strike>취소</strike> :
                  <Link onClick={(e)=>handleCancelOrder(e, row.ord_idx)} value={row.ord_idx} underline="none">취소</Link>
                }
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      </TableContainer>

      </CardContent>
      <CardActions>
        <Button onClick={handleWriteOrder} size="small">패키지 구매</Button>
      </CardActions>
    </Card>
  );
}