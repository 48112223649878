import {
  LIST_PACKAGE,
  USE_STUDENT_PACKAGE,
  USE_NOT_STUDENT_PACKAGE,
  USE_UPDATE_PACKAGE,
  STUDENT_INFO_PACKAGE,
  STUDENT_LIST_PACKAGE,
  STUDENT_LIST_PACKAGEINFO,
  STUDENT_INFO_PACKAGEINFO,
  USE_UPDATE_PACKAGEINFO,
} from "./types";

import PackageService from "../services/PackageService";

export const packageList = () => async (dispatch) => {
  
  try {
    
    const res = await PackageService.packageGetAll();

    // console.log("res : ", res);

    dispatch({
      type: LIST_PACKAGE,
      payload: res.data.data,
    });

    return Promise.resolve(res.data.data);

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};

export const packageUseStudent = (data) => async (dispatch) => {
  
  try {

    // console.log("packageUseStudent data : ", data);
    
    const res = await PackageService.packageUseStudent(data);

    // console.log("res : ", res);

    dispatch({
      type: USE_STUDENT_PACKAGE,
      payload: res.data.data,
    });

    return Promise.resolve(res.data.data);

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};

export const packageUseNotStudent = () => async (dispatch) => {
  
  try {
    
    const res = await PackageService.packageUseNotStudent();

    // console.log("res : ", res);

    dispatch({
      type: USE_NOT_STUDENT_PACKAGE,
      payload: res.data.data,
    });

    return Promise.resolve(res.data.data);

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};

export const packageUseStudentModify = (data) => async (dispatch) => {
  
  try {
    
    const res = await PackageService.packageUseStudentModify(data);

    // console.log("res : ", res);

    dispatch({
      type: USE_UPDATE_PACKAGE,
      payload: res.data,
    });

    return Promise.resolve(res.data);

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};

export const packageStudentInfo = () => async (dispatch) => {
  
  try {
    
    const res = await PackageService.packageStudentInfo();

    // console.log("res : ", res);

    dispatch({
      type: STUDENT_INFO_PACKAGE,
      payload: res.data.data,
    });

    return Promise.resolve(res.data.data);

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};

export const packageStudentList = () => async (dispatch) => {
  
  try {
    
    const res = await PackageService.packageStudentList();

    // console.log("res : ", res);

    dispatch({
      type: STUDENT_LIST_PACKAGE,
      payload: res.data.data,
    });

    return Promise.resolve(res.data.data);

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};

export const packageInfoList = () => async (dispatch) => {
  
  try {
    
    const res = await PackageService.packageInfoGetAll();

    // console.log("res : ", res);

    dispatch({
      type: STUDENT_LIST_PACKAGEINFO,
      payload: res.data.data,
    });

    return Promise.resolve(res.data.data);

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};

export const packageInfoStudentInfo = () => async (dispatch) => {
  
  try {
    
    const res = await PackageService.packageInfoStudentInfo();

    // console.log("res : ", res);

    dispatch({
      type: STUDENT_INFO_PACKAGEINFO,
      payload: res.data.data,
    });

    return Promise.resolve(res.data.data);

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};

export const packageInfoUseStudentModify = (data) => async (dispatch) => {
  
  try {
    
    const res = await PackageService.packageInfoUseStudentModify(data);

    // console.log("res : ", res);

    dispatch({
      type: USE_UPDATE_PACKAGEINFO,
      payload: res.data,
    });

    return Promise.resolve(res.data);

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};
