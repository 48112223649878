import {
  CREATE_KNOWLEDGE,
  LIST_KNOWLEDGE,
  DELETE_KNOWLEDGE,
  MODIFY_KNOWLEDGE,
  SELECT_KNOWLEDGE,
} from "./types";

import KnowledgeService from "../services/KnowledgeService";

export const knowledgeCreate = (data) => async (dispatch) => {
  
  try {
    
    const res = await KnowledgeService.knowledgeCreate(data);

    // console.log("res : ", res);

    dispatch({
      type: CREATE_KNOWLEDGE,
      payload: res.data,
    });

    return Promise.resolve(res.data);

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};

export const knowledgeList = () => async (dispatch) => {
  
  try {
    
    const res = await KnowledgeService.knowledgeGetAll();

    // console.log("res : ", res);

    dispatch({
      type: LIST_KNOWLEDGE,
      payload: res.data.data,
    });

    return Promise.resolve(res.data.data);

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};

export const knowledgeSelect = (idx) => async (dispatch) => {
  
  try {
    
    const res = await KnowledgeService.knowledgeSelect(idx);

    // console.log("res : ", res);

    dispatch({
      type: SELECT_KNOWLEDGE,
      payload: res.data.data,
    });

    return Promise.resolve(res.data.data);

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};

export const knowledgeDelete = (idx) => async (dispatch) => {
  
  try {

    // console.log("knowledgeDelete! idx : ", idx);

    const res = await KnowledgeService.knowledgeDelete(idx);

    // console.log("res : ", res);

    dispatch({
      type: DELETE_KNOWLEDGE,
      payload: res.data,
    });
  
    return Promise.resolve(res.data);

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};

export const knowledgeModify = (data) => async (dispatch) => {
  
  try {
    
    const res = await KnowledgeService.knowledgeModify(data);

    // console.log("res : ", res);

    dispatch({
      type: MODIFY_KNOWLEDGE,
      payload: res.data,
    });

    return Promise.resolve(res.data);

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};
