import React from 'react';
import Cover from './Profile/Cover';

import { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { userSelect } from "../../actions/users";
import { loginReload } from "../../actions/auth";

const UserProfile = () => {

    const profile = useSelector(state => state.users.user);
    console.log("profile : ", profile);
    const dispatch = useDispatch();
    const loginCheck = JSON.parse(localStorage.getItem("user"));

    const onReloadWindow = () => {
        
        if (loginCheck.email !== "")
        {
            console.log("dispatch userSelect");
            dispatch(userSelect(loginCheck.email))
            .then(data => 
            {
                console.log("data : ", data);
            })
            .catch(e => {
                console.log(e);
            });

            console.log("dispatch loginReload");
            dispatch(loginReload())
            .then(data => 
            {
                console.log("data : ", data);
            })
            .catch(e => {
                console.log(e);
            });
        }
      }

    useEffect(() => {
        
        if (loginCheck.email !== "")
        {
            console.log("dispatch userSelect");
            dispatch(userSelect(loginCheck.email))
            .then(data => 
            {
                console.log("data : ", data);
            })
            .catch(e => {
                console.log(e);
            });
        }
        return () => {      // 언마운트
            console.log("언마운트!");
        };

    }, [loginCheck.email, dispatch])

    return (
        <>
            사용자 프로필 <br /><br />
            <Cover profile={profile} onReloadWindow={onReloadWindow} /> 
        </>
    );
    
};

export default UserProfile;