import {
  LIST_RANKING,
  CREATE_RANKING,
  MODIFY_RANKING,
  DELETE_RANKING,
  SELECT_RANKING,
  MAKE_RANKING,
  LIST_CHALLENGE_RANKING,
  LIST_TOTAL_RANKING,
} from "./types";

import RankingService from "../services/RankingService";

export const rankingList = data => async (dispatch) => {
  
  try {
    
    const res = await RankingService.rankingGetAll(data);

    // console.log("res : ", res);

    dispatch({
      type: LIST_RANKING,
      payload: res.data.data,
    });

    return Promise.resolve(res.data.data);

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};

export const rankingCreate = (data) => async (dispatch) => {
  
  try {
    
    const res = await RankingService.rankingCreate(data);

    // console.log("res : ", res);

    dispatch({
      type: CREATE_RANKING,
      payload: res.data,
    });

    return Promise.resolve(res.data);

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};

export const rankingModify = (data) => async (dispatch) => {
  
  try {
    
    const res = await RankingService.rankingModify(data);

    // console.log("res : ", res);

    dispatch({
      type: MODIFY_RANKING,
      payload: res.data,
    });

    return Promise.resolve(res.data);

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};

export const rankingDelete = (data) => async (dispatch) => {
  
  try {

    const res = await RankingService.rankingDelete(data);

    // console.log("res : ", res);

    if (res.data.success === false)
    {
      alert(res.data.msg);
      return Promise.resolve(res.data);
    }
    else
    {
      dispatch({
        type: DELETE_RANKING,
        payload: res.data,
      });
  
      return Promise.resolve(res.data);
    }

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};

export const rankingSelect = data => async (dispatch) => {
  
  try {
    
    const res = await RankingService.rankingSelect(data);

    // console.log("res : ", res);

    dispatch({
      type: SELECT_RANKING,
      payload: res.data.data,
    });

    return Promise.resolve(res.data.data);

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};

export const rankingMake = data => async (dispatch) => {
  
  try {
    
    const res = await RankingService.rankingMake(data);

    // console.log("res : ", res);

    dispatch({
      type: MAKE_RANKING,
      payload: res.data,
    });

    return Promise.resolve(res.data);

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};

export const rankingChallengeList = data => async (dispatch) => {
  
  try {
    
    const res = await RankingService.rankingChallengeList(data);

    // console.log("res : ", res);

    dispatch({
      type: LIST_CHALLENGE_RANKING,
      payload: res.data.data,
    });

    return Promise.resolve(res.data.data);

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};

export const rankingTotalRanking = (data) => async (dispatch) => {
  
  try {
    
    const res = await RankingService.rankingTotalRanking(data);

    // console.log("res : ", res);

    dispatch({
      type: LIST_TOTAL_RANKING,
      payload: res.data.data,
    });

    return Promise.resolve(res.data.data);

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};

