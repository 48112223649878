import {
  CREATE_CATEGORY,
  LIST_CATEGORY,
  DELETE_CATEGORY,
  MODIFY_CATEGORY,
  SELECT_CATEGORY,
} from "../actions/types";

const initialState = [];

const categoryReducer = (category = initialState, action) => {
  const { type, payload } = action;

  // console.log("categoryReducer Call!");
  // console.log("type : ", type);
  // console.log("payload : ", payload);

  switch (type) {
    
    case CREATE_CATEGORY:
      return [];

    case LIST_CATEGORY:
      return payload;

    case SELECT_CATEGORY:
      return payload;

    case MODIFY_CATEGORY:
      return category.map((category) => {
        if (category.category_idx === payload.category_idx) {
          return {
            ...category,
            ...payload,
          };
        } else {
          return category;
        }
      });

    case DELETE_CATEGORY:
      return category.filter(({ category_idx }) => category_idx !== payload.category_idx);

    default:
      return category;
  }
};

export default categoryReducer;