import { Component } from 'react'
import ApexCharts from 'react-apexcharts'

export default class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      series: [{
        name: "클래스1",
        data: [10, 41, 35, 51, 49, 62]
      },
      {
        name: "클래스2",
        data: [1, 4, 15, 41, 69, 32]
      }],

      options: {  
        chart: {
        width:'100%',
          zoom: {
            enabled: false
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'straight'
        },
        title: {
          text: 'Product Trends by Month',
          align: 'left'
        },
        grid: {
          row: {
            colors: ['#f3f3f3f3', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.5
          },
        },
        xaxis: {
          categories: ['1월', '2월', '3월', '4월', '5월', '6월'],
        }
      }
    }
  }
  render() {
    return (
      <ApexCharts
        options={this.state.options}
        series={this.state.series}
        typs='line'
        height={280}
        />
    );
  }
}