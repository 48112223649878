import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';

import Box from '@mui/material/Box';
import Input from '@mui/material/Input';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import Link from '@mui/material/Link';
import FormControl from '@mui/material/FormControl';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormLabel from '@mui/material/FormLabel';
import FormControlLabel from '@mui/material/FormControlLabel';

import ImgFileUpload from '../../../common/ImgFileUpload';

import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";

import { solutionReply, solutionSelect } from "../../../actions/solution";

import { useNavigate, useParams } from 'react-router-dom';

import * as common from "../../../lib";

const ariaLabel = { 'aria-label': 'description' };

export default function SolutionReply() {

    const idx  = useParams().idx;
    // console.log("idx : ", idx);
    const prevPage  = useParams().prevPage;
    // console.log("prevPage : ", prevPage);

    const [mem_idx, setMem_idx] = useState("");
    const [name, setName] = useState("");
    const [mem_name, setMem_name] = useState("");
    const [email, setEmail] = useState("");
    const [mem_email, setMem_email] = useState("");
    const [reg_date, setReg_date] = useState("");
    const [sc_category_idx, setSc_category_idx] = useState(0);
    const [title, setTitle] = useState("");
    const [question, setQuestion] = useState("");
    const [answer, setAnswer] = useState("");
    const [answer_reg_date, setAnswer_reg_date] = useState("");
    const [file, setFile] = useState(null);
    const [fileName, setFileName] = useState(null);
    const [filePath, setFilePath] = useState(null);
    const [fileChange, setFileChange] = useState(false);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    // 리듀서 값들 저장
    const userInfo = useSelector(state => state.auth);
    // console.log("userInfo : ", userInfo);

    const onChange = (event) => {
        // console.log(event.target.value);
        // console.log(event.target.name);

        if (event.target.name === "answer")
        {
            setAnswer(event.target.value);
        }
    }

    const handleImgFileChange = (file) => {
        // console.log("file : ", file);
        setFile(file);
        setFileChange(true);
    };
    
    const onModifySubmit = (event) => {
    
        event.preventDefault();
        
        if(userInfo.isLoggedIn)
        {
            const formData = new FormData();
            formData.append('answer', answer);
            formData.append('idx', idx);
    
            // if (answer === "")
            // {
            //     alert("답변이 없습니다. 작성해주세요.");
            //     return;
            // }
        
            // 여기서 디스패치로~
            dispatch(solutionReply(formData))
            .then(data => 
            {
                // console.log("bbsModify data : ", data);
                if (!data.success)
                {
                    alert(data.msg);
                    return;
                }
                else
                {
                    navigate(`/dashboard/SolutionList/${prevPage}`);
                }
                    
    
            })
            .catch(e => {
                console.log("bbsModify error : ", e);
                console.log(e);
            });
        }
        else
        {
            alert("로그인 후에 수정을 할 수 있습니다.");
            return;
        }
    }

    useEffect(() => {

        // 여기서 디스패치로~
        dispatch(solutionSelect(idx))
        .then(data => 
        {
            // console.log("solutionSelect data : ", data);

            setMem_idx(data.mem_idx);
            setName(data.name);
            setMem_name(data.mem_name);
            setEmail(data.email);
            setMem_email(data.mem_email);
            setReg_date(data.reg_date);
            setSc_category_idx(data.sc_category_idx);
            setTitle(data.title);
            setQuestion(data.question);
            setAnswer(data.answer);
            setAnswer_reg_date(data.answer_reg_date);
        })
        .catch(e => {
            console.log("solutionSelect error : ", e);
            console.log(e);
        });

    }, [dispatch])


    const imageDelete = () => {

        // input file의 값을 초기화 합니다.
        let files = document.getElementsByName("imgFile");
        // console.log("files : ", files);
        files[0].value = "";
      
        // 미리보기 이미지도 초기화 합니다.
        setFileName(null);
        setFilePath(null);
        setFileChange(true);
    }

  return (
    <Card sx={{ minWidth: 275 }}>
        <CardContent>
        <Box
            sx={{
                '& > :not(style)': { m: 1, width: '400px' },
            }}
            noValidate
            autoComplete="off"
            >
            * 등록일 : {reg_date || ""}
        </Box>
        <Box
            sx={{
                '& > :not(style)': { m: 1, width: '400px' },
            }}
            noValidate
            autoComplete="off"
            >
            * 등록자 : {mem_idx === 0 ? name || "" : mem_name || ""} ({mem_idx === 0 ? "비회원" : "회원"})
        </Box>
        <Box
            sx={{
                '& > :not(style)': { m: 1, width: '400px' },
            }}
            noValidate
            autoComplete="off"
            >
            * 카테고리 : {common.returnSolutionType(sc_category_idx) || ""}
        </Box>
        <Box
            sx={{
                '& > :not(style)': { m: 1, width: '400px' },
            }}
            noValidate
            autoComplete="off"
            >
            * 제목 : {title || ""}
        </Box>
        <Box
            sx={{
                '& > :not(style)': { m: 1, width: '400px' },
            }}
            noValidate
            autoComplete="off"
            >
            * 문의사항
        </Box>
        <Box
        sx={{
            '& > :not(style)': { m: 1, width: '400px' },
        }}
        noValidate
        autoComplete="off"
        >
            {question || ""}
        </Box>
        <Box
            sx={{
                '& > :not(style)': { m: 1, width: '600px' },
            }}
            noValidate
            autoComplete="off"
            >
            * 답변
        </Box>
        <Box
        sx={{
            '& > :not(style)': { m: 1, width: '600px' },
        }}
        noValidate
        autoComplete="off"
        >
        <FormControl>
        <TextareaAutosize
            aria-label="minimum height"
            minRows={5}
            placeholder="답변"
            style={{ width: 600 }}
            onChange={onChange}
            id="answer" 
            name="answer"
            value={answer || ""}
        />
        </FormControl>
        </Box>
        
        {/* <ImgFileUpload handleImgFileChange={handleImgFileChange} width="400" /> */}
        {/* fileChange ? "" : <><img src={common.returnImgSrc(filePath, fileName)} width="400"/> <Button onClick={imageDelete}>이미지 삭제</Button></> */}
      </CardContent>
      <CardActions>
        <Button onClick={onModifySubmit} size="small">답변</Button>
        <Link href={`/dashboard/SolutionList/${prevPage}`} underline="none">목록</Link>
      </CardActions>
    </Card>
  );
}