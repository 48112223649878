import http from "../http-common";

const commentCreate = data => {
  return http.post("bbs/board/comment/add", data);
};

const commentGetAll = (bbs_name, page) => {
  return http.get(`bbs/board/comment/${bbs_name}/${page}`);
};

const commentDelete = data => {
  return http.delete("bbs/board/comment/del", {data : data});
};

const commentModify = data => {
  return http.put("bbs/board/comment/edit", data);
};

const CommentService = {
  commentCreate,
  commentGetAll,
  commentDelete,
  commentModify,
};

export default CommentService;
