import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import { useDispatch, useSelector } from "react-redux";
import { groupUserInfo } from "../../../actions/users";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function CustomizedDialogs(props) {
  const [open, setOpen] = React.useState(false);

  const dispatch = useDispatch();

  const userInfo = useSelector(state => state.users.info);
//   console.log("userInfo : ", userInfo);

  const handleClickOpen = () => {
    console.log(props.memIdx);

    dispatch(groupUserInfo(props.memIdx))
    .then(data => 
    {
        console.log("data : ", data);
    })
    .catch(e => {
        console.log(e);
    });

    setOpen(true);
  };
  
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button size={'small'} onClick={handleClickOpen}>
        {props.memName}
      </Button>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth={600}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
            {userInfo?.companyInfo?.mem_name} [{userInfo?.companyInfo?.mg_name}] &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>
            <>* 적용 상품 {userInfo?.productInfo?.length || 0}개<br></br><br></br></>
            {/* {userInfo?.productInfo && userInfo?.productInfo?.map((row, index) => (

                <>{row.mm_name} | {row.mm_type} | {row.mst_id} <br></br></>
              
            ))} */}
            <TableContainer component={Paper}>
            <Table sx={{ minWidth: 600 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                    <TableCell align="center">번호</TableCell>
                    <TableCell align="center">클래스명</TableCell>
                    <TableCell align="center">타입</TableCell>
                    <TableCell align="center">적용 학습자</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
            {
                userInfo?.productInfo && userInfo?.productInfo?.map((row, index) => (
                <TableRow
                    key={index}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                    <TableCell component="th" scope="row" align="center">
                    {index+1}
                    </TableCell>
                    <TableCell align="center">{row.mm_name}</TableCell>
                    <TableCell align="center">{row.mm_type}</TableCell>
                    <TableCell align="center">{row.mst_id}</TableCell>
                </TableRow>
                ))}
                </TableBody>
            </Table>
            </TableContainer>
            <br></br>
            <>* 클래스 {userInfo?.classInfo?.length || 0}개<br></br><br></br></>
            {/* {
                userInfo?.classInfo && userInfo?.classInfo?.map((row, index) => (
                    <>
                        클래스 : {row.classMain.mc_name} <br></br>
                        {
                            row.classSub.map((row, index) => (<>&nbsp;&nbsp;&nbsp;{row.mst_id} | ({row.valid_date || "무료 사용자"})<br></br></>))
                        }
                    </>
                )
            )} */}
            <TableContainer component={Paper}>
            <Table sx={{ minWidth: 600 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                    <TableCell align="center">번호</TableCell>
                    <TableCell align="center">클래스명</TableCell>
                    <TableCell align="center"></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
            {
                userInfo?.classInfo && userInfo?.classInfo?.map((row, index) => (
                <TableRow
                    key={index}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                    <TableCell component="th" scope="row" align="center">
                    {index+1}
                    </TableCell>
                    <TableCell align="center">{row.classMain.mc_name}</TableCell>
                    <TableCell align="center">

                    { row.classSub.length !== 0 ? 
                    <Table sx={{ minWidth: 450 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                            <TableCell align="center">번호</TableCell>
                            <TableCell align="center">학습자 정보 (아이디 | 유효기간)</TableCell>
                            <TableCell align="center"></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        {
                            row.classSub.map((row, index) => (
                            <TableRow
                                key={index}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="row" align="center">
                                {index+1}
                                </TableCell>
                                <TableCell align="center">{row.mst_id} | ({row.valid_date || "무료 사용자"})</TableCell>
                            </TableRow>
                            ))
                        }
                        </TableBody>
                    </Table>
                    :
                        ""
                    }


                    </TableCell>
                </TableRow>
                ))}
                </TableBody>
            </Table>
            </TableContainer>
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            닫기
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}