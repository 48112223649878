import {
  CREATE_COMMENT,
  LIST_COMMENT,
  DELETE_COMMENT,
  MODIFY_COMMENT,
} from "../actions/types";

const initialState = [];

const commentReducer = (comment = initialState, action) => {
  const { type, payload } = action;

  // console.log("commentReducer Call!");
  // console.log("type : ", type);
  // console.log("payload : ", payload);

  switch (type) {
    
    case CREATE_COMMENT:
      return [];

    case LIST_COMMENT:
      return payload;

    case MODIFY_COMMENT:
      return [];

    case DELETE_COMMENT:
      return [];

    default:
      return comment;
  }
};

export default commentReducer;